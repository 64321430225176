import React from 'react';

import Box from '@material-ui/core/Box';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import TextField from '@material-ui/core/TextField';
import CancelButton from 'components/Buttons/CancelButton';
import SaveButton from 'components/Buttons/SaveButton';
import ModalWrapper from 'components/Modal/FormikModalWrapper';
import { closeModal } from 'components/Modal/modalReducer';
import { Formik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import * as actions from 'store/actions';
import useStyles from 'styles/forms';
import * as Yup from 'yup';

const validationSchema = Yup.object({
  name: Yup.string().required('Name is Required!'),
  domain: Yup.string().required('Domain Name is Required!')
});

const AddOrganisation = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const organisation = useSelector((state) => state.modals.modalProps);
  const loading = useSelector((state) => state.portfolios.organisations.loading);

  const handleSave = (form) => {
    dispatch(actions.createOrganization(form.name, form.domain))
      .then(() => {
        dispatch(actions.getOrganisations());
        dispatch(closeModal());
        toast.success('Success');
      })
      .catch((err) => {
        toast.error(err);
        dispatch(closeModal());
      });
  };

  return (
    <ModalWrapper
      size="md"
      header={organisation?.id ? 'Edit Organisation' : 'New Organisation'}
      xl={false}
    >
      <Formik
        initialValues={organisation}
        validationSchema={validationSchema}
        onSubmit={(values) => {
          handleSave(values);
        }}
      >
        {({ values, isSubmitting, dirty, isValid, touched, errors, submitForm, handleChange }) => (
          <>
            <DialogContent>
              <TextField
                fullWidth
                label="Name"
                variant="outlined"
                InputProps={{
                  classes: {
                    input: classes.input
                  }
                }}
                disabled={loading}
                className={classes.textFieldXL}
                placeholder="Name"
                name="name"
                id="name"
                value={values.name}
                onChange={handleChange}
                error={touched.name && Boolean(errors.name)}
                helperText={touched.name && errors.name}
              />
              <TextField
                fullWidth
                label="Domain"
                variant="outlined"
                className={classes.textFieldXL}
                InputProps={{
                  classes: {
                    input: classes.input
                  }
                }}
                disabled={loading}
                placeholder="Domain"
                name="domain"
                id="domain"
                value={values.domain}
                onChange={handleChange}
                error={touched.domain && Boolean(errors.domain)}
                helperText={touched.domain && errors.domain}
              />
            </DialogContent>
            <DialogActions>
              <Box p={2} display="flex">
                <CancelButton onClick={() => dispatch(closeModal())} />
                <SaveButton
                  type="submit"
                  loading={loading}
                  disabled={!dirty || isSubmitting || !isValid}
                  onClick={submitForm}
                />
              </Box>
            </DialogActions>
          </>
        )}
      </Formik>
    </ModalWrapper>
  );
};

export default AddOrganisation;
