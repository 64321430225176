import React, { useState } from 'react';

import Box from '@material-ui/core/Box';
import Divider from '@material-ui/core/Divider';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import EditButton from 'components/Buttons/EditButton';
import Spinner from 'components/Spinner';
import AddContract from 'forms/AddContract';
import { formatDate } from 'helpers/utils';
import useRouter from 'hooks/useRouter';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import * as actions from 'store/actions';

import useStyles from '../styles';

const LeasingContract = ({ contractDetails }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { query } = useRouter();
  const { loaded, loading, error, units } = useSelector((state) => state.leasingManager);
  const [open, setOpen] = useState(false);

  const handleSave = (form) => {
    dispatch(actions.createLeasingContract(form))
      .then(() => {
        dispatch(actions.getContractById(query.id));
      })
      .then(() => {
        toast.success('Contract Updated');
      })
      .catch((err) => {
        toast.error(err);
      })
      .finally(() => {
        setOpen(false);
      });
  };

  if (loading) {
    return <Spinner mini />;
  }

  if (loaded && error) {
    return (
      <Typography color="error" style={{ marginLeft: 30, marginTop: 30 }}>
        {error}
      </Typography>
    );
  }

  return (
    <Box display="flex" justifyContent="center" flexDirection="column">
      {contractDetails && (
        <Paper>
          <Box>
            <Box display="flex" alignItems="center" pt={2} flexWrap="wrap">
              <Typography style={{ paddingRight: '20px' }} className={classes.title}>
                Contract Details
              </Typography>
              <EditButton onClick={() => setOpen(true)} />
            </Box>
            <AddContract
              units={units}
              open={open}
              handleClose={() => setOpen(false)}
              handleAdd={handleSave}
              details={contractDetails}
              label="Unit(s)"
            />
          </Box>
          <Box display="flex" justifyContent="center">
            <Divider className={classes.divider} />
          </Box>
          <Box flexWrap="wrap" display="flex" className={classes.boxContainer}>
            <Box display="flex" flexDirection="column" className={classes.boxInner}>
              <Typography className={classes.statsTitle}>Number/Name</Typography>
              <Typography className={classes.statsValue}>
                {contractDetails.number || 'N/A'}
              </Typography>
            </Box>
            <Box display="flex" flexDirection="column" className={classes.boxInner}>
              <Typography className={classes.statsTitle}>Unit(s)</Typography>
              <Typography className={classes.statsValue}>
                {contractDetails.unitidsList
                  .map((unitid) => units.find((unit) => unit.id === unitid)?.name)
                  .join(',')}
              </Typography>
            </Box>

            <Box display="flex" flexDirection="column" className={classes.boxInner}>
              <Typography className={classes.statsTitle}>Term Start Date</Typography>
              <Typography className={classes.statsValue}>
                {formatDate(contractDetails.startdate) || 'N/A'}
              </Typography>
            </Box>

            <Box display="flex" flexDirection="column" className={classes.boxInner}>
              <Typography className={classes.statsTitle}>Term End Date</Typography>
              <Typography className={classes.statsValue}>
                {formatDate(contractDetails.enddate) || 'N/A'}
              </Typography>
            </Box>
          </Box>
        </Paper>
      )}
    </Box>
  );
};

export default LeasingContract;

LeasingContract.defaultProps = {
  contractDetails: null
};

LeasingContract.propTypes = {
  contractDetails: PropTypes.object
};
