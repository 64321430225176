import React from 'react';

import { Dialog, Box, Typography } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import Transition from 'components/Transition';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import useStyles from 'styles/forms';

import { closeModal } from './modalReducer';

const ModalWrapper = ({ children, size, header, xl }) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const handleClose = () => {
    dispatch(closeModal());
  };

  return (
    <Dialog
      open
      onClose={handleClose}
      aria-labelledby="formik-dialog-modal"
      keepMounted
      TransitionComponent={Transition}
      classes={{ paper: classes.paper }}
      maxWidth={size}
    >
      <Box
        bgcolor="#2C324D"
        p={3}
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        mb={4}
        className={xl ? classes.responsiveXL : classes.responsive}
      >
        <Typography className={classes.assetTitle}>{header}</Typography>
        <CloseIcon className={classes.closeIcon} onClick={handleClose} />
      </Box>
      {children}
    </Dialog>
  );
};

export default ModalWrapper;

ModalWrapper.propTypes = {
  size: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  header: PropTypes.string.isRequired,
  xl: PropTypes.bool.isRequired
};
