import React from 'react';

import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import PropTypes from 'prop-types';

const SingleSelect = ({
  options = [],
  label = '',
  keyword = '',
  value = '',
  variant = 'standard',
  minWidth = 150,
  margin = '8px',
  handleChange = () => ({})
}) => (
  <FormControl style={{ margin, minWidth }} variant={variant}>
    <InputLabel id={`select-${label}-label`}>{label}</InputLabel>
    <Select
      labelId={`select-${label}-label`}
      id={`select-${label}`}
      value={value}
      onChange={handleChange}
      displayEmpty
      label={label}
    >
      {options.map((item) => (
        <MenuItem key={item.id} value={item.id}>
          {item[keyword]}
        </MenuItem>
      ))}
    </Select>
  </FormControl>
);

export default SingleSelect;

SingleSelect.defaultProps = {
  minWidth: 150,
  variant: 'standard',
  margin: '8px'
};

SingleSelect.propTypes = {
  options: PropTypes.arrayOf(PropTypes.object).isRequired,
  label: PropTypes.string.isRequired,
  keyword: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  handleChange: PropTypes.func.isRequired,
  minWidth: PropTypes.number,
  variant: PropTypes.string,
  margin: PropTypes.string
};
