import React from 'react';

import PropTypes from 'prop-types';

import FacilityManagementAlert from './types/FacilityAlert';
import LeaseBreakAlert from './types/LeaseBreakAlert';
import LeaseExpiryAlert from './types/LeaseExpiryAlert';
import NonOpexAlert from './types/NonOpexAlert';
import OpexAlert from './types/OpexAlert';
import VacantUnitsAlert from './types/VacantUnitsAlert';

const ALERT_TYPE_TENANT_CONTRACT_EXPIRATION = 1;
const ALERT_TYPE_FACILITY_MANAGER_TICKET_RAISED = 2;
const ALERT_TYPE_VACANT_UNITS = 3;
const ALERT_TYPE_TENANT_CONTRACT_BREAK = 4;
const ALERT_TYPE_OPEX_CONTRACT_EXPIRATION = 5;
const ALERT_TYPE_NON_OPEX_CONTRACT_EXPIRATION = 6;

export default function AlertBox({ item, toggleDrawer }) {
  const alerts = {
    [ALERT_TYPE_TENANT_CONTRACT_EXPIRATION]: (
      <LeaseExpiryAlert item={item} toggleDrawer={toggleDrawer} />
    ),
    [ALERT_TYPE_FACILITY_MANAGER_TICKET_RAISED]: (
      <FacilityManagementAlert item={item} toggleDrawer={toggleDrawer} />
    ),
    [ALERT_TYPE_VACANT_UNITS]: <VacantUnitsAlert item={item} toggleDrawer={toggleDrawer} />,
    [ALERT_TYPE_TENANT_CONTRACT_BREAK]: <LeaseBreakAlert item={item} toggleDrawer={toggleDrawer} />,
    [ALERT_TYPE_NON_OPEX_CONTRACT_EXPIRATION]: (
      <NonOpexAlert item={item} toggleDrawer={toggleDrawer} />
    ),
    [ALERT_TYPE_OPEX_CONTRACT_EXPIRATION]: <OpexAlert item={item} toggleDrawer={toggleDrawer} />
  };

  return alerts[item.type];
}

AlertBox.propTypes = {
  item: PropTypes.objectOf(PropTypes.any).isRequired,
  toggleDrawer: PropTypes.func.isRequired
};
