import { metaData } from 'config/config';
import wrappers from 'google-protobuf/google/protobuf/wrappers_pb';
import { formatForBackEnd } from 'helpers/utils';

import {
  CostManagerRequest,
  CreateCostManagerRequest,
  CostProviderRequest,
  CreateCostProviderRequest,
  UpdateCostProviderRequest,
  UpdateCostManagerRequest,
  CostSupplierDetailsRequest,
  UpdateCostSupplierDetailsRequest,
  GetCostContractDetailsRequest,
  UpdateCostContractDetailsRequest
} from '../../api/asset_grpc_web_pb';
import {
  CostManagerService,
  CostManagerServicePromise,
  CostProcurementService,
  CostContractDetailsService,
  CostSupplierService
} from '../services/asset';

import * as actionTypes from './actionTypes';

const getCostManagerStart = () => ({
  type: actionTypes.GET_ASSET_COST_MANAGER_START
});

const getCostManagerSuccess = (costManager) => ({
  type: actionTypes.GET_ASSET_COST_MANAGER_SUCCESS,
  costManager
});

const getCostManagerFail = (error) => ({
  type: actionTypes.GET_ASSET_COST_MANAGER_FAIL,
  error
});

export const getAssetCostManager = (id) => async (dispatch) => {
  dispatch(getCostManagerStart());
  const request = new CostManagerRequest();
  request.setId(id);

  try {
    const result = await CostManagerServicePromise.getCostManagersByAssetID(request, metaData());
    const response = result.toObject().costmanagersList;

    dispatch(getCostManagerSuccess(response));

    return response;
  } catch (error) {
    dispatch(getCostManagerFail(error.message));
    throw error.message;
  }
};

export const createAssetCostManager = (id, name) => () => {
  const request = new CreateCostManagerRequest();

  request.setAssetid(id);
  request.setName(name);

  return new Promise((resolve, reject) => {
    CostManagerService.createCostManager(request, metaData(), (err, response) => {
      if (err) {
        reject(err.message);
      } else {
        resolve(response.toObject());
      }
    });
  });
};

export const deleteAssetCostManager = (id) => () => {
  const request = new CostManagerRequest();

  request.setId(id);

  return new Promise((resolve, reject) => {
    CostManagerService.deleteCostManager(request, metaData(), (err, response) => {
      if (err) {
        reject(err.message);
      } else {
        resolve(response.toObject());
      }
    });
  });
};

const getAssetCostManagerDetailsStart = () => ({
  type: actionTypes.GET_ASSET_COST_MANAGER_DETAILS_START
});

const getAssetCostManagerDetailsSuccess = (details) => ({
  type: actionTypes.GET_ASSET_COST_MANAGER_DETAILS_SUCCESS,
  details
});

const getAssetCostManagerDetailsFail = (error) => ({
  type: actionTypes.GET_ASSET_COST_MANAGER_DETAILS_FAIL,
  error
});

export const getAssetCostManagerDetails = (id) => (dispatch) => {
  dispatch(getAssetCostManagerDetailsStart());
  const request = new CostManagerRequest();

  request.setId(id);

  return new Promise((resolve, reject) => {
    CostManagerService.getCostManagerByID(request, metaData(), (err, response) => {
      if (err) {
        dispatch(getAssetCostManagerDetailsFail(err.message));
        reject(err.message);
      } else {
        dispatch(getAssetCostManagerDetailsSuccess(response.toObject()));

        resolve(response.toObject());
      }
    });
  });
};

// Procurement
const getAssetCostManagerProcurementStart = () => ({
  type: actionTypes.GET_ASSET_COST_PROCUREMENT_START
});

const getAssetCostManagerProcurementSuccess = (procurement) => ({
  type: actionTypes.GET_ASSET_COST_PROCUREMENT_SUCCESS,
  procurement
});

const getAssetCostManagerProcurementFail = (error) => ({
  type: actionTypes.GET_ASSET_COST_PROCUREMENT_FAIL,
  error
});

export const getAssetCostManagerProcurement = (id) => (dispatch) => {
  dispatch(getAssetCostManagerProcurementStart());
  const request = new CostProviderRequest();

  request.setId(id);

  return new Promise((resolve, reject) => {
    CostProcurementService.getCostProvidersByCostManagerID(request, metaData(), (err, response) => {
      if (err) {
        dispatch(getAssetCostManagerProcurementFail(err.message));
        reject(err.message);
      } else {
        dispatch(getAssetCostManagerProcurementSuccess(response.toObject().costprovidersList));

        resolve(response.toObject());
      }
    });
  });
};

export const createAssetCostManagerProcurement = (id, form) => () => {
  if (form.id) {
    const request = new UpdateCostProviderRequest();

    request.setId(form.id);

    const name = new wrappers.StringValue();
    name.setValue(form.name);
    request.setName(name);

    const description = new wrappers.StringValue();
    description.setValue(form.description);
    request.setDescription(description);

    return new Promise((resolve, reject) => {
      CostProcurementService.updateCostProvider(request, metaData(), (err, response) => {
        if (err) {
          reject(err.message);
        } else {
          resolve(response.toObject());
        }
      });
    });
  }

  const request = new CreateCostProviderRequest();

  request.setCostmanagerid(id);
  request.setName(form.name);
  request.setDescription(form.description);

  return new Promise((resolve, reject) => {
    CostProcurementService.createCostProvider(request, metaData(), (err, response) => {
      if (err) {
        reject(err.message);
      } else {
        resolve(response.toObject());
      }
    });
  });
};

export const deleteAssetCostManagerProcurement = (id) => () => {
  const request = new CostProviderRequest();
  request.setId(id);

  return new Promise((resolve, reject) => {
    CostProcurementService.deleteCostProvider(request, metaData(), (err, response) => {
      if (err) {
        reject(err.message);
      } else {
        resolve(response.toObject());
      }
    });
  });
};

// Update AssetCost Manager Details
export const updateAssetCostManagerDetails = (id, name) => async () => {
  const request = new UpdateCostManagerRequest();

  request.setId(id);
  request.setName(name);

  return new Promise((resolve, reject) => {
    CostManagerService.updateCostManager(request, metaData(), (err, response) => {
      if (err) {
        reject(err.message);
      } else {
        resolve(response.toObject());
      }
    });
  });
};

// AssetCost Supplier Service
const getAssetCostSupplierService = (supplier) => ({
  type: actionTypes.GET_ASSET_COST_SUPPLIER_DETAILS,
  supplier
});

export const getAssetCostSupplierDetails = (id) => (dispatch) => {
  const request = new CostSupplierDetailsRequest();
  request.setId(id);

  return new Promise((resolve, reject) => {
    CostSupplierService.getCostSupplierDetailsByCostManagerID(
      request,
      metaData(),
      (err, response) => {
        if (err) {
          reject(err.message);
        } else {
          dispatch(getAssetCostSupplierService(response.toObject()));
          resolve(response.toObject());
        }
      }
    );
  });
};

export const updateAssetCostSupplierDetails = (form) => () => {
  const request = new UpdateCostSupplierDetailsRequest();
  request.setId(form.id);

  const city = new wrappers.StringValue();
  city.setValue(form.city);
  request.setCity(city);

  const companyname = new wrappers.StringValue();
  companyname.setValue(form.companyname);
  request.setCompanyname(companyname);

  const contact = new wrappers.StringValue();
  contact.setValue(form.contact);
  request.setContact(contact);

  const country = new wrappers.StringValue();
  country.setValue(form.country);
  request.setCountry(country);

  const email = new wrappers.StringValue();
  email.setValue(form.email);
  request.setEmail(email);

  const postcode = new wrappers.StringValue();
  postcode.setValue(form.postcode);
  request.setPostcode(postcode);

  const street = new wrappers.StringValue();
  street.setValue(form.street);
  request.setStreet(street);

  const phone = new wrappers.StringValue();
  phone.setValue(form.phone);
  request.setPhone(phone);

  return new Promise((resolve, reject) => {
    CostSupplierService.updateCostSupplierDetails(request, metaData(), (err, response) => {
      if (err) {
        reject(err.message);
      } else {
        resolve(response.toObject());
      }
    });
  });
};

// AssetCost Contract Service
const getAssetCostContractSuccess = (details) => ({
  type: actionTypes.GET_ASSET_COST_CONTRACT_DETAILS,
  details
});

export const getAssetCostContractDetails = (id) => (dispatch) => {
  const request = new GetCostContractDetailsRequest();
  request.setId(id);

  return new Promise((resolve, reject) => {
    CostContractDetailsService.getCostContractDetailsByCostManagerID(
      request,
      metaData(),
      (err, response) => {
        if (err) {
          reject(err.message);
        } else {
          dispatch(getAssetCostContractSuccess(response.toObject().contractdetailsList));
          resolve(response.toObject());
        }
      }
    );
  });
};

export const updateAssetCostContractDetails = (id, form) => () => {
  const request = new UpdateCostContractDetailsRequest();
  request.setId(id);

  const typeValue = new wrappers.StringValue();
  typeValue.setValue('Supplier');
  request.setType(typeValue);

  const startDate = new wrappers.StringValue();
  startDate.setValue(formatForBackEnd(form.startdate));
  request.setStartdate(startDate);

  const endDate = new wrappers.StringValue();
  endDate.setValue(formatForBackEnd(form.enddate));
  request.setEnddate(endDate);

  let formCopy = {};
  if (form.supplier.paymentTerms === 'RegularPayment') {
    formCopy = {
      supplier: {
        paymentTerms: form.supplier.paymentTerms,
        schedule: {
          paymentAmount: form.supplier.regularPayment.paymentAmount,
          frequency: form.supplier.frequency
        }
      }
    };
  } else {
    formCopy = {
      supplier: {
        paymentTerms: form.supplier.paymentTerms,
        schedule: form.supplier.schedule.payments
      }
    };
  }
  const typeDataWrap = new wrappers.StringValue();
  typeDataWrap.setValue(JSON.stringify(formCopy));

  request.setTypedata(typeDataWrap);

  return new Promise((resolve, reject) => {
    CostContractDetailsService.updateCostContractDetails(request, metaData(), (err, response) => {
      if (err) {
        reject(err.message);
      } else {
        resolve(response.toObject());
      }
    });
  });
};

export const resetAssetCostManager = () => ({
  type: actionTypes.RESET_ASSET_COST_MANAGER
});
