import { mockASSETCashManager } from '../fixtures';

import * as actionTypes from './actionTypes';

const getCashManagerStart = () => ({
  type: actionTypes.GET_ASSET_CASH_MANAGER_START
});

const getCashManagerSuccess = (cashManager) => ({
  type: actionTypes.GET_ASSET_CASH_MANAGER_SUCCESS,
  cashManager
});

const getCashManagerFail = (error) => ({
  type: actionTypes.GET_ASSET_CASH_MANAGER_FAIL,
  error
});

export const getAssetCashManager = () => (dispatch) => {
  dispatch(getCashManagerStart());

  try {
    setTimeout(() => {
      dispatch(getCashManagerSuccess(mockASSETCashManager));
    }, 500);
  } catch (error) {
    dispatch(getCashManagerFail(error));
  }
};
