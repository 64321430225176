import { metaData } from 'config/config';
import wrappers from 'google-protobuf/google/protobuf/wrappers_pb';

import {
  GetCashFlowByAssetIDRequest,
  GetAggregateFootfallsRequest,
  GetAggregateTurnoversRequest,
  TenantPerformanceRequest,
  AssetPerformanceRequest
} from '../../api/report_grpc_web_pb';
import {
  ReportAssetPerformanceService,
  ReportCashfLowService,
  ReportFootfallService,
  ReportTenantPerformance,
  ReportTurnoverService
} from '../services/report';

import * as actionTypes from './actionTypes';

// Cashflow reports
const getReportsStart = () => ({
  type: actionTypes.GET_REPORTS_START
});

const getReportsSuccess = (reports) => ({
  type: actionTypes.GET_REPORTS_SUCCESS,
  reports
});

const getReportsFail = (error) => ({
  type: actionTypes.GET_REPORTS_FAIL,
  error
});

export const getReports = (id) => (dispatch) => {
  const request = new GetCashFlowByAssetIDRequest();
  dispatch(getReportsStart());
  request.setAssetid(id);

  return new Promise((resolve, reject) => {
    ReportCashfLowService.getCashFlowByAssetID(request, metaData(), (err, response) => {
      if (err) {
        dispatch(getReportsFail(err.message));
        reject(err.message);
      } else {
        dispatch(getReportsSuccess(response.toObject()));

        resolve(response.toObject());
      }
    });
  });
};

// Footfall reports
const getFootfallReportStart = () => ({
  type: actionTypes.GET_FOOTFALL_REPORT_START
});

const getFootfallReportSuccess = (footfall) => ({
  type: actionTypes.GET_FOOTFALL_REPORT_SUCCESS,
  footfall
});

const getFootfallReportFail = (error) => ({
  type: actionTypes.GET_FOOTFALL_REPORT_FAIL,
  error
});

export const getFootfallReport = (id, type) => (dispatch) => {
  const request = new GetAggregateFootfallsRequest();
  dispatch(getFootfallReportStart());
  request.setAssetid(id);
  request.setAggregatetype(type);

  return new Promise((resolve, reject) => {
    ReportFootfallService.getAggregateFootfall(request, metaData(), (err, response) => {
      if (err) {
        dispatch(getFootfallReportFail(err.message));
        reject(err.message);
      } else {
        dispatch(getFootfallReportSuccess(response.toObject()));

        resolve(response.toObject());
      }
    });
  });
};

// Turnover reports
const getTurnoverReportStart = () => ({
  type: actionTypes.GET_TURNOVER_REPORT_START
});

const getTurnoverReportSuccess = (turnover, bundleId) => ({
  type: actionTypes.GET_TURNOVER_REPORT_SUCCESS,
  turnover,
  bundleId
});

const getTurnoverReportFail = (error) => ({
  type: actionTypes.GET_TURNOVER_REPORT_FAIL,
  error
});

export const setTurnoverReportBundle = (bundleId) => ({
  type: actionTypes.SET_TURNOVER_REPORT_BUNDLE,
  bundleId
});

export const getTurnoverReport = (id, tenant, bundleId) => (dispatch) => {
  const request = new GetAggregateTurnoversRequest();
  dispatch(getTurnoverReportStart());
  request.setAssetid(id);
  const tenantId = new wrappers.StringValue();
  tenantId.setValue(tenant);
  request.setTenantid(tenantId);

  return new Promise((resolve, reject) => {
    ReportTurnoverService.getAggregateTurnover(request, metaData(), (err, response) => {
      if (err) {
        dispatch(getTurnoverReportFail(err.message));
        reject(err.message);
      } else {
        dispatch(getTurnoverReportSuccess(response.toObject(), bundleId));

        resolve(response.toObject());
      }
    });
  });
};

// Tenant Performance reports
const getTenantPerformanceReportStart = () => ({
  type: actionTypes.GET_TENANT_PERFORMANCE_REPORT_START
});

const getTenantPerformanceReportSuccess = (tenantPerformance, bundleId) => ({
  type: actionTypes.GET_TENANT_PERFORMANCE_REPORT_SUCCESS,
  tenantPerformance,
  bundleId
});

const getTenantPerformanceReportFail = (error) => ({
  type: actionTypes.GET_TENANT_PERFORMANCE_REPORT_FAIL,
  error
});

export const setTenantPerformanceBundle = (bundleId) => ({
  type: actionTypes.SET_TENANT_PERFORMANCE_REPORT_BUNDLE,
  bundleId
});

export const getTenantPerformance = (id, bundle) => async (dispatch) => {
  const request = new TenantPerformanceRequest();
  dispatch(getTenantPerformanceReportStart());
  request.setTenantid(id);

  try {
    const result = await ReportTenantPerformance.getTenantPerformance(request, metaData());
    const response = result.toObject();
    dispatch(getTenantPerformanceReportSuccess(response, bundle));
    return response;
  } catch (error) {
    dispatch(getTenantPerformanceReportFail(error.message));

    return error.message;
  }
};

// Tenant Performance reports
const getAssetPerformanceReportStart = () => ({
  type: actionTypes.GET_ASSET_PERFORMANCE_REPORT_START
});

const getAssetPerformanceReportSuccess = (assetPerformance, bundleId) => ({
  type: actionTypes.GET_ASSET_PERFORMANCE_REPORT_SUCCESS,
  assetPerformance,
  bundleId
});

const getAssetPerformanceReportFail = (error) => ({
  type: actionTypes.GET_ASSET_PERFORMANCE_REPORT_FAIL,
  error
});

export const setAssetReportBundle = (bundleId) => ({
  type: actionTypes.SET_ASSETS_PERFORMANCE_EPORT_BUNDLE,
  bundleId
});

export const getAssetPerformance = (id, startDate, endDate, bundle) => async (dispatch) => {
  const request = new AssetPerformanceRequest();
  dispatch(getAssetPerformanceReportStart());
  request.setAssetid(id);

  try {
    const result = await ReportAssetPerformanceService.getAssetPerformance(request, metaData());
    const response = result.toObject();
    dispatch(getAssetPerformanceReportSuccess(response, bundle));
    return response;
  } catch (error) {
    dispatch(getAssetPerformanceReportFail(error.message));

    throw error.message;
  }
};

export const resetReports = () => ({
  type: actionTypes.RESET_REPORTS
});
