import React, { useEffect, lazy, Suspense } from 'react';

import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import NewButton from 'components/Buttons/NewButton';
import Container from 'components/Container';
import { openModal, closeModal } from 'components/Modal/modalReducer';
import Spinner from 'components/Spinner';
import useRouter from 'hooks/useRouter';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import * as actions from 'store/actions';
import useStyles from 'styles/costManager';

const FacilityTable = lazy(() => import('../../Shared/FacilityManager/FacilityTable'));
const bundleId = 'Page-1-org';

const FacilityManager = () => {
  const classes = useStyles();
  const { query } = useRouter();
  const dispatch = useDispatch();
  const { loaded, loading, facilityManager, error } = useSelector((state) => state.facilityManager);
  const bundleIds = useSelector((state) => state.users.byId);
  const { organizationid } = useSelector((state) => state.user.user);

  useEffect(() => {
    if (!query.id) {
      return;
    }

    dispatch(actions.getAssetFacilityManager(query.id));
  }, [query]);

  useEffect(() => {
    if (bundleIds.some((bundle) => bundle === bundleId)) {
      return;
    }
    dispatch(actions.getAllUsers(-1, bundleId, organizationid));
  }, []);

  async function handleSave(form) {
    await dispatch(actions.createFacilityTicket(query.id, form))
      .then(() => {
        dispatch(actions.getAssetFacilityManager(query.id));
        dispatch(closeModal());
      })
      .catch((err) => {
        toast.error(err);
      });
  }

  function deleteFacilityTicket(ticketId) {
    dispatch(actions.deleteFacilityTicket(ticketId)).then(() => {
      dispatch(actions.getAssetFacilityManager(query.id));
    });
  }

  const handleNew = () => {
    dispatch(
      openModal({
        modalType: 'FacilityManagerModal',
        modalProps: {
          facilityManager: {},
          handleSave,
          header: 'Facility Manager'
        }
      })
    );
  };

  if (!loaded || loading) {
    return <Spinner />;
  }

  if (error) {
    return (
      <Box p={3} m={3}>
        <Typography color="error">{error}</Typography>
      </Box>
    );
  }

  return (
    <Container>
      <Box display="flex" alignItems="center" justifyContent="space-between" m={3} p={2}>
        <Typography className={classes.header}>Facility Manager</Typography>
        <NewButton onClick={handleNew} label="New" />
      </Box>
      <Suspense fallback={<Spinner />}>
        {facilityManager && (
          <FacilityTable
            data={facilityManager}
            loading={loading}
            link={`/Asset/${query.id}/FacilityManager`}
            handleDelete={(ticketId) => deleteFacilityTicket(ticketId)}
          />
        )}
      </Suspense>
    </Container>
  );
};

export default FacilityManager;
