/**
 * @fileoverview gRPC-Web generated client stub for external
 * @enhanceable
 * @public
 */

// GENERATED CODE -- DO NOT EDIT!


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');


var google_protobuf_empty_pb = require('google-protobuf/google/protobuf/empty_pb.js')

var google_protobuf_wrappers_pb = require('google-protobuf/google/protobuf/wrappers_pb.js')
const proto = {};
proto.external = require('./user_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.external.UserServiceClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options['format'] = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.external.UserServicePromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options['format'] = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.external.RegisterUserRequest,
 *   !proto.google.protobuf.Empty>}
 */
const methodDescriptor_UserService_RegisterUser = new grpc.web.MethodDescriptor(
  '/external.UserService/RegisterUser',
  grpc.web.MethodType.UNARY,
  proto.external.RegisterUserRequest,
  google_protobuf_empty_pb.Empty,
  /**
   * @param {!proto.external.RegisterUserRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  google_protobuf_empty_pb.Empty.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.external.RegisterUserRequest,
 *   !proto.google.protobuf.Empty>}
 */
const methodInfo_UserService_RegisterUser = new grpc.web.AbstractClientBase.MethodInfo(
  google_protobuf_empty_pb.Empty,
  /**
   * @param {!proto.external.RegisterUserRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  google_protobuf_empty_pb.Empty.deserializeBinary
);


/**
 * @param {!proto.external.RegisterUserRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.google.protobuf.Empty)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.google.protobuf.Empty>|undefined}
 *     The XHR Node Readable Stream
 */
proto.external.UserServiceClient.prototype.registerUser =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/external.UserService/RegisterUser',
      request,
      metadata || {},
      methodDescriptor_UserService_RegisterUser,
      callback);
};


/**
 * @param {!proto.external.RegisterUserRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.google.protobuf.Empty>}
 *     Promise that resolves to the response
 */
proto.external.UserServicePromiseClient.prototype.registerUser =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/external.UserService/RegisterUser',
      request,
      metadata || {},
      methodDescriptor_UserService_RegisterUser);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.external.GetUserRequest,
 *   !proto.external.GetUserResponse>}
 */
const methodDescriptor_UserService_GetUser = new grpc.web.MethodDescriptor(
  '/external.UserService/GetUser',
  grpc.web.MethodType.UNARY,
  proto.external.GetUserRequest,
  proto.external.GetUserResponse,
  /**
   * @param {!proto.external.GetUserRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.external.GetUserResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.external.GetUserRequest,
 *   !proto.external.GetUserResponse>}
 */
const methodInfo_UserService_GetUser = new grpc.web.AbstractClientBase.MethodInfo(
  proto.external.GetUserResponse,
  /**
   * @param {!proto.external.GetUserRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.external.GetUserResponse.deserializeBinary
);


/**
 * @param {!proto.external.GetUserRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.external.GetUserResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.external.GetUserResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.external.UserServiceClient.prototype.getUser =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/external.UserService/GetUser',
      request,
      metadata || {},
      methodDescriptor_UserService_GetUser,
      callback);
};


/**
 * @param {!proto.external.GetUserRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.external.GetUserResponse>}
 *     Promise that resolves to the response
 */
proto.external.UserServicePromiseClient.prototype.getUser =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/external.UserService/GetUser',
      request,
      metadata || {},
      methodDescriptor_UserService_GetUser);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.external.UpdateUserRequest,
 *   !proto.google.protobuf.Empty>}
 */
const methodDescriptor_UserService_UpdateUser = new grpc.web.MethodDescriptor(
  '/external.UserService/UpdateUser',
  grpc.web.MethodType.UNARY,
  proto.external.UpdateUserRequest,
  google_protobuf_empty_pb.Empty,
  /**
   * @param {!proto.external.UpdateUserRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  google_protobuf_empty_pb.Empty.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.external.UpdateUserRequest,
 *   !proto.google.protobuf.Empty>}
 */
const methodInfo_UserService_UpdateUser = new grpc.web.AbstractClientBase.MethodInfo(
  google_protobuf_empty_pb.Empty,
  /**
   * @param {!proto.external.UpdateUserRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  google_protobuf_empty_pb.Empty.deserializeBinary
);


/**
 * @param {!proto.external.UpdateUserRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.google.protobuf.Empty)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.google.protobuf.Empty>|undefined}
 *     The XHR Node Readable Stream
 */
proto.external.UserServiceClient.prototype.updateUser =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/external.UserService/UpdateUser',
      request,
      metadata || {},
      methodDescriptor_UserService_UpdateUser,
      callback);
};


/**
 * @param {!proto.external.UpdateUserRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.google.protobuf.Empty>}
 *     Promise that resolves to the response
 */
proto.external.UserServicePromiseClient.prototype.updateUser =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/external.UserService/UpdateUser',
      request,
      metadata || {},
      methodDescriptor_UserService_UpdateUser);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.external.GetUsersRequest,
 *   !proto.external.GetUsersResponse>}
 */
const methodDescriptor_UserService_GetUsers = new grpc.web.MethodDescriptor(
  '/external.UserService/GetUsers',
  grpc.web.MethodType.UNARY,
  proto.external.GetUsersRequest,
  proto.external.GetUsersResponse,
  /**
   * @param {!proto.external.GetUsersRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.external.GetUsersResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.external.GetUsersRequest,
 *   !proto.external.GetUsersResponse>}
 */
const methodInfo_UserService_GetUsers = new grpc.web.AbstractClientBase.MethodInfo(
  proto.external.GetUsersResponse,
  /**
   * @param {!proto.external.GetUsersRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.external.GetUsersResponse.deserializeBinary
);


/**
 * @param {!proto.external.GetUsersRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.external.GetUsersResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.external.GetUsersResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.external.UserServiceClient.prototype.getUsers =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/external.UserService/GetUsers',
      request,
      metadata || {},
      methodDescriptor_UserService_GetUsers,
      callback);
};


/**
 * @param {!proto.external.GetUsersRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.external.GetUsersResponse>}
 *     Promise that resolves to the response
 */
proto.external.UserServicePromiseClient.prototype.getUsers =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/external.UserService/GetUsers',
      request,
      metadata || {},
      methodDescriptor_UserService_GetUsers);
};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.external.RoleServiceClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options['format'] = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.external.RoleServicePromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options['format'] = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.google.protobuf.Empty,
 *   !proto.external.GetRoleListResponse>}
 */
const methodDescriptor_RoleService_GetRoleList = new grpc.web.MethodDescriptor(
  '/external.RoleService/GetRoleList',
  grpc.web.MethodType.UNARY,
  google_protobuf_empty_pb.Empty,
  proto.external.GetRoleListResponse,
  /**
   * @param {!proto.google.protobuf.Empty} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.external.GetRoleListResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.google.protobuf.Empty,
 *   !proto.external.GetRoleListResponse>}
 */
const methodInfo_RoleService_GetRoleList = new grpc.web.AbstractClientBase.MethodInfo(
  proto.external.GetRoleListResponse,
  /**
   * @param {!proto.google.protobuf.Empty} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.external.GetRoleListResponse.deserializeBinary
);


/**
 * @param {!proto.google.protobuf.Empty} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.external.GetRoleListResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.external.GetRoleListResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.external.RoleServiceClient.prototype.getRoleList =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/external.RoleService/GetRoleList',
      request,
      metadata || {},
      methodDescriptor_RoleService_GetRoleList,
      callback);
};


/**
 * @param {!proto.google.protobuf.Empty} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.external.GetRoleListResponse>}
 *     Promise that resolves to the response
 */
proto.external.RoleServicePromiseClient.prototype.getRoleList =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/external.RoleService/GetRoleList',
      request,
      metadata || {},
      methodDescriptor_RoleService_GetRoleList);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.external.UpdateRolePermissionRequest,
 *   !proto.google.protobuf.Empty>}
 */
const methodDescriptor_RoleService_UpdateRolePermission = new grpc.web.MethodDescriptor(
  '/external.RoleService/UpdateRolePermission',
  grpc.web.MethodType.UNARY,
  proto.external.UpdateRolePermissionRequest,
  google_protobuf_empty_pb.Empty,
  /**
   * @param {!proto.external.UpdateRolePermissionRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  google_protobuf_empty_pb.Empty.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.external.UpdateRolePermissionRequest,
 *   !proto.google.protobuf.Empty>}
 */
const methodInfo_RoleService_UpdateRolePermission = new grpc.web.AbstractClientBase.MethodInfo(
  google_protobuf_empty_pb.Empty,
  /**
   * @param {!proto.external.UpdateRolePermissionRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  google_protobuf_empty_pb.Empty.deserializeBinary
);


/**
 * @param {!proto.external.UpdateRolePermissionRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.google.protobuf.Empty)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.google.protobuf.Empty>|undefined}
 *     The XHR Node Readable Stream
 */
proto.external.RoleServiceClient.prototype.updateRolePermission =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/external.RoleService/UpdateRolePermission',
      request,
      metadata || {},
      methodDescriptor_RoleService_UpdateRolePermission,
      callback);
};


/**
 * @param {!proto.external.UpdateRolePermissionRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.google.protobuf.Empty>}
 *     Promise that resolves to the response
 */
proto.external.RoleServicePromiseClient.prototype.updateRolePermission =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/external.RoleService/UpdateRolePermission',
      request,
      metadata || {},
      methodDescriptor_RoleService_UpdateRolePermission);
};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.external.PermittedAssetServiceClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options['format'] = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.external.PermittedAssetServicePromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options['format'] = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.external.UpdatePermittedAssetsRequest,
 *   !proto.google.protobuf.Empty>}
 */
const methodDescriptor_PermittedAssetService_UpdatePermittedAssets = new grpc.web.MethodDescriptor(
  '/external.PermittedAssetService/UpdatePermittedAssets',
  grpc.web.MethodType.UNARY,
  proto.external.UpdatePermittedAssetsRequest,
  google_protobuf_empty_pb.Empty,
  /**
   * @param {!proto.external.UpdatePermittedAssetsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  google_protobuf_empty_pb.Empty.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.external.UpdatePermittedAssetsRequest,
 *   !proto.google.protobuf.Empty>}
 */
const methodInfo_PermittedAssetService_UpdatePermittedAssets = new grpc.web.AbstractClientBase.MethodInfo(
  google_protobuf_empty_pb.Empty,
  /**
   * @param {!proto.external.UpdatePermittedAssetsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  google_protobuf_empty_pb.Empty.deserializeBinary
);


/**
 * @param {!proto.external.UpdatePermittedAssetsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.google.protobuf.Empty)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.google.protobuf.Empty>|undefined}
 *     The XHR Node Readable Stream
 */
proto.external.PermittedAssetServiceClient.prototype.updatePermittedAssets =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/external.PermittedAssetService/UpdatePermittedAssets',
      request,
      metadata || {},
      methodDescriptor_PermittedAssetService_UpdatePermittedAssets,
      callback);
};


/**
 * @param {!proto.external.UpdatePermittedAssetsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.google.protobuf.Empty>}
 *     Promise that resolves to the response
 */
proto.external.PermittedAssetServicePromiseClient.prototype.updatePermittedAssets =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/external.PermittedAssetService/UpdatePermittedAssets',
      request,
      metadata || {},
      methodDescriptor_PermittedAssetService_UpdatePermittedAssets);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.external.GetPermittedAssetsRequest,
 *   !proto.external.GetPermittedAssetsResponse>}
 */
const methodDescriptor_PermittedAssetService_GetPermittedAssetsByUserID = new grpc.web.MethodDescriptor(
  '/external.PermittedAssetService/GetPermittedAssetsByUserID',
  grpc.web.MethodType.UNARY,
  proto.external.GetPermittedAssetsRequest,
  proto.external.GetPermittedAssetsResponse,
  /**
   * @param {!proto.external.GetPermittedAssetsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.external.GetPermittedAssetsResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.external.GetPermittedAssetsRequest,
 *   !proto.external.GetPermittedAssetsResponse>}
 */
const methodInfo_PermittedAssetService_GetPermittedAssetsByUserID = new grpc.web.AbstractClientBase.MethodInfo(
  proto.external.GetPermittedAssetsResponse,
  /**
   * @param {!proto.external.GetPermittedAssetsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.external.GetPermittedAssetsResponse.deserializeBinary
);


/**
 * @param {!proto.external.GetPermittedAssetsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.external.GetPermittedAssetsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.external.GetPermittedAssetsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.external.PermittedAssetServiceClient.prototype.getPermittedAssetsByUserID =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/external.PermittedAssetService/GetPermittedAssetsByUserID',
      request,
      metadata || {},
      methodDescriptor_PermittedAssetService_GetPermittedAssetsByUserID,
      callback);
};


/**
 * @param {!proto.external.GetPermittedAssetsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.external.GetPermittedAssetsResponse>}
 *     Promise that resolves to the response
 */
proto.external.PermittedAssetServicePromiseClient.prototype.getPermittedAssetsByUserID =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/external.PermittedAssetService/GetPermittedAssetsByUserID',
      request,
      metadata || {},
      methodDescriptor_PermittedAssetService_GetPermittedAssetsByUserID);
};


module.exports = proto.external;

