import React from 'react';

import Button from '@material-ui/core/Button';
import Icon from '@material-ui/core/Icon';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

const useStyles = makeStyles((theme) => ({
  ctaButton: {
    borderRadius: 60,
    height: '100%',
    width: theme.spacing(13),
    color: theme.palette.primary.main,
    background: theme.palette.common.white,
    fontWeight: 'bold',
    boxShadow: 'none',
    border: '1px solid #5381EF',
    '&:hover': {
      backgroundColor: theme.palette.secondary.main,
      color: theme.palette.common.white
    },
    '&:hover .MuiIcon-root': {
      color: 'white !important'
    }
  },
  root: {
    color: '#5381EF !important'
  }
}));
const EditButton = ({ disabled, onClick }) => {
  const classes = useStyles();
  return (
    <Button
      variant="contained"
      disabled={disabled}
      className={classes.ctaButton}
      startIcon={<Icon classes={{ root: classes.root }}>edit</Icon>}
      onClick={onClick}
    >
      Edit
    </Button>
  );
};

export default EditButton;

EditButton.defaultProps = {
  disabled: false
};

EditButton.propTypes = {
  disabled: PropTypes.bool,
  onClick: PropTypes.func.isRequired
};
