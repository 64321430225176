import React from 'react';

import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import { capitalize } from 'helpers/utils';
import useRouter from 'hooks/useRouter';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  breadContainer: {
    padding: theme.spacing(4, 0, 0, 4)
  },
  link: {
    textDecoration: 'none'
  },
  textLink: {
    fontSize: 14,
    fontFamily: 'Open Sans',
    textDecoration: 'none',
    color: theme.palette.primary.main
  },
  lastTextLink: {
    fontSize: 14,
    fontFamily: 'Open Sans',
    textDecoration: 'none',
    color: 'black'
  }
}));

const Breadcrumb = ({ parent }) => {
  const {
    location: { pathname }
  } = useRouter();
  const classes = useStyles();

  const fragments = React.useMemo(() => {
    // split by slash and remove empty-splitted values caused by trailing slashes. We also don't
    // want to display the UUIDs as part of the breadcrumbs (which unfortunately exist in the URL)
    const pathKeys = pathname.split('/').filter((fragment) => !!fragment);
    //  && !isGuid(fragment));

    // return the label (what to show) and the uri of each fragment. The URI is constructed by
    // taking the existing path and removing whatever is after each pathKey (only keeping whatever
    // is before-and-including our key). The key is essentially the URL path itself just prettified
    // for displat
    if (!pathKeys.length) {
      return [];
    }

    return [
      {
        ...parent
      },
      ...pathKeys.map((key) => ({
        href: `${pathname.substr(0, pathname.indexOf(`/${key}/`))}/${key}`,
        text: decodeURIComponent(key)
          .split(/(?=[A-Z])/)
          .map(capitalize)
          .join(' ')
      }))
    ];
  }, [pathname]);
  return (
    <Breadcrumbs
      aria-label="breadcrumb"
      className={classes.breadContainer}
      separator={<NavigateNextIcon fontSize="small" />}
    >
      {fragments.map((item, index) => {
        const isLastBreadcrumb = index === fragments.length - 1;

        return (
          <Link
            to={item.href}
            className={classes.link}
            key={item.text}
            style={isLastBreadcrumb ? { pointerEvents: 'none' } : {}}
          >
            <Typography
              className={isLastBreadcrumb ? classes.lastTextLink : classes.textLink}
              data-testid={`${item.text}-bread`}
            >
              {item.text}
            </Typography>
          </Link>
        );
      })}
    </Breadcrumbs>
  );
};

export default Breadcrumb;

Breadcrumb.propTypes = {
  parent: PropTypes.shape({
    href: PropTypes.string,
    text: PropTypes.string
  })
};

Breadcrumb.defaultProps = {
  parent: { href: '/', text: 'Home' }
};
