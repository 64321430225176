/* eslint-disable no-return-await */
/* eslint-disable no-unused-vars */
import { LegalTracker, LegalTrackerDetails } from '../fixtures';

import * as actionTypes from './actionTypes';

const getLegalTrackerStart = () => ({
  type: actionTypes.GET_TENANT_LEGAL_TRACKER_START
});

const getLegalTrackerSuccess = (legalTracker, bundleId) => ({
  type: actionTypes.GET_TENANT_LEGAL_TRACKER_SUCCESS,
  legalTracker,
  bundleId
});

const getLegalTrackerFail = (error) => ({
  type: actionTypes.GET_TENANT_LEGAL_TRACKER_FAIL,
  error
});

export const getLegalTracker = (id, bundleId) => async (dispatch) => {
  dispatch(getLegalTrackerStart());

  try {
    await dispatch(getLegalTrackerSuccess(LegalTracker, bundleId));
  } catch (error) {
    dispatch(getLegalTrackerFail(error));
  }
};

export const setLegalTrackerBundle = (bundleId) => ({
  type: actionTypes.SET_LEGAL_TRACKER,
  bundleId
});

const getLegalTrackeDetailsStart = () => ({
  type: actionTypes.GET_TENANT_LEGAL_TRACKER_CASE_START
});

const getLegalTrackerDetailsSuccess = (details) => ({
  type: actionTypes.GET_TENANT_LEGAL_TRACKER_CASE_SUCCESS,
  details
});

const getLegalTrackerDetailsFail = (error) => ({
  type: actionTypes.GET_TENANT_LEGAL_TRACKER__CASE_FAIL,
  error
});

export const getLegalTrackerDetails = (id) => async (dispatch) => {
  dispatch(getLegalTrackeDetailsStart());
  try {
    await dispatch(getLegalTrackerDetailsSuccess(LegalTrackerDetails));
  } catch (error) {
    dispatch(getLegalTrackerDetailsFail(error));
  }
};
