import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  header: {
    fontSize: 25,
    fontFamily: theme.fonts.base
  },
  paper: {
    boxShadow: '0px 3px 14px #0000000F',
    borderRadius: 4,
    overflow: 'auto',
    margin: theme.spacing(2, 6),
    marginLeft: 0,
    marginRight: 0
  },
  boxContainer: {
    padding: theme.spacing(3)
  },
  boxInner: {
    display: 'flex',
    justifyContent: 'center',
    margin: theme.spacing(2),
    width: '100%',
    [theme.breakpoints.down('xs')]: {
      width: '100%'
    }
  },
  title: {
    fontFamily: theme.fonts.base,
    color: theme.palette.common.black,
    marginBottom: theme.spacing(2)
  },
  headerText: {
    fontFamily: theme.fonts.baseSerif,
    fontSize: 30,
    color: theme.palette.common.black,
    fontWeight: 500
  },
  bigIndicator: {
    height: theme.spacing(0.5),
    background: theme.palette.primary.main
  },
  root: {
    flexGrow: 1,
    boxShadow: '0px 3px 14px #0000000F'
  },
  icon: {
    fontSize: 18,
    color: theme.palette.text.lightgrey
  },
  tabsRoot: {
    textTransform: 'unset !important',
    fontFamily: theme.fonts.baseSerif,
    fontWeight: 400,
    fontSize: theme.spacing(2),
    padding: theme.spacing(3),
    whiteSpace: 'nowrap'
  },
  textColorPrimary: {
    color: theme.palette.common.black
  },
  tabsContainer: {
    justifyContent: 'flex-start'
  },
  tabPanel: {
    background: '#F2F3F7'
  },
  editIcon: {
    color: '#7D7D7D',
    cursor: 'pointer',
    marginRight: 12,
    fontSize: 26
  },
  scroller: {
    flexGrow: '0'
  },
  circle: {
    height: theme.spacing(1.8),
    width: theme.spacing(1.8),
    borderRadius: theme.spacing(1),
    marginRight: theme.spacing(1)
  },
  subtitle: {
    fontFamily: theme.fonts.openSans,
    color: '#7D7D7D',
    fontSize: 12
  },
  subtitle2: {
    fontFamily: theme.fonts.openSans,
    color: '#5C5C5C'
  },
  responsive: {
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column'
    }
  },
  box: {
    paddingRight: theme.spacing(8),
    [theme.breakpoints.down('sm')]: {
      paddingTop: theme.spacing(2)
    }
  },
  divider: {
    width: '93%'
  },
  filesTitle: {
    fontFamily: theme.fonts.base,
    padding: theme.spacing(4),
    color: theme.palette.common.black
  },
  details: {
    fontSize: 16,
    marginLeft: theme.spacing(6),
    color: theme.palette.text.grey,
    fontFamily: theme.fonts.openSans,
    padding: theme.spacing(1)
  },
  statsTitle: {
    fontFamily: theme.fonts.openSans,
    color: '#7D7D7D',
    fontSize: 12
  },
  statsValue: {
    color: '#5C5C5C',
    fontSize: 16,
    fontFamily: theme.fonts.openSans
  },
  filesAdd: {
    color: theme.palette.primary.main,
    fontFamily: theme.fonts.base,
    fontSize: 14,
    fontWeight: 'bold',
    cursor: 'pointer'
  },
  paperXL: {
    boxShadow: '0px 3px 14px #0000000F',
    borderRadius: 4,
    margin: theme.spacing(1),
    height: '100%',
    textOverflow: 'ellipsis',
    overflow: 'auto'
  },
  tabTitle: {
    margin: theme.spacing(0, 4),
    fontFamily: theme.fonts.openSans,
    cursor: 'pointer'
  }
}));

export default useStyles;
