import { red } from '@material-ui/core/colors';
import { createTheme } from '@material-ui/core/styles';

// Asset Dynamics custom theme
const theme = createTheme({
  palette: {
    primary: {
      main: '#5381EF'
    },
    secondary: {
      main: '#2C324D'
    },
    accent: {
      main: '#B9B9B9'
    },
    error: {
      main: red.A400
    },
    text: {
      white: '#FFFFFF',
      grey: '#5C5C5C',
      lightgrey: '#B9B9B9'
    },
    background: {
      default: '#FFFFFF',
      grey: '#F2F3F7'
    },
    charts: {
      blue: '#5381EF',
      teal: '#51CDD5',
      orange: '#FEAB29',
      green: '#6BCD75',
      purple: '#B276DF',
      pink: '#FE95DE'
    }
  },
  fonts: {
    base: 'Montserrat',
    baseSerif: 'Montserrat, sans-serif',
    openSans: 'Open Sans'
  },
  shape: {
    borderRadiusLg: 45
  },
  overrides: {
    MuiButton: {
      iconSizeMedium: {
        '& > *:first-child': {
          fontSize: 16,
          color: 'white'
        }
      }
    },
    MuiListItem: {
      root: {
        color: '#5C5C5C',
        fontFamily: 'Open Sans',
        background: 'transparent',
        '&[aria-selected="true"]': {
          background: 'transparent'
        }
      }
    },
    MuiList: {
      root: {
        padding: '0px !important'
      }
    },
    MuiPaper: {
      root: {
        boxShadow: '0px 3px 14px #0000002B'
      }
    }
  }
});

export default theme;
