/* eslint-disable react/prop-types */
import React from 'react';

import ApexCharts from 'react-apexcharts';

import './index.css';

const ApexChart = ({ series, options, type, width, height }) => (
  <ApexCharts options={options} series={series} type={type} width={width} height={height} />
);

export default React.memo(ApexChart);
