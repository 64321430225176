import { getEnviroment } from 'config/config';

import {
  ReportCashFlowServiceClient,
  ReportFootfallServiceClient,
  ReportTurnoverServiceClient,
  ReportTenantPerformanceServicePromiseClient,
  ReportAssetPerformanceServicePromiseClient
} from '../../api/report_grpc_web_pb';

const env = localStorage.getItem('env');
const ENV = getEnviroment(env);

export const ReportCashfLowService = new ReportCashFlowServiceClient(ENV);
export const ReportFootfallService = new ReportFootfallServiceClient(ENV);
export const ReportTurnoverService = new ReportTurnoverServiceClient(ENV);
export const ReportTenantPerformance = new ReportTenantPerformanceServicePromiseClient(ENV);
export const ReportAssetPerformanceService = new ReportAssetPerformanceServicePromiseClient(ENV);
