import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  assetTitle: {
    fontFamily: theme.fonts.base,
    fontSize: 20,
    color: theme.palette.common.white
  },
  closeIcon: {
    color: theme.palette.common.white,
    fontSize: 26,
    cursor: 'pointer'
  },
  paper: {
    boxShadow: '0px 3px 14px #0000000F'
  },
  textField: {
    margin: theme.spacing(2, 0),
    maxWidth: 400,
    '& .MuiOutlinedInput-root': {
      '&.Mui-focused fieldset': {
        borderColor: theme.palette.primary.main
      }
    }
  },
  textFieldXL: {
    margin: theme.spacing(2, 0),
    '& .MuiOutlinedInput-root': {
      '&.Mui-focused fieldset': {
        borderColor: theme.palette.primary.main
      }
    }
  },
  selectTextfieldWrapper: {
    maxHeight: 200,
    overflowY: 'auto',
    // boxShadow: '0px 3px 14px #0000000F',
    marginTop: 15,
    maxWidth: 350
  },
  circle: {
    height: theme.spacing(1.8),
    width: theme.spacing(1.8),
    borderRadius: theme.spacing(1),
    marginRight: theme.spacing(1)
  },
  input: {
    fontFamily: theme.fonts.openSans,
    color: '#5C5C5C'
  },
  selected: {
    background: 'white'
  },
  menuItem: {
    '&:focus': {
      background: 'white'
    },
    padding: 10
  },
  selectInput: {
    fontFamily: theme.fonts.openSans,
    color: '#5C5C5C'
  },
  responsive: {
    minWidth: 300,
    [theme.breakpoints.up('md')]: {
      minWidth: 600
    }
  },
  responsivePaper: {
    minWidth: 300,
    [theme.breakpoints.up('md')]: {
      minWidth: 700
    }
  },
  responsiveXL: {
    minWidth: 280,
    [theme.breakpoints.up('md')]: {
      minWidth: 900
    }
  },
  textFieldPortfolio: {
    '& .MuiOutlinedInput-root': {
      '&.Mui-focused fieldset': {
        borderColor: theme.palette.primary.main
      }
    }
  },
  title: {
    fontFamily: theme.fonts.base,
    fontSize: 20,
    color: theme.palette.common.white
  },
  box: {
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column'
    }
  },
  option: {
    backgroundColor: theme.palette.background.grey,
    minWidth: 200,
    borderRadius: 5,
    height: 50,
    marginBottom: theme.spacing(1),
    border: '1px solid #D9D9D9',
    marginLeft: '0px !important',
    [theme.breakpoints.down('sm')]: {
      minWidth: 190
    },
    marginTop: 12
  },
  optionXl: {
    backgroundColor: theme.palette.background.grey,
    minWidth: 260,
    borderRadius: 5,
    height: 50,
    marginBottom: theme.spacing(1),
    border: '1px solid #D9D9D9',
    marginLeft: '0px !important',
    [theme.breakpoints.down('sm')]: {
      minWidth: 190
    },
    marginTop: 12
  },
  label: {
    fontFamily: theme.fonts.openSans,
    color: '#5C5C5C'
  },
  typeLabel: {
    color: theme.palette.common.black,
    marginBottom: theme.spacing(2),
    fontFamily: theme.fonts.base
  },
  uploadText: {
    margin: theme.spacing(2, 0),
    fontFamily: theme.fonts.base
  },
  stepper: {
    fontFamily: theme.fonts.openSans,
    color: 'white'
  },
  stepIcon: {
    color: theme.palette.accent.main
  },
  stepperwrap: {
    width: '100%',
    background: 'transparent'
  },
  cancelButton: {
    height: 40,
    borderRadius: 60,
    width: theme.spacing(16),
    color: theme.palette.secondary.main,
    '&:hover': {
      backgroundColor: theme.palette.grey
    },
    margin: theme.spacing(1),
    fontFamily: theme.fonts.base
  },
  stepIconText: {
    fill: '#51CDD5'
  },
  unitLabel: {
    fontFamily: theme.fonts.base,
    color: theme.palette.primary.main
  },
  removeLabel: {
    fontFamily: theme.fonts.base,
    color: theme.palette.error.main,
    cursor: 'pointer'
  },
  arrow: {
    cursor: 'pointer',
    fontSize: 18
  },
  footfallHeader: {
    fontFamily: theme.fonts.base,
    fontSize: 20
  },
  autocomplete: {
    width: 400,
    margin: theme.spacing(2, 0)
  }
}));

export default useStyles;
