import { getEnviroment } from 'config/config';

import {
  PortfolioServiceClient,
  OrganizationServiceClient
} from '../../api/organization_grpc_web_pb';

const env = localStorage.getItem('env');
const ENV = getEnviroment(env);

export const PortfolioService = new PortfolioServiceClient(ENV);
export const OrganizationService = new OrganizationServiceClient(ENV);
