import * as actionTypes from '../actions/actionTypes';

export const assetsInitialState = {
  assets: {
    loading: false,
    loaded: false,
    data: [],
    error: null
  },
  assetDetails: {
    loading: false,
    loaded: false,
    data: {},
    error: null
  },
  assetKpi: {
    assetKpi: [],
    loading: false,
    loaded: false,
    error: null
  },
  assetImages: {
    loading: false,
    loaded: false,
    images: [],
    error: null
  },
  unit: {
    unitsLoaded: false,
    unitsByAsset: [],
    unitsByFloor: [],
    unitSchedule: [],
    loading: false,
    loaded: false,
    error: null,
    stackingPlan: [],
    stackingPlanLoading: false,
    stackingPlanLoaded: false,
    stackingPlanError: null
  },
  tenant: {
    tenantsLoaded: false,
    tenantsByAsset: [],
    tenantsByFloor: [],
    tenancySchedule: [],
    loading: false,
    loaded: false,
    error: null,
    stackingPlan: [],
    stackingPlanLoading: false,
    stackingPlanLoaded: false,
    stackingPlanError: null
  },
  footfall: {
    footfall: [],
    loading: false,
    loaded: false,
    error: null
  },
  allIds: [],
  byId: [],
  activePath: null
};

const reducer = (state = assetsInitialState, action) => {
  switch (action.type) {
    case actionTypes.GET_TENANTS_BYASSETID_SUCCESS:
      return {
        ...state,
        tenant: {
          ...state.tenant,
          tenantsByAsset: action.tenants,
          tenanstLoaded: true
        }
      };

    case actionTypes.GET_UNITS_BYASSETID_SUCCESS:
      return {
        ...state,
        unit: {
          ...state.unit,
          unitsByAsset: action.units,
          unitsLoaded: true
        }
      };
    case actionTypes.GET_ASSETS_START:
      return {
        ...state,
        assets: {
          ...state.assets,
          loading: true
        }
      };
    case actionTypes.GET_ASSETS_SUCCESS:
      return {
        ...state,
        assets: {
          loading: false,
          loaded: true,
          data: action.assets
        },
        byId: state.byId.some((id) => id === action.bundleId)
          ? state.byId
          : [...state.byId, action.bundleId],
        allIds: { ...state.allIds, [action.bundleId]: [...action.assets] }
      };
    case actionTypes.GET_ASSETS_FAIL:
      return {
        ...state,
        assets: {
          loading: false,
          loaded: true,
          error: action.error
        }
      };
    case actionTypes.GET_ASSETS_DONE:
      return {
        ...state,
        assets: {
          loading: false,
          loaded: true
        }
      };

    case actionTypes.SET_ASSETS_BUNDLE: {
      return {
        ...state,
        assets: state.allIds[action.bundleId]
      };
    }
    case actionTypes.GET_ASSET_DETAILS_START:
      return {
        ...state,
        assetDetails: {
          loading: true
        }
      };
    case actionTypes.GET_ASSET_DETAILS_SUCCESS:
      return {
        ...state,
        assetDetails: {
          loading: false,
          loaded: true,
          data: action.assetDetails
        }
      };
    case actionTypes.GET_ASSET_DETAILS_FAIL:
      return {
        ...state,
        assetDetails: {
          loading: false,
          loaded: true,
          error: action.error
        }
      };
    case actionTypes.RESET_ASSET_DETAILS_STATE:
      return assetsInitialState;
    case actionTypes.GET_UNITS_SCHEDULE_START:
      return {
        ...state,
        unit: {
          ...state.unit,
          loading: true
        }
      };
    case actionTypes.GET_UNITS_SCHEDULE_SUCCESS:
      return {
        ...state,
        unit: {
          ...state.unit,
          loading: false,
          loaded: true,
          unitSchedule: action.unitSchedule
        }
      };
    case actionTypes.GET_UNITS_SCHEDULE_FAIL:
      return {
        ...state,
        unit: {
          ...state.unit,
          loading: false,
          loaded: true,
          error: action.error
        }
      };

    case actionTypes.GET_TENANCY_SCHEDULE_START:
      return {
        ...state,
        tenant: {
          ...state.tenant,
          loading: true
        }
      };
    case actionTypes.GET_TENANCY_SCHEDULE_SUCCESS:
      return {
        ...state,
        tenant: {
          ...state.tenant,
          loading: false,
          loaded: true,
          tenancySchedule: action.tenancySchedule
        }
      };
    case actionTypes.GET_TENANCY_SCHEDULE_FAIL:
      return {
        ...state,
        tenant: {
          ...state.tenant,
          loading: false,
          loaded: true,
          error: action.error
        }
      };

    case actionTypes.GET_TENANT_STACKING_PLAN_START:
      return {
        ...state,
        tenant: {
          ...state.tenant,
          stackingPlanLoading: true
        }
      };
    case actionTypes.GET_TENANT_STACKING_PLAN_SUCCESS:
      return {
        ...state,
        tenant: {
          ...state.tenant,
          stackingPlanLoading: false,
          stackingPlanLoaded: true,
          stackingPlan: action.stackingPlan
        }
      };
    case actionTypes.GET_TENANT_STACKING_PLAN_FAIL:
      return {
        ...state,
        tenant: {
          ...state.tenant,
          stackingPlanLoading: false,
          stackingPlanLoaded: true,
          stackingPlanError: action.error
        }
      };

    case actionTypes.GET_UNIT_STACKING_PLAN_START:
      return {
        ...state,
        unit: {
          ...state.unit,
          stackingPlanLoading: true
        }
      };
    case actionTypes.GET_UNIT_STACKING_PLAN_SUCCESS:
      return {
        ...state,
        unit: {
          ...state.unit,
          stackingPlanLoading: false,
          stackingPlanLoaded: true,
          stackingPlan: action.stackingPlan
        }
      };
    case actionTypes.GET_UNIT_STACKING_PLAN_FAIL:
      return {
        ...state,
        unit: {
          ...state.unit,
          stackingPlanLoading: false,
          stackingPlanLoaded: true,
          stackingPlanError: action.error
        }
      };

    case actionTypes.GET_ASSET_KPIS_START:
      return {
        ...state,
        assetKpi: {
          ...state.assetKpi,
          loading: true
        }
      };
    case actionTypes.GET_ASSET_KPIS_SUCCESS:
      return {
        ...state,
        assetKpi: {
          ...state.assetKpi,
          loading: false,
          loaded: true,
          assetKpi: action.assetKpi
        }
      };
    case actionTypes.GET_ASSET_KPIS_FAIL:
      return {
        ...state,
        assetKpi: {
          ...state.assetKpi,
          loading: false,
          loaded: true,
          error: action.error
        }
      };

    case actionTypes.GET_FOOTFALL_START:
      return {
        ...state,
        footfall: {
          ...state.footfall,
          loading: true
        }
      };
    case actionTypes.GET_FOOTFALL_SUCCESS:
      return {
        ...state,
        footfall: {
          ...state.footfall,
          loading: false,
          loaded: true,
          footfall: action.footfall
        }
      };
    case actionTypes.GET_FOOTFALL_FAIL:
      return {
        ...state,
        footfall: {
          ...state.footfall,
          loading: false,
          loaded: true,
          error: action.error
        }
      };
    case actionTypes.GET_IMAGES_LIST_START:
      return {
        ...state,
        assetImages: {
          loading: true
        }
      };
    case actionTypes.GET_IMAGES_LIST_SUCCESS:
      return {
        ...state,
        assetImages: {
          loading: false,
          loaded: true,
          images: action.images
        }
      };
    case actionTypes.GET_IMAGES_LIST_FAIL:
      return {
        ...state,
        assetImages: {
          loading: false,
          loaded: true,
          error: action.error
        }
      };
    case actionTypes.UPDATE_PATH:
      return {
        ...state,
        activePath: action.path
      };
    default:
      return state;
  }
};

export default reducer;
