import React from 'react';

import Typography from '@material-ui/core/Typography';
import { openModal, closeModal } from 'components/Modal/modalReducer';
import { formatDate, numberWithCommas } from 'helpers/utils';
import useRouter from 'hooks/useRouter';
import MaterialTable from 'material-table';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import * as actions from 'store/actions';

const ValuationsTable = ({ data, loading }) => {
  const router = useRouter();
  const dispatch = useDispatch();

  const handleDelete = (id) => {
    dispatch(actions.deleteValuation(id))
      .then(() => {
        toast.success('Valuation Deleted');
      })
      .then(() => {
        dispatch(actions.getValuations(router.query.id));
      })
      .catch((err) => {
        toast.error(err);
      });
    dispatch(closeModal());
  };

  const handleConfirm = (id) => {
    dispatch(
      openModal({
        modalType: 'AlertModal',
        modalProps: {
          title: 'Delete Valuation',
          content:
            'Are you sure you want to delete the selected Valuation? This action cannot be undone',
          handleConfirm: () => {
            handleDelete(id);
          }
        }
      })
    );
  };

  return (
    <MaterialTable
      components={{
        Toolbar: () => null
      }}
      columns={[
        { title: 'Valuation', field: 'valuation' },
        {
          title: 'Date',
          field: 'date',
          render: (rowData) => <div>{formatDate(rowData.date)}</div>
        },
        {
          title: 'Fair Market Value',
          field: 'marketvalue',
          render: (rowData) => <div>{`€${numberWithCommas(rowData.marketvalue, '0')}`}</div>
        },
        {
          title: 'Liquidation Value',
          field: 'liquidationvalue',
          render: (rowData) => <div>{`€${numberWithCommas(rowData.liquidationvalue, '0')}`}</div>
        },
        {
          title: 'Valuer',
          field: 'valuer'
        }
      ]}
      loading={loading}
      data={data}
      style={{
        padding: '30px',
        borderRadius: 5,
        boxShadow: 'none',
        zIndex: 0
      }}
      title={null}
      search
      large
      testid="valuations-table"
      shadow={false}
      nRowClick={(e, rowData) => router.push(`/Asset/${router.query.id}/Valuations/${rowData.id}`)}
      actions={[
        {
          icon: 'edit',
          tooltip: 'Edit',
          onClick: (e, rowData) =>
            router.push(`/Asset/${router.query.id}/Valuations/${rowData.id}`),
          iconProps: { style: { color: 'grey' } }
        },
        () => ({
          icon: 'delete',
          tooltip: 'Delete',
          onClick: (e, rowData) => handleConfirm(rowData.id),
          iconProps: { style: { color: 'grey' } }
        })
      ]}
      options={{
        pageSize: 10,
        pageSizeOptions: [5, 10, 25, 50],
        actionsColumnIndex: -1,
        sorting: true,
        search: false,
        headerStyle: {
          fontFamily: 'Montserrat, sans-serif',
          fontSize: 14
        },
        rowStyle: {
          fontFamily: 'Montserrat, sans-serif',
          padding: '0 12px'
        },
        cellStyle: {
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          fontSize: 16,
          fontFamily: 'Open Sans, Regular',
          color: '#5C5C5C',
          maxWidth: 300
        }
      }}
      localization={{
        body: {
          emptyDataSourceMessage: (
            <Typography variant="subtitle1">
              No records to display, Start Adding some with the New Button!
            </Typography>
          )
        }
      }}
    />
  );
};

export default ValuationsTable;

ValuationsTable.defaultProps = {
  data: []
};

ValuationsTable.propTypes = {
  data: PropTypes.arrayOf(PropTypes.any),
  loading: PropTypes.bool.isRequired
};
