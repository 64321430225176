/**
 * @fileoverview gRPC-Web generated client stub for external
 * @enhanceable
 * @public
 */

// GENERATED CODE -- DO NOT EDIT!


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');


var external_filters_pb = require('./filters_pb.js')

var google_protobuf_empty_pb = require('google-protobuf/google/protobuf/empty_pb.js')
const proto = {};
proto.external = require('./alert_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.external.AlertStreamServiceClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options['format'] = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.external.AlertStreamServicePromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options['format'] = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.external.AlertAction,
 *   !proto.google.protobuf.Empty>}
 */
const methodDescriptor_AlertStreamService_Notify = new grpc.web.MethodDescriptor(
  '/external.AlertStreamService/Notify',
  grpc.web.MethodType.UNARY,
  proto.external.AlertAction,
  google_protobuf_empty_pb.Empty,
  /**
   * @param {!proto.external.AlertAction} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  google_protobuf_empty_pb.Empty.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.external.AlertAction,
 *   !proto.google.protobuf.Empty>}
 */
const methodInfo_AlertStreamService_Notify = new grpc.web.AbstractClientBase.MethodInfo(
  google_protobuf_empty_pb.Empty,
  /**
   * @param {!proto.external.AlertAction} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  google_protobuf_empty_pb.Empty.deserializeBinary
);


/**
 * @param {!proto.external.AlertAction} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.google.protobuf.Empty)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.google.protobuf.Empty>|undefined}
 *     The XHR Node Readable Stream
 */
proto.external.AlertStreamServiceClient.prototype.notify =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/external.AlertStreamService/Notify',
      request,
      metadata || {},
      methodDescriptor_AlertStreamService_Notify,
      callback);
};


/**
 * @param {!proto.external.AlertAction} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.google.protobuf.Empty>}
 *     Promise that resolves to the response
 */
proto.external.AlertStreamServicePromiseClient.prototype.notify =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/external.AlertStreamService/Notify',
      request,
      metadata || {},
      methodDescriptor_AlertStreamService_Notify);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.google.protobuf.Empty,
 *   !proto.external.GetAllResponse>}
 */
const methodDescriptor_AlertStreamService_GetAll = new grpc.web.MethodDescriptor(
  '/external.AlertStreamService/GetAll',
  grpc.web.MethodType.UNARY,
  google_protobuf_empty_pb.Empty,
  proto.external.GetAllResponse,
  /**
   * @param {!proto.google.protobuf.Empty} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.external.GetAllResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.google.protobuf.Empty,
 *   !proto.external.GetAllResponse>}
 */
const methodInfo_AlertStreamService_GetAll = new grpc.web.AbstractClientBase.MethodInfo(
  proto.external.GetAllResponse,
  /**
   * @param {!proto.google.protobuf.Empty} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.external.GetAllResponse.deserializeBinary
);


/**
 * @param {!proto.google.protobuf.Empty} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.external.GetAllResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.external.GetAllResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.external.AlertStreamServiceClient.prototype.getAll =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/external.AlertStreamService/GetAll',
      request,
      metadata || {},
      methodDescriptor_AlertStreamService_GetAll,
      callback);
};


/**
 * @param {!proto.google.protobuf.Empty} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.external.GetAllResponse>}
 *     Promise that resolves to the response
 */
proto.external.AlertStreamServicePromiseClient.prototype.getAll =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/external.AlertStreamService/GetAll',
      request,
      metadata || {},
      methodDescriptor_AlertStreamService_GetAll);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.external.ListAlertsRequest,
 *   !proto.external.ListAlertsResponse>}
 */
const methodDescriptor_AlertStreamService_ListAlerts = new grpc.web.MethodDescriptor(
  '/external.AlertStreamService/ListAlerts',
  grpc.web.MethodType.UNARY,
  proto.external.ListAlertsRequest,
  proto.external.ListAlertsResponse,
  /**
   * @param {!proto.external.ListAlertsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.external.ListAlertsResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.external.ListAlertsRequest,
 *   !proto.external.ListAlertsResponse>}
 */
const methodInfo_AlertStreamService_ListAlerts = new grpc.web.AbstractClientBase.MethodInfo(
  proto.external.ListAlertsResponse,
  /**
   * @param {!proto.external.ListAlertsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.external.ListAlertsResponse.deserializeBinary
);


/**
 * @param {!proto.external.ListAlertsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.external.ListAlertsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.external.ListAlertsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.external.AlertStreamServiceClient.prototype.listAlerts =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/external.AlertStreamService/ListAlerts',
      request,
      metadata || {},
      methodDescriptor_AlertStreamService_ListAlerts,
      callback);
};


/**
 * @param {!proto.external.ListAlertsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.external.ListAlertsResponse>}
 *     Promise that resolves to the response
 */
proto.external.AlertStreamServicePromiseClient.prototype.listAlerts =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/external.AlertStreamService/ListAlerts',
      request,
      metadata || {},
      methodDescriptor_AlertStreamService_ListAlerts);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.external.HideRequest,
 *   !proto.google.protobuf.Empty>}
 */
const methodDescriptor_AlertStreamService_Hide = new grpc.web.MethodDescriptor(
  '/external.AlertStreamService/Hide',
  grpc.web.MethodType.UNARY,
  proto.external.HideRequest,
  google_protobuf_empty_pb.Empty,
  /**
   * @param {!proto.external.HideRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  google_protobuf_empty_pb.Empty.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.external.HideRequest,
 *   !proto.google.protobuf.Empty>}
 */
const methodInfo_AlertStreamService_Hide = new grpc.web.AbstractClientBase.MethodInfo(
  google_protobuf_empty_pb.Empty,
  /**
   * @param {!proto.external.HideRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  google_protobuf_empty_pb.Empty.deserializeBinary
);


/**
 * @param {!proto.external.HideRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.google.protobuf.Empty)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.google.protobuf.Empty>|undefined}
 *     The XHR Node Readable Stream
 */
proto.external.AlertStreamServiceClient.prototype.hide =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/external.AlertStreamService/Hide',
      request,
      metadata || {},
      methodDescriptor_AlertStreamService_Hide,
      callback);
};


/**
 * @param {!proto.external.HideRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.google.protobuf.Empty>}
 *     Promise that resolves to the response
 */
proto.external.AlertStreamServicePromiseClient.prototype.hide =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/external.AlertStreamService/Hide',
      request,
      metadata || {},
      methodDescriptor_AlertStreamService_Hide);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.external.GetAlertsCountRequest,
 *   !proto.external.GetAlertsCountResponse>}
 */
const methodDescriptor_AlertStreamService_GetAlertsCount = new grpc.web.MethodDescriptor(
  '/external.AlertStreamService/GetAlertsCount',
  grpc.web.MethodType.UNARY,
  proto.external.GetAlertsCountRequest,
  proto.external.GetAlertsCountResponse,
  /**
   * @param {!proto.external.GetAlertsCountRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.external.GetAlertsCountResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.external.GetAlertsCountRequest,
 *   !proto.external.GetAlertsCountResponse>}
 */
const methodInfo_AlertStreamService_GetAlertsCount = new grpc.web.AbstractClientBase.MethodInfo(
  proto.external.GetAlertsCountResponse,
  /**
   * @param {!proto.external.GetAlertsCountRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.external.GetAlertsCountResponse.deserializeBinary
);


/**
 * @param {!proto.external.GetAlertsCountRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.external.GetAlertsCountResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.external.GetAlertsCountResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.external.AlertStreamServiceClient.prototype.getAlertsCount =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/external.AlertStreamService/GetAlertsCount',
      request,
      metadata || {},
      methodDescriptor_AlertStreamService_GetAlertsCount,
      callback);
};


/**
 * @param {!proto.external.GetAlertsCountRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.external.GetAlertsCountResponse>}
 *     Promise that resolves to the response
 */
proto.external.AlertStreamServicePromiseClient.prototype.getAlertsCount =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/external.AlertStreamService/GetAlertsCount',
      request,
      metadata || {},
      methodDescriptor_AlertStreamService_GetAlertsCount);
};


module.exports = proto.external;

