import React from 'react';

import useRouter from 'hooks/useRouter';
import { useSelector, useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import * as actions from 'store/actions';
import Procurement from 'Workflows/Shared/CostManager/Procurement';

const TenantCostProcurement = () => {
  const dispatch = useDispatch();

  const [openEdit, setOpenEdit] = React.useState(false);
  const { query } = useRouter();

  const loaded = useSelector((state) => state.costManager.procurement.loaded);
  const loading = useSelector((state) => state.costManager.procurement.loading);
  const procurement = useSelector((state) => state.costManager.procurement.procurement);
  const error = useSelector((state) => state.costManager.procurement.error);

  const handleSave = (data) => {
    dispatch(actions.createTenantCostManagerProcurement(query.index, data))
      .then(() => {
        dispatch(actions.getTenantCostManagerProcurement(query.index));

        toast.success('Procurement created');
        setOpenEdit(false);
      })
      .catch((err) => {
        toast.error(err);
      });
  };

  const handleDelete = (providerId) => {
    dispatch(actions.deleteTenantCostManagerProcurement(providerId))
      .then(() => {
        dispatch(actions.getTenantCostManagerProcurement(query.index));

        toast.success('TenantCost Procurement created');
        setOpenEdit(false);
      })
      .catch((err) => {
        toast.error(err);
      });
  };

  return (
    <>
      <Procurement
        pathName={`Tenants/${query.id}/TenantCostManager/${query.index}`}
        handleSave={handleSave}
        handleDelete={handleDelete}
        loading={loading}
        loaded={loaded}
        error={error}
        procurement={procurement}
        openEdit={openEdit}
        setOpenEdit={setOpenEdit}
      />
    </>
  );
};

export default TenantCostProcurement;
