import React from 'react';

import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';

const useStyles = makeStyles((theme) => ({
  box: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    width: 220,
    marginRight: theme.spacing(1),
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    height: 80,
    [theme.breakpoints.down('md')]: {
      width: 250
    }
  },
  statsIcon: {
    color: theme.palette.primary.main,
    fontSize: 20
  },
  statsIconBox: {
    background: theme.palette.background.grey,
    width: 55,
    height: 55,
    borderRadius: theme.spacing(4),
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginRight: theme.spacing(3)
  },

  statsTitle: {
    fontFamily: theme.fonts.base,
    fontSize: theme.spacing(2)
  },
  statsValue: {
    color: '#7D7D7D',
    fontSize: theme.spacing(2)
  }
}));

const StatsIcon = ({ Icon, title, statsValue }) => {
  const classes = useStyles();

  return (
    <Box className={classes.box}>
      <Box className={classes.statsIconBox}>
        {Icon ? (
          <Icon className={classes.statsIcon} />
        ) : (
          <p className={classes.statsIcon} style={{ fontWeight: 700, padding: 20 }}>
            %
          </p>
        )}
      </Box>
      <Box display="flex" flexDirection="column" width={140}>
        <Typography className={classes.statsTitle}>{title}</Typography>
        <Typography className={classes.statsValue}>{statsValue}</Typography>
      </Box>
    </Box>
  );
};

export default React.memo(StatsIcon);

StatsIcon.defaultProps = {
  Icon: null
};

StatsIcon.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  Icon: PropTypes.any,
  title: PropTypes.string.isRequired,
  statsValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired
};
