import React from 'react';

import { CircularProgress } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

const useStyles = makeStyles((theme) => ({
  ctaButton: {
    height: 40,
    borderRadius: 60,
    width: theme.spacing(18),
    color: 'white',
    background: theme.palette.primary.main,
    '&:hover': {
      backgroundColor: theme.palette.secondary.main
    },
    fontWeight: 'bold',
    fontFamily: theme.fonts.base
  }
}));
const SaveButton = ({ disabled, onClick, loading, label, ...rest }) => {
  const classes = useStyles();
  const labelText = label || 'Save';

  return (
    <Button
      variant="contained"
      disabled={disabled || loading}
      className={classes.ctaButton}
      onClick={onClick}
      {...rest}
    >
      {loading ? <CircularProgress size={24} /> : labelText}
    </Button>
  );
};

export default SaveButton;

SaveButton.defaultProps = {
  disabled: false,
  loading: false,
  onClick: () => {},
  label: ''
};

SaveButton.propTypes = {
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  loading: PropTypes.bool,
  label: PropTypes.string
};
