import React from 'react';

import { formatZillions } from 'helpers/utils';
import PropTypes from 'prop-types';

const InfoWindow = (props) => {
  const { place } = props;

  const infoWindowStyle = {
    position: 'relative',
    bottom: 120,
    left: '-45px',
    minWidth: 180,
    backgroundColor: 'white',
    boxShadow: '0 2px 7px 1px rgba(0, 0, 0, 0.3)',
    padding: 10,
    fontSize: 14,
    zIndex: 100,
    whiteSpace: 'nowrap',
    borderRadius: 10
  };

  return (
    <div style={infoWindowStyle}>
      <div style={{ padding: '1px 0' }}>{`Name: ${place?.name}`}</div>
      <div style={{ padding: '1px 0' }}>{`Type: ${place?.type}`}</div>
      <div style={{ padding: '1px 0' }}>{`GLA: ${formatZillions(place?.glatotal)} sqm`}</div>
      <div style={{ padding: '1px 0' }}>{`Occupancy: ${place?.occupancy}%`}</div>
      <div style={{ padding: '1px 0' }}>
        {`Total rent: ${formatZillions(place?.rent.toFixed(2))}€`}
      </div>
    </div>
  );
};

InfoWindow.propTypes = {
  place: PropTypes.object.isRequired
};

const SimpleMarker = ({ show, place }) => {
  const markerStyle = {
    border: '1px solid white',
    borderRadius: '50%',
    height: 10,
    width: 10,
    backgroundColor: 'red',
    cursor: 'pointer',
    zIndex: 10
  };

  return (
    <>
      <div style={markerStyle} />
      {show && <InfoWindow place={place} />}
    </>
  );
};

export default SimpleMarker;

SimpleMarker.defaultProps = {
  show: false,
  place: {}
};

SimpleMarker.propTypes = {
  show: PropTypes.bool,
  place: PropTypes.object
};
