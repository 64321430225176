import * as actionTypes from '../actions/actionTypes';

export const OpexManagerInitialState = {
  opexManager: [],
  loading: false,
  loaded: false,
  error: null,
  opexManagerDetailsError: null,
  opexManagerDetailsLoading: false,
  opexManagerDetailsLoaded: false,
  opexManagerDetails: {},
  procurement: {
    procurement: [],
    loaded: false,
    loading: false,
    error: null
  },
  activityFeed: [],
  supplier: {
    assetid: '',
    city: '',
    companyname: '',
    contact: '',
    country: '',
    email: '',
    phone: '',
    postcode: '',
    street: ''
  },
  details: []
};

const reducer = (state = OpexManagerInitialState, action) => {
  switch (action.type) {
    case actionTypes.GET_OPEX_MANAGER_START:
      return {
        ...state,
        loading: true
      };
    case actionTypes.GET_OPEX_MANAGER_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        opexManager: action.opexManager
      };
    case actionTypes.GET_OPEX_MANAGER_FAIL:
      return {
        ...state,
        loading: false,
        loaded: true,
        error: action.error
      };

    case actionTypes.GET_OPEX_MANAGER_DETAILS_START:
      return {
        ...state,
        opexManagerDetailsLoading: true
      };
    case actionTypes.GET_OPEX_MANAGER_DETAILS_SUCCESS:
      return {
        ...state,
        opexManagerDetailsLoading: false,
        opexManagerDetailsLoaded: true,
        opexManagerDetails: action.details
      };
    case actionTypes.GET_OPEX_MANAGER_DETAILS_FAIL:
      return {
        ...state,
        opexManagerDetailsLoading: false,
        opexManagerDetailsLoaded: true,
        opexManagerDetailsError: action.error
      };

    case actionTypes.GET_OPEX_PROCUREMENT_START:
      return {
        ...state,
        procurement: {
          ...state.procurement,
          loading: true
        }
      };
    case actionTypes.GET_OPEX_PROCUREMENT_SUCCESS:
      return {
        ...state,
        procurement: {
          ...state.procurement,
          loading: false,
          loaded: true,
          error: null,
          procurement: action.procurement
        }
      };
    case actionTypes.GET_OPEX_PROCUREMENT_FAIL:
      return {
        ...state,
        procurement: {
          ...state.procurement,
          loading: false,
          loaded: true,
          error: action.error
        }
      };
    case actionTypes.GET_OPEX_SUPPLIER_DETAILS:
      return {
        ...state,
        supplier: action.supplier
      };
    case actionTypes.GET_OPEX_CONTRACT_DETAILS:
      return {
        ...state,
        details: action.details
      };
    case actionTypes.RESET_OPEX_COST_MANAGER: {
      return OpexManagerInitialState;
    }

    default:
      return state;
  }
};

export default reducer;
