import React from 'react';

import Box from '@material-ui/core/Box';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import CancelButton from 'components/Buttons/CancelButton';
import SaveButton from 'components/Buttons/SaveButton';
import Transition from 'components/Transition';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import useStyles from 'styles/forms';

import { closeModal } from './modalReducer';

const ModalWrapper = ({
  children,
  size,
  header,
  loading,
  disabled,
  handleSave,
  label = 'Save'
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const handleClose = () => {
    dispatch(closeModal());
  };

  return (
    <Dialog
      open
      onClose={handleClose}
      aria-labelledby="form-dialog-modal"
      keepMounted
      TransitionComponent={Transition}
      classes={{ paper: classes.paper }}
      maxWidth={size}
    >
      <Box
        bgcolor="#2C324D"
        p={3}
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        mb={4}
        className={classes.responsive}
      >
        <Typography className={classes.assetTitle}>{header}</Typography>
        <CloseIcon className={classes.closeIcon} onClick={handleClose} />
      </Box>
      <DialogContent>{children}</DialogContent>
      <DialogActions>
        <Box p={2} display="flex">
          <CancelButton onClick={handleClose} />
          <SaveButton onClick={handleSave} disabled={disabled} loading={loading} label={label} />
        </Box>
      </DialogActions>
    </Dialog>
  );
};

export default ModalWrapper;
ModalWrapper.propTypes = {
  size: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  header: PropTypes.string.isRequired,
  loading: PropTypes.bool.isRequired,
  disabled: PropTypes.bool.isRequired,
  handleSave: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired
};
