import { useCallback, useEffect, useState } from 'react';

import { metaData, getEnviroment } from 'config/config';

import {
  AssetActivityFeedsByComponentRequest,
  AssetActivityFeedServicePromiseClient
} from '../api/asset_grpc_web_pb';
import {
  TenantActivityFeedServicePromiseClient,
  TenantActivityFeedsByComponentRequest
} from '../api/tenant_grpc_web_pb';

const ActivityFeedService = new AssetActivityFeedServicePromiseClient(
  getEnviroment(localStorage.getItem('env'))
);

const TenantActivityFeedService = new TenantActivityFeedServicePromiseClient(
  getEnviroment(localStorage.getItem('env'))
);

export default function useActivityFeed({ id, tenantLevel = false }) {
  const service = tenantLevel ? TenantActivityFeedService : ActivityFeedService;

  const [loading, setLoading] = useState(false);
  const [activityFeed, setActivityFeed] = useState([]);
  const [error, setError] = useState('');

  const getActivityFeed = useCallback(async () => {
    setLoading(true);
    setError(null);

    const request = tenantLevel
      ? new TenantActivityFeedsByComponentRequest()
      : new AssetActivityFeedsByComponentRequest();
    request.setId(id);

    try {
      const result = await service
        .getActivityFeedsByComponent(request, metaData())
        .then((res) => res.toObject().activityfeedsList);

      setActivityFeed(result);
    } catch (err) {
      setError(err);
    } finally {
      setLoading(false);
    }
  }, [id]);

  useEffect(() => {
    if (!id) return;
    getActivityFeed();
  }, [id]);

  return { fetch: getActivityFeed, loading, activityFeed, error };
}
