import React from 'react';

import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { format } from 'date-fns';
import PropTypes from 'prop-types';

import useStyles from '../footfallstyles';

import EnterFootfall from './EnterFootfall';

const FootFallItem = ({ item, handleSave }) => {
  const classes = useStyles();

  return (
    <Box
      minHeight={80}
      pt={3}
      pb={3}
      borderTop="1px solid #D9D9D9"
      display="flex"
      justifyContent="space-between"
      alignItems="center"
    >
      <Typography className={classes.footfallitem}>
        {format(new Date(item.name), 'eee dd MMM')}
      </Typography>

      <EnterFootfall form={item.footfall} date={item.name} handleSave={handleSave} id={item.id} />
    </Box>
  );
};

export default FootFallItem;

FootFallItem.propTypes = {
  item: PropTypes.objectOf(PropTypes.any).isRequired,
  handleSave: PropTypes.func.isRequired
};
