/* eslint-disable import/extensions */
import store from '../store/store';

const dev = 'https://dev-api.ad.recognyte.com';
const test = 'https://test-api.ad.resoluteplatform.com';
const prod = 'https://prod-api.ad.recognyte.com';
const preprod = 'https://pre-prod-api.ad.recognyte.com';
const domainName = window.location.hostname;

export const getEnviroment = (enviroment) => {
  switch (enviroment) {
    case 'dev':
      return dev;
    case 'test':
      return test;
    case 'preprod':
      return preprod;
    case 'prod':
      return prod;
    case 'demo':
      return test;
    default:
      return dev;
  }
};

export const metaData = () => {
  const { token } = store.getState().user.auth;

  return { Authorization: `${domainName.includes('dev') ? 'Bearer' : ''} ${token}` };
};

const empty = require('google-protobuf/google/protobuf/empty_pb.js');

export const Empty = empty.Empty.deserializeBinary();
