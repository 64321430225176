// source: external/alert.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

var external_filters_pb = require('./filters_pb.js');
goog.object.extend(proto, external_filters_pb);
var google_protobuf_empty_pb = require('google-protobuf/google/protobuf/empty_pb.js');
goog.object.extend(proto, google_protobuf_empty_pb);
goog.exportSymbol('proto.external.Alert', null, global);
goog.exportSymbol('proto.external.Alert.Category', null, global);
goog.exportSymbol('proto.external.Alert.FacilityManagerTicketRaised', null, global);
goog.exportSymbol('proto.external.Alert.PayloadCase', null, global);
goog.exportSymbol('proto.external.Alert.Type', null, global);
goog.exportSymbol('proto.external.AlertAction', null, global);
goog.exportSymbol('proto.external.AlertAction.Action', null, global);
goog.exportSymbol('proto.external.AlertCategory', null, global);
goog.exportSymbol('proto.external.AlertReport', null, global);
goog.exportSymbol('proto.external.AlertReport.FacilityManagerTicketRaised', null, global);
goog.exportSymbol('proto.external.AlertReport.LeasePayload', null, global);
goog.exportSymbol('proto.external.AlertReport.NonOpexManagerPayload', null, global);
goog.exportSymbol('proto.external.AlertReport.OpexManagerPayload', null, global);
goog.exportSymbol('proto.external.AlertReport.PayloadCase', null, global);
goog.exportSymbol('proto.external.AlertReport.VacantUnitsPayload', null, global);
goog.exportSymbol('proto.external.AlertType', null, global);
goog.exportSymbol('proto.external.GetAlertsCountRequest', null, global);
goog.exportSymbol('proto.external.GetAlertsCountResponse', null, global);
goog.exportSymbol('proto.external.GetAllResponse', null, global);
goog.exportSymbol('proto.external.HideRequest', null, global);
goog.exportSymbol('proto.external.ListAlertsRequest', null, global);
goog.exportSymbol('proto.external.ListAlertsResponse', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.external.GetAllResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.external.GetAllResponse.repeatedFields_, null);
};
goog.inherits(proto.external.GetAllResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.external.GetAllResponse.displayName = 'proto.external.GetAllResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.external.AlertAction = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.external.AlertAction, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.external.AlertAction.displayName = 'proto.external.AlertAction';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.external.Alert = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.external.Alert.oneofGroups_);
};
goog.inherits(proto.external.Alert, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.external.Alert.displayName = 'proto.external.Alert';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.external.Alert.FacilityManagerTicketRaised = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.external.Alert.FacilityManagerTicketRaised, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.external.Alert.FacilityManagerTicketRaised.displayName = 'proto.external.Alert.FacilityManagerTicketRaised';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.external.ListAlertsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.external.ListAlertsRequest.repeatedFields_, null);
};
goog.inherits(proto.external.ListAlertsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.external.ListAlertsRequest.displayName = 'proto.external.ListAlertsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.external.ListAlertsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.external.ListAlertsResponse.repeatedFields_, null);
};
goog.inherits(proto.external.ListAlertsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.external.ListAlertsResponse.displayName = 'proto.external.ListAlertsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.external.AlertReport = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.external.AlertReport.oneofGroups_);
};
goog.inherits(proto.external.AlertReport, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.external.AlertReport.displayName = 'proto.external.AlertReport';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.external.AlertReport.FacilityManagerTicketRaised = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.external.AlertReport.FacilityManagerTicketRaised, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.external.AlertReport.FacilityManagerTicketRaised.displayName = 'proto.external.AlertReport.FacilityManagerTicketRaised';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.external.AlertReport.OpexManagerPayload = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.external.AlertReport.OpexManagerPayload, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.external.AlertReport.OpexManagerPayload.displayName = 'proto.external.AlertReport.OpexManagerPayload';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.external.AlertReport.NonOpexManagerPayload = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.external.AlertReport.NonOpexManagerPayload, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.external.AlertReport.NonOpexManagerPayload.displayName = 'proto.external.AlertReport.NonOpexManagerPayload';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.external.AlertReport.VacantUnitsPayload = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.external.AlertReport.VacantUnitsPayload, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.external.AlertReport.VacantUnitsPayload.displayName = 'proto.external.AlertReport.VacantUnitsPayload';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.external.AlertReport.LeasePayload = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.external.AlertReport.LeasePayload, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.external.AlertReport.LeasePayload.displayName = 'proto.external.AlertReport.LeasePayload';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.external.HideRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.external.HideRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.external.HideRequest.displayName = 'proto.external.HideRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.external.GetAlertsCountRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.external.GetAlertsCountRequest.repeatedFields_, null);
};
goog.inherits(proto.external.GetAlertsCountRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.external.GetAlertsCountRequest.displayName = 'proto.external.GetAlertsCountRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.external.GetAlertsCountResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.external.GetAlertsCountResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.external.GetAlertsCountResponse.displayName = 'proto.external.GetAlertsCountResponse';
}

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.external.GetAllResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.external.GetAllResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.external.GetAllResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.external.GetAllResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.external.GetAllResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    alertsList: jspb.Message.toObjectList(msg.getAlertsList(),
    proto.external.Alert.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.external.GetAllResponse}
 */
proto.external.GetAllResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.external.GetAllResponse;
  return proto.external.GetAllResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.external.GetAllResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.external.GetAllResponse}
 */
proto.external.GetAllResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.external.Alert;
      reader.readMessage(value,proto.external.Alert.deserializeBinaryFromReader);
      msg.addAlerts(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.external.GetAllResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.external.GetAllResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.external.GetAllResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.external.GetAllResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAlertsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.external.Alert.serializeBinaryToWriter
    );
  }
};


/**
 * repeated Alert alerts = 1;
 * @return {!Array<!proto.external.Alert>}
 */
proto.external.GetAllResponse.prototype.getAlertsList = function() {
  return /** @type{!Array<!proto.external.Alert>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.external.Alert, 1));
};


/**
 * @param {!Array<!proto.external.Alert>} value
 * @return {!proto.external.GetAllResponse} returns this
*/
proto.external.GetAllResponse.prototype.setAlertsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.external.Alert=} opt_value
 * @param {number=} opt_index
 * @return {!proto.external.Alert}
 */
proto.external.GetAllResponse.prototype.addAlerts = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.external.Alert, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.external.GetAllResponse} returns this
 */
proto.external.GetAllResponse.prototype.clearAlertsList = function() {
  return this.setAlertsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.external.AlertAction.prototype.toObject = function(opt_includeInstance) {
  return proto.external.AlertAction.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.external.AlertAction} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.external.AlertAction.toObject = function(includeInstance, msg) {
  var f, obj = {
    action: jspb.Message.getFieldWithDefault(msg, 1, 0),
    id: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.external.AlertAction}
 */
proto.external.AlertAction.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.external.AlertAction;
  return proto.external.AlertAction.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.external.AlertAction} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.external.AlertAction}
 */
proto.external.AlertAction.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.external.AlertAction.Action} */ (reader.readEnum());
      msg.setAction(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.external.AlertAction.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.external.AlertAction.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.external.AlertAction} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.external.AlertAction.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAction();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * @enum {number}
 */
proto.external.AlertAction.Action = {
  HIDE_ALERT: 0
};

/**
 * optional Action action = 1;
 * @return {!proto.external.AlertAction.Action}
 */
proto.external.AlertAction.prototype.getAction = function() {
  return /** @type {!proto.external.AlertAction.Action} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.external.AlertAction.Action} value
 * @return {!proto.external.AlertAction} returns this
 */
proto.external.AlertAction.prototype.setAction = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional string id = 2;
 * @return {string}
 */
proto.external.AlertAction.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.external.AlertAction} returns this
 */
proto.external.AlertAction.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.external.Alert.oneofGroups_ = [[13]];

/**
 * @enum {number}
 */
proto.external.Alert.PayloadCase = {
  PAYLOAD_NOT_SET: 0,
  TICKET: 13
};

/**
 * @return {proto.external.Alert.PayloadCase}
 */
proto.external.Alert.prototype.getPayloadCase = function() {
  return /** @type {proto.external.Alert.PayloadCase} */(jspb.Message.computeOneofCase(this, proto.external.Alert.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.external.Alert.prototype.toObject = function(opt_includeInstance) {
  return proto.external.Alert.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.external.Alert} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.external.Alert.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    entityid: jspb.Message.getFieldWithDefault(msg, 2, ""),
    name: jspb.Message.getFieldWithDefault(msg, 3, ""),
    date: jspb.Message.getFieldWithDefault(msg, 4, ""),
    portfolioname: jspb.Message.getFieldWithDefault(msg, 5, ""),
    portfolioid: jspb.Message.getFieldWithDefault(msg, 6, ""),
    assetname: jspb.Message.getFieldWithDefault(msg, 7, ""),
    assetid: jspb.Message.getFieldWithDefault(msg, 8, ""),
    tenantname: jspb.Message.getFieldWithDefault(msg, 9, ""),
    tenantid: jspb.Message.getFieldWithDefault(msg, 10, ""),
    category: jspb.Message.getFieldWithDefault(msg, 11, 0),
    type: jspb.Message.getFieldWithDefault(msg, 12, 0),
    ticket: (f = msg.getTicket()) && proto.external.Alert.FacilityManagerTicketRaised.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.external.Alert}
 */
proto.external.Alert.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.external.Alert;
  return proto.external.Alert.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.external.Alert} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.external.Alert}
 */
proto.external.Alert.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setEntityid(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setDate(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setPortfolioname(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setPortfolioid(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setAssetname(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setAssetid(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setTenantname(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setTenantid(value);
      break;
    case 11:
      var value = /** @type {!proto.external.Alert.Category} */ (reader.readEnum());
      msg.setCategory(value);
      break;
    case 12:
      var value = /** @type {!proto.external.Alert.Type} */ (reader.readEnum());
      msg.setType(value);
      break;
    case 13:
      var value = new proto.external.Alert.FacilityManagerTicketRaised;
      reader.readMessage(value,proto.external.Alert.FacilityManagerTicketRaised.deserializeBinaryFromReader);
      msg.setTicket(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.external.Alert.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.external.Alert.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.external.Alert} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.external.Alert.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getEntityid();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getDate();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getPortfolioname();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getPortfolioid();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getAssetname();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getAssetid();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getTenantname();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getTenantid();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getCategory();
  if (f !== 0.0) {
    writer.writeEnum(
      11,
      f
    );
  }
  f = message.getType();
  if (f !== 0.0) {
    writer.writeEnum(
      12,
      f
    );
  }
  f = message.getTicket();
  if (f != null) {
    writer.writeMessage(
      13,
      f,
      proto.external.Alert.FacilityManagerTicketRaised.serializeBinaryToWriter
    );
  }
};


/**
 * @enum {number}
 */
proto.external.Alert.Category = {
  ASSET_MANAGEMENT_CATEGORY: 0,
  PROPERTY_MANAGEMENT_CATEGORY: 1
};

/**
 * @enum {number}
 */
proto.external.Alert.Type = {
  TENANT_CONTRACT_EXPIRATION: 0,
  FACILITY_MANAGER_TICKET_RAISED: 1
};




if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.external.Alert.FacilityManagerTicketRaised.prototype.toObject = function(opt_includeInstance) {
  return proto.external.Alert.FacilityManagerTicketRaised.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.external.Alert.FacilityManagerTicketRaised} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.external.Alert.FacilityManagerTicketRaised.toObject = function(includeInstance, msg) {
  var f, obj = {
    query: jspb.Message.getFieldWithDefault(msg, 1, ""),
    datecreated: jspb.Message.getFieldWithDefault(msg, 2, 0),
    datedue: jspb.Message.getFieldWithDefault(msg, 3, 0),
    assignedto: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.external.Alert.FacilityManagerTicketRaised}
 */
proto.external.Alert.FacilityManagerTicketRaised.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.external.Alert.FacilityManagerTicketRaised;
  return proto.external.Alert.FacilityManagerTicketRaised.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.external.Alert.FacilityManagerTicketRaised} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.external.Alert.FacilityManagerTicketRaised}
 */
proto.external.Alert.FacilityManagerTicketRaised.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setQuery(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setDatecreated(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setDatedue(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setAssignedto(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.external.Alert.FacilityManagerTicketRaised.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.external.Alert.FacilityManagerTicketRaised.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.external.Alert.FacilityManagerTicketRaised} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.external.Alert.FacilityManagerTicketRaised.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getQuery();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getDatecreated();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getDatedue();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = message.getAssignedto();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional string query = 1;
 * @return {string}
 */
proto.external.Alert.FacilityManagerTicketRaised.prototype.getQuery = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.external.Alert.FacilityManagerTicketRaised} returns this
 */
proto.external.Alert.FacilityManagerTicketRaised.prototype.setQuery = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int64 dateCreated = 2;
 * @return {number}
 */
proto.external.Alert.FacilityManagerTicketRaised.prototype.getDatecreated = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.external.Alert.FacilityManagerTicketRaised} returns this
 */
proto.external.Alert.FacilityManagerTicketRaised.prototype.setDatecreated = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int64 dateDue = 3;
 * @return {number}
 */
proto.external.Alert.FacilityManagerTicketRaised.prototype.getDatedue = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.external.Alert.FacilityManagerTicketRaised} returns this
 */
proto.external.Alert.FacilityManagerTicketRaised.prototype.setDatedue = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional string assignedTo = 4;
 * @return {string}
 */
proto.external.Alert.FacilityManagerTicketRaised.prototype.getAssignedto = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.external.Alert.FacilityManagerTicketRaised} returns this
 */
proto.external.Alert.FacilityManagerTicketRaised.prototype.setAssignedto = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.external.Alert.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.external.Alert} returns this
 */
proto.external.Alert.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string entityID = 2;
 * @return {string}
 */
proto.external.Alert.prototype.getEntityid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.external.Alert} returns this
 */
proto.external.Alert.prototype.setEntityid = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string name = 3;
 * @return {string}
 */
proto.external.Alert.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.external.Alert} returns this
 */
proto.external.Alert.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string date = 4;
 * @return {string}
 */
proto.external.Alert.prototype.getDate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.external.Alert} returns this
 */
proto.external.Alert.prototype.setDate = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string portfolioName = 5;
 * @return {string}
 */
proto.external.Alert.prototype.getPortfolioname = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.external.Alert} returns this
 */
proto.external.Alert.prototype.setPortfolioname = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string portfolioID = 6;
 * @return {string}
 */
proto.external.Alert.prototype.getPortfolioid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.external.Alert} returns this
 */
proto.external.Alert.prototype.setPortfolioid = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string assetName = 7;
 * @return {string}
 */
proto.external.Alert.prototype.getAssetname = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.external.Alert} returns this
 */
proto.external.Alert.prototype.setAssetname = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string assetID = 8;
 * @return {string}
 */
proto.external.Alert.prototype.getAssetid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.external.Alert} returns this
 */
proto.external.Alert.prototype.setAssetid = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string tenantName = 9;
 * @return {string}
 */
proto.external.Alert.prototype.getTenantname = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.external.Alert} returns this
 */
proto.external.Alert.prototype.setTenantname = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional string tenantID = 10;
 * @return {string}
 */
proto.external.Alert.prototype.getTenantid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.external.Alert} returns this
 */
proto.external.Alert.prototype.setTenantid = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional Category category = 11;
 * @return {!proto.external.Alert.Category}
 */
proto.external.Alert.prototype.getCategory = function() {
  return /** @type {!proto.external.Alert.Category} */ (jspb.Message.getFieldWithDefault(this, 11, 0));
};


/**
 * @param {!proto.external.Alert.Category} value
 * @return {!proto.external.Alert} returns this
 */
proto.external.Alert.prototype.setCategory = function(value) {
  return jspb.Message.setProto3EnumField(this, 11, value);
};


/**
 * optional Type type = 12;
 * @return {!proto.external.Alert.Type}
 */
proto.external.Alert.prototype.getType = function() {
  return /** @type {!proto.external.Alert.Type} */ (jspb.Message.getFieldWithDefault(this, 12, 0));
};


/**
 * @param {!proto.external.Alert.Type} value
 * @return {!proto.external.Alert} returns this
 */
proto.external.Alert.prototype.setType = function(value) {
  return jspb.Message.setProto3EnumField(this, 12, value);
};


/**
 * optional FacilityManagerTicketRaised ticket = 13;
 * @return {?proto.external.Alert.FacilityManagerTicketRaised}
 */
proto.external.Alert.prototype.getTicket = function() {
  return /** @type{?proto.external.Alert.FacilityManagerTicketRaised} */ (
    jspb.Message.getWrapperField(this, proto.external.Alert.FacilityManagerTicketRaised, 13));
};


/**
 * @param {?proto.external.Alert.FacilityManagerTicketRaised|undefined} value
 * @return {!proto.external.Alert} returns this
*/
proto.external.Alert.prototype.setTicket = function(value) {
  return jspb.Message.setOneofWrapperField(this, 13, proto.external.Alert.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.external.Alert} returns this
 */
proto.external.Alert.prototype.clearTicket = function() {
  return this.setTicket(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.external.Alert.prototype.hasTicket = function() {
  return jspb.Message.getField(this, 13) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.external.ListAlertsRequest.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.external.ListAlertsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.external.ListAlertsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.external.ListAlertsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.external.ListAlertsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    filtersList: jspb.Message.toObjectList(msg.getFiltersList(),
    external_filters_pb.Filter.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.external.ListAlertsRequest}
 */
proto.external.ListAlertsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.external.ListAlertsRequest;
  return proto.external.ListAlertsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.external.ListAlertsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.external.ListAlertsRequest}
 */
proto.external.ListAlertsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new external_filters_pb.Filter;
      reader.readMessage(value,external_filters_pb.Filter.deserializeBinaryFromReader);
      msg.addFilters(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.external.ListAlertsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.external.ListAlertsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.external.ListAlertsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.external.ListAlertsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFiltersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      external_filters_pb.Filter.serializeBinaryToWriter
    );
  }
};


/**
 * repeated Filter filters = 1;
 * @return {!Array<!proto.external.Filter>}
 */
proto.external.ListAlertsRequest.prototype.getFiltersList = function() {
  return /** @type{!Array<!proto.external.Filter>} */ (
    jspb.Message.getRepeatedWrapperField(this, external_filters_pb.Filter, 1));
};


/**
 * @param {!Array<!proto.external.Filter>} value
 * @return {!proto.external.ListAlertsRequest} returns this
*/
proto.external.ListAlertsRequest.prototype.setFiltersList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.external.Filter=} opt_value
 * @param {number=} opt_index
 * @return {!proto.external.Filter}
 */
proto.external.ListAlertsRequest.prototype.addFilters = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.external.Filter, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.external.ListAlertsRequest} returns this
 */
proto.external.ListAlertsRequest.prototype.clearFiltersList = function() {
  return this.setFiltersList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.external.ListAlertsResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.external.ListAlertsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.external.ListAlertsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.external.ListAlertsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.external.ListAlertsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    alertsList: jspb.Message.toObjectList(msg.getAlertsList(),
    proto.external.AlertReport.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.external.ListAlertsResponse}
 */
proto.external.ListAlertsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.external.ListAlertsResponse;
  return proto.external.ListAlertsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.external.ListAlertsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.external.ListAlertsResponse}
 */
proto.external.ListAlertsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.external.AlertReport;
      reader.readMessage(value,proto.external.AlertReport.deserializeBinaryFromReader);
      msg.addAlerts(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.external.ListAlertsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.external.ListAlertsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.external.ListAlertsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.external.ListAlertsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAlertsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.external.AlertReport.serializeBinaryToWriter
    );
  }
};


/**
 * repeated AlertReport alerts = 1;
 * @return {!Array<!proto.external.AlertReport>}
 */
proto.external.ListAlertsResponse.prototype.getAlertsList = function() {
  return /** @type{!Array<!proto.external.AlertReport>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.external.AlertReport, 1));
};


/**
 * @param {!Array<!proto.external.AlertReport>} value
 * @return {!proto.external.ListAlertsResponse} returns this
*/
proto.external.ListAlertsResponse.prototype.setAlertsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.external.AlertReport=} opt_value
 * @param {number=} opt_index
 * @return {!proto.external.AlertReport}
 */
proto.external.ListAlertsResponse.prototype.addAlerts = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.external.AlertReport, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.external.ListAlertsResponse} returns this
 */
proto.external.ListAlertsResponse.prototype.clearAlertsList = function() {
  return this.setAlertsList([]);
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.external.AlertReport.oneofGroups_ = [[12,13,14,15,16]];

/**
 * @enum {number}
 */
proto.external.AlertReport.PayloadCase = {
  PAYLOAD_NOT_SET: 0,
  TICKET: 12,
  OPEX: 13,
  NONOPEX: 14,
  VACANTUNITS: 15,
  LEASE: 16
};

/**
 * @return {proto.external.AlertReport.PayloadCase}
 */
proto.external.AlertReport.prototype.getPayloadCase = function() {
  return /** @type {proto.external.AlertReport.PayloadCase} */(jspb.Message.computeOneofCase(this, proto.external.AlertReport.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.external.AlertReport.prototype.toObject = function(opt_includeInstance) {
  return proto.external.AlertReport.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.external.AlertReport} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.external.AlertReport.toObject = function(includeInstance, msg) {
  var f, obj = {
    entityid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    date: jspb.Message.getFieldWithDefault(msg, 3, ""),
    portfolioname: jspb.Message.getFieldWithDefault(msg, 4, ""),
    portfolioid: jspb.Message.getFieldWithDefault(msg, 5, ""),
    assetname: jspb.Message.getFieldWithDefault(msg, 6, ""),
    assetid: jspb.Message.getFieldWithDefault(msg, 7, ""),
    tenantname: jspb.Message.getFieldWithDefault(msg, 8, ""),
    tenantid: jspb.Message.getFieldWithDefault(msg, 9, ""),
    category: jspb.Message.getFieldWithDefault(msg, 10, 0),
    type: jspb.Message.getFieldWithDefault(msg, 11, 0),
    ticket: (f = msg.getTicket()) && proto.external.AlertReport.FacilityManagerTicketRaised.toObject(includeInstance, f),
    opex: (f = msg.getOpex()) && proto.external.AlertReport.OpexManagerPayload.toObject(includeInstance, f),
    nonopex: (f = msg.getNonopex()) && proto.external.AlertReport.NonOpexManagerPayload.toObject(includeInstance, f),
    vacantunits: (f = msg.getVacantunits()) && proto.external.AlertReport.VacantUnitsPayload.toObject(includeInstance, f),
    lease: (f = msg.getLease()) && proto.external.AlertReport.LeasePayload.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.external.AlertReport}
 */
proto.external.AlertReport.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.external.AlertReport;
  return proto.external.AlertReport.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.external.AlertReport} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.external.AlertReport}
 */
proto.external.AlertReport.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setEntityid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setDate(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setPortfolioname(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setPortfolioid(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setAssetname(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setAssetid(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setTenantname(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setTenantid(value);
      break;
    case 10:
      var value = /** @type {!proto.external.AlertCategory} */ (reader.readEnum());
      msg.setCategory(value);
      break;
    case 11:
      var value = /** @type {!proto.external.AlertType} */ (reader.readEnum());
      msg.setType(value);
      break;
    case 12:
      var value = new proto.external.AlertReport.FacilityManagerTicketRaised;
      reader.readMessage(value,proto.external.AlertReport.FacilityManagerTicketRaised.deserializeBinaryFromReader);
      msg.setTicket(value);
      break;
    case 13:
      var value = new proto.external.AlertReport.OpexManagerPayload;
      reader.readMessage(value,proto.external.AlertReport.OpexManagerPayload.deserializeBinaryFromReader);
      msg.setOpex(value);
      break;
    case 14:
      var value = new proto.external.AlertReport.NonOpexManagerPayload;
      reader.readMessage(value,proto.external.AlertReport.NonOpexManagerPayload.deserializeBinaryFromReader);
      msg.setNonopex(value);
      break;
    case 15:
      var value = new proto.external.AlertReport.VacantUnitsPayload;
      reader.readMessage(value,proto.external.AlertReport.VacantUnitsPayload.deserializeBinaryFromReader);
      msg.setVacantunits(value);
      break;
    case 16:
      var value = new proto.external.AlertReport.LeasePayload;
      reader.readMessage(value,proto.external.AlertReport.LeasePayload.deserializeBinaryFromReader);
      msg.setLease(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.external.AlertReport.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.external.AlertReport.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.external.AlertReport} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.external.AlertReport.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEntityid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getDate();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getPortfolioname();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getPortfolioid();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getAssetname();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getAssetid();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getTenantname();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getTenantid();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getCategory();
  if (f !== 0.0) {
    writer.writeEnum(
      10,
      f
    );
  }
  f = message.getType();
  if (f !== 0.0) {
    writer.writeEnum(
      11,
      f
    );
  }
  f = message.getTicket();
  if (f != null) {
    writer.writeMessage(
      12,
      f,
      proto.external.AlertReport.FacilityManagerTicketRaised.serializeBinaryToWriter
    );
  }
  f = message.getOpex();
  if (f != null) {
    writer.writeMessage(
      13,
      f,
      proto.external.AlertReport.OpexManagerPayload.serializeBinaryToWriter
    );
  }
  f = message.getNonopex();
  if (f != null) {
    writer.writeMessage(
      14,
      f,
      proto.external.AlertReport.NonOpexManagerPayload.serializeBinaryToWriter
    );
  }
  f = message.getVacantunits();
  if (f != null) {
    writer.writeMessage(
      15,
      f,
      proto.external.AlertReport.VacantUnitsPayload.serializeBinaryToWriter
    );
  }
  f = message.getLease();
  if (f != null) {
    writer.writeMessage(
      16,
      f,
      proto.external.AlertReport.LeasePayload.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.external.AlertReport.FacilityManagerTicketRaised.prototype.toObject = function(opt_includeInstance) {
  return proto.external.AlertReport.FacilityManagerTicketRaised.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.external.AlertReport.FacilityManagerTicketRaised} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.external.AlertReport.FacilityManagerTicketRaised.toObject = function(includeInstance, msg) {
  var f, obj = {
    query: jspb.Message.getFieldWithDefault(msg, 1, ""),
    datecreated: jspb.Message.getFieldWithDefault(msg, 2, 0),
    datedue: jspb.Message.getFieldWithDefault(msg, 3, 0),
    assignedto: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.external.AlertReport.FacilityManagerTicketRaised}
 */
proto.external.AlertReport.FacilityManagerTicketRaised.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.external.AlertReport.FacilityManagerTicketRaised;
  return proto.external.AlertReport.FacilityManagerTicketRaised.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.external.AlertReport.FacilityManagerTicketRaised} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.external.AlertReport.FacilityManagerTicketRaised}
 */
proto.external.AlertReport.FacilityManagerTicketRaised.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setQuery(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setDatecreated(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setDatedue(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setAssignedto(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.external.AlertReport.FacilityManagerTicketRaised.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.external.AlertReport.FacilityManagerTicketRaised.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.external.AlertReport.FacilityManagerTicketRaised} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.external.AlertReport.FacilityManagerTicketRaised.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getQuery();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getDatecreated();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getDatedue();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = message.getAssignedto();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional string query = 1;
 * @return {string}
 */
proto.external.AlertReport.FacilityManagerTicketRaised.prototype.getQuery = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.external.AlertReport.FacilityManagerTicketRaised} returns this
 */
proto.external.AlertReport.FacilityManagerTicketRaised.prototype.setQuery = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int64 dateCreated = 2;
 * @return {number}
 */
proto.external.AlertReport.FacilityManagerTicketRaised.prototype.getDatecreated = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.external.AlertReport.FacilityManagerTicketRaised} returns this
 */
proto.external.AlertReport.FacilityManagerTicketRaised.prototype.setDatecreated = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int64 dateDue = 3;
 * @return {number}
 */
proto.external.AlertReport.FacilityManagerTicketRaised.prototype.getDatedue = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.external.AlertReport.FacilityManagerTicketRaised} returns this
 */
proto.external.AlertReport.FacilityManagerTicketRaised.prototype.setDatedue = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional string assignedTo = 4;
 * @return {string}
 */
proto.external.AlertReport.FacilityManagerTicketRaised.prototype.getAssignedto = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.external.AlertReport.FacilityManagerTicketRaised} returns this
 */
proto.external.AlertReport.FacilityManagerTicketRaised.prototype.setAssignedto = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.external.AlertReport.OpexManagerPayload.prototype.toObject = function(opt_includeInstance) {
  return proto.external.AlertReport.OpexManagerPayload.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.external.AlertReport.OpexManagerPayload} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.external.AlertReport.OpexManagerPayload.toObject = function(includeInstance, msg) {
  var f, obj = {
    opexname: jspb.Message.getFieldWithDefault(msg, 1, ""),
    supplier: jspb.Message.getFieldWithDefault(msg, 2, ""),
    contractenddate: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.external.AlertReport.OpexManagerPayload}
 */
proto.external.AlertReport.OpexManagerPayload.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.external.AlertReport.OpexManagerPayload;
  return proto.external.AlertReport.OpexManagerPayload.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.external.AlertReport.OpexManagerPayload} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.external.AlertReport.OpexManagerPayload}
 */
proto.external.AlertReport.OpexManagerPayload.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setOpexname(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setSupplier(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setContractenddate(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.external.AlertReport.OpexManagerPayload.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.external.AlertReport.OpexManagerPayload.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.external.AlertReport.OpexManagerPayload} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.external.AlertReport.OpexManagerPayload.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOpexname();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSupplier();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getContractenddate();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
};


/**
 * optional string opexName = 1;
 * @return {string}
 */
proto.external.AlertReport.OpexManagerPayload.prototype.getOpexname = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.external.AlertReport.OpexManagerPayload} returns this
 */
proto.external.AlertReport.OpexManagerPayload.prototype.setOpexname = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string supplier = 2;
 * @return {string}
 */
proto.external.AlertReport.OpexManagerPayload.prototype.getSupplier = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.external.AlertReport.OpexManagerPayload} returns this
 */
proto.external.AlertReport.OpexManagerPayload.prototype.setSupplier = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int64 contractEndDate = 3;
 * @return {number}
 */
proto.external.AlertReport.OpexManagerPayload.prototype.getContractenddate = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.external.AlertReport.OpexManagerPayload} returns this
 */
proto.external.AlertReport.OpexManagerPayload.prototype.setContractenddate = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.external.AlertReport.NonOpexManagerPayload.prototype.toObject = function(opt_includeInstance) {
  return proto.external.AlertReport.NonOpexManagerPayload.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.external.AlertReport.NonOpexManagerPayload} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.external.AlertReport.NonOpexManagerPayload.toObject = function(includeInstance, msg) {
  var f, obj = {
    costmanagername: jspb.Message.getFieldWithDefault(msg, 1, ""),
    supplier: jspb.Message.getFieldWithDefault(msg, 2, ""),
    contractenddate: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.external.AlertReport.NonOpexManagerPayload}
 */
proto.external.AlertReport.NonOpexManagerPayload.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.external.AlertReport.NonOpexManagerPayload;
  return proto.external.AlertReport.NonOpexManagerPayload.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.external.AlertReport.NonOpexManagerPayload} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.external.AlertReport.NonOpexManagerPayload}
 */
proto.external.AlertReport.NonOpexManagerPayload.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCostmanagername(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setSupplier(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setContractenddate(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.external.AlertReport.NonOpexManagerPayload.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.external.AlertReport.NonOpexManagerPayload.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.external.AlertReport.NonOpexManagerPayload} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.external.AlertReport.NonOpexManagerPayload.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCostmanagername();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSupplier();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getContractenddate();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
};


/**
 * optional string costManagerName = 1;
 * @return {string}
 */
proto.external.AlertReport.NonOpexManagerPayload.prototype.getCostmanagername = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.external.AlertReport.NonOpexManagerPayload} returns this
 */
proto.external.AlertReport.NonOpexManagerPayload.prototype.setCostmanagername = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string supplier = 2;
 * @return {string}
 */
proto.external.AlertReport.NonOpexManagerPayload.prototype.getSupplier = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.external.AlertReport.NonOpexManagerPayload} returns this
 */
proto.external.AlertReport.NonOpexManagerPayload.prototype.setSupplier = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int64 contractEndDate = 3;
 * @return {number}
 */
proto.external.AlertReport.NonOpexManagerPayload.prototype.getContractenddate = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.external.AlertReport.NonOpexManagerPayload} returns this
 */
proto.external.AlertReport.NonOpexManagerPayload.prototype.setContractenddate = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.external.AlertReport.VacantUnitsPayload.prototype.toObject = function(opt_includeInstance) {
  return proto.external.AlertReport.VacantUnitsPayload.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.external.AlertReport.VacantUnitsPayload} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.external.AlertReport.VacantUnitsPayload.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, ""),
    gla: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.external.AlertReport.VacantUnitsPayload}
 */
proto.external.AlertReport.VacantUnitsPayload.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.external.AlertReport.VacantUnitsPayload;
  return proto.external.AlertReport.VacantUnitsPayload.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.external.AlertReport.VacantUnitsPayload} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.external.AlertReport.VacantUnitsPayload}
 */
proto.external.AlertReport.VacantUnitsPayload.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setGla(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.external.AlertReport.VacantUnitsPayload.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.external.AlertReport.VacantUnitsPayload.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.external.AlertReport.VacantUnitsPayload} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.external.AlertReport.VacantUnitsPayload.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getGla();
  if (f !== 0.0) {
    writer.writeDouble(
      2,
      f
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.external.AlertReport.VacantUnitsPayload.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.external.AlertReport.VacantUnitsPayload} returns this
 */
proto.external.AlertReport.VacantUnitsPayload.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional double gla = 2;
 * @return {number}
 */
proto.external.AlertReport.VacantUnitsPayload.prototype.getGla = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.external.AlertReport.VacantUnitsPayload} returns this
 */
proto.external.AlertReport.VacantUnitsPayload.prototype.setGla = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.external.AlertReport.LeasePayload.prototype.toObject = function(opt_includeInstance) {
  return proto.external.AlertReport.LeasePayload.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.external.AlertReport.LeasePayload} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.external.AlertReport.LeasePayload.toObject = function(includeInstance, msg) {
  var f, obj = {
    enddate: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.external.AlertReport.LeasePayload}
 */
proto.external.AlertReport.LeasePayload.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.external.AlertReport.LeasePayload;
  return proto.external.AlertReport.LeasePayload.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.external.AlertReport.LeasePayload} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.external.AlertReport.LeasePayload}
 */
proto.external.AlertReport.LeasePayload.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setEnddate(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.external.AlertReport.LeasePayload.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.external.AlertReport.LeasePayload.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.external.AlertReport.LeasePayload} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.external.AlertReport.LeasePayload.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEnddate();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
};


/**
 * optional int64 endDate = 1;
 * @return {number}
 */
proto.external.AlertReport.LeasePayload.prototype.getEnddate = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.external.AlertReport.LeasePayload} returns this
 */
proto.external.AlertReport.LeasePayload.prototype.setEnddate = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string entityID = 1;
 * @return {string}
 */
proto.external.AlertReport.prototype.getEntityid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.external.AlertReport} returns this
 */
proto.external.AlertReport.prototype.setEntityid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.external.AlertReport.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.external.AlertReport} returns this
 */
proto.external.AlertReport.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string date = 3;
 * @return {string}
 */
proto.external.AlertReport.prototype.getDate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.external.AlertReport} returns this
 */
proto.external.AlertReport.prototype.setDate = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string portfolioName = 4;
 * @return {string}
 */
proto.external.AlertReport.prototype.getPortfolioname = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.external.AlertReport} returns this
 */
proto.external.AlertReport.prototype.setPortfolioname = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string portfolioID = 5;
 * @return {string}
 */
proto.external.AlertReport.prototype.getPortfolioid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.external.AlertReport} returns this
 */
proto.external.AlertReport.prototype.setPortfolioid = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string assetName = 6;
 * @return {string}
 */
proto.external.AlertReport.prototype.getAssetname = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.external.AlertReport} returns this
 */
proto.external.AlertReport.prototype.setAssetname = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string assetID = 7;
 * @return {string}
 */
proto.external.AlertReport.prototype.getAssetid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.external.AlertReport} returns this
 */
proto.external.AlertReport.prototype.setAssetid = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string tenantName = 8;
 * @return {string}
 */
proto.external.AlertReport.prototype.getTenantname = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.external.AlertReport} returns this
 */
proto.external.AlertReport.prototype.setTenantname = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string tenantID = 9;
 * @return {string}
 */
proto.external.AlertReport.prototype.getTenantid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.external.AlertReport} returns this
 */
proto.external.AlertReport.prototype.setTenantid = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional AlertCategory category = 10;
 * @return {!proto.external.AlertCategory}
 */
proto.external.AlertReport.prototype.getCategory = function() {
  return /** @type {!proto.external.AlertCategory} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/**
 * @param {!proto.external.AlertCategory} value
 * @return {!proto.external.AlertReport} returns this
 */
proto.external.AlertReport.prototype.setCategory = function(value) {
  return jspb.Message.setProto3EnumField(this, 10, value);
};


/**
 * optional AlertType type = 11;
 * @return {!proto.external.AlertType}
 */
proto.external.AlertReport.prototype.getType = function() {
  return /** @type {!proto.external.AlertType} */ (jspb.Message.getFieldWithDefault(this, 11, 0));
};


/**
 * @param {!proto.external.AlertType} value
 * @return {!proto.external.AlertReport} returns this
 */
proto.external.AlertReport.prototype.setType = function(value) {
  return jspb.Message.setProto3EnumField(this, 11, value);
};


/**
 * optional FacilityManagerTicketRaised ticket = 12;
 * @return {?proto.external.AlertReport.FacilityManagerTicketRaised}
 */
proto.external.AlertReport.prototype.getTicket = function() {
  return /** @type{?proto.external.AlertReport.FacilityManagerTicketRaised} */ (
    jspb.Message.getWrapperField(this, proto.external.AlertReport.FacilityManagerTicketRaised, 12));
};


/**
 * @param {?proto.external.AlertReport.FacilityManagerTicketRaised|undefined} value
 * @return {!proto.external.AlertReport} returns this
*/
proto.external.AlertReport.prototype.setTicket = function(value) {
  return jspb.Message.setOneofWrapperField(this, 12, proto.external.AlertReport.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.external.AlertReport} returns this
 */
proto.external.AlertReport.prototype.clearTicket = function() {
  return this.setTicket(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.external.AlertReport.prototype.hasTicket = function() {
  return jspb.Message.getField(this, 12) != null;
};


/**
 * optional OpexManagerPayload opex = 13;
 * @return {?proto.external.AlertReport.OpexManagerPayload}
 */
proto.external.AlertReport.prototype.getOpex = function() {
  return /** @type{?proto.external.AlertReport.OpexManagerPayload} */ (
    jspb.Message.getWrapperField(this, proto.external.AlertReport.OpexManagerPayload, 13));
};


/**
 * @param {?proto.external.AlertReport.OpexManagerPayload|undefined} value
 * @return {!proto.external.AlertReport} returns this
*/
proto.external.AlertReport.prototype.setOpex = function(value) {
  return jspb.Message.setOneofWrapperField(this, 13, proto.external.AlertReport.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.external.AlertReport} returns this
 */
proto.external.AlertReport.prototype.clearOpex = function() {
  return this.setOpex(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.external.AlertReport.prototype.hasOpex = function() {
  return jspb.Message.getField(this, 13) != null;
};


/**
 * optional NonOpexManagerPayload nonOpex = 14;
 * @return {?proto.external.AlertReport.NonOpexManagerPayload}
 */
proto.external.AlertReport.prototype.getNonopex = function() {
  return /** @type{?proto.external.AlertReport.NonOpexManagerPayload} */ (
    jspb.Message.getWrapperField(this, proto.external.AlertReport.NonOpexManagerPayload, 14));
};


/**
 * @param {?proto.external.AlertReport.NonOpexManagerPayload|undefined} value
 * @return {!proto.external.AlertReport} returns this
*/
proto.external.AlertReport.prototype.setNonopex = function(value) {
  return jspb.Message.setOneofWrapperField(this, 14, proto.external.AlertReport.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.external.AlertReport} returns this
 */
proto.external.AlertReport.prototype.clearNonopex = function() {
  return this.setNonopex(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.external.AlertReport.prototype.hasNonopex = function() {
  return jspb.Message.getField(this, 14) != null;
};


/**
 * optional VacantUnitsPayload vacantUnits = 15;
 * @return {?proto.external.AlertReport.VacantUnitsPayload}
 */
proto.external.AlertReport.prototype.getVacantunits = function() {
  return /** @type{?proto.external.AlertReport.VacantUnitsPayload} */ (
    jspb.Message.getWrapperField(this, proto.external.AlertReport.VacantUnitsPayload, 15));
};


/**
 * @param {?proto.external.AlertReport.VacantUnitsPayload|undefined} value
 * @return {!proto.external.AlertReport} returns this
*/
proto.external.AlertReport.prototype.setVacantunits = function(value) {
  return jspb.Message.setOneofWrapperField(this, 15, proto.external.AlertReport.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.external.AlertReport} returns this
 */
proto.external.AlertReport.prototype.clearVacantunits = function() {
  return this.setVacantunits(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.external.AlertReport.prototype.hasVacantunits = function() {
  return jspb.Message.getField(this, 15) != null;
};


/**
 * optional LeasePayload lease = 16;
 * @return {?proto.external.AlertReport.LeasePayload}
 */
proto.external.AlertReport.prototype.getLease = function() {
  return /** @type{?proto.external.AlertReport.LeasePayload} */ (
    jspb.Message.getWrapperField(this, proto.external.AlertReport.LeasePayload, 16));
};


/**
 * @param {?proto.external.AlertReport.LeasePayload|undefined} value
 * @return {!proto.external.AlertReport} returns this
*/
proto.external.AlertReport.prototype.setLease = function(value) {
  return jspb.Message.setOneofWrapperField(this, 16, proto.external.AlertReport.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.external.AlertReport} returns this
 */
proto.external.AlertReport.prototype.clearLease = function() {
  return this.setLease(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.external.AlertReport.prototype.hasLease = function() {
  return jspb.Message.getField(this, 16) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.external.HideRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.external.HideRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.external.HideRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.external.HideRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    entityid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    type: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.external.HideRequest}
 */
proto.external.HideRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.external.HideRequest;
  return proto.external.HideRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.external.HideRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.external.HideRequest}
 */
proto.external.HideRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setEntityid(value);
      break;
    case 2:
      var value = /** @type {!proto.external.AlertType} */ (reader.readEnum());
      msg.setType(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.external.HideRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.external.HideRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.external.HideRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.external.HideRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEntityid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getType();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
};


/**
 * optional string entityID = 1;
 * @return {string}
 */
proto.external.HideRequest.prototype.getEntityid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.external.HideRequest} returns this
 */
proto.external.HideRequest.prototype.setEntityid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional AlertType type = 2;
 * @return {!proto.external.AlertType}
 */
proto.external.HideRequest.prototype.getType = function() {
  return /** @type {!proto.external.AlertType} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.external.AlertType} value
 * @return {!proto.external.HideRequest} returns this
 */
proto.external.HideRequest.prototype.setType = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.external.GetAlertsCountRequest.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.external.GetAlertsCountRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.external.GetAlertsCountRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.external.GetAlertsCountRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.external.GetAlertsCountRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    filtersList: jspb.Message.toObjectList(msg.getFiltersList(),
    external_filters_pb.Filter.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.external.GetAlertsCountRequest}
 */
proto.external.GetAlertsCountRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.external.GetAlertsCountRequest;
  return proto.external.GetAlertsCountRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.external.GetAlertsCountRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.external.GetAlertsCountRequest}
 */
proto.external.GetAlertsCountRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new external_filters_pb.Filter;
      reader.readMessage(value,external_filters_pb.Filter.deserializeBinaryFromReader);
      msg.addFilters(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.external.GetAlertsCountRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.external.GetAlertsCountRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.external.GetAlertsCountRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.external.GetAlertsCountRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFiltersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      external_filters_pb.Filter.serializeBinaryToWriter
    );
  }
};


/**
 * repeated Filter filters = 1;
 * @return {!Array<!proto.external.Filter>}
 */
proto.external.GetAlertsCountRequest.prototype.getFiltersList = function() {
  return /** @type{!Array<!proto.external.Filter>} */ (
    jspb.Message.getRepeatedWrapperField(this, external_filters_pb.Filter, 1));
};


/**
 * @param {!Array<!proto.external.Filter>} value
 * @return {!proto.external.GetAlertsCountRequest} returns this
*/
proto.external.GetAlertsCountRequest.prototype.setFiltersList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.external.Filter=} opt_value
 * @param {number=} opt_index
 * @return {!proto.external.Filter}
 */
proto.external.GetAlertsCountRequest.prototype.addFilters = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.external.Filter, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.external.GetAlertsCountRequest} returns this
 */
proto.external.GetAlertsCountRequest.prototype.clearFiltersList = function() {
  return this.setFiltersList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.external.GetAlertsCountResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.external.GetAlertsCountResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.external.GetAlertsCountResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.external.GetAlertsCountResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    alertscount: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.external.GetAlertsCountResponse}
 */
proto.external.GetAlertsCountResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.external.GetAlertsCountResponse;
  return proto.external.GetAlertsCountResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.external.GetAlertsCountResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.external.GetAlertsCountResponse}
 */
proto.external.GetAlertsCountResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setAlertscount(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.external.GetAlertsCountResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.external.GetAlertsCountResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.external.GetAlertsCountResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.external.GetAlertsCountResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAlertscount();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
};


/**
 * optional int32 alertsCount = 1;
 * @return {number}
 */
proto.external.GetAlertsCountResponse.prototype.getAlertscount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.external.GetAlertsCountResponse} returns this
 */
proto.external.GetAlertsCountResponse.prototype.setAlertscount = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * @enum {number}
 */
proto.external.AlertCategory = {
  ALERT_CATEGORY_UNSPECIFIED: 0,
  ALERT_CATEGORY_ASSET_MANAGEMENT: 1,
  ALERT_CATEGORY_PROPERTY_MANAGEMENT: 2
};

/**
 * @enum {number}
 */
proto.external.AlertType = {
  ALERT_TYPE_UNSPECIFIED: 0,
  ALERT_TYPE_TENANT_CONTRACT_EXPIRATION: 1,
  ALERT_TYPE_FACILITY_MANAGER_TICKET_RAISED: 2,
  ALERT_TYPE_VACANT_UNITS: 3,
  ALERT_TYPE_TENANT_CONTRACT_BREAK: 4,
  ALERT_TYPE_OPEX_CONTRACT_EXPIRATION: 5,
  ALERT_TYPE_NON_OPEX_CONTRACT_EXPIRATION: 6
};

goog.object.extend(exports, proto.external);
