import React from 'react';

import Box from '@material-ui/core/Box';
import CircularProgress from '@material-ui/core/CircularProgress';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import clsx from 'clsx';
import { numberWithCommas } from 'helpers/utils';
import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import { v4 as uuidv4 } from 'uuid';

import useStyles from './styles';

const getColor = (value) => {
  if (value > 0) return '#3fab2c';
  if (value < 0) return '#c42929';
  return '#999';
};

const Loader = () => (
  <Box display="flex" justifyContent="center" width="100%">
    <CircularProgress />
  </Box>
);

const NoData = () => (
  <Typography variant="subtitle1" style={{ textAlign: 'center' }}>
    No records to display.
  </Typography>
);

const TableSummary = ({ data, loading, loaded, tab }) => {
  const classes = useStyles();

  const renderFallback = () => (
    <TableCell colSpan="10" className={clsx(classes.cell, classes.minWidth, classes.noBorder)}>
      {loading && <Loader />}
      {loaded && isEmpty(data) && <NoData />}
    </TableCell>
  );

  if (isEmpty(data)) {
    return (
      <Table>
        <TableHeader />
        <TableBody>
          <TableRow key={uuidv4()}>
            <TableCell className={clsx(classes.minWidth, classes.noBorder)}>
              <Typography>Contracted</Typography>
            </TableCell>
          </TableRow>
          {tab !== 'TurnoverRent' && (
            <TableRow key={uuidv4()}>
              <TableCell className={clsx(classes.minWidth, classes.noBorder)}>
                <Typography>MLA</Typography>
              </TableCell>
            </TableRow>
          )}
          {tab !== 'TurnoverRent' && (
            <TableRow key={uuidv4()}>
              <TableCell className={clsx(classes.minWidth, classes.noBorder)}>
                <Typography>Total Forecast</Typography>
              </TableCell>
              {renderFallback()}
            </TableRow>
          )}
          <TableRow key={uuidv4()}>
            <TableCell className={clsx(classes.minWidth, classes.noBorder)}>
              <Typography>Actual</Typography>
              {tab === 'TurnoverRent' && renderFallback()}
            </TableCell>
            {tab === 'TurnoverRent' && renderFallback()}
          </TableRow>
          <TableRow key={uuidv4()}>
            <TableCell className={clsx(classes.minWidth, classes.noBorder)}>
              <Typography>Forecast vs Actuals</Typography>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    );
  }

  return (
    <Table>
      <TableHeader />
      <TableBody>
        <TableRow key={uuidv4()}>
          <TableCell className={clsx(classes.minWidth, classes.noBorder)}>
            <Typography>Contracted</Typography>
          </TableCell>
          {loaded &&
            data.contractedList.map((item) => (
              <TableCell
                className={clsx(classes.noBorder)}
                key={uuidv4()}
                style={{ color: getColor(item) }}
              >
                {`€${numberWithCommas(item)}`}
              </TableCell>
            ))}
        </TableRow>
        {tab !== 'TurnoverRent' && (
          <TableRow key={uuidv4()}>
            <TableCell className={clsx(classes.minWidth, classes.noBorder)}>
              <Typography>MLA</Typography>
            </TableCell>
            {loaded &&
              data.mlasList.map((item) => (
                <TableCell
                  className={clsx(classes.noBorder)}
                  style={{ color: getColor(item) }}
                  key={uuidv4()}
                >
                  {`€${numberWithCommas(item)}`}
                </TableCell>
              ))}
          </TableRow>
        )}
        {tab !== 'TurnoverRent' && (
          <TableRow key={uuidv4()}>
            <TableCell className={clsx(classes.minWidth, classes.noBorder)}>
              <Typography>Total Forecast</Typography>
            </TableCell>
            {loaded &&
              data.totalforecastsList.map((item) => (
                <TableCell
                  className={clsx(classes.noBorder)}
                  key={uuidv4()}
                  style={{ color: getColor(item) }}
                >
                  {`€${numberWithCommas(item)}`}
                </TableCell>
              ))}
          </TableRow>
        )}

        <TableRow key={uuidv4()}>
          <TableCell className={clsx(classes.minWidth, classes.noBorder)}>
            <Typography>Actual</Typography>
          </TableCell>
          {loaded &&
            data.actualsList.map((item) => (
              <TableCell
                className={clsx(classes.noBorder)}
                key={uuidv4()}
                style={{ color: getColor(item) }}
              >
                {`€${numberWithCommas(item)}`}
              </TableCell>
            ))}
        </TableRow>
        <TableRow key={uuidv4()}>
          <TableCell className={clsx(classes.minWidth, classes.noBorder)}>
            <Typography>Forecast vs Actuals</Typography>
          </TableCell>
          {loaded &&
            data.deltasList.map((item) => (
              <TableCell
                className={clsx(classes.noBorder)}
                key={uuidv4()}
                style={{ color: getColor(item) }}
              >
                {`€${numberWithCommas(item)}`}
              </TableCell>
            ))}
        </TableRow>
      </TableBody>
    </Table>
  );
};

export default TableSummary;

TableSummary.propTypes = {
  data: PropTypes.object,
  loading: PropTypes.bool,
  loaded: PropTypes.bool,
  tab: PropTypes.string
};

TableSummary.defaultProps = {
  data: [],
  loading: false,
  loaded: false,
  tab: ''
};

const TableHeader = () => {
  const classes = useStyles();
  return (
    <TableHead>
      <TableRow>
        <TableCell className={classes.noBorder} />
        <TableCell className={classes.noBorder}>Jan</TableCell>
        <TableCell className={classes.noBorder}>Feb</TableCell>
        <TableCell className={classes.noBorder}>Mar</TableCell>
        <TableCell className={classes.noBorder}>Apr</TableCell>
        <TableCell className={classes.noBorder}>May</TableCell>
        <TableCell className={classes.noBorder}>Jun</TableCell>
        <TableCell className={classes.noBorder}>Jul</TableCell>
        <TableCell className={classes.noBorder}>Aug</TableCell>
        <TableCell className={classes.noBorder}>Sep</TableCell>
        <TableCell className={classes.noBorder}>Oct</TableCell>
        <TableCell className={classes.noBorder}>Nov</TableCell>
        <TableCell className={classes.noBorder}>Dec</TableCell>
      </TableRow>
    </TableHead>
  );
};
