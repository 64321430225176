// source: external/filters_gorm.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

goog.exportSymbol('proto.external.BetweenConditionGorm', null, global);
goog.exportSymbol('proto.external.BetweenConditionValueGorm', null, global);
goog.exportSymbol('proto.external.BetweenConditionValueGorm.ValueCase', null, global);
goog.exportSymbol('proto.external.ConditionValueGorm', null, global);
goog.exportSymbol('proto.external.ConditionValueGorm.ValueCase', null, global);
goog.exportSymbol('proto.external.EqualConditionGorm', null, global);
goog.exportSymbol('proto.external.FilterCondition', null, global);
goog.exportSymbol('proto.external.FilterCondition.ConditionCase', null, global);
goog.exportSymbol('proto.external.Filters', null, global);
goog.exportSymbol('proto.external.InConditionGorm', null, global);
goog.exportSymbol('proto.external.LikeConditionGorm', null, global);
goog.exportSymbol('proto.external.NotEqualConditionGorm', null, global);
goog.exportSymbol('proto.external.NotInConditionGorm', null, global);
goog.exportSymbol('proto.external.OrderCondition', null, global);
goog.exportSymbol('proto.external.OrderType', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.external.Filters = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.external.Filters.repeatedFields_, null);
};
goog.inherits(proto.external.Filters, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.external.Filters.displayName = 'proto.external.Filters';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.external.FilterCondition = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.external.FilterCondition.oneofGroups_);
};
goog.inherits(proto.external.FilterCondition, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.external.FilterCondition.displayName = 'proto.external.FilterCondition';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.external.EqualConditionGorm = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.external.EqualConditionGorm, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.external.EqualConditionGorm.displayName = 'proto.external.EqualConditionGorm';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.external.NotEqualConditionGorm = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.external.NotEqualConditionGorm, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.external.NotEqualConditionGorm.displayName = 'proto.external.NotEqualConditionGorm';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.external.InConditionGorm = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.external.InConditionGorm.repeatedFields_, null);
};
goog.inherits(proto.external.InConditionGorm, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.external.InConditionGorm.displayName = 'proto.external.InConditionGorm';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.external.NotInConditionGorm = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.external.NotInConditionGorm.repeatedFields_, null);
};
goog.inherits(proto.external.NotInConditionGorm, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.external.NotInConditionGorm.displayName = 'proto.external.NotInConditionGorm';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.external.BetweenConditionGorm = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.external.BetweenConditionGorm, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.external.BetweenConditionGorm.displayName = 'proto.external.BetweenConditionGorm';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.external.LikeConditionGorm = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.external.LikeConditionGorm, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.external.LikeConditionGorm.displayName = 'proto.external.LikeConditionGorm';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.external.ConditionValueGorm = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.external.ConditionValueGorm.oneofGroups_);
};
goog.inherits(proto.external.ConditionValueGorm, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.external.ConditionValueGorm.displayName = 'proto.external.ConditionValueGorm';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.external.BetweenConditionValueGorm = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.external.BetweenConditionValueGorm.oneofGroups_);
};
goog.inherits(proto.external.BetweenConditionValueGorm, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.external.BetweenConditionValueGorm.displayName = 'proto.external.BetweenConditionValueGorm';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.external.OrderCondition = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.external.OrderCondition, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.external.OrderCondition.displayName = 'proto.external.OrderCondition';
}

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.external.Filters.repeatedFields_ = [1,2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.external.Filters.prototype.toObject = function(opt_includeInstance) {
  return proto.external.Filters.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.external.Filters} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.external.Filters.toObject = function(includeInstance, msg) {
  var f, obj = {
    filterconditionsList: jspb.Message.toObjectList(msg.getFilterconditionsList(),
    proto.external.FilterCondition.toObject, includeInstance),
    orderconditionsList: jspb.Message.toObjectList(msg.getOrderconditionsList(),
    proto.external.OrderCondition.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.external.Filters}
 */
proto.external.Filters.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.external.Filters;
  return proto.external.Filters.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.external.Filters} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.external.Filters}
 */
proto.external.Filters.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.external.FilterCondition;
      reader.readMessage(value,proto.external.FilterCondition.deserializeBinaryFromReader);
      msg.addFilterconditions(value);
      break;
    case 2:
      var value = new proto.external.OrderCondition;
      reader.readMessage(value,proto.external.OrderCondition.deserializeBinaryFromReader);
      msg.addOrderconditions(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.external.Filters.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.external.Filters.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.external.Filters} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.external.Filters.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFilterconditionsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.external.FilterCondition.serializeBinaryToWriter
    );
  }
  f = message.getOrderconditionsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.external.OrderCondition.serializeBinaryToWriter
    );
  }
};


/**
 * repeated FilterCondition filterConditions = 1;
 * @return {!Array<!proto.external.FilterCondition>}
 */
proto.external.Filters.prototype.getFilterconditionsList = function() {
  return /** @type{!Array<!proto.external.FilterCondition>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.external.FilterCondition, 1));
};


/**
 * @param {!Array<!proto.external.FilterCondition>} value
 * @return {!proto.external.Filters} returns this
*/
proto.external.Filters.prototype.setFilterconditionsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.external.FilterCondition=} opt_value
 * @param {number=} opt_index
 * @return {!proto.external.FilterCondition}
 */
proto.external.Filters.prototype.addFilterconditions = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.external.FilterCondition, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.external.Filters} returns this
 */
proto.external.Filters.prototype.clearFilterconditionsList = function() {
  return this.setFilterconditionsList([]);
};


/**
 * repeated OrderCondition orderConditions = 2;
 * @return {!Array<!proto.external.OrderCondition>}
 */
proto.external.Filters.prototype.getOrderconditionsList = function() {
  return /** @type{!Array<!proto.external.OrderCondition>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.external.OrderCondition, 2));
};


/**
 * @param {!Array<!proto.external.OrderCondition>} value
 * @return {!proto.external.Filters} returns this
*/
proto.external.Filters.prototype.setOrderconditionsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.external.OrderCondition=} opt_value
 * @param {number=} opt_index
 * @return {!proto.external.OrderCondition}
 */
proto.external.Filters.prototype.addOrderconditions = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.external.OrderCondition, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.external.Filters} returns this
 */
proto.external.Filters.prototype.clearOrderconditionsList = function() {
  return this.setOrderconditionsList([]);
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.external.FilterCondition.oneofGroups_ = [[1,2,3,4,5,6]];

/**
 * @enum {number}
 */
proto.external.FilterCondition.ConditionCase = {
  CONDITION_NOT_SET: 0,
  EQUAL: 1,
  NOTEQUAL: 2,
  IN: 3,
  NOTIN: 4,
  BETWEEN: 5,
  LIKE: 6
};

/**
 * @return {proto.external.FilterCondition.ConditionCase}
 */
proto.external.FilterCondition.prototype.getConditionCase = function() {
  return /** @type {proto.external.FilterCondition.ConditionCase} */(jspb.Message.computeOneofCase(this, proto.external.FilterCondition.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.external.FilterCondition.prototype.toObject = function(opt_includeInstance) {
  return proto.external.FilterCondition.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.external.FilterCondition} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.external.FilterCondition.toObject = function(includeInstance, msg) {
  var f, obj = {
    equal: (f = msg.getEqual()) && proto.external.EqualConditionGorm.toObject(includeInstance, f),
    notequal: (f = msg.getNotequal()) && proto.external.NotEqualConditionGorm.toObject(includeInstance, f),
    pb_in: (f = msg.getIn()) && proto.external.InConditionGorm.toObject(includeInstance, f),
    notin: (f = msg.getNotin()) && proto.external.NotInConditionGorm.toObject(includeInstance, f),
    between: (f = msg.getBetween()) && proto.external.BetweenConditionGorm.toObject(includeInstance, f),
    like: (f = msg.getLike()) && proto.external.LikeConditionGorm.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.external.FilterCondition}
 */
proto.external.FilterCondition.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.external.FilterCondition;
  return proto.external.FilterCondition.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.external.FilterCondition} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.external.FilterCondition}
 */
proto.external.FilterCondition.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.external.EqualConditionGorm;
      reader.readMessage(value,proto.external.EqualConditionGorm.deserializeBinaryFromReader);
      msg.setEqual(value);
      break;
    case 2:
      var value = new proto.external.NotEqualConditionGorm;
      reader.readMessage(value,proto.external.NotEqualConditionGorm.deserializeBinaryFromReader);
      msg.setNotequal(value);
      break;
    case 3:
      var value = new proto.external.InConditionGorm;
      reader.readMessage(value,proto.external.InConditionGorm.deserializeBinaryFromReader);
      msg.setIn(value);
      break;
    case 4:
      var value = new proto.external.NotInConditionGorm;
      reader.readMessage(value,proto.external.NotInConditionGorm.deserializeBinaryFromReader);
      msg.setNotin(value);
      break;
    case 5:
      var value = new proto.external.BetweenConditionGorm;
      reader.readMessage(value,proto.external.BetweenConditionGorm.deserializeBinaryFromReader);
      msg.setBetween(value);
      break;
    case 6:
      var value = new proto.external.LikeConditionGorm;
      reader.readMessage(value,proto.external.LikeConditionGorm.deserializeBinaryFromReader);
      msg.setLike(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.external.FilterCondition.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.external.FilterCondition.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.external.FilterCondition} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.external.FilterCondition.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEqual();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.external.EqualConditionGorm.serializeBinaryToWriter
    );
  }
  f = message.getNotequal();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.external.NotEqualConditionGorm.serializeBinaryToWriter
    );
  }
  f = message.getIn();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.external.InConditionGorm.serializeBinaryToWriter
    );
  }
  f = message.getNotin();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.external.NotInConditionGorm.serializeBinaryToWriter
    );
  }
  f = message.getBetween();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.external.BetweenConditionGorm.serializeBinaryToWriter
    );
  }
  f = message.getLike();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      proto.external.LikeConditionGorm.serializeBinaryToWriter
    );
  }
};


/**
 * optional EqualConditionGorm equal = 1;
 * @return {?proto.external.EqualConditionGorm}
 */
proto.external.FilterCondition.prototype.getEqual = function() {
  return /** @type{?proto.external.EqualConditionGorm} */ (
    jspb.Message.getWrapperField(this, proto.external.EqualConditionGorm, 1));
};


/**
 * @param {?proto.external.EqualConditionGorm|undefined} value
 * @return {!proto.external.FilterCondition} returns this
*/
proto.external.FilterCondition.prototype.setEqual = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.external.FilterCondition.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.external.FilterCondition} returns this
 */
proto.external.FilterCondition.prototype.clearEqual = function() {
  return this.setEqual(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.external.FilterCondition.prototype.hasEqual = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional NotEqualConditionGorm notEqual = 2;
 * @return {?proto.external.NotEqualConditionGorm}
 */
proto.external.FilterCondition.prototype.getNotequal = function() {
  return /** @type{?proto.external.NotEqualConditionGorm} */ (
    jspb.Message.getWrapperField(this, proto.external.NotEqualConditionGorm, 2));
};


/**
 * @param {?proto.external.NotEqualConditionGorm|undefined} value
 * @return {!proto.external.FilterCondition} returns this
*/
proto.external.FilterCondition.prototype.setNotequal = function(value) {
  return jspb.Message.setOneofWrapperField(this, 2, proto.external.FilterCondition.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.external.FilterCondition} returns this
 */
proto.external.FilterCondition.prototype.clearNotequal = function() {
  return this.setNotequal(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.external.FilterCondition.prototype.hasNotequal = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional InConditionGorm in = 3;
 * @return {?proto.external.InConditionGorm}
 */
proto.external.FilterCondition.prototype.getIn = function() {
  return /** @type{?proto.external.InConditionGorm} */ (
    jspb.Message.getWrapperField(this, proto.external.InConditionGorm, 3));
};


/**
 * @param {?proto.external.InConditionGorm|undefined} value
 * @return {!proto.external.FilterCondition} returns this
*/
proto.external.FilterCondition.prototype.setIn = function(value) {
  return jspb.Message.setOneofWrapperField(this, 3, proto.external.FilterCondition.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.external.FilterCondition} returns this
 */
proto.external.FilterCondition.prototype.clearIn = function() {
  return this.setIn(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.external.FilterCondition.prototype.hasIn = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional NotInConditionGorm notIn = 4;
 * @return {?proto.external.NotInConditionGorm}
 */
proto.external.FilterCondition.prototype.getNotin = function() {
  return /** @type{?proto.external.NotInConditionGorm} */ (
    jspb.Message.getWrapperField(this, proto.external.NotInConditionGorm, 4));
};


/**
 * @param {?proto.external.NotInConditionGorm|undefined} value
 * @return {!proto.external.FilterCondition} returns this
*/
proto.external.FilterCondition.prototype.setNotin = function(value) {
  return jspb.Message.setOneofWrapperField(this, 4, proto.external.FilterCondition.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.external.FilterCondition} returns this
 */
proto.external.FilterCondition.prototype.clearNotin = function() {
  return this.setNotin(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.external.FilterCondition.prototype.hasNotin = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional BetweenConditionGorm between = 5;
 * @return {?proto.external.BetweenConditionGorm}
 */
proto.external.FilterCondition.prototype.getBetween = function() {
  return /** @type{?proto.external.BetweenConditionGorm} */ (
    jspb.Message.getWrapperField(this, proto.external.BetweenConditionGorm, 5));
};


/**
 * @param {?proto.external.BetweenConditionGorm|undefined} value
 * @return {!proto.external.FilterCondition} returns this
*/
proto.external.FilterCondition.prototype.setBetween = function(value) {
  return jspb.Message.setOneofWrapperField(this, 5, proto.external.FilterCondition.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.external.FilterCondition} returns this
 */
proto.external.FilterCondition.prototype.clearBetween = function() {
  return this.setBetween(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.external.FilterCondition.prototype.hasBetween = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional LikeConditionGorm like = 6;
 * @return {?proto.external.LikeConditionGorm}
 */
proto.external.FilterCondition.prototype.getLike = function() {
  return /** @type{?proto.external.LikeConditionGorm} */ (
    jspb.Message.getWrapperField(this, proto.external.LikeConditionGorm, 6));
};


/**
 * @param {?proto.external.LikeConditionGorm|undefined} value
 * @return {!proto.external.FilterCondition} returns this
*/
proto.external.FilterCondition.prototype.setLike = function(value) {
  return jspb.Message.setOneofWrapperField(this, 6, proto.external.FilterCondition.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.external.FilterCondition} returns this
 */
proto.external.FilterCondition.prototype.clearLike = function() {
  return this.setLike(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.external.FilterCondition.prototype.hasLike = function() {
  return jspb.Message.getField(this, 6) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.external.EqualConditionGorm.prototype.toObject = function(opt_includeInstance) {
  return proto.external.EqualConditionGorm.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.external.EqualConditionGorm} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.external.EqualConditionGorm.toObject = function(includeInstance, msg) {
  var f, obj = {
    field: jspb.Message.getFieldWithDefault(msg, 1, ""),
    value: (f = msg.getValue()) && proto.external.ConditionValueGorm.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.external.EqualConditionGorm}
 */
proto.external.EqualConditionGorm.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.external.EqualConditionGorm;
  return proto.external.EqualConditionGorm.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.external.EqualConditionGorm} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.external.EqualConditionGorm}
 */
proto.external.EqualConditionGorm.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setField(value);
      break;
    case 2:
      var value = new proto.external.ConditionValueGorm;
      reader.readMessage(value,proto.external.ConditionValueGorm.deserializeBinaryFromReader);
      msg.setValue(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.external.EqualConditionGorm.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.external.EqualConditionGorm.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.external.EqualConditionGorm} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.external.EqualConditionGorm.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getField();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getValue();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.external.ConditionValueGorm.serializeBinaryToWriter
    );
  }
};


/**
 * optional string field = 1;
 * @return {string}
 */
proto.external.EqualConditionGorm.prototype.getField = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.external.EqualConditionGorm} returns this
 */
proto.external.EqualConditionGorm.prototype.setField = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional ConditionValueGorm value = 2;
 * @return {?proto.external.ConditionValueGorm}
 */
proto.external.EqualConditionGorm.prototype.getValue = function() {
  return /** @type{?proto.external.ConditionValueGorm} */ (
    jspb.Message.getWrapperField(this, proto.external.ConditionValueGorm, 2));
};


/**
 * @param {?proto.external.ConditionValueGorm|undefined} value
 * @return {!proto.external.EqualConditionGorm} returns this
*/
proto.external.EqualConditionGorm.prototype.setValue = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.external.EqualConditionGorm} returns this
 */
proto.external.EqualConditionGorm.prototype.clearValue = function() {
  return this.setValue(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.external.EqualConditionGorm.prototype.hasValue = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.external.NotEqualConditionGorm.prototype.toObject = function(opt_includeInstance) {
  return proto.external.NotEqualConditionGorm.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.external.NotEqualConditionGorm} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.external.NotEqualConditionGorm.toObject = function(includeInstance, msg) {
  var f, obj = {
    field: jspb.Message.getFieldWithDefault(msg, 1, ""),
    value: (f = msg.getValue()) && proto.external.ConditionValueGorm.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.external.NotEqualConditionGorm}
 */
proto.external.NotEqualConditionGorm.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.external.NotEqualConditionGorm;
  return proto.external.NotEqualConditionGorm.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.external.NotEqualConditionGorm} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.external.NotEqualConditionGorm}
 */
proto.external.NotEqualConditionGorm.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setField(value);
      break;
    case 2:
      var value = new proto.external.ConditionValueGorm;
      reader.readMessage(value,proto.external.ConditionValueGorm.deserializeBinaryFromReader);
      msg.setValue(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.external.NotEqualConditionGorm.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.external.NotEqualConditionGorm.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.external.NotEqualConditionGorm} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.external.NotEqualConditionGorm.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getField();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getValue();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.external.ConditionValueGorm.serializeBinaryToWriter
    );
  }
};


/**
 * optional string field = 1;
 * @return {string}
 */
proto.external.NotEqualConditionGorm.prototype.getField = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.external.NotEqualConditionGorm} returns this
 */
proto.external.NotEqualConditionGorm.prototype.setField = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional ConditionValueGorm value = 2;
 * @return {?proto.external.ConditionValueGorm}
 */
proto.external.NotEqualConditionGorm.prototype.getValue = function() {
  return /** @type{?proto.external.ConditionValueGorm} */ (
    jspb.Message.getWrapperField(this, proto.external.ConditionValueGorm, 2));
};


/**
 * @param {?proto.external.ConditionValueGorm|undefined} value
 * @return {!proto.external.NotEqualConditionGorm} returns this
*/
proto.external.NotEqualConditionGorm.prototype.setValue = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.external.NotEqualConditionGorm} returns this
 */
proto.external.NotEqualConditionGorm.prototype.clearValue = function() {
  return this.setValue(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.external.NotEqualConditionGorm.prototype.hasValue = function() {
  return jspb.Message.getField(this, 2) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.external.InConditionGorm.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.external.InConditionGorm.prototype.toObject = function(opt_includeInstance) {
  return proto.external.InConditionGorm.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.external.InConditionGorm} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.external.InConditionGorm.toObject = function(includeInstance, msg) {
  var f, obj = {
    field: jspb.Message.getFieldWithDefault(msg, 1, ""),
    valuesList: jspb.Message.toObjectList(msg.getValuesList(),
    proto.external.ConditionValueGorm.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.external.InConditionGorm}
 */
proto.external.InConditionGorm.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.external.InConditionGorm;
  return proto.external.InConditionGorm.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.external.InConditionGorm} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.external.InConditionGorm}
 */
proto.external.InConditionGorm.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setField(value);
      break;
    case 2:
      var value = new proto.external.ConditionValueGorm;
      reader.readMessage(value,proto.external.ConditionValueGorm.deserializeBinaryFromReader);
      msg.addValues(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.external.InConditionGorm.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.external.InConditionGorm.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.external.InConditionGorm} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.external.InConditionGorm.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getField();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getValuesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.external.ConditionValueGorm.serializeBinaryToWriter
    );
  }
};


/**
 * optional string field = 1;
 * @return {string}
 */
proto.external.InConditionGorm.prototype.getField = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.external.InConditionGorm} returns this
 */
proto.external.InConditionGorm.prototype.setField = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated ConditionValueGorm values = 2;
 * @return {!Array<!proto.external.ConditionValueGorm>}
 */
proto.external.InConditionGorm.prototype.getValuesList = function() {
  return /** @type{!Array<!proto.external.ConditionValueGorm>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.external.ConditionValueGorm, 2));
};


/**
 * @param {!Array<!proto.external.ConditionValueGorm>} value
 * @return {!proto.external.InConditionGorm} returns this
*/
proto.external.InConditionGorm.prototype.setValuesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.external.ConditionValueGorm=} opt_value
 * @param {number=} opt_index
 * @return {!proto.external.ConditionValueGorm}
 */
proto.external.InConditionGorm.prototype.addValues = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.external.ConditionValueGorm, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.external.InConditionGorm} returns this
 */
proto.external.InConditionGorm.prototype.clearValuesList = function() {
  return this.setValuesList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.external.NotInConditionGorm.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.external.NotInConditionGorm.prototype.toObject = function(opt_includeInstance) {
  return proto.external.NotInConditionGorm.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.external.NotInConditionGorm} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.external.NotInConditionGorm.toObject = function(includeInstance, msg) {
  var f, obj = {
    field: jspb.Message.getFieldWithDefault(msg, 1, ""),
    valuesList: jspb.Message.toObjectList(msg.getValuesList(),
    proto.external.ConditionValueGorm.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.external.NotInConditionGorm}
 */
proto.external.NotInConditionGorm.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.external.NotInConditionGorm;
  return proto.external.NotInConditionGorm.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.external.NotInConditionGorm} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.external.NotInConditionGorm}
 */
proto.external.NotInConditionGorm.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setField(value);
      break;
    case 2:
      var value = new proto.external.ConditionValueGorm;
      reader.readMessage(value,proto.external.ConditionValueGorm.deserializeBinaryFromReader);
      msg.addValues(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.external.NotInConditionGorm.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.external.NotInConditionGorm.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.external.NotInConditionGorm} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.external.NotInConditionGorm.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getField();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getValuesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.external.ConditionValueGorm.serializeBinaryToWriter
    );
  }
};


/**
 * optional string field = 1;
 * @return {string}
 */
proto.external.NotInConditionGorm.prototype.getField = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.external.NotInConditionGorm} returns this
 */
proto.external.NotInConditionGorm.prototype.setField = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated ConditionValueGorm values = 2;
 * @return {!Array<!proto.external.ConditionValueGorm>}
 */
proto.external.NotInConditionGorm.prototype.getValuesList = function() {
  return /** @type{!Array<!proto.external.ConditionValueGorm>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.external.ConditionValueGorm, 2));
};


/**
 * @param {!Array<!proto.external.ConditionValueGorm>} value
 * @return {!proto.external.NotInConditionGorm} returns this
*/
proto.external.NotInConditionGorm.prototype.setValuesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.external.ConditionValueGorm=} opt_value
 * @param {number=} opt_index
 * @return {!proto.external.ConditionValueGorm}
 */
proto.external.NotInConditionGorm.prototype.addValues = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.external.ConditionValueGorm, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.external.NotInConditionGorm} returns this
 */
proto.external.NotInConditionGorm.prototype.clearValuesList = function() {
  return this.setValuesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.external.BetweenConditionGorm.prototype.toObject = function(opt_includeInstance) {
  return proto.external.BetweenConditionGorm.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.external.BetweenConditionGorm} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.external.BetweenConditionGorm.toObject = function(includeInstance, msg) {
  var f, obj = {
    field: jspb.Message.getFieldWithDefault(msg, 1, ""),
    from: (f = msg.getFrom()) && proto.external.BetweenConditionValueGorm.toObject(includeInstance, f),
    to: (f = msg.getTo()) && proto.external.BetweenConditionValueGorm.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.external.BetweenConditionGorm}
 */
proto.external.BetweenConditionGorm.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.external.BetweenConditionGorm;
  return proto.external.BetweenConditionGorm.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.external.BetweenConditionGorm} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.external.BetweenConditionGorm}
 */
proto.external.BetweenConditionGorm.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setField(value);
      break;
    case 2:
      var value = new proto.external.BetweenConditionValueGorm;
      reader.readMessage(value,proto.external.BetweenConditionValueGorm.deserializeBinaryFromReader);
      msg.setFrom(value);
      break;
    case 3:
      var value = new proto.external.BetweenConditionValueGorm;
      reader.readMessage(value,proto.external.BetweenConditionValueGorm.deserializeBinaryFromReader);
      msg.setTo(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.external.BetweenConditionGorm.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.external.BetweenConditionGorm.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.external.BetweenConditionGorm} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.external.BetweenConditionGorm.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getField();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getFrom();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.external.BetweenConditionValueGorm.serializeBinaryToWriter
    );
  }
  f = message.getTo();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.external.BetweenConditionValueGorm.serializeBinaryToWriter
    );
  }
};


/**
 * optional string field = 1;
 * @return {string}
 */
proto.external.BetweenConditionGorm.prototype.getField = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.external.BetweenConditionGorm} returns this
 */
proto.external.BetweenConditionGorm.prototype.setField = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional BetweenConditionValueGorm from = 2;
 * @return {?proto.external.BetweenConditionValueGorm}
 */
proto.external.BetweenConditionGorm.prototype.getFrom = function() {
  return /** @type{?proto.external.BetweenConditionValueGorm} */ (
    jspb.Message.getWrapperField(this, proto.external.BetweenConditionValueGorm, 2));
};


/**
 * @param {?proto.external.BetweenConditionValueGorm|undefined} value
 * @return {!proto.external.BetweenConditionGorm} returns this
*/
proto.external.BetweenConditionGorm.prototype.setFrom = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.external.BetweenConditionGorm} returns this
 */
proto.external.BetweenConditionGorm.prototype.clearFrom = function() {
  return this.setFrom(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.external.BetweenConditionGorm.prototype.hasFrom = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional BetweenConditionValueGorm to = 3;
 * @return {?proto.external.BetweenConditionValueGorm}
 */
proto.external.BetweenConditionGorm.prototype.getTo = function() {
  return /** @type{?proto.external.BetweenConditionValueGorm} */ (
    jspb.Message.getWrapperField(this, proto.external.BetweenConditionValueGorm, 3));
};


/**
 * @param {?proto.external.BetweenConditionValueGorm|undefined} value
 * @return {!proto.external.BetweenConditionGorm} returns this
*/
proto.external.BetweenConditionGorm.prototype.setTo = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.external.BetweenConditionGorm} returns this
 */
proto.external.BetweenConditionGorm.prototype.clearTo = function() {
  return this.setTo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.external.BetweenConditionGorm.prototype.hasTo = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.external.LikeConditionGorm.prototype.toObject = function(opt_includeInstance) {
  return proto.external.LikeConditionGorm.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.external.LikeConditionGorm} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.external.LikeConditionGorm.toObject = function(includeInstance, msg) {
  var f, obj = {
    field: jspb.Message.getFieldWithDefault(msg, 1, ""),
    value: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.external.LikeConditionGorm}
 */
proto.external.LikeConditionGorm.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.external.LikeConditionGorm;
  return proto.external.LikeConditionGorm.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.external.LikeConditionGorm} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.external.LikeConditionGorm}
 */
proto.external.LikeConditionGorm.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setField(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setValue(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.external.LikeConditionGorm.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.external.LikeConditionGorm.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.external.LikeConditionGorm} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.external.LikeConditionGorm.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getField();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getValue();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string field = 1;
 * @return {string}
 */
proto.external.LikeConditionGorm.prototype.getField = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.external.LikeConditionGorm} returns this
 */
proto.external.LikeConditionGorm.prototype.setField = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string value = 2;
 * @return {string}
 */
proto.external.LikeConditionGorm.prototype.getValue = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.external.LikeConditionGorm} returns this
 */
proto.external.LikeConditionGorm.prototype.setValue = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.external.ConditionValueGorm.oneofGroups_ = [[1,2,3,4]];

/**
 * @enum {number}
 */
proto.external.ConditionValueGorm.ValueCase = {
  VALUE_NOT_SET: 0,
  INT64VALUE: 1,
  FLOAT64VALUE: 2,
  STRINGVALUE: 3,
  BOOLVALUE: 4
};

/**
 * @return {proto.external.ConditionValueGorm.ValueCase}
 */
proto.external.ConditionValueGorm.prototype.getValueCase = function() {
  return /** @type {proto.external.ConditionValueGorm.ValueCase} */(jspb.Message.computeOneofCase(this, proto.external.ConditionValueGorm.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.external.ConditionValueGorm.prototype.toObject = function(opt_includeInstance) {
  return proto.external.ConditionValueGorm.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.external.ConditionValueGorm} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.external.ConditionValueGorm.toObject = function(includeInstance, msg) {
  var f, obj = {
    int64value: jspb.Message.getFieldWithDefault(msg, 1, 0),
    float64value: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0),
    stringvalue: jspb.Message.getFieldWithDefault(msg, 3, ""),
    boolvalue: jspb.Message.getBooleanFieldWithDefault(msg, 4, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.external.ConditionValueGorm}
 */
proto.external.ConditionValueGorm.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.external.ConditionValueGorm;
  return proto.external.ConditionValueGorm.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.external.ConditionValueGorm} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.external.ConditionValueGorm}
 */
proto.external.ConditionValueGorm.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setInt64value(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setFloat64value(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setStringvalue(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setBoolvalue(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.external.ConditionValueGorm.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.external.ConditionValueGorm.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.external.ConditionValueGorm} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.external.ConditionValueGorm.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {number} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeDouble(
      2,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 3));
  if (f != null) {
    writer.writeString(
      3,
      f
    );
  }
  f = /** @type {boolean} */ (jspb.Message.getField(message, 4));
  if (f != null) {
    writer.writeBool(
      4,
      f
    );
  }
};


/**
 * optional int64 int64Value = 1;
 * @return {number}
 */
proto.external.ConditionValueGorm.prototype.getInt64value = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.external.ConditionValueGorm} returns this
 */
proto.external.ConditionValueGorm.prototype.setInt64value = function(value) {
  return jspb.Message.setOneofField(this, 1, proto.external.ConditionValueGorm.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.external.ConditionValueGorm} returns this
 */
proto.external.ConditionValueGorm.prototype.clearInt64value = function() {
  return jspb.Message.setOneofField(this, 1, proto.external.ConditionValueGorm.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.external.ConditionValueGorm.prototype.hasInt64value = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional double float64Value = 2;
 * @return {number}
 */
proto.external.ConditionValueGorm.prototype.getFloat64value = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.external.ConditionValueGorm} returns this
 */
proto.external.ConditionValueGorm.prototype.setFloat64value = function(value) {
  return jspb.Message.setOneofField(this, 2, proto.external.ConditionValueGorm.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.external.ConditionValueGorm} returns this
 */
proto.external.ConditionValueGorm.prototype.clearFloat64value = function() {
  return jspb.Message.setOneofField(this, 2, proto.external.ConditionValueGorm.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.external.ConditionValueGorm.prototype.hasFloat64value = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional string stringValue = 3;
 * @return {string}
 */
proto.external.ConditionValueGorm.prototype.getStringvalue = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.external.ConditionValueGorm} returns this
 */
proto.external.ConditionValueGorm.prototype.setStringvalue = function(value) {
  return jspb.Message.setOneofField(this, 3, proto.external.ConditionValueGorm.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.external.ConditionValueGorm} returns this
 */
proto.external.ConditionValueGorm.prototype.clearStringvalue = function() {
  return jspb.Message.setOneofField(this, 3, proto.external.ConditionValueGorm.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.external.ConditionValueGorm.prototype.hasStringvalue = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional bool boolValue = 4;
 * @return {boolean}
 */
proto.external.ConditionValueGorm.prototype.getBoolvalue = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.external.ConditionValueGorm} returns this
 */
proto.external.ConditionValueGorm.prototype.setBoolvalue = function(value) {
  return jspb.Message.setOneofField(this, 4, proto.external.ConditionValueGorm.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.external.ConditionValueGorm} returns this
 */
proto.external.ConditionValueGorm.prototype.clearBoolvalue = function() {
  return jspb.Message.setOneofField(this, 4, proto.external.ConditionValueGorm.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.external.ConditionValueGorm.prototype.hasBoolvalue = function() {
  return jspb.Message.getField(this, 4) != null;
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.external.BetweenConditionValueGorm.oneofGroups_ = [[1,2]];

/**
 * @enum {number}
 */
proto.external.BetweenConditionValueGorm.ValueCase = {
  VALUE_NOT_SET: 0,
  INT64VALUE: 1,
  FLOAT64VALUE: 2
};

/**
 * @return {proto.external.BetweenConditionValueGorm.ValueCase}
 */
proto.external.BetweenConditionValueGorm.prototype.getValueCase = function() {
  return /** @type {proto.external.BetweenConditionValueGorm.ValueCase} */(jspb.Message.computeOneofCase(this, proto.external.BetweenConditionValueGorm.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.external.BetweenConditionValueGorm.prototype.toObject = function(opt_includeInstance) {
  return proto.external.BetweenConditionValueGorm.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.external.BetweenConditionValueGorm} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.external.BetweenConditionValueGorm.toObject = function(includeInstance, msg) {
  var f, obj = {
    int64value: jspb.Message.getFieldWithDefault(msg, 1, 0),
    float64value: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.external.BetweenConditionValueGorm}
 */
proto.external.BetweenConditionValueGorm.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.external.BetweenConditionValueGorm;
  return proto.external.BetweenConditionValueGorm.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.external.BetweenConditionValueGorm} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.external.BetweenConditionValueGorm}
 */
proto.external.BetweenConditionValueGorm.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setInt64value(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setFloat64value(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.external.BetweenConditionValueGorm.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.external.BetweenConditionValueGorm.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.external.BetweenConditionValueGorm} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.external.BetweenConditionValueGorm.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {number} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeDouble(
      2,
      f
    );
  }
};


/**
 * optional int64 int64Value = 1;
 * @return {number}
 */
proto.external.BetweenConditionValueGorm.prototype.getInt64value = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.external.BetweenConditionValueGorm} returns this
 */
proto.external.BetweenConditionValueGorm.prototype.setInt64value = function(value) {
  return jspb.Message.setOneofField(this, 1, proto.external.BetweenConditionValueGorm.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.external.BetweenConditionValueGorm} returns this
 */
proto.external.BetweenConditionValueGorm.prototype.clearInt64value = function() {
  return jspb.Message.setOneofField(this, 1, proto.external.BetweenConditionValueGorm.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.external.BetweenConditionValueGorm.prototype.hasInt64value = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional double float64Value = 2;
 * @return {number}
 */
proto.external.BetweenConditionValueGorm.prototype.getFloat64value = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.external.BetweenConditionValueGorm} returns this
 */
proto.external.BetweenConditionValueGorm.prototype.setFloat64value = function(value) {
  return jspb.Message.setOneofField(this, 2, proto.external.BetweenConditionValueGorm.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.external.BetweenConditionValueGorm} returns this
 */
proto.external.BetweenConditionValueGorm.prototype.clearFloat64value = function() {
  return jspb.Message.setOneofField(this, 2, proto.external.BetweenConditionValueGorm.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.external.BetweenConditionValueGorm.prototype.hasFloat64value = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.external.OrderCondition.prototype.toObject = function(opt_includeInstance) {
  return proto.external.OrderCondition.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.external.OrderCondition} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.external.OrderCondition.toObject = function(includeInstance, msg) {
  var f, obj = {
    field: jspb.Message.getFieldWithDefault(msg, 1, ""),
    ordertype: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.external.OrderCondition}
 */
proto.external.OrderCondition.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.external.OrderCondition;
  return proto.external.OrderCondition.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.external.OrderCondition} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.external.OrderCondition}
 */
proto.external.OrderCondition.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setField(value);
      break;
    case 2:
      var value = /** @type {!proto.external.OrderType} */ (reader.readEnum());
      msg.setOrdertype(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.external.OrderCondition.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.external.OrderCondition.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.external.OrderCondition} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.external.OrderCondition.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getField();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getOrdertype();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
};


/**
 * optional string field = 1;
 * @return {string}
 */
proto.external.OrderCondition.prototype.getField = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.external.OrderCondition} returns this
 */
proto.external.OrderCondition.prototype.setField = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional OrderType orderType = 2;
 * @return {!proto.external.OrderType}
 */
proto.external.OrderCondition.prototype.getOrdertype = function() {
  return /** @type {!proto.external.OrderType} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.external.OrderType} value
 * @return {!proto.external.OrderCondition} returns this
 */
proto.external.OrderCondition.prototype.setOrdertype = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * @enum {number}
 */
proto.external.OrderType = {
  NOT_SET: 0,
  ASC: 1,
  DESC: 2
};

goog.object.extend(exports, proto.external);
