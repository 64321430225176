import React from 'react';

import Spinner from 'components/Spinner';
import useRouter from 'hooks/useRouter';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import * as actions from 'store/actions';
import SupplierDetails from 'Workflows/Shared/CostManager/SupplierDetails';

const TenantSupplier = () => {
  const { query } = useRouter();
  const dispatch = useDispatch();

  const [openEdit, setOpenEdit] = React.useState(false);

  const loaded = useSelector((state) => state.costManager.supplier.loaded);
  const loading = useSelector((state) => state.costManager.supplier.loading);
  const error = useSelector((state) => state.costManager.supplier.error);
  const details = useSelector((state) => state.costManager.supplier);

  const handleSave = (form) => {
    dispatch(actions.updateTenantCostSupplierDetails(form))
      .then(() => {
        dispatch(actions.getTenantCostSupplierDetails(query.index));
        toast.success('Cost Supplier updated');
        setOpenEdit(false);
      })
      .catch((err) => {
        toast.error(err);
      });
  };

  if (loading || loaded) {
    return <Spinner />;
  }

  if (error) {
    return <div>{error}</div>;
  }

  return (
    <>
      <SupplierDetails
        openEdit={openEdit}
        setOpenEdit={setOpenEdit}
        pathName={`Tenants/${query.id}/TenantCostManager/${query.index}/SupplierDetails`}
        handleSave={handleSave}
        details={details}
      />
    </>
  );
};

export default TenantSupplier;
