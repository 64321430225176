import { metaData } from 'config/config';
import wrappers from 'google-protobuf/google/protobuf/wrappers_pb';
import { formatForBackEnd } from 'helpers/utils';

import {
  GetContractsRequest,
  CreateContractRequest,
  UpdateContractRequest
} from '../../api/contract_grpc_web_pb';
import {
  GetStrategyByContractIDRequest,
  GetTurnoversRequest,
  CreateTurnoverRequest,
  UpdateTurnoverRequest,
  DeleteTurnoverRequest,
  GetTenantOptionsRequest,
  CreateTenantOptionRequest,
  UpdateTenantOptionsRequest,
  UpdateStrategyRequest,
  GetClausesByContractRequest,
  CreateClauseRequest,
  DeleteClauseRequest,
  GetCashFlowCurrentRequest,
  UpdateClauseRequest,
  GetTenantDetailsByContractIDRequest,
  UpdateTenantDetailsRequest,
  DeleteTenantOptionsRequest
} from '../../api/tenant_grpc_web_pb';
import { GetUnitsRequest } from '../../api/unit_grpc_web_pb';
import { UnitService } from '../services/asset';
import {
  ContractClauseService,
  ContractService,
  TurnoverService,
  TenantDetailService,
  OptionsService,
  StrategyService
} from '../services/tenant';

import * as actionTypes from './actionTypes';

// Contracts
const getContractStart = () => ({
  type: actionTypes.GET_TENANT_LEASING_CONTRACT_START
});

const getContractSuccess = (contract) => ({
  type: actionTypes.GET_TENANT_LEASING_CONTRACT_SUCCESS,
  contract
});

const getContractFail = (error) => ({
  type: actionTypes.GET_TENANT_LEASING_CONTRACT_FAIL,
  error
});

export const getContractById = (id) => (dispatch) => {
  dispatch(getContractStart());
  const request = new GetContractsRequest();
  request.setTenantid(id);

  return new Promise((resolve, reject) => {
    ContractService.getContractsByTenant(request, metaData(), (err, response) => {
      if (err) {
        dispatch(getContractFail(err.message));
        reject(err.message);
      } else {
        dispatch(getContractSuccess(response.toObject().contractsList));
        resolve(response.toObject());
      }
    });
  });
};

// Create contract
export const createLeasingContract = (form) => () => {
  if (form.id) {
    const request = new UpdateContractRequest();
    request.setContractid(form.id);
    request.setUnitidsList(form.unitidsList);
    const startdate = new wrappers.StringValue();
    startdate.setValue(formatForBackEnd(form.startdate));
    request.setStartdate(startdate);
    const enddate = new wrappers.StringValue();
    enddate.setValue(formatForBackEnd(form.enddate));
    request.setEnddate(enddate);
    const number = new wrappers.StringValue();
    number.setValue(form.number);
    request.setNumber(number);

    return new Promise((resolve, reject) => {
      ContractService.updateContract(request, metaData(), (err, response) => {
        if (err) {
          reject(err.message);
        } else {
          resolve(response.toObject());
        }
      });
    });
  }
  const request = new CreateContractRequest();
  request.setAssetid(form.assetid);
  request.setTenantid(form.tenantid);
  request.setUnitidsList(form.unitidsList);

  request.setNumber(form.number);
  request.setStartdate(formatForBackEnd(form.startdate));
  request.setEnddate(formatForBackEnd(form.enddate));

  return new Promise((resolve, reject) => {
    ContractService.createContract(request, metaData(), (err, response) => {
      if (err) {
        reject(err.message);
      } else {
        resolve(response.toObject());
      }
    });
  });
};

export const UpdateLeasingContract = (id, active) => () => {
  const request = new UpdateContractRequest();
  request.setContractid(id);
  const inPlace = new wrappers.StringValue();
  inPlace.setValue(active ? 'true' : 'false');
  request.setInplace(inPlace);

  return new Promise((resolve, reject) => {
    ContractService.updateContract(request, metaData(), (err, response) => {
      if (err) {
        reject(err.message);
      } else {
        resolve(response.toObject());
      }
    });
  });
};

// Units
const getUnitsStart = () => ({
  type: actionTypes.GET_UNITS_START
});

const getUnitsSuccess = (units) => ({
  type: actionTypes.GET_UNITS_SUCCESS,
  units
});

const getUnitsFail = (error) => ({
  type: actionTypes.GET_UNITS_FAIL,
  error
});

export const getUnits = (id) => (dispatch) => {
  dispatch(getUnitsStart());
  const request = new GetUnitsRequest();
  request.setAssetid(id);

  return new Promise((resolve, reject) => {
    UnitService.getUnits(request, metaData(), (err, response) => {
      if (err) {
        dispatch(getUnitsFail(err.message));
        reject(err.message);
      } else {
        dispatch(getUnitsSuccess(response.toObject().unitsList));
        resolve(response.toObject());
      }
    });
  });
};

// Strategy
const getStrategyStart = () => ({
  type: actionTypes.GET_TENANT_LEASING_STRATEGY_START
});

const getStrategySuccess = (strategy) => ({
  type: actionTypes.GET_TENANT_LEASING_STRATEGY_SUCCESS,
  strategy
});

const getStrategyFail = (error) => ({
  type: actionTypes.GET_TENANT_LEASING_STRATEGY_FAIL,
  error
});

export const getLeasingStrategy = (id) => (dispatch) => {
  dispatch(getStrategyStart());
  const request = new GetStrategyByContractIDRequest();
  request.setId(id);

  return new Promise((resolve, reject) => {
    StrategyService.getStrategyByContractID(request, metaData(), (err, response) => {
      if (err) {
        dispatch(getStrategyFail(err.message));
        reject(err.message);
      } else {
        dispatch(getStrategySuccess(response.toObject()));
        resolve(response.toObject());
      }
    });
  });
};

export const updateLeasingStrategy = (id, strategy) => () => {
  const request = new UpdateStrategyRequest();
  request.setId(id);
  request.setStrategytext(strategy);

  return new Promise((resolve, reject) => {
    StrategyService.updateStrategy(request, metaData(), (err, response) => {
      if (err) {
        reject(err.message);
      } else {
        resolve(response.toObject());
      }
    });
  });
};

// Budget
const getBudgetStart = () => ({
  type: actionTypes.GET_TENANT_LEASING_BUDGET_START
});

const getBudgetSuccess = (budget) => ({
  type: actionTypes.GET_TENANT_LEASING_BUDGET_SUCCESS,
  budget
});

const getBudgetFail = (error) => ({
  type: actionTypes.GET_TENANT_LEASING_BUDGET_FAIL,
  error
});

export const getLeasingBudget = (id) => (dispatch) => {
  dispatch(getBudgetStart());
  const request = new GetClausesByContractRequest();
  request.setContractid(id);
  request.setContracted(false);
  return new Promise((resolve, reject) => {
    ContractClauseService.getClausesByContract(request, metaData(), (err, response) => {
      if (err) {
        dispatch(getBudgetFail(err.message));
        reject(err.message);
      } else {
        dispatch(getBudgetSuccess(response.toObject().clausesList));
        resolve(response.toObject());
      }
    });
  });
};

// Turnover
const getTurnoverStart = () => ({
  type: actionTypes.GET_TENANT_LEASING_TURNOVER_START
});

const getTurnoverSuccess = (turnover) => ({
  type: actionTypes.GET_TENANT_LEASING_TURNOVER_SUCCESS,
  turnover
});

const getTurnoverFail = (error) => ({
  type: actionTypes.GET_TENANT_LEASING_TURNOVER_FAIL,
  error
});

export const getLeasingTurnover = (id) => (dispatch) => {
  dispatch(getTurnoverStart());

  const request = new GetTurnoversRequest();
  request.setTenantcontractid(id);

  return new Promise((resolve, reject) => {
    TurnoverService.getTurnovers(request, metaData(), (err, response) => {
      if (err) {
        dispatch(getTurnoverFail(err.message));
        reject(err.message);
      } else {
        dispatch(getTurnoverSuccess(response.toObject().turnoversList));
        resolve(response.toObject());
      }
    });
  });
};

export const updateLeasingTurnover = (form, id, selectedYear) => () => {
  if (form.id) {
    const monthToPass = form.month.toString().length > 1 ? form.month : `0${form.month}`;
    const request = new UpdateTurnoverRequest();

    const yearMonth = new wrappers.StringValue();
    yearMonth.setValue(`${selectedYear}-${monthToPass}`);

    const turnover = new wrappers.FloatValue();
    turnover.setValue(form.turnover);

    request.setId(form.id);
    request.setYearmonth(yearMonth);
    request.setTurnover(turnover);

    return new Promise((resolve, reject) => {
      TurnoverService.updateTurnover(request, metaData(), (err, response) => {
        if (err) {
          reject(err.message);
        } else {
          resolve(response.toObject());
        }
      });
    });
  }

  const monthToPass = form.month.toString().length > 1 ? form.month : `0${form.month}`;
  const request = new CreateTurnoverRequest();

  request.setTenantcontractid(id);
  request.setYearmonth(`${form.year}-${monthToPass}`);
  request.setTurnover(form.turnover);

  return new Promise((resolve, reject) => {
    TurnoverService.createTurnover(request, metaData(), (err, response) => {
      if (err) {
        reject(err.message);
      } else {
        resolve(response.toObject());
      }
    });
  });
};

export const deleteLeasingTurnover = (id) => () => {
  const request = new DeleteTurnoverRequest();

  request.setId(id);
  return new Promise((resolve, reject) => {
    TurnoverService.deleteTurnover(request, metaData(), (err, response) => {
      if (err) {
        reject(err.message);
      } else {
        resolve(response.toObject());
      }
    });
  });
};
// Tenant Options
const getTenantOptionsStart = () => ({
  type: actionTypes.GET_TENANT_LEASING_OPTIONS_START
});

const getTenantOptionsSuccess = (options) => ({
  type: actionTypes.GET_TENANT_LEASING_OPTIONS_SUCCESS,
  options
});

const getTenantOptionsFail = (error) => ({
  type: actionTypes.GET_TENANT_LEASING_OPTIONS_FAIL,
  error
});

export const getTenantOptions = (id) => (dispatch) => {
  dispatch(getTenantOptionsStart());

  const request = new GetTenantOptionsRequest();
  request.setContractid(id);

  return new Promise((resolve, reject) => {
    OptionsService.getTenantOptions(request, metaData(), (err, response) => {
      if (err) {
        dispatch(getTenantOptionsFail(err.message));
        reject(err.message);
      } else {
        dispatch(getTenantOptionsSuccess(response.toObject().tenantoptionsList));
        resolve(response.toObject());
      }
    });
  });
};

export const updateLeasingOptions = (form, id) => () => {
  if (form.id) {
    const request = new UpdateTenantOptionsRequest();

    const type = new wrappers.StringValue();
    type.setValue(form.type);

    const description = new wrappers.StringValue();
    description.setValue(form.description);

    const effectivedate = new wrappers.StringValue();
    effectivedate.setValue(formatForBackEnd(form.effectivedate));

    const noticeperiodstart = new wrappers.StringValue();
    noticeperiodstart.setValue(formatForBackEnd(form.noticeperiodstart));

    const noticeperiodend = new wrappers.StringValue();
    noticeperiodend.setValue(formatForBackEnd(form.noticeperiodend));

    request.setId(form.id);
    request.setType(type);
    request.setDescription(description);
    request.setEffectivedate(effectivedate);
    request.setNoticeperiodstart(noticeperiodstart);
    request.setNoticeperiodend(noticeperiodend);

    return new Promise((resolve, reject) => {
      OptionsService.updateTenantOptions(request, metaData(), (err, response) => {
        if (err) {
          reject(err.message);
        } else {
          resolve(response.toObject());
        }
      });
    });
  }

  const request = new CreateTenantOptionRequest();

  request.setContractid(id);
  request.setType(form.type);
  request.setDescription(form.description);
  request.setEffectivedate(formatForBackEnd(form.effectivedate));
  request.setNoticeperiodstart(formatForBackEnd(form.noticeperiodstart));
  request.setNoticeperiodend(formatForBackEnd(form.noticeperiodend));

  return new Promise((resolve, reject) => {
    OptionsService.createTenantOption(request, metaData(), (err, response) => {
      if (err) {
        reject(err.message);
      } else {
        resolve(response.toObject());
      }
    });
  });
};

export const deleteLeasingOption = (id) => () => {
  const request = new DeleteTenantOptionsRequest();

  request.setId(id);
  return new Promise((resolve, reject) => {
    OptionsService.deleteTenantOptions(request, metaData(), (err, response) => {
      if (err) {
        reject(err.message);
      } else {
        resolve(response.toObject());
      }
    });
  });
};

// Contract Clauses
const getClausesStart = () => ({
  type: actionTypes.GET_LEASING_CONTRACT_CLAUSES_START
});

const getClausesSuccess = (clauses) => ({
  type: actionTypes.GET_LEASING_CONTRACT_CLAUSES_SUCCESS,
  clauses
});

const getClausesFail = (error) => ({
  type: actionTypes.GET_LEASING_CONTRACT_CLAUSES_FAIL,
  error
});

export const getClausesByContract = (id) => (dispatch) => {
  dispatch(getClausesStart());
  const request = new GetClausesByContractRequest();
  request.setContractid(id);
  request.setContracted(true);

  return new Promise((resolve, reject) => {
    ContractClauseService.getClausesByContract(request, metaData(), (err, response) => {
      if (err) {
        dispatch(getClausesFail(err.message));
        reject(err.message);
      } else {
        dispatch(getClausesSuccess(response.toObject().clausesList));
        resolve(response.toObject());
      }
    });
  });
};

function assignFormData(form) {
  if (form.rentSchedule === 'Custom') {
    const { startingBaseRent, ...updatedForm } = form;
    return JSON.stringify(updatedForm);
  }
  return JSON.stringify(form);
}

// Create contract Clause
export const createLeasingClause = (form, type, id, clauseId, contracted) => () => {
  let preparedForm = form;
  if (type === 'Service Charge') {
    preparedForm = {
      chargePerSqm: form.chargePerSqm,
      indexationSchedule: form.indexationSchedule,
      payableFrequency: form.payableFrequency
    };
  }

  const typedata = assignFormData(preparedForm);

  if (clauseId) {
    const request = new UpdateClauseRequest();
    const typeValue = new wrappers.StringValue();
    typeValue.setValue(type);
    const tenantcontractId = new wrappers.StringValue();
    tenantcontractId.setValue(id);
    request.setId(clauseId);
    request.setTenantcontractid(tenantcontractId);
    request.setType(typeValue);
    request.setTypedata(typedata);
    return new Promise((resolve, reject) => {
      ContractClauseService.updateClause(request, metaData(), (err, response) => {
        if (err) {
          reject(err.message);
        } else {
          resolve(response.toObject());
        }
      });
    });
  }

  const request = new CreateClauseRequest();
  request.setTenantcontractid(id);
  request.setType(type);
  request.setContracted(contracted);
  request.setTypedata(typedata);
  return new Promise((resolve, reject) => {
    ContractClauseService.createClause(request, metaData(), (err, response) => {
      if (err) {
        reject(err.message);
      } else {
        resolve(response.toObject());
      }
    });
  });
};

export const deleteLeasingClause = (id) => () => {
  const request = new DeleteClauseRequest();

  request.setId(id);
  return new Promise((resolve, reject) => {
    ContractClauseService.deleteClause(request, metaData(), (err, response) => {
      if (err) {
        reject(err.message);
      } else {
        resolve(response.toObject());
      }
    });
  });
};

// Cashflow
const getCashflowStart = () => ({
  type: actionTypes.GET_TENANT_LEASING_CASHFLOW_START
});

const getCashflowSuccess = (cashflow) => ({
  type: actionTypes.GET_TENANT_LEASING_CASHFLOW_SUCCESS,
  cashflow
});

const getCashflowFail = (error) => ({
  type: actionTypes.GET_TENANT_LEASING_CASHFLOW_FAIL,
  error
});

export const getCashflow = (id) => (dispatch) => {
  dispatch(getCashflowStart());
  const request = new GetCashFlowCurrentRequest();
  request.setTenantcontractid(id);
  request.setContracted(true);

  return new Promise((resolve, reject) => {
    ContractClauseService.getCashFlowCurrent(request, metaData(), (err, response) => {
      if (err) {
        dispatch(getCashflowFail(err.message));
        reject(err.message);
      } else {
        dispatch(getCashflowSuccess(response.toObject().currentList));
        resolve(response.toObject());
      }
    });
  });
};

const getCashflowBudgetStart = () => ({
  type: actionTypes.GET_TENANT_LEASING_CASHFLOW_BUDGET_START
});

const getCashflowBudgetSuccess = (budget) => ({
  type: actionTypes.GET_TENANT_LEASING_CASHFLOW_BUDGET_SUCCESS,
  budget
});

const getCashflowBudgetFail = (error) => ({
  type: actionTypes.GET_TENANT_LEASING_CASHFLOW_BUDGET_FAIL,
  error
});

export const getCashflowBudget = (id) => (dispatch) => {
  dispatch(getCashflowBudgetStart());
  const request = new GetCashFlowCurrentRequest();
  request.setTenantcontractid(id);
  request.setContracted(false);

  return new Promise((resolve, reject) => {
    ContractClauseService.getCashFlowCurrent(request, metaData(), (err, response) => {
      if (err) {
        dispatch(getCashflowBudgetFail(err.message));
        reject(err.message);
      } else {
        dispatch(getCashflowBudgetSuccess(response.toObject().currentList));
        resolve(response.toObject());
      }
    });
  });
};

// Get Tenant Leasing Manager Details
const getTenantDetailsStart = () => ({
  type: actionTypes.GET_TENANT_LEASING_DETAILS_START
});

const getTenantDetailsSuccess = (details) => ({
  type: actionTypes.GET_TENANT_LEASING_DETAILS_SUCCESS,
  details
});

const getTenantDetailsFail = (error) => ({
  type: actionTypes.GET_TENANT_LEASING_DETAILS_FAIL,
  error
});

export const getTenantLeasingDetails = (id) => (dispatch) => {
  dispatch(getTenantDetailsStart());
  const request = new GetTenantDetailsByContractIDRequest();
  request.setContractid(id);

  return new Promise((resolve, reject) => {
    TenantDetailService.getTenantDetailsByContractID(request, metaData(), (err, response) => {
      if (err) {
        dispatch(getTenantDetailsFail(err.message));
        reject(err.message);
      } else {
        dispatch(getTenantDetailsSuccess(response.toObject()));
        resolve(response.toObject());
      }
    });
  });
};

export const updateTenantLeasingDetails = (form) => () => {
  const request = new UpdateTenantDetailsRequest();
  const maincontact = new wrappers.StringValue();
  const phone = new wrappers.StringValue();
  const email = new wrappers.StringValue();
  const position = new wrappers.StringValue();
  const companyName = new wrappers.StringValue();
  const tradingName = new wrappers.StringValue();
  const industry = new wrappers.StringValue();
  const headofficeaddress = new wrappers.StringValue();
  const notes = new wrappers.StringValue();

  companyName.setValue(form.companyname);
  tradingName.setValue(form.tradingname);
  industry.setValue(form.industry);
  headofficeaddress.setValue(form.headofficeaddress);
  notes.setValue(form.notes);
  maincontact.setValue(form.maincontact);
  position.setValue(form.position);
  email.setValue(form.email);
  phone.setValue(form.phone);

  request.setId(form.id);
  request.setCompanyname(companyName);
  request.setTradingname(tradingName);
  request.setIndustry(industry);
  request.setHeadOfficeAddress(headofficeaddress);
  request.setNotes(notes);
  request.setMainContact(maincontact);
  request.setPosition(position);
  request.setEmail(email);
  request.setPhone(phone);

  return new Promise((resolve, reject) => {
    TenantDetailService.updateTenantDetails(request, metaData(), (err, response) => {
      if (err) {
        reject(err.message);
      } else {
        resolve(response.toObject());
      }
    });
  });
};
