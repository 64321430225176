/* eslint-disable no-case-declarations */
import * as actionTypes from '../actions/actionTypes';

export const userInitialState = {
  user: {},
  userRoles: [],
  userPermissions: {
    permissionsList: [
      {
        id: 'f3a6f93f-d288-455e-abbd-9d779a67c7f5',
        title: 'portfolio',
        rightsList: []
      },
      {
        id: 'f3a6f93f-d288-455e-abbd-9d779a67c7f5',
        title: 'asset',
        rightsList: []
      },
      {
        id: 'f3a6f93f-d288-455e-abbd-9d779a67c7f5',
        title: 'tenant',
        rightsList: []
      },
      {
        id: 'f3a6f93f-d288-455e-abbd-9d779a67c7f5',
        title: 'Home',
        rightsList: []
      },
      {
        id: 'f3a6f93f-d288-455e-abbd-9d779a67c7f5',
        title: 'Users',
        rightsList: []
      }
    ],
    roletitle: ''
  },
  loading: false,
  loaded: false,
  userRolesLoading: false,
  userRolesLoaded: false,
  error: null,
  userRegistered: false,
  auth: {
    authIsEmpty: true,
    uid: '',
    photoURL: '',
    loaded: false,
    token: ''
  }
};

const reducer = (state = userInitialState, action) => {
  switch (action.type) {
    case actionTypes.GET_USER_START:
      return {
        ...state,
        loading: true
      };
    case actionTypes.GET_USER_SUCCESS:
      return {
        ...state,
        user: action.user,
        loading: false,
        loaded: true,
        userRegistered: true
      };
    case actionTypes.GET_USER_FAIL:
      return {
        ...state,
        error: action.error,
        loading: false,
        loaded: true,
        userRegistered: false
      };
    case actionTypes.USER_SIGNOUT:
      return {
        ...state,
        ...userInitialState
      };
    case actionTypes.REGISTER_USER_START:
      return {
        ...state,
        loading: true
      };
    case actionTypes.SIGN_USER_FAIL: {
      return {
        ...state,
        auth: {
          ...state.auth,
          authIsEmpty: true,
          uid: '',
          photoURL: '',
          loaded: true
        }
      };
    }
    case actionTypes.SIGN_USER_SUCCESS: {
      return {
        ...state,
        auth: {
          ...state.auth,
          authIsEmpty: false,
          uid: action.auth.uid,
          photoURL: action.auth.photoURL,
          token: action.auth.accessToken,
          loaded: true
        }
      };
    }
    case actionTypes.REGISTER_USER_SUCCESS:
      return {
        ...state,
        user: action.user,
        loading: false,
        userRegistered: true,
        error: null
      };
    case actionTypes.REGISTER_USER_FAIL:
      return {
        ...state,
        error: action.error,
        loading: false
      };
    case actionTypes.GET_USER_ROLES_START:
      return {
        ...state,
        userRolesLoading: true
      };
    case actionTypes.GET_USER_ROLES_SUCCESS:
      const { userRoleId, userRoles } = action;
      const userPermissions =
        userRoles.find((userRole) => userRole.id === userRoleId) || state.userPermissions;

      return {
        ...state,
        userRoles: [
          ...userRoles,
          { id: '1fd6d279-98e3-463e-b996-898041bb3a87', roletitle: 'default' }
        ],
        userPermissions,
        userRolesLoading: false,
        userRolesLoaded: true
      };
    case actionTypes.GET_USER_ROLES_FAIL:
      return {
        ...state,
        error: action.error,
        userRolesLoading: false,
        userRolesLoaded: true
      };
    case actionTypes.REFRESH_USER_TOKEN:
      return {
        ...state,
        auth: {
          ...state.auth,
          token: action.token
        }
      };
    default:
      return state;
  }
};

export default reducer;
