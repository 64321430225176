import React, { Suspense, useEffect, useState } from 'react';

import { makeStyles } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Divider from '@material-ui/core/Divider';
import Paper from '@material-ui/core/Paper';
import useRouter from 'hooks/useRouter';
import { useDispatch } from 'react-redux';
import * as actions from 'store/actions';

import TenantPerformance from './TenantPerformance';
import Turnover from './Turnover';

const Cashflow = React.lazy(() => import('./Cashflow'));
const Footfall = React.lazy(() => import('./Footfall'));

const useStyles = makeStyles(() => ({
  paper: {
    boxShadow: '0px 3px 14px #0000000F',
    borderRadius: 4,
    height: '100%',
    textOverflow: 'ellipsis',
    overflow: 'auto'
  }
}));

const DividerComponent = () => (
  <Box mt={6} mb={6} width="100%" display="flex" justifyContent="center" alignItems="center">
    <Divider width="90%" style={{ boxShadow: '0px 3px 14px #0000000F' }} />
  </Box>
);

const Dashboard = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [tenants, setTenants] = useState([]);
  const [loaded, setLoaded] = useState(false);
  const { query } = useRouter();

  useEffect(() => {
    dispatch(actions.getTenants([], `Page1-PortfolioIds${query.id}`, [query.id]))
      .then((res) => {
        setTenants(res);
      })
      .finally(() => {
        setLoaded(true);
      });
    return () => {
      dispatch(actions.resetReports());
    };
  }, []);

  return (
    <Paper className={classes.paper}>
      <Box display="flex" justifyContent="center" alignItems="center" flexDirection="column">
        {loaded && tenants && tenants.length > 0 && (
          <>
            <TenantPerformance id={query.id} tenants={tenants} loaded={loaded} />
            <Turnover id={query.id} tenants={tenants} />
            <DividerComponent />
          </>
        )}

        <Suspense fallback={<div />}>
          <Footfall id={query.id} />
          <DividerComponent />
          <Cashflow />
        </Suspense>
      </Box>
    </Paper>
  );
};

export default Dashboard;
