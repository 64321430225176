/* eslint-disable no-console */
import React from 'react';

import { Box, makeStyles, Typography } from '@material-ui/core';
import BackupIcon from '@material-ui/icons/Backup';
import PropTypes from 'prop-types';
import Dropzone from 'react-dropzone';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: '#F2F3F7',
    border: '1px dashed #AAAAAA',
    width: '100%',
    padding: theme.spacing(2)
  },
  inner: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-around',
    alignItems: 'center',
    '&:focus': {
      outline: 'none'
    }
  },
  dividerText: {
    fontFamily: theme.openSans,
    fontSize: 14,
    color: '#7D7D7D'
  },
  icon: {
    color: theme.palette.primary.main,
    width: 45,
    height: 32,
    marginRight: 12
  },
  vertical: {
    borderLeft: '1px solid #D9D9D9',
    height: 16
  },
  dragNdrop: {
    color: '#5C5C5C',
    fontFamily: theme.fonts.openSans
  },
  browse: {
    border: '1px solid #5381EF',
    borderRadius: 50,
    padding: theme.spacing(1.5, 3),
    color: theme.palette.primary.main,
    fontFamily: theme.fonts.base,
    fontWeight: 'bold',
    cursor: 'pointer',
    [theme.breakpoints.down('sm')]: {
      width: 300
    }
  },
  responsive: {
    [theme.breakpoints.down('sm')]: {
      display: 'none'
    }
  },
  responsiveBox: {
    width: '48%',
    [theme.breakpoints.down('sm')]: {
      width: '80%'
    }
  }
}));

const Upload = ({ setAcceptedFiles }) => {
  const classes = useStyles();
  const [isDragged, setDrag] = React.useState(false);

  const handleFiles = (files) => {
    setAcceptedFiles(files);
  };

  return (
    <Box className={classes.root} style={isDragged ? { opacity: 0.5 } : {}}>
      <Dropzone
        onDrop={(acceptedFiles) => handleFiles(acceptedFiles)}
        onDragOver={() => setDrag(true)}
        onDragLeave={() => setDrag(false)}
        onDropAccepted={() => setDrag(false)}
        onDropRejected={() => setDrag(false)}
      >
        {({ getRootProps, getInputProps }) => (
          <Box {...getRootProps()} className={classes.inner}>
            <Box
              display="flex"
              alignItems="center"
              justifyContent="center"
              flexDirection="row"
              width="48%"
              className={classes.responsive}
            >
              <BackupIcon className={classes.icon} />
              <Typography className={classes.dragNdrop}>Drag and drop some files </Typography>
            </Box>

            <Box
              display="flex"
              flexDirection="column"
              justifyContent="center"
              alignItems="center"
              className={classes.responsive}
            >
              <div className={classes.vertical} />
              <Typography className={classes.dividerText}>or</Typography>

              <div className={classes.vertical} />
            </Box>

            <Box className={classes.responsiveBox} display="flex" justifyContent="center">
              <input {...getInputProps()} />
              <Typography className={classes.browse}>Browse files</Typography>
            </Box>
          </Box>
        )}
      </Dropzone>
    </Box>
  );
};

export default React.memo(Upload);

Upload.propTypes = {
  setAcceptedFiles: PropTypes.func.isRequired
};
