/* eslint-disable no-console */
import { useEffect, useState } from 'react';

import { authService, onAuthStateChanged } from 'config/firebase';
import { differenceInSeconds } from 'date-fns';
import useIsDev from 'hooks/useIsDev';
import { useDispatch } from 'react-redux';

import * as actions from '../store/actions';

export default function useAuth() {
  const isDev = useIsDev();
  const [auth, setAuth] = useState(null);
  const dispatch = useDispatch();

  // Auth Handler
  function authHandler() {
    onAuthStateChanged(authService, (user) => {
      if (!user) {
        return dispatch(actions.SignUserFail());
      }
      setAuth(user);
      dispatch(actions.SignUserSuccess(user));
      return user
        .getIdTokenResult()
        .then((userToken) => {
          if (!isDev) {
            setTimeout(() => {
              authHandler();
            }, differenceInSeconds(new Date(userToken.expirationTime), new Date()) * 1010);
            console.log('Your jwt Token is: \n', userToken.token);
          }
          dispatch(actions.refreshUserToken(userToken.token));
        })
        .catch((err) => {
          console.error('Error refreshing id token', err);
        });
    });
  }

  useEffect(() => {
    authHandler();
  }, []);

  return { auth };
}
