/* eslint-disable no-useless-escape */
/* eslint-disable implicit-arrow-linebreak */
// import mapValues from 'lodash-es/mapValues';
import { format, isAfter, addMonths, parseISO, formatISO } from 'date-fns';

import { activityFeed } from '../constants';

export const CHECK_IF_HASH_REGEX = new RegExp('[a-f0-9]{32}');

export const isHash = (str) => CHECK_IF_HASH_REGEX.test(str);

// Get activity message code and conver it into a message
export const getActivityFeedMessage = (id, component) => {
  const message = activityFeed.find((item) => item.id === id);

  if (!message) return 'Undefined Action';

  return `${message.name} ${component}`;
};

// Conver any number to comma seperated in thousands
export function numberWithCommas(numb, placeholder) {
  if (numb === 0) return numb;
  if (placeholder && !numb) {
    return placeholder;
  }

  if (!numb && !placeholder) {
    return 'N/A';
  }

  const numParts = numb.toString().split('.');
  const integerPart = numParts[0];
  const decimalPart = numParts[1] || '';

  const integerArray = integerPart.split('').reverse();
  const formattedArray = [];

  for (let i = 0; i < integerArray.length; i += 1) {
    if (i % 3 === 0 && i !== 0) {
      formattedArray.push(',');
    }
    formattedArray.push(integerArray[i]);
  }

  const formattedInteger = formattedArray.reverse().join('');
  const formattedNumber = decimalPart ? `${formattedInteger}.${decimalPart}` : formattedInteger;

  return formattedNumber;
}
/**
 * caps the first letter of a string
 */

export const capitalize = (str) => {
  if (typeof str !== 'string') return '';

  return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
};

/** Slice text to 7 characters, mostly used for hashIds */
export const shortenId = (id) => {
  if (typeof id !== 'string') return id;

  return id.substring(0, 7);
};

/** Converts minutes integer to representative string i.e. 15 -> 15min,  120 -> 2h */
export const minutesToString = (minutes) => (minutes < 60 ? `${minutes}min` : `${minutes / 60}h`);

/** Converts seconds to an `h m s` format */
export const secondsToHMS = (seconds) => {
  if (seconds < 60) {
    return `${seconds}s`;
  }

  if (seconds < 3600) {
    return `${Math.floor(seconds / 60)}m ${seconds % 60}s`;
  }

  return `${Math.floor(seconds / 3600)}h ${Math.floor((seconds % 3600) / 60)}m ${seconds % 60}s`;
};

// /** Converts any value of the object that is an array to a comma-separated string */
// export const convertObjArrayValuesToCsv = (obj) =>
//   mapValues(obj, (v) => (Array.isArray(v) ? v.join(',') : v));

/**
 * makes sure that it properly formats a JSON struct in order to be properly displayed within the
 * editor
 * @param code valid JSON
 * @returns String
 */
export const formatJSON = (code) => JSON.stringify(code, null, '\t');

export const validateEmail = (email) => {
  const re =
    /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(email);
};

// Get Proper string from floor number
export const getFloorName = (floor) => {
  if (floor === 'N/A') {
    return 'Floor';
  }
  switch (floor) {
    case 0:
      return 'Basement';
    case 1:
      return '1st Floor';
    case 2:
      return '2nd Floor';
    case 3:
      return '3d Floor';
    default:
      return `${floor}d Floor`;
  }
};

export function getDate(date) {
  if (date instanceof Date) {
    return format(date, 'dd MMM, yyyy');
  }
  if (typeof date === 'string' && date !== '') {
    const newDate = new Date(parseISO(date));
    return format(newDate, 'dd MMM, yyyy');
  }

  return date;
}

// Return format like 1 Jan 2021
export const formatDate = (date) => {
  if (date === '0001-01-01') {
    return 'N/A';
  }

  if (typeof date === 'number') {
    return format(new Date(date * 1000), 'dd MMM, yyyy');
  }

  if (date instanceof Date) {
    return format(parseISO(date), 'dd MMM, yyyy');
  }
  if (typeof date === 'string' && date !== '') {
    const newDate = date.length > 10 ? new Date(parseISO(date)) : new Date(date);
    return format(newDate, 'dd MMM, yyyy');
  }

  return undefined;
};

export const formatForBackEnd = (date) => {
  if (date) {
    return format(new Date(date), 'yyyy-MM-dd');
  }
  return format(new Date(), 'yyyy-MM-dd');
};

export const formatDateTimeForBackEnd = (date) => {
  if (date) {
    return format(new Date(date), 'yyyy-MM-dd HH:mm:ss.SSSSSS');
  }
  return format(new Date(), 'yyyy-MM-dd HH:mm:ss.SSSSSS');
};

// Check if date is after another date
export const isAfterDate = (date, monthsFromNow) =>
  isAfter(new Date(date), addMonths(new Date(), monthsFromNow));

export const isContractExpired = (endDate) => new Date(endDate) < new Date();
export const formatISODate = (date) => formatISO(new Date(date), { representation: 'date' });
export const convertBytesToKB = (bytes) => Math.round(bytes / 1000);

export function lowerCaseAllWordsExceptFirstLetters(string) {
  if (!string) return null;

  return string.replace(/\S*/g, (word) => word.charAt(0) + word.slice(1).toLowerCase());
}

export function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

export function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

export function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

// Billion/Million converter
// Example 10.000.000 = 10M
export const formatZillions = (value, metric) => {
  if (value !== 0 && !value) return 'N/A';

  const absValue = Math.abs(value);

  if (absValue > 999999999) {
    return `${metric}${(value / 1000000000).toFixed(1)}B`;
  }

  const key = metric || '';

  if (absValue > 999999) {
    return `${key}${(value / 1000000).toFixed(1)}M`;
  }

  if (absValue > 999) {
    return `${key}${(value / 1000).toFixed(1)}K`;
  }

  return `${key}${value}`;
};

export const getEnv = () => localStorage.getItem('env');
export const customAuthToken = () => window.location.href.split('customToken=')[1];
export const getPercentage = (currentMonth, prevMonth) => {
  if (prevMonth === 0) return '+ ∞';
  return `${(((currentMonth - prevMonth) / prevMonth) * 100).toFixed(1)}%`;
};

export const isValid = (value) => !value.match(/^\s*$/);

export const truncateString = (string, number) => {
  if (string.length > number) {
    return `${string.substring(0, number)} ...`;
  }

  return string;
};

// https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/NumberFormat#examples
export const formatNumberToCurrency = (numericValue, currency = 'EUR', isEmpty = '€0') => {
  if (!numericValue) {
    return isEmpty;
  }

  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency,
    currencyDisplay: 'symbol', // "symbol", "narrowSymbol", "code", "name"
    signDisplay: 'auto',
    trailingZeroDisplay: 'stripIfInteger'
    // These options are needed to round to whole numbers if that's what you want.
    // minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
    // maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
  });

  return formatter.format(numericValue);
};
