import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  divider: {
    width: '93%'
  },
  paper: {
    boxShadow: '0px 3px 14px #0000000F',
    borderRadius: 4,
    margin: theme.spacing(1),
    height: '100%',
    textOverflow: 'ellipsis',
    overflow: 'auto'
  },
  filesTitle: {
    fontFamily: theme.fonts.base,
    padding: theme.spacing(4),
    color: theme.palette.common.black
  },
  responsive: {
    margin: theme.spacing(1),
    [theme.breakpoints.down('xs')]: {
      justifyContent: 'center'
    }
  },
  filesAdd: {
    color: theme.palette.primary.main,
    fontFamily: theme.fonts.base,
    fontSize: 14,
    fontWeight: 'bold',
    cursor: 'pointer'
  },
  box: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: 220,
    marginRight: theme.spacing(1),
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    [theme.breakpoints.down('md')]: {
      width: 250
    }
  },
  statsIcon: {
    color: theme.palette.primary.main,
    fontSize: 26
  },
  statsIconBox: {
    background: theme.palette.background.grey,
    width: 60,
    height: 60,
    borderRadius: theme.spacing(6),
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  emptyText: {
    fontFamily: theme.fonts.base,
    fontSize: theme.spacing(2),
    color: '#7D7D7D'
  }
}));

export default useStyles;
