import React, { useState, useCallback, lazy, Suspense } from 'react';

import AppBar from '@material-ui/core/AppBar';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import useLocalStorage from 'hooks/useLocalStorage';
import { useSelector, useDispatch } from 'react-redux';
import { NavLink } from 'react-router-dom';
import * as actions from 'store/actions';

import useStyles from './styles';
import Toolbar from './Toolbar';

const TourGuides = lazy(() => import('../tourGuides'));

const adminLinks = [
  { path: '/Users', name: 'Users', rights: 'asset' },
  { path: '/Organisations', name: 'Organisations', rights: 'tenant' }
];

const menuId = 'primary-search-account-menu';

const PrimarySearchAppBar = () => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const isMenuOpen = Boolean(anchorEl);
  const { user } = useSelector((state) => state.user);
  const { removeValue } = useLocalStorage('token', null);

  const handleProfileMenuOpen = useCallback((event) => {
    setAnchorEl(event.currentTarget);
  }, []);

  const handleMenuClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const handleSignOut = useCallback(() => {
    removeValue();
    dispatch(actions.signOut());
  }, []);

  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
      id={menuId}
      keepMounted
      transformOrigin={{ vertical: 'top', horizontal: 'center' }}
      open={isMenuOpen}
      onClose={handleMenuClose}
      className={classes.menuDesktop}
      style={{ marginTop: 55 }}
    >
      {user.displayname === 'Kamil Smas' &&
        adminLinks.map(({ path, name }) => (
          <MenuItem key={name}>
            <NavLink exact to={path} style={{ color: '#666', textDecoration: 'none' }}>
              <p>{name}</p>
            </NavLink>
          </MenuItem>
        ))}
      <MenuItem style={{ padding: 20 }} onClick={handleSignOut}>
        Sign Out
      </MenuItem>
    </Menu>
  );

  return (
    <div>
      <AppBar position="static" className={classes.appBar} elevation={0}>
        <Toolbar handleProfileMenuOpen={handleProfileMenuOpen} />
      </AppBar>
      {renderMenu}
      <Suspense fallback={<div />}>
        <TourGuides />
      </Suspense>
    </div>
  );
};

export default React.memo(PrimarySearchAppBar);
