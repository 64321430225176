import React from 'react';

import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes, { arrayOf } from 'prop-types';

import InvoiceList from './InvoiceList';
import InvoiceOwedList from './InvoiceOwedList';

const useStyles = makeStyles((theme) => ({
  buttonActive: {
    background: theme.palette.primary.main,
    color: theme.palette.common.white,
    pointerEvents: 'none'
  },
  buttonInactive: {
    color: theme.palette.secondary.main
  },
  tabButton: {
    width: 270,
    height: 50,
    fontFamily: theme.fonts.openSans,
    fontSize: theme.spacing(2),
    textTransform: 'unset',
    [theme.breakpoints.down('sm')]: {
      width: 130,
      fontSize: 12
    }
  },
  btnGroup: {
    padding: theme.spacing(4)
  }
}));

const CashManager = ({ invoices, invoicesOwed }) => {
  const classes = useStyles();

  const [stackingTab, setStackingTab] = React.useState(true);

  return (
    <Box mt={4}>
      <Box>
        <ButtonGroup
          size="small"
          aria-label="small outlined button group"
          disableElevation
          className={classes.btnGroup}
          disableRipple
        >
          <Button
            className={[
              classes.tabButton,
              stackingTab ? classes.buttonActive : classes.buttonInactive
            ]}
            onClick={() => setStackingTab(true)}
          >
            Invoices awaiting approval
          </Button>
          <Button
            className={[
              !stackingTab ? classes.buttonActive : classes.buttonInactive,
              classes.tabButton
            ]}
            onClick={() => setStackingTab(false)}
          >
            Invoices owed to you
          </Button>
        </ButtonGroup>
      </Box>
      {stackingTab && invoices.length > 0 && <InvoiceList invoices={invoices} />}
      {!stackingTab && invoicesOwed.length > 0 && <InvoiceOwedList invoices={invoicesOwed} />}
    </Box>
  );
};

export default CashManager;
CashManager.propTypes = {
  invoices: arrayOf(PropTypes.any).isRequired,
  invoicesOwed: arrayOf(PropTypes.any).isRequired
};
