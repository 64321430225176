import { metaData } from 'config/config';
import wrappers from 'google-protobuf/google/protobuf/wrappers_pb';
import { formatForBackEnd } from 'helpers/utils';

import {
  OpexManagerRequest,
  CreateOpexManagerRequest,
  UpdateOpexManagerRequest,
  OpexProviderRequest,
  OpexSupplierDetailsRequest,
  UpdateOpexSupplierDetailsRequest,
  GetOpexContractDetailsRequest,
  UpdateOpexContractDetailsRequest,
  CreateOpexProviderRequest,
  UpdateOpexProviderRequest
} from '../../api/asset_grpc_web_pb';
import {
  OpexManagerProcurementService,
  OpexManagerService,
  OpexContractService,
  OpexSupplierService
} from '../services/asset';

import * as actionTypes from './actionTypes';

const getCostManagerStart = () => ({
  type: actionTypes.GET_OPEX_MANAGER_START
});

const getCostManagerSuccess = (opexManager) => ({
  type: actionTypes.GET_OPEX_MANAGER_SUCCESS,
  opexManager
});

const getCostManagerFail = (error) => ({
  type: actionTypes.GET_OPEX_MANAGER_FAIL,
  error
});

export const getOpexManager = (id) => (dispatch) => {
  dispatch(getCostManagerStart());
  const request = new OpexManagerRequest();

  request.setId(id);

  return new Promise((resolve, reject) => {
    OpexManagerService.getOpexManagersByAssetID(request, metaData(), (err, response) => {
      if (err) {
        dispatch(getCostManagerFail(err.message));
        reject(err.message);
      } else {
        dispatch(getCostManagerSuccess(response.toObject().opexmanagersList));
        resolve(response.toObject().opexmanagersList);
      }
    });
  });
};

export const createOpexManager = (id, name) => () => {
  const request = new CreateOpexManagerRequest();

  request.setAssetid(id);
  request.setName(name);

  return new Promise((resolve, reject) => {
    OpexManagerService.createOpexManager(request, metaData(), (err, response) => {
      if (err) {
        reject(err.message);
      } else {
        resolve(response.toObject());
      }
    });
  });
};

export const deleteOpexManager = (id) => () => {
  const request = new OpexManagerRequest();

  request.setId(id);

  return new Promise((resolve, reject) => {
    OpexManagerService.deleteOpexManager(request, metaData(), (err, response) => {
      if (err) {
        reject(err.message);
      } else {
        resolve(response.toObject());
      }
    });
  });
};

const getOpexManagerDetailsStart = () => ({
  type: actionTypes.GET_OPEX_MANAGER_DETAILS_START
});

const getOpexManagerDetailsSuccess = (details) => ({
  type: actionTypes.GET_OPEX_MANAGER_DETAILS_SUCCESS,
  details
});

const getOpexManagerDetailsFail = (error) => ({
  type: actionTypes.GET_OPEX_MANAGER_DETAILS_FAIL,
  error
});

export const getOpexManagerDetails = (id) => (dispatch) => {
  dispatch(getOpexManagerDetailsStart());
  const request = new OpexManagerRequest();

  request.setId(id);

  return new Promise((resolve, reject) => {
    OpexManagerService.getOpexManagerByID(request, metaData(), (err, response) => {
      if (err) {
        dispatch(getOpexManagerDetailsFail(err.message));
        reject(err.message);
      } else {
        dispatch(getOpexManagerDetailsSuccess(response.toObject()));
        resolve(response.toObject());
      }
    });
  });
};

// Procurement
const getOpexManagerProcurementStart = () => ({
  type: actionTypes.GET_OPEX_PROCUREMENT_START
});

const getOpexManagerProcurementSuccess = (procurement) => ({
  type: actionTypes.GET_OPEX_PROCUREMENT_SUCCESS,
  procurement
});

const getOpexManagerProcurementFail = (error) => ({
  type: actionTypes.GET_OPEX_PROCUREMENT_FAIL,
  error
});

export const getOpexManagerProcurement = (id) => (dispatch) => {
  dispatch(getOpexManagerProcurementStart());
  const request = new OpexProviderRequest();

  request.setId(id);

  return new Promise((resolve, reject) => {
    OpexManagerProcurementService.getOpexProvidersByOpexManagerID(
      request,
      metaData(),
      (err, response) => {
        if (err) {
          dispatch(getOpexManagerProcurementFail(err.message));
          reject(err.message);
        } else {
          dispatch(getOpexManagerProcurementSuccess(response.toObject().opexprovidersList));

          resolve(response.toObject());
        }
      }
    );
  });
};

export const createOpexManagerProcurement = (id, form) => () => {
  if (form.id) {
    const request = new UpdateOpexProviderRequest();

    request.setId(form.id);

    const name = new wrappers.StringValue();
    name.setValue(form.name);
    request.setName(name);

    const description = new wrappers.StringValue();
    description.setValue(form.description);
    request.setDescription(description);

    return new Promise((resolve, reject) => {
      OpexManagerProcurementService.updateOpexProvider(request, metaData(), (err, response) => {
        if (err) {
          reject(err.message);
        } else {
          resolve(response.toObject());
        }
      });
    });
  }

  const request = new CreateOpexProviderRequest();

  request.setOpexmanagerid(id);
  request.setName(form.name);
  request.setDescription(form.description);

  return new Promise((resolve, reject) => {
    OpexManagerProcurementService.createOpexProvider(request, metaData(), (err, response) => {
      if (err) {
        reject(err.message);
      } else {
        resolve(response.toObject());
      }
    });
  });
};

export const deleteOpexManagerProcurement = (id) => () => {
  const request = new OpexProviderRequest();
  request.setId(id);

  return new Promise((resolve, reject) => {
    OpexManagerProcurementService.deleteOpexProvider(request, metaData(), (err, response) => {
      if (err) {
        reject(err.message);
      } else {
        resolve(response.toObject());
      }
    });
  });
};

// Update Opex Manager Details
export const updateOpexManagerDetails = (id, name) => async () => {
  const request = new UpdateOpexManagerRequest();

  request.setId(id);
  request.setName(name);

  return new Promise((resolve, reject) => {
    OpexManagerService.updateOpexManager(request, metaData(), (err, response) => {
      if (err) {
        reject(err.message);
      } else {
        resolve(response.toObject());
      }
    });
  });
};

// Opex Supplier Service
const getOpexSupplierService = (supplier) => ({
  type: actionTypes.GET_OPEX_SUPPLIER_DETAILS,
  supplier
});

export const getOpexSupplierDetails = (id) => (dispatch) => {
  const request = new OpexSupplierDetailsRequest();
  request.setId(id);

  return new Promise((resolve, reject) => {
    OpexSupplierService.getOpexSupplierDetailsByOpexManagerID(
      request,
      metaData(),
      (err, response) => {
        if (err) {
          reject(err.message);
        } else {
          dispatch(getOpexSupplierService(response.toObject()));
          resolve(response.toObject());
        }
      }
    );
  });
};

export const updateOpexSupplierDetails = (form) => () => {
  const request = new UpdateOpexSupplierDetailsRequest();
  request.setId(form.id);

  const city = new wrappers.StringValue();
  city.setValue(form.city);
  request.setCity(city);

  const companyname = new wrappers.StringValue();
  companyname.setValue(form.companyname);
  request.setCompanyname(companyname);

  const contact = new wrappers.StringValue();
  contact.setValue(form.contact);
  request.setContact(contact);

  const country = new wrappers.StringValue();
  country.setValue(form.country);
  request.setCountry(country);

  const email = new wrappers.StringValue();
  email.setValue(form.email);
  request.setEmail(email);

  const postcode = new wrappers.StringValue();
  postcode.setValue(form.postcode);
  request.setPostcode(postcode);

  const street = new wrappers.StringValue();
  street.setValue(form.street);
  request.setStreet(street);

  const phone = new wrappers.StringValue();
  phone.setValue(form.phone);
  request.setPhone(phone);

  return new Promise((resolve, reject) => {
    OpexSupplierService.updateOpexSupplierDetails(request, metaData(), (err, response) => {
      if (err) {
        reject(err.message);
      } else {
        resolve(response.toObject());
      }
    });
  });
};

// Opex Contract Service
const getOpexContractSuccess = (details) => ({
  type: actionTypes.GET_OPEX_CONTRACT_DETAILS,
  details
});

export const getOpexContractDetails = (id) => (dispatch) => {
  const request = new GetOpexContractDetailsRequest();
  request.setId(id);

  return new Promise((resolve, reject) => {
    OpexContractService.getOpexContractDetailsByOpexManagerID(
      request,
      metaData(),
      (err, response) => {
        if (err) {
          reject(err.message);
        } else {
          dispatch(getOpexContractSuccess(response.toObject().contractdetailsList));
          resolve(response.toObject());
        }
      }
    );
  });
};

export const updateOpexContractDetails = (id, form) => () => {
  const request = new UpdateOpexContractDetailsRequest();
  request.setId(id);

  const typeValue = new wrappers.StringValue();
  typeValue.setValue('Supplier');
  request.setType(typeValue);

  const startDate = new wrappers.StringValue();
  startDate.setValue(formatForBackEnd(form.startdate));
  request.setStartdate(startDate);

  const endDate = new wrappers.StringValue();
  endDate.setValue(formatForBackEnd(form.enddate));
  request.setEnddate(endDate);
  let formCopy = {};

  if (form.supplier.paymentTerms === 'RegularPayment') {
    formCopy = {
      supplier: {
        paymentTerms: form.supplier.paymentTerms,
        schedule: {
          paymentAmount: form.supplier.regularPayment.paymentAmount,
          frequency: form.supplier.frequency
        }
      }
    };
  } else {
    formCopy = {
      supplier: {
        paymentTerms: form.supplier.paymentTerms,
        schedule: form.supplier.schedule.payments
      }
    };
  }

  const typeDataWrap = new wrappers.StringValue();
  typeDataWrap.setValue(JSON.stringify(formCopy));
  request.setTypedata(typeDataWrap);

  return new Promise((resolve, reject) => {
    OpexContractService.updateOpexContractDetails(request, metaData(), (err, response) => {
      if (err) {
        reject(err.message);
      } else {
        resolve(response.toObject());
      }
    });
  });
};

export const resetOpexCostManager = () => ({
  type: actionTypes.RESET_OPEX_COST_MANAGER
});
