import * as React from 'react';

import Box from '@material-ui/core/Box';
import Divider from '@material-ui/core/Divider';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import MaterialTable from 'material-table';
import PropTypes from 'prop-types';

const useStyles = makeStyles((theme) => ({
  watchTitle: {
    fontFamily: theme.fonts.base
  },
  boxRoot: {
    maxHeight: 420,
    overflowY: 'auto'
  }
}));
const columns = [
  { title: 'Account', field: 'name' },
  { title: 'Payble', field: 'payble', render: (rowData) => <div>{`€  ${rowData.payble}`}</div> },
  {
    title: 'Receivable',
    field: 'receivable',
    render: (rowData) => <div>{`€  ${rowData.receivable}`}</div>
  }
];

const AccountWatch = ({ data }) => {
  const classes = useStyles();

  return (
    <Box
      pt={4}
      pb={4}
      width="100%"
      display="flex"
      flexDirection="column"
      className={classes.boxRoot}
    >
      <Typography className={classes.watchTitle}>Account Watchlist</Typography>
      <Box mt={3}>
        <Divider />
      </Box>
      <MaterialTable
        disabled
        style={{
          padding: '20px 0px',
          borderRadius: 5,
          boxShadow: 'none',
          zIndex: 0
        }}
        components={{
          Toolbar: () => <div style={{ height: 0 }} />
        }}
        columns={columns}
        data={data}
        title={null}
        options={{
          actionsColumnIndex: -1,
          sorting: true,
          paging: false,
          search: false,
          headerStyle: {
            fontFamily: 'Montserrat, sans-serif',
            fontSize: 14
          },
          rowStyle: {
            fontFamily: 'Montserrat, sans-serif',
            padding: '0 12px',
            fontSize: 16
          },
          cellStyle: {
            textOverflow: 'ellipsis',
            fontFamily: 'Open Sans, Regular',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            color: '#5C5C5C',
            padding: 22
          }
        }}
      />
    </Box>
  );
};

export default AccountWatch;

AccountWatch.propTypes = {
  data: PropTypes.arrayOf(PropTypes.any).isRequired
};
