import React from 'react';

import DateFnsUtils from '@date-io/date-fns';
import CssBaseline from '@material-ui/core/CssBaseline';
import { ThemeProvider } from '@material-ui/core/styles';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';

import 'react-toastify/dist/ReactToastify.css';
import App from './App';
import initSentry from './config/sentry';
import theme from './config/theme';
import store from './store/store';
import './index.css';

initSentry();
ReactDOM.render(
  <Provider store={store}>
    <ThemeProvider theme={theme}>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <ToastContainer position="bottom-left" hideProgressBar autoClose={5000} maxSnack={5} />
        <CssBaseline />
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </MuiPickersUtilsProvider>
    </ThemeProvider>
  </Provider>,
  document.getElementById('root')
);

if (window.Cypress) {
  window.store = store;
}
