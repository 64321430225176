import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import LogRocket from 'logrocket';

const domainName = window.location.hostname;

export default function initSentry() {
  if (process.env.NODE_ENV === 'production' && domainName.includes('prod')) {
    LogRocket.init('dgz5df/ad');
  }

  if (
    process.env.NODE_ENV === 'production' &&
    (domainName.includes('prod') || domainName.includes('test'))
  ) {
    Sentry.init({
      dsn: process.env.REACT_APP_SENTRY_KEY,
      release: `${process.env.REACT_APP_VERSION}`,
      integrations: [new Integrations.BrowserTracing()],
      tracesSampleRate: 0.3
    });
  }
}
