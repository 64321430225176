import * as actionTypes from '../actions/actionTypes';

export const cashManagerInitialState = {
  cashManager: {},
  loading: false,
  loaded: false,
  error: null
};

const reducer = (state = cashManagerInitialState, action) => {
  switch (action.type) {
    case actionTypes.GET_TENANT_CASH_MANAGER_START:
      return {
        ...state,
        loading: true
      };
    case actionTypes.GET_TENANT_CASH_MANAGER_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        cashManager: action.cashManager
      };
    case actionTypes.GET_TENANT_CASH_MANAGER_FAIL:
      return {
        ...state,
        loading: false,
        loaded: true,
        error: action.error
      };
    default:
      return state;
  }
};

export default reducer;
