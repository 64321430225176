import { useMemo } from 'react';

import PropTypes from 'prop-types';

// Pass the perrmission page and the permission that needs validation to see if the user has access
const UserHasAccess = (page, permissionInCheck, userPermissions) =>
  useMemo(() => {
    if (!userPermissions) {
      return false;
    }
    //   return true;

    return userPermissions
      .find((userPermission) => userPermission?.title.toLowerCase() === page.toLowerCase())
      ?.rightsList.includes(permissionInCheck);
  }, [page, permissionInCheck, userPermissions]);

export default UserHasAccess;

UserHasAccess.propTypes = {
  page: PropTypes.string.isRequired,
  permissionInCheck: PropTypes.string.isRequired,
  userPermissions: PropTypes.arrayOf(PropTypes.object).isRequired
};
