import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  divider: {
    width: '93%'
  },
  paper: {
    boxShadow: '0px 3px 14px #0000000F',
    borderRadius: 4,
    margin: theme.spacing(1),
    height: '100%',
    textOverflow: 'ellipsis',
    overflow: 'auto'
  },
  title: {
    fontFamily: theme.fonts.base,
    padding: theme.spacing(4),
    marginLeft: theme.spacing(2),
    color: theme.palette.common.black
  },
  filesTitle: {
    fontFamily: theme.fonts.base,
    padding: theme.spacing(4),
    color: theme.palette.common.black
  },
  details: {
    fontSize: 16,
    marginLeft: theme.spacing(6),
    color: theme.palette.text.grey,
    fontFamily: theme.fonts.openSans,
    padding: theme.spacing(1)
  },
  boxContainer: {
    padding: theme.spacing(4)
  },
  boxInner: {
    display: 'flex',
    justifyContent: 'center',
    margin: theme.spacing(4, 2),
    [theme.breakpoints.down('xs')]: {
      width: '100%'
    }
  },
  statsTitle: {
    fontFamily: theme.fonts.openSans,
    color: '#7D7D7D',
    fontSize: 12
  },
  statsValue: {
    color: '#5C5C5C',
    fontSize: 16,
    fontFamily: theme.fonts.openSans
  },
  responsive: {
    margin: theme.spacing(1),
    [theme.breakpoints.down('xs')]: {
      justifyContent: 'center'
    }
  },
  filesAdd: {
    color: theme.palette.primary.main,
    fontFamily: theme.fonts.base,
    fontSize: 14,
    fontWeight: 'bold',
    cursor: 'pointer'
  }
}));

export default useStyles;
