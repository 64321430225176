import React from 'react';

import Button from '@material-ui/core/Button';
import Icon from '@material-ui/core/Icon';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

const useStyles = makeStyles((theme) => ({
  ctaButton: {
    borderRadius: 60,
    minWidth: 100,
    color: 'white',
    background: theme.palette.primary.main,
    '&:hover': {
      backgroundColor: theme.palette.secondary.main
    },
    fontWeight: 'bold',
    fontFamily: theme.fonts.base
  },
  fontSizeSmall: {
    fontSize: 12,
    color: theme.palette.common.white
  }
}));

const NewButton = ({ disabled, onClick, label }) => {
  const classes = useStyles();

  return (
    <Button
      variant="contained"
      data-testid={label}
      disabled={disabled}
      className={classes.ctaButton}
      startIcon={<Icon classes={{ fontSizeSmall: classes.fontSizeSmall }}>add</Icon>}
      onClick={onClick}
      data-cy="btn-new"
    >
      {label}
    </Button>
  );
};

export default NewButton;

NewButton.defaultProps = {
  disabled: false
};

NewButton.propTypes = {
  disabled: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired
};
