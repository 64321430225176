import { facilityStatus, legalCategories } from 'constants/index';

import React from 'react';

import Box from '@material-ui/core/Box';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import MenuItem from '@material-ui/core/MenuItem';
import Typography from '@material-ui/core/Typography';
import CancelButton from 'components/Buttons/CancelButton';
import SaveButton from 'components/Buttons/SaveButton';
import DatePicker from 'components/DatePicker/FormikPicker';
import TextInput from 'components/Input/TextInput';
import ModalWrapper from 'components/Modal/FormikModalWrapper';
import { closeModal } from 'components/Modal/modalReducer';
import { Formik } from 'formik';
import { lowerCaseAllWordsExceptFirstLetters } from 'helpers/utils';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import useStyles from 'styles/forms';
import * as Yup from 'yup';

const getForm = (form) => ({
  category: form.category ?? 0,
  datecreated: form.datecreated ?? new Date(),
  expecteddate: form.expecteddate ?? new Date(),
  status: form.status ?? 0,
  userid: form.userid ?? '',
  caseref: form.caseref ?? '',
  claimant: form.claimant ?? '',
  description: form.description ?? '',
  opinion: form.opinion ?? '',
  estimatedpayment: form.estimatedpayment ?? 0,
  directorate: form.directorate ?? '',
  foragainst: form.foragainst ?? true,
  party: form.party ?? '',
  id: form.id ?? ''
});

const validationSchema = Yup.object({
  category: Yup.number().required('Category is Required!'),
  datecreated: Yup.date(),
  expecteddate: Yup.date(),
  status: Yup.number().required('Status is Required!'),
  userid: Yup.string().required('Assigned to is Required!'),
  caseref: Yup.string().required('Case is required!'),
  claimant: Yup.string(),
  opinion: Yup.string(),
  estimatedpayment: Yup.number(),
  directorate: Yup.string(),
  description: Yup.string(),
  foragainst: Yup.bool().required('For/Against is required!'),
  party: Yup.string(),
  id: Yup.string()
});

function filterDirtyFields(id, values, dirtyVals) {
  return Object.keys(values).reduce(
    (acc, key) => {
      if (values[key] !== dirtyVals[key]) {
        acc[key] = values[key];
      }
      return acc;
    },
    { id }
  );
}

const AddLegalTracker = ({ handleSave, legalTracker, handleSuccess }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const initialValues = getForm(legalTracker);
  const { users } = useSelector((state) => state.users);

  return (
    <ModalWrapper
      size="md"
      header={legalTracker?.id ? 'Edit Legal Tracker' : 'New Legal Tracker'}
      xl
    >
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={async (values, { setSubmitting }) => {
          setSubmitting(true);

          const formValues = legalTracker.id
            ? filterDirtyFields(legalTracker.id, values, initialValues)
            : values;
          await handleSave(formValues)
            .then(() => {
              setSubmitting(false);
              handleSuccess();

              dispatch(closeModal());
            })
            .catch(() => {
              setSubmitting(false);
            });
        }}
      >
        {({ values, isSubmitting, dirty, isValid, touched, errors, submitForm, handleChange }) => (
          <>
            <DialogContent>
              <Box
                data-testid="legaltracker-form"
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                className={classes.box}
              >
                <TextInput
                  label="Case ref"
                  disabled={isSubmitting}
                  placeholder="Case #231"
                  name="caseref"
                  value={values.caseref}
                  onChange={handleChange}
                  error={touched.caseref && Boolean(errors.caseref)}
                  helperText={touched.caseref && errors.caseref}
                />

                <TextInput
                  label="Status"
                  large
                  disabled={isSubmitting}
                  name="status"
                  value={values.status}
                  onChange={handleChange}
                  error={touched.status && Boolean(errors.status)}
                  helperText={touched.status && errors.status}
                  select
                  style={{ width: '47%' }}
                >
                  {facilityStatus.map((option) => (
                    <MenuItem
                      key={option.id}
                      value={option.id}
                      classes={{
                        root: classes.menuItem,
                        selected: classes.selected
                      }}
                    >
                      <Box display="flex" alignItems="center">
                        <div
                          className={classes.circle}
                          style={
                            option.id === 0 ? { background: '#01B231' } : { background: '#D9D9D9' }
                          }
                        />
                        <Typography
                          style={option.id === 0 ? { color: '#01B231' } : { color: '#AAAAAA' }}
                        >
                          {lowerCaseAllWordsExceptFirstLetters(option.name)}
                        </Typography>
                      </Box>
                    </MenuItem>
                  ))}
                </TextInput>
              </Box>

              <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                className={classes.box}
              >
                <TextInput
                  label="Claimant"
                  name="claimant"
                  value={values.claimant}
                  onChange={handleChange}
                  disabled={isSubmitting}
                  error={touched.claimant && Boolean(errors.claimant)}
                  helperText={touched.claimant && errors.claimant}
                />

                <TextInput
                  label="Party"
                  name="party"
                  disabled={isSubmitting}
                  value={values.party}
                  onChange={handleChange}
                  error={touched.party && Boolean(errors.party)}
                  helperText={touched.party && errors.party}
                />
              </Box>
              <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                className={classes.box}
              >
                <TextInput
                  label="Category"
                  large
                  variant="outlined"
                  InputProps={{
                    classes: {
                      input: classes.selectInput
                    }
                  }}
                  SelectProps={{
                    MenuProps: {
                      anchorOrigin: { vertical: 'bottom', horizontal: 'center' },
                      transformOrigin: { vertical: 'top', horizontal: 'center' },
                      getContentAnchorEl: null
                    }
                  }}
                  disabled={isSubmitting}
                  name="category"
                  value={values.category}
                  onChange={handleChange}
                  error={touched.category && Boolean(errors.category)}
                  helperText={touched.category && errors.category}
                  select
                  style={{ width: '47%' }}
                >
                  {legalCategories.map((option) => (
                    <MenuItem
                      key={option.id}
                      value={option.id}
                      classes={{ root: classes.menuItem, selected: classes.selected }}
                    >
                      <Typography>{lowerCaseAllWordsExceptFirstLetters(option.name)}</Typography>
                    </MenuItem>
                  ))}
                </TextInput>

                <TextInput
                  label="For/Against"
                  large
                  InputProps={{
                    classes: {
                      input: classes.selectInput
                    }
                  }}
                  SelectProps={{
                    MenuProps: {
                      anchorOrigin: { vertical: 'bottom', horizontal: 'center' },
                      transformOrigin: { vertical: 'top', horizontal: 'center' },
                      getContentAnchorEl: null
                    }
                  }}
                  disabled={isSubmitting}
                  name="foragainst"
                  value={values.foragainst}
                  onChange={handleChange}
                  error={touched.foragainst && Boolean(errors.foragainst)}
                  helperText={touched.foragainst && errors.foragainst}
                  select
                  style={{ width: '47%' }}
                >
                  <MenuItem
                    value
                    classes={{
                      root: classes.menuItem,
                      selected: classes.selected
                    }}
                  >
                    <Typography>For</Typography>
                  </MenuItem>
                  <MenuItem
                    value={false}
                    classes={{
                      root: classes.menuItem,
                      selected: classes.selected
                    }}
                  >
                    <Typography>Against</Typography>
                  </MenuItem>
                </TextInput>
              </Box>
              <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                className={classes.box}
              >
                <DatePicker label="Expected Date" name="expecteddate" id="expecteddate" />

                {users && users.length > 0 && (
                  <TextInput
                    label="Assigned to"
                    large
                    InputProps={{
                      classes: {
                        input: classes.selectInput
                      }
                    }}
                    SelectProps={{
                      MenuProps: {
                        anchorOrigin: { vertical: 'bottom', horizontal: 'center' },
                        transformOrigin: { vertical: 'top', horizontal: 'center' },
                        getContentAnchorEl: null,
                        classes: {
                          paper: classes.selectTextfieldWrapper
                        }
                      }
                    }}
                    disabled={isSubmitting}
                    name="userid"
                    value={values.userid}
                    onChange={handleChange}
                    error={touched.userid && Boolean(errors.userid)}
                    helperText={touched.userid && errors.userid}
                    select
                    style={{ width: '47%', minHeight: 61 }}
                  >
                    {users.map((option) => (
                      <MenuItem
                        key={option.id}
                        value={option.id}
                        classes={{ root: classes.menuItem, selected: classes.selected }}
                      >
                        <Typography>{option.email.substring(0, 40)}</Typography>
                      </MenuItem>
                    ))}
                  </TextInput>
                )}
              </Box>

              <TextInput
                large
                label="Description"
                disabled={isSubmitting}
                name="description"
                value={values.description}
                onChange={handleChange}
                error={touched.description && Boolean(errors.description)}
                helperText={touched.description && errors.description}
                multiline
                rows={3}
              />
              <TextInput
                label="Court/Enforcement Directorate"
                large
                disabled={isSubmitting}
                name="directorate"
                value={values.directorate}
                onChange={handleChange}
                error={touched.directorate && Boolean(errors.directorate)}
                helperText={touched.directorate && errors.directorate}
              />

              <TextInput
                label="Estimated payment (€)"
                disabled={isSubmitting}
                name="estimatedpayment"
                value={values.estimatedpayment}
                onChange={handleChange}
                error={touched.estimatedpayment && Boolean(errors.estimatedpayment)}
                helperText={touched.estimatedpayment && errors.estimatedpayment}
                type="number"
              />
              <TextInput
                large
                label="Opinion"
                disabled={isSubmitting}
                name="opinion"
                value={values.opinion}
                onChange={handleChange}
                error={touched.opinion && Boolean(errors.opinion)}
                helperText={touched.opinion && errors.opinion}
                multiline
                rows={3}
              />
            </DialogContent>
            <DialogActions>
              <Box p={2} display="flex">
                <CancelButton onClick={() => dispatch(closeModal())} disabled={isSubmitting} />
                <SaveButton
                  type="submit"
                  loading={isSubmitting}
                  disabled={!dirty || isSubmitting || !isValid}
                  onClick={submitForm}
                />
              </Box>
            </DialogActions>
          </>
        )}
      </Formik>
    </ModalWrapper>
  );
};

export default AddLegalTracker;

AddLegalTracker.propTypes = {
  handleSave: PropTypes.func.isRequired,
  legalTracker: PropTypes.object.isRequired,
  handleSuccess: PropTypes.func.isRequired
};
