import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  title: {
    marginBottom: theme.spacing(3),
    fontFamily: theme.fonts.base
  },
  legend: {
    fontFamily: theme.fonts.openSans,
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    color: '#5C5C5C'
  }
}));

export default useStyles;
