/* eslint-disable no-unused-vars */
/* eslint-disable no-console */
import React, { useCallback, useEffect } from 'react';

import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Crumps from 'components/Breadcrumps/Crumps';
import { closeModal, openModal } from 'components/Modal/modalReducer';
import Spinner from 'components/Spinner';
import UploadRightBox from 'components/Wizards/UploadRightBox';
import useActivityFeed from 'hooks/useActivityFeed';
import useRouter from 'hooks/useRouter';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import * as actions from 'store/actions';
import useStyles from 'styles/costManager';
import Header from 'Workflows/Shared/LegalTracker/Header';
import Tabs from 'Workflows/Shared/LegalTracker/LegalTabs';

const bundleId = 'Page-1-org';

const LegalTrackerDetails = () => {
  const { query } = useRouter();
  const dispatch = useDispatch();
  const classes = useStyles();
  const { loaded, loading, details } = useSelector((state) => state.legalTracker.details);
  const { crumps, loading: crumpsLoading } = useSelector((state) => state.tenants.crumps);

  const pathName = `Tenants/${query.id}/LegalTracker/${query.index}`;
  const bundleIds = useSelector((state) => state.users.byId);
  const { organizationid } = useSelector((state) => state.user.user);
  const { activityFeed } = useActivityFeed({ id: query.index, tenantLevel: true });

  useEffect(() => {
    if (!query.id) return;

    dispatch(actions.getTenantCrumps(query.id));

    if (query.index !== details.id) {
      dispatch(actions.getLegalTrackerDetails(query.index));
    }
  }, [query]);

  useEffect(() => {
    if (bundleIds.some((bundle) => bundle === bundleId) || !organizationid) {
      return;
    }
    dispatch(actions.getAllUsers(-1, bundleId, organizationid));
  }, []);
  const handleDeleteLegalTicket = useCallback(() => {
    dispatch(
      openModal({
        modalType: 'AlertModal',
        modalProps: {
          content: 'Are you sure you want to delete this Ticket, this action cannot be undone',
          title: 'Delete Legal Tracker',
          handleConfirm: () => {
            dispatch(closeModal());
          }
        }
      })
    );
  }, []);

  const handleSave = async (form) => {
    const result = await console.log(form);

    return result;
  };

  if (!loaded || loading || crumpsLoading) {
    return <Spinner />;
  }

  return (
    <Box height="100%">
      <Paper className={classes.root}>
        <Crumps
          fragments={[
            { title: crumps.assetname, href: `/Asset/${crumps.assetid}` },
            { title: crumps.tenantname, href: `/Tenants/${crumps.tenantid}` },
            { title: 'Legal Tracker', href: `/Tenants/${crumps.tenantid}/LegalTracker` },
            {
              title: `Case # ${details.caseref}`,
              href: `/Tenants/${crumps.tenantid}/LegalTracker/${query.index}`
            }
          ]}
        />
        <Header handleDelete={handleDeleteLegalTicket} details={details} handleSave={handleSave} />
      </Paper>

      <Box m={2}>
        <Grid container>
          <Grid item xs={12} sm={8} md={9} style={{ marginTop: 10 }}>
            <Tabs details={details} activityFeed={activityFeed} />
          </Grid>

          <UploadRightBox pathName={pathName} />
        </Grid>
      </Box>
    </Box>
  );
};

export default LegalTrackerDetails;
