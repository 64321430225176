/**
 * @fileoverview gRPC-Web generated client stub for external
 * @enhanceable
 * @public
 */

// GENERATED CODE -- DO NOT EDIT!


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');


var google_protobuf_empty_pb = require('google-protobuf/google/protobuf/empty_pb.js')

var google_protobuf_wrappers_pb = require('google-protobuf/google/protobuf/wrappers_pb.js')
const proto = {};
proto.external = require('./asset_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.external.AssetServiceClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options['format'] = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.external.AssetServicePromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options['format'] = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.external.CreateAssetRequest,
 *   !proto.google.protobuf.Empty>}
 */
const methodDescriptor_AssetService_CreateAsset = new grpc.web.MethodDescriptor(
  '/external.AssetService/CreateAsset',
  grpc.web.MethodType.UNARY,
  proto.external.CreateAssetRequest,
  google_protobuf_empty_pb.Empty,
  /**
   * @param {!proto.external.CreateAssetRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  google_protobuf_empty_pb.Empty.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.external.CreateAssetRequest,
 *   !proto.google.protobuf.Empty>}
 */
const methodInfo_AssetService_CreateAsset = new grpc.web.AbstractClientBase.MethodInfo(
  google_protobuf_empty_pb.Empty,
  /**
   * @param {!proto.external.CreateAssetRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  google_protobuf_empty_pb.Empty.deserializeBinary
);


/**
 * @param {!proto.external.CreateAssetRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.google.protobuf.Empty)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.google.protobuf.Empty>|undefined}
 *     The XHR Node Readable Stream
 */
proto.external.AssetServiceClient.prototype.createAsset =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/external.AssetService/CreateAsset',
      request,
      metadata || {},
      methodDescriptor_AssetService_CreateAsset,
      callback);
};


/**
 * @param {!proto.external.CreateAssetRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.google.protobuf.Empty>}
 *     Promise that resolves to the response
 */
proto.external.AssetServicePromiseClient.prototype.createAsset =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/external.AssetService/CreateAsset',
      request,
      metadata || {},
      methodDescriptor_AssetService_CreateAsset);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.external.GetAssetsRequest,
 *   !proto.external.GetAssetsResponse>}
 */
const methodDescriptor_AssetService_GetAssets = new grpc.web.MethodDescriptor(
  '/external.AssetService/GetAssets',
  grpc.web.MethodType.UNARY,
  proto.external.GetAssetsRequest,
  proto.external.GetAssetsResponse,
  /**
   * @param {!proto.external.GetAssetsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.external.GetAssetsResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.external.GetAssetsRequest,
 *   !proto.external.GetAssetsResponse>}
 */
const methodInfo_AssetService_GetAssets = new grpc.web.AbstractClientBase.MethodInfo(
  proto.external.GetAssetsResponse,
  /**
   * @param {!proto.external.GetAssetsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.external.GetAssetsResponse.deserializeBinary
);


/**
 * @param {!proto.external.GetAssetsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.external.GetAssetsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.external.GetAssetsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.external.AssetServiceClient.prototype.getAssets =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/external.AssetService/GetAssets',
      request,
      metadata || {},
      methodDescriptor_AssetService_GetAssets,
      callback);
};


/**
 * @param {!proto.external.GetAssetsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.external.GetAssetsResponse>}
 *     Promise that resolves to the response
 */
proto.external.AssetServicePromiseClient.prototype.getAssets =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/external.AssetService/GetAssets',
      request,
      metadata || {},
      methodDescriptor_AssetService_GetAssets);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.external.GetAssetRequest,
 *   !proto.external.Asset>}
 */
const methodDescriptor_AssetService_GetAssetByID = new grpc.web.MethodDescriptor(
  '/external.AssetService/GetAssetByID',
  grpc.web.MethodType.UNARY,
  proto.external.GetAssetRequest,
  proto.external.Asset,
  /**
   * @param {!proto.external.GetAssetRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.external.Asset.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.external.GetAssetRequest,
 *   !proto.external.Asset>}
 */
const methodInfo_AssetService_GetAssetByID = new grpc.web.AbstractClientBase.MethodInfo(
  proto.external.Asset,
  /**
   * @param {!proto.external.GetAssetRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.external.Asset.deserializeBinary
);


/**
 * @param {!proto.external.GetAssetRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.external.Asset)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.external.Asset>|undefined}
 *     The XHR Node Readable Stream
 */
proto.external.AssetServiceClient.prototype.getAssetByID =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/external.AssetService/GetAssetByID',
      request,
      metadata || {},
      methodDescriptor_AssetService_GetAssetByID,
      callback);
};


/**
 * @param {!proto.external.GetAssetRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.external.Asset>}
 *     Promise that resolves to the response
 */
proto.external.AssetServicePromiseClient.prototype.getAssetByID =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/external.AssetService/GetAssetByID',
      request,
      metadata || {},
      methodDescriptor_AssetService_GetAssetByID);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.external.UpdateAssetRequest,
 *   !proto.google.protobuf.Empty>}
 */
const methodDescriptor_AssetService_UpdateAsset = new grpc.web.MethodDescriptor(
  '/external.AssetService/UpdateAsset',
  grpc.web.MethodType.UNARY,
  proto.external.UpdateAssetRequest,
  google_protobuf_empty_pb.Empty,
  /**
   * @param {!proto.external.UpdateAssetRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  google_protobuf_empty_pb.Empty.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.external.UpdateAssetRequest,
 *   !proto.google.protobuf.Empty>}
 */
const methodInfo_AssetService_UpdateAsset = new grpc.web.AbstractClientBase.MethodInfo(
  google_protobuf_empty_pb.Empty,
  /**
   * @param {!proto.external.UpdateAssetRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  google_protobuf_empty_pb.Empty.deserializeBinary
);


/**
 * @param {!proto.external.UpdateAssetRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.google.protobuf.Empty)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.google.protobuf.Empty>|undefined}
 *     The XHR Node Readable Stream
 */
proto.external.AssetServiceClient.prototype.updateAsset =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/external.AssetService/UpdateAsset',
      request,
      metadata || {},
      methodDescriptor_AssetService_UpdateAsset,
      callback);
};


/**
 * @param {!proto.external.UpdateAssetRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.google.protobuf.Empty>}
 *     Promise that resolves to the response
 */
proto.external.AssetServicePromiseClient.prototype.updateAsset =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/external.AssetService/UpdateAsset',
      request,
      metadata || {},
      methodDescriptor_AssetService_UpdateAsset);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.external.DeleteAssetRequest,
 *   !proto.google.protobuf.Empty>}
 */
const methodDescriptor_AssetService_DeleteAsset = new grpc.web.MethodDescriptor(
  '/external.AssetService/DeleteAsset',
  grpc.web.MethodType.UNARY,
  proto.external.DeleteAssetRequest,
  google_protobuf_empty_pb.Empty,
  /**
   * @param {!proto.external.DeleteAssetRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  google_protobuf_empty_pb.Empty.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.external.DeleteAssetRequest,
 *   !proto.google.protobuf.Empty>}
 */
const methodInfo_AssetService_DeleteAsset = new grpc.web.AbstractClientBase.MethodInfo(
  google_protobuf_empty_pb.Empty,
  /**
   * @param {!proto.external.DeleteAssetRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  google_protobuf_empty_pb.Empty.deserializeBinary
);


/**
 * @param {!proto.external.DeleteAssetRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.google.protobuf.Empty)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.google.protobuf.Empty>|undefined}
 *     The XHR Node Readable Stream
 */
proto.external.AssetServiceClient.prototype.deleteAsset =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/external.AssetService/DeleteAsset',
      request,
      metadata || {},
      methodDescriptor_AssetService_DeleteAsset,
      callback);
};


/**
 * @param {!proto.external.DeleteAssetRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.google.protobuf.Empty>}
 *     Promise that resolves to the response
 */
proto.external.AssetServicePromiseClient.prototype.deleteAsset =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/external.AssetService/DeleteAsset',
      request,
      metadata || {},
      methodDescriptor_AssetService_DeleteAsset);
};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.external.ValuationServiceClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options['format'] = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.external.ValuationServicePromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options['format'] = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.external.CreateValuationRequest,
 *   !proto.external.CreateValuationResponse>}
 */
const methodDescriptor_ValuationService_CreateValuation = new grpc.web.MethodDescriptor(
  '/external.ValuationService/CreateValuation',
  grpc.web.MethodType.UNARY,
  proto.external.CreateValuationRequest,
  proto.external.CreateValuationResponse,
  /**
   * @param {!proto.external.CreateValuationRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.external.CreateValuationResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.external.CreateValuationRequest,
 *   !proto.external.CreateValuationResponse>}
 */
const methodInfo_ValuationService_CreateValuation = new grpc.web.AbstractClientBase.MethodInfo(
  proto.external.CreateValuationResponse,
  /**
   * @param {!proto.external.CreateValuationRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.external.CreateValuationResponse.deserializeBinary
);


/**
 * @param {!proto.external.CreateValuationRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.external.CreateValuationResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.external.CreateValuationResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.external.ValuationServiceClient.prototype.createValuation =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/external.ValuationService/CreateValuation',
      request,
      metadata || {},
      methodDescriptor_ValuationService_CreateValuation,
      callback);
};


/**
 * @param {!proto.external.CreateValuationRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.external.CreateValuationResponse>}
 *     Promise that resolves to the response
 */
proto.external.ValuationServicePromiseClient.prototype.createValuation =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/external.ValuationService/CreateValuation',
      request,
      metadata || {},
      methodDescriptor_ValuationService_CreateValuation);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.external.GetValuationRequest,
 *   !proto.external.GetValuationResponse>}
 */
const methodDescriptor_ValuationService_GetValuation = new grpc.web.MethodDescriptor(
  '/external.ValuationService/GetValuation',
  grpc.web.MethodType.UNARY,
  proto.external.GetValuationRequest,
  proto.external.GetValuationResponse,
  /**
   * @param {!proto.external.GetValuationRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.external.GetValuationResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.external.GetValuationRequest,
 *   !proto.external.GetValuationResponse>}
 */
const methodInfo_ValuationService_GetValuation = new grpc.web.AbstractClientBase.MethodInfo(
  proto.external.GetValuationResponse,
  /**
   * @param {!proto.external.GetValuationRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.external.GetValuationResponse.deserializeBinary
);


/**
 * @param {!proto.external.GetValuationRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.external.GetValuationResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.external.GetValuationResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.external.ValuationServiceClient.prototype.getValuation =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/external.ValuationService/GetValuation',
      request,
      metadata || {},
      methodDescriptor_ValuationService_GetValuation,
      callback);
};


/**
 * @param {!proto.external.GetValuationRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.external.GetValuationResponse>}
 *     Promise that resolves to the response
 */
proto.external.ValuationServicePromiseClient.prototype.getValuation =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/external.ValuationService/GetValuation',
      request,
      metadata || {},
      methodDescriptor_ValuationService_GetValuation);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.external.GetValuationsByAssetIDRequest,
 *   !proto.external.GetValuationsByAssetIDResponse>}
 */
const methodDescriptor_ValuationService_GetValuationsByAssetID = new grpc.web.MethodDescriptor(
  '/external.ValuationService/GetValuationsByAssetID',
  grpc.web.MethodType.UNARY,
  proto.external.GetValuationsByAssetIDRequest,
  proto.external.GetValuationsByAssetIDResponse,
  /**
   * @param {!proto.external.GetValuationsByAssetIDRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.external.GetValuationsByAssetIDResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.external.GetValuationsByAssetIDRequest,
 *   !proto.external.GetValuationsByAssetIDResponse>}
 */
const methodInfo_ValuationService_GetValuationsByAssetID = new grpc.web.AbstractClientBase.MethodInfo(
  proto.external.GetValuationsByAssetIDResponse,
  /**
   * @param {!proto.external.GetValuationsByAssetIDRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.external.GetValuationsByAssetIDResponse.deserializeBinary
);


/**
 * @param {!proto.external.GetValuationsByAssetIDRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.external.GetValuationsByAssetIDResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.external.GetValuationsByAssetIDResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.external.ValuationServiceClient.prototype.getValuationsByAssetID =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/external.ValuationService/GetValuationsByAssetID',
      request,
      metadata || {},
      methodDescriptor_ValuationService_GetValuationsByAssetID,
      callback);
};


/**
 * @param {!proto.external.GetValuationsByAssetIDRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.external.GetValuationsByAssetIDResponse>}
 *     Promise that resolves to the response
 */
proto.external.ValuationServicePromiseClient.prototype.getValuationsByAssetID =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/external.ValuationService/GetValuationsByAssetID',
      request,
      metadata || {},
      methodDescriptor_ValuationService_GetValuationsByAssetID);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.external.GetValuationRequest,
 *   !proto.external.GetValuationResponse>}
 */
const methodDescriptor_ValuationService_GetLastValuationByAssetID = new grpc.web.MethodDescriptor(
  '/external.ValuationService/GetLastValuationByAssetID',
  grpc.web.MethodType.UNARY,
  proto.external.GetValuationRequest,
  proto.external.GetValuationResponse,
  /**
   * @param {!proto.external.GetValuationRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.external.GetValuationResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.external.GetValuationRequest,
 *   !proto.external.GetValuationResponse>}
 */
const methodInfo_ValuationService_GetLastValuationByAssetID = new grpc.web.AbstractClientBase.MethodInfo(
  proto.external.GetValuationResponse,
  /**
   * @param {!proto.external.GetValuationRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.external.GetValuationResponse.deserializeBinary
);


/**
 * @param {!proto.external.GetValuationRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.external.GetValuationResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.external.GetValuationResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.external.ValuationServiceClient.prototype.getLastValuationByAssetID =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/external.ValuationService/GetLastValuationByAssetID',
      request,
      metadata || {},
      methodDescriptor_ValuationService_GetLastValuationByAssetID,
      callback);
};


/**
 * @param {!proto.external.GetValuationRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.external.GetValuationResponse>}
 *     Promise that resolves to the response
 */
proto.external.ValuationServicePromiseClient.prototype.getLastValuationByAssetID =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/external.ValuationService/GetLastValuationByAssetID',
      request,
      metadata || {},
      methodDescriptor_ValuationService_GetLastValuationByAssetID);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.external.UpdateValuationRequest,
 *   !proto.google.protobuf.Empty>}
 */
const methodDescriptor_ValuationService_UpdateValuation = new grpc.web.MethodDescriptor(
  '/external.ValuationService/UpdateValuation',
  grpc.web.MethodType.UNARY,
  proto.external.UpdateValuationRequest,
  google_protobuf_empty_pb.Empty,
  /**
   * @param {!proto.external.UpdateValuationRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  google_protobuf_empty_pb.Empty.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.external.UpdateValuationRequest,
 *   !proto.google.protobuf.Empty>}
 */
const methodInfo_ValuationService_UpdateValuation = new grpc.web.AbstractClientBase.MethodInfo(
  google_protobuf_empty_pb.Empty,
  /**
   * @param {!proto.external.UpdateValuationRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  google_protobuf_empty_pb.Empty.deserializeBinary
);


/**
 * @param {!proto.external.UpdateValuationRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.google.protobuf.Empty)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.google.protobuf.Empty>|undefined}
 *     The XHR Node Readable Stream
 */
proto.external.ValuationServiceClient.prototype.updateValuation =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/external.ValuationService/UpdateValuation',
      request,
      metadata || {},
      methodDescriptor_ValuationService_UpdateValuation,
      callback);
};


/**
 * @param {!proto.external.UpdateValuationRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.google.protobuf.Empty>}
 *     Promise that resolves to the response
 */
proto.external.ValuationServicePromiseClient.prototype.updateValuation =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/external.ValuationService/UpdateValuation',
      request,
      metadata || {},
      methodDescriptor_ValuationService_UpdateValuation);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.external.DeleteValuationRequest,
 *   !proto.google.protobuf.Empty>}
 */
const methodDescriptor_ValuationService_DeleteValuation = new grpc.web.MethodDescriptor(
  '/external.ValuationService/DeleteValuation',
  grpc.web.MethodType.UNARY,
  proto.external.DeleteValuationRequest,
  google_protobuf_empty_pb.Empty,
  /**
   * @param {!proto.external.DeleteValuationRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  google_protobuf_empty_pb.Empty.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.external.DeleteValuationRequest,
 *   !proto.google.protobuf.Empty>}
 */
const methodInfo_ValuationService_DeleteValuation = new grpc.web.AbstractClientBase.MethodInfo(
  google_protobuf_empty_pb.Empty,
  /**
   * @param {!proto.external.DeleteValuationRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  google_protobuf_empty_pb.Empty.deserializeBinary
);


/**
 * @param {!proto.external.DeleteValuationRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.google.protobuf.Empty)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.google.protobuf.Empty>|undefined}
 *     The XHR Node Readable Stream
 */
proto.external.ValuationServiceClient.prototype.deleteValuation =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/external.ValuationService/DeleteValuation',
      request,
      metadata || {},
      methodDescriptor_ValuationService_DeleteValuation,
      callback);
};


/**
 * @param {!proto.external.DeleteValuationRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.google.protobuf.Empty>}
 *     Promise that resolves to the response
 */
proto.external.ValuationServicePromiseClient.prototype.deleteValuation =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/external.ValuationService/DeleteValuation',
      request,
      metadata || {},
      methodDescriptor_ValuationService_DeleteValuation);
};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.external.AssetActivityFeedServiceClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options['format'] = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.external.AssetActivityFeedServicePromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options['format'] = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.external.AssetActivityFeedsByAssetRequest,
 *   !proto.external.AssetActivityFeedsResponse>}
 */
const methodDescriptor_AssetActivityFeedService_GetActivityFeedsByAsset = new grpc.web.MethodDescriptor(
  '/external.AssetActivityFeedService/GetActivityFeedsByAsset',
  grpc.web.MethodType.UNARY,
  proto.external.AssetActivityFeedsByAssetRequest,
  proto.external.AssetActivityFeedsResponse,
  /**
   * @param {!proto.external.AssetActivityFeedsByAssetRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.external.AssetActivityFeedsResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.external.AssetActivityFeedsByAssetRequest,
 *   !proto.external.AssetActivityFeedsResponse>}
 */
const methodInfo_AssetActivityFeedService_GetActivityFeedsByAsset = new grpc.web.AbstractClientBase.MethodInfo(
  proto.external.AssetActivityFeedsResponse,
  /**
   * @param {!proto.external.AssetActivityFeedsByAssetRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.external.AssetActivityFeedsResponse.deserializeBinary
);


/**
 * @param {!proto.external.AssetActivityFeedsByAssetRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.external.AssetActivityFeedsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.external.AssetActivityFeedsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.external.AssetActivityFeedServiceClient.prototype.getActivityFeedsByAsset =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/external.AssetActivityFeedService/GetActivityFeedsByAsset',
      request,
      metadata || {},
      methodDescriptor_AssetActivityFeedService_GetActivityFeedsByAsset,
      callback);
};


/**
 * @param {!proto.external.AssetActivityFeedsByAssetRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.external.AssetActivityFeedsResponse>}
 *     Promise that resolves to the response
 */
proto.external.AssetActivityFeedServicePromiseClient.prototype.getActivityFeedsByAsset =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/external.AssetActivityFeedService/GetActivityFeedsByAsset',
      request,
      metadata || {},
      methodDescriptor_AssetActivityFeedService_GetActivityFeedsByAsset);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.external.AssetActivityFeedsByComponentRequest,
 *   !proto.external.AssetActivityFeedsResponse>}
 */
const methodDescriptor_AssetActivityFeedService_GetActivityFeedsByComponent = new grpc.web.MethodDescriptor(
  '/external.AssetActivityFeedService/GetActivityFeedsByComponent',
  grpc.web.MethodType.UNARY,
  proto.external.AssetActivityFeedsByComponentRequest,
  proto.external.AssetActivityFeedsResponse,
  /**
   * @param {!proto.external.AssetActivityFeedsByComponentRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.external.AssetActivityFeedsResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.external.AssetActivityFeedsByComponentRequest,
 *   !proto.external.AssetActivityFeedsResponse>}
 */
const methodInfo_AssetActivityFeedService_GetActivityFeedsByComponent = new grpc.web.AbstractClientBase.MethodInfo(
  proto.external.AssetActivityFeedsResponse,
  /**
   * @param {!proto.external.AssetActivityFeedsByComponentRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.external.AssetActivityFeedsResponse.deserializeBinary
);


/**
 * @param {!proto.external.AssetActivityFeedsByComponentRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.external.AssetActivityFeedsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.external.AssetActivityFeedsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.external.AssetActivityFeedServiceClient.prototype.getActivityFeedsByComponent =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/external.AssetActivityFeedService/GetActivityFeedsByComponent',
      request,
      metadata || {},
      methodDescriptor_AssetActivityFeedService_GetActivityFeedsByComponent,
      callback);
};


/**
 * @param {!proto.external.AssetActivityFeedsByComponentRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.external.AssetActivityFeedsResponse>}
 *     Promise that resolves to the response
 */
proto.external.AssetActivityFeedServicePromiseClient.prototype.getActivityFeedsByComponent =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/external.AssetActivityFeedService/GetActivityFeedsByComponent',
      request,
      metadata || {},
      methodDescriptor_AssetActivityFeedService_GetActivityFeedsByComponent);
};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.external.CostContractDetailsServiceClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options['format'] = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.external.CostContractDetailsServicePromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options['format'] = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.external.GetCostContractDetailsRequest,
 *   !proto.external.GetCostContractDetailsByCostManagerIDResponse>}
 */
const methodDescriptor_CostContractDetailsService_GetCostContractDetailsByCostManagerID = new grpc.web.MethodDescriptor(
  '/external.CostContractDetailsService/GetCostContractDetailsByCostManagerID',
  grpc.web.MethodType.UNARY,
  proto.external.GetCostContractDetailsRequest,
  proto.external.GetCostContractDetailsByCostManagerIDResponse,
  /**
   * @param {!proto.external.GetCostContractDetailsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.external.GetCostContractDetailsByCostManagerIDResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.external.GetCostContractDetailsRequest,
 *   !proto.external.GetCostContractDetailsByCostManagerIDResponse>}
 */
const methodInfo_CostContractDetailsService_GetCostContractDetailsByCostManagerID = new grpc.web.AbstractClientBase.MethodInfo(
  proto.external.GetCostContractDetailsByCostManagerIDResponse,
  /**
   * @param {!proto.external.GetCostContractDetailsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.external.GetCostContractDetailsByCostManagerIDResponse.deserializeBinary
);


/**
 * @param {!proto.external.GetCostContractDetailsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.external.GetCostContractDetailsByCostManagerIDResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.external.GetCostContractDetailsByCostManagerIDResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.external.CostContractDetailsServiceClient.prototype.getCostContractDetailsByCostManagerID =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/external.CostContractDetailsService/GetCostContractDetailsByCostManagerID',
      request,
      metadata || {},
      methodDescriptor_CostContractDetailsService_GetCostContractDetailsByCostManagerID,
      callback);
};


/**
 * @param {!proto.external.GetCostContractDetailsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.external.GetCostContractDetailsByCostManagerIDResponse>}
 *     Promise that resolves to the response
 */
proto.external.CostContractDetailsServicePromiseClient.prototype.getCostContractDetailsByCostManagerID =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/external.CostContractDetailsService/GetCostContractDetailsByCostManagerID',
      request,
      metadata || {},
      methodDescriptor_CostContractDetailsService_GetCostContractDetailsByCostManagerID);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.external.GetCostContractDetailsRequest,
 *   !proto.external.GetCostContractDetailsResponse>}
 */
const methodDescriptor_CostContractDetailsService_GetCostContractDetails = new grpc.web.MethodDescriptor(
  '/external.CostContractDetailsService/GetCostContractDetails',
  grpc.web.MethodType.UNARY,
  proto.external.GetCostContractDetailsRequest,
  proto.external.GetCostContractDetailsResponse,
  /**
   * @param {!proto.external.GetCostContractDetailsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.external.GetCostContractDetailsResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.external.GetCostContractDetailsRequest,
 *   !proto.external.GetCostContractDetailsResponse>}
 */
const methodInfo_CostContractDetailsService_GetCostContractDetails = new grpc.web.AbstractClientBase.MethodInfo(
  proto.external.GetCostContractDetailsResponse,
  /**
   * @param {!proto.external.GetCostContractDetailsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.external.GetCostContractDetailsResponse.deserializeBinary
);


/**
 * @param {!proto.external.GetCostContractDetailsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.external.GetCostContractDetailsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.external.GetCostContractDetailsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.external.CostContractDetailsServiceClient.prototype.getCostContractDetails =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/external.CostContractDetailsService/GetCostContractDetails',
      request,
      metadata || {},
      methodDescriptor_CostContractDetailsService_GetCostContractDetails,
      callback);
};


/**
 * @param {!proto.external.GetCostContractDetailsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.external.GetCostContractDetailsResponse>}
 *     Promise that resolves to the response
 */
proto.external.CostContractDetailsServicePromiseClient.prototype.getCostContractDetails =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/external.CostContractDetailsService/GetCostContractDetails',
      request,
      metadata || {},
      methodDescriptor_CostContractDetailsService_GetCostContractDetails);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.external.UpdateCostContractDetailsRequest,
 *   !proto.google.protobuf.Empty>}
 */
const methodDescriptor_CostContractDetailsService_UpdateCostContractDetails = new grpc.web.MethodDescriptor(
  '/external.CostContractDetailsService/UpdateCostContractDetails',
  grpc.web.MethodType.UNARY,
  proto.external.UpdateCostContractDetailsRequest,
  google_protobuf_empty_pb.Empty,
  /**
   * @param {!proto.external.UpdateCostContractDetailsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  google_protobuf_empty_pb.Empty.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.external.UpdateCostContractDetailsRequest,
 *   !proto.google.protobuf.Empty>}
 */
const methodInfo_CostContractDetailsService_UpdateCostContractDetails = new grpc.web.AbstractClientBase.MethodInfo(
  google_protobuf_empty_pb.Empty,
  /**
   * @param {!proto.external.UpdateCostContractDetailsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  google_protobuf_empty_pb.Empty.deserializeBinary
);


/**
 * @param {!proto.external.UpdateCostContractDetailsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.google.protobuf.Empty)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.google.protobuf.Empty>|undefined}
 *     The XHR Node Readable Stream
 */
proto.external.CostContractDetailsServiceClient.prototype.updateCostContractDetails =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/external.CostContractDetailsService/UpdateCostContractDetails',
      request,
      metadata || {},
      methodDescriptor_CostContractDetailsService_UpdateCostContractDetails,
      callback);
};


/**
 * @param {!proto.external.UpdateCostContractDetailsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.google.protobuf.Empty>}
 *     Promise that resolves to the response
 */
proto.external.CostContractDetailsServicePromiseClient.prototype.updateCostContractDetails =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/external.CostContractDetailsService/UpdateCostContractDetails',
      request,
      metadata || {},
      methodDescriptor_CostContractDetailsService_UpdateCostContractDetails);
};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.external.CostProviderServiceClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options['format'] = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.external.CostProviderServicePromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options['format'] = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.external.CostProviderRequest,
 *   !proto.external.GetCostProviderResponse>}
 */
const methodDescriptor_CostProviderService_GetCostProviderByID = new grpc.web.MethodDescriptor(
  '/external.CostProviderService/GetCostProviderByID',
  grpc.web.MethodType.UNARY,
  proto.external.CostProviderRequest,
  proto.external.GetCostProviderResponse,
  /**
   * @param {!proto.external.CostProviderRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.external.GetCostProviderResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.external.CostProviderRequest,
 *   !proto.external.GetCostProviderResponse>}
 */
const methodInfo_CostProviderService_GetCostProviderByID = new grpc.web.AbstractClientBase.MethodInfo(
  proto.external.GetCostProviderResponse,
  /**
   * @param {!proto.external.CostProviderRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.external.GetCostProviderResponse.deserializeBinary
);


/**
 * @param {!proto.external.CostProviderRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.external.GetCostProviderResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.external.GetCostProviderResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.external.CostProviderServiceClient.prototype.getCostProviderByID =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/external.CostProviderService/GetCostProviderByID',
      request,
      metadata || {},
      methodDescriptor_CostProviderService_GetCostProviderByID,
      callback);
};


/**
 * @param {!proto.external.CostProviderRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.external.GetCostProviderResponse>}
 *     Promise that resolves to the response
 */
proto.external.CostProviderServicePromiseClient.prototype.getCostProviderByID =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/external.CostProviderService/GetCostProviderByID',
      request,
      metadata || {},
      methodDescriptor_CostProviderService_GetCostProviderByID);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.external.CostProviderRequest,
 *   !proto.external.GetCostProvidersResponse>}
 */
const methodDescriptor_CostProviderService_GetCostProvidersByCostManagerID = new grpc.web.MethodDescriptor(
  '/external.CostProviderService/GetCostProvidersByCostManagerID',
  grpc.web.MethodType.UNARY,
  proto.external.CostProviderRequest,
  proto.external.GetCostProvidersResponse,
  /**
   * @param {!proto.external.CostProviderRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.external.GetCostProvidersResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.external.CostProviderRequest,
 *   !proto.external.GetCostProvidersResponse>}
 */
const methodInfo_CostProviderService_GetCostProvidersByCostManagerID = new grpc.web.AbstractClientBase.MethodInfo(
  proto.external.GetCostProvidersResponse,
  /**
   * @param {!proto.external.CostProviderRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.external.GetCostProvidersResponse.deserializeBinary
);


/**
 * @param {!proto.external.CostProviderRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.external.GetCostProvidersResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.external.GetCostProvidersResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.external.CostProviderServiceClient.prototype.getCostProvidersByCostManagerID =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/external.CostProviderService/GetCostProvidersByCostManagerID',
      request,
      metadata || {},
      methodDescriptor_CostProviderService_GetCostProvidersByCostManagerID,
      callback);
};


/**
 * @param {!proto.external.CostProviderRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.external.GetCostProvidersResponse>}
 *     Promise that resolves to the response
 */
proto.external.CostProviderServicePromiseClient.prototype.getCostProvidersByCostManagerID =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/external.CostProviderService/GetCostProvidersByCostManagerID',
      request,
      metadata || {},
      methodDescriptor_CostProviderService_GetCostProvidersByCostManagerID);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.external.UpdateCostProviderRequest,
 *   !proto.google.protobuf.Empty>}
 */
const methodDescriptor_CostProviderService_UpdateCostProvider = new grpc.web.MethodDescriptor(
  '/external.CostProviderService/UpdateCostProvider',
  grpc.web.MethodType.UNARY,
  proto.external.UpdateCostProviderRequest,
  google_protobuf_empty_pb.Empty,
  /**
   * @param {!proto.external.UpdateCostProviderRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  google_protobuf_empty_pb.Empty.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.external.UpdateCostProviderRequest,
 *   !proto.google.protobuf.Empty>}
 */
const methodInfo_CostProviderService_UpdateCostProvider = new grpc.web.AbstractClientBase.MethodInfo(
  google_protobuf_empty_pb.Empty,
  /**
   * @param {!proto.external.UpdateCostProviderRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  google_protobuf_empty_pb.Empty.deserializeBinary
);


/**
 * @param {!proto.external.UpdateCostProviderRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.google.protobuf.Empty)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.google.protobuf.Empty>|undefined}
 *     The XHR Node Readable Stream
 */
proto.external.CostProviderServiceClient.prototype.updateCostProvider =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/external.CostProviderService/UpdateCostProvider',
      request,
      metadata || {},
      methodDescriptor_CostProviderService_UpdateCostProvider,
      callback);
};


/**
 * @param {!proto.external.UpdateCostProviderRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.google.protobuf.Empty>}
 *     Promise that resolves to the response
 */
proto.external.CostProviderServicePromiseClient.prototype.updateCostProvider =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/external.CostProviderService/UpdateCostProvider',
      request,
      metadata || {},
      methodDescriptor_CostProviderService_UpdateCostProvider);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.external.CostProviderRequest,
 *   !proto.google.protobuf.Empty>}
 */
const methodDescriptor_CostProviderService_DeleteCostProvider = new grpc.web.MethodDescriptor(
  '/external.CostProviderService/DeleteCostProvider',
  grpc.web.MethodType.UNARY,
  proto.external.CostProviderRequest,
  google_protobuf_empty_pb.Empty,
  /**
   * @param {!proto.external.CostProviderRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  google_protobuf_empty_pb.Empty.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.external.CostProviderRequest,
 *   !proto.google.protobuf.Empty>}
 */
const methodInfo_CostProviderService_DeleteCostProvider = new grpc.web.AbstractClientBase.MethodInfo(
  google_protobuf_empty_pb.Empty,
  /**
   * @param {!proto.external.CostProviderRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  google_protobuf_empty_pb.Empty.deserializeBinary
);


/**
 * @param {!proto.external.CostProviderRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.google.protobuf.Empty)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.google.protobuf.Empty>|undefined}
 *     The XHR Node Readable Stream
 */
proto.external.CostProviderServiceClient.prototype.deleteCostProvider =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/external.CostProviderService/DeleteCostProvider',
      request,
      metadata || {},
      methodDescriptor_CostProviderService_DeleteCostProvider,
      callback);
};


/**
 * @param {!proto.external.CostProviderRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.google.protobuf.Empty>}
 *     Promise that resolves to the response
 */
proto.external.CostProviderServicePromiseClient.prototype.deleteCostProvider =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/external.CostProviderService/DeleteCostProvider',
      request,
      metadata || {},
      methodDescriptor_CostProviderService_DeleteCostProvider);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.external.CreateCostProviderRequest,
 *   !proto.external.CreateCostProviderResponse>}
 */
const methodDescriptor_CostProviderService_CreateCostProvider = new grpc.web.MethodDescriptor(
  '/external.CostProviderService/CreateCostProvider',
  grpc.web.MethodType.UNARY,
  proto.external.CreateCostProviderRequest,
  proto.external.CreateCostProviderResponse,
  /**
   * @param {!proto.external.CreateCostProviderRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.external.CreateCostProviderResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.external.CreateCostProviderRequest,
 *   !proto.external.CreateCostProviderResponse>}
 */
const methodInfo_CostProviderService_CreateCostProvider = new grpc.web.AbstractClientBase.MethodInfo(
  proto.external.CreateCostProviderResponse,
  /**
   * @param {!proto.external.CreateCostProviderRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.external.CreateCostProviderResponse.deserializeBinary
);


/**
 * @param {!proto.external.CreateCostProviderRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.external.CreateCostProviderResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.external.CreateCostProviderResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.external.CostProviderServiceClient.prototype.createCostProvider =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/external.CostProviderService/CreateCostProvider',
      request,
      metadata || {},
      methodDescriptor_CostProviderService_CreateCostProvider,
      callback);
};


/**
 * @param {!proto.external.CreateCostProviderRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.external.CreateCostProviderResponse>}
 *     Promise that resolves to the response
 */
proto.external.CostProviderServicePromiseClient.prototype.createCostProvider =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/external.CostProviderService/CreateCostProvider',
      request,
      metadata || {},
      methodDescriptor_CostProviderService_CreateCostProvider);
};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.external.CostSupplierDetailsServiceClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options['format'] = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.external.CostSupplierDetailsServicePromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options['format'] = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.external.CostSupplierDetailsRequest,
 *   !proto.external.GetCostSupplierDetailsResponse>}
 */
const methodDescriptor_CostSupplierDetailsService_GetCostSupplierDetailsByID = new grpc.web.MethodDescriptor(
  '/external.CostSupplierDetailsService/GetCostSupplierDetailsByID',
  grpc.web.MethodType.UNARY,
  proto.external.CostSupplierDetailsRequest,
  proto.external.GetCostSupplierDetailsResponse,
  /**
   * @param {!proto.external.CostSupplierDetailsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.external.GetCostSupplierDetailsResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.external.CostSupplierDetailsRequest,
 *   !proto.external.GetCostSupplierDetailsResponse>}
 */
const methodInfo_CostSupplierDetailsService_GetCostSupplierDetailsByID = new grpc.web.AbstractClientBase.MethodInfo(
  proto.external.GetCostSupplierDetailsResponse,
  /**
   * @param {!proto.external.CostSupplierDetailsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.external.GetCostSupplierDetailsResponse.deserializeBinary
);


/**
 * @param {!proto.external.CostSupplierDetailsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.external.GetCostSupplierDetailsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.external.GetCostSupplierDetailsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.external.CostSupplierDetailsServiceClient.prototype.getCostSupplierDetailsByID =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/external.CostSupplierDetailsService/GetCostSupplierDetailsByID',
      request,
      metadata || {},
      methodDescriptor_CostSupplierDetailsService_GetCostSupplierDetailsByID,
      callback);
};


/**
 * @param {!proto.external.CostSupplierDetailsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.external.GetCostSupplierDetailsResponse>}
 *     Promise that resolves to the response
 */
proto.external.CostSupplierDetailsServicePromiseClient.prototype.getCostSupplierDetailsByID =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/external.CostSupplierDetailsService/GetCostSupplierDetailsByID',
      request,
      metadata || {},
      methodDescriptor_CostSupplierDetailsService_GetCostSupplierDetailsByID);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.external.CostSupplierDetailsRequest,
 *   !proto.external.GetCostSupplierDetailsResponse>}
 */
const methodDescriptor_CostSupplierDetailsService_GetCostSupplierDetailsByCostManagerID = new grpc.web.MethodDescriptor(
  '/external.CostSupplierDetailsService/GetCostSupplierDetailsByCostManagerID',
  grpc.web.MethodType.UNARY,
  proto.external.CostSupplierDetailsRequest,
  proto.external.GetCostSupplierDetailsResponse,
  /**
   * @param {!proto.external.CostSupplierDetailsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.external.GetCostSupplierDetailsResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.external.CostSupplierDetailsRequest,
 *   !proto.external.GetCostSupplierDetailsResponse>}
 */
const methodInfo_CostSupplierDetailsService_GetCostSupplierDetailsByCostManagerID = new grpc.web.AbstractClientBase.MethodInfo(
  proto.external.GetCostSupplierDetailsResponse,
  /**
   * @param {!proto.external.CostSupplierDetailsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.external.GetCostSupplierDetailsResponse.deserializeBinary
);


/**
 * @param {!proto.external.CostSupplierDetailsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.external.GetCostSupplierDetailsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.external.GetCostSupplierDetailsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.external.CostSupplierDetailsServiceClient.prototype.getCostSupplierDetailsByCostManagerID =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/external.CostSupplierDetailsService/GetCostSupplierDetailsByCostManagerID',
      request,
      metadata || {},
      methodDescriptor_CostSupplierDetailsService_GetCostSupplierDetailsByCostManagerID,
      callback);
};


/**
 * @param {!proto.external.CostSupplierDetailsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.external.GetCostSupplierDetailsResponse>}
 *     Promise that resolves to the response
 */
proto.external.CostSupplierDetailsServicePromiseClient.prototype.getCostSupplierDetailsByCostManagerID =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/external.CostSupplierDetailsService/GetCostSupplierDetailsByCostManagerID',
      request,
      metadata || {},
      methodDescriptor_CostSupplierDetailsService_GetCostSupplierDetailsByCostManagerID);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.external.UpdateCostSupplierDetailsRequest,
 *   !proto.google.protobuf.Empty>}
 */
const methodDescriptor_CostSupplierDetailsService_UpdateCostSupplierDetails = new grpc.web.MethodDescriptor(
  '/external.CostSupplierDetailsService/UpdateCostSupplierDetails',
  grpc.web.MethodType.UNARY,
  proto.external.UpdateCostSupplierDetailsRequest,
  google_protobuf_empty_pb.Empty,
  /**
   * @param {!proto.external.UpdateCostSupplierDetailsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  google_protobuf_empty_pb.Empty.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.external.UpdateCostSupplierDetailsRequest,
 *   !proto.google.protobuf.Empty>}
 */
const methodInfo_CostSupplierDetailsService_UpdateCostSupplierDetails = new grpc.web.AbstractClientBase.MethodInfo(
  google_protobuf_empty_pb.Empty,
  /**
   * @param {!proto.external.UpdateCostSupplierDetailsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  google_protobuf_empty_pb.Empty.deserializeBinary
);


/**
 * @param {!proto.external.UpdateCostSupplierDetailsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.google.protobuf.Empty)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.google.protobuf.Empty>|undefined}
 *     The XHR Node Readable Stream
 */
proto.external.CostSupplierDetailsServiceClient.prototype.updateCostSupplierDetails =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/external.CostSupplierDetailsService/UpdateCostSupplierDetails',
      request,
      metadata || {},
      methodDescriptor_CostSupplierDetailsService_UpdateCostSupplierDetails,
      callback);
};


/**
 * @param {!proto.external.UpdateCostSupplierDetailsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.google.protobuf.Empty>}
 *     Promise that resolves to the response
 */
proto.external.CostSupplierDetailsServicePromiseClient.prototype.updateCostSupplierDetails =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/external.CostSupplierDetailsService/UpdateCostSupplierDetails',
      request,
      metadata || {},
      methodDescriptor_CostSupplierDetailsService_UpdateCostSupplierDetails);
};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.external.CostManagerServiceClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options['format'] = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.external.CostManagerServicePromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options['format'] = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.external.CostManagerRequest,
 *   !proto.external.CostManagerResponse>}
 */
const methodDescriptor_CostManagerService_GetCostManagerByID = new grpc.web.MethodDescriptor(
  '/external.CostManagerService/GetCostManagerByID',
  grpc.web.MethodType.UNARY,
  proto.external.CostManagerRequest,
  proto.external.CostManagerResponse,
  /**
   * @param {!proto.external.CostManagerRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.external.CostManagerResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.external.CostManagerRequest,
 *   !proto.external.CostManagerResponse>}
 */
const methodInfo_CostManagerService_GetCostManagerByID = new grpc.web.AbstractClientBase.MethodInfo(
  proto.external.CostManagerResponse,
  /**
   * @param {!proto.external.CostManagerRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.external.CostManagerResponse.deserializeBinary
);


/**
 * @param {!proto.external.CostManagerRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.external.CostManagerResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.external.CostManagerResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.external.CostManagerServiceClient.prototype.getCostManagerByID =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/external.CostManagerService/GetCostManagerByID',
      request,
      metadata || {},
      methodDescriptor_CostManagerService_GetCostManagerByID,
      callback);
};


/**
 * @param {!proto.external.CostManagerRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.external.CostManagerResponse>}
 *     Promise that resolves to the response
 */
proto.external.CostManagerServicePromiseClient.prototype.getCostManagerByID =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/external.CostManagerService/GetCostManagerByID',
      request,
      metadata || {},
      methodDescriptor_CostManagerService_GetCostManagerByID);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.external.CostManagerRequest,
 *   !proto.external.CostManagersResponse>}
 */
const methodDescriptor_CostManagerService_GetCostManagersByAssetID = new grpc.web.MethodDescriptor(
  '/external.CostManagerService/GetCostManagersByAssetID',
  grpc.web.MethodType.UNARY,
  proto.external.CostManagerRequest,
  proto.external.CostManagersResponse,
  /**
   * @param {!proto.external.CostManagerRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.external.CostManagersResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.external.CostManagerRequest,
 *   !proto.external.CostManagersResponse>}
 */
const methodInfo_CostManagerService_GetCostManagersByAssetID = new grpc.web.AbstractClientBase.MethodInfo(
  proto.external.CostManagersResponse,
  /**
   * @param {!proto.external.CostManagerRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.external.CostManagersResponse.deserializeBinary
);


/**
 * @param {!proto.external.CostManagerRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.external.CostManagersResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.external.CostManagersResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.external.CostManagerServiceClient.prototype.getCostManagersByAssetID =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/external.CostManagerService/GetCostManagersByAssetID',
      request,
      metadata || {},
      methodDescriptor_CostManagerService_GetCostManagersByAssetID,
      callback);
};


/**
 * @param {!proto.external.CostManagerRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.external.CostManagersResponse>}
 *     Promise that resolves to the response
 */
proto.external.CostManagerServicePromiseClient.prototype.getCostManagersByAssetID =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/external.CostManagerService/GetCostManagersByAssetID',
      request,
      metadata || {},
      methodDescriptor_CostManagerService_GetCostManagersByAssetID);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.external.CreateCostManagerRequest,
 *   !proto.external.CreateCostManagerResponse>}
 */
const methodDescriptor_CostManagerService_CreateCostManager = new grpc.web.MethodDescriptor(
  '/external.CostManagerService/CreateCostManager',
  grpc.web.MethodType.UNARY,
  proto.external.CreateCostManagerRequest,
  proto.external.CreateCostManagerResponse,
  /**
   * @param {!proto.external.CreateCostManagerRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.external.CreateCostManagerResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.external.CreateCostManagerRequest,
 *   !proto.external.CreateCostManagerResponse>}
 */
const methodInfo_CostManagerService_CreateCostManager = new grpc.web.AbstractClientBase.MethodInfo(
  proto.external.CreateCostManagerResponse,
  /**
   * @param {!proto.external.CreateCostManagerRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.external.CreateCostManagerResponse.deserializeBinary
);


/**
 * @param {!proto.external.CreateCostManagerRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.external.CreateCostManagerResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.external.CreateCostManagerResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.external.CostManagerServiceClient.prototype.createCostManager =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/external.CostManagerService/CreateCostManager',
      request,
      metadata || {},
      methodDescriptor_CostManagerService_CreateCostManager,
      callback);
};


/**
 * @param {!proto.external.CreateCostManagerRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.external.CreateCostManagerResponse>}
 *     Promise that resolves to the response
 */
proto.external.CostManagerServicePromiseClient.prototype.createCostManager =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/external.CostManagerService/CreateCostManager',
      request,
      metadata || {},
      methodDescriptor_CostManagerService_CreateCostManager);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.external.UpdateCostManagerRequest,
 *   !proto.google.protobuf.Empty>}
 */
const methodDescriptor_CostManagerService_UpdateCostManager = new grpc.web.MethodDescriptor(
  '/external.CostManagerService/UpdateCostManager',
  grpc.web.MethodType.UNARY,
  proto.external.UpdateCostManagerRequest,
  google_protobuf_empty_pb.Empty,
  /**
   * @param {!proto.external.UpdateCostManagerRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  google_protobuf_empty_pb.Empty.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.external.UpdateCostManagerRequest,
 *   !proto.google.protobuf.Empty>}
 */
const methodInfo_CostManagerService_UpdateCostManager = new grpc.web.AbstractClientBase.MethodInfo(
  google_protobuf_empty_pb.Empty,
  /**
   * @param {!proto.external.UpdateCostManagerRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  google_protobuf_empty_pb.Empty.deserializeBinary
);


/**
 * @param {!proto.external.UpdateCostManagerRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.google.protobuf.Empty)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.google.protobuf.Empty>|undefined}
 *     The XHR Node Readable Stream
 */
proto.external.CostManagerServiceClient.prototype.updateCostManager =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/external.CostManagerService/UpdateCostManager',
      request,
      metadata || {},
      methodDescriptor_CostManagerService_UpdateCostManager,
      callback);
};


/**
 * @param {!proto.external.UpdateCostManagerRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.google.protobuf.Empty>}
 *     Promise that resolves to the response
 */
proto.external.CostManagerServicePromiseClient.prototype.updateCostManager =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/external.CostManagerService/UpdateCostManager',
      request,
      metadata || {},
      methodDescriptor_CostManagerService_UpdateCostManager);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.external.CostManagerRequest,
 *   !proto.google.protobuf.Empty>}
 */
const methodDescriptor_CostManagerService_DeleteCostManager = new grpc.web.MethodDescriptor(
  '/external.CostManagerService/DeleteCostManager',
  grpc.web.MethodType.UNARY,
  proto.external.CostManagerRequest,
  google_protobuf_empty_pb.Empty,
  /**
   * @param {!proto.external.CostManagerRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  google_protobuf_empty_pb.Empty.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.external.CostManagerRequest,
 *   !proto.google.protobuf.Empty>}
 */
const methodInfo_CostManagerService_DeleteCostManager = new grpc.web.AbstractClientBase.MethodInfo(
  google_protobuf_empty_pb.Empty,
  /**
   * @param {!proto.external.CostManagerRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  google_protobuf_empty_pb.Empty.deserializeBinary
);


/**
 * @param {!proto.external.CostManagerRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.google.protobuf.Empty)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.google.protobuf.Empty>|undefined}
 *     The XHR Node Readable Stream
 */
proto.external.CostManagerServiceClient.prototype.deleteCostManager =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/external.CostManagerService/DeleteCostManager',
      request,
      metadata || {},
      methodDescriptor_CostManagerService_DeleteCostManager,
      callback);
};


/**
 * @param {!proto.external.CostManagerRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.google.protobuf.Empty>}
 *     Promise that resolves to the response
 */
proto.external.CostManagerServicePromiseClient.prototype.deleteCostManager =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/external.CostManagerService/DeleteCostManager',
      request,
      metadata || {},
      methodDescriptor_CostManagerService_DeleteCostManager);
};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.external.OpexContractDetailsServiceClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options['format'] = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.external.OpexContractDetailsServicePromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options['format'] = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.external.GetOpexContractDetailsRequest,
 *   !proto.external.GetOpexContractDetailsByOpexManagerIDResponse>}
 */
const methodDescriptor_OpexContractDetailsService_GetOpexContractDetailsByOpexManagerID = new grpc.web.MethodDescriptor(
  '/external.OpexContractDetailsService/GetOpexContractDetailsByOpexManagerID',
  grpc.web.MethodType.UNARY,
  proto.external.GetOpexContractDetailsRequest,
  proto.external.GetOpexContractDetailsByOpexManagerIDResponse,
  /**
   * @param {!proto.external.GetOpexContractDetailsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.external.GetOpexContractDetailsByOpexManagerIDResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.external.GetOpexContractDetailsRequest,
 *   !proto.external.GetOpexContractDetailsByOpexManagerIDResponse>}
 */
const methodInfo_OpexContractDetailsService_GetOpexContractDetailsByOpexManagerID = new grpc.web.AbstractClientBase.MethodInfo(
  proto.external.GetOpexContractDetailsByOpexManagerIDResponse,
  /**
   * @param {!proto.external.GetOpexContractDetailsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.external.GetOpexContractDetailsByOpexManagerIDResponse.deserializeBinary
);


/**
 * @param {!proto.external.GetOpexContractDetailsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.external.GetOpexContractDetailsByOpexManagerIDResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.external.GetOpexContractDetailsByOpexManagerIDResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.external.OpexContractDetailsServiceClient.prototype.getOpexContractDetailsByOpexManagerID =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/external.OpexContractDetailsService/GetOpexContractDetailsByOpexManagerID',
      request,
      metadata || {},
      methodDescriptor_OpexContractDetailsService_GetOpexContractDetailsByOpexManagerID,
      callback);
};


/**
 * @param {!proto.external.GetOpexContractDetailsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.external.GetOpexContractDetailsByOpexManagerIDResponse>}
 *     Promise that resolves to the response
 */
proto.external.OpexContractDetailsServicePromiseClient.prototype.getOpexContractDetailsByOpexManagerID =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/external.OpexContractDetailsService/GetOpexContractDetailsByOpexManagerID',
      request,
      metadata || {},
      methodDescriptor_OpexContractDetailsService_GetOpexContractDetailsByOpexManagerID);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.external.GetOpexContractDetailsRequest,
 *   !proto.external.GetOpexContractDetailsResponse>}
 */
const methodDescriptor_OpexContractDetailsService_GetOpexContractDetails = new grpc.web.MethodDescriptor(
  '/external.OpexContractDetailsService/GetOpexContractDetails',
  grpc.web.MethodType.UNARY,
  proto.external.GetOpexContractDetailsRequest,
  proto.external.GetOpexContractDetailsResponse,
  /**
   * @param {!proto.external.GetOpexContractDetailsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.external.GetOpexContractDetailsResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.external.GetOpexContractDetailsRequest,
 *   !proto.external.GetOpexContractDetailsResponse>}
 */
const methodInfo_OpexContractDetailsService_GetOpexContractDetails = new grpc.web.AbstractClientBase.MethodInfo(
  proto.external.GetOpexContractDetailsResponse,
  /**
   * @param {!proto.external.GetOpexContractDetailsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.external.GetOpexContractDetailsResponse.deserializeBinary
);


/**
 * @param {!proto.external.GetOpexContractDetailsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.external.GetOpexContractDetailsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.external.GetOpexContractDetailsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.external.OpexContractDetailsServiceClient.prototype.getOpexContractDetails =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/external.OpexContractDetailsService/GetOpexContractDetails',
      request,
      metadata || {},
      methodDescriptor_OpexContractDetailsService_GetOpexContractDetails,
      callback);
};


/**
 * @param {!proto.external.GetOpexContractDetailsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.external.GetOpexContractDetailsResponse>}
 *     Promise that resolves to the response
 */
proto.external.OpexContractDetailsServicePromiseClient.prototype.getOpexContractDetails =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/external.OpexContractDetailsService/GetOpexContractDetails',
      request,
      metadata || {},
      methodDescriptor_OpexContractDetailsService_GetOpexContractDetails);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.external.UpdateOpexContractDetailsRequest,
 *   !proto.google.protobuf.Empty>}
 */
const methodDescriptor_OpexContractDetailsService_UpdateOpexContractDetails = new grpc.web.MethodDescriptor(
  '/external.OpexContractDetailsService/UpdateOpexContractDetails',
  grpc.web.MethodType.UNARY,
  proto.external.UpdateOpexContractDetailsRequest,
  google_protobuf_empty_pb.Empty,
  /**
   * @param {!proto.external.UpdateOpexContractDetailsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  google_protobuf_empty_pb.Empty.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.external.UpdateOpexContractDetailsRequest,
 *   !proto.google.protobuf.Empty>}
 */
const methodInfo_OpexContractDetailsService_UpdateOpexContractDetails = new grpc.web.AbstractClientBase.MethodInfo(
  google_protobuf_empty_pb.Empty,
  /**
   * @param {!proto.external.UpdateOpexContractDetailsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  google_protobuf_empty_pb.Empty.deserializeBinary
);


/**
 * @param {!proto.external.UpdateOpexContractDetailsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.google.protobuf.Empty)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.google.protobuf.Empty>|undefined}
 *     The XHR Node Readable Stream
 */
proto.external.OpexContractDetailsServiceClient.prototype.updateOpexContractDetails =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/external.OpexContractDetailsService/UpdateOpexContractDetails',
      request,
      metadata || {},
      methodDescriptor_OpexContractDetailsService_UpdateOpexContractDetails,
      callback);
};


/**
 * @param {!proto.external.UpdateOpexContractDetailsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.google.protobuf.Empty>}
 *     Promise that resolves to the response
 */
proto.external.OpexContractDetailsServicePromiseClient.prototype.updateOpexContractDetails =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/external.OpexContractDetailsService/UpdateOpexContractDetails',
      request,
      metadata || {},
      methodDescriptor_OpexContractDetailsService_UpdateOpexContractDetails);
};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.external.OpexProviderServiceClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options['format'] = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.external.OpexProviderServicePromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options['format'] = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.external.OpexProviderRequest,
 *   !proto.external.GetOpexProviderResponse>}
 */
const methodDescriptor_OpexProviderService_GetOpexProviderByID = new grpc.web.MethodDescriptor(
  '/external.OpexProviderService/GetOpexProviderByID',
  grpc.web.MethodType.UNARY,
  proto.external.OpexProviderRequest,
  proto.external.GetOpexProviderResponse,
  /**
   * @param {!proto.external.OpexProviderRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.external.GetOpexProviderResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.external.OpexProviderRequest,
 *   !proto.external.GetOpexProviderResponse>}
 */
const methodInfo_OpexProviderService_GetOpexProviderByID = new grpc.web.AbstractClientBase.MethodInfo(
  proto.external.GetOpexProviderResponse,
  /**
   * @param {!proto.external.OpexProviderRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.external.GetOpexProviderResponse.deserializeBinary
);


/**
 * @param {!proto.external.OpexProviderRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.external.GetOpexProviderResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.external.GetOpexProviderResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.external.OpexProviderServiceClient.prototype.getOpexProviderByID =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/external.OpexProviderService/GetOpexProviderByID',
      request,
      metadata || {},
      methodDescriptor_OpexProviderService_GetOpexProviderByID,
      callback);
};


/**
 * @param {!proto.external.OpexProviderRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.external.GetOpexProviderResponse>}
 *     Promise that resolves to the response
 */
proto.external.OpexProviderServicePromiseClient.prototype.getOpexProviderByID =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/external.OpexProviderService/GetOpexProviderByID',
      request,
      metadata || {},
      methodDescriptor_OpexProviderService_GetOpexProviderByID);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.external.OpexProviderRequest,
 *   !proto.external.GetOpexProvidersResponse>}
 */
const methodDescriptor_OpexProviderService_GetOpexProvidersByOpexManagerID = new grpc.web.MethodDescriptor(
  '/external.OpexProviderService/GetOpexProvidersByOpexManagerID',
  grpc.web.MethodType.UNARY,
  proto.external.OpexProviderRequest,
  proto.external.GetOpexProvidersResponse,
  /**
   * @param {!proto.external.OpexProviderRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.external.GetOpexProvidersResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.external.OpexProviderRequest,
 *   !proto.external.GetOpexProvidersResponse>}
 */
const methodInfo_OpexProviderService_GetOpexProvidersByOpexManagerID = new grpc.web.AbstractClientBase.MethodInfo(
  proto.external.GetOpexProvidersResponse,
  /**
   * @param {!proto.external.OpexProviderRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.external.GetOpexProvidersResponse.deserializeBinary
);


/**
 * @param {!proto.external.OpexProviderRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.external.GetOpexProvidersResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.external.GetOpexProvidersResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.external.OpexProviderServiceClient.prototype.getOpexProvidersByOpexManagerID =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/external.OpexProviderService/GetOpexProvidersByOpexManagerID',
      request,
      metadata || {},
      methodDescriptor_OpexProviderService_GetOpexProvidersByOpexManagerID,
      callback);
};


/**
 * @param {!proto.external.OpexProviderRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.external.GetOpexProvidersResponse>}
 *     Promise that resolves to the response
 */
proto.external.OpexProviderServicePromiseClient.prototype.getOpexProvidersByOpexManagerID =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/external.OpexProviderService/GetOpexProvidersByOpexManagerID',
      request,
      metadata || {},
      methodDescriptor_OpexProviderService_GetOpexProvidersByOpexManagerID);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.external.UpdateOpexProviderRequest,
 *   !proto.google.protobuf.Empty>}
 */
const methodDescriptor_OpexProviderService_UpdateOpexProvider = new grpc.web.MethodDescriptor(
  '/external.OpexProviderService/UpdateOpexProvider',
  grpc.web.MethodType.UNARY,
  proto.external.UpdateOpexProviderRequest,
  google_protobuf_empty_pb.Empty,
  /**
   * @param {!proto.external.UpdateOpexProviderRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  google_protobuf_empty_pb.Empty.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.external.UpdateOpexProviderRequest,
 *   !proto.google.protobuf.Empty>}
 */
const methodInfo_OpexProviderService_UpdateOpexProvider = new grpc.web.AbstractClientBase.MethodInfo(
  google_protobuf_empty_pb.Empty,
  /**
   * @param {!proto.external.UpdateOpexProviderRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  google_protobuf_empty_pb.Empty.deserializeBinary
);


/**
 * @param {!proto.external.UpdateOpexProviderRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.google.protobuf.Empty)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.google.protobuf.Empty>|undefined}
 *     The XHR Node Readable Stream
 */
proto.external.OpexProviderServiceClient.prototype.updateOpexProvider =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/external.OpexProviderService/UpdateOpexProvider',
      request,
      metadata || {},
      methodDescriptor_OpexProviderService_UpdateOpexProvider,
      callback);
};


/**
 * @param {!proto.external.UpdateOpexProviderRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.google.protobuf.Empty>}
 *     Promise that resolves to the response
 */
proto.external.OpexProviderServicePromiseClient.prototype.updateOpexProvider =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/external.OpexProviderService/UpdateOpexProvider',
      request,
      metadata || {},
      methodDescriptor_OpexProviderService_UpdateOpexProvider);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.external.OpexProviderRequest,
 *   !proto.google.protobuf.Empty>}
 */
const methodDescriptor_OpexProviderService_DeleteOpexProvider = new grpc.web.MethodDescriptor(
  '/external.OpexProviderService/DeleteOpexProvider',
  grpc.web.MethodType.UNARY,
  proto.external.OpexProviderRequest,
  google_protobuf_empty_pb.Empty,
  /**
   * @param {!proto.external.OpexProviderRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  google_protobuf_empty_pb.Empty.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.external.OpexProviderRequest,
 *   !proto.google.protobuf.Empty>}
 */
const methodInfo_OpexProviderService_DeleteOpexProvider = new grpc.web.AbstractClientBase.MethodInfo(
  google_protobuf_empty_pb.Empty,
  /**
   * @param {!proto.external.OpexProviderRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  google_protobuf_empty_pb.Empty.deserializeBinary
);


/**
 * @param {!proto.external.OpexProviderRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.google.protobuf.Empty)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.google.protobuf.Empty>|undefined}
 *     The XHR Node Readable Stream
 */
proto.external.OpexProviderServiceClient.prototype.deleteOpexProvider =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/external.OpexProviderService/DeleteOpexProvider',
      request,
      metadata || {},
      methodDescriptor_OpexProviderService_DeleteOpexProvider,
      callback);
};


/**
 * @param {!proto.external.OpexProviderRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.google.protobuf.Empty>}
 *     Promise that resolves to the response
 */
proto.external.OpexProviderServicePromiseClient.prototype.deleteOpexProvider =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/external.OpexProviderService/DeleteOpexProvider',
      request,
      metadata || {},
      methodDescriptor_OpexProviderService_DeleteOpexProvider);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.external.CreateOpexProviderRequest,
 *   !proto.external.CreateOpexProviderResponse>}
 */
const methodDescriptor_OpexProviderService_CreateOpexProvider = new grpc.web.MethodDescriptor(
  '/external.OpexProviderService/CreateOpexProvider',
  grpc.web.MethodType.UNARY,
  proto.external.CreateOpexProviderRequest,
  proto.external.CreateOpexProviderResponse,
  /**
   * @param {!proto.external.CreateOpexProviderRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.external.CreateOpexProviderResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.external.CreateOpexProviderRequest,
 *   !proto.external.CreateOpexProviderResponse>}
 */
const methodInfo_OpexProviderService_CreateOpexProvider = new grpc.web.AbstractClientBase.MethodInfo(
  proto.external.CreateOpexProviderResponse,
  /**
   * @param {!proto.external.CreateOpexProviderRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.external.CreateOpexProviderResponse.deserializeBinary
);


/**
 * @param {!proto.external.CreateOpexProviderRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.external.CreateOpexProviderResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.external.CreateOpexProviderResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.external.OpexProviderServiceClient.prototype.createOpexProvider =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/external.OpexProviderService/CreateOpexProvider',
      request,
      metadata || {},
      methodDescriptor_OpexProviderService_CreateOpexProvider,
      callback);
};


/**
 * @param {!proto.external.CreateOpexProviderRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.external.CreateOpexProviderResponse>}
 *     Promise that resolves to the response
 */
proto.external.OpexProviderServicePromiseClient.prototype.createOpexProvider =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/external.OpexProviderService/CreateOpexProvider',
      request,
      metadata || {},
      methodDescriptor_OpexProviderService_CreateOpexProvider);
};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.external.OpexSupplierDetailsServiceClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options['format'] = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.external.OpexSupplierDetailsServicePromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options['format'] = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.external.OpexSupplierDetailsRequest,
 *   !proto.external.GetOpexSupplierDetailsResponse>}
 */
const methodDescriptor_OpexSupplierDetailsService_GetOpexSupplierDetailsByID = new grpc.web.MethodDescriptor(
  '/external.OpexSupplierDetailsService/GetOpexSupplierDetailsByID',
  grpc.web.MethodType.UNARY,
  proto.external.OpexSupplierDetailsRequest,
  proto.external.GetOpexSupplierDetailsResponse,
  /**
   * @param {!proto.external.OpexSupplierDetailsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.external.GetOpexSupplierDetailsResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.external.OpexSupplierDetailsRequest,
 *   !proto.external.GetOpexSupplierDetailsResponse>}
 */
const methodInfo_OpexSupplierDetailsService_GetOpexSupplierDetailsByID = new grpc.web.AbstractClientBase.MethodInfo(
  proto.external.GetOpexSupplierDetailsResponse,
  /**
   * @param {!proto.external.OpexSupplierDetailsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.external.GetOpexSupplierDetailsResponse.deserializeBinary
);


/**
 * @param {!proto.external.OpexSupplierDetailsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.external.GetOpexSupplierDetailsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.external.GetOpexSupplierDetailsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.external.OpexSupplierDetailsServiceClient.prototype.getOpexSupplierDetailsByID =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/external.OpexSupplierDetailsService/GetOpexSupplierDetailsByID',
      request,
      metadata || {},
      methodDescriptor_OpexSupplierDetailsService_GetOpexSupplierDetailsByID,
      callback);
};


/**
 * @param {!proto.external.OpexSupplierDetailsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.external.GetOpexSupplierDetailsResponse>}
 *     Promise that resolves to the response
 */
proto.external.OpexSupplierDetailsServicePromiseClient.prototype.getOpexSupplierDetailsByID =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/external.OpexSupplierDetailsService/GetOpexSupplierDetailsByID',
      request,
      metadata || {},
      methodDescriptor_OpexSupplierDetailsService_GetOpexSupplierDetailsByID);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.external.OpexSupplierDetailsRequest,
 *   !proto.external.GetOpexSupplierDetailsResponse>}
 */
const methodDescriptor_OpexSupplierDetailsService_GetOpexSupplierDetailsByOpexManagerID = new grpc.web.MethodDescriptor(
  '/external.OpexSupplierDetailsService/GetOpexSupplierDetailsByOpexManagerID',
  grpc.web.MethodType.UNARY,
  proto.external.OpexSupplierDetailsRequest,
  proto.external.GetOpexSupplierDetailsResponse,
  /**
   * @param {!proto.external.OpexSupplierDetailsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.external.GetOpexSupplierDetailsResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.external.OpexSupplierDetailsRequest,
 *   !proto.external.GetOpexSupplierDetailsResponse>}
 */
const methodInfo_OpexSupplierDetailsService_GetOpexSupplierDetailsByOpexManagerID = new grpc.web.AbstractClientBase.MethodInfo(
  proto.external.GetOpexSupplierDetailsResponse,
  /**
   * @param {!proto.external.OpexSupplierDetailsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.external.GetOpexSupplierDetailsResponse.deserializeBinary
);


/**
 * @param {!proto.external.OpexSupplierDetailsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.external.GetOpexSupplierDetailsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.external.GetOpexSupplierDetailsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.external.OpexSupplierDetailsServiceClient.prototype.getOpexSupplierDetailsByOpexManagerID =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/external.OpexSupplierDetailsService/GetOpexSupplierDetailsByOpexManagerID',
      request,
      metadata || {},
      methodDescriptor_OpexSupplierDetailsService_GetOpexSupplierDetailsByOpexManagerID,
      callback);
};


/**
 * @param {!proto.external.OpexSupplierDetailsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.external.GetOpexSupplierDetailsResponse>}
 *     Promise that resolves to the response
 */
proto.external.OpexSupplierDetailsServicePromiseClient.prototype.getOpexSupplierDetailsByOpexManagerID =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/external.OpexSupplierDetailsService/GetOpexSupplierDetailsByOpexManagerID',
      request,
      metadata || {},
      methodDescriptor_OpexSupplierDetailsService_GetOpexSupplierDetailsByOpexManagerID);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.external.UpdateOpexSupplierDetailsRequest,
 *   !proto.google.protobuf.Empty>}
 */
const methodDescriptor_OpexSupplierDetailsService_UpdateOpexSupplierDetails = new grpc.web.MethodDescriptor(
  '/external.OpexSupplierDetailsService/UpdateOpexSupplierDetails',
  grpc.web.MethodType.UNARY,
  proto.external.UpdateOpexSupplierDetailsRequest,
  google_protobuf_empty_pb.Empty,
  /**
   * @param {!proto.external.UpdateOpexSupplierDetailsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  google_protobuf_empty_pb.Empty.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.external.UpdateOpexSupplierDetailsRequest,
 *   !proto.google.protobuf.Empty>}
 */
const methodInfo_OpexSupplierDetailsService_UpdateOpexSupplierDetails = new grpc.web.AbstractClientBase.MethodInfo(
  google_protobuf_empty_pb.Empty,
  /**
   * @param {!proto.external.UpdateOpexSupplierDetailsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  google_protobuf_empty_pb.Empty.deserializeBinary
);


/**
 * @param {!proto.external.UpdateOpexSupplierDetailsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.google.protobuf.Empty)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.google.protobuf.Empty>|undefined}
 *     The XHR Node Readable Stream
 */
proto.external.OpexSupplierDetailsServiceClient.prototype.updateOpexSupplierDetails =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/external.OpexSupplierDetailsService/UpdateOpexSupplierDetails',
      request,
      metadata || {},
      methodDescriptor_OpexSupplierDetailsService_UpdateOpexSupplierDetails,
      callback);
};


/**
 * @param {!proto.external.UpdateOpexSupplierDetailsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.google.protobuf.Empty>}
 *     Promise that resolves to the response
 */
proto.external.OpexSupplierDetailsServicePromiseClient.prototype.updateOpexSupplierDetails =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/external.OpexSupplierDetailsService/UpdateOpexSupplierDetails',
      request,
      metadata || {},
      methodDescriptor_OpexSupplierDetailsService_UpdateOpexSupplierDetails);
};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.external.OpexManagerServiceClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options['format'] = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.external.OpexManagerServicePromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options['format'] = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.external.OpexManagerRequest,
 *   !proto.external.OpexManagerResponse>}
 */
const methodDescriptor_OpexManagerService_GetOpexManagerByID = new grpc.web.MethodDescriptor(
  '/external.OpexManagerService/GetOpexManagerByID',
  grpc.web.MethodType.UNARY,
  proto.external.OpexManagerRequest,
  proto.external.OpexManagerResponse,
  /**
   * @param {!proto.external.OpexManagerRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.external.OpexManagerResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.external.OpexManagerRequest,
 *   !proto.external.OpexManagerResponse>}
 */
const methodInfo_OpexManagerService_GetOpexManagerByID = new grpc.web.AbstractClientBase.MethodInfo(
  proto.external.OpexManagerResponse,
  /**
   * @param {!proto.external.OpexManagerRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.external.OpexManagerResponse.deserializeBinary
);


/**
 * @param {!proto.external.OpexManagerRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.external.OpexManagerResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.external.OpexManagerResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.external.OpexManagerServiceClient.prototype.getOpexManagerByID =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/external.OpexManagerService/GetOpexManagerByID',
      request,
      metadata || {},
      methodDescriptor_OpexManagerService_GetOpexManagerByID,
      callback);
};


/**
 * @param {!proto.external.OpexManagerRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.external.OpexManagerResponse>}
 *     Promise that resolves to the response
 */
proto.external.OpexManagerServicePromiseClient.prototype.getOpexManagerByID =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/external.OpexManagerService/GetOpexManagerByID',
      request,
      metadata || {},
      methodDescriptor_OpexManagerService_GetOpexManagerByID);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.external.OpexManagerRequest,
 *   !proto.external.OpexManagersResponse>}
 */
const methodDescriptor_OpexManagerService_GetOpexManagersByAssetID = new grpc.web.MethodDescriptor(
  '/external.OpexManagerService/GetOpexManagersByAssetID',
  grpc.web.MethodType.UNARY,
  proto.external.OpexManagerRequest,
  proto.external.OpexManagersResponse,
  /**
   * @param {!proto.external.OpexManagerRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.external.OpexManagersResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.external.OpexManagerRequest,
 *   !proto.external.OpexManagersResponse>}
 */
const methodInfo_OpexManagerService_GetOpexManagersByAssetID = new grpc.web.AbstractClientBase.MethodInfo(
  proto.external.OpexManagersResponse,
  /**
   * @param {!proto.external.OpexManagerRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.external.OpexManagersResponse.deserializeBinary
);


/**
 * @param {!proto.external.OpexManagerRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.external.OpexManagersResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.external.OpexManagersResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.external.OpexManagerServiceClient.prototype.getOpexManagersByAssetID =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/external.OpexManagerService/GetOpexManagersByAssetID',
      request,
      metadata || {},
      methodDescriptor_OpexManagerService_GetOpexManagersByAssetID,
      callback);
};


/**
 * @param {!proto.external.OpexManagerRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.external.OpexManagersResponse>}
 *     Promise that resolves to the response
 */
proto.external.OpexManagerServicePromiseClient.prototype.getOpexManagersByAssetID =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/external.OpexManagerService/GetOpexManagersByAssetID',
      request,
      metadata || {},
      methodDescriptor_OpexManagerService_GetOpexManagersByAssetID);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.external.CreateOpexManagerRequest,
 *   !proto.external.CreateOpexManagerResponse>}
 */
const methodDescriptor_OpexManagerService_CreateOpexManager = new grpc.web.MethodDescriptor(
  '/external.OpexManagerService/CreateOpexManager',
  grpc.web.MethodType.UNARY,
  proto.external.CreateOpexManagerRequest,
  proto.external.CreateOpexManagerResponse,
  /**
   * @param {!proto.external.CreateOpexManagerRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.external.CreateOpexManagerResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.external.CreateOpexManagerRequest,
 *   !proto.external.CreateOpexManagerResponse>}
 */
const methodInfo_OpexManagerService_CreateOpexManager = new grpc.web.AbstractClientBase.MethodInfo(
  proto.external.CreateOpexManagerResponse,
  /**
   * @param {!proto.external.CreateOpexManagerRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.external.CreateOpexManagerResponse.deserializeBinary
);


/**
 * @param {!proto.external.CreateOpexManagerRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.external.CreateOpexManagerResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.external.CreateOpexManagerResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.external.OpexManagerServiceClient.prototype.createOpexManager =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/external.OpexManagerService/CreateOpexManager',
      request,
      metadata || {},
      methodDescriptor_OpexManagerService_CreateOpexManager,
      callback);
};


/**
 * @param {!proto.external.CreateOpexManagerRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.external.CreateOpexManagerResponse>}
 *     Promise that resolves to the response
 */
proto.external.OpexManagerServicePromiseClient.prototype.createOpexManager =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/external.OpexManagerService/CreateOpexManager',
      request,
      metadata || {},
      methodDescriptor_OpexManagerService_CreateOpexManager);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.external.UpdateOpexManagerRequest,
 *   !proto.google.protobuf.Empty>}
 */
const methodDescriptor_OpexManagerService_UpdateOpexManager = new grpc.web.MethodDescriptor(
  '/external.OpexManagerService/UpdateOpexManager',
  grpc.web.MethodType.UNARY,
  proto.external.UpdateOpexManagerRequest,
  google_protobuf_empty_pb.Empty,
  /**
   * @param {!proto.external.UpdateOpexManagerRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  google_protobuf_empty_pb.Empty.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.external.UpdateOpexManagerRequest,
 *   !proto.google.protobuf.Empty>}
 */
const methodInfo_OpexManagerService_UpdateOpexManager = new grpc.web.AbstractClientBase.MethodInfo(
  google_protobuf_empty_pb.Empty,
  /**
   * @param {!proto.external.UpdateOpexManagerRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  google_protobuf_empty_pb.Empty.deserializeBinary
);


/**
 * @param {!proto.external.UpdateOpexManagerRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.google.protobuf.Empty)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.google.protobuf.Empty>|undefined}
 *     The XHR Node Readable Stream
 */
proto.external.OpexManagerServiceClient.prototype.updateOpexManager =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/external.OpexManagerService/UpdateOpexManager',
      request,
      metadata || {},
      methodDescriptor_OpexManagerService_UpdateOpexManager,
      callback);
};


/**
 * @param {!proto.external.UpdateOpexManagerRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.google.protobuf.Empty>}
 *     Promise that resolves to the response
 */
proto.external.OpexManagerServicePromiseClient.prototype.updateOpexManager =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/external.OpexManagerService/UpdateOpexManager',
      request,
      metadata || {},
      methodDescriptor_OpexManagerService_UpdateOpexManager);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.external.OpexManagerRequest,
 *   !proto.google.protobuf.Empty>}
 */
const methodDescriptor_OpexManagerService_DeleteOpexManager = new grpc.web.MethodDescriptor(
  '/external.OpexManagerService/DeleteOpexManager',
  grpc.web.MethodType.UNARY,
  proto.external.OpexManagerRequest,
  google_protobuf_empty_pb.Empty,
  /**
   * @param {!proto.external.OpexManagerRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  google_protobuf_empty_pb.Empty.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.external.OpexManagerRequest,
 *   !proto.google.protobuf.Empty>}
 */
const methodInfo_OpexManagerService_DeleteOpexManager = new grpc.web.AbstractClientBase.MethodInfo(
  google_protobuf_empty_pb.Empty,
  /**
   * @param {!proto.external.OpexManagerRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  google_protobuf_empty_pb.Empty.deserializeBinary
);


/**
 * @param {!proto.external.OpexManagerRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.google.protobuf.Empty)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.google.protobuf.Empty>|undefined}
 *     The XHR Node Readable Stream
 */
proto.external.OpexManagerServiceClient.prototype.deleteOpexManager =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/external.OpexManagerService/DeleteOpexManager',
      request,
      metadata || {},
      methodDescriptor_OpexManagerService_DeleteOpexManager,
      callback);
};


/**
 * @param {!proto.external.OpexManagerRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.google.protobuf.Empty>}
 *     Promise that resolves to the response
 */
proto.external.OpexManagerServicePromiseClient.prototype.deleteOpexManager =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/external.OpexManagerService/DeleteOpexManager',
      request,
      metadata || {},
      methodDescriptor_OpexManagerService_DeleteOpexManager);
};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.external.FootfallServiceClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options['format'] = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.external.FootfallServicePromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options['format'] = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.external.GetFootfallsByAssetAndMonthRequest,
 *   !proto.external.GetFootfallsResponse>}
 */
const methodDescriptor_FootfallService_GetFootfallsByAssetIDAndMonth = new grpc.web.MethodDescriptor(
  '/external.FootfallService/GetFootfallsByAssetIDAndMonth',
  grpc.web.MethodType.UNARY,
  proto.external.GetFootfallsByAssetAndMonthRequest,
  proto.external.GetFootfallsResponse,
  /**
   * @param {!proto.external.GetFootfallsByAssetAndMonthRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.external.GetFootfallsResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.external.GetFootfallsByAssetAndMonthRequest,
 *   !proto.external.GetFootfallsResponse>}
 */
const methodInfo_FootfallService_GetFootfallsByAssetIDAndMonth = new grpc.web.AbstractClientBase.MethodInfo(
  proto.external.GetFootfallsResponse,
  /**
   * @param {!proto.external.GetFootfallsByAssetAndMonthRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.external.GetFootfallsResponse.deserializeBinary
);


/**
 * @param {!proto.external.GetFootfallsByAssetAndMonthRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.external.GetFootfallsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.external.GetFootfallsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.external.FootfallServiceClient.prototype.getFootfallsByAssetIDAndMonth =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/external.FootfallService/GetFootfallsByAssetIDAndMonth',
      request,
      metadata || {},
      methodDescriptor_FootfallService_GetFootfallsByAssetIDAndMonth,
      callback);
};


/**
 * @param {!proto.external.GetFootfallsByAssetAndMonthRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.external.GetFootfallsResponse>}
 *     Promise that resolves to the response
 */
proto.external.FootfallServicePromiseClient.prototype.getFootfallsByAssetIDAndMonth =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/external.FootfallService/GetFootfallsByAssetIDAndMonth',
      request,
      metadata || {},
      methodDescriptor_FootfallService_GetFootfallsByAssetIDAndMonth);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.external.GetFootfallsRequest,
 *   !proto.external.Footfall>}
 */
const methodDescriptor_FootfallService_GetFootfallByID = new grpc.web.MethodDescriptor(
  '/external.FootfallService/GetFootfallByID',
  grpc.web.MethodType.UNARY,
  proto.external.GetFootfallsRequest,
  proto.external.Footfall,
  /**
   * @param {!proto.external.GetFootfallsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.external.Footfall.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.external.GetFootfallsRequest,
 *   !proto.external.Footfall>}
 */
const methodInfo_FootfallService_GetFootfallByID = new grpc.web.AbstractClientBase.MethodInfo(
  proto.external.Footfall,
  /**
   * @param {!proto.external.GetFootfallsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.external.Footfall.deserializeBinary
);


/**
 * @param {!proto.external.GetFootfallsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.external.Footfall)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.external.Footfall>|undefined}
 *     The XHR Node Readable Stream
 */
proto.external.FootfallServiceClient.prototype.getFootfallByID =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/external.FootfallService/GetFootfallByID',
      request,
      metadata || {},
      methodDescriptor_FootfallService_GetFootfallByID,
      callback);
};


/**
 * @param {!proto.external.GetFootfallsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.external.Footfall>}
 *     Promise that resolves to the response
 */
proto.external.FootfallServicePromiseClient.prototype.getFootfallByID =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/external.FootfallService/GetFootfallByID',
      request,
      metadata || {},
      methodDescriptor_FootfallService_GetFootfallByID);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.external.GetFootfallsRequest,
 *   !proto.google.protobuf.Empty>}
 */
const methodDescriptor_FootfallService_DeleteFootfall = new grpc.web.MethodDescriptor(
  '/external.FootfallService/DeleteFootfall',
  grpc.web.MethodType.UNARY,
  proto.external.GetFootfallsRequest,
  google_protobuf_empty_pb.Empty,
  /**
   * @param {!proto.external.GetFootfallsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  google_protobuf_empty_pb.Empty.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.external.GetFootfallsRequest,
 *   !proto.google.protobuf.Empty>}
 */
const methodInfo_FootfallService_DeleteFootfall = new grpc.web.AbstractClientBase.MethodInfo(
  google_protobuf_empty_pb.Empty,
  /**
   * @param {!proto.external.GetFootfallsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  google_protobuf_empty_pb.Empty.deserializeBinary
);


/**
 * @param {!proto.external.GetFootfallsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.google.protobuf.Empty)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.google.protobuf.Empty>|undefined}
 *     The XHR Node Readable Stream
 */
proto.external.FootfallServiceClient.prototype.deleteFootfall =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/external.FootfallService/DeleteFootfall',
      request,
      metadata || {},
      methodDescriptor_FootfallService_DeleteFootfall,
      callback);
};


/**
 * @param {!proto.external.GetFootfallsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.google.protobuf.Empty>}
 *     Promise that resolves to the response
 */
proto.external.FootfallServicePromiseClient.prototype.deleteFootfall =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/external.FootfallService/DeleteFootfall',
      request,
      metadata || {},
      methodDescriptor_FootfallService_DeleteFootfall);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.external.UpdateFootfallRequest,
 *   !proto.google.protobuf.Empty>}
 */
const methodDescriptor_FootfallService_UpdateFootfall = new grpc.web.MethodDescriptor(
  '/external.FootfallService/UpdateFootfall',
  grpc.web.MethodType.UNARY,
  proto.external.UpdateFootfallRequest,
  google_protobuf_empty_pb.Empty,
  /**
   * @param {!proto.external.UpdateFootfallRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  google_protobuf_empty_pb.Empty.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.external.UpdateFootfallRequest,
 *   !proto.google.protobuf.Empty>}
 */
const methodInfo_FootfallService_UpdateFootfall = new grpc.web.AbstractClientBase.MethodInfo(
  google_protobuf_empty_pb.Empty,
  /**
   * @param {!proto.external.UpdateFootfallRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  google_protobuf_empty_pb.Empty.deserializeBinary
);


/**
 * @param {!proto.external.UpdateFootfallRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.google.protobuf.Empty)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.google.protobuf.Empty>|undefined}
 *     The XHR Node Readable Stream
 */
proto.external.FootfallServiceClient.prototype.updateFootfall =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/external.FootfallService/UpdateFootfall',
      request,
      metadata || {},
      methodDescriptor_FootfallService_UpdateFootfall,
      callback);
};


/**
 * @param {!proto.external.UpdateFootfallRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.google.protobuf.Empty>}
 *     Promise that resolves to the response
 */
proto.external.FootfallServicePromiseClient.prototype.updateFootfall =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/external.FootfallService/UpdateFootfall',
      request,
      metadata || {},
      methodDescriptor_FootfallService_UpdateFootfall);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.external.CreateFootfallRequest,
 *   !proto.external.CreateFootfallResponse>}
 */
const methodDescriptor_FootfallService_CreateFootfall = new grpc.web.MethodDescriptor(
  '/external.FootfallService/CreateFootfall',
  grpc.web.MethodType.UNARY,
  proto.external.CreateFootfallRequest,
  proto.external.CreateFootfallResponse,
  /**
   * @param {!proto.external.CreateFootfallRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.external.CreateFootfallResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.external.CreateFootfallRequest,
 *   !proto.external.CreateFootfallResponse>}
 */
const methodInfo_FootfallService_CreateFootfall = new grpc.web.AbstractClientBase.MethodInfo(
  proto.external.CreateFootfallResponse,
  /**
   * @param {!proto.external.CreateFootfallRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.external.CreateFootfallResponse.deserializeBinary
);


/**
 * @param {!proto.external.CreateFootfallRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.external.CreateFootfallResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.external.CreateFootfallResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.external.FootfallServiceClient.prototype.createFootfall =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/external.FootfallService/CreateFootfall',
      request,
      metadata || {},
      methodDescriptor_FootfallService_CreateFootfall,
      callback);
};


/**
 * @param {!proto.external.CreateFootfallRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.external.CreateFootfallResponse>}
 *     Promise that resolves to the response
 */
proto.external.FootfallServicePromiseClient.prototype.createFootfall =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/external.FootfallService/CreateFootfall',
      request,
      metadata || {},
      methodDescriptor_FootfallService_CreateFootfall);
};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.external.AssetCashFlowServiceClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options['format'] = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.external.AssetCashFlowServicePromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options['format'] = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.external.GetOpexManagerByAssetIDRequest,
 *   !proto.external.GetOpexManagerByAssetIDResponse>}
 */
const methodDescriptor_AssetCashFlowService_GetOpexManagerByAssetID = new grpc.web.MethodDescriptor(
  '/external.AssetCashFlowService/GetOpexManagerByAssetID',
  grpc.web.MethodType.UNARY,
  proto.external.GetOpexManagerByAssetIDRequest,
  proto.external.GetOpexManagerByAssetIDResponse,
  /**
   * @param {!proto.external.GetOpexManagerByAssetIDRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.external.GetOpexManagerByAssetIDResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.external.GetOpexManagerByAssetIDRequest,
 *   !proto.external.GetOpexManagerByAssetIDResponse>}
 */
const methodInfo_AssetCashFlowService_GetOpexManagerByAssetID = new grpc.web.AbstractClientBase.MethodInfo(
  proto.external.GetOpexManagerByAssetIDResponse,
  /**
   * @param {!proto.external.GetOpexManagerByAssetIDRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.external.GetOpexManagerByAssetIDResponse.deserializeBinary
);


/**
 * @param {!proto.external.GetOpexManagerByAssetIDRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.external.GetOpexManagerByAssetIDResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.external.GetOpexManagerByAssetIDResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.external.AssetCashFlowServiceClient.prototype.getOpexManagerByAssetID =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/external.AssetCashFlowService/GetOpexManagerByAssetID',
      request,
      metadata || {},
      methodDescriptor_AssetCashFlowService_GetOpexManagerByAssetID,
      callback);
};


/**
 * @param {!proto.external.GetOpexManagerByAssetIDRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.external.GetOpexManagerByAssetIDResponse>}
 *     Promise that resolves to the response
 */
proto.external.AssetCashFlowServicePromiseClient.prototype.getOpexManagerByAssetID =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/external.AssetCashFlowService/GetOpexManagerByAssetID',
      request,
      metadata || {},
      methodDescriptor_AssetCashFlowService_GetOpexManagerByAssetID);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.external.GetCostManagerByAssetIDRequest,
 *   !proto.external.GetCostManagerByAssetIDResponse>}
 */
const methodDescriptor_AssetCashFlowService_GetCostManagerByAssetID = new grpc.web.MethodDescriptor(
  '/external.AssetCashFlowService/GetCostManagerByAssetID',
  grpc.web.MethodType.UNARY,
  proto.external.GetCostManagerByAssetIDRequest,
  proto.external.GetCostManagerByAssetIDResponse,
  /**
   * @param {!proto.external.GetCostManagerByAssetIDRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.external.GetCostManagerByAssetIDResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.external.GetCostManagerByAssetIDRequest,
 *   !proto.external.GetCostManagerByAssetIDResponse>}
 */
const methodInfo_AssetCashFlowService_GetCostManagerByAssetID = new grpc.web.AbstractClientBase.MethodInfo(
  proto.external.GetCostManagerByAssetIDResponse,
  /**
   * @param {!proto.external.GetCostManagerByAssetIDRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.external.GetCostManagerByAssetIDResponse.deserializeBinary
);


/**
 * @param {!proto.external.GetCostManagerByAssetIDRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.external.GetCostManagerByAssetIDResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.external.GetCostManagerByAssetIDResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.external.AssetCashFlowServiceClient.prototype.getCostManagerByAssetID =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/external.AssetCashFlowService/GetCostManagerByAssetID',
      request,
      metadata || {},
      methodDescriptor_AssetCashFlowService_GetCostManagerByAssetID,
      callback);
};


/**
 * @param {!proto.external.GetCostManagerByAssetIDRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.external.GetCostManagerByAssetIDResponse>}
 *     Promise that resolves to the response
 */
proto.external.AssetCashFlowServicePromiseClient.prototype.getCostManagerByAssetID =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/external.AssetCashFlowService/GetCostManagerByAssetID',
      request,
      metadata || {},
      methodDescriptor_AssetCashFlowService_GetCostManagerByAssetID);
};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.external.FacilityManagerTicketServiceClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options['format'] = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.external.FacilityManagerTicketServicePromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options['format'] = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.external.GetFacilityManagerTicketRequest,
 *   !proto.external.GetFacilityManagerTicketResponse>}
 */
const methodDescriptor_FacilityManagerTicketService_GetFacilityManagerTicket = new grpc.web.MethodDescriptor(
  '/external.FacilityManagerTicketService/GetFacilityManagerTicket',
  grpc.web.MethodType.UNARY,
  proto.external.GetFacilityManagerTicketRequest,
  proto.external.GetFacilityManagerTicketResponse,
  /**
   * @param {!proto.external.GetFacilityManagerTicketRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.external.GetFacilityManagerTicketResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.external.GetFacilityManagerTicketRequest,
 *   !proto.external.GetFacilityManagerTicketResponse>}
 */
const methodInfo_FacilityManagerTicketService_GetFacilityManagerTicket = new grpc.web.AbstractClientBase.MethodInfo(
  proto.external.GetFacilityManagerTicketResponse,
  /**
   * @param {!proto.external.GetFacilityManagerTicketRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.external.GetFacilityManagerTicketResponse.deserializeBinary
);


/**
 * @param {!proto.external.GetFacilityManagerTicketRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.external.GetFacilityManagerTicketResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.external.GetFacilityManagerTicketResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.external.FacilityManagerTicketServiceClient.prototype.getFacilityManagerTicket =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/external.FacilityManagerTicketService/GetFacilityManagerTicket',
      request,
      metadata || {},
      methodDescriptor_FacilityManagerTicketService_GetFacilityManagerTicket,
      callback);
};


/**
 * @param {!proto.external.GetFacilityManagerTicketRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.external.GetFacilityManagerTicketResponse>}
 *     Promise that resolves to the response
 */
proto.external.FacilityManagerTicketServicePromiseClient.prototype.getFacilityManagerTicket =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/external.FacilityManagerTicketService/GetFacilityManagerTicket',
      request,
      metadata || {},
      methodDescriptor_FacilityManagerTicketService_GetFacilityManagerTicket);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.external.GetFacilityManagerTicketByAssetIDRequest,
 *   !proto.external.GetFacilityManagerTicketByAssetIDResponse>}
 */
const methodDescriptor_FacilityManagerTicketService_GetFacilityManagerTicketByAssetID = new grpc.web.MethodDescriptor(
  '/external.FacilityManagerTicketService/GetFacilityManagerTicketByAssetID',
  grpc.web.MethodType.UNARY,
  proto.external.GetFacilityManagerTicketByAssetIDRequest,
  proto.external.GetFacilityManagerTicketByAssetIDResponse,
  /**
   * @param {!proto.external.GetFacilityManagerTicketByAssetIDRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.external.GetFacilityManagerTicketByAssetIDResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.external.GetFacilityManagerTicketByAssetIDRequest,
 *   !proto.external.GetFacilityManagerTicketByAssetIDResponse>}
 */
const methodInfo_FacilityManagerTicketService_GetFacilityManagerTicketByAssetID = new grpc.web.AbstractClientBase.MethodInfo(
  proto.external.GetFacilityManagerTicketByAssetIDResponse,
  /**
   * @param {!proto.external.GetFacilityManagerTicketByAssetIDRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.external.GetFacilityManagerTicketByAssetIDResponse.deserializeBinary
);


/**
 * @param {!proto.external.GetFacilityManagerTicketByAssetIDRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.external.GetFacilityManagerTicketByAssetIDResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.external.GetFacilityManagerTicketByAssetIDResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.external.FacilityManagerTicketServiceClient.prototype.getFacilityManagerTicketByAssetID =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/external.FacilityManagerTicketService/GetFacilityManagerTicketByAssetID',
      request,
      metadata || {},
      methodDescriptor_FacilityManagerTicketService_GetFacilityManagerTicketByAssetID,
      callback);
};


/**
 * @param {!proto.external.GetFacilityManagerTicketByAssetIDRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.external.GetFacilityManagerTicketByAssetIDResponse>}
 *     Promise that resolves to the response
 */
proto.external.FacilityManagerTicketServicePromiseClient.prototype.getFacilityManagerTicketByAssetID =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/external.FacilityManagerTicketService/GetFacilityManagerTicketByAssetID',
      request,
      metadata || {},
      methodDescriptor_FacilityManagerTicketService_GetFacilityManagerTicketByAssetID);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.external.CreateFacilityManagerTicketRequest,
 *   !proto.external.CreateFacilityManagerTicketResponse>}
 */
const methodDescriptor_FacilityManagerTicketService_CreateFacilityManagerTicket = new grpc.web.MethodDescriptor(
  '/external.FacilityManagerTicketService/CreateFacilityManagerTicket',
  grpc.web.MethodType.UNARY,
  proto.external.CreateFacilityManagerTicketRequest,
  proto.external.CreateFacilityManagerTicketResponse,
  /**
   * @param {!proto.external.CreateFacilityManagerTicketRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.external.CreateFacilityManagerTicketResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.external.CreateFacilityManagerTicketRequest,
 *   !proto.external.CreateFacilityManagerTicketResponse>}
 */
const methodInfo_FacilityManagerTicketService_CreateFacilityManagerTicket = new grpc.web.AbstractClientBase.MethodInfo(
  proto.external.CreateFacilityManagerTicketResponse,
  /**
   * @param {!proto.external.CreateFacilityManagerTicketRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.external.CreateFacilityManagerTicketResponse.deserializeBinary
);


/**
 * @param {!proto.external.CreateFacilityManagerTicketRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.external.CreateFacilityManagerTicketResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.external.CreateFacilityManagerTicketResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.external.FacilityManagerTicketServiceClient.prototype.createFacilityManagerTicket =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/external.FacilityManagerTicketService/CreateFacilityManagerTicket',
      request,
      metadata || {},
      methodDescriptor_FacilityManagerTicketService_CreateFacilityManagerTicket,
      callback);
};


/**
 * @param {!proto.external.CreateFacilityManagerTicketRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.external.CreateFacilityManagerTicketResponse>}
 *     Promise that resolves to the response
 */
proto.external.FacilityManagerTicketServicePromiseClient.prototype.createFacilityManagerTicket =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/external.FacilityManagerTicketService/CreateFacilityManagerTicket',
      request,
      metadata || {},
      methodDescriptor_FacilityManagerTicketService_CreateFacilityManagerTicket);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.external.UpdateFacilityManagerTicketRequest,
 *   !proto.google.protobuf.Empty>}
 */
const methodDescriptor_FacilityManagerTicketService_UpdateFacilityManagerTicket = new grpc.web.MethodDescriptor(
  '/external.FacilityManagerTicketService/UpdateFacilityManagerTicket',
  grpc.web.MethodType.UNARY,
  proto.external.UpdateFacilityManagerTicketRequest,
  google_protobuf_empty_pb.Empty,
  /**
   * @param {!proto.external.UpdateFacilityManagerTicketRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  google_protobuf_empty_pb.Empty.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.external.UpdateFacilityManagerTicketRequest,
 *   !proto.google.protobuf.Empty>}
 */
const methodInfo_FacilityManagerTicketService_UpdateFacilityManagerTicket = new grpc.web.AbstractClientBase.MethodInfo(
  google_protobuf_empty_pb.Empty,
  /**
   * @param {!proto.external.UpdateFacilityManagerTicketRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  google_protobuf_empty_pb.Empty.deserializeBinary
);


/**
 * @param {!proto.external.UpdateFacilityManagerTicketRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.google.protobuf.Empty)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.google.protobuf.Empty>|undefined}
 *     The XHR Node Readable Stream
 */
proto.external.FacilityManagerTicketServiceClient.prototype.updateFacilityManagerTicket =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/external.FacilityManagerTicketService/UpdateFacilityManagerTicket',
      request,
      metadata || {},
      methodDescriptor_FacilityManagerTicketService_UpdateFacilityManagerTicket,
      callback);
};


/**
 * @param {!proto.external.UpdateFacilityManagerTicketRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.google.protobuf.Empty>}
 *     Promise that resolves to the response
 */
proto.external.FacilityManagerTicketServicePromiseClient.prototype.updateFacilityManagerTicket =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/external.FacilityManagerTicketService/UpdateFacilityManagerTicket',
      request,
      metadata || {},
      methodDescriptor_FacilityManagerTicketService_UpdateFacilityManagerTicket);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.external.DeleteFacilityManagerTicketRequest,
 *   !proto.google.protobuf.Empty>}
 */
const methodDescriptor_FacilityManagerTicketService_DeleteFacilityManagerTicket = new grpc.web.MethodDescriptor(
  '/external.FacilityManagerTicketService/DeleteFacilityManagerTicket',
  grpc.web.MethodType.UNARY,
  proto.external.DeleteFacilityManagerTicketRequest,
  google_protobuf_empty_pb.Empty,
  /**
   * @param {!proto.external.DeleteFacilityManagerTicketRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  google_protobuf_empty_pb.Empty.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.external.DeleteFacilityManagerTicketRequest,
 *   !proto.google.protobuf.Empty>}
 */
const methodInfo_FacilityManagerTicketService_DeleteFacilityManagerTicket = new grpc.web.AbstractClientBase.MethodInfo(
  google_protobuf_empty_pb.Empty,
  /**
   * @param {!proto.external.DeleteFacilityManagerTicketRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  google_protobuf_empty_pb.Empty.deserializeBinary
);


/**
 * @param {!proto.external.DeleteFacilityManagerTicketRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.google.protobuf.Empty)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.google.protobuf.Empty>|undefined}
 *     The XHR Node Readable Stream
 */
proto.external.FacilityManagerTicketServiceClient.prototype.deleteFacilityManagerTicket =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/external.FacilityManagerTicketService/DeleteFacilityManagerTicket',
      request,
      metadata || {},
      methodDescriptor_FacilityManagerTicketService_DeleteFacilityManagerTicket,
      callback);
};


/**
 * @param {!proto.external.DeleteFacilityManagerTicketRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.google.protobuf.Empty>}
 *     Promise that resolves to the response
 */
proto.external.FacilityManagerTicketServicePromiseClient.prototype.deleteFacilityManagerTicket =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/external.FacilityManagerTicketService/DeleteFacilityManagerTicket',
      request,
      metadata || {},
      methodDescriptor_FacilityManagerTicketService_DeleteFacilityManagerTicket);
};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.external.LegalTrackerTicketServiceClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options['format'] = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.external.LegalTrackerTicketServicePromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options['format'] = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.external.CreateLegalTrackerTicketRequest,
 *   !proto.external.CreateLegalTrackerTicketResponse>}
 */
const methodDescriptor_LegalTrackerTicketService_CreateLegalTrackerTicket = new grpc.web.MethodDescriptor(
  '/external.LegalTrackerTicketService/CreateLegalTrackerTicket',
  grpc.web.MethodType.UNARY,
  proto.external.CreateLegalTrackerTicketRequest,
  proto.external.CreateLegalTrackerTicketResponse,
  /**
   * @param {!proto.external.CreateLegalTrackerTicketRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.external.CreateLegalTrackerTicketResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.external.CreateLegalTrackerTicketRequest,
 *   !proto.external.CreateLegalTrackerTicketResponse>}
 */
const methodInfo_LegalTrackerTicketService_CreateLegalTrackerTicket = new grpc.web.AbstractClientBase.MethodInfo(
  proto.external.CreateLegalTrackerTicketResponse,
  /**
   * @param {!proto.external.CreateLegalTrackerTicketRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.external.CreateLegalTrackerTicketResponse.deserializeBinary
);


/**
 * @param {!proto.external.CreateLegalTrackerTicketRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.external.CreateLegalTrackerTicketResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.external.CreateLegalTrackerTicketResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.external.LegalTrackerTicketServiceClient.prototype.createLegalTrackerTicket =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/external.LegalTrackerTicketService/CreateLegalTrackerTicket',
      request,
      metadata || {},
      methodDescriptor_LegalTrackerTicketService_CreateLegalTrackerTicket,
      callback);
};


/**
 * @param {!proto.external.CreateLegalTrackerTicketRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.external.CreateLegalTrackerTicketResponse>}
 *     Promise that resolves to the response
 */
proto.external.LegalTrackerTicketServicePromiseClient.prototype.createLegalTrackerTicket =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/external.LegalTrackerTicketService/CreateLegalTrackerTicket',
      request,
      metadata || {},
      methodDescriptor_LegalTrackerTicketService_CreateLegalTrackerTicket);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.external.GetLegalTrackerTicketRequest,
 *   !proto.external.GetLegalTrackerTicketResponse>}
 */
const methodDescriptor_LegalTrackerTicketService_GetLegalTrackerTicket = new grpc.web.MethodDescriptor(
  '/external.LegalTrackerTicketService/GetLegalTrackerTicket',
  grpc.web.MethodType.UNARY,
  proto.external.GetLegalTrackerTicketRequest,
  proto.external.GetLegalTrackerTicketResponse,
  /**
   * @param {!proto.external.GetLegalTrackerTicketRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.external.GetLegalTrackerTicketResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.external.GetLegalTrackerTicketRequest,
 *   !proto.external.GetLegalTrackerTicketResponse>}
 */
const methodInfo_LegalTrackerTicketService_GetLegalTrackerTicket = new grpc.web.AbstractClientBase.MethodInfo(
  proto.external.GetLegalTrackerTicketResponse,
  /**
   * @param {!proto.external.GetLegalTrackerTicketRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.external.GetLegalTrackerTicketResponse.deserializeBinary
);


/**
 * @param {!proto.external.GetLegalTrackerTicketRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.external.GetLegalTrackerTicketResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.external.GetLegalTrackerTicketResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.external.LegalTrackerTicketServiceClient.prototype.getLegalTrackerTicket =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/external.LegalTrackerTicketService/GetLegalTrackerTicket',
      request,
      metadata || {},
      methodDescriptor_LegalTrackerTicketService_GetLegalTrackerTicket,
      callback);
};


/**
 * @param {!proto.external.GetLegalTrackerTicketRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.external.GetLegalTrackerTicketResponse>}
 *     Promise that resolves to the response
 */
proto.external.LegalTrackerTicketServicePromiseClient.prototype.getLegalTrackerTicket =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/external.LegalTrackerTicketService/GetLegalTrackerTicket',
      request,
      metadata || {},
      methodDescriptor_LegalTrackerTicketService_GetLegalTrackerTicket);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.external.GetLegalTrackerTicketByAssetIDRequest,
 *   !proto.external.GetLegalTrackerTicketByAssetIDResponse>}
 */
const methodDescriptor_LegalTrackerTicketService_GetLegalTrackerTicketByAssetID = new grpc.web.MethodDescriptor(
  '/external.LegalTrackerTicketService/GetLegalTrackerTicketByAssetID',
  grpc.web.MethodType.UNARY,
  proto.external.GetLegalTrackerTicketByAssetIDRequest,
  proto.external.GetLegalTrackerTicketByAssetIDResponse,
  /**
   * @param {!proto.external.GetLegalTrackerTicketByAssetIDRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.external.GetLegalTrackerTicketByAssetIDResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.external.GetLegalTrackerTicketByAssetIDRequest,
 *   !proto.external.GetLegalTrackerTicketByAssetIDResponse>}
 */
const methodInfo_LegalTrackerTicketService_GetLegalTrackerTicketByAssetID = new grpc.web.AbstractClientBase.MethodInfo(
  proto.external.GetLegalTrackerTicketByAssetIDResponse,
  /**
   * @param {!proto.external.GetLegalTrackerTicketByAssetIDRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.external.GetLegalTrackerTicketByAssetIDResponse.deserializeBinary
);


/**
 * @param {!proto.external.GetLegalTrackerTicketByAssetIDRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.external.GetLegalTrackerTicketByAssetIDResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.external.GetLegalTrackerTicketByAssetIDResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.external.LegalTrackerTicketServiceClient.prototype.getLegalTrackerTicketByAssetID =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/external.LegalTrackerTicketService/GetLegalTrackerTicketByAssetID',
      request,
      metadata || {},
      methodDescriptor_LegalTrackerTicketService_GetLegalTrackerTicketByAssetID,
      callback);
};


/**
 * @param {!proto.external.GetLegalTrackerTicketByAssetIDRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.external.GetLegalTrackerTicketByAssetIDResponse>}
 *     Promise that resolves to the response
 */
proto.external.LegalTrackerTicketServicePromiseClient.prototype.getLegalTrackerTicketByAssetID =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/external.LegalTrackerTicketService/GetLegalTrackerTicketByAssetID',
      request,
      metadata || {},
      methodDescriptor_LegalTrackerTicketService_GetLegalTrackerTicketByAssetID);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.external.UpdateLegalTrackerTicketRequest,
 *   !proto.google.protobuf.Empty>}
 */
const methodDescriptor_LegalTrackerTicketService_UpdateLegalTrackerTicket = new grpc.web.MethodDescriptor(
  '/external.LegalTrackerTicketService/UpdateLegalTrackerTicket',
  grpc.web.MethodType.UNARY,
  proto.external.UpdateLegalTrackerTicketRequest,
  google_protobuf_empty_pb.Empty,
  /**
   * @param {!proto.external.UpdateLegalTrackerTicketRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  google_protobuf_empty_pb.Empty.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.external.UpdateLegalTrackerTicketRequest,
 *   !proto.google.protobuf.Empty>}
 */
const methodInfo_LegalTrackerTicketService_UpdateLegalTrackerTicket = new grpc.web.AbstractClientBase.MethodInfo(
  google_protobuf_empty_pb.Empty,
  /**
   * @param {!proto.external.UpdateLegalTrackerTicketRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  google_protobuf_empty_pb.Empty.deserializeBinary
);


/**
 * @param {!proto.external.UpdateLegalTrackerTicketRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.google.protobuf.Empty)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.google.protobuf.Empty>|undefined}
 *     The XHR Node Readable Stream
 */
proto.external.LegalTrackerTicketServiceClient.prototype.updateLegalTrackerTicket =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/external.LegalTrackerTicketService/UpdateLegalTrackerTicket',
      request,
      metadata || {},
      methodDescriptor_LegalTrackerTicketService_UpdateLegalTrackerTicket,
      callback);
};


/**
 * @param {!proto.external.UpdateLegalTrackerTicketRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.google.protobuf.Empty>}
 *     Promise that resolves to the response
 */
proto.external.LegalTrackerTicketServicePromiseClient.prototype.updateLegalTrackerTicket =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/external.LegalTrackerTicketService/UpdateLegalTrackerTicket',
      request,
      metadata || {},
      methodDescriptor_LegalTrackerTicketService_UpdateLegalTrackerTicket);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.external.DeleteLegalTrackerTicketRequest,
 *   !proto.google.protobuf.Empty>}
 */
const methodDescriptor_LegalTrackerTicketService_DeleteLegalTrackerTicket = new grpc.web.MethodDescriptor(
  '/external.LegalTrackerTicketService/DeleteLegalTrackerTicket',
  grpc.web.MethodType.UNARY,
  proto.external.DeleteLegalTrackerTicketRequest,
  google_protobuf_empty_pb.Empty,
  /**
   * @param {!proto.external.DeleteLegalTrackerTicketRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  google_protobuf_empty_pb.Empty.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.external.DeleteLegalTrackerTicketRequest,
 *   !proto.google.protobuf.Empty>}
 */
const methodInfo_LegalTrackerTicketService_DeleteLegalTrackerTicket = new grpc.web.AbstractClientBase.MethodInfo(
  google_protobuf_empty_pb.Empty,
  /**
   * @param {!proto.external.DeleteLegalTrackerTicketRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  google_protobuf_empty_pb.Empty.deserializeBinary
);


/**
 * @param {!proto.external.DeleteLegalTrackerTicketRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.google.protobuf.Empty)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.google.protobuf.Empty>|undefined}
 *     The XHR Node Readable Stream
 */
proto.external.LegalTrackerTicketServiceClient.prototype.deleteLegalTrackerTicket =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/external.LegalTrackerTicketService/DeleteLegalTrackerTicket',
      request,
      metadata || {},
      methodDescriptor_LegalTrackerTicketService_DeleteLegalTrackerTicket,
      callback);
};


/**
 * @param {!proto.external.DeleteLegalTrackerTicketRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.google.protobuf.Empty>}
 *     Promise that resolves to the response
 */
proto.external.LegalTrackerTicketServicePromiseClient.prototype.deleteLegalTrackerTicket =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/external.LegalTrackerTicketService/DeleteLegalTrackerTicket',
      request,
      metadata || {},
      methodDescriptor_LegalTrackerTicketService_DeleteLegalTrackerTicket);
};


module.exports = proto.external;

