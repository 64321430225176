import React, { useState, useMemo, useCallback } from 'react';

import InputBase from '@material-ui/core/InputBase';
import { makeStyles, alpha } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import SearchIcon from '@material-ui/icons/Search';
import { Autocomplete, createFilterOptions } from '@material-ui/lab';
import Router from 'hooks/useRouter';
import { debounce } from 'lodash';
import { useSelector } from 'react-redux';

const useStyles = makeStyles((theme) => ({
  input: {
    color: 'white'
  },
  root: {
    color: 'white'
  },
  inputInput: {
    color: 'white',
    paddingRight: `calc(1em + ${theme.spacing(4)}px)`,
    paddingLeft: theme.spacing(4),
    transition: theme.transitions.create('width'),
    width: '100%',
    letterSpacing: '-0.01em',
    lineHeight: '22px',
    [theme.breakpoints.up('md')]: {
      width: '19ch',
      '&:focus': {
        width: '22ch'
      }
    }
  },
  search: {
    display: 'flex',
    justifyContent: 'flex-end',
    position: 'relative',
    borderRadius: 20,
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: alpha(theme.palette.common.white, 0.25)
    },
    marginRight: theme.spacing(10),
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(3),
      width: 'auto',
      minWidth: 200
    }
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  inputRoot: {
    color: 'inherit',
    minWidth: 200,
    height: 40
  },
  listbox: {
    padding: 0
  },
  option: {
    background: 'transparent',
    '&[aria-selected="true"]': {
      background: 'transparent'
    }
  },
  listItem: {
    fontFamily: theme.fonts.openSans,
    color: '#5C5C5C'
  },
  paperroot: {
    boxShadow: '0px 3px 14px #0000002B'
  }
}));

const OPTIONS_LIMIT = 5;
const defaultFilterOptions = createFilterOptions();

const filterOptions = (options, state) =>
  defaultFilterOptions(options, state).slice(0, OPTIONS_LIMIT);

export default function SearchInput() {
  const classes = useStyles();
  const [value, setValue] = useState({
    name: '',
    id: ''
  });
  const [input, setInput] = useState('');
  const router = Router();
  const { data: assets } = useSelector((state) => state.assets.assets);
  const [open, setOpen] = useState(true);
  const [loading, setLoading] = useState(false);

  const setDebounce = useMemo(
    () =>
      debounce(() => {
        setLoading(false);
      }, 500),
    []
  );

  const handleInput = useCallback(
    (e) => {
      if (!loading) setLoading(true);

      if (e?.type === 'change') {
        if (!open) setOpen(true);
        setInput(e.target.value);
        setDebounce(e.target.value);
      } else {
        setInput('');
        document.activeElement.blur();
        router.push(`/Asset/${value.id}/Details`);
      }
    },
    [value, open]
  );

  return (
    <div className={`${classes.search} Search-nav`}>
      <div className={classes.searchIcon}>
        <SearchIcon />
      </div>
      <Autocomplete
        id="search"
        freeSolo
        filterOptions={filterOptions}
        options={loading ? [] : assets}
        disableClearable
        value={value}
        loading={loading}
        inputValue={input}
        onInputChange={handleInput}
        onChange={(event, newValue) => {
          setValue(newValue);
        }}
        classes={{
          listbox: classes.listbox,
          option: classes.option,
          paper: classes.paperroot
        }}
        open={input.length > 2 && open}
        getOptionLabel={(option) => option?.name}
        renderOption={(option) => (
          <>
            <Typography className={classes.listItem}>{option.name}</Typography>
          </>
        )}
        renderInput={(params) => {
          const { InputLabelProps, InputProps, ...rest } = params;

          return (
            <InputBase
              {...params.InputProps}
              {...rest}
              placeholder="Search ..."
              classes={{
                root: classes.inputRoot,
                input: classes.inputInput
              }}
              onBlur={() => {
                setOpen(false);
              }}
            />
          );
        }}
      />
    </div>
  );
}
