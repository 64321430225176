import React from 'react';

import { Box, makeStyles, Typography, Grid, Tooltip } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import DescriptionOutlinedIcon from '@material-ui/icons/DescriptionOutlined';
import { formatDate } from 'helpers/utils';
import PropTypes from 'prop-types';

const useStyles = makeStyles((theme) => ({
  root: {
    border: '1px solid #D9D9D9',
    width: 270,
    height: 75,
    marginTop: theme.spacing(2),
    marginRight: theme.spacing(2),
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    [theme.breakpoints.down('md')]: {
      width: 180
    }
  },
  inner: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  editIcon: {
    color: theme.palette.primary.main,
    marginLeft: theme.spacing(1),
    marginTop: theme.spacing(1),
    cursor: 'pointer'
  },
  closeIcon: {
    marginRight: theme.spacing(1),
    cursor: 'pointer',
    fontSize: 20
  },
  nameText: {
    fontFamily: theme.fonts.openSans,
    color: theme.palette.text.grey
  },
  dateText: {
    fontFamily: theme.fonts.openSans,
    fontSize: 12,
    color: '#7D7D7D',
    [theme.breakpoints.down('md')]: {
      display: 'none'
    }
  }
}));

const UploadedFile = ({ name, dateUploaded, url, handleDelete }) => {
  const classes = useStyles();

  return (
    <Box className={classes.root}>
      <Grid container className={classes.inner}>
        <Grid item sm={2}>
          <a target="_blank" rel="noreferrer" href={url}>
            <DescriptionOutlinedIcon className={classes.editIcon} />
          </a>
        </Grid>

        <Grid item sm={8}>
          <Tooltip title={name} aria-label={name}>
            <Box display="flex" justifyContent="center" flexDirection="column" pl={1} pr={1}>
              <Typography className={classes.nameText}>{`${name.substring(0, 12)}..`}</Typography>
              <Typography className={classes.dateText}>
                {`Uploaded ${formatDate(dateUploaded)}`}
              </Typography>
            </Box>
          </Tooltip>
        </Grid>

        <Grid item sm={2}>
          <Box display="flex" justifyContent="flex-end">
            <CloseIcon className={classes.closeIcon} onClick={handleDelete} />
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default React.memo(UploadedFile);

UploadedFile.defaultProps = {
  handleDelete: () => {}
};

UploadedFile.propTypes = {
  name: PropTypes.string.isRequired,
  dateUploaded: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  handleDelete: PropTypes.func
};
