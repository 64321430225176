import React from 'react';

import { openModal } from 'components/Modal/modalReducer';
import Table from 'components/Table';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import * as actions from 'store/actions';

const UsersTable = ({ title = '', data = [], columns = [] }) => {
  const dispatch = useDispatch();
  const handleDel = (id) => {
    dispatch(actions.deleteOrganisation(id)).then(() => {
      dispatch(actions.getOrganisations());
    });
  };

  const createNewOrganisation = () => {
    dispatch(
      openModal({
        modalType: 'OrganisationModal',
        modalProps: {
          name: '',
          domain: ''
        }
      })
    );
  };

  return (
    <Table
      handleNew={createNewOrganisation}
      actions={[
        (rowData) => ({
          icon: 'delete',
          tooltip: 'Delete',
          disabled: true,
          // iconProps: { style: { color: '#2C324D' } },
          iconProps: { style: { color: 'grey' } },
          onClick: () => handleDel(rowData.id)
        })
      ]}
      loading={false}
      title={title}
      columns={columns}
      data={data}
      testid="organisations-table"
      components={{
        Toolbar: () => null
      }}
    />
  );
};

export default UsersTable;

UsersTable.propTypes = {
  title: PropTypes.string.isRequired,
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  columns: PropTypes.arrayOf(PropTypes.object).isRequired
};
