import * as actionTypes from '../actions/actionTypes';

export const actualsInitialState = {
  actuals: [],
  loading: false,
  loaded: false,
  error: null
};
const reducer = (state = actualsInitialState, action) => {
  switch (action.type) {
    case actionTypes.GET_ACTUALS_LIST_START:
      return {
        ...state,
        loading: true
      };
    case actionTypes.GET_ACTUALS_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        actuals: action.actuals
      };
    case actionTypes.GET_ACTUALS_LIST_FAIL:
      return {
        ...state,
        loading: false,
        loaded: true,
        error: action.error
      };
    case actionTypes.RESET_ACTUALS_STATE:
      return actualsInitialState;
    default:
      return state;
  }
};

export default reducer;
