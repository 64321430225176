import { metaData } from 'config/config';
import wrappers from 'google-protobuf/google/protobuf/wrappers_pb';
import { formatForBackEnd } from 'helpers/utils';
import { toast } from 'react-toastify';

import {
  GetFacilityManagerTicketRequest,
  GetFacilityManagerTicketByAssetIDRequest,
  CreateFacilityManagerTicketRequest,
  DeleteFacilityManagerTicketRequest,
  UpdateFacilityManagerTicketRequest
} from '../../api/asset_grpc_web_pb';
import { FacilityService, FacilityManagementService } from '../services/asset';

import * as actionTypes from './actionTypes';

const getFacilityManagerStart = () => ({
  type: actionTypes.GET_ASSET_FACILITY_MANAGER_START
});

const getFacilityManagerSuccess = (facilityManager) => ({
  type: actionTypes.GET_ASSET_FACILITY_MANAGER_SUCCESS,
  facilityManager
});

const getFacilityManagerFail = (error) => ({
  type: actionTypes.GET_ASSET_FACILITY_MANAGER_FAIL,
  error
});

export const getAssetFacilityManager = (id) => async (dispatch) => {
  dispatch(getFacilityManagerStart());
  const request = new GetFacilityManagerTicketByAssetIDRequest();

  request.setAssetid(id);

  try {
    const result = await FacilityService.getFacilityManagerTicketByAssetID(request, metaData());
    const response = result.toObject().ticketsList;

    dispatch(getFacilityManagerSuccess(response));
    return response;
  } catch (error) {
    dispatch(getFacilityManagerFail(error.message));
    throw error.message;
  }
};

const getFacilityManagerDetailsStart = () => ({
  type: actionTypes.GET_ASSET_FACILITY_MANAGER_CASE_START
});

const getFacilityManagerDetailsSuccess = (details) => ({
  type: actionTypes.GET_ASSET_FACILITY_MANAGER_CASE_SUCCESS,
  details
});

const getFacilityManagerDetailsFail = (error) => ({
  type: actionTypes.GET_ASSET_FACILITY_MANAGER__CASE_FAIL,
  error
});

export const getFacilityManagerDetails = (id) => async (dispatch) => {
  dispatch(getFacilityManagerDetailsStart());
  const request = new GetFacilityManagerTicketRequest();

  request.setId(id);

  try {
    const result = await FacilityService.getFacilityManagerTicket(request, metaData());
    const response = result.toObject();

    dispatch(getFacilityManagerDetailsSuccess(response));
    return response;
  } catch (error) {
    dispatch(getFacilityManagerDetailsFail(error.message));
    throw error.message;
  }
};

// Create
export const createFacilityTicket = (assetId, form) => async () => {
  const request = new CreateFacilityManagerTicketRequest();

  request.setAssetid(assetId);
  request.setUserid(form.userid);
  request.setQueryname(form.queryname);
  request.setDuedate(formatForBackEnd(form.duedate));
  request.setLocation(form.location);
  request.setStatus(form.status);
  request.setDescription(form.description);

  return new Promise((resolve, reject) => {
    FacilityManagementService.createFacilityManagerTicket(request, metaData(), (err, response) => {
      if (err) {
        reject(err.message);
      } else {
        resolve(response.toObject());
      }
    });
  });
};

// Delete
export const deleteFacilityTicket = (ticketId) => () => {
  const request = new DeleteFacilityManagerTicketRequest();
  request.setId(ticketId);

  return new Promise((resolve, reject) => {
    FacilityManagementService.deleteFacilityManagerTicket(request, metaData(), (err, response) => {
      if (err) {
        reject(err.message);
        toast.error(err.message);
      } else {
        resolve(response.toObject());
        toast.success('Ticket Deleted!');
      }
    });
  });
};

// Update
export const updateFacilityTicket = (form) => () => {
  const request = new UpdateFacilityManagerTicketRequest();
  request.setId(form.id);

  if (form.userid) {
    const userid = new wrappers.StringValue();
    userid.setValue(form.userid);
    request.setUserid(userid);
  }

  if (form.queryname) {
    const queryname = new wrappers.StringValue();
    queryname.setValue(form.queryname);
    request.setQueryname(queryname);
  }

  if (form.duedate) {
    const duedate = new wrappers.StringValue();
    duedate.setValue(formatForBackEnd(form.duedate));
    request.setDuedate(duedate);
  }

  if (form.location) {
    const location = new wrappers.StringValue();
    location.setValue(form.location);
    request.setLocation(location);
  }

  if (form.status >= 0) {
    const status = new wrappers.StringValue();
    status.setValue(form.status.toString());
    request.setStatus(status);
  }

  if (form.description) {
    const description = new wrappers.StringValue();
    description.setValue(form.description);
    request.setDescription(description);
  }

  return new Promise((resolve, reject) => {
    FacilityManagementService.updateFacilityManagerTicket(request, metaData(), (err, response) => {
      if (err) {
        reject(err.message);
        toast.error(err.message);
      } else {
        toast.success('Ticket Updated!');
        resolve(response.toObject());
      }
    });
  });
};
