import { getEnviroment } from 'config/config';

import { ContractServiceClient } from '../../api/contract_grpc_web_pb';
import {
  TenantCostManagerServiceClient,
  TenantCostProviderServiceClient,
  TenantCostSupplierDetailsServiceClient,
  TenantCostContractDetailsServiceClient,
  TenantLeaseStrategyServiceClient,
  TenantDetailsServiceClient,
  ContractClauseServiceClient,
  TenantTurnoverClient,
  TenantOptionsServiceClient,
  TenantServicePromiseClient,
  TenantManagerTicketServicePromiseClient,
  TenantManagerTicketServiceClient
} from '../../api/tenant_grpc_web_pb';

const env = localStorage.getItem('env');
const ENV = getEnviroment(env);

export const CostManagerService = new TenantCostManagerServiceClient(ENV);
export const CostProcurementService = new TenantCostProviderServiceClient(ENV);
export const CostSupplierService = new TenantCostSupplierDetailsServiceClient(ENV);
export const CostContractDetailsService = new TenantCostContractDetailsServiceClient(ENV);
export const StrategyService = new TenantLeaseStrategyServiceClient(ENV);
export const TenantDetailService = new TenantDetailsServiceClient(ENV);
export const ContractClauseService = new ContractClauseServiceClient(ENV);
export const ContractService = new ContractServiceClient(ENV);
export const TurnoverService = new TenantTurnoverClient(ENV);
export const OptionsService = new TenantOptionsServiceClient(ENV);
export const TenantService = new TenantServicePromiseClient(ENV);
export const TenantManagerTicketServicePromise = new TenantManagerTicketServicePromiseClient(ENV);
export const TenantManagerTicketService = new TenantManagerTicketServiceClient(ENV);
