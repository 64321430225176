import React, { useState, useCallback, useEffect } from 'react';

import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Dropdown from 'components/Filters/Dropdown';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import * as actions from 'store/actions';

import useStyles from '../styles';

const makeBundleId = (id) => `id-${id}`;

const TenantPicker = ({ tenants }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [value, setValue] = useState(tenants[0]?.id || '');
  const bundleIds = useSelector((state) => state.reports.tenantPerformance.byId);
  const loading = useSelector((state) => state.reports.tenantPerformance.loading);

  useEffect(() => {
    if (!value) return;

    const bundleId = makeBundleId(value);

    if (bundleIds.some((bundle) => bundle === bundleId)) {
      dispatch(actions.setTenantPerformanceBundle(bundleId));
    } else {
      dispatch(actions.getTenantPerformance(value, bundleId));
    }
  }, [value]);

  const handleChangeMenu = useCallback((e) => {
    setValue(e.target.value);
  }, []);

  return (
    <Box
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      mb={2}
      style={loading ? { pointerEvents: 'none' } : {}}
    >
      <Typography className={classes.title}>Tenant Performance</Typography>
      <Dropdown
        items={tenants}
        value={value}
        handleChange={handleChangeMenu}
        label="Tenant"
        keyword="companyname"
        small
      />
    </Box>
  );
};

export default TenantPicker;

TenantPicker.propTypes = {
  tenants: PropTypes.arrayOf(PropTypes.any).isRequired
};
