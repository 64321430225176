import { metaData } from 'config/config';

import { ExportRentRollRequest } from '../../api/data_export_pb';
import { DataExportClient } from '../services/dataExport';

export const exportRentRoll = (assetID) => async () => {
  const request = new ExportRentRollRequest();
  request.setAssetid(assetID);

  return new Promise((resolve, reject) => {
    DataExportClient.exportRentRoll(request, metaData(), (err, response) => {
      if (err) {
        reject(err);
      } else {
        resolve(response.toObject());
      }
    });
  });
};
