import React, { useState } from 'react';

import { Box, TextField } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import DatePicker from 'components/DatePicker/KeyboardPicker';
import { closeModal } from 'components/Modal/modalReducer';
import ModalWrapper from 'components/Modal/ModalWrapper';
import { isValid } from 'helpers/utils';
import useUrlParams from 'hooks/useUrlParams';
import isEqual from 'lodash/isEqual';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import * as actions from 'store/actions';
import useStyles from 'styles/forms';

const makeBundleId = (portfolioIds) => `PortfolioIds${portfolioIds}`;

const getForm = (tenant, details, isEdit) => ({
  companyname: isEdit ? tenant.companyname : '',
  tradingname: isEdit ? tenant.tradingname : '',
  id: isEdit ? tenant.id : null,
  industry: isEdit ? tenant.industry : '',
  unitidsList: isEdit && details.unitidsList !== undefined ? details.unitidsList : [],
  tenantcontractid:
    isEdit && details.tenantcontractid !== undefined ? details.tenantcontractid : '',
  contractstartdate: isEdit ? new Date(tenant.startdate) : null,
  contractenddate: isEdit ? new Date(tenant.enddate) : null
});

const TenantModal = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const loading = useSelector((state) => state.tenants.loading);
  const { data: asset } = useSelector((state) => state.assets.assetDetails);
  const units = useSelector((state) => state.assets.unit.unitSchedule);
  const { isEdit } = useSelector((state) => state.modals.modalProps);
  const { portfolioIds } = useUrlParams();
  const { details } = useSelector((state) => state.leasingManager.details);
  const { data } = useSelector((state) => state.tenants.tenantDetails);
  const [form, setForm] = useState(getForm(data, details, isEdit));

  const updateTenant = () =>
    dispatch(actions.updateTenant(form))
      .then(() => {
        dispatch(actions.getTenantDetails(form.id));
        dispatch(actions.getTenantHeader(form.id));
        toast.success('Tenant Updated');
      })
      .catch((err) => {
        toast.error(err);
      })
      .finally(() => {
        dispatch(actions.getTenantsByAssetId(asset.id));
        dispatch(closeModal());
      });

  const createTenant = () =>
    dispatch(actions.createTenant(form, asset.id))
      .then(() => {
        dispatch(actions.getTenants(portfolioIds, makeBundleId(portfolioIds), [], true));
        toast.success('Tenant Added');
        setForm(getForm());
      })
      .catch((err) => {
        toast.error(err);
      })
      .finally(() => {
        dispatch(actions.getTenantsByAssetId(asset.id));
        dispatch(closeModal());
      });

  const handleSave = () => {
    if (!isValid(form.companyname) || !isValid(form.tradingname) || !isValid(form.industry)) {
      return toast.error('Please fill in the form!');
    }
    return isEdit ? updateTenant() : createTenant();
  };

  const handleChange = (name, value) => {
    setForm((prevState) => ({
      ...prevState,
      [name]: value
    }));
  };

  return (
    <ModalWrapper
      size="xl"
      header={isEdit ? 'Edit Tenant' : 'Create Tenant'}
      handleSave={handleSave}
      disabled={isEqual(getForm(data, details, isEdit), form)}
      loading={loading}
      label="Save"
    >
      <Box display="flex" alignItems="center" justifyContent="center" flexDirection="column">
        <Autocomplete
          id="units"
          options={units}
          getOptionLabel={(option) => option.unitname}
          className={classes.autocomplete}
          autoComplete
          autoHighlight
          clearOnEscape
          multiple
          value={units?.filter((unit) => form.unitidsList.indexOf(unit.unitid) > -1)}
          onChange={(_, value) => {
            handleChange(
              'unitidsList',
              value.map((val) => val.unitid)
            );
          }}
          renderInput={(params) => <TextField {...params} label="Units" variant="outlined" />}
        />
        <TextField
          fullWidth
          required
          label="Industry"
          variant="outlined"
          className={classes.textField}
          InputProps={{
            classes: {
              input: classes.selectInput
            }
          }}
          value={form.industry}
          onChange={(e) => handleChange('industry', e.target.value)}
        />
        <TextField
          fullWidth
          required
          label="Company Name"
          variant="outlined"
          className={classes.textField}
          value={form.companyname}
          InputProps={{
            classes: {
              input: classes.input
            }
          }}
          disabled={loading}
          onChange={(e) => handleChange('companyname', e.target.value)}
          id="company"
        />
        <TextField
          fullWidth
          required
          label="Trading Name"
          variant="outlined"
          className={classes.textField}
          value={form.tradingname}
          InputProps={{
            classes: {
              input: classes.input
            }
          }}
          disabled={loading}
          onChange={(e) => handleChange('tradingname', e.target.value)}
          id="Trading name"
        />
        <Box mt={2}>
          <DatePicker
            selectedDate={form.contractstartdate}
            setSelectedDate={(e) => handleChange('contractstartdate', e)}
            label="Term Start Date"
            className={classes.textField}
            InputProps={{
              classes: {
                input: classes.input
              }
            }}
          />
        </Box>

        <Box mt={3}>
          <DatePicker
            selectedDate={form.contractenddate}
            minDate={form.contractstartdate}
            setSelectedDate={(e) => handleChange('contractenddate', e)}
            label="Term End Date"
            minErrorText="Date cannot be before term start date"
            className={classes.textField}
            InputProps={{
              classes: {
                input: classes.input
              }
            }}
          />
        </Box>
      </Box>
    </ModalWrapper>
  );
};

export default TenantModal;
