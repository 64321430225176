/**
 * @fileoverview gRPC-Web generated client stub for external
 * @enhanceable
 * @public
 */

// GENERATED CODE -- DO NOT EDIT!


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');

const proto = {};
proto.external = require('./schedule_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.external.ScheduleServiceClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options['format'] = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.external.ScheduleServicePromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options['format'] = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.external.GetUnitsScheduleRequest,
 *   !proto.external.GetUnitsScheduleResponse>}
 */
const methodDescriptor_ScheduleService_GetUnitsSchedule = new grpc.web.MethodDescriptor(
  '/external.ScheduleService/GetUnitsSchedule',
  grpc.web.MethodType.UNARY,
  proto.external.GetUnitsScheduleRequest,
  proto.external.GetUnitsScheduleResponse,
  /**
   * @param {!proto.external.GetUnitsScheduleRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.external.GetUnitsScheduleResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.external.GetUnitsScheduleRequest,
 *   !proto.external.GetUnitsScheduleResponse>}
 */
const methodInfo_ScheduleService_GetUnitsSchedule = new grpc.web.AbstractClientBase.MethodInfo(
  proto.external.GetUnitsScheduleResponse,
  /**
   * @param {!proto.external.GetUnitsScheduleRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.external.GetUnitsScheduleResponse.deserializeBinary
);


/**
 * @param {!proto.external.GetUnitsScheduleRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.external.GetUnitsScheduleResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.external.GetUnitsScheduleResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.external.ScheduleServiceClient.prototype.getUnitsSchedule =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/external.ScheduleService/GetUnitsSchedule',
      request,
      metadata || {},
      methodDescriptor_ScheduleService_GetUnitsSchedule,
      callback);
};


/**
 * @param {!proto.external.GetUnitsScheduleRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.external.GetUnitsScheduleResponse>}
 *     Promise that resolves to the response
 */
proto.external.ScheduleServicePromiseClient.prototype.getUnitsSchedule =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/external.ScheduleService/GetUnitsSchedule',
      request,
      metadata || {},
      methodDescriptor_ScheduleService_GetUnitsSchedule);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.external.GetUnitStackingPlanRequest,
 *   !proto.external.GetUnitStackingPlanResponse>}
 */
const methodDescriptor_ScheduleService_GetUnitStackingPlan = new grpc.web.MethodDescriptor(
  '/external.ScheduleService/GetUnitStackingPlan',
  grpc.web.MethodType.UNARY,
  proto.external.GetUnitStackingPlanRequest,
  proto.external.GetUnitStackingPlanResponse,
  /**
   * @param {!proto.external.GetUnitStackingPlanRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.external.GetUnitStackingPlanResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.external.GetUnitStackingPlanRequest,
 *   !proto.external.GetUnitStackingPlanResponse>}
 */
const methodInfo_ScheduleService_GetUnitStackingPlan = new grpc.web.AbstractClientBase.MethodInfo(
  proto.external.GetUnitStackingPlanResponse,
  /**
   * @param {!proto.external.GetUnitStackingPlanRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.external.GetUnitStackingPlanResponse.deserializeBinary
);


/**
 * @param {!proto.external.GetUnitStackingPlanRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.external.GetUnitStackingPlanResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.external.GetUnitStackingPlanResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.external.ScheduleServiceClient.prototype.getUnitStackingPlan =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/external.ScheduleService/GetUnitStackingPlan',
      request,
      metadata || {},
      methodDescriptor_ScheduleService_GetUnitStackingPlan,
      callback);
};


/**
 * @param {!proto.external.GetUnitStackingPlanRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.external.GetUnitStackingPlanResponse>}
 *     Promise that resolves to the response
 */
proto.external.ScheduleServicePromiseClient.prototype.getUnitStackingPlan =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/external.ScheduleService/GetUnitStackingPlan',
      request,
      metadata || {},
      methodDescriptor_ScheduleService_GetUnitStackingPlan);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.external.GetTenantsScheduleRequest,
 *   !proto.external.GetTenantsScheduleResponse>}
 */
const methodDescriptor_ScheduleService_GetTenantSchedule = new grpc.web.MethodDescriptor(
  '/external.ScheduleService/GetTenantSchedule',
  grpc.web.MethodType.UNARY,
  proto.external.GetTenantsScheduleRequest,
  proto.external.GetTenantsScheduleResponse,
  /**
   * @param {!proto.external.GetTenantsScheduleRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.external.GetTenantsScheduleResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.external.GetTenantsScheduleRequest,
 *   !proto.external.GetTenantsScheduleResponse>}
 */
const methodInfo_ScheduleService_GetTenantSchedule = new grpc.web.AbstractClientBase.MethodInfo(
  proto.external.GetTenantsScheduleResponse,
  /**
   * @param {!proto.external.GetTenantsScheduleRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.external.GetTenantsScheduleResponse.deserializeBinary
);


/**
 * @param {!proto.external.GetTenantsScheduleRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.external.GetTenantsScheduleResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.external.GetTenantsScheduleResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.external.ScheduleServiceClient.prototype.getTenantSchedule =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/external.ScheduleService/GetTenantSchedule',
      request,
      metadata || {},
      methodDescriptor_ScheduleService_GetTenantSchedule,
      callback);
};


/**
 * @param {!proto.external.GetTenantsScheduleRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.external.GetTenantsScheduleResponse>}
 *     Promise that resolves to the response
 */
proto.external.ScheduleServicePromiseClient.prototype.getTenantSchedule =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/external.ScheduleService/GetTenantSchedule',
      request,
      metadata || {},
      methodDescriptor_ScheduleService_GetTenantSchedule);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.external.GetTenantStackingPlanRequest,
 *   !proto.external.GetTenantStackingPlanResponse>}
 */
const methodDescriptor_ScheduleService_GetTenantStackingPlan = new grpc.web.MethodDescriptor(
  '/external.ScheduleService/GetTenantStackingPlan',
  grpc.web.MethodType.UNARY,
  proto.external.GetTenantStackingPlanRequest,
  proto.external.GetTenantStackingPlanResponse,
  /**
   * @param {!proto.external.GetTenantStackingPlanRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.external.GetTenantStackingPlanResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.external.GetTenantStackingPlanRequest,
 *   !proto.external.GetTenantStackingPlanResponse>}
 */
const methodInfo_ScheduleService_GetTenantStackingPlan = new grpc.web.AbstractClientBase.MethodInfo(
  proto.external.GetTenantStackingPlanResponse,
  /**
   * @param {!proto.external.GetTenantStackingPlanRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.external.GetTenantStackingPlanResponse.deserializeBinary
);


/**
 * @param {!proto.external.GetTenantStackingPlanRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.external.GetTenantStackingPlanResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.external.GetTenantStackingPlanResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.external.ScheduleServiceClient.prototype.getTenantStackingPlan =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/external.ScheduleService/GetTenantStackingPlan',
      request,
      metadata || {},
      methodDescriptor_ScheduleService_GetTenantStackingPlan,
      callback);
};


/**
 * @param {!proto.external.GetTenantStackingPlanRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.external.GetTenantStackingPlanResponse>}
 *     Promise that resolves to the response
 */
proto.external.ScheduleServicePromiseClient.prototype.getTenantStackingPlan =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/external.ScheduleService/GetTenantStackingPlan',
      request,
      metadata || {},
      methodDescriptor_ScheduleService_GetTenantStackingPlan);
};


module.exports = proto.external;

