/**
 * @fileoverview gRPC-Web generated client stub for external
 * @enhanceable
 * @public
 */

// GENERATED CODE -- DO NOT EDIT!


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');


var google_protobuf_empty_pb = require('google-protobuf/google/protobuf/empty_pb.js')

var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js')

var google_protobuf_wrappers_pb = require('google-protobuf/google/protobuf/wrappers_pb.js')
const proto = {};
proto.external = require('./tenant_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.external.TenantServiceClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options['format'] = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.external.TenantServicePromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options['format'] = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.external.CreateTenantRequest,
 *   !proto.external.CreateTenantResponse>}
 */
const methodDescriptor_TenantService_CreateTenant = new grpc.web.MethodDescriptor(
  '/external.TenantService/CreateTenant',
  grpc.web.MethodType.UNARY,
  proto.external.CreateTenantRequest,
  proto.external.CreateTenantResponse,
  /**
   * @param {!proto.external.CreateTenantRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.external.CreateTenantResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.external.CreateTenantRequest,
 *   !proto.external.CreateTenantResponse>}
 */
const methodInfo_TenantService_CreateTenant = new grpc.web.AbstractClientBase.MethodInfo(
  proto.external.CreateTenantResponse,
  /**
   * @param {!proto.external.CreateTenantRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.external.CreateTenantResponse.deserializeBinary
);


/**
 * @param {!proto.external.CreateTenantRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.external.CreateTenantResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.external.CreateTenantResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.external.TenantServiceClient.prototype.createTenant =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/external.TenantService/CreateTenant',
      request,
      metadata || {},
      methodDescriptor_TenantService_CreateTenant,
      callback);
};


/**
 * @param {!proto.external.CreateTenantRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.external.CreateTenantResponse>}
 *     Promise that resolves to the response
 */
proto.external.TenantServicePromiseClient.prototype.createTenant =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/external.TenantService/CreateTenant',
      request,
      metadata || {},
      methodDescriptor_TenantService_CreateTenant);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.external.GetTenantRequest,
 *   !proto.external.GetTenantResponse>}
 */
const methodDescriptor_TenantService_GetTenant = new grpc.web.MethodDescriptor(
  '/external.TenantService/GetTenant',
  grpc.web.MethodType.UNARY,
  proto.external.GetTenantRequest,
  proto.external.GetTenantResponse,
  /**
   * @param {!proto.external.GetTenantRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.external.GetTenantResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.external.GetTenantRequest,
 *   !proto.external.GetTenantResponse>}
 */
const methodInfo_TenantService_GetTenant = new grpc.web.AbstractClientBase.MethodInfo(
  proto.external.GetTenantResponse,
  /**
   * @param {!proto.external.GetTenantRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.external.GetTenantResponse.deserializeBinary
);


/**
 * @param {!proto.external.GetTenantRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.external.GetTenantResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.external.GetTenantResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.external.TenantServiceClient.prototype.getTenant =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/external.TenantService/GetTenant',
      request,
      metadata || {},
      methodDescriptor_TenantService_GetTenant,
      callback);
};


/**
 * @param {!proto.external.GetTenantRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.external.GetTenantResponse>}
 *     Promise that resolves to the response
 */
proto.external.TenantServicePromiseClient.prototype.getTenant =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/external.TenantService/GetTenant',
      request,
      metadata || {},
      methodDescriptor_TenantService_GetTenant);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.external.GetTenantsRequest,
 *   !proto.external.GetTenantsResponse>}
 */
const methodDescriptor_TenantService_GetTenants = new grpc.web.MethodDescriptor(
  '/external.TenantService/GetTenants',
  grpc.web.MethodType.UNARY,
  proto.external.GetTenantsRequest,
  proto.external.GetTenantsResponse,
  /**
   * @param {!proto.external.GetTenantsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.external.GetTenantsResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.external.GetTenantsRequest,
 *   !proto.external.GetTenantsResponse>}
 */
const methodInfo_TenantService_GetTenants = new grpc.web.AbstractClientBase.MethodInfo(
  proto.external.GetTenantsResponse,
  /**
   * @param {!proto.external.GetTenantsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.external.GetTenantsResponse.deserializeBinary
);


/**
 * @param {!proto.external.GetTenantsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.external.GetTenantsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.external.GetTenantsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.external.TenantServiceClient.prototype.getTenants =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/external.TenantService/GetTenants',
      request,
      metadata || {},
      methodDescriptor_TenantService_GetTenants,
      callback);
};


/**
 * @param {!proto.external.GetTenantsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.external.GetTenantsResponse>}
 *     Promise that resolves to the response
 */
proto.external.TenantServicePromiseClient.prototype.getTenants =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/external.TenantService/GetTenants',
      request,
      metadata || {},
      methodDescriptor_TenantService_GetTenants);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.external.GetTenantRequest,
 *   !proto.external.GetTenantContractDatesResponse>}
 */
const methodDescriptor_TenantService_GetTenantContractDates = new grpc.web.MethodDescriptor(
  '/external.TenantService/GetTenantContractDates',
  grpc.web.MethodType.UNARY,
  proto.external.GetTenantRequest,
  proto.external.GetTenantContractDatesResponse,
  /**
   * @param {!proto.external.GetTenantRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.external.GetTenantContractDatesResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.external.GetTenantRequest,
 *   !proto.external.GetTenantContractDatesResponse>}
 */
const methodInfo_TenantService_GetTenantContractDates = new grpc.web.AbstractClientBase.MethodInfo(
  proto.external.GetTenantContractDatesResponse,
  /**
   * @param {!proto.external.GetTenantRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.external.GetTenantContractDatesResponse.deserializeBinary
);


/**
 * @param {!proto.external.GetTenantRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.external.GetTenantContractDatesResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.external.GetTenantContractDatesResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.external.TenantServiceClient.prototype.getTenantContractDates =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/external.TenantService/GetTenantContractDates',
      request,
      metadata || {},
      methodDescriptor_TenantService_GetTenantContractDates,
      callback);
};


/**
 * @param {!proto.external.GetTenantRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.external.GetTenantContractDatesResponse>}
 *     Promise that resolves to the response
 */
proto.external.TenantServicePromiseClient.prototype.getTenantContractDates =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/external.TenantService/GetTenantContractDates',
      request,
      metadata || {},
      methodDescriptor_TenantService_GetTenantContractDates);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.external.UpdateTenantRequest,
 *   !proto.google.protobuf.Empty>}
 */
const methodDescriptor_TenantService_UpdateTenant = new grpc.web.MethodDescriptor(
  '/external.TenantService/UpdateTenant',
  grpc.web.MethodType.UNARY,
  proto.external.UpdateTenantRequest,
  google_protobuf_empty_pb.Empty,
  /**
   * @param {!proto.external.UpdateTenantRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  google_protobuf_empty_pb.Empty.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.external.UpdateTenantRequest,
 *   !proto.google.protobuf.Empty>}
 */
const methodInfo_TenantService_UpdateTenant = new grpc.web.AbstractClientBase.MethodInfo(
  google_protobuf_empty_pb.Empty,
  /**
   * @param {!proto.external.UpdateTenantRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  google_protobuf_empty_pb.Empty.deserializeBinary
);


/**
 * @param {!proto.external.UpdateTenantRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.google.protobuf.Empty)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.google.protobuf.Empty>|undefined}
 *     The XHR Node Readable Stream
 */
proto.external.TenantServiceClient.prototype.updateTenant =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/external.TenantService/UpdateTenant',
      request,
      metadata || {},
      methodDescriptor_TenantService_UpdateTenant,
      callback);
};


/**
 * @param {!proto.external.UpdateTenantRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.google.protobuf.Empty>}
 *     Promise that resolves to the response
 */
proto.external.TenantServicePromiseClient.prototype.updateTenant =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/external.TenantService/UpdateTenant',
      request,
      metadata || {},
      methodDescriptor_TenantService_UpdateTenant);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.external.DeleteTenantRequest,
 *   !proto.google.protobuf.Empty>}
 */
const methodDescriptor_TenantService_DeleteCTenant = new grpc.web.MethodDescriptor(
  '/external.TenantService/DeleteCTenant',
  grpc.web.MethodType.UNARY,
  proto.external.DeleteTenantRequest,
  google_protobuf_empty_pb.Empty,
  /**
   * @param {!proto.external.DeleteTenantRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  google_protobuf_empty_pb.Empty.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.external.DeleteTenantRequest,
 *   !proto.google.protobuf.Empty>}
 */
const methodInfo_TenantService_DeleteCTenant = new grpc.web.AbstractClientBase.MethodInfo(
  google_protobuf_empty_pb.Empty,
  /**
   * @param {!proto.external.DeleteTenantRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  google_protobuf_empty_pb.Empty.deserializeBinary
);


/**
 * @param {!proto.external.DeleteTenantRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.google.protobuf.Empty)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.google.protobuf.Empty>|undefined}
 *     The XHR Node Readable Stream
 */
proto.external.TenantServiceClient.prototype.deleteCTenant =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/external.TenantService/DeleteCTenant',
      request,
      metadata || {},
      methodDescriptor_TenantService_DeleteCTenant,
      callback);
};


/**
 * @param {!proto.external.DeleteTenantRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.google.protobuf.Empty>}
 *     Promise that resolves to the response
 */
proto.external.TenantServicePromiseClient.prototype.deleteCTenant =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/external.TenantService/DeleteCTenant',
      request,
      metadata || {},
      methodDescriptor_TenantService_DeleteCTenant);
};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.external.ContractClauseServiceClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options['format'] = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.external.ContractClauseServicePromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options['format'] = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.external.CreateClauseRequest,
 *   !proto.external.CreateClauseResponse>}
 */
const methodDescriptor_ContractClauseService_CreateClause = new grpc.web.MethodDescriptor(
  '/external.ContractClauseService/CreateClause',
  grpc.web.MethodType.UNARY,
  proto.external.CreateClauseRequest,
  proto.external.CreateClauseResponse,
  /**
   * @param {!proto.external.CreateClauseRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.external.CreateClauseResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.external.CreateClauseRequest,
 *   !proto.external.CreateClauseResponse>}
 */
const methodInfo_ContractClauseService_CreateClause = new grpc.web.AbstractClientBase.MethodInfo(
  proto.external.CreateClauseResponse,
  /**
   * @param {!proto.external.CreateClauseRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.external.CreateClauseResponse.deserializeBinary
);


/**
 * @param {!proto.external.CreateClauseRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.external.CreateClauseResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.external.CreateClauseResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.external.ContractClauseServiceClient.prototype.createClause =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/external.ContractClauseService/CreateClause',
      request,
      metadata || {},
      methodDescriptor_ContractClauseService_CreateClause,
      callback);
};


/**
 * @param {!proto.external.CreateClauseRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.external.CreateClauseResponse>}
 *     Promise that resolves to the response
 */
proto.external.ContractClauseServicePromiseClient.prototype.createClause =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/external.ContractClauseService/CreateClause',
      request,
      metadata || {},
      methodDescriptor_ContractClauseService_CreateClause);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.external.CreateClausesRequest,
 *   !proto.google.protobuf.Empty>}
 */
const methodDescriptor_ContractClauseService_CreateClauses = new grpc.web.MethodDescriptor(
  '/external.ContractClauseService/CreateClauses',
  grpc.web.MethodType.UNARY,
  proto.external.CreateClausesRequest,
  google_protobuf_empty_pb.Empty,
  /**
   * @param {!proto.external.CreateClausesRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  google_protobuf_empty_pb.Empty.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.external.CreateClausesRequest,
 *   !proto.google.protobuf.Empty>}
 */
const methodInfo_ContractClauseService_CreateClauses = new grpc.web.AbstractClientBase.MethodInfo(
  google_protobuf_empty_pb.Empty,
  /**
   * @param {!proto.external.CreateClausesRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  google_protobuf_empty_pb.Empty.deserializeBinary
);


/**
 * @param {!proto.external.CreateClausesRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.google.protobuf.Empty)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.google.protobuf.Empty>|undefined}
 *     The XHR Node Readable Stream
 */
proto.external.ContractClauseServiceClient.prototype.createClauses =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/external.ContractClauseService/CreateClauses',
      request,
      metadata || {},
      methodDescriptor_ContractClauseService_CreateClauses,
      callback);
};


/**
 * @param {!proto.external.CreateClausesRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.google.protobuf.Empty>}
 *     Promise that resolves to the response
 */
proto.external.ContractClauseServicePromiseClient.prototype.createClauses =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/external.ContractClauseService/CreateClauses',
      request,
      metadata || {},
      methodDescriptor_ContractClauseService_CreateClauses);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.external.GetClauseRequest,
 *   !proto.external.GetClauseResponse>}
 */
const methodDescriptor_ContractClauseService_GetClause = new grpc.web.MethodDescriptor(
  '/external.ContractClauseService/GetClause',
  grpc.web.MethodType.UNARY,
  proto.external.GetClauseRequest,
  proto.external.GetClauseResponse,
  /**
   * @param {!proto.external.GetClauseRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.external.GetClauseResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.external.GetClauseRequest,
 *   !proto.external.GetClauseResponse>}
 */
const methodInfo_ContractClauseService_GetClause = new grpc.web.AbstractClientBase.MethodInfo(
  proto.external.GetClauseResponse,
  /**
   * @param {!proto.external.GetClauseRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.external.GetClauseResponse.deserializeBinary
);


/**
 * @param {!proto.external.GetClauseRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.external.GetClauseResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.external.GetClauseResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.external.ContractClauseServiceClient.prototype.getClause =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/external.ContractClauseService/GetClause',
      request,
      metadata || {},
      methodDescriptor_ContractClauseService_GetClause,
      callback);
};


/**
 * @param {!proto.external.GetClauseRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.external.GetClauseResponse>}
 *     Promise that resolves to the response
 */
proto.external.ContractClauseServicePromiseClient.prototype.getClause =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/external.ContractClauseService/GetClause',
      request,
      metadata || {},
      methodDescriptor_ContractClauseService_GetClause);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.external.GetClausesByContractRequest,
 *   !proto.external.GetClausesByContractResponse>}
 */
const methodDescriptor_ContractClauseService_GetClausesByContract = new grpc.web.MethodDescriptor(
  '/external.ContractClauseService/GetClausesByContract',
  grpc.web.MethodType.UNARY,
  proto.external.GetClausesByContractRequest,
  proto.external.GetClausesByContractResponse,
  /**
   * @param {!proto.external.GetClausesByContractRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.external.GetClausesByContractResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.external.GetClausesByContractRequest,
 *   !proto.external.GetClausesByContractResponse>}
 */
const methodInfo_ContractClauseService_GetClausesByContract = new grpc.web.AbstractClientBase.MethodInfo(
  proto.external.GetClausesByContractResponse,
  /**
   * @param {!proto.external.GetClausesByContractRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.external.GetClausesByContractResponse.deserializeBinary
);


/**
 * @param {!proto.external.GetClausesByContractRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.external.GetClausesByContractResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.external.GetClausesByContractResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.external.ContractClauseServiceClient.prototype.getClausesByContract =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/external.ContractClauseService/GetClausesByContract',
      request,
      metadata || {},
      methodDescriptor_ContractClauseService_GetClausesByContract,
      callback);
};


/**
 * @param {!proto.external.GetClausesByContractRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.external.GetClausesByContractResponse>}
 *     Promise that resolves to the response
 */
proto.external.ContractClauseServicePromiseClient.prototype.getClausesByContract =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/external.ContractClauseService/GetClausesByContract',
      request,
      metadata || {},
      methodDescriptor_ContractClauseService_GetClausesByContract);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.external.UpdateClauseRequest,
 *   !proto.google.protobuf.Empty>}
 */
const methodDescriptor_ContractClauseService_UpdateClause = new grpc.web.MethodDescriptor(
  '/external.ContractClauseService/UpdateClause',
  grpc.web.MethodType.UNARY,
  proto.external.UpdateClauseRequest,
  google_protobuf_empty_pb.Empty,
  /**
   * @param {!proto.external.UpdateClauseRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  google_protobuf_empty_pb.Empty.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.external.UpdateClauseRequest,
 *   !proto.google.protobuf.Empty>}
 */
const methodInfo_ContractClauseService_UpdateClause = new grpc.web.AbstractClientBase.MethodInfo(
  google_protobuf_empty_pb.Empty,
  /**
   * @param {!proto.external.UpdateClauseRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  google_protobuf_empty_pb.Empty.deserializeBinary
);


/**
 * @param {!proto.external.UpdateClauseRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.google.protobuf.Empty)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.google.protobuf.Empty>|undefined}
 *     The XHR Node Readable Stream
 */
proto.external.ContractClauseServiceClient.prototype.updateClause =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/external.ContractClauseService/UpdateClause',
      request,
      metadata || {},
      methodDescriptor_ContractClauseService_UpdateClause,
      callback);
};


/**
 * @param {!proto.external.UpdateClauseRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.google.protobuf.Empty>}
 *     Promise that resolves to the response
 */
proto.external.ContractClauseServicePromiseClient.prototype.updateClause =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/external.ContractClauseService/UpdateClause',
      request,
      metadata || {},
      methodDescriptor_ContractClauseService_UpdateClause);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.external.DeleteClauseRequest,
 *   !proto.google.protobuf.Empty>}
 */
const methodDescriptor_ContractClauseService_DeleteClause = new grpc.web.MethodDescriptor(
  '/external.ContractClauseService/DeleteClause',
  grpc.web.MethodType.UNARY,
  proto.external.DeleteClauseRequest,
  google_protobuf_empty_pb.Empty,
  /**
   * @param {!proto.external.DeleteClauseRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  google_protobuf_empty_pb.Empty.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.external.DeleteClauseRequest,
 *   !proto.google.protobuf.Empty>}
 */
const methodInfo_ContractClauseService_DeleteClause = new grpc.web.AbstractClientBase.MethodInfo(
  google_protobuf_empty_pb.Empty,
  /**
   * @param {!proto.external.DeleteClauseRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  google_protobuf_empty_pb.Empty.deserializeBinary
);


/**
 * @param {!proto.external.DeleteClauseRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.google.protobuf.Empty)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.google.protobuf.Empty>|undefined}
 *     The XHR Node Readable Stream
 */
proto.external.ContractClauseServiceClient.prototype.deleteClause =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/external.ContractClauseService/DeleteClause',
      request,
      metadata || {},
      methodDescriptor_ContractClauseService_DeleteClause,
      callback);
};


/**
 * @param {!proto.external.DeleteClauseRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.google.protobuf.Empty>}
 *     Promise that resolves to the response
 */
proto.external.ContractClauseServicePromiseClient.prototype.deleteClause =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/external.ContractClauseService/DeleteClause',
      request,
      metadata || {},
      methodDescriptor_ContractClauseService_DeleteClause);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.external.GetCashFlowCurrentRequest,
 *   !proto.external.GetCashFlowCurrentResponse>}
 */
const methodDescriptor_ContractClauseService_GetCashFlowCurrent = new grpc.web.MethodDescriptor(
  '/external.ContractClauseService/GetCashFlowCurrent',
  grpc.web.MethodType.UNARY,
  proto.external.GetCashFlowCurrentRequest,
  proto.external.GetCashFlowCurrentResponse,
  /**
   * @param {!proto.external.GetCashFlowCurrentRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.external.GetCashFlowCurrentResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.external.GetCashFlowCurrentRequest,
 *   !proto.external.GetCashFlowCurrentResponse>}
 */
const methodInfo_ContractClauseService_GetCashFlowCurrent = new grpc.web.AbstractClientBase.MethodInfo(
  proto.external.GetCashFlowCurrentResponse,
  /**
   * @param {!proto.external.GetCashFlowCurrentRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.external.GetCashFlowCurrentResponse.deserializeBinary
);


/**
 * @param {!proto.external.GetCashFlowCurrentRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.external.GetCashFlowCurrentResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.external.GetCashFlowCurrentResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.external.ContractClauseServiceClient.prototype.getCashFlowCurrent =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/external.ContractClauseService/GetCashFlowCurrent',
      request,
      metadata || {},
      methodDescriptor_ContractClauseService_GetCashFlowCurrent,
      callback);
};


/**
 * @param {!proto.external.GetCashFlowCurrentRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.external.GetCashFlowCurrentResponse>}
 *     Promise that resolves to the response
 */
proto.external.ContractClauseServicePromiseClient.prototype.getCashFlowCurrent =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/external.ContractClauseService/GetCashFlowCurrent',
      request,
      metadata || {},
      methodDescriptor_ContractClauseService_GetCashFlowCurrent);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.external.GetTurnoverRentCurrentRequest,
 *   !proto.external.GetTurnoverRentCurrentResponse>}
 */
const methodDescriptor_ContractClauseService_GetTurnoverRentCurrent = new grpc.web.MethodDescriptor(
  '/external.ContractClauseService/GetTurnoverRentCurrent',
  grpc.web.MethodType.UNARY,
  proto.external.GetTurnoverRentCurrentRequest,
  proto.external.GetTurnoverRentCurrentResponse,
  /**
   * @param {!proto.external.GetTurnoverRentCurrentRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.external.GetTurnoverRentCurrentResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.external.GetTurnoverRentCurrentRequest,
 *   !proto.external.GetTurnoverRentCurrentResponse>}
 */
const methodInfo_ContractClauseService_GetTurnoverRentCurrent = new grpc.web.AbstractClientBase.MethodInfo(
  proto.external.GetTurnoverRentCurrentResponse,
  /**
   * @param {!proto.external.GetTurnoverRentCurrentRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.external.GetTurnoverRentCurrentResponse.deserializeBinary
);


/**
 * @param {!proto.external.GetTurnoverRentCurrentRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.external.GetTurnoverRentCurrentResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.external.GetTurnoverRentCurrentResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.external.ContractClauseServiceClient.prototype.getTurnoverRentCurrent =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/external.ContractClauseService/GetTurnoverRentCurrent',
      request,
      metadata || {},
      methodDescriptor_ContractClauseService_GetTurnoverRentCurrent,
      callback);
};


/**
 * @param {!proto.external.GetTurnoverRentCurrentRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.external.GetTurnoverRentCurrentResponse>}
 *     Promise that resolves to the response
 */
proto.external.ContractClauseServicePromiseClient.prototype.getTurnoverRentCurrent =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/external.ContractClauseService/GetTurnoverRentCurrent',
      request,
      metadata || {},
      methodDescriptor_ContractClauseService_GetTurnoverRentCurrent);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.external.GetTotalRentByAssetAndYearRequest,
 *   !proto.external.RentCalculationResponse>}
 */
const methodDescriptor_ContractClauseService_GetTotalRentByAssetAndYear = new grpc.web.MethodDescriptor(
  '/external.ContractClauseService/GetTotalRentByAssetAndYear',
  grpc.web.MethodType.UNARY,
  proto.external.GetTotalRentByAssetAndYearRequest,
  proto.external.RentCalculationResponse,
  /**
   * @param {!proto.external.GetTotalRentByAssetAndYearRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.external.RentCalculationResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.external.GetTotalRentByAssetAndYearRequest,
 *   !proto.external.RentCalculationResponse>}
 */
const methodInfo_ContractClauseService_GetTotalRentByAssetAndYear = new grpc.web.AbstractClientBase.MethodInfo(
  proto.external.RentCalculationResponse,
  /**
   * @param {!proto.external.GetTotalRentByAssetAndYearRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.external.RentCalculationResponse.deserializeBinary
);


/**
 * @param {!proto.external.GetTotalRentByAssetAndYearRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.external.RentCalculationResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.external.RentCalculationResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.external.ContractClauseServiceClient.prototype.getTotalRentByAssetAndYear =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/external.ContractClauseService/GetTotalRentByAssetAndYear',
      request,
      metadata || {},
      methodDescriptor_ContractClauseService_GetTotalRentByAssetAndYear,
      callback);
};


/**
 * @param {!proto.external.GetTotalRentByAssetAndYearRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.external.RentCalculationResponse>}
 *     Promise that resolves to the response
 */
proto.external.ContractClauseServicePromiseClient.prototype.getTotalRentByAssetAndYear =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/external.ContractClauseService/GetTotalRentByAssetAndYear',
      request,
      metadata || {},
      methodDescriptor_ContractClauseService_GetTotalRentByAssetAndYear);
};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.external.TenantLeaseStrategyServiceClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options['format'] = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.external.TenantLeaseStrategyServicePromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options['format'] = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.external.GetStrategyByIDRequest,
 *   !proto.external.GetStrategyResponse>}
 */
const methodDescriptor_TenantLeaseStrategyService_GetStrategyByID = new grpc.web.MethodDescriptor(
  '/external.TenantLeaseStrategyService/GetStrategyByID',
  grpc.web.MethodType.UNARY,
  proto.external.GetStrategyByIDRequest,
  proto.external.GetStrategyResponse,
  /**
   * @param {!proto.external.GetStrategyByIDRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.external.GetStrategyResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.external.GetStrategyByIDRequest,
 *   !proto.external.GetStrategyResponse>}
 */
const methodInfo_TenantLeaseStrategyService_GetStrategyByID = new grpc.web.AbstractClientBase.MethodInfo(
  proto.external.GetStrategyResponse,
  /**
   * @param {!proto.external.GetStrategyByIDRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.external.GetStrategyResponse.deserializeBinary
);


/**
 * @param {!proto.external.GetStrategyByIDRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.external.GetStrategyResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.external.GetStrategyResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.external.TenantLeaseStrategyServiceClient.prototype.getStrategyByID =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/external.TenantLeaseStrategyService/GetStrategyByID',
      request,
      metadata || {},
      methodDescriptor_TenantLeaseStrategyService_GetStrategyByID,
      callback);
};


/**
 * @param {!proto.external.GetStrategyByIDRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.external.GetStrategyResponse>}
 *     Promise that resolves to the response
 */
proto.external.TenantLeaseStrategyServicePromiseClient.prototype.getStrategyByID =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/external.TenantLeaseStrategyService/GetStrategyByID',
      request,
      metadata || {},
      methodDescriptor_TenantLeaseStrategyService_GetStrategyByID);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.external.GetStrategyByContractIDRequest,
 *   !proto.external.GetStrategyResponse>}
 */
const methodDescriptor_TenantLeaseStrategyService_GetStrategyByContractID = new grpc.web.MethodDescriptor(
  '/external.TenantLeaseStrategyService/GetStrategyByContractID',
  grpc.web.MethodType.UNARY,
  proto.external.GetStrategyByContractIDRequest,
  proto.external.GetStrategyResponse,
  /**
   * @param {!proto.external.GetStrategyByContractIDRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.external.GetStrategyResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.external.GetStrategyByContractIDRequest,
 *   !proto.external.GetStrategyResponse>}
 */
const methodInfo_TenantLeaseStrategyService_GetStrategyByContractID = new grpc.web.AbstractClientBase.MethodInfo(
  proto.external.GetStrategyResponse,
  /**
   * @param {!proto.external.GetStrategyByContractIDRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.external.GetStrategyResponse.deserializeBinary
);


/**
 * @param {!proto.external.GetStrategyByContractIDRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.external.GetStrategyResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.external.GetStrategyResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.external.TenantLeaseStrategyServiceClient.prototype.getStrategyByContractID =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/external.TenantLeaseStrategyService/GetStrategyByContractID',
      request,
      metadata || {},
      methodDescriptor_TenantLeaseStrategyService_GetStrategyByContractID,
      callback);
};


/**
 * @param {!proto.external.GetStrategyByContractIDRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.external.GetStrategyResponse>}
 *     Promise that resolves to the response
 */
proto.external.TenantLeaseStrategyServicePromiseClient.prototype.getStrategyByContractID =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/external.TenantLeaseStrategyService/GetStrategyByContractID',
      request,
      metadata || {},
      methodDescriptor_TenantLeaseStrategyService_GetStrategyByContractID);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.external.UpdateStrategyRequest,
 *   !proto.google.protobuf.Empty>}
 */
const methodDescriptor_TenantLeaseStrategyService_UpdateStrategy = new grpc.web.MethodDescriptor(
  '/external.TenantLeaseStrategyService/UpdateStrategy',
  grpc.web.MethodType.UNARY,
  proto.external.UpdateStrategyRequest,
  google_protobuf_empty_pb.Empty,
  /**
   * @param {!proto.external.UpdateStrategyRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  google_protobuf_empty_pb.Empty.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.external.UpdateStrategyRequest,
 *   !proto.google.protobuf.Empty>}
 */
const methodInfo_TenantLeaseStrategyService_UpdateStrategy = new grpc.web.AbstractClientBase.MethodInfo(
  google_protobuf_empty_pb.Empty,
  /**
   * @param {!proto.external.UpdateStrategyRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  google_protobuf_empty_pb.Empty.deserializeBinary
);


/**
 * @param {!proto.external.UpdateStrategyRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.google.protobuf.Empty)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.google.protobuf.Empty>|undefined}
 *     The XHR Node Readable Stream
 */
proto.external.TenantLeaseStrategyServiceClient.prototype.updateStrategy =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/external.TenantLeaseStrategyService/UpdateStrategy',
      request,
      metadata || {},
      methodDescriptor_TenantLeaseStrategyService_UpdateStrategy,
      callback);
};


/**
 * @param {!proto.external.UpdateStrategyRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.google.protobuf.Empty>}
 *     Promise that resolves to the response
 */
proto.external.TenantLeaseStrategyServicePromiseClient.prototype.updateStrategy =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/external.TenantLeaseStrategyService/UpdateStrategy',
      request,
      metadata || {},
      methodDescriptor_TenantLeaseStrategyService_UpdateStrategy);
};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.external.TenantTurnoverClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options['format'] = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.external.TenantTurnoverPromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options['format'] = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.external.CreateTurnoverRequest,
 *   !proto.external.CreateTurnoverResponse>}
 */
const methodDescriptor_TenantTurnover_CreateTurnover = new grpc.web.MethodDescriptor(
  '/external.TenantTurnover/CreateTurnover',
  grpc.web.MethodType.UNARY,
  proto.external.CreateTurnoverRequest,
  proto.external.CreateTurnoverResponse,
  /**
   * @param {!proto.external.CreateTurnoverRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.external.CreateTurnoverResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.external.CreateTurnoverRequest,
 *   !proto.external.CreateTurnoverResponse>}
 */
const methodInfo_TenantTurnover_CreateTurnover = new grpc.web.AbstractClientBase.MethodInfo(
  proto.external.CreateTurnoverResponse,
  /**
   * @param {!proto.external.CreateTurnoverRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.external.CreateTurnoverResponse.deserializeBinary
);


/**
 * @param {!proto.external.CreateTurnoverRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.external.CreateTurnoverResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.external.CreateTurnoverResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.external.TenantTurnoverClient.prototype.createTurnover =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/external.TenantTurnover/CreateTurnover',
      request,
      metadata || {},
      methodDescriptor_TenantTurnover_CreateTurnover,
      callback);
};


/**
 * @param {!proto.external.CreateTurnoverRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.external.CreateTurnoverResponse>}
 *     Promise that resolves to the response
 */
proto.external.TenantTurnoverPromiseClient.prototype.createTurnover =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/external.TenantTurnover/CreateTurnover',
      request,
      metadata || {},
      methodDescriptor_TenantTurnover_CreateTurnover);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.external.GetTurnoversRequest,
 *   !proto.external.GetTurnoversResponse>}
 */
const methodDescriptor_TenantTurnover_GetTurnovers = new grpc.web.MethodDescriptor(
  '/external.TenantTurnover/GetTurnovers',
  grpc.web.MethodType.UNARY,
  proto.external.GetTurnoversRequest,
  proto.external.GetTurnoversResponse,
  /**
   * @param {!proto.external.GetTurnoversRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.external.GetTurnoversResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.external.GetTurnoversRequest,
 *   !proto.external.GetTurnoversResponse>}
 */
const methodInfo_TenantTurnover_GetTurnovers = new grpc.web.AbstractClientBase.MethodInfo(
  proto.external.GetTurnoversResponse,
  /**
   * @param {!proto.external.GetTurnoversRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.external.GetTurnoversResponse.deserializeBinary
);


/**
 * @param {!proto.external.GetTurnoversRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.external.GetTurnoversResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.external.GetTurnoversResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.external.TenantTurnoverClient.prototype.getTurnovers =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/external.TenantTurnover/GetTurnovers',
      request,
      metadata || {},
      methodDescriptor_TenantTurnover_GetTurnovers,
      callback);
};


/**
 * @param {!proto.external.GetTurnoversRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.external.GetTurnoversResponse>}
 *     Promise that resolves to the response
 */
proto.external.TenantTurnoverPromiseClient.prototype.getTurnovers =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/external.TenantTurnover/GetTurnovers',
      request,
      metadata || {},
      methodDescriptor_TenantTurnover_GetTurnovers);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.external.UpdateTurnoverRequest,
 *   !proto.google.protobuf.Empty>}
 */
const methodDescriptor_TenantTurnover_UpdateTurnover = new grpc.web.MethodDescriptor(
  '/external.TenantTurnover/UpdateTurnover',
  grpc.web.MethodType.UNARY,
  proto.external.UpdateTurnoverRequest,
  google_protobuf_empty_pb.Empty,
  /**
   * @param {!proto.external.UpdateTurnoverRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  google_protobuf_empty_pb.Empty.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.external.UpdateTurnoverRequest,
 *   !proto.google.protobuf.Empty>}
 */
const methodInfo_TenantTurnover_UpdateTurnover = new grpc.web.AbstractClientBase.MethodInfo(
  google_protobuf_empty_pb.Empty,
  /**
   * @param {!proto.external.UpdateTurnoverRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  google_protobuf_empty_pb.Empty.deserializeBinary
);


/**
 * @param {!proto.external.UpdateTurnoverRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.google.protobuf.Empty)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.google.protobuf.Empty>|undefined}
 *     The XHR Node Readable Stream
 */
proto.external.TenantTurnoverClient.prototype.updateTurnover =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/external.TenantTurnover/UpdateTurnover',
      request,
      metadata || {},
      methodDescriptor_TenantTurnover_UpdateTurnover,
      callback);
};


/**
 * @param {!proto.external.UpdateTurnoverRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.google.protobuf.Empty>}
 *     Promise that resolves to the response
 */
proto.external.TenantTurnoverPromiseClient.prototype.updateTurnover =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/external.TenantTurnover/UpdateTurnover',
      request,
      metadata || {},
      methodDescriptor_TenantTurnover_UpdateTurnover);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.external.DeleteTurnoverRequest,
 *   !proto.google.protobuf.Empty>}
 */
const methodDescriptor_TenantTurnover_DeleteTurnover = new grpc.web.MethodDescriptor(
  '/external.TenantTurnover/DeleteTurnover',
  grpc.web.MethodType.UNARY,
  proto.external.DeleteTurnoverRequest,
  google_protobuf_empty_pb.Empty,
  /**
   * @param {!proto.external.DeleteTurnoverRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  google_protobuf_empty_pb.Empty.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.external.DeleteTurnoverRequest,
 *   !proto.google.protobuf.Empty>}
 */
const methodInfo_TenantTurnover_DeleteTurnover = new grpc.web.AbstractClientBase.MethodInfo(
  google_protobuf_empty_pb.Empty,
  /**
   * @param {!proto.external.DeleteTurnoverRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  google_protobuf_empty_pb.Empty.deserializeBinary
);


/**
 * @param {!proto.external.DeleteTurnoverRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.google.protobuf.Empty)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.google.protobuf.Empty>|undefined}
 *     The XHR Node Readable Stream
 */
proto.external.TenantTurnoverClient.prototype.deleteTurnover =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/external.TenantTurnover/DeleteTurnover',
      request,
      metadata || {},
      methodDescriptor_TenantTurnover_DeleteTurnover,
      callback);
};


/**
 * @param {!proto.external.DeleteTurnoverRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.google.protobuf.Empty>}
 *     Promise that resolves to the response
 */
proto.external.TenantTurnoverPromiseClient.prototype.deleteTurnover =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/external.TenantTurnover/DeleteTurnover',
      request,
      metadata || {},
      methodDescriptor_TenantTurnover_DeleteTurnover);
};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.external.TenantOptionsServiceClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options['format'] = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.external.TenantOptionsServicePromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options['format'] = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.external.CreateTenantOptionRequest,
 *   !proto.external.CreateTenantOptionResponse>}
 */
const methodDescriptor_TenantOptionsService_CreateTenantOption = new grpc.web.MethodDescriptor(
  '/external.TenantOptionsService/CreateTenantOption',
  grpc.web.MethodType.UNARY,
  proto.external.CreateTenantOptionRequest,
  proto.external.CreateTenantOptionResponse,
  /**
   * @param {!proto.external.CreateTenantOptionRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.external.CreateTenantOptionResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.external.CreateTenantOptionRequest,
 *   !proto.external.CreateTenantOptionResponse>}
 */
const methodInfo_TenantOptionsService_CreateTenantOption = new grpc.web.AbstractClientBase.MethodInfo(
  proto.external.CreateTenantOptionResponse,
  /**
   * @param {!proto.external.CreateTenantOptionRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.external.CreateTenantOptionResponse.deserializeBinary
);


/**
 * @param {!proto.external.CreateTenantOptionRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.external.CreateTenantOptionResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.external.CreateTenantOptionResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.external.TenantOptionsServiceClient.prototype.createTenantOption =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/external.TenantOptionsService/CreateTenantOption',
      request,
      metadata || {},
      methodDescriptor_TenantOptionsService_CreateTenantOption,
      callback);
};


/**
 * @param {!proto.external.CreateTenantOptionRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.external.CreateTenantOptionResponse>}
 *     Promise that resolves to the response
 */
proto.external.TenantOptionsServicePromiseClient.prototype.createTenantOption =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/external.TenantOptionsService/CreateTenantOption',
      request,
      metadata || {},
      methodDescriptor_TenantOptionsService_CreateTenantOption);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.external.GetTenantOptionsRequest,
 *   !proto.external.GetTenantOptionsResponse>}
 */
const methodDescriptor_TenantOptionsService_GetTenantOptions = new grpc.web.MethodDescriptor(
  '/external.TenantOptionsService/GetTenantOptions',
  grpc.web.MethodType.UNARY,
  proto.external.GetTenantOptionsRequest,
  proto.external.GetTenantOptionsResponse,
  /**
   * @param {!proto.external.GetTenantOptionsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.external.GetTenantOptionsResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.external.GetTenantOptionsRequest,
 *   !proto.external.GetTenantOptionsResponse>}
 */
const methodInfo_TenantOptionsService_GetTenantOptions = new grpc.web.AbstractClientBase.MethodInfo(
  proto.external.GetTenantOptionsResponse,
  /**
   * @param {!proto.external.GetTenantOptionsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.external.GetTenantOptionsResponse.deserializeBinary
);


/**
 * @param {!proto.external.GetTenantOptionsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.external.GetTenantOptionsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.external.GetTenantOptionsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.external.TenantOptionsServiceClient.prototype.getTenantOptions =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/external.TenantOptionsService/GetTenantOptions',
      request,
      metadata || {},
      methodDescriptor_TenantOptionsService_GetTenantOptions,
      callback);
};


/**
 * @param {!proto.external.GetTenantOptionsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.external.GetTenantOptionsResponse>}
 *     Promise that resolves to the response
 */
proto.external.TenantOptionsServicePromiseClient.prototype.getTenantOptions =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/external.TenantOptionsService/GetTenantOptions',
      request,
      metadata || {},
      methodDescriptor_TenantOptionsService_GetTenantOptions);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.external.UpdateTenantOptionsRequest,
 *   !proto.google.protobuf.Empty>}
 */
const methodDescriptor_TenantOptionsService_UpdateTenantOptions = new grpc.web.MethodDescriptor(
  '/external.TenantOptionsService/UpdateTenantOptions',
  grpc.web.MethodType.UNARY,
  proto.external.UpdateTenantOptionsRequest,
  google_protobuf_empty_pb.Empty,
  /**
   * @param {!proto.external.UpdateTenantOptionsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  google_protobuf_empty_pb.Empty.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.external.UpdateTenantOptionsRequest,
 *   !proto.google.protobuf.Empty>}
 */
const methodInfo_TenantOptionsService_UpdateTenantOptions = new grpc.web.AbstractClientBase.MethodInfo(
  google_protobuf_empty_pb.Empty,
  /**
   * @param {!proto.external.UpdateTenantOptionsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  google_protobuf_empty_pb.Empty.deserializeBinary
);


/**
 * @param {!proto.external.UpdateTenantOptionsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.google.protobuf.Empty)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.google.protobuf.Empty>|undefined}
 *     The XHR Node Readable Stream
 */
proto.external.TenantOptionsServiceClient.prototype.updateTenantOptions =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/external.TenantOptionsService/UpdateTenantOptions',
      request,
      metadata || {},
      methodDescriptor_TenantOptionsService_UpdateTenantOptions,
      callback);
};


/**
 * @param {!proto.external.UpdateTenantOptionsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.google.protobuf.Empty>}
 *     Promise that resolves to the response
 */
proto.external.TenantOptionsServicePromiseClient.prototype.updateTenantOptions =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/external.TenantOptionsService/UpdateTenantOptions',
      request,
      metadata || {},
      methodDescriptor_TenantOptionsService_UpdateTenantOptions);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.external.DeleteTenantOptionsRequest,
 *   !proto.google.protobuf.Empty>}
 */
const methodDescriptor_TenantOptionsService_DeleteTenantOptions = new grpc.web.MethodDescriptor(
  '/external.TenantOptionsService/DeleteTenantOptions',
  grpc.web.MethodType.UNARY,
  proto.external.DeleteTenantOptionsRequest,
  google_protobuf_empty_pb.Empty,
  /**
   * @param {!proto.external.DeleteTenantOptionsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  google_protobuf_empty_pb.Empty.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.external.DeleteTenantOptionsRequest,
 *   !proto.google.protobuf.Empty>}
 */
const methodInfo_TenantOptionsService_DeleteTenantOptions = new grpc.web.AbstractClientBase.MethodInfo(
  google_protobuf_empty_pb.Empty,
  /**
   * @param {!proto.external.DeleteTenantOptionsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  google_protobuf_empty_pb.Empty.deserializeBinary
);


/**
 * @param {!proto.external.DeleteTenantOptionsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.google.protobuf.Empty)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.google.protobuf.Empty>|undefined}
 *     The XHR Node Readable Stream
 */
proto.external.TenantOptionsServiceClient.prototype.deleteTenantOptions =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/external.TenantOptionsService/DeleteTenantOptions',
      request,
      metadata || {},
      methodDescriptor_TenantOptionsService_DeleteTenantOptions,
      callback);
};


/**
 * @param {!proto.external.DeleteTenantOptionsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.google.protobuf.Empty>}
 *     Promise that resolves to the response
 */
proto.external.TenantOptionsServicePromiseClient.prototype.deleteTenantOptions =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/external.TenantOptionsService/DeleteTenantOptions',
      request,
      metadata || {},
      methodDescriptor_TenantOptionsService_DeleteTenantOptions);
};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.external.TenantDetailsServiceClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options['format'] = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.external.TenantDetailsServicePromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options['format'] = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.external.GetTenantDetailsByIDRequest,
 *   !proto.external.TenantDetails>}
 */
const methodDescriptor_TenantDetailsService_GetTenantDetailsByID = new grpc.web.MethodDescriptor(
  '/external.TenantDetailsService/GetTenantDetailsByID',
  grpc.web.MethodType.UNARY,
  proto.external.GetTenantDetailsByIDRequest,
  proto.external.TenantDetails,
  /**
   * @param {!proto.external.GetTenantDetailsByIDRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.external.TenantDetails.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.external.GetTenantDetailsByIDRequest,
 *   !proto.external.TenantDetails>}
 */
const methodInfo_TenantDetailsService_GetTenantDetailsByID = new grpc.web.AbstractClientBase.MethodInfo(
  proto.external.TenantDetails,
  /**
   * @param {!proto.external.GetTenantDetailsByIDRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.external.TenantDetails.deserializeBinary
);


/**
 * @param {!proto.external.GetTenantDetailsByIDRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.external.TenantDetails)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.external.TenantDetails>|undefined}
 *     The XHR Node Readable Stream
 */
proto.external.TenantDetailsServiceClient.prototype.getTenantDetailsByID =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/external.TenantDetailsService/GetTenantDetailsByID',
      request,
      metadata || {},
      methodDescriptor_TenantDetailsService_GetTenantDetailsByID,
      callback);
};


/**
 * @param {!proto.external.GetTenantDetailsByIDRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.external.TenantDetails>}
 *     Promise that resolves to the response
 */
proto.external.TenantDetailsServicePromiseClient.prototype.getTenantDetailsByID =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/external.TenantDetailsService/GetTenantDetailsByID',
      request,
      metadata || {},
      methodDescriptor_TenantDetailsService_GetTenantDetailsByID);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.external.GetTenantDetailsByContractIDRequest,
 *   !proto.external.TenantDetails>}
 */
const methodDescriptor_TenantDetailsService_GetTenantDetailsByContractID = new grpc.web.MethodDescriptor(
  '/external.TenantDetailsService/GetTenantDetailsByContractID',
  grpc.web.MethodType.UNARY,
  proto.external.GetTenantDetailsByContractIDRequest,
  proto.external.TenantDetails,
  /**
   * @param {!proto.external.GetTenantDetailsByContractIDRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.external.TenantDetails.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.external.GetTenantDetailsByContractIDRequest,
 *   !proto.external.TenantDetails>}
 */
const methodInfo_TenantDetailsService_GetTenantDetailsByContractID = new grpc.web.AbstractClientBase.MethodInfo(
  proto.external.TenantDetails,
  /**
   * @param {!proto.external.GetTenantDetailsByContractIDRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.external.TenantDetails.deserializeBinary
);


/**
 * @param {!proto.external.GetTenantDetailsByContractIDRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.external.TenantDetails)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.external.TenantDetails>|undefined}
 *     The XHR Node Readable Stream
 */
proto.external.TenantDetailsServiceClient.prototype.getTenantDetailsByContractID =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/external.TenantDetailsService/GetTenantDetailsByContractID',
      request,
      metadata || {},
      methodDescriptor_TenantDetailsService_GetTenantDetailsByContractID,
      callback);
};


/**
 * @param {!proto.external.GetTenantDetailsByContractIDRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.external.TenantDetails>}
 *     Promise that resolves to the response
 */
proto.external.TenantDetailsServicePromiseClient.prototype.getTenantDetailsByContractID =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/external.TenantDetailsService/GetTenantDetailsByContractID',
      request,
      metadata || {},
      methodDescriptor_TenantDetailsService_GetTenantDetailsByContractID);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.external.UpdateTenantDetailsRequest,
 *   !proto.google.protobuf.Empty>}
 */
const methodDescriptor_TenantDetailsService_UpdateTenantDetails = new grpc.web.MethodDescriptor(
  '/external.TenantDetailsService/UpdateTenantDetails',
  grpc.web.MethodType.UNARY,
  proto.external.UpdateTenantDetailsRequest,
  google_protobuf_empty_pb.Empty,
  /**
   * @param {!proto.external.UpdateTenantDetailsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  google_protobuf_empty_pb.Empty.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.external.UpdateTenantDetailsRequest,
 *   !proto.google.protobuf.Empty>}
 */
const methodInfo_TenantDetailsService_UpdateTenantDetails = new grpc.web.AbstractClientBase.MethodInfo(
  google_protobuf_empty_pb.Empty,
  /**
   * @param {!proto.external.UpdateTenantDetailsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  google_protobuf_empty_pb.Empty.deserializeBinary
);


/**
 * @param {!proto.external.UpdateTenantDetailsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.google.protobuf.Empty)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.google.protobuf.Empty>|undefined}
 *     The XHR Node Readable Stream
 */
proto.external.TenantDetailsServiceClient.prototype.updateTenantDetails =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/external.TenantDetailsService/UpdateTenantDetails',
      request,
      metadata || {},
      methodDescriptor_TenantDetailsService_UpdateTenantDetails,
      callback);
};


/**
 * @param {!proto.external.UpdateTenantDetailsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.google.protobuf.Empty>}
 *     Promise that resolves to the response
 */
proto.external.TenantDetailsServicePromiseClient.prototype.updateTenantDetails =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/external.TenantDetailsService/UpdateTenantDetails',
      request,
      metadata || {},
      methodDescriptor_TenantDetailsService_UpdateTenantDetails);
};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.external.CashFlowServiceClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options['format'] = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.external.CashFlowServicePromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options['format'] = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.external.GetIncomeByAssetIDRequest,
 *   !proto.external.GetIncomeByAssetIDResponse>}
 */
const methodDescriptor_CashFlowService_GetIncomeByAssetID = new grpc.web.MethodDescriptor(
  '/external.CashFlowService/GetIncomeByAssetID',
  grpc.web.MethodType.UNARY,
  proto.external.GetIncomeByAssetIDRequest,
  proto.external.GetIncomeByAssetIDResponse,
  /**
   * @param {!proto.external.GetIncomeByAssetIDRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.external.GetIncomeByAssetIDResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.external.GetIncomeByAssetIDRequest,
 *   !proto.external.GetIncomeByAssetIDResponse>}
 */
const methodInfo_CashFlowService_GetIncomeByAssetID = new grpc.web.AbstractClientBase.MethodInfo(
  proto.external.GetIncomeByAssetIDResponse,
  /**
   * @param {!proto.external.GetIncomeByAssetIDRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.external.GetIncomeByAssetIDResponse.deserializeBinary
);


/**
 * @param {!proto.external.GetIncomeByAssetIDRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.external.GetIncomeByAssetIDResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.external.GetIncomeByAssetIDResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.external.CashFlowServiceClient.prototype.getIncomeByAssetID =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/external.CashFlowService/GetIncomeByAssetID',
      request,
      metadata || {},
      methodDescriptor_CashFlowService_GetIncomeByAssetID,
      callback);
};


/**
 * @param {!proto.external.GetIncomeByAssetIDRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.external.GetIncomeByAssetIDResponse>}
 *     Promise that resolves to the response
 */
proto.external.CashFlowServicePromiseClient.prototype.getIncomeByAssetID =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/external.CashFlowService/GetIncomeByAssetID',
      request,
      metadata || {},
      methodDescriptor_CashFlowService_GetIncomeByAssetID);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.external.GetServiceChargeByAssetIDRequest,
 *   !proto.external.GetServiceChargeByAssetIDResponse>}
 */
const methodDescriptor_CashFlowService_GetServiceChargeByAssetID = new grpc.web.MethodDescriptor(
  '/external.CashFlowService/GetServiceChargeByAssetID',
  grpc.web.MethodType.UNARY,
  proto.external.GetServiceChargeByAssetIDRequest,
  proto.external.GetServiceChargeByAssetIDResponse,
  /**
   * @param {!proto.external.GetServiceChargeByAssetIDRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.external.GetServiceChargeByAssetIDResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.external.GetServiceChargeByAssetIDRequest,
 *   !proto.external.GetServiceChargeByAssetIDResponse>}
 */
const methodInfo_CashFlowService_GetServiceChargeByAssetID = new grpc.web.AbstractClientBase.MethodInfo(
  proto.external.GetServiceChargeByAssetIDResponse,
  /**
   * @param {!proto.external.GetServiceChargeByAssetIDRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.external.GetServiceChargeByAssetIDResponse.deserializeBinary
);


/**
 * @param {!proto.external.GetServiceChargeByAssetIDRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.external.GetServiceChargeByAssetIDResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.external.GetServiceChargeByAssetIDResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.external.CashFlowServiceClient.prototype.getServiceChargeByAssetID =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/external.CashFlowService/GetServiceChargeByAssetID',
      request,
      metadata || {},
      methodDescriptor_CashFlowService_GetServiceChargeByAssetID,
      callback);
};


/**
 * @param {!proto.external.GetServiceChargeByAssetIDRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.external.GetServiceChargeByAssetIDResponse>}
 *     Promise that resolves to the response
 */
proto.external.CashFlowServicePromiseClient.prototype.getServiceChargeByAssetID =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/external.CashFlowService/GetServiceChargeByAssetID',
      request,
      metadata || {},
      methodDescriptor_CashFlowService_GetServiceChargeByAssetID);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.external.GetMonthlyCashFlowRequest,
 *   !proto.external.GetMonthlyCashFlowResponse>}
 */
const methodDescriptor_CashFlowService_GetMonthlyCashFlow = new grpc.web.MethodDescriptor(
  '/external.CashFlowService/GetMonthlyCashFlow',
  grpc.web.MethodType.UNARY,
  proto.external.GetMonthlyCashFlowRequest,
  proto.external.GetMonthlyCashFlowResponse,
  /**
   * @param {!proto.external.GetMonthlyCashFlowRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.external.GetMonthlyCashFlowResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.external.GetMonthlyCashFlowRequest,
 *   !proto.external.GetMonthlyCashFlowResponse>}
 */
const methodInfo_CashFlowService_GetMonthlyCashFlow = new grpc.web.AbstractClientBase.MethodInfo(
  proto.external.GetMonthlyCashFlowResponse,
  /**
   * @param {!proto.external.GetMonthlyCashFlowRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.external.GetMonthlyCashFlowResponse.deserializeBinary
);


/**
 * @param {!proto.external.GetMonthlyCashFlowRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.external.GetMonthlyCashFlowResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.external.GetMonthlyCashFlowResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.external.CashFlowServiceClient.prototype.getMonthlyCashFlow =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/external.CashFlowService/GetMonthlyCashFlow',
      request,
      metadata || {},
      methodDescriptor_CashFlowService_GetMonthlyCashFlow,
      callback);
};


/**
 * @param {!proto.external.GetMonthlyCashFlowRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.external.GetMonthlyCashFlowResponse>}
 *     Promise that resolves to the response
 */
proto.external.CashFlowServicePromiseClient.prototype.getMonthlyCashFlow =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/external.CashFlowService/GetMonthlyCashFlow',
      request,
      metadata || {},
      methodDescriptor_CashFlowService_GetMonthlyCashFlow);
};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.external.TenantCostContractDetailsServiceClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options['format'] = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.external.TenantCostContractDetailsServicePromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options['format'] = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.external.GetTenantCostContractDetailsRequest,
 *   !proto.external.GetTenantCostContractDetailsByCostManagerIDResponse>}
 */
const methodDescriptor_TenantCostContractDetailsService_GetTenantCostContractDetailsByCostManagerID = new grpc.web.MethodDescriptor(
  '/external.TenantCostContractDetailsService/GetTenantCostContractDetailsByCostManagerID',
  grpc.web.MethodType.UNARY,
  proto.external.GetTenantCostContractDetailsRequest,
  proto.external.GetTenantCostContractDetailsByCostManagerIDResponse,
  /**
   * @param {!proto.external.GetTenantCostContractDetailsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.external.GetTenantCostContractDetailsByCostManagerIDResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.external.GetTenantCostContractDetailsRequest,
 *   !proto.external.GetTenantCostContractDetailsByCostManagerIDResponse>}
 */
const methodInfo_TenantCostContractDetailsService_GetTenantCostContractDetailsByCostManagerID = new grpc.web.AbstractClientBase.MethodInfo(
  proto.external.GetTenantCostContractDetailsByCostManagerIDResponse,
  /**
   * @param {!proto.external.GetTenantCostContractDetailsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.external.GetTenantCostContractDetailsByCostManagerIDResponse.deserializeBinary
);


/**
 * @param {!proto.external.GetTenantCostContractDetailsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.external.GetTenantCostContractDetailsByCostManagerIDResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.external.GetTenantCostContractDetailsByCostManagerIDResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.external.TenantCostContractDetailsServiceClient.prototype.getTenantCostContractDetailsByCostManagerID =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/external.TenantCostContractDetailsService/GetTenantCostContractDetailsByCostManagerID',
      request,
      metadata || {},
      methodDescriptor_TenantCostContractDetailsService_GetTenantCostContractDetailsByCostManagerID,
      callback);
};


/**
 * @param {!proto.external.GetTenantCostContractDetailsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.external.GetTenantCostContractDetailsByCostManagerIDResponse>}
 *     Promise that resolves to the response
 */
proto.external.TenantCostContractDetailsServicePromiseClient.prototype.getTenantCostContractDetailsByCostManagerID =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/external.TenantCostContractDetailsService/GetTenantCostContractDetailsByCostManagerID',
      request,
      metadata || {},
      methodDescriptor_TenantCostContractDetailsService_GetTenantCostContractDetailsByCostManagerID);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.external.GetTenantCostContractDetailsRequest,
 *   !proto.external.GetTenantCostContractDetailsResponse>}
 */
const methodDescriptor_TenantCostContractDetailsService_GetTenantCostContractDetails = new grpc.web.MethodDescriptor(
  '/external.TenantCostContractDetailsService/GetTenantCostContractDetails',
  grpc.web.MethodType.UNARY,
  proto.external.GetTenantCostContractDetailsRequest,
  proto.external.GetTenantCostContractDetailsResponse,
  /**
   * @param {!proto.external.GetTenantCostContractDetailsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.external.GetTenantCostContractDetailsResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.external.GetTenantCostContractDetailsRequest,
 *   !proto.external.GetTenantCostContractDetailsResponse>}
 */
const methodInfo_TenantCostContractDetailsService_GetTenantCostContractDetails = new grpc.web.AbstractClientBase.MethodInfo(
  proto.external.GetTenantCostContractDetailsResponse,
  /**
   * @param {!proto.external.GetTenantCostContractDetailsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.external.GetTenantCostContractDetailsResponse.deserializeBinary
);


/**
 * @param {!proto.external.GetTenantCostContractDetailsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.external.GetTenantCostContractDetailsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.external.GetTenantCostContractDetailsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.external.TenantCostContractDetailsServiceClient.prototype.getTenantCostContractDetails =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/external.TenantCostContractDetailsService/GetTenantCostContractDetails',
      request,
      metadata || {},
      methodDescriptor_TenantCostContractDetailsService_GetTenantCostContractDetails,
      callback);
};


/**
 * @param {!proto.external.GetTenantCostContractDetailsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.external.GetTenantCostContractDetailsResponse>}
 *     Promise that resolves to the response
 */
proto.external.TenantCostContractDetailsServicePromiseClient.prototype.getTenantCostContractDetails =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/external.TenantCostContractDetailsService/GetTenantCostContractDetails',
      request,
      metadata || {},
      methodDescriptor_TenantCostContractDetailsService_GetTenantCostContractDetails);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.external.UpdateTenantCostContractDetailsRequest,
 *   !proto.google.protobuf.Empty>}
 */
const methodDescriptor_TenantCostContractDetailsService_UpdateTenantCostContractDetails = new grpc.web.MethodDescriptor(
  '/external.TenantCostContractDetailsService/UpdateTenantCostContractDetails',
  grpc.web.MethodType.UNARY,
  proto.external.UpdateTenantCostContractDetailsRequest,
  google_protobuf_empty_pb.Empty,
  /**
   * @param {!proto.external.UpdateTenantCostContractDetailsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  google_protobuf_empty_pb.Empty.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.external.UpdateTenantCostContractDetailsRequest,
 *   !proto.google.protobuf.Empty>}
 */
const methodInfo_TenantCostContractDetailsService_UpdateTenantCostContractDetails = new grpc.web.AbstractClientBase.MethodInfo(
  google_protobuf_empty_pb.Empty,
  /**
   * @param {!proto.external.UpdateTenantCostContractDetailsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  google_protobuf_empty_pb.Empty.deserializeBinary
);


/**
 * @param {!proto.external.UpdateTenantCostContractDetailsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.google.protobuf.Empty)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.google.protobuf.Empty>|undefined}
 *     The XHR Node Readable Stream
 */
proto.external.TenantCostContractDetailsServiceClient.prototype.updateTenantCostContractDetails =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/external.TenantCostContractDetailsService/UpdateTenantCostContractDetails',
      request,
      metadata || {},
      methodDescriptor_TenantCostContractDetailsService_UpdateTenantCostContractDetails,
      callback);
};


/**
 * @param {!proto.external.UpdateTenantCostContractDetailsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.google.protobuf.Empty>}
 *     Promise that resolves to the response
 */
proto.external.TenantCostContractDetailsServicePromiseClient.prototype.updateTenantCostContractDetails =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/external.TenantCostContractDetailsService/UpdateTenantCostContractDetails',
      request,
      metadata || {},
      methodDescriptor_TenantCostContractDetailsService_UpdateTenantCostContractDetails);
};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.external.TenantCostProviderServiceClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options['format'] = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.external.TenantCostProviderServicePromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options['format'] = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.external.TenantCostProviderRequest,
 *   !proto.external.GetTenantCostProviderResponse>}
 */
const methodDescriptor_TenantCostProviderService_GetTenantCostProviderByID = new grpc.web.MethodDescriptor(
  '/external.TenantCostProviderService/GetTenantCostProviderByID',
  grpc.web.MethodType.UNARY,
  proto.external.TenantCostProviderRequest,
  proto.external.GetTenantCostProviderResponse,
  /**
   * @param {!proto.external.TenantCostProviderRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.external.GetTenantCostProviderResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.external.TenantCostProviderRequest,
 *   !proto.external.GetTenantCostProviderResponse>}
 */
const methodInfo_TenantCostProviderService_GetTenantCostProviderByID = new grpc.web.AbstractClientBase.MethodInfo(
  proto.external.GetTenantCostProviderResponse,
  /**
   * @param {!proto.external.TenantCostProviderRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.external.GetTenantCostProviderResponse.deserializeBinary
);


/**
 * @param {!proto.external.TenantCostProviderRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.external.GetTenantCostProviderResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.external.GetTenantCostProviderResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.external.TenantCostProviderServiceClient.prototype.getTenantCostProviderByID =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/external.TenantCostProviderService/GetTenantCostProviderByID',
      request,
      metadata || {},
      methodDescriptor_TenantCostProviderService_GetTenantCostProviderByID,
      callback);
};


/**
 * @param {!proto.external.TenantCostProviderRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.external.GetTenantCostProviderResponse>}
 *     Promise that resolves to the response
 */
proto.external.TenantCostProviderServicePromiseClient.prototype.getTenantCostProviderByID =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/external.TenantCostProviderService/GetTenantCostProviderByID',
      request,
      metadata || {},
      methodDescriptor_TenantCostProviderService_GetTenantCostProviderByID);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.external.TenantCostProviderRequest,
 *   !proto.external.GetTenantCostProvidersResponse>}
 */
const methodDescriptor_TenantCostProviderService_GetTenantCostProvidersByCostManagerID = new grpc.web.MethodDescriptor(
  '/external.TenantCostProviderService/GetTenantCostProvidersByCostManagerID',
  grpc.web.MethodType.UNARY,
  proto.external.TenantCostProviderRequest,
  proto.external.GetTenantCostProvidersResponse,
  /**
   * @param {!proto.external.TenantCostProviderRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.external.GetTenantCostProvidersResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.external.TenantCostProviderRequest,
 *   !proto.external.GetTenantCostProvidersResponse>}
 */
const methodInfo_TenantCostProviderService_GetTenantCostProvidersByCostManagerID = new grpc.web.AbstractClientBase.MethodInfo(
  proto.external.GetTenantCostProvidersResponse,
  /**
   * @param {!proto.external.TenantCostProviderRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.external.GetTenantCostProvidersResponse.deserializeBinary
);


/**
 * @param {!proto.external.TenantCostProviderRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.external.GetTenantCostProvidersResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.external.GetTenantCostProvidersResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.external.TenantCostProviderServiceClient.prototype.getTenantCostProvidersByCostManagerID =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/external.TenantCostProviderService/GetTenantCostProvidersByCostManagerID',
      request,
      metadata || {},
      methodDescriptor_TenantCostProviderService_GetTenantCostProvidersByCostManagerID,
      callback);
};


/**
 * @param {!proto.external.TenantCostProviderRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.external.GetTenantCostProvidersResponse>}
 *     Promise that resolves to the response
 */
proto.external.TenantCostProviderServicePromiseClient.prototype.getTenantCostProvidersByCostManagerID =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/external.TenantCostProviderService/GetTenantCostProvidersByCostManagerID',
      request,
      metadata || {},
      methodDescriptor_TenantCostProviderService_GetTenantCostProvidersByCostManagerID);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.external.UpdateTenantCostProviderRequest,
 *   !proto.google.protobuf.Empty>}
 */
const methodDescriptor_TenantCostProviderService_UpdateTenantCostProvider = new grpc.web.MethodDescriptor(
  '/external.TenantCostProviderService/UpdateTenantCostProvider',
  grpc.web.MethodType.UNARY,
  proto.external.UpdateTenantCostProviderRequest,
  google_protobuf_empty_pb.Empty,
  /**
   * @param {!proto.external.UpdateTenantCostProviderRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  google_protobuf_empty_pb.Empty.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.external.UpdateTenantCostProviderRequest,
 *   !proto.google.protobuf.Empty>}
 */
const methodInfo_TenantCostProviderService_UpdateTenantCostProvider = new grpc.web.AbstractClientBase.MethodInfo(
  google_protobuf_empty_pb.Empty,
  /**
   * @param {!proto.external.UpdateTenantCostProviderRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  google_protobuf_empty_pb.Empty.deserializeBinary
);


/**
 * @param {!proto.external.UpdateTenantCostProviderRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.google.protobuf.Empty)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.google.protobuf.Empty>|undefined}
 *     The XHR Node Readable Stream
 */
proto.external.TenantCostProviderServiceClient.prototype.updateTenantCostProvider =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/external.TenantCostProviderService/UpdateTenantCostProvider',
      request,
      metadata || {},
      methodDescriptor_TenantCostProviderService_UpdateTenantCostProvider,
      callback);
};


/**
 * @param {!proto.external.UpdateTenantCostProviderRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.google.protobuf.Empty>}
 *     Promise that resolves to the response
 */
proto.external.TenantCostProviderServicePromiseClient.prototype.updateTenantCostProvider =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/external.TenantCostProviderService/UpdateTenantCostProvider',
      request,
      metadata || {},
      methodDescriptor_TenantCostProviderService_UpdateTenantCostProvider);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.external.TenantCostProviderRequest,
 *   !proto.google.protobuf.Empty>}
 */
const methodDescriptor_TenantCostProviderService_DeleteTenantCostProvider = new grpc.web.MethodDescriptor(
  '/external.TenantCostProviderService/DeleteTenantCostProvider',
  grpc.web.MethodType.UNARY,
  proto.external.TenantCostProviderRequest,
  google_protobuf_empty_pb.Empty,
  /**
   * @param {!proto.external.TenantCostProviderRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  google_protobuf_empty_pb.Empty.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.external.TenantCostProviderRequest,
 *   !proto.google.protobuf.Empty>}
 */
const methodInfo_TenantCostProviderService_DeleteTenantCostProvider = new grpc.web.AbstractClientBase.MethodInfo(
  google_protobuf_empty_pb.Empty,
  /**
   * @param {!proto.external.TenantCostProviderRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  google_protobuf_empty_pb.Empty.deserializeBinary
);


/**
 * @param {!proto.external.TenantCostProviderRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.google.protobuf.Empty)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.google.protobuf.Empty>|undefined}
 *     The XHR Node Readable Stream
 */
proto.external.TenantCostProviderServiceClient.prototype.deleteTenantCostProvider =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/external.TenantCostProviderService/DeleteTenantCostProvider',
      request,
      metadata || {},
      methodDescriptor_TenantCostProviderService_DeleteTenantCostProvider,
      callback);
};


/**
 * @param {!proto.external.TenantCostProviderRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.google.protobuf.Empty>}
 *     Promise that resolves to the response
 */
proto.external.TenantCostProviderServicePromiseClient.prototype.deleteTenantCostProvider =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/external.TenantCostProviderService/DeleteTenantCostProvider',
      request,
      metadata || {},
      methodDescriptor_TenantCostProviderService_DeleteTenantCostProvider);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.external.CreateTenantCostProviderRequest,
 *   !proto.external.CreateTenantCostProviderResponse>}
 */
const methodDescriptor_TenantCostProviderService_CreateTenantCostProvider = new grpc.web.MethodDescriptor(
  '/external.TenantCostProviderService/CreateTenantCostProvider',
  grpc.web.MethodType.UNARY,
  proto.external.CreateTenantCostProviderRequest,
  proto.external.CreateTenantCostProviderResponse,
  /**
   * @param {!proto.external.CreateTenantCostProviderRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.external.CreateTenantCostProviderResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.external.CreateTenantCostProviderRequest,
 *   !proto.external.CreateTenantCostProviderResponse>}
 */
const methodInfo_TenantCostProviderService_CreateTenantCostProvider = new grpc.web.AbstractClientBase.MethodInfo(
  proto.external.CreateTenantCostProviderResponse,
  /**
   * @param {!proto.external.CreateTenantCostProviderRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.external.CreateTenantCostProviderResponse.deserializeBinary
);


/**
 * @param {!proto.external.CreateTenantCostProviderRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.external.CreateTenantCostProviderResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.external.CreateTenantCostProviderResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.external.TenantCostProviderServiceClient.prototype.createTenantCostProvider =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/external.TenantCostProviderService/CreateTenantCostProvider',
      request,
      metadata || {},
      methodDescriptor_TenantCostProviderService_CreateTenantCostProvider,
      callback);
};


/**
 * @param {!proto.external.CreateTenantCostProviderRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.external.CreateTenantCostProviderResponse>}
 *     Promise that resolves to the response
 */
proto.external.TenantCostProviderServicePromiseClient.prototype.createTenantCostProvider =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/external.TenantCostProviderService/CreateTenantCostProvider',
      request,
      metadata || {},
      methodDescriptor_TenantCostProviderService_CreateTenantCostProvider);
};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.external.TenantCostSupplierDetailsServiceClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options['format'] = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.external.TenantCostSupplierDetailsServicePromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options['format'] = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.external.TenantCostSupplierDetailsRequest,
 *   !proto.external.GetTenantCostSupplierDetailsResponse>}
 */
const methodDescriptor_TenantCostSupplierDetailsService_GetTenantCostSupplierDetailsByID = new grpc.web.MethodDescriptor(
  '/external.TenantCostSupplierDetailsService/GetTenantCostSupplierDetailsByID',
  grpc.web.MethodType.UNARY,
  proto.external.TenantCostSupplierDetailsRequest,
  proto.external.GetTenantCostSupplierDetailsResponse,
  /**
   * @param {!proto.external.TenantCostSupplierDetailsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.external.GetTenantCostSupplierDetailsResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.external.TenantCostSupplierDetailsRequest,
 *   !proto.external.GetTenantCostSupplierDetailsResponse>}
 */
const methodInfo_TenantCostSupplierDetailsService_GetTenantCostSupplierDetailsByID = new grpc.web.AbstractClientBase.MethodInfo(
  proto.external.GetTenantCostSupplierDetailsResponse,
  /**
   * @param {!proto.external.TenantCostSupplierDetailsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.external.GetTenantCostSupplierDetailsResponse.deserializeBinary
);


/**
 * @param {!proto.external.TenantCostSupplierDetailsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.external.GetTenantCostSupplierDetailsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.external.GetTenantCostSupplierDetailsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.external.TenantCostSupplierDetailsServiceClient.prototype.getTenantCostSupplierDetailsByID =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/external.TenantCostSupplierDetailsService/GetTenantCostSupplierDetailsByID',
      request,
      metadata || {},
      methodDescriptor_TenantCostSupplierDetailsService_GetTenantCostSupplierDetailsByID,
      callback);
};


/**
 * @param {!proto.external.TenantCostSupplierDetailsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.external.GetTenantCostSupplierDetailsResponse>}
 *     Promise that resolves to the response
 */
proto.external.TenantCostSupplierDetailsServicePromiseClient.prototype.getTenantCostSupplierDetailsByID =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/external.TenantCostSupplierDetailsService/GetTenantCostSupplierDetailsByID',
      request,
      metadata || {},
      methodDescriptor_TenantCostSupplierDetailsService_GetTenantCostSupplierDetailsByID);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.external.TenantCostSupplierDetailsRequest,
 *   !proto.external.GetTenantCostSupplierDetailsResponse>}
 */
const methodDescriptor_TenantCostSupplierDetailsService_GetTenantCostSupplierDetailsByCostManagerID = new grpc.web.MethodDescriptor(
  '/external.TenantCostSupplierDetailsService/GetTenantCostSupplierDetailsByCostManagerID',
  grpc.web.MethodType.UNARY,
  proto.external.TenantCostSupplierDetailsRequest,
  proto.external.GetTenantCostSupplierDetailsResponse,
  /**
   * @param {!proto.external.TenantCostSupplierDetailsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.external.GetTenantCostSupplierDetailsResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.external.TenantCostSupplierDetailsRequest,
 *   !proto.external.GetTenantCostSupplierDetailsResponse>}
 */
const methodInfo_TenantCostSupplierDetailsService_GetTenantCostSupplierDetailsByCostManagerID = new grpc.web.AbstractClientBase.MethodInfo(
  proto.external.GetTenantCostSupplierDetailsResponse,
  /**
   * @param {!proto.external.TenantCostSupplierDetailsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.external.GetTenantCostSupplierDetailsResponse.deserializeBinary
);


/**
 * @param {!proto.external.TenantCostSupplierDetailsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.external.GetTenantCostSupplierDetailsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.external.GetTenantCostSupplierDetailsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.external.TenantCostSupplierDetailsServiceClient.prototype.getTenantCostSupplierDetailsByCostManagerID =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/external.TenantCostSupplierDetailsService/GetTenantCostSupplierDetailsByCostManagerID',
      request,
      metadata || {},
      methodDescriptor_TenantCostSupplierDetailsService_GetTenantCostSupplierDetailsByCostManagerID,
      callback);
};


/**
 * @param {!proto.external.TenantCostSupplierDetailsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.external.GetTenantCostSupplierDetailsResponse>}
 *     Promise that resolves to the response
 */
proto.external.TenantCostSupplierDetailsServicePromiseClient.prototype.getTenantCostSupplierDetailsByCostManagerID =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/external.TenantCostSupplierDetailsService/GetTenantCostSupplierDetailsByCostManagerID',
      request,
      metadata || {},
      methodDescriptor_TenantCostSupplierDetailsService_GetTenantCostSupplierDetailsByCostManagerID);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.external.UpdateTenantCostSupplierDetailsRequest,
 *   !proto.google.protobuf.Empty>}
 */
const methodDescriptor_TenantCostSupplierDetailsService_UpdateTenantCostSupplierDetails = new grpc.web.MethodDescriptor(
  '/external.TenantCostSupplierDetailsService/UpdateTenantCostSupplierDetails',
  grpc.web.MethodType.UNARY,
  proto.external.UpdateTenantCostSupplierDetailsRequest,
  google_protobuf_empty_pb.Empty,
  /**
   * @param {!proto.external.UpdateTenantCostSupplierDetailsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  google_protobuf_empty_pb.Empty.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.external.UpdateTenantCostSupplierDetailsRequest,
 *   !proto.google.protobuf.Empty>}
 */
const methodInfo_TenantCostSupplierDetailsService_UpdateTenantCostSupplierDetails = new grpc.web.AbstractClientBase.MethodInfo(
  google_protobuf_empty_pb.Empty,
  /**
   * @param {!proto.external.UpdateTenantCostSupplierDetailsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  google_protobuf_empty_pb.Empty.deserializeBinary
);


/**
 * @param {!proto.external.UpdateTenantCostSupplierDetailsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.google.protobuf.Empty)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.google.protobuf.Empty>|undefined}
 *     The XHR Node Readable Stream
 */
proto.external.TenantCostSupplierDetailsServiceClient.prototype.updateTenantCostSupplierDetails =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/external.TenantCostSupplierDetailsService/UpdateTenantCostSupplierDetails',
      request,
      metadata || {},
      methodDescriptor_TenantCostSupplierDetailsService_UpdateTenantCostSupplierDetails,
      callback);
};


/**
 * @param {!proto.external.UpdateTenantCostSupplierDetailsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.google.protobuf.Empty>}
 *     Promise that resolves to the response
 */
proto.external.TenantCostSupplierDetailsServicePromiseClient.prototype.updateTenantCostSupplierDetails =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/external.TenantCostSupplierDetailsService/UpdateTenantCostSupplierDetails',
      request,
      metadata || {},
      methodDescriptor_TenantCostSupplierDetailsService_UpdateTenantCostSupplierDetails);
};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.external.TenantCostManagerServiceClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options['format'] = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.external.TenantCostManagerServicePromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options['format'] = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.external.TenantCostManagerRequest,
 *   !proto.external.TenantCostManagerResponse>}
 */
const methodDescriptor_TenantCostManagerService_GetTenantCostManagerByID = new grpc.web.MethodDescriptor(
  '/external.TenantCostManagerService/GetTenantCostManagerByID',
  grpc.web.MethodType.UNARY,
  proto.external.TenantCostManagerRequest,
  proto.external.TenantCostManagerResponse,
  /**
   * @param {!proto.external.TenantCostManagerRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.external.TenantCostManagerResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.external.TenantCostManagerRequest,
 *   !proto.external.TenantCostManagerResponse>}
 */
const methodInfo_TenantCostManagerService_GetTenantCostManagerByID = new grpc.web.AbstractClientBase.MethodInfo(
  proto.external.TenantCostManagerResponse,
  /**
   * @param {!proto.external.TenantCostManagerRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.external.TenantCostManagerResponse.deserializeBinary
);


/**
 * @param {!proto.external.TenantCostManagerRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.external.TenantCostManagerResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.external.TenantCostManagerResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.external.TenantCostManagerServiceClient.prototype.getTenantCostManagerByID =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/external.TenantCostManagerService/GetTenantCostManagerByID',
      request,
      metadata || {},
      methodDescriptor_TenantCostManagerService_GetTenantCostManagerByID,
      callback);
};


/**
 * @param {!proto.external.TenantCostManagerRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.external.TenantCostManagerResponse>}
 *     Promise that resolves to the response
 */
proto.external.TenantCostManagerServicePromiseClient.prototype.getTenantCostManagerByID =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/external.TenantCostManagerService/GetTenantCostManagerByID',
      request,
      metadata || {},
      methodDescriptor_TenantCostManagerService_GetTenantCostManagerByID);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.external.TenantCostManagerRequest,
 *   !proto.external.TenantCostManagersResponse>}
 */
const methodDescriptor_TenantCostManagerService_GetTenantCostManagersByTenantID = new grpc.web.MethodDescriptor(
  '/external.TenantCostManagerService/GetTenantCostManagersByTenantID',
  grpc.web.MethodType.UNARY,
  proto.external.TenantCostManagerRequest,
  proto.external.TenantCostManagersResponse,
  /**
   * @param {!proto.external.TenantCostManagerRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.external.TenantCostManagersResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.external.TenantCostManagerRequest,
 *   !proto.external.TenantCostManagersResponse>}
 */
const methodInfo_TenantCostManagerService_GetTenantCostManagersByTenantID = new grpc.web.AbstractClientBase.MethodInfo(
  proto.external.TenantCostManagersResponse,
  /**
   * @param {!proto.external.TenantCostManagerRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.external.TenantCostManagersResponse.deserializeBinary
);


/**
 * @param {!proto.external.TenantCostManagerRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.external.TenantCostManagersResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.external.TenantCostManagersResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.external.TenantCostManagerServiceClient.prototype.getTenantCostManagersByTenantID =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/external.TenantCostManagerService/GetTenantCostManagersByTenantID',
      request,
      metadata || {},
      methodDescriptor_TenantCostManagerService_GetTenantCostManagersByTenantID,
      callback);
};


/**
 * @param {!proto.external.TenantCostManagerRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.external.TenantCostManagersResponse>}
 *     Promise that resolves to the response
 */
proto.external.TenantCostManagerServicePromiseClient.prototype.getTenantCostManagersByTenantID =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/external.TenantCostManagerService/GetTenantCostManagersByTenantID',
      request,
      metadata || {},
      methodDescriptor_TenantCostManagerService_GetTenantCostManagersByTenantID);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.external.CreateTenantCostManagerRequest,
 *   !proto.external.CreateTenantCostManagerResponse>}
 */
const methodDescriptor_TenantCostManagerService_CreateTenantCostManager = new grpc.web.MethodDescriptor(
  '/external.TenantCostManagerService/CreateTenantCostManager',
  grpc.web.MethodType.UNARY,
  proto.external.CreateTenantCostManagerRequest,
  proto.external.CreateTenantCostManagerResponse,
  /**
   * @param {!proto.external.CreateTenantCostManagerRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.external.CreateTenantCostManagerResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.external.CreateTenantCostManagerRequest,
 *   !proto.external.CreateTenantCostManagerResponse>}
 */
const methodInfo_TenantCostManagerService_CreateTenantCostManager = new grpc.web.AbstractClientBase.MethodInfo(
  proto.external.CreateTenantCostManagerResponse,
  /**
   * @param {!proto.external.CreateTenantCostManagerRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.external.CreateTenantCostManagerResponse.deserializeBinary
);


/**
 * @param {!proto.external.CreateTenantCostManagerRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.external.CreateTenantCostManagerResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.external.CreateTenantCostManagerResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.external.TenantCostManagerServiceClient.prototype.createTenantCostManager =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/external.TenantCostManagerService/CreateTenantCostManager',
      request,
      metadata || {},
      methodDescriptor_TenantCostManagerService_CreateTenantCostManager,
      callback);
};


/**
 * @param {!proto.external.CreateTenantCostManagerRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.external.CreateTenantCostManagerResponse>}
 *     Promise that resolves to the response
 */
proto.external.TenantCostManagerServicePromiseClient.prototype.createTenantCostManager =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/external.TenantCostManagerService/CreateTenantCostManager',
      request,
      metadata || {},
      methodDescriptor_TenantCostManagerService_CreateTenantCostManager);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.external.UpdateTenantCostManagerRequest,
 *   !proto.google.protobuf.Empty>}
 */
const methodDescriptor_TenantCostManagerService_UpdateTenantCostManager = new grpc.web.MethodDescriptor(
  '/external.TenantCostManagerService/UpdateTenantCostManager',
  grpc.web.MethodType.UNARY,
  proto.external.UpdateTenantCostManagerRequest,
  google_protobuf_empty_pb.Empty,
  /**
   * @param {!proto.external.UpdateTenantCostManagerRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  google_protobuf_empty_pb.Empty.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.external.UpdateTenantCostManagerRequest,
 *   !proto.google.protobuf.Empty>}
 */
const methodInfo_TenantCostManagerService_UpdateTenantCostManager = new grpc.web.AbstractClientBase.MethodInfo(
  google_protobuf_empty_pb.Empty,
  /**
   * @param {!proto.external.UpdateTenantCostManagerRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  google_protobuf_empty_pb.Empty.deserializeBinary
);


/**
 * @param {!proto.external.UpdateTenantCostManagerRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.google.protobuf.Empty)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.google.protobuf.Empty>|undefined}
 *     The XHR Node Readable Stream
 */
proto.external.TenantCostManagerServiceClient.prototype.updateTenantCostManager =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/external.TenantCostManagerService/UpdateTenantCostManager',
      request,
      metadata || {},
      methodDescriptor_TenantCostManagerService_UpdateTenantCostManager,
      callback);
};


/**
 * @param {!proto.external.UpdateTenantCostManagerRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.google.protobuf.Empty>}
 *     Promise that resolves to the response
 */
proto.external.TenantCostManagerServicePromiseClient.prototype.updateTenantCostManager =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/external.TenantCostManagerService/UpdateTenantCostManager',
      request,
      metadata || {},
      methodDescriptor_TenantCostManagerService_UpdateTenantCostManager);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.external.TenantCostManagerRequest,
 *   !proto.google.protobuf.Empty>}
 */
const methodDescriptor_TenantCostManagerService_DeleteTenantCostManager = new grpc.web.MethodDescriptor(
  '/external.TenantCostManagerService/DeleteTenantCostManager',
  grpc.web.MethodType.UNARY,
  proto.external.TenantCostManagerRequest,
  google_protobuf_empty_pb.Empty,
  /**
   * @param {!proto.external.TenantCostManagerRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  google_protobuf_empty_pb.Empty.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.external.TenantCostManagerRequest,
 *   !proto.google.protobuf.Empty>}
 */
const methodInfo_TenantCostManagerService_DeleteTenantCostManager = new grpc.web.AbstractClientBase.MethodInfo(
  google_protobuf_empty_pb.Empty,
  /**
   * @param {!proto.external.TenantCostManagerRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  google_protobuf_empty_pb.Empty.deserializeBinary
);


/**
 * @param {!proto.external.TenantCostManagerRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.google.protobuf.Empty)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.google.protobuf.Empty>|undefined}
 *     The XHR Node Readable Stream
 */
proto.external.TenantCostManagerServiceClient.prototype.deleteTenantCostManager =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/external.TenantCostManagerService/DeleteTenantCostManager',
      request,
      metadata || {},
      methodDescriptor_TenantCostManagerService_DeleteTenantCostManager,
      callback);
};


/**
 * @param {!proto.external.TenantCostManagerRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.google.protobuf.Empty>}
 *     Promise that resolves to the response
 */
proto.external.TenantCostManagerServicePromiseClient.prototype.deleteTenantCostManager =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/external.TenantCostManagerService/DeleteTenantCostManager',
      request,
      metadata || {},
      methodDescriptor_TenantCostManagerService_DeleteTenantCostManager);
};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.external.TenantActivityFeedServiceClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options['format'] = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.external.TenantActivityFeedServicePromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options['format'] = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.external.TenantActivityFeedsByAssetIDRequest,
 *   !proto.external.TenantActivityFeedsResponse>}
 */
const methodDescriptor_TenantActivityFeedService_GetActivityFeedsByAsset = new grpc.web.MethodDescriptor(
  '/external.TenantActivityFeedService/GetActivityFeedsByAsset',
  grpc.web.MethodType.UNARY,
  proto.external.TenantActivityFeedsByAssetIDRequest,
  proto.external.TenantActivityFeedsResponse,
  /**
   * @param {!proto.external.TenantActivityFeedsByAssetIDRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.external.TenantActivityFeedsResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.external.TenantActivityFeedsByAssetIDRequest,
 *   !proto.external.TenantActivityFeedsResponse>}
 */
const methodInfo_TenantActivityFeedService_GetActivityFeedsByAsset = new grpc.web.AbstractClientBase.MethodInfo(
  proto.external.TenantActivityFeedsResponse,
  /**
   * @param {!proto.external.TenantActivityFeedsByAssetIDRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.external.TenantActivityFeedsResponse.deserializeBinary
);


/**
 * @param {!proto.external.TenantActivityFeedsByAssetIDRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.external.TenantActivityFeedsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.external.TenantActivityFeedsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.external.TenantActivityFeedServiceClient.prototype.getActivityFeedsByAsset =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/external.TenantActivityFeedService/GetActivityFeedsByAsset',
      request,
      metadata || {},
      methodDescriptor_TenantActivityFeedService_GetActivityFeedsByAsset,
      callback);
};


/**
 * @param {!proto.external.TenantActivityFeedsByAssetIDRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.external.TenantActivityFeedsResponse>}
 *     Promise that resolves to the response
 */
proto.external.TenantActivityFeedServicePromiseClient.prototype.getActivityFeedsByAsset =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/external.TenantActivityFeedService/GetActivityFeedsByAsset',
      request,
      metadata || {},
      methodDescriptor_TenantActivityFeedService_GetActivityFeedsByAsset);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.external.TenantActivityFeedsByComponentRequest,
 *   !proto.external.TenantActivityFeedsResponse>}
 */
const methodDescriptor_TenantActivityFeedService_GetActivityFeedsByComponent = new grpc.web.MethodDescriptor(
  '/external.TenantActivityFeedService/GetActivityFeedsByComponent',
  grpc.web.MethodType.UNARY,
  proto.external.TenantActivityFeedsByComponentRequest,
  proto.external.TenantActivityFeedsResponse,
  /**
   * @param {!proto.external.TenantActivityFeedsByComponentRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.external.TenantActivityFeedsResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.external.TenantActivityFeedsByComponentRequest,
 *   !proto.external.TenantActivityFeedsResponse>}
 */
const methodInfo_TenantActivityFeedService_GetActivityFeedsByComponent = new grpc.web.AbstractClientBase.MethodInfo(
  proto.external.TenantActivityFeedsResponse,
  /**
   * @param {!proto.external.TenantActivityFeedsByComponentRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.external.TenantActivityFeedsResponse.deserializeBinary
);


/**
 * @param {!proto.external.TenantActivityFeedsByComponentRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.external.TenantActivityFeedsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.external.TenantActivityFeedsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.external.TenantActivityFeedServiceClient.prototype.getActivityFeedsByComponent =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/external.TenantActivityFeedService/GetActivityFeedsByComponent',
      request,
      metadata || {},
      methodDescriptor_TenantActivityFeedService_GetActivityFeedsByComponent,
      callback);
};


/**
 * @param {!proto.external.TenantActivityFeedsByComponentRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.external.TenantActivityFeedsResponse>}
 *     Promise that resolves to the response
 */
proto.external.TenantActivityFeedServicePromiseClient.prototype.getActivityFeedsByComponent =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/external.TenantActivityFeedService/GetActivityFeedsByComponent',
      request,
      metadata || {},
      methodDescriptor_TenantActivityFeedService_GetActivityFeedsByComponent);
};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.external.TenantManagerTicketServiceClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options['format'] = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.external.TenantManagerTicketServicePromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options['format'] = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.external.CreateTenantManagerTicketRequest,
 *   !proto.external.CreateTenantManagerTicketResponse>}
 */
const methodDescriptor_TenantManagerTicketService_CreateTenantManagerTicket = new grpc.web.MethodDescriptor(
  '/external.TenantManagerTicketService/CreateTenantManagerTicket',
  grpc.web.MethodType.UNARY,
  proto.external.CreateTenantManagerTicketRequest,
  proto.external.CreateTenantManagerTicketResponse,
  /**
   * @param {!proto.external.CreateTenantManagerTicketRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.external.CreateTenantManagerTicketResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.external.CreateTenantManagerTicketRequest,
 *   !proto.external.CreateTenantManagerTicketResponse>}
 */
const methodInfo_TenantManagerTicketService_CreateTenantManagerTicket = new grpc.web.AbstractClientBase.MethodInfo(
  proto.external.CreateTenantManagerTicketResponse,
  /**
   * @param {!proto.external.CreateTenantManagerTicketRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.external.CreateTenantManagerTicketResponse.deserializeBinary
);


/**
 * @param {!proto.external.CreateTenantManagerTicketRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.external.CreateTenantManagerTicketResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.external.CreateTenantManagerTicketResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.external.TenantManagerTicketServiceClient.prototype.createTenantManagerTicket =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/external.TenantManagerTicketService/CreateTenantManagerTicket',
      request,
      metadata || {},
      methodDescriptor_TenantManagerTicketService_CreateTenantManagerTicket,
      callback);
};


/**
 * @param {!proto.external.CreateTenantManagerTicketRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.external.CreateTenantManagerTicketResponse>}
 *     Promise that resolves to the response
 */
proto.external.TenantManagerTicketServicePromiseClient.prototype.createTenantManagerTicket =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/external.TenantManagerTicketService/CreateTenantManagerTicket',
      request,
      metadata || {},
      methodDescriptor_TenantManagerTicketService_CreateTenantManagerTicket);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.external.GetTenantManagerTicketRequest,
 *   !proto.external.GetTenantManagerTicketResponse>}
 */
const methodDescriptor_TenantManagerTicketService_GetTenantManagerTicket = new grpc.web.MethodDescriptor(
  '/external.TenantManagerTicketService/GetTenantManagerTicket',
  grpc.web.MethodType.UNARY,
  proto.external.GetTenantManagerTicketRequest,
  proto.external.GetTenantManagerTicketResponse,
  /**
   * @param {!proto.external.GetTenantManagerTicketRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.external.GetTenantManagerTicketResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.external.GetTenantManagerTicketRequest,
 *   !proto.external.GetTenantManagerTicketResponse>}
 */
const methodInfo_TenantManagerTicketService_GetTenantManagerTicket = new grpc.web.AbstractClientBase.MethodInfo(
  proto.external.GetTenantManagerTicketResponse,
  /**
   * @param {!proto.external.GetTenantManagerTicketRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.external.GetTenantManagerTicketResponse.deserializeBinary
);


/**
 * @param {!proto.external.GetTenantManagerTicketRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.external.GetTenantManagerTicketResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.external.GetTenantManagerTicketResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.external.TenantManagerTicketServiceClient.prototype.getTenantManagerTicket =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/external.TenantManagerTicketService/GetTenantManagerTicket',
      request,
      metadata || {},
      methodDescriptor_TenantManagerTicketService_GetTenantManagerTicket,
      callback);
};


/**
 * @param {!proto.external.GetTenantManagerTicketRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.external.GetTenantManagerTicketResponse>}
 *     Promise that resolves to the response
 */
proto.external.TenantManagerTicketServicePromiseClient.prototype.getTenantManagerTicket =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/external.TenantManagerTicketService/GetTenantManagerTicket',
      request,
      metadata || {},
      methodDescriptor_TenantManagerTicketService_GetTenantManagerTicket);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.external.GetTenantManagerTicketsByTenantIDRequest,
 *   !proto.external.GetTenantManagerTicketsByTenantIDResponse>}
 */
const methodDescriptor_TenantManagerTicketService_GetTenantManagerTicketsByTenantID = new grpc.web.MethodDescriptor(
  '/external.TenantManagerTicketService/GetTenantManagerTicketsByTenantID',
  grpc.web.MethodType.UNARY,
  proto.external.GetTenantManagerTicketsByTenantIDRequest,
  proto.external.GetTenantManagerTicketsByTenantIDResponse,
  /**
   * @param {!proto.external.GetTenantManagerTicketsByTenantIDRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.external.GetTenantManagerTicketsByTenantIDResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.external.GetTenantManagerTicketsByTenantIDRequest,
 *   !proto.external.GetTenantManagerTicketsByTenantIDResponse>}
 */
const methodInfo_TenantManagerTicketService_GetTenantManagerTicketsByTenantID = new grpc.web.AbstractClientBase.MethodInfo(
  proto.external.GetTenantManagerTicketsByTenantIDResponse,
  /**
   * @param {!proto.external.GetTenantManagerTicketsByTenantIDRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.external.GetTenantManagerTicketsByTenantIDResponse.deserializeBinary
);


/**
 * @param {!proto.external.GetTenantManagerTicketsByTenantIDRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.external.GetTenantManagerTicketsByTenantIDResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.external.GetTenantManagerTicketsByTenantIDResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.external.TenantManagerTicketServiceClient.prototype.getTenantManagerTicketsByTenantID =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/external.TenantManagerTicketService/GetTenantManagerTicketsByTenantID',
      request,
      metadata || {},
      methodDescriptor_TenantManagerTicketService_GetTenantManagerTicketsByTenantID,
      callback);
};


/**
 * @param {!proto.external.GetTenantManagerTicketsByTenantIDRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.external.GetTenantManagerTicketsByTenantIDResponse>}
 *     Promise that resolves to the response
 */
proto.external.TenantManagerTicketServicePromiseClient.prototype.getTenantManagerTicketsByTenantID =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/external.TenantManagerTicketService/GetTenantManagerTicketsByTenantID',
      request,
      metadata || {},
      methodDescriptor_TenantManagerTicketService_GetTenantManagerTicketsByTenantID);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.external.UpdateTenantManagerTicketRequest,
 *   !proto.google.protobuf.Empty>}
 */
const methodDescriptor_TenantManagerTicketService_UpdateTenantManagerTicket = new grpc.web.MethodDescriptor(
  '/external.TenantManagerTicketService/UpdateTenantManagerTicket',
  grpc.web.MethodType.UNARY,
  proto.external.UpdateTenantManagerTicketRequest,
  google_protobuf_empty_pb.Empty,
  /**
   * @param {!proto.external.UpdateTenantManagerTicketRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  google_protobuf_empty_pb.Empty.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.external.UpdateTenantManagerTicketRequest,
 *   !proto.google.protobuf.Empty>}
 */
const methodInfo_TenantManagerTicketService_UpdateTenantManagerTicket = new grpc.web.AbstractClientBase.MethodInfo(
  google_protobuf_empty_pb.Empty,
  /**
   * @param {!proto.external.UpdateTenantManagerTicketRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  google_protobuf_empty_pb.Empty.deserializeBinary
);


/**
 * @param {!proto.external.UpdateTenantManagerTicketRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.google.protobuf.Empty)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.google.protobuf.Empty>|undefined}
 *     The XHR Node Readable Stream
 */
proto.external.TenantManagerTicketServiceClient.prototype.updateTenantManagerTicket =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/external.TenantManagerTicketService/UpdateTenantManagerTicket',
      request,
      metadata || {},
      methodDescriptor_TenantManagerTicketService_UpdateTenantManagerTicket,
      callback);
};


/**
 * @param {!proto.external.UpdateTenantManagerTicketRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.google.protobuf.Empty>}
 *     Promise that resolves to the response
 */
proto.external.TenantManagerTicketServicePromiseClient.prototype.updateTenantManagerTicket =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/external.TenantManagerTicketService/UpdateTenantManagerTicket',
      request,
      metadata || {},
      methodDescriptor_TenantManagerTicketService_UpdateTenantManagerTicket);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.external.DeleteTenantManagerTicketRequest,
 *   !proto.google.protobuf.Empty>}
 */
const methodDescriptor_TenantManagerTicketService_DeleteTenantManagerTicket = new grpc.web.MethodDescriptor(
  '/external.TenantManagerTicketService/DeleteTenantManagerTicket',
  grpc.web.MethodType.UNARY,
  proto.external.DeleteTenantManagerTicketRequest,
  google_protobuf_empty_pb.Empty,
  /**
   * @param {!proto.external.DeleteTenantManagerTicketRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  google_protobuf_empty_pb.Empty.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.external.DeleteTenantManagerTicketRequest,
 *   !proto.google.protobuf.Empty>}
 */
const methodInfo_TenantManagerTicketService_DeleteTenantManagerTicket = new grpc.web.AbstractClientBase.MethodInfo(
  google_protobuf_empty_pb.Empty,
  /**
   * @param {!proto.external.DeleteTenantManagerTicketRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  google_protobuf_empty_pb.Empty.deserializeBinary
);


/**
 * @param {!proto.external.DeleteTenantManagerTicketRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.google.protobuf.Empty)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.google.protobuf.Empty>|undefined}
 *     The XHR Node Readable Stream
 */
proto.external.TenantManagerTicketServiceClient.prototype.deleteTenantManagerTicket =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/external.TenantManagerTicketService/DeleteTenantManagerTicket',
      request,
      metadata || {},
      methodDescriptor_TenantManagerTicketService_DeleteTenantManagerTicket,
      callback);
};


/**
 * @param {!proto.external.DeleteTenantManagerTicketRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.google.protobuf.Empty>}
 *     Promise that resolves to the response
 */
proto.external.TenantManagerTicketServicePromiseClient.prototype.deleteTenantManagerTicket =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/external.TenantManagerTicketService/DeleteTenantManagerTicket',
      request,
      metadata || {},
      methodDescriptor_TenantManagerTicketService_DeleteTenantManagerTicket);
};


module.exports = proto.external;

