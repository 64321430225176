import React from 'react';

import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import ReactApexChart from 'components/Charts';
import Dropdown from 'components/Filters/Dropdown';
import PropTypes from 'prop-types';

const options = {
  chart: {
    height: 350,
    type: 'line',
    id: 'areachart-cash-manager',
    toolbar: {
      show: false
    }
  },
  dataLabels: {
    enabled: false
  },
  color: ['#5381EF'],
  markers: {
    size: 5,
    hover: {
      size: 9
    },
    colors: ['white'],
    strokeColors: ['#5381EF']
  },
  stroke: {
    curve: 'straight',
    colors: ['#5381EF'],
    width: 3
  },
  fill: {
    type: 'solid',
    colors: ['#F2F3F7']
  },
  grid: {
    padding: {
      right: 30,
      left: 20
    },
    xaxis: {
      lines: {
        show: false
      }
    },
    yaxis: {
      lines: {
        show: false
      }
    }
  },
  xaxis: {
    labels: {
      style: {
        fontSize: '16px',
        fontFamily: 'Open Sans, Regular',
        colors: ['#595959']
      }
    },
    lines: {
      show: false
    },
    axisTicks: {
      show: false
    },
    axisBorder: {
      show: true,
      color: 'black',
      height: 2,
      width: '100%',
      offsetX: 0,
      offsetY: 0
    }
  },
  yaxis: {
    floating: true,
    axisTicks: {
      show: false
    },
    axisBorder: {
      show: false
    },
    labels: {
      show: false
    },
    lines: {
      show: false
    }
  },
  labels: ['1 Jan', '5 Jan', '7 Jan', '10 Jan', '14 Jan', '18 Jan', '21 Jan', '27 Jan']
};

const useStyles = makeStyles((theme) => ({
  title: {
    fontFamily: theme.fonts.base
  },
  graphContainer: {
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column'
    }
  }
}));

// eslint-disable-next-line no-unused-vars
const AccountGraph = ({ data }) => {
  const [period, setPeriod] = React.useState(1);
  const [account, setAccount] = React.useState(1);
  const classes = useStyles();

  return (
    <Box pt={3} pb={1} maxHeight={420}>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        className={classes.graphContainer}
      >
        <Typography className={classes.title}>Overview</Typography>

        <Box>
          <Dropdown
            value={account}
            handleChange={(e) => setAccount(e.target.value)}
            label="Account"
            keyword="name"
            items={[
              { id: 1, name: 'All' },
              { id: 2, name: 'Account 1' },
              { id: 3, name: 'Account 2' },
              { id: 4, name: 'Account 3' }
            ]}
            small
          />
          <Dropdown
            value={period}
            handleChange={(e) => setPeriod(e.target.value)}
            label="Month"
            keyword="name"
            items={[
              { id: 1, name: 'January, 2020' },
              { id: 2, name: 'February, 2020' }
            ]}
            small
          />
        </Box>
      </Box>
      <ReactApexChart
        options={options}
        series={[
          {
            name: 'Accounts',
            data: [1000, 41000, 13500, 51000, 49000, 62000, 69000, 91000]
          }
        ]}
        type="area"
        height={300}
      />
    </Box>
  );
};

export default React.memo(AccountGraph);

AccountGraph.propTypes = {
  data: PropTypes.arrayOf(PropTypes.any).isRequired
};
