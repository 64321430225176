import React, { useMemo, Suspense, useEffect } from 'react';

import Badge from '@material-ui/core/Badge';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { Notifications } from '@material-ui/icons';
import Breadcrumps from 'components/Breadcrumps';
import Container from 'components/Container';
import Spinner from 'components/Spinner';
import { formatZillions } from 'helpers/utils';
import useUrlParams from 'hooks/useUrlParams';
import { useSelector, useDispatch } from 'react-redux';
import * as actions from 'store/actions';

import PortfoliosTable from './PortfolioTable';

const useStyles = makeStyles((theme) => ({
  icon: {
    color: '#CCCCCC'
  },
  root: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: 500,
    padding: theme.spacing(6)
  },
  badge: {
    minWidth: theme.spacing(2.2),
    height: theme.spacing(2.2),
    width: theme.spacing(2.2)
  }
}));

const Portfolio = () => {
  const classes = useStyles();
  const { roleIds, setRoles } = useUrlParams();
  const { portfolios, loading, error } = useSelector((state) => state.portfolios);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(actions.getPortfolios());
  }, []);

  const columns = useMemo(
    () => [
      {
        title: 'Portfolios',
        field: 'name',
        render: (rowData) => `${rowData.name} ${rowData.assigned ? '' : '(unassigned)'}`,
        customSort: (a, b) => a.name.localeCompare(b.name)
      },
      { title: 'No. of Assets', field: 'assetcount' },
      {
        title: 'Leased Area (m2)',
        field: 'glatotal',
        render: (rowData) => formatZillions(rowData.glatotal)
      },
      {
        title: 'Notifications',
        field: 'notifs',
        render: (rowData) => (
          <Badge
            badgeContent={rowData.alertcount}
            color="error"
            overlap="circular"
            classes={{ badge: classes.badge }}
            onClick={(e) => {
              e.stopPropagation();

              dispatch(
                actions.setAlertFilters({
                  asset: '',
                  portfolio: rowData.id,
                  tenant: ''
                })
              );

              dispatch(actions.toggleAlertMenu());
            }}
          >
            <Notifications className={classes.icon} />
          </Badge>
        )
      }
    ],
    []
  );

  if (error) {
    return (
      <div className={classes.root}>
        <Typography color="error" variant="h5">
          {error}
        </Typography>
      </div>
    );
  }

  return (
    <Container>
      <Suspense fallback={<Spinner mini />}>
        <Breadcrumps />
        <PortfoliosTable
          title={`Portfolios (${portfolios.length})`}
          columns={columns}
          data={portfolios}
          loading={loading}
          roleIds={roleIds}
          setRoles={setRoles}
        />
      </Suspense>
    </Container>
  );
};

export default Portfolio;
