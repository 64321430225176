import { useState, useMemo, useEffect } from 'react';

import { storageService, ref, listAll, getDownloadURL, getMetadata } from 'config/firebase';

const storageRef = ref(storageService);

const useFireStorage = (pathName) => {
  const listRef = ref(storageRef, pathName);
  const [filesLoading, setFilesLoading] = useState(false);
  const [filesloaded, setFilesloaded] = useState({ files: [] });

  const listStorageFiles = () => {
    setFilesloaded({ files: [] });
    setFilesLoading(true);
    listAll(listRef)
      .then(async (result) => {
        const promisses = await result.items.map(async (fileRef) => {
          const fileName = await getDownloadURL(fileRef);
          const fileDate = await getMetadata(fileRef).then((metadata) => metadata.updated);

          return { name: fileRef.name, url: fileName, dateUploaded: fileDate };
        });

        return Promise.all(promisses).then((res) => setFilesloaded({ files: res }));
      })
      .finally(() => {
        setFilesLoading(false);
      });
  };

  useEffect(() => {
    const unsubscribe = () => listStorageFiles();
    unsubscribe();

    return () => unsubscribe();
  }, []);

  return useMemo(
    () => ({
      filesLoading,
      files: filesloaded,
      refresh: listStorageFiles
    }),
    [filesloaded, filesLoading]
  );
};

export default useFireStorage;
