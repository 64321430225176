/* eslint-disable import/no-dynamic-require */
import React from 'react';

import PropTypes from 'prop-types';

function CustomIcon({ name, width, height }) {
  // eslint-disable-next-line global-require
  const getIcon = (icon) => require(`assets/${icon}.svg`);

  return <img src={getIcon(name)} alt="Icon" width={width} height={height} />;
}

export default CustomIcon;

CustomIcon.propTypes = {
  name: PropTypes.string.isRequired,
  width: PropTypes.oneOf([PropTypes.string, PropTypes.number]),
  height: PropTypes.oneOf([PropTypes.string, PropTypes.number])
};

CustomIcon.defaultProps = {
  width: 24,
  height: 24
};
