import * as actionTypes from '../actions/actionTypes';

export const legalTrackerInitialState = {
  legalTracker: {},
  loading: false,
  loaded: false,
  error: null,
  byId: {},
  allIds: [],
  details: {
    loading: false,
    loaded: false,
    error: null,
    details: {},
    activityFeed: []
  }
};

const reducer = (state = legalTrackerInitialState, action) => {
  switch (action.type) {
    case actionTypes.GET_TENANT_LEGAL_TRACKER_START:
      return {
        ...state,
        loading: true
      };
    case actionTypes.GET_TENANT_LEGAL_TRACKER_SUCCESS:
      const { legalTracker, bundleId } = action;

      return {
        ...state,
        loading: false,
        loaded: true,
        legalTracker,
        byId: { ...state.byId, [bundleId]: legalTracker },
        allIds: [...state.allIds, bundleId]
      };
    case actionTypes.SET_LEGAL_TRACKER:
      return {
        ...state,
        legalTracker: state.byId[action.bundleId]
      };
    case actionTypes.GET_TENANT_LEGAL_TRACKER_FAIL:
      return {
        ...state,
        loading: false,
        loaded: true,
        error: action.error
      };

    case actionTypes.GET_TENANT_LEGAL_TRACKER_CASE_START:
      return {
        ...state,
        details: {
          ...state.details,
          loading: true
        }
      };
    case actionTypes.GET_TENANT_LEGAL_TRACKER_CASE_SUCCESS:
      return {
        ...state,
        details: {
          ...state.details,
          loading: false,
          loaded: true,
          details: action.details
        }
      };
    case actionTypes.GET_TENANT_LEGAL_TRACKER__CASE_FAIL:
      return {
        ...state,
        details: {
          ...state.details,
          loading: false,
          loaded: true,
          error: action.error
        }
      };
    default:
      return state;
  }
};

export default reducer;
