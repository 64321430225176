/* eslint-disable indent */

import { formatForBackEnd } from 'helpers/utils';
import { v4 as uuidv4 } from 'uuid';

export const getForm = (details, form) => ({
  contractType: 'Supplier',
  startdate: details?.startdate || new Date(),
  enddate: details?.enddate || new Date(),
  supplier: {
    frequency: form.supplier?.schedule?.frequency || 'Monthly',
    paymentTerms: form.supplier?.paymentTerms || 'ScheduledPayment',
    scheduledPayment: {
      paymentAmount: form.supplier?.scheduledPayment?.paymentAmount || 1000,
      paymentDate: form.supplier?.scheduledPayment?.paymentDate || new Date()
    },
    regularPayment: {
      paymentAmount: form.supplier?.schedule?.paymentAmount || 1000
    },
    schedule: {
      payments:
        form.supplier?.schedule.length > 0
          ? form.supplier.schedule
          : [
              {
                paymentID: uuidv4(),
                paymentAmount: 1000,
                paymentDate: formatForBackEnd(new Date())
              }
            ]
    }
  },
  loanAgreement: {
    loanType: form.loanAgreement?.loanType || 'InterestOnly',
    schedule: {
      payments: form.loanAgreement?.schedule.payments || []
    },
    ltv: form.loanAgreement?.ltv || 1,
    originalLoanBalance: form.loanAgreement?.originalLoanBalance || 1000,
    currentLoanBalance: form.loanAgreement?.currentLoanBalance || 1000,
    currentBalanceDate: formatForBackEnd(form.loanAgreement?.currentBalanceDate) || new Date(),
    interestBasis: form.loanAgreement?.interestBasis || 1,
    interestMargin: form.loanAgreement?.interestMargin || 1,
    allInRate: form.loanAgreement?.allInRate || 1,
    loanDescription: form.loanAgreement?.loanDescription || '',
    arrangementFees: form.loanAgreement?.arrangementFees || 4,
    otherFees: form.loanAgreement?.otherFees || 400,
    FeesDescription: form.loanAgreement?.feesDescription || ''
  }
});
