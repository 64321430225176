import React from 'react';

import Box from '@material-ui/core/Box';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import TextField from '@material-ui/core/TextField';
import CancelButton from 'components/Buttons/CancelButton';
import SaveButton from 'components/Buttons/SaveButton';
import DatePicker from 'components/DatePicker/FormikPicker';
import ModalWrapper from 'components/Modal/FormikModalWrapper';
import { closeModal } from 'components/Modal/modalReducer';
import { Formik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import * as actions from 'store/actions';
import useStyles from 'styles/forms';
import * as Yup from 'yup';

const getForm = (form) => ({
  valuation: '',
  date: new Date(),
  valuer: '',
  marketvalue: '',
  liquidationvalue: '',
  summary: '',
  assetid: '',
  id: '',
  ...form
});

const validationSchema = Yup.object({
  valuation: Yup.string().required('Valuation is Required!'),
  date: Yup.date().required('Valuation Date is Required!'),
  valuer: Yup.string().required('Valuer is Required!'),
  marketvalue: Yup.string().required('Market Value is Required!'),
  liquidationvalue: Yup.string(),
  summary: Yup.string(),
  assetid: Yup.string(),
  id: Yup.string()
});

const AddValuation = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { valuations, fetch } = useSelector((state) => state.modals.modalProps);
  const loading = useSelector((state) => state.valuations.loading);
  const { data: asset } = useSelector((state) => state.assets.assetDetails);

  const handleSave = (form) => {
    dispatch(actions.createValuation(form, asset.id))
      .then(() => {
        if (form.id) {
          dispatch(actions.getValuationsDetails(form.id));
          fetch();
        }
        dispatch(actions.getValuations(asset.id));
      })
      .then(() => {
        toast.success('Success');
        dispatch(closeModal());
      })
      .catch((err) => {
        toast.error(err);
      });
  };

  return (
    <ModalWrapper size="md" header={valuations?.id ? 'Edit Valuation' : 'New Valuation'} xl>
      <Formik
        initialValues={getForm(valuations)}
        validationSchema={validationSchema}
        onSubmit={(values) => {
          handleSave(values);
        }}
      >
        {({ values, isSubmitting, dirty, isValid, touched, errors, submitForm, handleChange }) => (
          <>
            <DialogContent>
              <TextField
                fullWidth
                label="Valuation name"
                variant="outlined"
                InputProps={{
                  classes: {
                    input: classes.input
                  }
                }}
                disabled={loading}
                required
                className={classes.textFieldXL}
                placeholder="Valuation 1"
                name="valuation"
                id="valuation"
                value={values.valuation}
                onChange={handleChange}
                error={touched.valuation && Boolean(errors.valuation)}
                helperText={touched.valuation && errors.valuation}
              />
              <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                className={classes.box}
              >
                <DatePicker label="Date of Valuation" name="date" id="date" />
                <TextField
                  fullWidth
                  label="Valuer"
                  variant="outlined"
                  required
                  className={classes.textField}
                  InputProps={{
                    classes: {
                      input: classes.input
                    }
                  }}
                  style={{ width: '48%' }}
                  disabled={loading}
                  placeholder="Valuer name"
                  name="valuer"
                  id="valuer"
                  value={values.valuer}
                  onChange={handleChange}
                  error={touched.valuer && Boolean(errors.valuer)}
                  helperText={touched.valuer && errors.valuer}
                />
              </Box>

              <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                className={classes.box}
              >
                <TextField
                  fullWidth
                  label="FMV (€)"
                  variant="outlined"
                  className={classes.textField}
                  InputProps={{
                    classes: {
                      input: classes.input
                    }
                  }}
                  type="number"
                  disabled={loading}
                  name="marketvalue"
                  required
                  id="marketvalue"
                  value={values.marketvalue}
                  onChange={handleChange}
                  error={touched.marketvalue && Boolean(errors.marketvalue)}
                  helperText={touched.marketvalue && errors.marketvalue}
                  style={{ width: '48%' }}
                />
                <TextField
                  fullWidth
                  label="Liquidation (€)"
                  variant="outlined"
                  className={classes.textField}
                  InputProps={{
                    classes: {
                      input: classes.input
                    }
                  }}
                  type="number"
                  disabled={loading}
                  name="liquidationvalue"
                  id="liquidationvalue"
                  value={values.liquidationvalue}
                  onChange={handleChange}
                  error={touched.liquidationvalue && Boolean(errors.liquidationvalue)}
                  helperText={touched.liquidationvalue && errors.liquidationvalue}
                  style={{ width: '48%' }}
                />
              </Box>

              <TextField
                fullWidth
                label="Report Summary"
                multiline
                variant="outlined"
                className={classes.textFieldXL}
                InputProps={{
                  classes: {
                    input: classes.input
                  }
                }}
                disabled={loading}
                name="summary"
                id="summary"
                rows={5}
                value={values.summary}
                onChange={handleChange}
                error={touched.summary && Boolean(errors.summary)}
                helperText={touched.summary && errors.summary}
                placeholder="Enter your report"
              />
            </DialogContent>
            <DialogActions>
              <Box p={2} display="flex">
                <CancelButton onClick={() => dispatch(closeModal())} />
                <SaveButton
                  type="submit"
                  loading={loading}
                  disabled={!dirty || isSubmitting || !isValid}
                  onClick={submitForm}
                  data-testid="valuations-save"
                />
              </Box>
            </DialogActions>
          </>
        )}
      </Formik>
    </ModalWrapper>
  );
};

export default AddValuation;
