// Logged User
export const GET_USER_START = 'GET_USER_START';
export const GET_USER_SUCCESS = 'GET_USER_SUCCESS';
export const GET_USER_FAIL = 'GET_USER_FAIL';
export const USER_SIGNOUT = 'USER_SIGNOUT';
export const SIGN_USER_SUCCESS = 'SIGN_USER_SUCCESS';
export const SIGN_USER_FAIL = 'SIGN_USER_FAIL';

// Roles
export const GET_USER_ROLES_START = 'GET_USER_ROLES_START';
export const GET_USER_ROLES_SUCCESS = 'GET_USER_ROLES_SUCCESS';
export const GET_USER_ROLES_FAIL = 'GET_USER_ROLES_FAIL';

// Organisations
export const GET_ORGANISATIONS_START = 'GET_ORGANISATIONS_START';
export const GET_ORGANISATIONS_SUCCESS = 'GET_ORGANISATIONS_SUCCESS';
export const GET_ORGANISATIONS_FAIL = 'GET_ORGANISATIONS_FAIL';

// Users
export const REGISTER_USER_START = 'REGISTER_USER_START';
export const REGISTER_USER_SUCCESS = 'REGISTER_USER_SUCCESS';
export const REGISTER_USER_FAIL = 'REGISTER_USER_FAIL';
export const REFRESH_USER_TOKEN = 'REFRESH_USER_TOKEN';

export const GET_USERS_START = 'GET_USERS_START';
export const GET_USERS_SUCCESS = 'GET_USERS_SUCCESS';
export const GET_USERS_FAIL = 'GET_USERS_FAIL';
export const SET_USERS_BUNDLE = 'SET_USERS_BUNDLE';

export const GET_USER_DETAILS_START = 'GET_USER_DETAILS_START';
export const GET_USER_DETAILS_SUCCESS = 'GET_USER_DETAILS_SUCCESS';
export const GET_USER_DETAILS_FAIL = 'GET_USER_DETAILS_FAIL';

export const UPDATE_USER_START = 'UPDATE_USER_START';
export const UPDATE_USER_SUCCESS = 'UPDATE_USER_SUCCESS';
export const UPDATE_USER_FAIL = 'UPDATE_USER_FAIL';

// Portfolios
export const GET_PORTFOLIOS_START = 'GET_PORTFOLIOS_START';
export const GET_PORTFOLIOS_SUCCESS = 'GET_PORTFOLIOS_SUCCESS';
export const GET_PORTFOLIOS_FAIL = 'GET_PORTFOLIOS_FAIL';
export const CREATE_PORTFOLIO = 'CREATE_PORTFOLIO';

// Assets
export const GET_ASSETS_START = 'GET_ASSETS_START';
export const GET_ASSETS_SUCCESS = 'GET_ASSETS_SUCCESS';
export const GET_ASSETS_FAIL = 'GET_ASSETS_FAIL';
export const SET_ASSETS_BUNDLE = 'SET_ASSETS_BUNDLE';
export const CREATE_ASSET = 'CREATE_ASSET';
export const GET_ASSETS_DONE = 'GET_ASSETS_DONE';

export const GET_TENANTS_BYASSETID_SUCCESS = 'GET_TENANTS_BYASSETID_SUCCESS';
export const GET_UNITS_BYASSETID_SUCCESS = 'GET_UNITS_BYASSETID_SUCCESS';

export const GET_ASSET_DETAILS_START = 'GET_ASSET_DETAILS_START';
export const GET_ASSET_DETAILS_SUCCESS = 'GET_ASSET_DETAILS_SUCCESS';
export const GET_ASSET_DETAILS_FAIL = 'GET_ASSET_DETAILS_FAIL';

export const GET_ASSET_KPIS_START = 'GET_ASSET_KPIS_START';
export const GET_ASSET_KPIS_SUCCESS = 'GET_ASSET_KPIS_SUCCESS';
export const GET_ASSET_KPIS_FAIL = 'GET_ASSET_KPIS_FAIL';

export const GET_UNITS_SCHEDULE_START = 'GET_UNITS_SCHEDULE_START';
export const GET_UNITS_SCHEDULE_SUCCESS = 'GET_UNITS_SCHEDULE_SUCCESS';
export const GET_UNITS_SCHEDULE_FAIL = 'GET_UNITS_SCHEDULE_FAIL';

export const GET_TENANCY_SCHEDULE_START = 'GET_TENANCY_SCHEDULE_START';
export const GET_TENANCY_SCHEDULE_SUCCESS = 'GET_TENANCY_SCHEDULE_SUCCESS';
export const GET_TENANCY_SCHEDULE_FAIL = 'GET_TENANCY_SCHEDULE_FAIL';

export const GET_TENANT_STACKING_PLAN_START = 'GET_TENANT_STACKING_PLAN_START';
export const GET_TENANT_STACKING_PLAN_SUCCESS = 'GET_TENANT_STACKING_PLAN_SUCCESS';
export const GET_TENANT_STACKING_PLAN_FAIL = 'GET_TENANT_STACKING_PLAN_FAIL';

export const GET_UNIT_STACKING_PLAN_START = 'GET_UNIT_STACKING_PLAN_START';
export const GET_UNIT_STACKING_PLAN_SUCCESS = 'GET_UNIT_STACKING_PLAN_SUCCESS';
export const GET_UNIT_STACKING_PLAN_FAIL = 'GET_UNIT_STACKING_PLAN_FAIL';

export const GET_FOOTFALL_START = 'GET_FOOTFALL_START';
export const GET_FOOTFALL_SUCCESS = 'GET_FOOTFALL_SUCCESS';
export const GET_FOOTFALL_FAIL = 'GET_FOOTFALL_FAIL';

// Tenants
export const GET_TENANTS_START = 'GET_TENANTS_START';
export const GET_TENANTS_SUCCESS = 'GET_TENANTS_SUCCESS';
export const GET_TENANTS_FAIL = 'GET_TENANTS_FAIL';
export const SET_TENANTS_BUNDLE = 'SET_TENANTS_BUNDLE';

export const GET_TENANTS_DETAILS_START = 'GET_TENANTS_DETAILS_START';
export const GET_TENANTS_DETAILS_SUCCESS = 'GET_TENANTS_DETAILS_SUCCESS';
export const GET_TENANTS_DETAILS_FAIL = 'GET_TENANTS_DETAILS_FAIL';

export const GET_TENANT_HEADER_START = 'GET_TENANT_HEADER_START';
export const GET_TENANT_HEADER_SUCCESS = 'GET_TENANT_HEADER_SUCCESS';
export const GET_TENANT_HEADER_FAIL = 'GET_TENANT_HEADER_FAIL';

export const GET_BREADCRUMPS_START = 'GET_BREADCRUMPS_START';
export const GET_BREADCRUMPS_SUCCESS = 'GET_BREADCRUMPS_SUCCESS';
export const GET_BREADCRUMPS_FAIL = 'GET_BREADCRUMPS_FAIL';

// Tenant Leasing Manager
export const GET_UNITS_START = 'GET_UNITS_START';
export const GET_UNITS_SUCCESS = 'GET_UNITS_SUCCESS';
export const GET_UNITS_FAIL = 'GET_UNITS_FAIL';

export const CREATE_LEASING_CONTRACT = 'CREATE_LEASING_CONTRACT';

export const GET_LEASING_CONTRACT_CLAUSES_START = 'GET_LEASING_CONTRACT_CLAUSES_START';
export const GET_LEASING_CONTRACT_CLAUSES_SUCCESS = 'GET_LEASING_CONTRACT_CLAUSES_SUCCESS';
export const GET_LEASING_CONTRACT_CLAUSES_FAIL = 'GET_LEASING_CONTRACT_CLAUSES_FAIL';

export const GET_TENANT_LEASING_CONTRACT_START = 'GET_TENANT_LEASING_CONTRACT_START';
export const GET_TENANT_LEASING_CONTRACT_SUCCESS = 'GET_TENANT_LEASING_CONTRACT_SUCCESS';
export const GET_TENANT_LEASING_CONTRACT_FAIL = 'GET_TENANT_LEASING_CONTRACT_FAIL';

export const GET_TENANT_LEASING_STRATEGY_START = 'GET_TENANT_LEASING_STRATEGY_START';
export const GET_TENANT_LEASING_STRATEGY_SUCCESS = 'GET_TENANT_LEASING_STRATEGY_SUCCESS';
export const GET_TENANT_LEASING_STRATEGY_FAIL = 'GET_TENANT_LEASING_STRATEGY_FAIL';

export const GET_TENANT_LEASING_OPTIONS_START = 'GET_TENANT_LEASING_OPTIONS_START';
export const GET_TENANT_LEASING_OPTIONS_SUCCESS = 'GET_TENANT_LEASING_OPTIONS_SUCCESS';
export const GET_TENANT_LEASING_OPTIONS_FAIL = 'GET_TENANT_LEASING_OPTIONS_FAIL';

export const GET_TENANT_LEASING_TURNOVER_START = 'GET_TENANT_LEASING_TURNOVER_START';
export const GET_TENANT_LEASING_TURNOVER_SUCCESS = 'GET_TENANT_LEASING_TURNOVER_SUCCESS';
export const GET_TENANT_LEASING_TURNOVER_FAIL = 'GET_TENANT_LEASING_TURNOVER_FAIL';

export const GET_TENANT_LEASING_BUDGET_START = 'GET_TENANT_LEASING_BUDGET_START';
export const GET_TENANT_LEASING_BUDGET_SUCCESS = 'GET_TENANT_LEASING_BUDGET_SUCCESS';
export const GET_TENANT_LEASING_BUDGET_FAIL = 'GET_TENANT_LEASING_BUDGET_FAIL';

export const GET_TENANT_LEASING_CASHFLOW_START = 'GET_TENANT_LEASING_CASHFLOW_START';
export const GET_TENANT_LEASING_CASHFLOW_SUCCESS = 'GET_TENANT_LEASING_CASHFLOW_SUCCESS';
export const GET_TENANT_LEASING_CASHFLOW_FAIL = 'GET_TENANT_LEASING_CASHFLOW_FAIL';

export const GET_TENANT_LEASING_CASHFLOW_BUDGET_START = 'GET_TENANT_LEASING_CASHFLOW_BUDGET_START';
export const GET_TENANT_LEASING_CASHFLOW_BUDGET_SUCCESS =
  'GET_TENANT_LEASING_CASHFLOW_BUDGET_SUCCESS';
export const GET_TENANT_LEASING_CASHFLOW_BUDGET_FAIL = 'GET_TENANT_LEASING_CASHFLOW_BUDGET_FAIL';

export const GET_TENANT_LEASING_DETAILS_START = 'GET_TENANT_LEASING_DETAILS_START';
export const GET_TENANT_LEASING_DETAILS_SUCCESS = 'GET_TENANT_LEASING_DETAILS_SUCCESS';
export const GET_TENANT_LEASING_DETAILS_FAIL = 'GET_TENANT_LEASING_DETAILS_FAIL';

// Tenant Cost Manager
export const GET_TENANT_COST_MANAGER_SUCCESS = 'GET_TENANT_COST_MANAGER_SUCCESS';
export const GET_TENANT_COST_MANAGER_START = 'GET_TENANT_COST_MANAGER_START';
export const GET_TENANT_COST_MANAGER_FAIL = 'GET_TENANT_COST_MANAGER_FAIL';

export const GET_TENANT_COST_MANAGER_DETAILS_START = 'GET_TENANT_COST_MANAGER_DETAILS_START';
export const GET_TENANT_COST_MANAGER_DETAILS_SUCCESS = 'GET_TENANT_COST_MANAGER_DETAILS_SUCCESS';
export const GET_TENANT_COST_MANAGER_DETAILS_FAIL = 'GET_TENANT_COST_MANAGER_DETAILS_FAIL';
export const GET_TENANT_COST_MANAGER_DETAILS = 'GET_TENANT_COST_MANAGER_DETAILS';

export const GET_TENANT_COST_PROCUREMENT_START = 'GET_TENANT_COST_PROCUREMENT_START';
export const GET_TENANT_COST_PROCUREMENT_SUCCESS = 'GET_TENANT_COST_PROCUREMENT_SUCCESS';
export const GET_TENANT_COST_PROCUREMENT_FAIL = 'GET_TENANT_COST_PROCUREMENT_FAIL';

export const GET_TENANT_COST_SUPPLIER_DETAILS = 'GET_TENANT_COST_SUPPLIER_DETAILS';
export const GET_TENANT_COST_CONTRACT_DETAILS = 'GET_TENANT_COST_CONTRACT_DETAILS';

export const UPDATE_TENANT_COST_MANAGER_START = 'UPDATE_TENANT_COST_MANAGER_START';
export const UPDATE_TENANT_COST_MANAGER_SUCCESS = 'UPDATE_TENANT_COST_MANAGER_SUCCESS';
export const UPDATE_TENANT_COST_MANAGER_FAIL = 'UPDATE_TENANT_COST_MANAGER_FAIL';

// Tenant Cash Manager
export const GET_TENANT_CASH_MANAGER_START = 'GET_TENANT_CASH_MANAGER_START';
export const GET_TENANT_CASH_MANAGER_SUCCESS = 'GET_TENANT_CASH_MANAGER_SUCCESS';
export const GET_TENANT_CASH_MANAGER_FAIL = 'GET_TENANT_CASH_MANAGER_FAIL';

// Asset Cash Manager
export const GET_ASSET_CASH_MANAGER_START = 'GET_ASSET_CASH_MANAGER_START';
export const GET_ASSET_CASH_MANAGER_SUCCESS = 'GET_ASSET_CASH_MANAGER_SUCCESS';
export const GET_ASSET_CASH_MANAGER_FAIL = 'GET_ASSET_CASH_MANAGER_FAIL';

// Tenant Legal Tracker
export const GET_TENANT_LEGAL_TRACKER_START = 'GET_TENANT_LEGAL_TRACKER_START';
export const GET_TENANT_LEGAL_TRACKER_SUCCESS = 'GET_TENANT_LEGAL_TRACKER_SUCCESS';
export const GET_TENANT_LEGAL_TRACKER_FAIL = 'GET_TENANT_LEGAL_TRACKER_FAIL';
export const SET_LEGAL_TRACKER = 'SET_LEGAL_TRACKER';

export const GET_TENANT_LEGAL_TRACKER_CASE_START = 'GET_TENANT_LEGAL_TRACKER_CASE_START';
export const GET_TENANT_LEGAL_TRACKER_CASE_SUCCESS = 'GET_TENANT_LEGAL_TRACKER_CASE_SUCCESS';
export const GET_TENANT_LEGAL_TRACKER__CASE_FAIL = 'GET_TENANT_LEGAL_TRACKER__CASE_FAIL';

// Asset Legal Tracker
export const GET_ASSET_LEGAL_TRACKER_START = 'GET_ASSET_LEGAL_TRACKER_START';
export const GET_ASSET_LEGAL_TRACKER_SUCCESS = 'GET_ASSET_LEGAL_TRACKER_SUCCESS';
export const GET_ASSET_LEGAL_TRACKER_FAIL = 'GET_ASSET_LEGAL_TRACKER_FAIL';
export const SET_ASSET_LEGAL_TRACKER = 'SET_ASSET_LEGAL_TRACKER';

export const GET_ASSET_LEGAL_TRACKER_CASE_START = 'GET_ASSET_LEGAL_TRACKER_CASE_START';
export const GET_ASSET_LEGAL_TRACKER_CASE_SUCCESS = 'GET_ASSET_LEGAL_TRACKER_CASE_SUCCESS';
export const GET_ASSET_LEGAL_TRACKER__CASE_FAIL = 'GET_ASSET_LEGAL_TRACKER__CASE_FAIL';

// HomeData
export const GET_HOMEDATA_START = 'GET_HOMEDATA_START';
export const GET_HOMEDATA_SUCCESS = 'GET_HOMEDATA_SUCCESS';
export const GET_HOMEDATA_FAIL = 'GET_HOMEDATA_FAIL';
export const GET_HOMEDATAYEARS_START = 'GET_HOMEDATAYEARS_START';
export const GET_HOMEDATAYEARS_SUCCESS = 'GET_HOMEDATAYEARS_SUCCESS';
export const GET_HOMEDATAYEARS_FAIL = 'GET_HOMEDATAYEARS_FAIL';
export const SET_YEAR_FILTER = 'SET_YEAR_FILTER';
export const SET_HOMEDATA_BUNDLE = 'SET_HOMEDATA_BUNDLE';
export const SHOW_TASKS = 'SHOW_TASKS';

// Asset Cost Manager
export const GET_ASSET_COST_MANAGER_SUCCESS = 'GET_ASSET_COST_MANAGER_SUCCESS';
export const GET_ASSET_COST_MANAGER_START = 'GET_ASSET_COST_MANAGER_START';
export const GET_ASSET_COST_MANAGER_FAIL = 'GET_ASSET_COST_MANAGER_FAIL';

export const GET_ASSET_COST_MANAGER_DETAILS_START = 'GET_ASSET_COST_MANAGER_DETAILS_START';
export const GET_ASSET_COST_MANAGER_DETAILS_SUCCESS = 'GET_ASSET_COST_MANAGER_DETAILS_SUCCESS';
export const GET_ASSET_COST_MANAGER_DETAILS_FAIL = 'GET_ASSET_COST_MANAGER_DETAILS_FAIL';
export const GET_ASSET_COST_MANAGER_DETAILS = 'GET_ASSET_COST_MANAGER_DETAILS';

export const GET_ASSET_COST_PROCUREMENT_START = 'GET_ASSET_COST_PROCUREMENT_START';
export const GET_ASSET_COST_PROCUREMENT_SUCCESS = 'GET_ASSET_COST_PROCUREMENT_SUCCESS';
export const GET_ASSET_COST_PROCUREMENT_FAIL = 'GET_ASSET_COST_PROCUREMENT_FAIL';

export const GET_ASSET_COST_SUPPLIER_DETAILS = 'GET_ASSET_COST_SUPPLIER_DETAILS';
export const GET_ASSET_COST_CONTRACT_DETAILS = 'GET_ASSET_COST_CONTRACT_DETAILS';

export const UPDATE_ASSET_COST_MANAGER_START = 'UPDATE_ASSET_COST_MANAGER_START';
export const UPDATE_ASSET_COST_MANAGER_SUCCESS = 'UPDATE_ASSET_COST_MANAGER_SUCCESS';
export const UPDATE_ASSET_COST_MANAGER_FAIL = 'UPDATE_ASSET_COST_MANAGER_FAIL';

// Asset Valuations
export const GET_VALUATIONS_START = 'GET_VALUATIONS_START';
export const GET_VALUATIONS_SUCCESS = 'GET_VALUATIONS_SUCCESS';
export const GET_VALUATIONS_FAIL = 'GET_VALUATIONS_FAIL';

export const GET_VALUATIONS_DETAILS_START = 'GET_VALUATIONS_DETAILS_START';
export const GET_VALUATIONS_DETAILS_SUCCESS = 'GET_VALUATIONS_DETAILS_SUCCESS';
export const GET_VALUATIONS_DETAILS_FAIL = 'GET_VALUATIONS_DETAILS_FAIL';

// Opex Manager
export const GET_OPEX_MANAGER_SUCCESS = 'GET_OPEX_MANAGER_SUCCESS';
export const GET_OPEX_MANAGER_START = 'GET_OPEX_MANAGER_START';
export const GET_OPEX_MANAGER_FAIL = 'GET_OPEX_MANAGER_FAIL';

export const GET_OPEX_MANAGER_DETAILS_START = 'GET_OPEX_MANAGER_DETAILS_START';
export const GET_OPEX_MANAGER_DETAILS_SUCCESS = 'GET_OPEX_MANAGER_DETAILS_SUCCESS';
export const GET_OPEX_MANAGER_DETAILS_FAIL = 'GET_OPEX_MANAGER_DETAILS_FAIL';

export const GET_OPEX_PROCUREMENT_START = 'GET_OPEX_PROCUREMENT_START';
export const GET_OPEX_PROCUREMENT_SUCCESS = 'GET_OPEX_PROCUREMENT_SUCCESS';
export const GET_OPEX_PROCUREMENT_FAIL = 'GET_OPEX_PROCUREMENT_FAIL';

export const GET_OPEX_SUPPLIER_DETAILS = 'GET_OPEX_SUPPLIER_DETAILS';
export const GET_OPEX_CONTRACT_DETAILS = 'GET_OPEX_CONTRACT_DETAILS';

// Reports
export const GET_REPORTS_START = 'GET_REPORTS_START';
export const GET_REPORTS_SUCCESS = 'GET_REPORTS_SUCCESS';
export const GET_REPORTS_FAIL = 'GET_REPORTS_FAIL';

export const GET_FOOTFALL_REPORT_START = 'GET_FOOTFALL_REPORT_START';
export const GET_FOOTFALL_REPORT_SUCCESS = 'GET_FOOTFALL_REPORT_SUCCESS';
export const GET_FOOTFALL_REPORT_FAIL = 'GET_FOOTFALL_REPORT_FAIL';

export const GET_TURNOVER_REPORT_START = 'GET_TURNOVER_REPORT_START';
export const GET_TURNOVER_REPORT_SUCCESS = 'GET_TURNOVER_REPORT_SUCCESS';
export const GET_TURNOVER_REPORT_FAIL = 'GET_TURNOVER_REPORT_FAIL';
export const SET_TURNOVER_REPORT_BUNDLE = 'SET_TURNOVER_REPORT_BUNDLE';

export const GET_TENANT_PERFORMANCE_REPORT_START = 'GET_TENANT_PERFORMANCE_REPORT_START';
export const GET_TENANT_PERFORMANCE_REPORT_SUCCESS = 'GET_TENANT_PERFORMANCE_REPORT_SUCCESS';
export const GET_TENANT_PERFORMANCE_REPORT_FAIL = 'GET_TENANT_PERFORMANCE_REPORT_FAIL';
export const SET_TENANT_PERFORMANCE_REPORT_BUNDLE = 'SET_TENANT_PERFORMANCE_REPORT_BUNDLE';

export const GET_ASSET_PERFORMANCE_REPORT_START = 'GET_ASSET_PERFORMANCE_REPORT_START';
export const GET_ASSET_PERFORMANCE_REPORT_SUCCESS = 'GET_ASSET_PERFORMANCE_REPORT_SUCCESS';
export const GET_ASSET_PERFORMANCE_REPORT_FAIL = 'GET_ASSET_PERFORMANCE_REPORT_FAIL';
export const SET_ASSETS_PERFORMANCE_EPORT_BUNDLE = 'SET_ASSETS_PERFORMANCE_EPORT_BUNDLE';

// Reset
export const RESET_REPORTS = 'RESET_REPORTS';
export const RESET_TENANT_COST_MANAGER = 'RESET_TENANT_COST_MANAGER';
export const RESET_ASSET_DETAILS_STATE = 'RESET_ASSET_DETAILS_STATE';
export const RESET_ACTUALS_STATE = 'RESET_ACTUALS_STATE';
export const RESET_OPEX_COST_MANAGER = 'RESET_OPEX_COST_MANAGER';
export const RESET_ASSET_COST_MANAGER = 'RESET_ASSET_COST_MANAGER';
export const RESET_VALUATIONS_STATE = 'RESET_VALUATIONS_STATE';
export const RESET_MLA_STATE = 'RESET_MLA_STATE';
export const RESET_GEO_DATA_STATE = 'RESET_GEO_DATA_STATE';

// Alerts
export const ALERT_MESSAGE_RECEIVED = 'ALERT_MESSAGE_RECEIVED';
export const ALERT_SERVICE_ERROR = 'ALERT_SERVICE_ERROR';
export const GET_ALERT_LIST_START = 'GET_ALERT_LIST_START';
export const GET_ALERT_LIST_SUCCESS = 'GET_ALERT_LIST_SUCCESS';
export const GET_ALERT_LIST_FAIL = 'GET_ALERT_LIST_FAIL';
export const ALERT_MESSAGE_HIDE = 'ALERT_MESSAGE_HIDE';
export const SET_ALERT_FILTERS = 'SET_ALERT_FILTERS';
export const TOGGLE_ALERT_MENU = 'TOGGLE_ALERT_MENU';

// Facility Manager
export const GET_ASSET_FACILITY_MANAGER_START = 'GET_ASSET_FACILITY_MANAGER_START';
export const GET_ASSET_FACILITY_MANAGER_SUCCESS = 'GET_ASSET_FACILITY_MANAGER_SUCCESS';
export const GET_ASSET_FACILITY_MANAGER_FAIL = 'GET_ASSET_FACILITY_MANAGER_FAIL';
export const GET_ASSET_FACILITY_MANAGER_CASE_START = 'GET_ASSET_FACILITY_MANAGER_CASE_START';
export const GET_ASSET_FACILITY_MANAGER_CASE_SUCCESS = 'GET_ASSET_FACILITY_MANAGER_CASE_SUCCESS';
export const GET_ASSET_FACILITY_MANAGER__CASE_FAIL = 'GET_ASSET_FACILITY_MANAGER__CASE_FAIL';

// Tenant Manager
export const GET_TENANT_MANAGER_START = 'GET_TENANT_MANAGER_START';
export const GET_TENANT_MANAGER_SUCCESS = 'GET_TENANT_MANAGER_SUCCESS';
export const GET_TENANT_MANAGER_FAIL = 'GET_TENANT_MANAGER_FAIL';
export const GET_TENANT_MANAGER_CASE_START = 'GET_TENANT_MANAGER_CASE_START';
export const GET_TENANT_MANAGER_CASE_SUCCESS = 'GET_TENANT_MANAGER_CASE_SUCCESS';
export const GET_TENANT_MANAGER__CASE_FAIL = 'GET_TENANT_MANAGER__CASE_FAIL';

// Actuals
export const GET_ACTUALS_LIST_START = 'GET_ACTUALS_LIST_START';
export const GET_ACTUALS_LIST_SUCCESS = 'GET_ACTUALS_LIST_SUCCESS';
export const GET_ACTUALS_LIST_FAIL = 'GET_ACTUALS_LIST_FAIL';

// Asset Images
export const GET_IMAGES_LIST_START = 'GET_IMAGES_LIST_START';
export const GET_IMAGES_LIST_SUCCESS = 'GET_IMAGES_LIST_SUCCESS';
export const GET_IMAGES_LIST_FAIL = 'GET_IMAGES_LIST_FAIL';

export const UPDATE_PATH = 'UPDATE_PATH';

// Chart Data
export const GET_CHART_DATA_START = 'GET_CHART_DATA_START';
export const GET_CHART_DATA_SUCCESS = 'GET_CHART_DATA_SUCCESS';
export const GET_CHART_DATA_FAIL = 'GET_CHART_DATA_FAIL';

export const GET_CHART_DATA_PAGE_START = 'GET_CHART_DATA_PAGE_START';
export const GET_CHART_DATA_PAGE_SUCCESS = 'GET_CHART_DATA_PAGE_SUCCESS';
export const GET_CHART_DATA_PAGE_FAIL = 'GET_CHART_DATA_PAGE_FAIL';

// Geo Data
export const GET_GEO_DATA_START = 'GET_GEO_DATA_START';
export const GET_GEO_DATA_SUCCESS = 'GET_GEO_DATA_SUCCESS';
export const GET_GEO_DATA_FAIL = 'GET_GEO_DATA_FAIL';

// Summary Table
export const GET_SUMMARY_TABLE_START = 'GET_SUMMARY_TABLE_START';
export const GET_SUMMARY_TABLE_SUCCESS = 'GET_SUMMARY_TABLE_SUCCESS';
export const GET_SUMMARY_TABLE_FAIL = 'GET_SUMMARY_TABLE_FAIL';

// Tenants Forecasts
export const GET_TENANTS_FORECAST_START = 'GET_TENANTS_FORECAST_START';
export const GET_TENANTS_FORECAST_SUCCESS = 'GET_TENANTS_FORECAST_SUCCESS';
export const GET_TENANTS_FORECAST_FAIL = 'GET_TENANTS_FORECAST_FAIL';

// Tenants Actuals
export const GET_TENANTS_ACTUALS_START = 'GET_TENANTS_ACTUALS_START';
export const GET_TENANTS_ACTUALS_SUCCESS = 'GET_TENANTS_ACTUALS_SUCCESS';
export const GET_TENANTS_ACTUALS_FAIL = 'GET_TENANTS_ACTUALS_FAIL';

// MLA
export const GET_UNIT_MLA_START = 'GET_UNIT_MLA_START';
export const GET_UNIT_MLA_SUCCESS = 'GET_UNIT_MLA_SUCCESS';
export const GET_UNIT_MLA_FAIL = 'GET_UNIT_MLA_FAIL';
