import React from 'react';

import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import ApexChart from 'components/Charts';
import { numberWithCommas } from 'helpers/utils';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';

import { config } from './chartConfig';
import useStyles from './styles';

const GraphCard = ({ dataCy, to, from, totalValue, prevTotalValue, series, name }) => {
  const classes = useStyles();

  return (
    <Box data-cy={dataCy}>
      <Paper className={classes.paper}>
        <Box style={{ padding: '10px 20px' }}>
          <NavLink
            to={{
              pathname: to,
              search: from
            }}
            className={classes.navLink}
          >
            <Box display="flex" justifyContent="space-between" alignItems="center">
              <Typography variant="h6" style={{ color: '#555' }} className={classes.chartName}>
                {name}
              </Typography>
              <MoreVertIcon style={{ color: '#666' }} />
            </Box>
          </NavLink>
          <Box className={classes.wrapCard}>
            <Box className={classes.wrapHeading}>
              <Typography variant="body1">{`€${numberWithCommas(totalValue)}`}</Typography>
              <Typography className={classes.fromText}>
                {`from €${numberWithCommas(prevTotalValue)}`}
              </Typography>
            </Box>
          </Box>
        </Box>
        <ApexChart options={config.options} series={series} />
      </Paper>
    </Box>
  );
};

export default GraphCard;

GraphCard.defaultProps = {
  totalValue: 0,
  prevTotalValue: 0,
  name: '',
  series: [],
  dataCy: ''
};

GraphCard.propTypes = {
  totalValue: PropTypes.number,
  prevTotalValue: PropTypes.number,
  name: PropTypes.string,
  series: PropTypes.arrayOf(PropTypes.object),
  dataCy: PropTypes.string,
  to: PropTypes.string.isRequired,
  from: PropTypes.string.isRequired
};
