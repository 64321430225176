import { metaData, Empty } from 'config/config';
import wrappers from 'google-protobuf/google/protobuf/wrappers_pb';

import {
  PortfolioCreateRequest,
  PortfolioUpdateRequest,
  OrganizationCreateRequest,
  OrganizationDeleteRequest,
  PortfolioDeleteRequest
} from '../../api/organization_grpc_web_pb';
import { AggregationService } from '../services/aggregation';
import { OrganizationService, PortfolioService } from '../services/organization';

import * as actionTypes from './actionTypes';

const getPortfoliosStart = () => ({
  type: actionTypes.GET_PORTFOLIOS_START
});

const getPortfoliosSuccess = (portfolios) => ({
  type: actionTypes.GET_PORTFOLIOS_SUCCESS,
  portfolios
});

const getPortfoliosFail = (error) => ({
  type: actionTypes.GET_PORTFOLIOS_FAIL,
  error
});

export const getPortfolios = () => async (dispatch) => {
  dispatch(getPortfoliosStart());

  try {
    const result = await AggregationService.getPortfoliosAggregation(Empty, metaData());
    const response = result.toObject().portfoliosList;

    dispatch(getPortfoliosSuccess(response));
    return response;
  } catch (error) {
    dispatch(getPortfoliosFail(error.message));
    throw error.message;
  }
};

export const createPortfolio = (form, organizationId) => () => {
  if (!form.id) {
    const request = new PortfolioCreateRequest();
    request.setName(form.name);

    request.setOrganizationid(organizationId);

    return new Promise((resolve, reject) => {
      PortfolioService.create(request, metaData(), (err, response) => {
        if (err) {
          reject(err.message);
        } else {
          resolve(response.toObject());
        }
      });
    });
  }

  const request = new PortfolioUpdateRequest();
  const text = new wrappers.StringValue();
  text.setValue(form.name);

  request.setId(form.id);
  request.setName(text);

  return new Promise((resolve, reject) => {
    PortfolioService.update(request, metaData(), (err, response) => {
      if (err) {
        reject(err.message);
      } else {
        resolve(response.toObject());
      }
    });
  });
};

export const deletePortfolio = (id) => () => {
  const request = new PortfolioDeleteRequest();
  request.setId(id);

  return new Promise((resolve, reject) => {
    PortfolioService.delete(request, metaData(), (err, response) => {
      if (err) {
        reject(err.message);
      } else {
        resolve(response.toObject());
      }
    });
  });
};

// Organisations
const getOrganisationsStart = () => ({
  type: actionTypes.GET_ORGANISATIONS_START
});

const getOrganisationsSuccess = (organisations) => ({
  type: actionTypes.GET_ORGANISATIONS_SUCCESS,
  organisations
});

const getOrganisationsFail = (error) => ({
  type: actionTypes.GET_ORGANISATIONS_FAIL,
  error
});

export const getOrganisations = () => (dispatch) => {
  dispatch(getOrganisationsStart());

  OrganizationService.getAll(Empty, metaData(), (err, response) => {
    if (err) {
      dispatch(getOrganisationsFail(err.message));
    } else {
      dispatch(getOrganisationsSuccess(response.toObject().organizationsList));
    }
  });
};

export const createOrganization = (name, domain) => () => {
  const request = new OrganizationCreateRequest();
  request.setName(name);
  request.setDomain(domain);

  return new Promise((resolve, reject) => {
    OrganizationService.create(request, metaData(), (err, response) => {
      if (err) {
        reject(err.message);
      } else {
        resolve(response.toObject());
      }
    });
  });
};

export const deleteOrganisation = (id) => () => {
  const request = new OrganizationDeleteRequest();

  request.setId(id);
  return new Promise((resolve, reject) => {
    OrganizationService.getAll(request, metaData(), (err, response) => {
      if (err) {
        reject(err.message);
      } else {
        resolve(response.toObject());
      }
    });
  });
};
