import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  container: {
    padding: theme.spacing(0, 8),
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(0, 3)
    },
    [theme.breakpoints.up('xl')]: {
      padding: theme.spacing(0, 22)
    },
    '& .MuiTable-root tr th:last-child': {
      textAlign: 'center !important'
    },
    '& .MuiTable-root tr td:last-child': {
      textAlign: 'center !important'
    },
    '& .MuiTableCell-footer': {
      border: 'none'
    },
    '& .MuiTablePagination-root': {
      [theme.breakpoints.down('sm')]: {
        width: theme.spacing(40)
      },
      [theme.breakpoints.up('sm')]: {
        width: '100%'
      }
    }
  },
  containerLarge: {
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(0, 3)
    },
    '& .MuiTable-root tr td:last-child': {
      textAlign: 'center !important'
    },
    '& .MuiTableCell-footer': {
      border: 'none'
    },
    '& .MuiTablePagination-root': {
      [theme.breakpoints.down('sm')]: {
        width: theme.spacing(40)
      },
      [theme.breakpoints.up('sm')]: {
        width: '100%'
      }
    }
  },
  table: {
    boxShadow: '0px 3px 14px #0000001C',
    width: '100%'
  },
  badge: {
    height: theme.spacing(2)
  },
  title: {
    fontFamily: 'Montserrat, sans-serif',
    fontSize: theme.spacing(3)
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3)
  }
}));

export default useStyles;
