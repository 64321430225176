import { metaData, Empty } from 'config/config';
import { authService, signOut as logOut } from 'config/firebase';

import { GetUserRequest, RegisterUserRequest } from '../../api/user_grpc_web_pb';
import { UserService, RoleService } from '../services/user';

import * as actionTypes from './actionTypes';

const getUserStart = () => ({
  type: actionTypes.GET_USER_START
});

const getUserSuccess = (user) => ({
  type: actionTypes.GET_USER_SUCCESS,
  user
});

const getUserFail = (error) => ({
  type: actionTypes.GET_USER_FAIL,
  error
});

export const SignUserSuccess = (auth) => ({
  type: actionTypes.SIGN_USER_SUCCESS,
  auth
});

export const SignUserFail = () => ({
  type: actionTypes.SIGN_USER_FAIL
});

const registerUserStart = () => ({
  type: actionTypes.REGISTER_USER_START
});

const registerUserSuccess = (user) => ({
  type: actionTypes.REGISTER_USER_SUCCESS,
  user
});

const registerUserFail = (error) => ({
  type: actionTypes.REGISTER_USER_FAIL,
  error
});

// Roles
const getUserRolesStart = () => ({
  type: actionTypes.GET_USER_ROLES_START
});

const getUserRolesSuccess = (userRoleId, userRoles) => ({
  type: actionTypes.GET_USER_ROLES_SUCCESS,
  userRoles,
  userRoleId
});

const getUserRolesFail = (error) => ({
  type: actionTypes.GET_USER_ROLES_FAIL,
  error
});

export const refreshUserToken = (token) => ({
  type: actionTypes.REFRESH_USER_TOKEN,
  token
});

export const RegisterUser = (firebaseId) => (dispatch) => {
  dispatch(registerUserStart());
  const request = new RegisterUserRequest();
  request.setFirebaseid(firebaseId);

  return new Promise((resolve, reject) => {
    UserService.registerUser(request, metaData(), (err, response) => {
      if (err) {
        dispatch(registerUserFail(err.message));
        reject(err.message);
      } else {
        dispatch(registerUserSuccess(response.toObject()));
        resolve(response);
      }
    });
  });
};

export const getUser = (firebaseId) => (dispatch) => {
  dispatch(getUserStart());
  const request = new GetUserRequest();

  request.setFirebaseid(firebaseId);
  return new Promise((resolve, reject) => {
    UserService.getUser(request, metaData(), (err, response) => {
      if (err) {
        dispatch(getUserFail(err.message));
        reject(err.message);
      } else {
        dispatch(getUserSuccess(response.toObject()));
        resolve(response.toObject());
      }
    });
  });
};

export const getUserRoles = (userRoleId) => (dispatch) => {
  dispatch(getUserRolesStart());

  return new Promise((resolve, reject) => {
    RoleService.getRoleList(Empty, metaData(), (err, response) => {
      if (err) {
        dispatch(getUserRolesFail(err.message));
        reject(err.message);
      } else {
        dispatch(getUserRolesSuccess(userRoleId, response.toObject().rolesList));
        resolve(response.toObject().rolesList);
      }
    });
  });
};

export const signOut = () => {
  logOut(authService);

  window.localStorage.removeItem('assetId');
  window.localStorage.removeItem('activeTab');

  return {
    type: actionTypes.USER_SIGNOUT
  };
};
