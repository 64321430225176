import { metaData } from 'config/config';
import wrappers from 'google-protobuf/google/protobuf/wrappers_pb';
import { formatForBackEnd } from 'helpers/utils';
import { toast } from 'react-toastify';

import {
  GetTenantManagerTicketsByTenantIDRequest,
  CreateTenantManagerTicketRequest,
  GetTenantManagerTicketRequest,
  UpdateTenantManagerTicketRequest,
  DeleteTenantManagerTicketRequest
} from '../../api/tenant_grpc_web_pb';
import { TenantManagerTicketServicePromise, TenantManagerTicketService } from '../services/tenant';

import * as actionTypes from './actionTypes';

const getTenantManagerStart = () => ({
  type: actionTypes.GET_TENANT_MANAGER_START
});

const getTenantManagerSuccess = (tenantManager) => ({
  type: actionTypes.GET_TENANT_MANAGER_SUCCESS,
  tenantManager
});

const getTenantManagerFail = (error) => ({
  type: actionTypes.GET_TENANT_MANAGER_FAIL,
  error
});

export const getTenantManager = (id) => async (dispatch) => {
  dispatch(getTenantManagerStart());
  const request = new GetTenantManagerTicketsByTenantIDRequest();

  request.setTenantid(id);

  try {
    const result = await TenantManagerTicketServicePromise.getTenantManagerTicketsByTenantID(
      request,
      metaData()
    );
    const response = result.toObject().ticketsList;
    dispatch(getTenantManagerSuccess(response));
    return response;
  } catch (error) {
    dispatch(getTenantManagerFail(error.message));
    throw error.message;
  }
};

// Create
export const createTenantManager = (assetId, tenantId, form) => async () => {
  const request = new CreateTenantManagerTicketRequest();

  request.setAssetid(assetId);
  request.setTenantid(tenantId);
  request.setUserid(form.userid);
  request.setQueryname(form.queryname);
  request.setDuedate(formatForBackEnd(form.duedate));
  request.setLocation(form.location);
  request.setStatus(form.status);
  request.setDescription(form.description);

  return new Promise((resolve, reject) => {
    TenantManagerTicketService.createTenantManagerTicket(request, metaData(), (err, response) => {
      if (err) {
        reject(err.message);
      } else {
        resolve(response.toObject());
      }
    });
  });
};

// Details
const getTenantManagerDetailsStart = () => ({
  type: actionTypes.GET_TENANT_MANAGER_CASE_START
});

const getTenantManagerDetailsSuccess = (details) => ({
  type: actionTypes.GET_TENANT_MANAGER_CASE_SUCCESS,
  details
});

const getTenantManagerDetailsFail = (error) => ({
  type: actionTypes.GET_TENANT_MANAGER__CASE_FAIL,
  error
});

export const getTenantManagerDetails = (id) => async (dispatch) => {
  dispatch(getTenantManagerDetailsStart());
  const request = new GetTenantManagerTicketRequest();
  request.setId(id);

  try {
    const result = await TenantManagerTicketServicePromise.getTenantManagerTicket(
      request,
      metaData()
    );
    const response = result.toObject();

    dispatch(getTenantManagerDetailsSuccess(response));
    return response;
  } catch (error) {
    dispatch(getTenantManagerDetailsFail(error.message));
    throw error.message;
  }
};

// Delete
export const deleteTenantManagerTicket = (ticketId) => () => {
  const request = new DeleteTenantManagerTicketRequest();
  request.setId(ticketId);

  return new Promise((resolve, reject) => {
    TenantManagerTicketService.deleteTenantManagerTicket(request, metaData(), (err, response) => {
      if (err) {
        reject(err.message);
        toast.error(err.message);
      } else {
        resolve(response.toObject());
        toast.success('Ticket Deleted!');
      }
    });
  });
};

// Update
export const updateTenantManagerTicket = (form) => () => {
  const request = new UpdateTenantManagerTicketRequest();
  request.setId(form.id);

  if (form.userid) {
    const userid = new wrappers.StringValue();
    userid.setValue(form.userid);
    request.setUserid(userid);
  }

  if (form.queryname) {
    const queryname = new wrappers.StringValue();
    queryname.setValue(form.queryname);
    request.setQueryname(queryname);
  }

  if (form.duedate) {
    const duedate = new wrappers.StringValue();
    duedate.setValue(formatForBackEnd(form.duedate));
    request.setDuedate(duedate);
  }

  if (form.location) {
    const location = new wrappers.StringValue();
    location.setValue(form.location);
    request.setLocation(location);
  }

  if (form.status >= 0) {
    const status = new wrappers.StringValue();
    status.setValue(form.status.toString());
    request.setStatus(status);
  }

  if (form.description) {
    const description = new wrappers.StringValue();
    description.setValue(form.description);
    request.setDescription(description);
  }

  return new Promise((resolve, reject) => {
    TenantManagerTicketService.updateTenantManagerTicket(request, metaData(), (err, response) => {
      if (err) {
        reject(err.message);
        toast.error(err.message);
      } else {
        toast.success('Ticket Updated!');
        resolve(response.toObject());
      }
    });
  });
};
