import * as actionTypes from '../actions/actionTypes';

const initialState = {
  homeData: {
    assetCount: 0,
    gla: 0,
    occupancy: 0,
    portfolioCount: 0,
    tenantCount: 0,
    unitsCount: 0,
    anp: 0,
    noi: 0
  },
  chartData: {
    loading: false,
    loaded: false,
    error: null,
    data: {}
  },
  chartDataPage: {
    loading: false,
    loaded: false,
    data: {},
    error: null
  },
  homeDataYears: [],
  yearFilter: 2024,
  loading: false,
  loaded: false,
  error: null,
  allIds: [],
  byId: {},
  geoData: {
    loading: false,
    loaded: false,
    data: [],
    error: null
  },
  summaryData: {
    loading: false,
    loaded: false,
    data: {},
    error: null
  },
  forecasts: {
    loading: false,
    loaded: false,
    data: [],
    error: null
  },
  actuals: {
    loading: false,
    loaded: false,
    data: [],
    error: null
  }
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_HOMEDATA_START:
      return {
        ...state,
        loading: true,
        error: null
      };
    case actionTypes.GET_HOMEDATA_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        homeData: action.homeData,
        byId: { ...state.byId, [action.bundleId]: { ...action.homeData } },
        allIds: [...state.allIds, action.bundleId]
      };
    case actionTypes.GET_HOMEDATA_FAIL:
      return {
        ...state,
        loading: false,
        loaded: true,
        error: action.error
      };
    case actionTypes.GET_HOMEDATAYEARS_START:
      return {
        ...state,
        loading: true,
        error: null
      };
    case actionTypes.GET_HOMEDATAYEARS_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        homeDataYears: action.data
      };
    case actionTypes.GET_HOMEDATAYEARS_FAIL:
      return {
        ...state,
        loading: false,
        loaded: true,
        error: action.error
      };
    case actionTypes.SET_YEAR_FILTER:
      return {
        ...state,
        yearFilter: action.year
      };
    case actionTypes.SET_HOMEDATA_BUNDLE: {
      return {
        ...state,
        homeData: state.byId[action.bundleId],
        error: null
      };
    }
    case actionTypes.GET_CHART_DATA_START:
      return {
        ...state,
        chartData: {
          loading: true
        }
      };
    case actionTypes.GET_CHART_DATA_SUCCESS:
      return {
        ...state,
        chartData: {
          loading: false,
          loaded: true,
          data: action.data
        }
      };
    case actionTypes.GET_CHART_DATA_FAIL:
      return {
        ...state,
        chartData: {
          loading: false,
          loaded: true,
          error: action.error
        }
      };
    case actionTypes.GET_CHART_DATA_PAGE_START:
      return {
        ...state,
        chartDataPage: {
          loading: true
        }
      };
    case actionTypes.GET_CHART_DATA_PAGE_SUCCESS:
      return {
        ...state,
        chartDataPage: {
          loading: false,
          loaded: true,
          data: action.data
        }
      };
    case actionTypes.GET_CHART_DATA_PAGE_FAIL:
      return {
        ...state,
        chartDataPage: {
          loading: false,
          loaded: true,
          error: action.error
        }
      };
    case actionTypes.GET_GEO_DATA_START:
      return {
        ...state,
        geoData: {
          loading: true
        }
      };
    case actionTypes.GET_GEO_DATA_SUCCESS:
      return {
        ...state,
        geoData: {
          loading: false,
          loaded: true,
          data: action.data
        }
      };
    case actionTypes.GET_GEO_DATA_FAIL:
      return {
        ...state,
        geoData: {
          loading: false,
          loaded: true,
          error: action.error
        }
      };
    case actionTypes.RESET_GEO_DATA_STATE:
      return {
        ...state,
        geoData: {
          loading: false,
          loaded: false,
          data: [],
          error: null
        }
      };
    case actionTypes.GET_SUMMARY_TABLE_START:
      return {
        ...state,
        summaryData: {
          loading: true
        }
      };
    case actionTypes.GET_SUMMARY_TABLE_SUCCESS:
      return {
        ...state,
        summaryData: {
          loading: false,
          loaded: true,
          data: action.data
        }
      };
    case actionTypes.GET_SUMMARY_TABLE_FAIL:
      return {
        ...state,
        summaryData: {
          loading: false,
          loaded: true,
          error: action.error
        }
      };
    case actionTypes.GET_TENANTS_FORECAST_START:
      return {
        ...state,
        forecasts: {
          loading: true
        }
      };
    case actionTypes.GET_TENANTS_FORECAST_SUCCESS:
      return {
        ...state,
        forecasts: {
          loading: false,
          loaded: true,
          data: action.data
        }
      };
    case actionTypes.GET_TENANTS_FORECAST_FAIL:
      return {
        ...state,
        forecasts: {
          loading: false,
          loaded: true,
          error: action.error
        }
      };
    case actionTypes.GET_TENANTS_ACTUALS_START:
      return {
        ...state,
        actuals: {
          loading: true
        }
      };
    case actionTypes.GET_TENANTS_ACTUALS_SUCCESS:
      return {
        ...state,
        actuals: {
          loading: false,
          loaded: true,
          data: action.data
        }
      };

    case actionTypes.GET_TENANTS_ACTUALS_FAIL:
      return {
        ...state,
        actuals: {
          loading: false,
          loaded: true,
          error: action.error
        }
      };
    default:
      return state;
  }
};

export default reducer;
