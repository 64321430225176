import { getEnviroment } from 'config/config';

import {
  AggregationServiceClient,
  AggregationServicePromiseClient
} from '../../api/aggregation_grpc_web_pb';

const env = localStorage.getItem('env');
const ENV = getEnviroment(env);

export const AggregationService = new AggregationServicePromiseClient(ENV);
export const AggregationServiceInstance = new AggregationServiceClient(ENV);
