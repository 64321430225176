import * as actionTypes from '../actions/actionTypes';

const initialState = {
  costManager: [],
  loading: false,
  loaded: false,
  error: null,
  costManagerDetailsError: null,
  costManagerDetailsLoading: false,
  costManagerDetailsLoaded: false,
  costManagerDetails: {},
  procurement: {
    procurement: [],
    loaded: false,
    loading: false,
    error: null
  },
  activityFeed: [],
  supplier: {
    assetid: '',
    city: '',
    companyname: '',
    contact: '',
    country: '',
    email: '',
    phone: '',
    postcode: '',
    street: ''
  },
  details: []
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_TENANT_COST_MANAGER_START:
      return {
        ...state,
        loading: true
      };
    case actionTypes.GET_TENANT_COST_MANAGER_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        costManager: action.costManager
      };
    case actionTypes.GET_TENANT_COST_MANAGER_FAIL:
      return {
        ...state,
        loading: false,
        loaded: true,
        error: action.error
      };
    case actionTypes.GET_TENANT_COST_MANAGER_DETAILS_START:
      return {
        ...state,
        costManagerDetailsLoading: true
      };
    case actionTypes.GET_TENANT_COST_MANAGER_DETAILS_SUCCESS:
      return {
        ...state,
        costManagerDetailsLoading: false,
        costManagerDetailsLoaded: true,
        costManagerDetails: action.details
      };
    case actionTypes.GET_TENANT_COST_MANAGER_DETAILS_FAIL:
      return {
        ...state,
        costManagerDetailsLoading: false,
        costManagerDetailsLoaded: true,
        costManagerDetailsError: action.error
      };

    case actionTypes.GET_TENANT_COST_PROCUREMENT_START:
      return {
        ...state,
        procurement: {
          ...state.procurement,
          loading: true
        }
      };
    case actionTypes.GET_TENANT_COST_PROCUREMENT_SUCCESS:
      return {
        ...state,
        procurement: {
          ...state.procurement,
          loading: false,
          loaded: true,
          error: null,
          procurement: action.procurement
        }
      };
    case actionTypes.GET_TENANT_COST_PROCUREMENT_FAIL:
      return {
        ...state,
        procurement: {
          ...state.procurement,
          loading: false,
          loaded: true,
          error: action.error
        }
      };
    case actionTypes.GET_TENANT_COST_SUPPLIER_DETAILS:
      return {
        ...state,
        supplier: action.supplier
      };
    case actionTypes.GET_TENANT_COST_CONTRACT_DETAILS:
      return {
        ...state,
        details: action.details
      };

    case actionTypes.RESET_TENANT_COST_MANAGER: {
      return initialState;
    }
    default:
      return state;
  }
};

export default reducer;
