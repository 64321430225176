import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  paper: {
    borderRadius: '10px'
  },
  iconWrapperRed: {
    borderRadius: '50%',
    backgroundColor: 'rgba(247, 87, 87, .2)',
    width: '24px',
    height: '24px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    margin: '0 10px'
  },
  iconWrapperGreen: {
    borderRadius: '50%',
    backgroundColor: 'rgba(87, 247, 119, .2)',
    width: '24px',
    height: '24px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    margin: '0 10px'
  },
  paddingX: {
    padding: theme.spacing(0, 6)
  },
  paddingY: {
    padding: theme.spacing(2, 0)
  },
  item: {
    display: 'flex',
    alignItems: 'center'
  },
  badge: {
    color: '#FFF',
    width: 40,
    height: 40,
    borderRadius: '50%',
    backgroundColor: '#645af2',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    margin: theme.spacing(1)
  },
  itemsWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: theme.spacing(1)
  },
  sectionInfo: {
    marginBottom: theme.spacing(4)
  },
  tasks: {
    padding: theme.spacing(3)
  },
  task: {
    display: 'flex',
    justifyContent: 'space-between',
    alignContent: 'center',
    padding: '15px',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: '#eee',
      transition: 'all .3s'
    }
  },
  boxHeading: {
    margin: '24px 0',
    width: '150px',
    borderRadius: '8px',
    paddingLeft: '10px',
    color: '#666'
  },
  textRed: {
    color: 'red',
    fontWeight: 'bold'
  },
  textGreen: {
    color: 'green',
    fontWeight: 'bold'
  },
  textGrey: {
    color: 'grey',
    fontWeight: 'bold'
  },
  fromText: {
    fontSize: '12px',
    color: '#ddd',
    padding: '0 5px'
  },
  iconRed: {
    color: 'red'
  },
  iconGreen: {
    color: 'green'
  },
  wrapHeading: {
    display: 'flex',
    alignItems: 'baseline'
  },
  wrapCard: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  navLink: {
    textDecoration: 'none'
  },
  flexColumn: {
    display: 'flex',
    flexDirection: 'column',
    paddingBottom: '25px'
  },
  textUpper: {
    alignSelf: 'flex-start',
    padding: '10px'
  },
  textValue: {
    textAlign: 'center'
  },
  infoContainer: {
    display: 'flex',
    padding: '20px 0 20px 0',
    maxWidth: 800,
    justifyContent: 'space-between'
  },
  fontLight: {
    fontWeight: 400,
    fontSize: '18px'
  },
  tooltip: {
    position: 'absolute',
    bottom: '5%',
    left: '2%',
    zIndex: 999
  },
  filterWrapper: {
    display: 'flex',
    padding: theme.spacing(3, 0)
  },
  mapBox: {
    width: '100%',
    height: '500px',
    borderRadius: '10px',
    overflow: 'hidden'
  },
  chartName: {
    [theme.breakpoints.up('lg')]: {
      fontSize: '1.4rem'
    },
    [theme.breakpoints.up('xl')]: {
      fontSize: '1.5rem'
    }
  }
}));

export default useStyles;
