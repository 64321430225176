import * as actionTypes from '../actions/actionTypes';

export const portfolioInitialState = {
  portfolios: [],
  loading: false,
  loaded: false,
  error: null,
  organisations: {
    organisations: [],
    loading: false,
    loaded: false,
    error: null
  }
};

const reducer = (state = portfolioInitialState, action) => {
  switch (action.type) {
    case actionTypes.GET_PORTFOLIOS_START:
      return {
        ...state,
        loading: true
      };
    case actionTypes.GET_PORTFOLIOS_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        portfolios: action.portfolios
      };
    case actionTypes.GET_PORTFOLIOS_FAIL:
      return {
        ...state,
        loading: false,
        loaded: true,
        error: action.error
      };
    case actionTypes.GET_ORGANISATIONS_START:
      return {
        ...state,
        organisations: {
          loading: true
        }
      };
    case actionTypes.GET_ORGANISATIONS_SUCCESS:
      return {
        ...state,
        organisations: {
          ...state.organisations,
          organisations: action.organisations,
          loading: false,
          loaded: true
        }
      };
    case actionTypes.GET_ORGANISATIONS_FAIL:
      return {
        ...state,
        organisations: {
          ...state.organisations,
          loading: false,
          loaded: true,
          error: action.error
        }
      };
    default:
      return state;
  }
};

export default reducer;
