import { metaData } from 'config/config';
import wrappers from 'google-protobuf/google/protobuf/wrappers_pb';
import { formatForBackEnd } from 'helpers/utils';

import {
  GetValuationsByAssetIDRequest,
  CreateValuationRequest,
  GetValuationRequest,
  UpdateValuationRequest,
  DeleteValuationRequest
} from '../../api/asset_grpc_web_pb';
import { ValuationService } from '../services/asset';

import * as actionTypes from './actionTypes';

const getValuationsStart = () => ({
  type: actionTypes.GET_VALUATIONS_START
});

const getValuationsSuccess = (valuations) => ({
  type: actionTypes.GET_VALUATIONS_SUCCESS,
  valuations
});

const getValuationsFail = (error) => ({
  type: actionTypes.GET_VALUATIONS_FAIL,
  error
});

export const getValuations = (id) => (dispatch) => {
  dispatch(getValuationsStart());
  const request = new GetValuationsByAssetIDRequest();
  request.setAssetid(id);

  return new Promise((resolve, reject) => {
    ValuationService.getValuationsByAssetID(request, metaData(), (err, response) => {
      if (err) {
        dispatch(getValuationsFail(err.message));

        reject(err.message);
      } else {
        dispatch(getValuationsSuccess(response.toObject().valuationsList));

        resolve(response.toObject());
      }
    });
  });
};

const getValuationsDetailsStart = () => ({
  type: actionTypes.GET_VALUATIONS_DETAILS_START
});

const getValuationsDetailsSuccess = (details) => ({
  type: actionTypes.GET_VALUATIONS_DETAILS_SUCCESS,
  details
});

const getValuationsDetailsFail = (error) => ({
  type: actionTypes.GET_VALUATIONS_DETAILS_FAIL,
  error
});

export const getValuationsDetails = (id) => (dispatch) => {
  dispatch(getValuationsDetailsStart());
  const request = new GetValuationRequest();
  request.setId(id);

  return new Promise((resolve, reject) => {
    ValuationService.getValuation(request, metaData(), (err, response) => {
      if (err) {
        dispatch(getValuationsDetailsFail(err.message));

        reject(err.message);
      } else {
        dispatch(getValuationsDetailsSuccess(response.toObject()));
        resolve(response.toObject());
      }
    });
  });
};

export const createValuation = (form, assetId) => () => {
  if (form.id) {
    const request = new UpdateValuationRequest();
    request.setId(form.id);

    const valuation = new wrappers.StringValue();
    valuation.setValue(form.valuation);
    request.setValuation(valuation);

    const date = new wrappers.StringValue();
    date.setValue(formatForBackEnd(form.date));
    request.setDate(date);

    const assetid = new wrappers.StringValue();
    assetid.setValue(form.assetid);
    request.setAssetid(assetid);

    const marketvalue = new wrappers.DoubleValue();
    marketvalue.setValue(form.marketvalue);
    request.setMarketvalue(marketvalue);

    const liquidationvalue = new wrappers.DoubleValue();
    liquidationvalue.setValue(form.liquidationvalue);
    request.setLiquidationvalue(liquidationvalue);

    const valuer = new wrappers.StringValue();
    valuer.setValue(form.valuer);
    request.setValuer(valuer);

    const summary = new wrappers.StringValue();
    summary.setValue(form.summary);
    request.setSummary(summary);

    return new Promise((resolve, reject) => {
      ValuationService.updateValuation(request, metaData(), (err, response) => {
        if (err) {
          reject(err.message);
        } else {
          resolve(response.toObject());
        }
      });
    });
  }
  const request = new CreateValuationRequest();
  request.setAssetid(assetId);
  request.setDate(formatForBackEnd(form.date));
  request.setValuation(form.valuation);
  request.setMarketvalue(form.marketvalue);
  request.setLiquidationvalue(form.liquidationvalue);
  request.setValuer(form.valuer);
  request.setSummary(form.summary);

  return new Promise((resolve, reject) => {
    ValuationService.createValuation(request, metaData(), (err, response) => {
      if (err) {
        reject(err.message);
      } else {
        resolve(response.toObject());
      }
    });
  });
};

export const deleteValuation = (id) => () => {
  const request = new DeleteValuationRequest();
  request.setId(id);

  return new Promise((resolve, reject) => {
    ValuationService.deleteValuation(request, metaData(), (err, response) => {
      if (err) {
        reject(err.message);
      } else {
        resolve(response.toObject());
      }
    });
  });
};

export const resetValuations = () => ({
  type: actionTypes.RESET_VALUATIONS_STATE
});
