import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  divider: {
    width: '100%',
    margin: theme.spacing(1)
  },
  accordionSummary: {
    fontFamily: theme.fonts.openSans,
    margin: theme.spacing(0, 1),
    color: '#7D7D7D',
    fontSize: 14
  },
  accordionSubtitle: {
    fontFamily: theme.fonts.openSans,
    marginLeft: theme.spacing(1),
    color: '#2C324E',
    fontWeight: 'bold',
    fontSize: 14
  },
  accordionTitle: {
    fontSize: 16,
    fontFamily: theme.fonts.base,
    margin: theme.spacing(0, 1),
    color: '#000000'
  }
}));

export default useStyles;
