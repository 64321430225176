import { mockTenantCashManager } from '../fixtures';

import * as actionTypes from './actionTypes';

const getCashManagerStart = () => ({
  type: actionTypes.GET_TENANT_CASH_MANAGER_START
});

const getCashManagerSuccess = (cashManager) => ({
  type: actionTypes.GET_TENANT_CASH_MANAGER_SUCCESS,
  cashManager
});

const getCashManagerFail = (error) => ({
  type: actionTypes.GET_TENANT_CASH_MANAGER_FAIL,
  error
});

export const getCashManager = () => (dispatch) => {
  dispatch(getCashManagerStart());

  try {
    setTimeout(() => {
      dispatch(getCashManagerSuccess(mockTenantCashManager));
    }, 500);
  } catch (error) {
    dispatch(getCashManagerFail(error));
  }
};
