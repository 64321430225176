/* eslint-disable no-case-declarations */
import orderBy from 'lodash/orderBy';

import * as actionTypes from '../actions/actionTypes';

export const InitialState = {
  loading: false,
  loaded: false,
  contract: {},
  error: null,
  units: [],
  unitLoaded: false,
  unitLoading: false,
  strategy: {
    strategy: '',
    loading: false,
    loaded: false,
    error: null
  },
  turnover: {
    turnover: [],
    loading: false,
    loaded: false,
    error: null
  },
  options: {
    options: [],
    loading: false,
    loaded: false,
    error: null
  },
  budget: {
    budget: [],
    loading: false,
    loaded: false,
    error: null
  },
  clauses: {
    clauses: [],
    loading: false,
    loaded: false,
    error: null
  },
  cashflow: {
    cashflow: [],
    loading: false,
    loaded: false,
    error: null,
    budget: [],
    budgetLoading: false,
    budgetLoaded: false,
    budgetError: null
  },
  details: {
    details: {},
    loading: false,
    loaded: false,
    error: null
  }
};

const reducer = (state = InitialState, action) => {
  switch (action.type) {
    case actionTypes.GET_UNITS_START:
      return {
        ...state,
        unitLoading: true,
        units: [],
        unitLoaded: false
      };
    case actionTypes.GET_UNITS_SUCCESS:
      return {
        ...state,
        units: action.units,
        unitLoading: false,
        unitLoaded: true,
        error: null
      };
    case actionTypes.GET_UNITS_FAIL:
      return {
        ...state,
        error: action.error,
        unitLoading: false,
        unitLoaded: true
      };

    case actionTypes.GET_TENANT_LEASING_CONTRACT_START:
      return {
        ...state,
        loading: true,
        loaded: false,
        contracts: []
      };
    case actionTypes.GET_TENANT_LEASING_CONTRACT_SUCCESS:
      return {
        ...state,
        loading: false,
        contract: action.contract.reverse(),
        loaded: true,
        error: null
      };
    case actionTypes.GET_TENANT_LEASING_CONTRACT_FAIL:
      return {
        ...state,
        loading: false,
        error: action.error,
        loaded: true
      };

    case actionTypes.GET_TENANT_LEASING_STRATEGY_START:
      return {
        ...state,
        strategy: {
          ...state.strategy,
          loading: true,
          loaded: false,
          strategy: ''
        }
      };
    case actionTypes.GET_TENANT_LEASING_STRATEGY_SUCCESS:
      return {
        ...state,
        strategy: {
          ...state.strategy,
          loading: false,
          strategy: action.strategy,
          loaded: true,
          error: null
        }
      };
    case actionTypes.GET_TENANT_LEASING_STRATEGY_FAIL:
      return {
        ...state,
        strategy: {
          ...state.strategy,
          loading: false,
          error: action.error,
          loaded: true
        }
      };

    case actionTypes.GET_TENANT_LEASING_TURNOVER_START:
      return {
        ...state,
        turnover: {
          ...state.turnover,
          loading: true,
          turnover: [],
          loaded: false
        }
      };
    case actionTypes.GET_TENANT_LEASING_TURNOVER_SUCCESS:
      const { turnover } = action;
      const turnoverData = orderBy(turnover, 'year', 'asc');
      return {
        ...state,
        turnover: {
          ...state.turnover,
          loading: false,
          turnover: turnoverData,
          loaded: true,
          error: null
        }
      };
    case actionTypes.GET_TENANT_LEASING_TURNOVER_FAIL:
      return {
        ...state,
        turnover: {
          ...state.turnover,
          loading: false,
          error: action.error,
          loaded: true
        }
      };
    case actionTypes.GET_TENANT_LEASING_OPTIONS_START:
      return {
        ...state,
        options: {
          ...state.options,
          loading: true,
          options: [],
          loaded: false
        }
      };
    case actionTypes.GET_TENANT_LEASING_OPTIONS_SUCCESS:
      return {
        ...state,
        options: {
          ...state.options,
          loading: false,
          options: action.options,
          loaded: true,
          error: null
        }
      };
    case actionTypes.GET_TENANT_LEASING_OPTIONS_FAIL:
      return {
        ...state,
        options: {
          ...state.options,
          loading: false,
          error: action.error,
          loaded: true
        }
      };

    case actionTypes.GET_TENANT_LEASING_BUDGET_START:
      return {
        ...state,
        budget: {
          ...state.budget,
          loading: true,
          budget: [],
          loaded: false
        }
      };
    case actionTypes.GET_TENANT_LEASING_BUDGET_SUCCESS:
      return {
        ...state,
        budget: {
          ...state.budget,
          loading: false,
          budget: action.budget,
          loaded: true,
          error: null
        }
      };
    case actionTypes.GET_TENANT_LEASING_BUDGET_FAIL:
      return {
        ...state,
        budget: {
          ...state.budget,
          loading: false,
          error: action.error,
          loaded: true
        }
      };

    case actionTypes.GET_LEASING_CONTRACT_CLAUSES_START:
      return {
        ...state,
        clauses: {
          ...state.clauses,
          loading: true,
          clauses: [],
          loaded: false
        }
      };
    case actionTypes.GET_LEASING_CONTRACT_CLAUSES_SUCCESS:
      return {
        ...state,
        clauses: {
          ...state.clauses,
          loading: false,
          clauses: action.clauses,
          loaded: true,
          error: null
        }
      };
    case actionTypes.GET_LEASING_CONTRACT_CLAUSES_FAIL:
      return {
        ...state,
        clauses: {
          ...state.clauses,
          loading: false,
          error: action.error,
          loaded: true
        }
      };

    case actionTypes.GET_TENANT_LEASING_CASHFLOW_START:
      return {
        ...state,
        cashflow: {
          ...state.cashflow,
          loading: true,
          cashflow: [],
          loaded: false
        }
      };
    case actionTypes.GET_TENANT_LEASING_CASHFLOW_SUCCESS:
      return {
        ...state,
        cashflow: {
          ...state.cashflow,
          loading: false,
          cashflow: action.cashflow,
          loaded: true,
          error: null
        }
      };
    case actionTypes.GET_TENANT_LEASING_CASHFLOW_FAIL:
      return {
        ...state,
        cashflow: {
          ...state.cashflow,
          loading: false,
          error: action.error,
          loaded: true
        }
      };
    case actionTypes.GET_TENANT_LEASING_CASHFLOW_BUDGET_START:
      return {
        ...state,
        cashflow: {
          ...state.cashflow,
          budgetLoading: true,
          budget: [],
          budgetLoaded: false
        }
      };
    case actionTypes.GET_TENANT_LEASING_CASHFLOW_BUDGET_SUCCESS:
      return {
        ...state,
        cashflow: {
          ...state.cashflow,
          budgetLoading: false,
          budget: action.budget,
          budgetLoaded: true,
          budgetError: null
        }
      };
    case actionTypes.GET_TENANT_LEASING_CASHFLOW_BUDGET_FAIL:
      return {
        ...state,
        cashflow: {
          ...state.cashflow,
          budgetLoading: false,
          budgetError: action.error,
          budgetLoaded: true
        }
      };

    case actionTypes.GET_TENANT_LEASING_DETAILS_START:
      return {
        ...state,
        details: {
          ...state.details,
          loading: true,
          details: {},
          loaded: false
        }
      };
    case actionTypes.GET_TENANT_LEASING_DETAILS_SUCCESS:
      return {
        ...state,
        details: {
          ...state.details,
          loading: false,
          details: action.details,
          loaded: true,
          error: null
        }
      };
    case actionTypes.GET_TENANT_LEASING_DETAILS_FAIL:
      return {
        ...state,
        details: {
          ...state.details,
          loading: false,
          error: action.error,
          loaded: true
        }
      };

    default:
      return state;
  }
};

export default reducer;
