import React from 'react';

import Box from '@material-ui/core/Box';
import Drawer from '@material-ui/core/Drawer';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import PeopleIcon from '@material-ui/icons/People';
import { ReactComponent as FolderIcon } from 'assets/folder.svg';
import { ReactComponent as HomeIcon } from 'assets/home.svg';
import { ReactComponent as LocationIcon } from 'assets/location.svg';
import { ReactComponent as ChevronDownIcon } from 'assets/menu-down.svg';
import CustomIcon from 'components/CustomIcon';
import useRouter from 'hooks/useRouter';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';

import useStyles from './styles';

const navLinks = [
  { path: '/', name: 'Home', rights: 'asset', icon: HomeIcon },
  { path: '/Portfolios', name: 'Portfolios', rights: 'portfolio', icon: FolderIcon },
  { path: '/Assets', name: 'Assets', rights: 'asset', icon: LocationIcon },
  { path: '/Tenants', name: 'Tenants', rights: 'asset', icon: PeopleIcon }
];

const DrawerMenu = ({ drawerOpen, toggleDrawer }) => {
  const classes = useStyles();
  const router = useRouter();
  const { pathname } = router;

  return (
    <Drawer
      anchor="left"
      open={drawerOpen}
      onClose={() => toggleDrawer(false)}
      classes={{
        paper: classes.drawerPaper
      }}
    >
      <Box height={64}>
        <Box display="flex" justifyContent="flex-end">
          <IconButton onClick={() => toggleDrawer(false)} className={classes.iconButton}>
            <ArrowBackIcon className={classes.white} />
          </IconButton>
        </Box>
      </Box>
      <List>
        {navLinks.map((link) => (
          <ListItem
            key={link.name}
            component={NavLink}
            to={link.path}
            className={classes.listItem}
            selected={pathname === link.path}
            data-testid={`sidebar-${link.name}`}
            onClick={() => toggleDrawer((drawer) => !drawer)}
          >
            <ListItemIcon className={classes.itemIcon}>
              <link.icon className={classes.white} />
            </ListItemIcon>
            <ListItemText>{link.name}</ListItemText>
            {link.path === '/Settings' && <ChevronDownIcon />}
          </ListItem>
        ))}
        <ListItem
          key="advanced-reporting"
          component={NavLink}
          to={{
            pathname: '/AdvancedReporting',
            state: { from: { text: 'Home', href: '/' } }
          }}
          className={classes.listItem}
          selected={pathname === '/AdvancedReporting'}
          data-testid="sidebar-advanced-reporting"
          onClick={() => toggleDrawer((drawer) => !drawer)}
        >
          <ListItemIcon className={classes.itemIcon}>
            <CustomIcon name="analytics" width="24" height="24" />
          </ListItemIcon>
          <ListItemText>Advanced Reporting</ListItemText>
        </ListItem>
      </List>
    </Drawer>
  );
};

export default DrawerMenu;

DrawerMenu.propTypes = {
  drawerOpen: PropTypes.bool.isRequired,
  toggleDrawer: PropTypes.func.isRequired
};
