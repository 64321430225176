import React from 'react';

import Box from '@material-ui/core/Box';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { Euro } from '@material-ui/icons';
import Crumps from 'components/Breadcrumps/Crumps';
import StatsIcon from 'components/Icons/StatsIcon';
import Spinner from 'components/Spinner';
import useRouter from 'hooks/useRouter';
import { useDispatch, useSelector } from 'react-redux';
import * as actions from 'store/actions';

import AccountGraph from './AccountGraph';
import AccountWatch from './AccountWatch';
import Invoices from './Invoices';

const useStyles = makeStyles((theme) => ({
  header: {
    fontSize: 25,
    fontFamily: theme.fonts.base
  },
  paper: {
    boxShadow: '0px 3px 14px #0000000F',
    borderRadius: 4,
    overflow: 'auto',
    margin: theme.spacing(2, 6),
    minHeight: 400
  },
  boxContainer: {
    padding: theme.spacing(3)
  },
  boxInner: {
    display: 'flex',
    justifyContent: 'center',
    margin: theme.spacing(2),
    width: '100%',
    [theme.breakpoints.down('xs')]: {
      width: '100%'
    }
  },
  title: {
    fontFamily: theme.fonts.base,
    color: theme.palette.common.black,
    marginBottom: theme.spacing(2)
  },
  vertical: {
    height: '100%',
    minHeight: 360,
    [theme.breakpoints.down('sm')]: {
      display: 'none'
    }
  },
  overflow: {
    overflowX: 'auto'
  }
}));

const CashManager = () => {
  const classes = useStyles();
  const { query } = useRouter();
  const dispatch = useDispatch();

  const loaded = useSelector((state) => state.cashManager.loaded);
  const loading = useSelector((state) => state.cashManager.loading);
  const cashManager = useSelector((state) => state.cashManager.cashManager);
  const crumps = useSelector((state) => state.tenants.crumps.crumps);

  React.useEffect(() => {
    if (crumps.tenantid === query.id && loaded) {
      return;
    }

    if (query.id) {
      dispatch(actions.getTenantCrumps(query.id));
      dispatch(actions.getTenantDetails(query.id));
      dispatch(actions.getCashManager(query.id));
    }
  }, [query]);

  if (!loaded || loading) {
    return <Spinner />;
  }
  return (
    <Box pb={2}>
      <Crumps
        fragments={[
          { title: crumps.assetname, href: `/Asset/${crumps.assetid}` },
          { title: crumps.tenantname, href: `/Tenants/${crumps.tenantid}` },
          { title: 'Cash Manager', href: '' }
        ]}
      />

      <Box display="flex" alignItems="center" m={3} p={2}>
        <Typography className={classes.header}>Tenant Cash Manager</Typography>
      </Box>

      <Paper className={classes.paper}>
        <Box display="flex" className={classes.boxContainer}>
          <Box display="flex" flexDirection="column" className={classes.boxInner}>
            <Box display="flex" justifyContent="space-between" className={classes.overflow}>
              {cashManager.accounts?.length > 0 &&
                cashManager.accounts.map((account) => (
                  <StatsIcon
                    title={account.name}
                    statsValue={`€${account.value}`}
                    Icon={Euro}
                    key={account.id}
                  />
                ))}
            </Box>
            <Box mt={2}>
              <Divider />
            </Box>
            <Grid container>
              <Grid item xs={12} md={5}>
                {cashManager.chartData?.length > 0 && <AccountGraph data={cashManager.chartData} />}
              </Grid>
              <Grid item xs={false} md={1}>
                <Box mt={4} ml={6}>
                  <Divider orientation="vertical" className={classes.vertical} />
                </Box>
              </Grid>

              <Grid item xs={12} md={6}>
                <AccountWatch data={cashManager.accounts} />
              </Grid>
            </Grid>
            <Box mt={2}>
              <Divider />
            </Box>
            <Invoices
              invoices={cashManager.invoicesAwaitingAproval ?? []}
              invoicesOwed={cashManager.invoicesOwed ?? []}
            />
          </Box>
        </Box>
      </Paper>
    </Box>
  );
};

export default CashManager;
