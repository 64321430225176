import * as actionTypes from '../actions/actionTypes';

export const usersInitialState = {
  userDetails: [],
  users: [],
  loading: false,
  userDetailsLoading: false,
  userDetailsLoaded: false,
  userDetailsError: null,
  loaded: false,
  error: null,
  userTotalCount: 20,
  allIds: [],
  byId: []
};

const reducer = (state = usersInitialState, action) => {
  switch (action.type) {
    case actionTypes.GET_USERS_START:
      return {
        ...state,
        loading: true
      };
    case actionTypes.GET_USERS_SUCCESS:
      return {
        ...state,
        users: action.users.usersList,
        userTotalCount: action.users.totalusercount,
        loading: false,
        loaded: true,
        byId: [...state.byId, action.bundleId],
        allIds: { ...state.allIds, [action.bundleId]: [...action.users.usersList] }
      };
    case actionTypes.SET_USERS_BUNDLE: {
      return {
        ...state,
        users: state.allIds[action.bundleId]
      };
    }
    case actionTypes.GET_USERS_FAIL:
      return {
        ...state,
        loading: false,
        loaded: true,
        error: action.error
      };
    case actionTypes.UPDATE_USER_START:
      return {
        ...state,
        loading: true
      };
    case actionTypes.UPDATE_USER_SUCCESS:
      return {
        ...state,
        loading: false,
        allIds: [],
        byId: []
      };
    case actionTypes.UPDATE_USER_FAIL:
      return {
        ...state,
        loading: false,
        userDetailsError: action.error
      };
    case actionTypes.GET_USER_DETAILS_START:
      return {
        ...state,
        userDetailsLoading: true
      };
    case actionTypes.GET_USER_DETAILS_SUCCESS:
      return {
        ...state,
        userDetails: action.userDetails,
        userDetailsLoading: false,
        userDetailsLoaded: true
      };
    case actionTypes.GET_USER_DETAILS_FAIL:
      return {
        ...state,
        error: action.error,
        userDetailsLoading: false,
        userDetailsLoaded: true
      };
    default:
      return state;
  }
};

export default reducer;
