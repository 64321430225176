import React, { useState } from 'react';

import { Dialog, Box, DialogContent, DialogActions, Typography } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import CancelButton from 'components/Buttons/CancelButton';
import SaveButton from 'components/Buttons/SaveButton';
import Transition from 'components/Transition';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import * as actions from 'store/actions';
import useStyles from 'styles/forms';

import { getForm } from './form';
import Supplier from './Supplier';

const ContractDetailsEdit = ({ open, handleClose, details, loading, id, type }) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const formatedData = details?.typedata ? JSON.parse(details?.typedata) : {};
  const [form, setForm] = useState(getForm(details, formatedData));

  const handleSaveSupplierAsset = () => {
    dispatch(actions.updateAssetCostContractDetails(details.id, form))
      .then(() => {
        dispatch(actions.getAssetCostContractDetails(id));

        toast.success('AssetCost Contract Details updated');
      })
      .catch((err) => {
        toast.error(err);
      })
      .finally(() => dispatch(handleClose()));
  };

  const handleSaveSupplierOpex = () => {
    dispatch(actions.updateOpexContractDetails(details.id, form))
      .then(() => {
        dispatch(actions.getOpexContractDetails(id));

        toast.success('Opex Contract Details updated');
        dispatch(actions.getAlertsList());
      })
      .catch((err) => {
        toast.error(err);
      })
      .finally(() => dispatch(handleClose()));
  };
  const handleSaveSupplierTenant = () => {
    dispatch(actions.updateTenantCostContractDetails(details.id, form))
      .then(() => {
        dispatch(actions.getTenantCostContractDetails(id));

        toast.success('TenantCost Contract Details updated');
      })
      .catch((err) => {
        toast.error(err);
      })
      .finally(() => dispatch(handleClose()));
  };

  const handleSave = () => {
    if (new Date(form.startdate) > new Date(form.enddate)) {
      return toast.error('Start Date needs to be before End Date');
    }

    if (type === 'opex') return handleSaveSupplierOpex();
    if (type === 'asset') return handleSaveSupplierAsset();
    return handleSaveSupplierTenant();
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
      keepMounted
      TransitionComponent={Transition}
      classes={{ paper: classes.paper }}
      maxWidth="xl"
    >
      <Box
        bgcolor="#2C324D"
        p={3}
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        mb={4}
        className={classes.responsiveXL}
      >
        <Typography className={classes.title}>Contract Details</Typography>
        <CloseIcon className={classes.closeIcon} onClick={handleClose} />
      </Box>
      <DialogContent>
        <Supplier form={form} setForm={setForm} />
      </DialogContent>
      <DialogActions>
        <Box p={2} display="flex">
          <CancelButton onClick={handleClose} />
          <SaveButton
            onClick={handleSave}
            loading={loading}
            disabled={
              form.contractType !== 'Supplier' || new Date(form.startdate) > new Date(form.enddate)
            }
          />
        </Box>
      </DialogActions>
    </Dialog>
  );
};

export default ContractDetailsEdit;

ContractDetailsEdit.defaultProps = {
  loading: false
};

ContractDetailsEdit.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  details: PropTypes.objectOf(PropTypes.any).isRequired,
  loading: PropTypes.bool,
  id: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired
};
