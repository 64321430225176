/* eslint-disable no-console */
/* eslint-disable no-unused-vars */
import { assetTypes } from 'constants/index';

import {
  GetTenantByPortfolioIDsRequest,
  GetAssetAggregationRequest,
  AssetRequest
} from 'api/aggregation_grpc_web_pb';
import { IngestRentRollRequest } from 'api/data_ingestion_grpc_web_pb';
import { metaData } from 'config/config';
import { rentrollStorage, ref, listAll, getDownloadURL, getMetadata } from 'config/firebase';
import wrappers from 'google-protobuf/google/protobuf/wrappers_pb';
import { formatForBackEnd } from 'helpers/utils';

import {
  CreateAssetRequest,
  UpdateAssetRequest,
  DeleteAssetRequest,
  GetAssetRequest,
  GetFootfallsByAssetAndMonthRequest,
  CreateFootfallRequest,
  UpdateFootfallRequest
} from '../../api/asset_grpc_web_pb';
import {
  GetUnitsScheduleRequest,
  GetTenantsScheduleRequest,
  GetUnitStackingPlanRequest,
  GetUnitsRequest
} from '../../api/schedule_grpc_web_pb';
import {
  UpdateUnitRequest,
  DeleteUnitRequest,
  CreateUnitRequest,
  CreateUnitsRequest
} from '../../api/unit_grpc_web_pb';
import { AggregationServiceInstance, AggregationService } from '../services/aggregation';
import { AssetService, UnitService, FootfallService, UnitPromiseService } from '../services/asset';
import { DataIngestionService } from '../services/dataIngestion';
import { ScheduleService } from '../services/schedule';

import * as actionTypes from './actionTypes';

const getAssetsStart = () => ({
  type: actionTypes.GET_ASSETS_START
});

const getAssetsSuccess = (assets, bundleId) => ({
  type: actionTypes.GET_ASSETS_SUCCESS,
  assets,
  bundleId
});

const getAssetsFail = (error) => ({
  type: actionTypes.GET_ASSETS_FAIL,
  error
});

export const setAssetsBundle = (bundleId) => ({ type: actionTypes.SET_ASSETS_BUNDLE, bundleId });

export const getAssets = (portfolioIds, bundleId, dispatchBool) => (dispatch) => {
  const request = new GetAssetAggregationRequest();
  request.setPortfolioidsList(portfolioIds ?? []);
  dispatch(getAssetsStart());

  return new Promise((resolve, reject) => {
    AggregationServiceInstance.getAssetAggregation(request, metaData(), (err, response) => {
      if (err) {
        dispatch(getAssetsFail(err.message));
        reject(err.message);
      } else {
        if (!dispatchBool) {
          dispatch(getAssetsSuccess(response.toObject().assetsList, bundleId));
        } else {
          dispatch({
            type: actionTypes.GET_ASSETS_DONE
          });
        }

        resolve(response.toObject().assetsList);
      }
    });
  });
};

// Get Asset KPIs
const getAssetKpiStart = () => ({
  type: actionTypes.GET_ASSET_KPIS_START
});

const getAssetKpiSuccess = (assetKpi) => ({
  type: actionTypes.GET_ASSET_KPIS_SUCCESS,
  assetKpi
});

const getAssetKpiFail = (error) => ({
  type: actionTypes.GET_ASSET_KPIS_FAIL,
  error
});

export const getAssetKpi = (id) => (dispatch) => {
  const request = new AssetRequest();
  request.setAssetid(id);

  dispatch(getAssetKpiStart());

  return new Promise((resolve, reject) => {
    AggregationServiceInstance.getAssetKpis(request, metaData(), (err, response) => {
      if (err) {
        dispatch(getAssetKpiFail(err.message));
        reject(err.message);
      } else {
        dispatch(getAssetKpiSuccess(response.toObject()));

        resolve(response.toObject());
      }
    });
  });
};

// Get Asset Details
const getAssetDetailsStart = () => ({
  type: actionTypes.GET_ASSET_DETAILS_START
});

const getAssetDetailsSuccess = (assetDetails) => ({
  type: actionTypes.GET_ASSET_DETAILS_SUCCESS,
  assetDetails
});

const getAssetDetailFail = (error) => ({
  type: actionTypes.GET_ASSET_DETAILS_FAIL,
  error
});

export const getAssetDetails = (id) => (dispatch) => {
  const request = new GetAssetRequest();
  request.setId(id);

  dispatch(getAssetDetailsStart());

  return new Promise((resolve, reject) => {
    AssetService.getAssetByID(request, metaData(), (err, response) => {
      if (err) {
        dispatch(getAssetDetailFail(err.message));
        reject(err.message);
      } else {
        dispatch(getAssetDetailsSuccess(response.toObject()));

        resolve(response.toObject());
      }
    });
  });
};

export const deleteAsset = (id) => () => {
  const request = new DeleteAssetRequest();
  request.setId(id);

  return new Promise((resolve, reject) => {
    AssetService.deleteAsset(request, metaData(), (err, response) => {
      if (err) {
        reject(err.message);
      } else {
        resolve(response.toObject());
      }
    });
  });
};

// Unit
const getUnitScheduleStart = () => ({
  type: actionTypes.GET_UNITS_SCHEDULE_START
});

const getUnitsScheduleSuccess = (unitSchedule) => ({
  type: actionTypes.GET_UNITS_SCHEDULE_SUCCESS,
  unitSchedule
});

const getUnitsScheduleFail = (error) => ({
  type: actionTypes.GET_UNITS_SCHEDULE_FAIL,
  error
});

export const getUnitSchedule = (id) => (dispatch) => {
  dispatch(getUnitScheduleStart());
  const request = new GetUnitsScheduleRequest();

  request.setAssetid(id);

  return new Promise((resolve, reject) => {
    ScheduleService.getUnitsSchedule(request, metaData(), (err, response) => {
      if (err) {
        dispatch(getUnitsScheduleFail(err.message));
        reject(err.message);
      } else {
        dispatch(getUnitsScheduleSuccess(response.toObject().unitsList));
        resolve(response.toObject());
      }
    });
  });
};

export const createUnit = (form, assetId) => (dispatch) => {
  if (form.unitid) {
    const request = new UpdateUnitRequest();

    const name = new wrappers.StringValue();
    name.setValue(form.name);

    const gla = new wrappers.Int32Value();
    gla.setValue(form.gla);

    const floor = new wrappers.FloatValue();
    floor.setValue(form.floor);

    if (form.building) {
      const building = new wrappers.StringValue();
      building.setValue(form.building);
      request.setBuilding(building);
    }

    request.setId(form.unitid);
    request.setFloor(floor);
    request.setName(name);
    request.setGla(gla);

    return new Promise((resolve, reject) => {
      UnitService.updateUnit(request, metaData(), (err, response) => {
        if (err) {
          reject(err.message);
        } else {
          resolve(response.toObject());
        }
      });
    });
  }

  const request = new CreateUnitRequest();

  if (form.building) {
    const building = new wrappers.StringValue();
    building.setValue(form.building);
    request.setBuilding(building);
  }

  request.setName(form.name);
  request.setFloor(form.floor);
  request.setGla(form.gla);
  request.setAssetid(assetId);

  return new Promise((resolve, reject) => {
    UnitService.createUnit(request, metaData(), (err, response) => {
      if (err) {
        reject(err.message);
      } else {
        resolve(response.toObject());
      }
    });
  });
};

// Tenancy Schedule
const getTenancyScheduleStart = () => ({
  type: actionTypes.GET_TENANCY_SCHEDULE_START
});

const getTenancyScheduleSuccess = (tenancySchedule) => ({
  type: actionTypes.GET_TENANCY_SCHEDULE_SUCCESS,
  tenancySchedule
});

const getTenancyScheduleFail = (error) => ({
  type: actionTypes.GET_TENANCY_SCHEDULE_FAIL,
  error
});

export const getTenancySchedule = (id) => (dispatch) => {
  dispatch(getTenancyScheduleStart());
  const request = new GetTenantsScheduleRequest();

  request.setAssetid(id);

  return new Promise((resolve, reject) => {
    ScheduleService.getTenantSchedule(request, metaData(), (err, response) => {
      if (err) {
        dispatch(getTenancyScheduleFail(err.message));
        reject(err.message);
      } else {
        dispatch(getTenancyScheduleSuccess(response.toObject().unitsList));
        resolve(response.toObject());
      }
    });
  });
};

// Tenant Stacking Plan
const getTenantStackingPlanStart = () => ({
  type: actionTypes.GET_TENANT_STACKING_PLAN_START
});

const getTenantStackingPlanSuccess = (stackingPlan) => ({
  type: actionTypes.GET_TENANT_STACKING_PLAN_SUCCESS,
  stackingPlan
});

const getTenantStackingPlanFail = (error) => ({
  type: actionTypes.GET_TENANT_STACKING_PLAN_FAIL,
  error
});

export const getTenantStackingPlan = (id) => (dispatch) => {
  dispatch(getTenantStackingPlanStart());
  const request = new GetUnitStackingPlanRequest();

  request.setAssetid(id);

  return new Promise((resolve, reject) => {
    ScheduleService.getUnitStackingPlan(request, metaData(), (err, response) => {
      if (err) {
        dispatch(getTenantStackingPlanFail(err.message));
        reject(err.message);
      } else {
        dispatch(getTenantStackingPlanSuccess(response.toObject()));
        resolve(response.toObject());
      }
    });
  });
};

// Unit Stacking Plan
const getUnitStackingPlanStart = () => ({
  type: actionTypes.GET_UNIT_STACKING_PLAN_START
});

const getUnitStackingPlanSuccess = (stackingPlan) => ({
  type: actionTypes.GET_UNIT_STACKING_PLAN_SUCCESS,
  stackingPlan
});

const getUnitStackingPlanFail = (error) => ({
  type: actionTypes.GET_UNIT_STACKING_PLAN_FAIL,
  error
});

export const getUnitStackingPlan = (id) => (dispatch) => {
  dispatch(getUnitStackingPlanStart());
  const request = new GetUnitStackingPlanRequest();

  request.setAssetid(id);

  return new Promise((resolve, reject) => {
    ScheduleService.getUnitStackingPlan(request, metaData(), (err, response) => {
      if (err) {
        dispatch(getUnitStackingPlanFail(err.message));
        reject(err.message);
      } else {
        dispatch(getUnitStackingPlanSuccess(response.toObject()));
        resolve(response.toObject());
      }
    });
  });
};

export const deleteUnit = (unitid) => (dispatch) => {
  const request = new DeleteUnitRequest();

  request.setId(unitid);
  return new Promise((resolve, reject) => {
    UnitService.deleteUnit(request, metaData(), (err, response) => {
      if (err) {
        reject(err.message);
      } else {
        resolve(response.toObject());
      }
    });
  });
};

export const createMultiUnits = (units) => (dispatch) => {
  const request = new CreateUnitsRequest();
  const requestsList = [];
  units.forEach((unit) => {
    const request2 = new CreateUnitRequest();
    request2.setName(unit.name);
    request2.setFloor(unit.floor);
    request2.setGla(unit.gla);
    request2.setAssetid(unit.assetID);

    requestsList.push(request2);
  });

  request.setUnitsList(requestsList);

  return new Promise((resolve, reject) => {
    UnitService.createUnits(request, metaData(), (err, response) => {
      if (err) {
        reject(err.message);
      } else {
        resolve(response.toObject());
      }
    });
  });
};

export const createAsset = (form) => () => {
  const request = new CreateAssetRequest();

  request.setName(form.name);
  request.setCountry(form.country);
  request.setCity(form.city);
  request.setStreet(form.street);
  request.setPostcode(form.postcode);
  request.setType(form.type);
  request.setPortfolioid(form.portfolioID);

  return new Promise((resolve, reject) => {
    AssetService.createAsset(request, metaData(), (err, response) => {
      if (err) {
        reject(err.message);
      } else {
        resolve(response.toObject());
      }
    });
  });
};

export const updateAsset = (form) => () => {
  const request = new UpdateAssetRequest();

  const name = new wrappers.StringValue();
  name.setValue(form.name);

  const country = new wrappers.StringValue();
  country.setValue(form.country);

  const city = new wrappers.StringValue();
  city.setValue(form.city);

  const street = new wrappers.StringValue();
  street.setValue(form.street);

  const postcode = new wrappers.StringValue();
  postcode.setValue(form.postcode);

  const type = new wrappers.StringValue();
  type.setValue(form.type);

  request.setId(form.id);
  request.setName(name);
  request.setCountry(country);
  request.setCity(city);
  request.setStreet(street);
  request.setPostcode(postcode);
  request.setType(type);

  return new Promise((resolve, reject) => {
    AssetService.updateAsset(request, metaData(), (err, response) => {
      if (err) {
        reject(err.message);
      } else {
        resolve(response.toObject());
      }
    });
  });
};

// Footfall on asset level
const getFootfallStart = () => ({
  type: actionTypes.GET_FOOTFALL_START
});

const getFootfallSuccess = (footfall) => ({
  type: actionTypes.GET_FOOTFALL_SUCCESS,
  footfall
});

const getFootfallFail = (error) => ({
  type: actionTypes.GET_FOOTFALL_FAIL,
  error
});

export const getFootfall = (assetId, yearMonth) => (dispatch) => {
  dispatch(getFootfallStart());
  const request = new GetFootfallsByAssetAndMonthRequest();

  request.setAssetid(assetId);
  request.setYearmonth(yearMonth);

  return new Promise((resolve, reject) => {
    FootfallService.getFootfallsByAssetIDAndMonth(request, metaData(), (err, response) => {
      if (err) {
        dispatch(getFootfallFail(err.message));
        reject(err.message);
      } else {
        dispatch(getFootfallSuccess(response.toObject().footfallsList));
        resolve(response.toObject());
      }
    });
  });
};

export const createFootfall = (assetId, footfallCount, date, id) => (dispatch) => {
  if (id) {
    const request = new UpdateFootfallRequest();

    request.setId(id);
    const footfall = new wrappers.Int32Value();
    footfall.setValue(footfallCount);
    request.setFootfall(footfall);

    return new Promise((resolve, reject) => {
      FootfallService.updateFootfall(request, metaData(), (err, response) => {
        if (err) {
          reject(err.message);
        } else {
          resolve(response.toObject());
        }
      });
    });
  }

  const request = new CreateFootfallRequest();

  request.setAssetid(assetId);
  request.setFootfallcount(footfallCount);
  request.setDate(formatForBackEnd(date));
  return new Promise((resolve, reject) => {
    FootfallService.createFootfall(request, metaData(), (err, response) => {
      if (err) {
        reject(err.message);
      } else {
        resolve(response.toObject());
      }
    });
  });
};

const getTenantsByAssetIdSuccess = (tenants) => ({
  type: actionTypes.GET_TENANTS_BYASSETID_SUCCESS,
  tenants
});

export const getTenantsByAssetId = (assetId) => async (dispatch) => {
  const request = new GetTenantByPortfolioIDsRequest();
  request.setAssetsList([assetId]);
  try {
    const result = await AggregationService.getTenantByPortfolioIDs(request, metaData());
    const res = result.toObject().tenantsList;

    dispatch(getTenantsByAssetIdSuccess(res));
    return res;
  } catch (error) {
    throw error.message;
  }
};

const getUnitsByAssetIdSuccess = (units) => ({
  type: actionTypes.GET_UNITS_BYASSETID_SUCCESS,
  units
});

export const getUnitsByAssetId = (assetId) => async (dispatch) => {
  const request = new GetUnitsRequest();
  request.setAssetid(assetId);

  try {
    const result = await UnitPromiseService.getUnits(request, metaData());
    const res = result.toObject().unitsList;

    dispatch(getUnitsByAssetIdSuccess(res));
    return res;
  } catch (error) {
    throw error.message;
  }
};

export const uploadRentroll = (path, assetId) => async () => {
  const request = new IngestRentRollRequest();
  request.setAssetid(assetId);
  request.setPath(path);

  return new Promise((resolve, reject) => {
    DataIngestionService.ingestRentRoll(request, metaData(), (err, response) => {
      if (err) {
        reject(err.message);
      } else {
        resolve(response.toObject());
      }
    });
  });
};

// Asset Images
const getAssetImagesStart = () => ({
  type: actionTypes.GET_IMAGES_LIST_START
});

const getAssetImagesSuccess = (images) => ({
  type: actionTypes.GET_IMAGES_LIST_SUCCESS,
  images
});

const getAssetImagesFail = (error) => ({
  type: actionTypes.GET_IMAGES_LIST_FAIL,
  error
});

export const listImages = (assetId) => async (dispatch) => {
  dispatch(getAssetImagesStart());
  try {
    const listRef = ref(rentrollStorage, assetId);
    const response = await listAll(listRef);
    const files = await response.items.map(async (fileRef) => {
      const fileName = await getDownloadURL(fileRef);
      const fileDate = await getMetadata(fileRef).then((metadata) => metadata.updated);

      return { name: fileRef.name, url: fileName, dateUploaded: fileDate };
    });
    const result = await Promise.all(files);
    dispatch(getAssetImagesSuccess(result));
  } catch (err) {
    dispatch(getAssetImagesFail(err));
  }
};

export const resetAssetDetails = () => ({
  type: actionTypes.RESET_ASSET_DETAILS_STATE
});
