import React, { useEffect } from 'react';

import { Typography, Paper } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Grow from '@material-ui/core/Grow';
import { makeStyles } from '@material-ui/core/styles';
import Spinner from 'components/Spinner';
// import UserHasAccess from 'hooks/UserHasAccess';
import useRouter from 'hooks/useRouter';
import { useDispatch, useSelector } from 'react-redux';
import * as actions from 'store/actions';

import UsersEditForm from './UsersEditForm';

const useStyles = makeStyles((theme) => ({
  headerText: {
    color: theme.palette.text.white,
    fontFamily: theme.fonts.baseSerif
  },
  paper: {
    boxShadow: '0px 3px 14px #0000000F'
  }
}));

const UsersEdit = () => {
  const { query } = useRouter();
  const dispatch = useDispatch();
  const classes = useStyles();

  const userRoles = useSelector((state) => state.user.userRoles);
  const userDetailsLoaded = useSelector((state) => state.users.userDetailsLoaded);
  const userDetailsLoading = useSelector((state) => state.users.userDetailsLoading);
  const userDetails = useSelector((state) => state.users.userDetails);
  // const userPermissions = useSelector((state) => state.user.userPermissions.permissionsList);
  // const EditUsersPermissions = UserHasAccess('users', 'edit', userPermissions);

  useEffect(() => {
    if (query.id) {
      dispatch(actions.getUserDetails(query.id));
    }
  }, [query]);

  if (!userDetailsLoaded || userDetailsLoading) {
    return <Spinner />;
  }

  return (
    <Grow in>
      <Box
        height="100%"
        display="flex"
        alignItems="center"
        mt={2}
        flexDirection="column"
        borderRadius={4}
      >
        <Paper elevation={0} className={classes.paper}>
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            bgcolor="#2C324D"
            padding={2}
            marginBottom={2}
          >
            <Typography variant="h5" className={classes.headerText}>
              Edit User
            </Typography>
          </Box>
          <UsersEditForm userDetails={userDetails} userRoles={userRoles} editRights />
        </Paper>
      </Box>
    </Grow>
  );
};

export default UsersEdit;
