/* eslint-disable no-new */
import React, { useEffect, useMemo, Suspense, useState } from 'react';

import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { Skeleton } from '@material-ui/lab';
import Select from 'components/Select';
import Spinner from 'components/Spinner';
import useLocalStorage from 'hooks/useLocalStorage';
import useUrlParams from 'hooks/useUrlParams';
import { useSelector, useDispatch } from 'react-redux';
import * as actions from 'store/actions';

import CustomAutocomplete from './CustomAutocomplete';
import GraphCard from './GraphCard';
import InfoPanel from './InfoPanel';
import Map from './Map/Map';
import useStyles from './styles';
import Tasks from './Tasks';

const SkeletonComponent = (
  <Skeleton
    variant="rect"
    width="100%"
    animation="wave"
    height="100%"
    style={{ borderRadius: '10px' }}
  />
);

const HomeScreen = () => {
  const classes = useStyles();
  const {
    homeData,
    loaded: homeDataLoaded,
    homeDataYears: yearOptions,
    yearFilter: year
  } = useSelector((state) => state.home);
  const { data: coordinates } = useSelector((state) => state.home.geoData);
  const { data, loaded: chartDataLoaded } = useSelector((state) => state.home.chartData);
  const { portfolios } = useSelector((state) => state.portfolios);
  const { data: assets } = useSelector((state) => state.assets.assets);
  const { portfolioIds, setPortfolios, assetIds, setAssetIds } = useUrlParams();
  const { storedValue, setValue } = useLocalStorage('assetId', '');
  const [assetId, setAssetId] = useState(storedValue);

  const dispatch = useDispatch();

  const getFilters = useMemo(
    () => ({
      PortfolioID: portfolioIds,
      AssetID: assetIds,
      Year: [year]
    }),
    [portfolioIds, assetIds, year]
  );

  const getCoordinates = useMemo(
    () =>
      coordinates
        ?.filter((obj) => assets.find((asset) => asset.id === obj.assetid))
        .map((obj, i) => ({
          type: 'Feature',
          properties: {
            cluster: false,
            id: i,
            asset: assets.find((asset) => asset.id === obj.assetid)
          },
          geometry: {
            type: 'Point',
            coordinates: [parseFloat(obj.longitude), parseFloat(obj.latitude)]
          }
        })),
    [coordinates]
  );

  useEffect(() => {
    dispatch(actions.getAssets(portfolioIds)).then((resp) => {
      setAssetId(resp[0].id);
      setValue(assetIds.length ? assetIds[0] : resp[0].id);
      const geoFilters = getFilters;

      geoFilters.Year = [];

      dispatch(actions.getGeoData(geoFilters));
    });
    dispatch(actions.getChartData(getFilters));
    dispatch(actions.getAlertsList(portfolioIds, assetIds));
  }, [portfolioIds, assetIds, year]);

  useEffect(() => {
    dispatch(actions.getFilterYears());
    dispatch(actions.getHomeData());
    dispatch(actions.getAlertsCount());
  }, []);

  useEffect(() => {
    const [firstId] = assetIds;

    if (firstId === storedValue || firstId === undefined) return;

    setValue(firstId);
  }, [assetIds]);

  const handleSelectYear = (e) => {
    dispatch(actions.setYearFilter(e.target.value));
  };

  if (!homeDataLoaded && !chartDataLoaded) {
    return <Spinner />;
  }

  return (
    <Box className={classes.paddingX}>
      <InfoPanel data={homeData} />
      <Box className={classes.filterWrapper}>
        <CustomAutocomplete
          items={portfolios}
          updateUrlParams={setPortfolios}
          urlParams={portfolioIds}
          label="Portfolios"
          placeholder="Portfolios"
          disabled={!!assetIds.length}
        />
        <CustomAutocomplete
          items={assets}
          updateUrlParams={setAssetIds}
          urlParams={assetIds}
          label="Assets"
          placeholder="Assets"
        />
        <Select
          options={yearOptions}
          label="Year"
          keyword="label"
          value={year.toString()}
          handleChange={handleSelectYear}
          minWidth={400}
          variant="outlined"
          margin="0"
        />
      </Box>
      <Grid container spacing={4}>
        <Grid item xs={12} sm={12} md={8}>
          <Box className={classes.mapBox}>
            <Map
              coordinates={getCoordinates}
              defaulCenter={[50.07, 14.43]}
              defaultZoom={3}
              mapType="hybrid"
            />
          </Box>
        </Grid>
        <Grid item xs={12} sm={12} md={4}>
          <Tasks />
        </Grid>
      </Grid>
      <Box className={classes.boxHeading}>
        <Typography variant="h5">Income</Typography>
      </Box>
      <Grid container spacing={4}>
        <Grid item xs={12} md={4}>
          <Suspense fallback={SkeletonComponent}>
            <GraphCard
              dataCy="base-rent"
              to={`/BaseRent/Details/${storedValue || assetId}`}
              from={window.location.search}
              totalValue={data?.BaseRent.currentmonthrent}
              prevTotalValue={data?.BaseRent.lastmonthrent}
              series={[
                {
                  name: 'Base Rent Forecasts',
                  data: data?.BaseRent.forecastsList || []
                },
                {
                  name: 'Base Rent Actuals',
                  data: data?.BaseRent.actualforecastsList || []
                }
              ]}
              name="Base Rent"
            />
          </Suspense>
        </Grid>
        <Grid item xs={12} md={4}>
          <Suspense fallback={SkeletonComponent}>
            <GraphCard
              dataCy="service-charge"
              to={`/ServiceCharge/Details/${storedValue || assetId}`}
              from={window.location.search}
              totalValue={data?.ServiceCharge.currentmonthrent}
              prevTotalValue={data?.ServiceCharge.lastmonthrent}
              series={[
                {
                  name: 'Service Charge Forecasts',
                  data: data?.ServiceCharge.forecastsList || []
                },
                {
                  name: 'Service Charge Actuals',
                  data: data?.ServiceCharge.actualforecastsList || []
                }
              ]}
              name="Service Charge"
            />
          </Suspense>
        </Grid>
        <Grid item xs={12} md={4}>
          <Suspense fallback={SkeletonComponent}>
            <GraphCard
              dataCy="other-income"
              to={`/OtherIncome/Details/${storedValue || assetId}`}
              from={window.location.search}
              totalValue={data?.OtherIncome.currentmonthrent}
              prevTotalValue={data?.OtherIncome.lastmonthrent}
              series={[
                {
                  name: 'Other Income Forecasts',
                  data: data?.OtherIncome.forecastsList || []
                },
                {
                  name: 'Other Income Actuals',
                  data: data?.OtherIncome.actualforecastsList || []
                }
              ]}
              name="Other Income"
            />
          </Suspense>
        </Grid>
        <Grid item xs={12} md={4}>
          <Suspense fallback={SkeletonComponent}>
            <GraphCard
              dataCy="cumulative-rent"
              to={`/CumulativeRent/Details/${storedValue || assetId}`}
              from={window.location.search}
              totalValue={data?.CumulativeRent.currentmonthrent}
              prevTotalValue={data?.CumulativeRent.lastmonthrent}
              series={[
                {
                  name: 'Cumulative Rent Forecasts',
                  data: data?.CumulativeRent.forecastsList || []
                },
                {
                  name: 'Cumulative Rent Actuals',
                  data: data?.CumulativeRent.actualforecastsList || []
                }
              ]}
              name="Cumulative Rent"
            />
          </Suspense>
        </Grid>
        <Grid item xs={12} md={4}>
          <Suspense fallback={SkeletonComponent}>
            <GraphCard
              dataCy="turnover-rent"
              to={`/TurnoverRent/Details/${storedValue || assetId}`}
              from={window.location.search}
              totalValue={data?.TurnoverRent.currentmonthrent}
              prevTotalValue={data?.TurnoverRent.lastmonthrent}
              series={[
                {
                  name: 'Turnover Rent Forecasts',
                  data: data?.TurnoverRent.forecastsList || []
                },
                {
                  name: 'Turnover Rent Actuals',
                  data: data?.TurnoverRent.actualforecastsList || []
                }
              ]}
              name="Turnover Rent"
            />
          </Suspense>
        </Grid>
      </Grid>
      <Box className={classes.boxHeading}>
        <Typography variant="h5">Expenses</Typography>
      </Box>
      <Grid container spacing={4}>
        <Grid item xs={12} md={4}>
          <Suspense fallback={SkeletonComponent}>
            <GraphCard
              dataCy="opex"
              to={`/Opex/Details/${storedValue || assetId}`}
              from={window.location.search}
              totalValue={data?.Opex.currentmonthrent}
              prevTotalValue={data?.Opex.lastmonthrent}
              series={[
                {
                  name: 'Opex Forecasts',
                  data: data?.Opex.forecastsList || []
                },
                {
                  name: 'Opex Actuals',
                  data: data?.Opex.actualforecastsList || []
                }
              ]}
              name="Opex"
            />
          </Suspense>
        </Grid>
        <Grid item xs={12} md={4}>
          <Suspense fallback={SkeletonComponent}>
            <GraphCard
              dataCy="capex"
              to={`/Capex/Details/${storedValue || assetId}`}
              from={window.location.search}
              totalValue={data?.Capex.currentmonthrent}
              prevTotalValue={data?.Capex.lastmonthrent}
              series={[
                {
                  name: 'Capex Forecasts',
                  data: data?.Capex.forecastsList || []
                },
                {
                  name: 'Capex Actuals',
                  data: data?.Capex.actualforecastsList || []
                }
              ]}
              name="Capex"
            />
          </Suspense>
        </Grid>
        <Grid item xs={12} md={4}>
          <Suspense fallback={SkeletonComponent}>
            <GraphCard
              dataCy="other-expenses"
              to={`/OtherExpenses/Details/${storedValue || assetId}`}
              from={window.location.search}
              totalValue={data?.OtherExpenses.currentmonthrent}
              prevTotalValue={data?.OtherExpenses.lastmonthrent}
              series={[
                {
                  name: 'Other Expenses Forecasts',
                  data: data?.OtherExpenses.forecastsList || []
                },
                {
                  name: 'Other Expenses Actuals',
                  data: data?.OtherExpenses.actualforecastsList || []
                }
              ]}
              name="Other Expenses"
            />
          </Suspense>
        </Grid>
      </Grid>
      <Box className={classes.boxHeading}>
        <Typography variant="h5">KPI&apos;s</Typography>
      </Box>
      <Grid container spacing={4}>
        <Grid item xs={12} md={4}>
          <Suspense fallback={SkeletonComponent}>
            <GraphCard
              dataCy="noi"
              to={`/NOI/Details/${storedValue || assetId}`}
              from={window.location.search}
              totalValue={data?.NOI.currentmonthrent}
              prevTotalValue={data?.NOI.lastmonthrent}
              series={[
                {
                  name: 'NOI Forecasts',
                  data: data?.NOI.forecastsList || []
                },
                {
                  name: 'NOI Actuals',
                  data: data?.NOI.actualforecastsList || []
                }
              ]}
              name="NOI"
            />
          </Suspense>
        </Grid>
      </Grid>
    </Box>
  );
};

export default HomeScreen;
