import { storageService, ref, uploadBytesResumable, deleteObject } from 'config/firebase';

export function UploadFiles(files, pathName) {
  const promises = files.map((file) => {
    const storageRef = ref(storageService, `${pathName}/${file.name}`);
    return uploadBytesResumable(storageRef, file);
  });

  return Promise.all(promises);
}

export async function DeleteFile(name, pathName) {
  const itemRef = ref(storageService, `${pathName}/${name}`);

  try {
    const result = await deleteObject(itemRef);
    return result;
  } catch (error) {
    throw error.message;
  }
}
