import React, { useEffect, Suspense } from 'react';

import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Grow from '@material-ui/core/Grow';
import Icon from '@material-ui/core/Icon';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import TableContainer from '@material-ui/core/TableContainer';
import Typography from '@material-ui/core/Typography';
import { openModal } from 'components/Modal/modalReducer';
import Spinner from 'components/Spinner';
import useRouter from 'hooks/useRouter';
import { useDispatch, useSelector } from 'react-redux';
import * as actions from 'store/actions';

import ValuationsTable from './ValuationsTable';

const useStyles = makeStyles((theme) => ({
  ctaButton: {
    borderRadius: 60,
    height: '100%',
    width: theme.spacing(17),
    color: theme.palette.primary.main,
    background: theme.palette.common.white,
    fontWeight: 'bold',
    boxShadow: 'none',
    border: '1px solid #5381EF',
    '&:hover': {
      backgroundColor: theme.palette.secondary.main,
      color: theme.palette.common.white
    },
    '&:hover .MuiIcon-root': {
      color: 'white !important'
    },
    '&:hover .MuiIconButton-root:hover': {
      backgroundColor: 'transparent !important'
    }
  }
}));
const Valuations = () => {
  const { query } = useRouter();
  const dispatch = useDispatch();
  const classes = useStyles();
  const { valuations, loading, loaded, error } = useSelector((state) => state.valuations);
  const { data: asset } = useSelector((state) => state.assets.assetDetails);

  useEffect(() => {
    if (!query.id || (query.id === asset.id && loaded)) {
      return;
    }
    dispatch(actions.getValuations(query.id));
  }, []);

  const handleNew = () => {
    dispatch(
      openModal({
        modalType: 'ValuationModal',
        modalProps: {
          valuations
        }
      })
    );
  };

  if (!loaded || loading) {
    return <Spinner />;
  }

  if (error) {
    return (
      <Typography variant="h6" color="error" style={{ marginTop: 50, marginLeft: 50 }}>
        {error}
      </Typography>
    );
  }

  return (
    <Grow in>
      <TableContainer component={Paper}>
        <Box style={{ display: 'flex', padding: '30px 30px 0 30px' }}>
          <Button
            className={classes.ctaButton}
            style={{ marginRight: '10px' }}
            startIcon={<Icon style={{ color: '#5381EF' }}>add</Icon>}
            onClick={handleNew}
          >
            VALUATION
          </Button>
        </Box>
        <Suspense fallback={<Spinner />}>
          <ValuationsTable data={valuations} loading={loading} shadow={false} />
        </Suspense>
      </TableContainer>
    </Grow>
  );
};

export default Valuations;
