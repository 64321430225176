/**
 * @fileoverview gRPC-Web generated client stub for external
 * @enhanceable
 * @public
 */

// GENERATED CODE -- DO NOT EDIT!


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');


var google_protobuf_empty_pb = require('google-protobuf/google/protobuf/empty_pb.js')

var google_protobuf_wrappers_pb = require('google-protobuf/google/protobuf/wrappers_pb.js')
const proto = {};
proto.external = require('./contract_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.external.ContractServiceClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options['format'] = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.external.ContractServicePromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options['format'] = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.external.CreateContractRequest,
 *   !proto.external.CreateContractResponse>}
 */
const methodDescriptor_ContractService_CreateContract = new grpc.web.MethodDescriptor(
  '/external.ContractService/CreateContract',
  grpc.web.MethodType.UNARY,
  proto.external.CreateContractRequest,
  proto.external.CreateContractResponse,
  /**
   * @param {!proto.external.CreateContractRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.external.CreateContractResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.external.CreateContractRequest,
 *   !proto.external.CreateContractResponse>}
 */
const methodInfo_ContractService_CreateContract = new grpc.web.AbstractClientBase.MethodInfo(
  proto.external.CreateContractResponse,
  /**
   * @param {!proto.external.CreateContractRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.external.CreateContractResponse.deserializeBinary
);


/**
 * @param {!proto.external.CreateContractRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.external.CreateContractResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.external.CreateContractResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.external.ContractServiceClient.prototype.createContract =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/external.ContractService/CreateContract',
      request,
      metadata || {},
      methodDescriptor_ContractService_CreateContract,
      callback);
};


/**
 * @param {!proto.external.CreateContractRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.external.CreateContractResponse>}
 *     Promise that resolves to the response
 */
proto.external.ContractServicePromiseClient.prototype.createContract =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/external.ContractService/CreateContract',
      request,
      metadata || {},
      methodDescriptor_ContractService_CreateContract);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.external.GetContractRequest,
 *   !proto.external.ContractResponse>}
 */
const methodDescriptor_ContractService_GetContractByID = new grpc.web.MethodDescriptor(
  '/external.ContractService/GetContractByID',
  grpc.web.MethodType.UNARY,
  proto.external.GetContractRequest,
  proto.external.ContractResponse,
  /**
   * @param {!proto.external.GetContractRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.external.ContractResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.external.GetContractRequest,
 *   !proto.external.ContractResponse>}
 */
const methodInfo_ContractService_GetContractByID = new grpc.web.AbstractClientBase.MethodInfo(
  proto.external.ContractResponse,
  /**
   * @param {!proto.external.GetContractRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.external.ContractResponse.deserializeBinary
);


/**
 * @param {!proto.external.GetContractRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.external.ContractResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.external.ContractResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.external.ContractServiceClient.prototype.getContractByID =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/external.ContractService/GetContractByID',
      request,
      metadata || {},
      methodDescriptor_ContractService_GetContractByID,
      callback);
};


/**
 * @param {!proto.external.GetContractRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.external.ContractResponse>}
 *     Promise that resolves to the response
 */
proto.external.ContractServicePromiseClient.prototype.getContractByID =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/external.ContractService/GetContractByID',
      request,
      metadata || {},
      methodDescriptor_ContractService_GetContractByID);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.external.GetContractsRequest,
 *   !proto.external.GetContractsResponse>}
 */
const methodDescriptor_ContractService_GetContractsByTenant = new grpc.web.MethodDescriptor(
  '/external.ContractService/GetContractsByTenant',
  grpc.web.MethodType.UNARY,
  proto.external.GetContractsRequest,
  proto.external.GetContractsResponse,
  /**
   * @param {!proto.external.GetContractsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.external.GetContractsResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.external.GetContractsRequest,
 *   !proto.external.GetContractsResponse>}
 */
const methodInfo_ContractService_GetContractsByTenant = new grpc.web.AbstractClientBase.MethodInfo(
  proto.external.GetContractsResponse,
  /**
   * @param {!proto.external.GetContractsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.external.GetContractsResponse.deserializeBinary
);


/**
 * @param {!proto.external.GetContractsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.external.GetContractsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.external.GetContractsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.external.ContractServiceClient.prototype.getContractsByTenant =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/external.ContractService/GetContractsByTenant',
      request,
      metadata || {},
      methodDescriptor_ContractService_GetContractsByTenant,
      callback);
};


/**
 * @param {!proto.external.GetContractsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.external.GetContractsResponse>}
 *     Promise that resolves to the response
 */
proto.external.ContractServicePromiseClient.prototype.getContractsByTenant =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/external.ContractService/GetContractsByTenant',
      request,
      metadata || {},
      methodDescriptor_ContractService_GetContractsByTenant);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.external.UpdateContractRequest,
 *   !proto.google.protobuf.Empty>}
 */
const methodDescriptor_ContractService_UpdateContract = new grpc.web.MethodDescriptor(
  '/external.ContractService/UpdateContract',
  grpc.web.MethodType.UNARY,
  proto.external.UpdateContractRequest,
  google_protobuf_empty_pb.Empty,
  /**
   * @param {!proto.external.UpdateContractRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  google_protobuf_empty_pb.Empty.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.external.UpdateContractRequest,
 *   !proto.google.protobuf.Empty>}
 */
const methodInfo_ContractService_UpdateContract = new grpc.web.AbstractClientBase.MethodInfo(
  google_protobuf_empty_pb.Empty,
  /**
   * @param {!proto.external.UpdateContractRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  google_protobuf_empty_pb.Empty.deserializeBinary
);


/**
 * @param {!proto.external.UpdateContractRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.google.protobuf.Empty)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.google.protobuf.Empty>|undefined}
 *     The XHR Node Readable Stream
 */
proto.external.ContractServiceClient.prototype.updateContract =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/external.ContractService/UpdateContract',
      request,
      metadata || {},
      methodDescriptor_ContractService_UpdateContract,
      callback);
};


/**
 * @param {!proto.external.UpdateContractRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.google.protobuf.Empty>}
 *     Promise that resolves to the response
 */
proto.external.ContractServicePromiseClient.prototype.updateContract =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/external.ContractService/UpdateContract',
      request,
      metadata || {},
      methodDescriptor_ContractService_UpdateContract);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.external.DeleteContractRequest,
 *   !proto.google.protobuf.Empty>}
 */
const methodDescriptor_ContractService_DeleteContract = new grpc.web.MethodDescriptor(
  '/external.ContractService/DeleteContract',
  grpc.web.MethodType.UNARY,
  proto.external.DeleteContractRequest,
  google_protobuf_empty_pb.Empty,
  /**
   * @param {!proto.external.DeleteContractRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  google_protobuf_empty_pb.Empty.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.external.DeleteContractRequest,
 *   !proto.google.protobuf.Empty>}
 */
const methodInfo_ContractService_DeleteContract = new grpc.web.AbstractClientBase.MethodInfo(
  google_protobuf_empty_pb.Empty,
  /**
   * @param {!proto.external.DeleteContractRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  google_protobuf_empty_pb.Empty.deserializeBinary
);


/**
 * @param {!proto.external.DeleteContractRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.google.protobuf.Empty)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.google.protobuf.Empty>|undefined}
 *     The XHR Node Readable Stream
 */
proto.external.ContractServiceClient.prototype.deleteContract =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/external.ContractService/DeleteContract',
      request,
      metadata || {},
      methodDescriptor_ContractService_DeleteContract,
      callback);
};


/**
 * @param {!proto.external.DeleteContractRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.google.protobuf.Empty>}
 *     Promise that resolves to the response
 */
proto.external.ContractServicePromiseClient.prototype.deleteContract =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/external.ContractService/DeleteContract',
      request,
      metadata || {},
      methodDescriptor_ContractService_DeleteContract);
};


module.exports = proto.external;

