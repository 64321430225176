/**
 * @fileoverview gRPC-Web generated client stub for external
 * @enhanceable
 * @public
 */

// GENERATED CODE -- DO NOT EDIT!


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');

const proto = {};
proto.external = require('./data_export_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.external.DataExportServiceClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options['format'] = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.external.DataExportServicePromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options['format'] = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.external.ExportRentRollRequest,
 *   !proto.external.ExportRentRollResponse>}
 */
const methodDescriptor_DataExportService_ExportRentRoll = new grpc.web.MethodDescriptor(
  '/external.DataExportService/ExportRentRoll',
  grpc.web.MethodType.UNARY,
  proto.external.ExportRentRollRequest,
  proto.external.ExportRentRollResponse,
  /**
   * @param {!proto.external.ExportRentRollRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.external.ExportRentRollResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.external.ExportRentRollRequest,
 *   !proto.external.ExportRentRollResponse>}
 */
const methodInfo_DataExportService_ExportRentRoll = new grpc.web.AbstractClientBase.MethodInfo(
  proto.external.ExportRentRollResponse,
  /**
   * @param {!proto.external.ExportRentRollRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.external.ExportRentRollResponse.deserializeBinary
);


/**
 * @param {!proto.external.ExportRentRollRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.external.ExportRentRollResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.external.ExportRentRollResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.external.DataExportServiceClient.prototype.exportRentRoll =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/external.DataExportService/ExportRentRoll',
      request,
      metadata || {},
      methodDescriptor_DataExportService_ExportRentRoll,
      callback);
};


/**
 * @param {!proto.external.ExportRentRollRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.external.ExportRentRollResponse>}
 *     Promise that resolves to the response
 */
proto.external.DataExportServicePromiseClient.prototype.exportRentRoll =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/external.DataExportService/ExportRentRoll',
      request,
      metadata || {},
      methodDescriptor_DataExportService_ExportRentRoll);
};


module.exports = proto.external;

