import React from 'react';

import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

const useStyles = makeStyles((theme) => ({
  ctaButton: {
    height: 40,
    borderRadius: 60,
    width: theme.spacing(18),
    color: theme.palette.secondary.main,
    '&:hover': {
      backgroundColor: theme.palette.grey
    },
    margin: theme.spacing(0, 3),
    fontFamily: theme.fonts.base
  }
}));
const CancelButton = ({ onClick, ...rest }) => {
  const classes = useStyles();
  return (
    <Button variant="text" className={classes.ctaButton} onClick={onClick} {...rest}>
      Cancel
    </Button>
  );
};

export default CancelButton;

CancelButton.defaultProps = {};

CancelButton.propTypes = {
  onClick: PropTypes.func.isRequired
};
