/**
 * @fileoverview gRPC-Web generated client stub for external
 * @enhanceable
 * @public
 */

// GENERATED CODE -- DO NOT EDIT!


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');


var google_protobuf_empty_pb = require('google-protobuf/google/protobuf/empty_pb.js')
const proto = {};
proto.external = require('./aggregation_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.external.AggregationServiceClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options['format'] = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.external.AggregationServicePromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options['format'] = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.external.AssetRequest,
 *   !proto.external.AssetKpiResponse>}
 */
const methodDescriptor_AggregationService_GetAssetKpis = new grpc.web.MethodDescriptor(
  '/external.AggregationService/GetAssetKpis',
  grpc.web.MethodType.UNARY,
  proto.external.AssetRequest,
  proto.external.AssetKpiResponse,
  /**
   * @param {!proto.external.AssetRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.external.AssetKpiResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.external.AssetRequest,
 *   !proto.external.AssetKpiResponse>}
 */
const methodInfo_AggregationService_GetAssetKpis = new grpc.web.AbstractClientBase.MethodInfo(
  proto.external.AssetKpiResponse,
  /**
   * @param {!proto.external.AssetRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.external.AssetKpiResponse.deserializeBinary
);


/**
 * @param {!proto.external.AssetRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.external.AssetKpiResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.external.AssetKpiResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.external.AggregationServiceClient.prototype.getAssetKpis =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/external.AggregationService/GetAssetKpis',
      request,
      metadata || {},
      methodDescriptor_AggregationService_GetAssetKpis,
      callback);
};


/**
 * @param {!proto.external.AssetRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.external.AssetKpiResponse>}
 *     Promise that resolves to the response
 */
proto.external.AggregationServicePromiseClient.prototype.getAssetKpis =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/external.AggregationService/GetAssetKpis',
      request,
      metadata || {},
      methodDescriptor_AggregationService_GetAssetKpis);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.external.GetTenantHeaderRequest,
 *   !proto.external.GetTenantHeaderResponse>}
 */
const methodDescriptor_AggregationService_GetTenantHeader = new grpc.web.MethodDescriptor(
  '/external.AggregationService/GetTenantHeader',
  grpc.web.MethodType.UNARY,
  proto.external.GetTenantHeaderRequest,
  proto.external.GetTenantHeaderResponse,
  /**
   * @param {!proto.external.GetTenantHeaderRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.external.GetTenantHeaderResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.external.GetTenantHeaderRequest,
 *   !proto.external.GetTenantHeaderResponse>}
 */
const methodInfo_AggregationService_GetTenantHeader = new grpc.web.AbstractClientBase.MethodInfo(
  proto.external.GetTenantHeaderResponse,
  /**
   * @param {!proto.external.GetTenantHeaderRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.external.GetTenantHeaderResponse.deserializeBinary
);


/**
 * @param {!proto.external.GetTenantHeaderRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.external.GetTenantHeaderResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.external.GetTenantHeaderResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.external.AggregationServiceClient.prototype.getTenantHeader =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/external.AggregationService/GetTenantHeader',
      request,
      metadata || {},
      methodDescriptor_AggregationService_GetTenantHeader,
      callback);
};


/**
 * @param {!proto.external.GetTenantHeaderRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.external.GetTenantHeaderResponse>}
 *     Promise that resolves to the response
 */
proto.external.AggregationServicePromiseClient.prototype.getTenantHeader =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/external.AggregationService/GetTenantHeader',
      request,
      metadata || {},
      methodDescriptor_AggregationService_GetTenantHeader);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.external.GetTenantByPortfolioIDsRequest,
 *   !proto.external.GetTenantByPortfolioIDsResponse>}
 */
const methodDescriptor_AggregationService_GetTenantByPortfolioIDs = new grpc.web.MethodDescriptor(
  '/external.AggregationService/GetTenantByPortfolioIDs',
  grpc.web.MethodType.UNARY,
  proto.external.GetTenantByPortfolioIDsRequest,
  proto.external.GetTenantByPortfolioIDsResponse,
  /**
   * @param {!proto.external.GetTenantByPortfolioIDsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.external.GetTenantByPortfolioIDsResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.external.GetTenantByPortfolioIDsRequest,
 *   !proto.external.GetTenantByPortfolioIDsResponse>}
 */
const methodInfo_AggregationService_GetTenantByPortfolioIDs = new grpc.web.AbstractClientBase.MethodInfo(
  proto.external.GetTenantByPortfolioIDsResponse,
  /**
   * @param {!proto.external.GetTenantByPortfolioIDsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.external.GetTenantByPortfolioIDsResponse.deserializeBinary
);


/**
 * @param {!proto.external.GetTenantByPortfolioIDsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.external.GetTenantByPortfolioIDsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.external.GetTenantByPortfolioIDsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.external.AggregationServiceClient.prototype.getTenantByPortfolioIDs =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/external.AggregationService/GetTenantByPortfolioIDs',
      request,
      metadata || {},
      methodDescriptor_AggregationService_GetTenantByPortfolioIDs,
      callback);
};


/**
 * @param {!proto.external.GetTenantByPortfolioIDsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.external.GetTenantByPortfolioIDsResponse>}
 *     Promise that resolves to the response
 */
proto.external.AggregationServicePromiseClient.prototype.getTenantByPortfolioIDs =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/external.AggregationService/GetTenantByPortfolioIDs',
      request,
      metadata || {},
      methodDescriptor_AggregationService_GetTenantByPortfolioIDs);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.external.GetAssetAggregationRequest,
 *   !proto.external.AssetAggregationResponse>}
 */
const methodDescriptor_AggregationService_GetAssetAggregation = new grpc.web.MethodDescriptor(
  '/external.AggregationService/GetAssetAggregation',
  grpc.web.MethodType.UNARY,
  proto.external.GetAssetAggregationRequest,
  proto.external.AssetAggregationResponse,
  /**
   * @param {!proto.external.GetAssetAggregationRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.external.AssetAggregationResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.external.GetAssetAggregationRequest,
 *   !proto.external.AssetAggregationResponse>}
 */
const methodInfo_AggregationService_GetAssetAggregation = new grpc.web.AbstractClientBase.MethodInfo(
  proto.external.AssetAggregationResponse,
  /**
   * @param {!proto.external.GetAssetAggregationRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.external.AssetAggregationResponse.deserializeBinary
);


/**
 * @param {!proto.external.GetAssetAggregationRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.external.AssetAggregationResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.external.AssetAggregationResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.external.AggregationServiceClient.prototype.getAssetAggregation =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/external.AggregationService/GetAssetAggregation',
      request,
      metadata || {},
      methodDescriptor_AggregationService_GetAssetAggregation,
      callback);
};


/**
 * @param {!proto.external.GetAssetAggregationRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.external.AssetAggregationResponse>}
 *     Promise that resolves to the response
 */
proto.external.AggregationServicePromiseClient.prototype.getAssetAggregation =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/external.AggregationService/GetAssetAggregation',
      request,
      metadata || {},
      methodDescriptor_AggregationService_GetAssetAggregation);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.google.protobuf.Empty,
 *   !proto.external.PortfoliosAggregationResponse>}
 */
const methodDescriptor_AggregationService_GetPortfoliosAggregation = new grpc.web.MethodDescriptor(
  '/external.AggregationService/GetPortfoliosAggregation',
  grpc.web.MethodType.UNARY,
  google_protobuf_empty_pb.Empty,
  proto.external.PortfoliosAggregationResponse,
  /**
   * @param {!proto.google.protobuf.Empty} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.external.PortfoliosAggregationResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.google.protobuf.Empty,
 *   !proto.external.PortfoliosAggregationResponse>}
 */
const methodInfo_AggregationService_GetPortfoliosAggregation = new grpc.web.AbstractClientBase.MethodInfo(
  proto.external.PortfoliosAggregationResponse,
  /**
   * @param {!proto.google.protobuf.Empty} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.external.PortfoliosAggregationResponse.deserializeBinary
);


/**
 * @param {!proto.google.protobuf.Empty} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.external.PortfoliosAggregationResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.external.PortfoliosAggregationResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.external.AggregationServiceClient.prototype.getPortfoliosAggregation =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/external.AggregationService/GetPortfoliosAggregation',
      request,
      metadata || {},
      methodDescriptor_AggregationService_GetPortfoliosAggregation,
      callback);
};


/**
 * @param {!proto.google.protobuf.Empty} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.external.PortfoliosAggregationResponse>}
 *     Promise that resolves to the response
 */
proto.external.AggregationServicePromiseClient.prototype.getPortfoliosAggregation =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/external.AggregationService/GetPortfoliosAggregation',
      request,
      metadata || {},
      methodDescriptor_AggregationService_GetPortfoliosAggregation);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.external.GetTotalsByTypesRequest,
 *   !proto.external.GetTotalsByTypesResponse>}
 */
const methodDescriptor_AggregationService_GetTotalsByTypes = new grpc.web.MethodDescriptor(
  '/external.AggregationService/GetTotalsByTypes',
  grpc.web.MethodType.UNARY,
  proto.external.GetTotalsByTypesRequest,
  proto.external.GetTotalsByTypesResponse,
  /**
   * @param {!proto.external.GetTotalsByTypesRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.external.GetTotalsByTypesResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.external.GetTotalsByTypesRequest,
 *   !proto.external.GetTotalsByTypesResponse>}
 */
const methodInfo_AggregationService_GetTotalsByTypes = new grpc.web.AbstractClientBase.MethodInfo(
  proto.external.GetTotalsByTypesResponse,
  /**
   * @param {!proto.external.GetTotalsByTypesRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.external.GetTotalsByTypesResponse.deserializeBinary
);


/**
 * @param {!proto.external.GetTotalsByTypesRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.external.GetTotalsByTypesResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.external.GetTotalsByTypesResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.external.AggregationServiceClient.prototype.getTotalsByTypes =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/external.AggregationService/GetTotalsByTypes',
      request,
      metadata || {},
      methodDescriptor_AggregationService_GetTotalsByTypes,
      callback);
};


/**
 * @param {!proto.external.GetTotalsByTypesRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.external.GetTotalsByTypesResponse>}
 *     Promise that resolves to the response
 */
proto.external.AggregationServicePromiseClient.prototype.getTotalsByTypes =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/external.AggregationService/GetTotalsByTypes',
      request,
      metadata || {},
      methodDescriptor_AggregationService_GetTotalsByTypes);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.external.GetBreadCrumbsRequest,
 *   !proto.external.GetBreadCrumbsResponse>}
 */
const methodDescriptor_AggregationService_GetBreadCrumbs = new grpc.web.MethodDescriptor(
  '/external.AggregationService/GetBreadCrumbs',
  grpc.web.MethodType.UNARY,
  proto.external.GetBreadCrumbsRequest,
  proto.external.GetBreadCrumbsResponse,
  /**
   * @param {!proto.external.GetBreadCrumbsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.external.GetBreadCrumbsResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.external.GetBreadCrumbsRequest,
 *   !proto.external.GetBreadCrumbsResponse>}
 */
const methodInfo_AggregationService_GetBreadCrumbs = new grpc.web.AbstractClientBase.MethodInfo(
  proto.external.GetBreadCrumbsResponse,
  /**
   * @param {!proto.external.GetBreadCrumbsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.external.GetBreadCrumbsResponse.deserializeBinary
);


/**
 * @param {!proto.external.GetBreadCrumbsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.external.GetBreadCrumbsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.external.GetBreadCrumbsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.external.AggregationServiceClient.prototype.getBreadCrumbs =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/external.AggregationService/GetBreadCrumbs',
      request,
      metadata || {},
      methodDescriptor_AggregationService_GetBreadCrumbs,
      callback);
};


/**
 * @param {!proto.external.GetBreadCrumbsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.external.GetBreadCrumbsResponse>}
 *     Promise that resolves to the response
 */
proto.external.AggregationServicePromiseClient.prototype.getBreadCrumbs =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/external.AggregationService/GetBreadCrumbs',
      request,
      metadata || {},
      methodDescriptor_AggregationService_GetBreadCrumbs);
};


module.exports = proto.external;

