import * as actionTypes from '../actions/actionTypes';

export const alertInitialState = {
  alerts: [],
  loading: false,
  loaded: false,
  error: null,
  open: false,
  taskToShow: null,
  filters: {
    asset: '',
    portfolio: '',
    tenant: ''
  }
};

const reducer = (state = alertInitialState, action) => {
  switch (action.type) {
    case actionTypes.ALERT_MESSAGE_RECEIVED:
      return {
        ...state,
        alerts: [...state.alerts, action.message]
      };
    case actionTypes.SET_ALERT_FILTERS:
      return {
        ...state,
        filters: { ...state.filters, ...action.filter }
      };
    case actionTypes.TOGGLE_ALERT_MENU:
      return {
        ...state,
        open: !state.open,
        taskToShow: !state.open ? null : action.task
      };
    case actionTypes.ALERT_SERVICE_ERROR:
      return {
        ...state,
        loading: false,
        loaded: true,
        error: action.error
      };
    case actionTypes.GET_ALERT_LIST_START:
      return {
        ...state,
        loading: true
      };
    case actionTypes.GET_ALERT_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        alerts: action.alerts
      };
    case actionTypes.GET_ALERT_LIST_FAIL:
      return {
        ...state,
        loading: false,
        loaded: true,
        error: action.error
      };
    case actionTypes.ALERT_MESSAGE_HIDE:
      return {
        ...state,
        alerts: state.alerts.filter((alert) => alert.entityid !== action.payload.entityId)
      };
    case actionTypes.SHOW_TASKS:
      return {
        ...state,
        taskToShow: action.task
      };
    default:
      return state;
  }
};

export default reducer;
