import React from 'react';

import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import CheckRoundedIcon from '@material-ui/icons/CheckRounded';
import CloseIcon from '@material-ui/icons/Close';
import { formatDate, stableSort, getComparator } from 'helpers/utils';
import PropTypes from 'prop-types';

import TableHeader from '../TableHeader';

const headCells = [
  { id: 'name', numeric: false, disablePadding: true, label: 'Invoice', sortable: true },
  {
    id: 'dateReceived',
    numeric: true,
    disablePadding: false,
    label: 'Date Received',
    sortable: true
  },
  { id: 'type', numeric: true, disablePadding: false, label: 'Invoice Type' },
  { id: 'company', numeric: true, disablePadding: false, label: 'Company' },
  { id: 'amount', numeric: true, disablePadding: false, label: 'Total Amount', sortable: true },
  { id: 'approve', numeric: true, disablePadding: false, label: 'Approve' }
];

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    maxHeight: 500,
    overflowY: 'auto'
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2)
  },
  table: {
    minWidth: 750
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1
  },
  cell: {
    fontFamily: theme.fonts.openSans,
    fontSize: 16,
    color: '#5C5C5C',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    height: 76,
    overflow: 'hidden',
    width: 120
  },
  header: {
    fontFamily: 'Montserrat, sans-serif',
    fontSize: 14,
    color: '#000000'
  },
  declineIcon: {
    color: '#DE0000',
    width: 38,
    height: 34,
    border: '1px solid #DE0000',
    borderRadius: 20,
    padding: 4,
    cursor: 'pointer'
  },
  approveIcon: {
    color: '#01B231',
    width: 40,
    height: 38,
    border: '1px solid #01B231',
    borderRadius: 20,
    padding: 4,
    marginLeft: theme.spacing(2),
    cursor: 'pointer'
  }
}));

const InvoicesList = ({ invoices }) => {
  const classes = useStyles();
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('');
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  return (
    <div className={classes.root}>
      <TableContainer>
        <Table
          className={classes.table}
          aria-labelledby="invoices awaiting"
          size="medium"
          aria-label="invoices awaiting table"
        >
          <TableHeader
            classes={classes}
            order={order}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
            headCells={headCells}
          />
          <TableBody>
            {stableSort(invoices, getComparator(order, orderBy)).map((row, index) => {
              const labelId = `invoice-table-checkbox-${index}`;

              return (
                <TableRow tabIndex={-1} key={row.name}>
                  <TableCell
                    component="th"
                    id={labelId}
                    scope="row"
                    padding="none"
                    className={classes.cell}
                  >
                    {row.name}
                  </TableCell>
                  <TableCell align="center" className={classes.cell}>
                    {formatDate(row.dateReceived)}
                  </TableCell>
                  <TableCell align="center" className={classes.cell}>
                    {row.type}
                  </TableCell>
                  <TableCell align="center" className={classes.cell}>
                    {row.company}
                  </TableCell>
                  <TableCell align="center" className={classes.cell}>
                    {`€${row.amount}`}
                  </TableCell>
                  <TableCell align="center" className={classes.cell}>
                    <Box>
                      <CloseIcon className={classes.declineIcon} />

                      <CheckRoundedIcon className={classes.approveIcon} />
                    </Box>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default InvoicesList;

InvoicesList.propTypes = {
  invoices: PropTypes.arrayOf(PropTypes.any).isRequired
};
