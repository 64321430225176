import React from 'react';

import Box from '@material-ui/core/Box';
import Divider from '@material-ui/core/Divider';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import BackgroundCover from 'assets/bg.jpeg';
import Logo from 'assets/logo-white.svg';
import Footer from 'components/Footer';
import { authService, googleProvider } from 'config/firebase';
import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth';

const useStyles = makeStyles((theme) => ({
  loginContainer: {
    background: `url(${BackgroundCover})`,
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    height: '100vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column'
  },
  paper: {
    width: theme.spacing(32),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    marginBottom: theme.spacing(6),
    padding: theme.spacing(3),
    [theme.breakpoints.up('md')]: {
      width: theme.spacing(48)
    },
    [theme.breakpoints.down('xs')]: {
      width: theme.spacing(30)
    }
  },
  logo: {
    [theme.breakpoints.down('sm')]: {
      width: '200px !important',
      height: '100%'
    }
  },
  inner: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: 380
  },
  subtitle: {
    width: '100%',
    padding: theme.spacing(1),
    fontWeight: 300
  },
  divider: {
    width: '100%',
    marginTop: theme.spacing(5)
  },
  customSignIn: {
    backgroundColor: 'rgb(255, 255, 255)',
    direction: 'ltr',
    fontWeight: 500,
    height: 'auto',
    lineHeight: 'normal',
    maxWidth: '202px',
    minHeight: '40px',
    padding: '8px 16px',
    textAlign: 'left',
    width: '100%',
    textTransform: 'none',
    boxShadow:
      '0 2px 2px 0 rgb(0 0 0 / 14%), 0 3px 1px -2px rgb(0 0 0 / 20%), 0 1px 5px 0 rgb(0 0 0 / 12%)',
    '&:hover': {
      backgroundColor: 'transparent'
    }
  },
  logoImg: {
    width: '18px',
    height: '18px'
  },
  btnText: {
    paddingLeft: '16px'
  }
}));

const LoginScreen = () => {
  const classes = useStyles();
  const uiConfig = {
    signInFlow: 'popup',
    signInOptions: [googleProvider.providerId, 'microsoft.com'],
    callbacks: {}
  };

  return (
    <Box className={classes.loginContainer} data-testid="loginscreen">
      <Box className={classes.inner}>
        <Paper elevation={3} className={classes.paper}>
          <img
            src={Logo}
            alt="logo"
            className={classes.logo}
            style={{ padding: 8, width: 250, height: 78 }}
          />
          <Divider className={classes.divider} />
          <Typography className={classes.subtitle} variant="subtitle1">
            By Signing In you accept our privacy and terms linked bellow.
          </Typography>
          <StyledFirebaseAuth uiConfig={uiConfig} firebaseAuth={authService} />
        </Paper>
      </Box>
      <Footer />
    </Box>
  );
};
export default LoginScreen;
