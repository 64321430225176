import React, { useState } from 'react';

import { makeStyles } from '@material-ui/core/styles';
import Spinner from 'components/Spinner';

const useStyles = makeStyles(() => ({
  tableauContainer: {
    display: 'block',
    width: '100%',
    height: 'calc(100vh - 135px)',
    visibility: 'visible',
    border: 'none'
  }
}));

const TABLEAU_URL =
  'https://tableau-dev.resoluteplatform.com/#/site/standardproductsources/workbooks/30/views';

function TableauDashboard() {
  const [loaded, setLoaded] = useState(false);
  const classes = useStyles();

  return (
    <>
      {!loaded && <Spinner />}
      <iframe
        src={TABLEAU_URL}
        className={classes.tableauContainer}
        allowfullscreen="true"
        title="Data Visualization"
        onLoad={() => setLoaded(true)}
      />
    </>
  );
}

export default TableauDashboard;
