import { metaData } from 'config/config';
import wrappers from 'google-protobuf/google/protobuf/wrappers_pb';

import { GetUnitsMLARequest, UpdateMLARequest } from '../../api/mla_pb';
import { MLAService } from '../services/mla';

import * as actionTypes from './actionTypes';

const getUnitsMLAStart = () => ({
  type: actionTypes.GET_UNIT_MLA_START
});

const getUnitsMLASuccess = (unitMLA) => ({
  type: actionTypes.GET_UNIT_MLA_SUCCESS,
  unitMLA
});

const getUnitsMLAFail = (error) => ({
  type: actionTypes.GET_UNIT_MLA_FAIL,
  error
});

export const getUntsMLAByAssetID = (assetID) => async (dispatch) => {
  dispatch(getUnitsMLAStart());
  const request = new GetUnitsMLARequest();
  request.setAssetid(assetID);

  try {
    const result = await MLAService.getUnitsMLA(request, metaData());
    const response = result.toObject();

    dispatch(getUnitsMLASuccess(response.unitmlasList));
    return response;
  } catch (error) {
    dispatch(getUnitsMLAFail(error.message));
    return error.message;
  }
};

export const updateMLA = (mla) => async () => {
  const request = new UpdateMLARequest();
  request.setUnitid(mla.unitid);

  const duration = new wrappers.Int32Value();
  duration.setValue(mla.duration);
  request.setDuration(duration);

  const startingRent = new wrappers.DoubleValue();
  startingRent.setValue(mla.startingrent);
  request.setStartingrent(startingRent);

  const serviceCharge = new wrappers.DoubleValue();
  serviceCharge.setValue(mla.servicecharge);
  request.setServicecharge(serviceCharge);

  const annualIndexation = new wrappers.DoubleValue();
  annualIndexation.setValue(mla.annualindexation);
  request.setAnnualindexation(annualIndexation);

  const startDate = new wrappers.StringValue();
  startDate.setValue(mla.mlastartdate.toISOString().split('T')[0]);
  request.setMlastartdate(startDate);

  try {
    const result = await MLAService.updateMLA(request, metaData());
    const response = result.toObject();
    return response;
  } catch (error) {
    return error.message;
  }
};

export const resetMLA = () => ({
  type: actionTypes.RESET_MLA_STATE
});
