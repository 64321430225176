import { graphColors } from 'constants/index';

import React, { lazy, Suspense, useEffect, useState } from 'react';

import { Typography } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import { Skeleton } from '@material-ui/lab';
import Dropdown from 'components/Filters/Dropdown';
import Spinner from 'components/Spinner';
import { numberWithCommas } from 'helpers/utils';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import * as actions from 'store/actions';

import BoxWrap from './Box';

const ApexCharts = lazy(() => import('components/Charts/ApexChart'));

const useStyles = makeStyles((theme) => ({
  title: {
    marginBottom: theme.spacing(3),
    fontFamily: theme.fonts.base
  }
}));

const getOptions = (categories) => ({
  chart: {
    type: 'bar',
    toolbar: {
      show: false
    }
  },
  grid: {
    show: true,
    xaxis: {
      lines: {
        show: true
      }
    },
    yaxis: {
      lines: {
        show: false
      }
    }
  },
  plotOptions: {
    bar: {
      horizontal: true,
      dataLabels: {
        position: 'top'
      }
    }
  },
  colors: graphColors,
  legend: {
    position: 'top'
  },
  dataLabels: {
    enabled: false
  },
  stroke: {
    show: true,
    width: 1,
    colors: ['#fff']
  },
  fill: {
    type: 'solid',
    opacity: 1
  },
  tooltip: {
    y: {
      title: {
        formatter: () => null
      }
    }
  },
  xaxis: {
    categories,
    labels: {
      show: true,
      formatter: (val) => `€ ${numberWithCommas(val)}`,
      style: {
        fontSize: 14,
        fontFamily: 'Open Sans',
        color: '#5C5C5C'
      }
    }
  },
  yaxis: {
    labels: {
      show: true,
      style: {
        fontSize: 14,
        fontFamily: 'Open Sans !important',
        color: '#5C5C5C'
      }
    }
  },
  responsive: [
    {
      breakpoint: 480,
      options: {
        chart: {
          width: 280
        },
        legend: {
          show: false
        },
        yaxis: {
          labels: {
            style: {
              fontSize: 10
            }
          }
        }
      }
    }
  ]
});

const makeBundleId = (id) => `id-${id}`;

const Turnover = ({ id, tenants }) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const [tenantId, setTenantId] = useState(tenants[0].id || '');
  const [arr, setArr] = useState([]);
  const turnover = useSelector((state) => state.reports.turnover.turnover);
  const loaded = useSelector((state) => state.reports.turnover.loaded);
  const error = useSelector((state) => state.reports.turnover.error);
  const bundleIds = useSelector((state) => state.reports.turnover.byId);

  useEffect(() => {
    if (!id) {
      return;
    }

    const bundleId = makeBundleId(tenantId);

    if (bundleIds.some((bundle) => bundle === bundleId)) {
      dispatch(actions.setTurnoverReportBundle(bundleId));
    } else {
      dispatch(actions.getTurnoverReport(id, tenantId, bundleId));
    }
  }, [tenantId]);

  useEffect(() => {
    if (turnover?.seriesList?.length < 1) {
      return;
    }

    setArr(
      turnover.seriesList.map((serie) => ({ name: serie.seriestitle, data: serie.valuesList }))
    );
  }, [turnover]);

  if (!loaded) {
    return (
      <Box p={3} mt={3} width="95%" height={700}>
        <Spinner mini />
      </Box>
    );
  }

  if (error) {
    return (
      <Box p={3} mt={3} width="95%" height={600}>
        <Typography color="error">{error}</Typography>
      </Box>
    );
  }

  return (
    <>
      <BoxWrap p={3} mb={3} width="95%" height={700}>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography className={classes.title}>Turnover</Typography>

          <Dropdown
            items={tenants}
            value={tenantId}
            handleChange={(e) => setTenantId(e.target.value)}
            label="Tenant"
            keyword="companyname"
            small
          />
        </Box>

        <Suspense fallback={<Skeleton width="100%" height={380} />}>
          <ApexCharts
            series={arr}
            options={getOptions(turnover.categoriesList.map((category) => category.substr(0, 3)))}
            type="bar"
            height={620}
          />
        </Suspense>
      </BoxWrap>
    </>
  );
};

export default React.memo(Turnover);

Turnover.propTypes = {
  id: PropTypes.string.isRequired,
  tenants: PropTypes.arrayOf(PropTypes.any).isRequired
};
