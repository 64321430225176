import React from 'react';

import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Icon from '@material-ui/core/Icon';
import Typography from '@material-ui/core/Typography';
import UserHasAccess from 'hooks/UserHasAccess';
import { useSelector } from 'react-redux';

import Navbar from '../components/Navbar';
import Routes from '../routes';

const Layout = () => {
  const userPermissionsLoaded = useSelector((state) => state.user.userRolesLoaded);
  const userPermissions = useSelector((state) => state.user.userPermissions.permissionsList);
  const ReadPortfoliosPermissions = UserHasAccess('portfolio', 'view', userPermissions);

  return (
    <>
      <Navbar />
      <Box height="100%">
        {userPermissionsLoaded && <Routes userPermissions={userPermissions} />}
        {!ReadPortfoliosPermissions && userPermissionsLoaded && (
          <Box ml={3} mt={3} display="flex" justifyContent="center" alignItems="center">
            <Typography color="error">
              Your account has no permissions to interact with the app, if you are under the right
              organisation account please contact an admin to upgrade your guest account
            </Typography>
            <Box pl={3} pr={3}>
              <Button
                variant="contained"
                color="primary"
                rel="noopener noreferrer"
                href="mailto:thanos.tsavlis@resolute-datascience.com"
                endIcon={<Icon>send</Icon>}
              >
                Request Access
              </Button>
            </Box>
          </Box>
        )}
      </Box>
    </>
  );
};

export default React.memo(Layout);
