import React, { useEffect, lazy, Suspense, useCallback } from 'react';

import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import NewButton from 'components/Buttons/NewButton';
import Container from 'components/Container';
import { openModal } from 'components/Modal/modalReducer';
import Spinner from 'components/Spinner';
import useRouter from 'hooks/useRouter';
import { useDispatch, useSelector } from 'react-redux';
import * as actions from 'store/actions';
import useStyles from 'styles/costManager';

const LegalTable = lazy(() => import('../../Shared/LegalTracker/LegalTable'));
const bundleId = 'Page-1-org';
const bundleLegalId = 'ALL-ALL-ALL';

const LegalTracker = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { legalTracker, loading, allIds, error } = useSelector((state) => state.assetLegalTracker);
  const bundleIds = useSelector((state) => state.users.byId);
  const { organizationid } = useSelector((state) => state.user.user);
  const { query } = useRouter();

  useEffect(() => {
    if (!query.id) {
      return;
    }

    dispatch(actions.getAssetLegalTracker(query.id, bundleLegalId));
  }, [query.id]);

  useEffect(() => {
    if (bundleIds.some((bundle) => bundle === bundleId)) {
      return;
    }
    dispatch(actions.getAllUsers(-1, bundleId, organizationid));
  }, []);

  async function handleSave(form) {
    await dispatch(actions.createLegalTicket(query.id, form));
  }

  const handleNew = useCallback(() => {
    dispatch(
      openModal({
        modalType: 'LegalTrackerModal',
        modalProps: {
          legalTracker: {},
          handleSave,
          handleSuccess: () => dispatch(actions.getAssetLegalTracker(query.id, bundleLegalId))
        }
      })
    );
  }, []);

  if (loading) {
    return <Spinner />;
  }

  if (error) {
    return (
      <Box p={3} m={3}>
        <Typography color="error">{error}</Typography>
      </Box>
    );
  }

  return (
    <Container pb={2}>
      <Box display="flex" alignItems="center" justifyContent="space-between" m={3} p={2}>
        <Typography className={classes.header}>Legal Tracker</Typography>
        <NewButton onClick={handleNew} label="New" />
      </Box>

      <Suspense fallback={<Spinner mini />}>
        {legalTracker && (
          <LegalTable
            data={legalTracker}
            link={`/Asset/${query.id}/LegalTracker`}
            loading={loading}
            allIds={allIds}
            setLegal={(bundle) => dispatch(actions.setAssetLegalTrackerBundle(bundle))}
            fetchLegal={(id, bundle) => dispatch(actions.getAssetLegalTracker(id, bundle))}
            handleDelete={(id) =>
              dispatch(actions.deleteLegalTicket(id)).then(() => {
                dispatch(actions.getAssetLegalTracker(query.id, bundleLegalId));
              })
            }
          />
        )}
      </Suspense>
    </Container>
  );
};

export default LegalTracker;
