import React from 'react';

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  footer: {
    color: theme.palette.common.white,
    padding: theme.spacing(2),
    textAlign: 'center',

    width: '100%',
    [theme.breakpoints.down('sm')]: {
      fontSize: 10
    }
  },
  footerInner: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column'
  },
  footerParagraph: {
    fontSize: 12,
    margin: '2px',
    color: '#dddddd'
  }
}));

const Footer = () => {
  const classes = useStyles();
  return (
    <div className={classes.footer}>
      <div className={classes.footerInner}>
        <p className={classes.footerParagraph}>
          {`© ${new Date().getFullYear()} Recognyte Limited`}
        </p>
      </div>
    </div>
  );
};

export default Footer;
