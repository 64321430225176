/* eslint-disable no-unused-vars */
import { Empty, metaData } from 'config/config';
import { toast } from 'react-toastify';

import { HideRequest, ListAlertsRequest } from '../../api/alert_grpc_web_pb';
import { EqualCondition, ConditionValue } from '../../api/alert_pb';
import { GetAssetsRequest } from '../../api/asset_grpc_web_pb';
import { AlertServiceClient, AssetService } from '../services/alert';

import * as actionTypes from './actionTypes';

export const alertMessageReceived = (message) => ({
  type: actionTypes.ALERT_MESSAGE_RECEIVED,
  message
});

export const alertServiceError = (error) => ({
  type: actionTypes.ALERT_SERVICE_ERROR,
  error
});

export const setAlertFilters = (filter) => ({
  type: actionTypes.SET_ALERT_FILTERS,
  filter
});

export const toggleAlertMenu = () => ({
  type: actionTypes.TOGGLE_ALERT_MENU
});

// Alert List
const getAlertsListStart = () => ({
  type: actionTypes.GET_ALERT_LIST_START
});

const getAlertListSuccess = (alerts) => ({
  type: actionTypes.GET_ALERT_LIST_SUCCESS,
  alerts
});

const getAlertListFail = (error) => ({
  type: actionTypes.GET_ALERT_LIST_FAIL,
  error
});

export const getAlertsList = (portfolioIDs, assetIDs) => (dispatch) => {
  dispatch(getAlertsListStart());
  const request = new ListAlertsRequest();

  portfolioIDs.forEach((id) => {
    const addFilters = request.addFilters();
    addFilters.setField('PortfolioID');
    const conditionValue = new ConditionValue();
    conditionValue.setStringvalue(id);
    const equalCondition = new EqualCondition();
    equalCondition.setValue(conditionValue);
    addFilters.setEqual(equalCondition);
  });

  assetIDs.forEach((id) => {
    const addFilters = request.addFilters();
    addFilters.setField('AssetID');
    const conditionValue = new ConditionValue();
    conditionValue.setStringvalue(id);
    const equalCondition = new EqualCondition();
    equalCondition.setValue(conditionValue);
    addFilters.setEqual(equalCondition);
  });

  return new Promise((resolve, reject) => {
    AlertServiceClient.listAlerts(request, metaData(), (err, response) => {
      if (err) {
        dispatch(getAlertListFail(err.message));
        reject(err.message);
      } else {
        dispatch(getAlertListSuccess(response.toObject().alertsList));
        resolve(response.toObject());
      }
    });
  });
};

export const getAlertsCount = () => () =>
  new Promise((resolve, reject) => {
    AlertServiceClient.getAlertsCount(Empty, metaData(), (err, response) => {
      if (err) {
        reject(err.message);
      } else {
        resolve(response.toObject().alertscount);
      }
    });
  });

const hideAlertSuccess = (entityId, type) => ({
  type: actionTypes.ALERT_MESSAGE_HIDE,
  payload: {
    entityId,
    type
  }
});

export const hideAlert = (entityId, type) => (dispatch) => {
  const request = new HideRequest();
  request.setEntityid(entityId);
  request.setType(type);

  return new Promise((resolve, reject) => {
    AlertServiceClient.hide(request, metaData(), (err, response) => {
      if (err) {
        toast.error(err);
        reject(err.message);
      } else {
        dispatch(hideAlertSuccess(entityId, type));
        toast.success('Alert Removed');
        resolve(response.toObject());
      }
    });
  });
};

export const getAssetsByPortfolio = (portfolioIds) => (dispatch) => {
  const request = new GetAssetsRequest();
  request.setPortfolioidsList(portfolioIds ?? []);
  return new Promise((resolve, reject) => {
    AssetService.getAssets(request, metaData(), (err, response) => {
      if (err) {
        reject(err.message);
      } else {
        resolve(response.toObject().assetsList);
      }
    });
  });
};

export const showTask = (task) => ({
  type: actionTypes.SHOW_TASKS,
  task
});
