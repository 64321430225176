import { metaData } from 'config/config';
import wrappers from 'google-protobuf/google/protobuf/wrappers_pb';

import { GetUserRequest, GetUsersRequest, UpdateUserRequest } from '../../api/user_grpc_web_pb';
import { UserService } from '../services/user';

import * as actionTypes from './actionTypes';

// Get User Details
const getUserDetailsStart = () => ({
  type: actionTypes.GET_USER_DETAILS_START
});

const getUserDetailsSuccess = (userDetails) => ({
  type: actionTypes.GET_USER_DETAILS_SUCCESS,
  userDetails
});

const getUserDetailsFail = (error) => ({
  type: actionTypes.GET_USER_DETAILS_FAIL,
  error
});
export const getUserDetails = (firebaseId) => (dispatch) => {
  dispatch(getUserDetailsStart());
  const request = new GetUserRequest();

  request.setFirebaseid(firebaseId);

  return new Promise((resolve, reject) => {
    UserService.getUser(request, metaData(), (err, response) => {
      if (err) {
        dispatch(getUserDetailsFail(err.message));
        reject(err.message);
      } else {
        dispatch(getUserDetailsSuccess(response.toObject()));
        resolve(response.toObject());
      }
    });
  });
};

// Get All Users
const getUsersStart = () => ({
  type: actionTypes.GET_USERS_START
});

const getUsersSuccess = (users, bundleId) => ({
  type: actionTypes.GET_USERS_SUCCESS,
  users,
  bundleId
});

const getUsersFail = (error) => ({
  type: actionTypes.GET_USERS_FAIL,
  error
});
export const setUsersBundle = (bundleId) => ({ type: actionTypes.SET_USERS_BUNDLE, bundleId });

export const getAllUsers = (page, bundleId, organisation) => (dispatch) => {
  const request = new GetUsersRequest();
  request.setPage(page ?? 1);

  if (organisation) {
    const organisationID = new wrappers.StringValue();
    organisationID.setValue(organisation);
    request.setOrganizationid(organisationID);
  }

  dispatch(getUsersStart());
  UserService.getUsers(request, metaData(), (err, response) => {
    if (err) {
      dispatch(getUsersFail(err.message));
    } else {
      dispatch(getUsersSuccess(response.toObject(), bundleId));
    }
  });
};

// Update User info
const updateUserStart = () => ({
  type: actionTypes.UPDATE_USER_START
});

const updateUserSuccess = () => ({
  type: actionTypes.UPDATE_USER_SUCCESS
});

const updateUserFail = (error) => ({
  type: actionTypes.UPDATE_USER_FAIL,
  error
});

export const updateUser = (form) => async (dispatch) => {
  dispatch(updateUserStart());
  const request = new UpdateUserRequest();

  const name = new wrappers.StringValue();
  name.setValue(form.displayname);

  const email = new wrappers.StringValue();
  email.setValue(form.email);

  const roleId = new wrappers.StringValue();
  roleId.setValue(form.roleid);

  request.setDisplayname(name);
  request.setEmail(email);
  request.setRoleId(roleId);
  request.setId(form.id);

  return new Promise((resolve, reject) => {
    UserService.updateUser(request, metaData(), (err, response) => {
      if (err) {
        dispatch(updateUserFail(err.message));
        reject(err.message);
      } else {
        dispatch(updateUserSuccess());
        resolve(response.toObject());
      }
    });
  });
};
