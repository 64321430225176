import React, { Suspense, lazy, useEffect, useState } from 'react';

import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Grow from '@material-ui/core/Grow';
import Icon from '@material-ui/core/Icon';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import TableContainer from '@material-ui/core/TableContainer';
import Typography from '@material-ui/core/Typography';
import Spinner from 'components/Spinner';
import AddCostManager from 'forms/AddCostManager';
import useRouter from 'hooks/useRouter';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import * as actions from 'store/actions';

const CostTable = lazy(() => import('../../Shared/CostManager/CostTable'));

const useStyles = makeStyles((theme) => ({
  ctaButton: {
    borderRadius: 60,
    height: '100%',
    width: theme.spacing(13),
    color: theme.palette.primary.main,
    background: theme.palette.common.white,
    fontWeight: 'bold',
    boxShadow: 'none',
    border: '1px solid #5381EF',
    '&:hover': {
      backgroundColor: theme.palette.secondary.main,
      color: theme.palette.common.white
    },
    '&:hover .MuiIcon-root': {
      color: 'white !important'
    },
    '&:hover .MuiIconButton-root:hover': {
      backgroundColor: 'transparent !important'
    }
  }
}));

const OpexManager = () => {
  const { query } = useRouter();
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();
  const classes = useStyles();
  const [formName, setFormName] = useState('');
  const { loaded, error, loading, opexManager } = useSelector((state) => state.opexManager);

  useEffect(() => {
    dispatch(actions.getOpexManager(query.id));
  }, []);

  const handleNew = () => {
    setFormName('');
    setOpen(true);
  };

  const handleClose = () => {
    setFormName('');
    setOpen(false);
  };

  const handleSave = (form) => {
    dispatch(actions.createOpexManager(query.id, form)).then(() => {
      dispatch(actions.getOpexManager(query.id))
        .then(() => {
          toast.success('Opex Manager Added');
          handleClose();
        })
        .catch((err) => {
          toast.error(err);
        });
    });
  };

  const handleDeleteOpexManager = (id) => {
    dispatch(actions.deleteOpexManager(id)).then(() => {
      dispatch(actions.getOpexManager(query.id))
        .then(() => {
          toast.success('Opex Manager Deleted');
          handleClose();
        })
        .catch((err) => {
          toast.error(err);
        });
    });
  };

  if (!loaded || loading) {
    return <Spinner />;
  }

  if (error) {
    return (
      <Box p={3} m={3}>
        <Typography color="error">{error}</Typography>
      </Box>
    );
  }

  return (
    <Grow in>
      <TableContainer component={Paper}>
        <Box style={{ display: 'flex', padding: '30px 30px 0 30px' }}>
          <Button
            className={classes.ctaButton}
            style={{ marginRight: '10px' }}
            startIcon={<Icon style={{ color: '#5381EF' }}>add</Icon>}
            onClick={handleNew}
          >
            OPEX
          </Button>
          <AddCostManager
            open={open}
            handleClose={handleClose}
            costManager={formName}
            createCostManager={handleSave}
            title="Add Opex Manager"
          />
        </Box>
        <Suspense fallback={<Spinner />}>
          <CostTable
            data={opexManager}
            loading={loading}
            link={`/Asset/${query.id}/OpexManager`}
            handleDelete={(ticketId) => handleDeleteOpexManager(ticketId)}
          />
        </Suspense>
      </TableContainer>
    </Grow>
  );
};

export default OpexManager;
