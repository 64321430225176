/* eslint-disable global-require */
/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import React, { useMemo, useState } from 'react';

import AppBar from '@material-ui/core/AppBar';
import Avatar from '@material-ui/core/Avatar';
import Badge from '@material-ui/core/Badge';
import Box from '@material-ui/core/Box';
import IconButton from '@material-ui/core/IconButton';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import MenuIcon from '@material-ui/icons/Menu';
import NotificationsIcon from '@material-ui/icons/Notifications';
import NotificationsNoneIcon from '@material-ui/icons/NotificationsNone';
import App from 'App';
import Logo from 'assets/logo.svg';
import SearchInput from 'components/Search';
import useIsAdmin from 'helpers/isAdmin';
import UserHasAccess from 'hooks/UserHasAccess';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import * as actions from 'store/actions';

import DrawerMenu from './Drawer';
import SideBar from './Sidebar';
import useStyles from './styles';

const menuId = 'primary-search-account-menu';

function ToolbarComponent({ handleProfileMenuOpen }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { open } = useSelector((state) => state.alerts);
  const userNotifications = useSelector((state) => state.alerts.alerts.length);
  const role = useSelector((state) => state.user.userPermissions.roletitle);
  const isAdmin = useIsAdmin();
  const userPermissions = useSelector((state) => state.user.userPermissions.permissionsList);
  const assetAccess = UserHasAccess('asset', 'view', userPermissions);
  const { displayname } = useSelector((state) => state.user.user);
  const [drawerOpen, setDrawer] = useState(false);
  const [hover, setHover] = useState(false);

  const getNameInitials = useMemo(
    () => `${displayname?.split(' ')[0][0]}${displayname?.split(' ')[1][0]}`,
    [displayname]
  );

  const toggleDrawer = () => setDrawer((drawer) => !drawer);

  return (
    <>
      {assetAccess && <SideBar open={open} setOpen={() => dispatch(actions.toggleAlertMenu())} />}
      <DrawerMenu toggleDrawer={toggleDrawer} drawerOpen={drawerOpen} />
      <Toolbar className={classes.appBar} disableGutters>
        <IconButton
          className={classes.menuButton}
          color="inherit"
          aria-label="open drawer"
          onClick={toggleDrawer}
        >
          <MenuIcon />
        </IconButton>
        <NavLink to="/" className={classes.link}>
          <Box display="flex" alignItems="center">
            <img src={Logo} alt="nav-logo" className={classes.logo} />
            <Box display="flex" flexDirection="column" className={classes.productName}>
              <Typography variant="paragraph" className={classes.logoTextTop}>
                Recognyte Platform
              </Typography>
              <Typography variant="paragraph" className={classes.logoTextBottom}>
                <span style={{ color: '#009ADE' }}>Asset</span>
                Dynamics
              </Typography>
            </Box>
          </Box>
        </NavLink>

        <div className={classes.growHorizontal} />

        {role && !isAdmin && <SearchInput />}

        <div className={classes.sectionDesktop}>
          {role && (
            <IconButton
              aria-label="show 14 new notifications"
              color="inherit"
              onClick={() => dispatch(actions.toggleAlertMenu())}
              classes={{ root: classes.iconBtn }}
              onMouseEnter={() => setHover(true)}
              onMouseLeave={() => setHover(false)}
              disabled={!userNotifications}
            >
              <Badge badgeContent={userNotifications} classes={{ badge: classes.customBadge }}>
                {userNotifications > 0 && hover ? (
                  <NotificationsIcon />
                ) : (
                  <NotificationsNoneIcon
                    style={{
                      color: userNotifications > 0 ? '#FFF' : '#999'
                    }}
                  />
                )}
              </Badge>
            </IconButton>
          )}
          <Avatar
            variant="circle"
            className={classes.blue}
            onClick={handleProfileMenuOpen}
            aria-controls={menuId}
          >
            {getNameInitials}
          </Avatar>
        </div>
      </Toolbar>
    </>
  );
}

export default React.memo(ToolbarComponent);
