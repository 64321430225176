import React from 'react';

import { openModal, closeModal } from 'components/Modal/modalReducer';
import Table from 'components/Table';
import UserHasAccess from 'hooks/UserHasAccess';
import useRouter from 'hooks/useRouter';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import * as actions from 'store/actions';

const PortfoliosTable = ({ title = '', data = [], columns = [], loading = false }) => {
  const userPermissions = useSelector((state) => state.user.userPermissions.permissionsList);
  const userHasEditRights = UserHasAccess('portfolio', 'edit', userPermissions);
  const router = useRouter();
  const dispatch = useDispatch();
  const createNewPortfolio = () => {
    dispatch(
      openModal({
        modalType: 'PortfolioModal',
        modalProps: { portfolio: { name: '' } }
      })
    );
  };

  const handleDelete = (id) => {
    dispatch(actions.deletePortfolio(id))
      .then(() => {
        toast.success('Portfolio deleted succesfully!');
        dispatch(actions.getPortfolios());
      })
      .catch((err) => {
        toast.error(err.message);
      });
  };

  const handleConfirm = (portfolioId) => {
    dispatch(
      openModal({
        modalType: 'AlertModal',
        modalProps: {
          content: 'Are you sure you want to delete this Item, this action cannot be undone',
          title: 'Delete Portfolio',
          handleConfirm: () => {
            handleDelete(portfolioId);
            dispatch(closeModal());
          }
        }
      })
    );
  };

  return (
    <>
      <Table
        handleNew={createNewPortfolio}
        disabled={!userHasEditRights}
        components={{
          Toolbar: () => null
        }}
        columns={columns}
        data={data}
        title={title}
        loading={loading}
        testid="portfolios-table"
        data-cy="portfolios-table"
        onRowClick={(e, rowData) => router.push(`Assets?portfolioIds=${rowData.id}`)}
        actions={[
          {
            icon: 'edit',
            tooltip: 'Edit Portfolio',
            iconProps: { style: { color: '#5C5C5C' } },
            onClick: (event, rowData) => {
              dispatch(
                openModal({
                  modalType: 'PortfolioModal',
                  modalProps: { portfolio: { name: rowData.name, id: rowData.id } }
                })
              );
            }
          },
          {
            icon: 'delete',
            tooltip: 'Delete Portfolio',
            iconProps: { style: { color: '#5C5C5C' } },
            onClick: (event, rowData) => handleConfirm(rowData.id)
          }
        ]}
      />
    </>
  );
};

export default PortfoliosTable;

PortfoliosTable.propTypes = {
  title: PropTypes.string.isRequired,
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  columns: PropTypes.arrayOf(PropTypes.object).isRequired,
  loading: PropTypes.bool.isRequired
};
