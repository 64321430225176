import React from 'react';

import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { makeStyles } from '@material-ui/core/styles';
import DetailsIcon from '@material-ui/icons/Details';
import FolderOpenIcon from '@material-ui/icons/FolderOpen';
import GroupIcon from '@material-ui/icons/Group';
import InsertChartIcon from '@material-ui/icons/InsertChart';
import ShowChartIcon from '@material-ui/icons/ShowChart';
import CustomIcon from 'components/CustomIcon';
import useRouter from 'hooks/useRouter';
import { useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  heading: {
    fontSize: theme.spacing(2),
    marginLeft: theme.spacing(2),
    color: theme.palette.text.grey,
    fontFamily: theme.fonts.openSans,
    padding: theme.spacing(1)
  },
  details: {
    fontSize: 16,
    color: '#FFF',
    fontFamily: theme.fonts.openSans,
    padding: theme.spacing(1),
    cursor: 'pointer',
    '&:hover': {
      opacity: '0.8'
    }
  },
  drawerPaper: {
    top: 'unset',
    backgroundColor: '#1B2740',
    '& .Mui-selected': {
      backgroundColor: 'rgba(0, 0, 0, 0.3)',
      '&:hover': {
        backgroundColor: 'rgba(0, 0, 0, 0.3)'
      }
    }
  },
  light: {
    backgroundColor: '#555'
  },
  white: {
    color: theme.palette.common.white
  },
  paddingBottom: {
    paddingBottom: '65px !important'
  },
  itemIcon: {
    minWidth: 50,
    marginLeft: 12
  }
}));

const SideMenu = () => {
  const classes = useStyles();
  const router = useRouter();
  const { id } = router.query;
  const { tab } = router.match.params;
  const { data: asset } = useSelector((state) => state.assets.assetDetails);

  return (
    <Drawer
      variant="permanent"
      anchor="left"
      classes={{
        paper: classes.drawerPaper
      }}
    >
      <List>
        <ListItem
          className={classes.details}
          selected={tab === 'Details'}
          component={NavLink}
          to={`/Asset/${id}/Details`}
        >
          <ListItemIcon className={classes.itemIcon}>
            <DetailsIcon className={classes.white} />
          </ListItemIcon>
          <ListItemText>Site Plan</ListItemText>
        </ListItem>
        <ListItem
          className={classes.details}
          selected={tab === 'Tenants'}
          component={NavLink}
          to={`/Asset/${id}/Tenants`}
        >
          <ListItemIcon className={classes.itemIcon}>
            <GroupIcon className={classes.white} />
          </ListItemIcon>
          <ListItemText>Tenants</ListItemText>
        </ListItem>
        <ListItem
          className={classes.details}
          selected={tab === 'OpexManager'}
          component={NavLink}
          to={`/Asset/${id}/OpexManager`}
        >
          <ListItemIcon className={classes.itemIcon}>
            <InsertChartIcon className={classes.white} />
          </ListItemIcon>
          <ListItemText>Opex Manager</ListItemText>
        </ListItem>
        <ListItem
          className={classes.details}
          selected={tab === 'CostManager'}
          component={NavLink}
          to={`/Asset/${id}/CostManager`}
        >
          <ListItemIcon className={classes.itemIcon}>
            <InsertChartIcon className={classes.white} />
          </ListItemIcon>
          <ListItemText>Capex Manager</ListItemText>
        </ListItem>
        <ListItem
          className={classes.details}
          selected={tab === 'Actuals'}
          component={NavLink}
          to={`/Asset/${id}/Actuals`}
        >
          <ListItemIcon className={classes.itemIcon}>
            <FolderOpenIcon className={classes.white} />
          </ListItemIcon>
          <ListItemText>Actuals</ListItemText>
        </ListItem>
      </List>
      <List>
        <ListItem
          className={classes.details}
          selected={tab === 'Valuations'}
          component={NavLink}
          to={`/Asset/${id}/Valuations`}
        >
          <ListItemIcon className={classes.itemIcon}>
            <ShowChartIcon className={classes.white} />
          </ListItemIcon>
          <ListItemText>Valuations</ListItemText>
        </ListItem>
        <ListItem
          className={classes.details}
          component={NavLink}
          to={{
            pathname: `/BaseRent/Details/${id}`
          }}
        >
          <ListItemIcon className={classes.itemIcon}>
            <CustomIcon name="link_icon" width="24" height="24" />
          </ListItemIcon>
          <ListItemText>Asset Reports</ListItemText>
        </ListItem>
        <ListItem
          className={classes.details}
          component={NavLink}
          to={{
            pathname: '/AdvancedReporting',
            state: { from: { text: asset.name, href: `/Asset/${id}/Details` } }
          }}
        >
          <ListItemIcon className={classes.itemIcon}>
            <CustomIcon name="analytics" width="24" height="24" />
          </ListItemIcon>
          <ListItemText>Advanced Reporting</ListItemText>
        </ListItem>
      </List>
    </Drawer>
  );
};

export default SideMenu;
