/**
 * @fileoverview gRPC-Web generated client stub for external
 * @enhanceable
 * @public
 */

// GENERATED CODE -- DO NOT EDIT!


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');


var google_protobuf_empty_pb = require('google-protobuf/google/protobuf/empty_pb.js')

var google_protobuf_wrappers_pb = require('google-protobuf/google/protobuf/wrappers_pb.js')
const proto = {};
proto.external = require('./organization_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.external.OrganizationServiceClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options['format'] = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.external.OrganizationServicePromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options['format'] = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.external.OrganizationCreateRequest,
 *   !proto.google.protobuf.Empty>}
 */
const methodDescriptor_OrganizationService_Create = new grpc.web.MethodDescriptor(
  '/external.OrganizationService/Create',
  grpc.web.MethodType.UNARY,
  proto.external.OrganizationCreateRequest,
  google_protobuf_empty_pb.Empty,
  /**
   * @param {!proto.external.OrganizationCreateRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  google_protobuf_empty_pb.Empty.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.external.OrganizationCreateRequest,
 *   !proto.google.protobuf.Empty>}
 */
const methodInfo_OrganizationService_Create = new grpc.web.AbstractClientBase.MethodInfo(
  google_protobuf_empty_pb.Empty,
  /**
   * @param {!proto.external.OrganizationCreateRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  google_protobuf_empty_pb.Empty.deserializeBinary
);


/**
 * @param {!proto.external.OrganizationCreateRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.google.protobuf.Empty)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.google.protobuf.Empty>|undefined}
 *     The XHR Node Readable Stream
 */
proto.external.OrganizationServiceClient.prototype.create =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/external.OrganizationService/Create',
      request,
      metadata || {},
      methodDescriptor_OrganizationService_Create,
      callback);
};


/**
 * @param {!proto.external.OrganizationCreateRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.google.protobuf.Empty>}
 *     Promise that resolves to the response
 */
proto.external.OrganizationServicePromiseClient.prototype.create =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/external.OrganizationService/Create',
      request,
      metadata || {},
      methodDescriptor_OrganizationService_Create);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.external.OrganizationGetRequest,
 *   !proto.external.OrganizationGetResponse>}
 */
const methodDescriptor_OrganizationService_Get = new grpc.web.MethodDescriptor(
  '/external.OrganizationService/Get',
  grpc.web.MethodType.UNARY,
  proto.external.OrganizationGetRequest,
  proto.external.OrganizationGetResponse,
  /**
   * @param {!proto.external.OrganizationGetRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.external.OrganizationGetResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.external.OrganizationGetRequest,
 *   !proto.external.OrganizationGetResponse>}
 */
const methodInfo_OrganizationService_Get = new grpc.web.AbstractClientBase.MethodInfo(
  proto.external.OrganizationGetResponse,
  /**
   * @param {!proto.external.OrganizationGetRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.external.OrganizationGetResponse.deserializeBinary
);


/**
 * @param {!proto.external.OrganizationGetRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.external.OrganizationGetResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.external.OrganizationGetResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.external.OrganizationServiceClient.prototype.get =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/external.OrganizationService/Get',
      request,
      metadata || {},
      methodDescriptor_OrganizationService_Get,
      callback);
};


/**
 * @param {!proto.external.OrganizationGetRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.external.OrganizationGetResponse>}
 *     Promise that resolves to the response
 */
proto.external.OrganizationServicePromiseClient.prototype.get =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/external.OrganizationService/Get',
      request,
      metadata || {},
      methodDescriptor_OrganizationService_Get);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.google.protobuf.Empty,
 *   !proto.external.AllOrganizationResponse>}
 */
const methodDescriptor_OrganizationService_GetAll = new grpc.web.MethodDescriptor(
  '/external.OrganizationService/GetAll',
  grpc.web.MethodType.UNARY,
  google_protobuf_empty_pb.Empty,
  proto.external.AllOrganizationResponse,
  /**
   * @param {!proto.google.protobuf.Empty} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.external.AllOrganizationResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.google.protobuf.Empty,
 *   !proto.external.AllOrganizationResponse>}
 */
const methodInfo_OrganizationService_GetAll = new grpc.web.AbstractClientBase.MethodInfo(
  proto.external.AllOrganizationResponse,
  /**
   * @param {!proto.google.protobuf.Empty} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.external.AllOrganizationResponse.deserializeBinary
);


/**
 * @param {!proto.google.protobuf.Empty} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.external.AllOrganizationResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.external.AllOrganizationResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.external.OrganizationServiceClient.prototype.getAll =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/external.OrganizationService/GetAll',
      request,
      metadata || {},
      methodDescriptor_OrganizationService_GetAll,
      callback);
};


/**
 * @param {!proto.google.protobuf.Empty} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.external.AllOrganizationResponse>}
 *     Promise that resolves to the response
 */
proto.external.OrganizationServicePromiseClient.prototype.getAll =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/external.OrganizationService/GetAll',
      request,
      metadata || {},
      methodDescriptor_OrganizationService_GetAll);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.external.OrganizationUpdateRequest,
 *   !proto.google.protobuf.Empty>}
 */
const methodDescriptor_OrganizationService_Update = new grpc.web.MethodDescriptor(
  '/external.OrganizationService/Update',
  grpc.web.MethodType.UNARY,
  proto.external.OrganizationUpdateRequest,
  google_protobuf_empty_pb.Empty,
  /**
   * @param {!proto.external.OrganizationUpdateRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  google_protobuf_empty_pb.Empty.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.external.OrganizationUpdateRequest,
 *   !proto.google.protobuf.Empty>}
 */
const methodInfo_OrganizationService_Update = new grpc.web.AbstractClientBase.MethodInfo(
  google_protobuf_empty_pb.Empty,
  /**
   * @param {!proto.external.OrganizationUpdateRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  google_protobuf_empty_pb.Empty.deserializeBinary
);


/**
 * @param {!proto.external.OrganizationUpdateRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.google.protobuf.Empty)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.google.protobuf.Empty>|undefined}
 *     The XHR Node Readable Stream
 */
proto.external.OrganizationServiceClient.prototype.update =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/external.OrganizationService/Update',
      request,
      metadata || {},
      methodDescriptor_OrganizationService_Update,
      callback);
};


/**
 * @param {!proto.external.OrganizationUpdateRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.google.protobuf.Empty>}
 *     Promise that resolves to the response
 */
proto.external.OrganizationServicePromiseClient.prototype.update =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/external.OrganizationService/Update',
      request,
      metadata || {},
      methodDescriptor_OrganizationService_Update);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.external.OrganizationDeleteRequest,
 *   !proto.google.protobuf.Empty>}
 */
const methodDescriptor_OrganizationService_Delete = new grpc.web.MethodDescriptor(
  '/external.OrganizationService/Delete',
  grpc.web.MethodType.UNARY,
  proto.external.OrganizationDeleteRequest,
  google_protobuf_empty_pb.Empty,
  /**
   * @param {!proto.external.OrganizationDeleteRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  google_protobuf_empty_pb.Empty.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.external.OrganizationDeleteRequest,
 *   !proto.google.protobuf.Empty>}
 */
const methodInfo_OrganizationService_Delete = new grpc.web.AbstractClientBase.MethodInfo(
  google_protobuf_empty_pb.Empty,
  /**
   * @param {!proto.external.OrganizationDeleteRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  google_protobuf_empty_pb.Empty.deserializeBinary
);


/**
 * @param {!proto.external.OrganizationDeleteRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.google.protobuf.Empty)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.google.protobuf.Empty>|undefined}
 *     The XHR Node Readable Stream
 */
proto.external.OrganizationServiceClient.prototype.delete =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/external.OrganizationService/Delete',
      request,
      metadata || {},
      methodDescriptor_OrganizationService_Delete,
      callback);
};


/**
 * @param {!proto.external.OrganizationDeleteRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.google.protobuf.Empty>}
 *     Promise that resolves to the response
 */
proto.external.OrganizationServicePromiseClient.prototype.delete =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/external.OrganizationService/Delete',
      request,
      metadata || {},
      methodDescriptor_OrganizationService_Delete);
};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.external.PortfolioServiceClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options['format'] = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.external.PortfolioServicePromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options['format'] = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.external.PortfolioCreateRequest,
 *   !proto.google.protobuf.Empty>}
 */
const methodDescriptor_PortfolioService_Create = new grpc.web.MethodDescriptor(
  '/external.PortfolioService/Create',
  grpc.web.MethodType.UNARY,
  proto.external.PortfolioCreateRequest,
  google_protobuf_empty_pb.Empty,
  /**
   * @param {!proto.external.PortfolioCreateRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  google_protobuf_empty_pb.Empty.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.external.PortfolioCreateRequest,
 *   !proto.google.protobuf.Empty>}
 */
const methodInfo_PortfolioService_Create = new grpc.web.AbstractClientBase.MethodInfo(
  google_protobuf_empty_pb.Empty,
  /**
   * @param {!proto.external.PortfolioCreateRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  google_protobuf_empty_pb.Empty.deserializeBinary
);


/**
 * @param {!proto.external.PortfolioCreateRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.google.protobuf.Empty)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.google.protobuf.Empty>|undefined}
 *     The XHR Node Readable Stream
 */
proto.external.PortfolioServiceClient.prototype.create =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/external.PortfolioService/Create',
      request,
      metadata || {},
      methodDescriptor_PortfolioService_Create,
      callback);
};


/**
 * @param {!proto.external.PortfolioCreateRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.google.protobuf.Empty>}
 *     Promise that resolves to the response
 */
proto.external.PortfolioServicePromiseClient.prototype.create =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/external.PortfolioService/Create',
      request,
      metadata || {},
      methodDescriptor_PortfolioService_Create);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.external.PortfolioGetRequest,
 *   !proto.external.PortfolioGetResponse>}
 */
const methodDescriptor_PortfolioService_Get = new grpc.web.MethodDescriptor(
  '/external.PortfolioService/Get',
  grpc.web.MethodType.UNARY,
  proto.external.PortfolioGetRequest,
  proto.external.PortfolioGetResponse,
  /**
   * @param {!proto.external.PortfolioGetRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.external.PortfolioGetResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.external.PortfolioGetRequest,
 *   !proto.external.PortfolioGetResponse>}
 */
const methodInfo_PortfolioService_Get = new grpc.web.AbstractClientBase.MethodInfo(
  proto.external.PortfolioGetResponse,
  /**
   * @param {!proto.external.PortfolioGetRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.external.PortfolioGetResponse.deserializeBinary
);


/**
 * @param {!proto.external.PortfolioGetRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.external.PortfolioGetResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.external.PortfolioGetResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.external.PortfolioServiceClient.prototype.get =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/external.PortfolioService/Get',
      request,
      metadata || {},
      methodDescriptor_PortfolioService_Get,
      callback);
};


/**
 * @param {!proto.external.PortfolioGetRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.external.PortfolioGetResponse>}
 *     Promise that resolves to the response
 */
proto.external.PortfolioServicePromiseClient.prototype.get =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/external.PortfolioService/Get',
      request,
      metadata || {},
      methodDescriptor_PortfolioService_Get);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.external.PortfolioUpdateRequest,
 *   !proto.google.protobuf.Empty>}
 */
const methodDescriptor_PortfolioService_Update = new grpc.web.MethodDescriptor(
  '/external.PortfolioService/Update',
  grpc.web.MethodType.UNARY,
  proto.external.PortfolioUpdateRequest,
  google_protobuf_empty_pb.Empty,
  /**
   * @param {!proto.external.PortfolioUpdateRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  google_protobuf_empty_pb.Empty.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.external.PortfolioUpdateRequest,
 *   !proto.google.protobuf.Empty>}
 */
const methodInfo_PortfolioService_Update = new grpc.web.AbstractClientBase.MethodInfo(
  google_protobuf_empty_pb.Empty,
  /**
   * @param {!proto.external.PortfolioUpdateRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  google_protobuf_empty_pb.Empty.deserializeBinary
);


/**
 * @param {!proto.external.PortfolioUpdateRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.google.protobuf.Empty)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.google.protobuf.Empty>|undefined}
 *     The XHR Node Readable Stream
 */
proto.external.PortfolioServiceClient.prototype.update =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/external.PortfolioService/Update',
      request,
      metadata || {},
      methodDescriptor_PortfolioService_Update,
      callback);
};


/**
 * @param {!proto.external.PortfolioUpdateRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.google.protobuf.Empty>}
 *     Promise that resolves to the response
 */
proto.external.PortfolioServicePromiseClient.prototype.update =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/external.PortfolioService/Update',
      request,
      metadata || {},
      methodDescriptor_PortfolioService_Update);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.external.PortfolioDeleteRequest,
 *   !proto.google.protobuf.Empty>}
 */
const methodDescriptor_PortfolioService_Delete = new grpc.web.MethodDescriptor(
  '/external.PortfolioService/Delete',
  grpc.web.MethodType.UNARY,
  proto.external.PortfolioDeleteRequest,
  google_protobuf_empty_pb.Empty,
  /**
   * @param {!proto.external.PortfolioDeleteRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  google_protobuf_empty_pb.Empty.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.external.PortfolioDeleteRequest,
 *   !proto.google.protobuf.Empty>}
 */
const methodInfo_PortfolioService_Delete = new grpc.web.AbstractClientBase.MethodInfo(
  google_protobuf_empty_pb.Empty,
  /**
   * @param {!proto.external.PortfolioDeleteRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  google_protobuf_empty_pb.Empty.deserializeBinary
);


/**
 * @param {!proto.external.PortfolioDeleteRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.google.protobuf.Empty)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.google.protobuf.Empty>|undefined}
 *     The XHR Node Readable Stream
 */
proto.external.PortfolioServiceClient.prototype.delete =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/external.PortfolioService/Delete',
      request,
      metadata || {},
      methodDescriptor_PortfolioService_Delete,
      callback);
};


/**
 * @param {!proto.external.PortfolioDeleteRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.google.protobuf.Empty>}
 *     Promise that resolves to the response
 */
proto.external.PortfolioServicePromiseClient.prototype.delete =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/external.PortfolioService/Delete',
      request,
      metadata || {},
      methodDescriptor_PortfolioService_Delete);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.google.protobuf.Empty,
 *   !proto.external.AllPortfoliosResponse>}
 */
const methodDescriptor_PortfolioService_GetAllPortfolios = new grpc.web.MethodDescriptor(
  '/external.PortfolioService/GetAllPortfolios',
  grpc.web.MethodType.UNARY,
  google_protobuf_empty_pb.Empty,
  proto.external.AllPortfoliosResponse,
  /**
   * @param {!proto.google.protobuf.Empty} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.external.AllPortfoliosResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.google.protobuf.Empty,
 *   !proto.external.AllPortfoliosResponse>}
 */
const methodInfo_PortfolioService_GetAllPortfolios = new grpc.web.AbstractClientBase.MethodInfo(
  proto.external.AllPortfoliosResponse,
  /**
   * @param {!proto.google.protobuf.Empty} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.external.AllPortfoliosResponse.deserializeBinary
);


/**
 * @param {!proto.google.protobuf.Empty} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.external.AllPortfoliosResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.external.AllPortfoliosResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.external.PortfolioServiceClient.prototype.getAllPortfolios =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/external.PortfolioService/GetAllPortfolios',
      request,
      metadata || {},
      methodDescriptor_PortfolioService_GetAllPortfolios,
      callback);
};


/**
 * @param {!proto.google.protobuf.Empty} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.external.AllPortfoliosResponse>}
 *     Promise that resolves to the response
 */
proto.external.PortfolioServicePromiseClient.prototype.getAllPortfolios =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/external.PortfolioService/GetAllPortfolios',
      request,
      metadata || {},
      methodDescriptor_PortfolioService_GetAllPortfolios);
};


module.exports = proto.external;

