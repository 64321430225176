import React, { useEffect } from 'react';

import { Typography } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Crumps from 'components/Breadcrumps/Crumps';
import { closeModal, openModal } from 'components/Modal/modalReducer';
import Spinner from 'components/Spinner';
import UploadRightBox from 'components/Wizards/UploadRightBox';
import useActivityFeed from 'hooks/useActivityFeed';
import useRouter from 'hooks/useRouter';
import { useDispatch, useSelector } from 'react-redux';
import * as actions from 'store/actions';
import useStyles from 'styles/costManager';
import FacilityTabs from 'Workflows/Shared/FacilityManager/FacilityTabs';
import Header from 'Workflows/Shared/FacilityManager/Header';

const bundleId = 'Page-1-org';

const TenantManagerDetails = () => {
  const { query } = useRouter();
  const dispatch = useDispatch();
  const classes = useStyles();
  const router = useRouter();
  const { loaded, loading, details, error } = useSelector((state) => state.tenantManager.details);
  const pathName = `Tenants/${query.id}/TenantManager/${query.index}`;
  const bundleIds = useSelector((state) => state.users.byId);
  const { organizationid } = useSelector((state) => state.user.user);
  const { crumps, loaded: crumpsLoaded } = useSelector((state) => state.tenants.crumps);
  const { activityFeed, fetch } = useActivityFeed({ id: query.index, tenantLevel: true });

  useEffect(() => {
    if (query.index !== details.id) {
      dispatch(actions.getTenantCrumps(query.id));
      dispatch(actions.getTenantManagerDetails(query.index));
    }
  }, [query]);

  useEffect(() => {
    if (bundleIds.some((bundle) => bundle === bundleId) || !organizationid) {
      return;
    }
    dispatch(actions.getAllUsers(-1, bundleId, organizationid));
  }, []);

  const handleDelete = (ticketId) => {
    dispatch(actions.deleteTenantManagerTicket(ticketId)).then(() => {
      router.push(`/Tenants/${query.id}/TenantManager`);
    });
  };

  const handleDeleteFacility = (ticketId) => {
    dispatch(
      openModal({
        modalType: 'AlertModal',
        modalProps: {
          content:
            'Are you sure you want to delete this Tenant Manager, this action cannot be undone',
          title: 'Delete Tenant Manager',
          handleConfirm: () => {
            handleDelete(ticketId);
            dispatch(closeModal());
          }
        }
      })
    );
  };

  const handleSave = async (form) => {
    await dispatch(actions.updateTenantManagerTicket(form)).then(() => {
      dispatch(actions.getTenantManagerDetails(query.index));
      fetch();
      dispatch(closeModal());
    });
  };

  if (!loaded || loading || !crumpsLoaded) {
    return <Spinner />;
  }

  if (error) {
    return (
      <Box p={3}>
        <Typography color="error">{error}</Typography>
      </Box>
    );
  }

  return (
    <Box height="100%">
      <Paper className={classes.root}>
        <Crumps
          fragments={[
            { title: crumps.assetname, href: `/Asset/${crumps.assetid}` },
            { title: crumps.tenantname, href: `/Tenants/${crumps.tenantid}` },
            { title: 'Tenant Manager', href: `/Tenants/${crumps.tenantid}/TenantManager` },
            {
              title: details.caseref,
              href: `/Tenants/${crumps.tenantid}/TenantManager/${query.index}`
            }
          ]}
        />
        <Header
          handleDelete={handleDeleteFacility}
          details={details}
          handleSave={handleSave}
          header="Tenant Manager"
        />
      </Paper>

      <Box m={2}>
        <Grid container>
          <Grid item xs={12} sm={8} md={9} style={{ marginTop: 10 }}>
            <FacilityTabs details={details} activityFeed={activityFeed} />
          </Grid>

          <UploadRightBox pathName={pathName} />
        </Grid>
      </Box>
    </Box>
  );
};

export default TenantManagerDetails;
