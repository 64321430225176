import React, { useState } from 'react';

import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import SaveButton from 'components/Buttons/SaveButton';
import Dropdown from 'components/Filters/Dropdown';
import { validateEmail } from 'helpers/utils';
import useUrlParams from 'hooks/useUrlParams';
import isEqual from 'lodash/isEqual';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import * as actions from 'store/actions';

const useStyles = makeStyles((theme) => ({
  form: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    padding: theme.spacing(0, 8),
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(0, 4)
    }
  },
  textField: {
    margin: theme.spacing(2),
    width: theme.spacing(45),
    [theme.breakpoints.down('sm')]: {
      width: 'auto'
    },
    '& .MuiOutlinedInput-root': {
      '&.Mui-focused fieldset': {
        borderColor: theme.palette.primary.main
      }
    }
  },
  input: {
    fontFamily: theme.fonts.openSans
  },
  button: {
    color: 'white'
  }
}));

const UsersEditForm = ({ userRoles, userDetails, editRights }) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [form, setForm] = useState(userDetails);
  const [formCopy, setFormCopy] = useState(form);
  const { page } = useUrlParams();

  const [emailError, setEmailError] = useState(false);
  const loading = useSelector((state) => state.users.loading);

  const handleChange = (name, value) => {
    if (emailError) {
      setEmailError(false);
    }

    setForm((prevState) => ({
      ...prevState,
      [name]: value
    }));
  };

  const validateForm = () => {
    if (validateEmail(form.email)) {
      return dispatch(actions.updateUser(form))
        .then(() => {
          toast.success('User Updated');
          setFormCopy(form);
          dispatch(actions.getAllUsers(page, `Page${page}`));
        })
        .catch((err) => {
          toast.error(err);
        });
    }
    return setEmailError(true);
  };
  const handleSave = () => {
    validateForm();
  };
  return (
    <form className={classes.form} noValidate autoComplete="off">
      <TextField
        label="Display Name"
        variant="outlined"
        className={classes.textField}
        value={form.displayname}
        disabled={!editRights || loading}
        InputProps={{
          classes: {
            input: classes.input
          }
        }}
        onChange={(e) => handleChange('displayname', e.target.value)}
      />
      <TextField
        variant="outlined"
        className={classes.textField}
        value={form.email}
        error={emailError}
        disabled={!editRights || loading}
        InputProps={{
          classes: {
            input: classes.input
          }
        }}
        label={emailError ? 'Please provide a valid email Adress' : 'Email'}
        onChange={(e) => handleChange('email', e.target.value)}
      />
      <Box margin={3}>
        <Dropdown
          items={userRoles}
          value={form.roleid}
          handleChange={(e) => handleChange('roleid', e.target.value)}
          label="Role"
          disabled={!editRights || loading}
          keyword="roletitle"
        />
      </Box>

      <Box padding={4}>
        <SaveButton disabled={isEqual(formCopy, form)} loading={loading} onClick={handleSave} />
      </Box>
    </form>
  );
};

export default UsersEditForm;

UsersEditForm.propTypes = {
  userRoles: PropTypes.arrayOf(PropTypes.any).isRequired,
  userDetails: PropTypes.objectOf(PropTypes.any).isRequired,
  editRights: PropTypes.bool.isRequired
};
