import React, { useState } from 'react';

import Box from '@material-ui/core/Box';
import Divider from '@material-ui/core/Divider';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import ActivityFeed from 'components/ActivityFeed';
import { numberWithCommas } from 'helpers/utils';
import PropTypes from 'prop-types';
import useStyles from 'styles/costManager';

// eslint-disable-next-line react/prop-types
function DetailsItem({ label, value }) {
  const classes = useStyles();

  return (
    <Box display="flex" flexDirection="column" className={classes.boxInner}>
      <Typography className={classes.statsTitle}>{label}</Typography>
      <Typography className={classes.statsValue}>{value || 'N/A'}</Typography>
    </Box>
  );
}

const LegalTrackerTabs = ({ details, activityFeed }) => {
  const [tab, setTab] = useState(0);
  const classes = useStyles();

  return (
    <Paper className={classes.paperXL}>
      <Box display="flex" width="100%" flexDirection="column" p={2} mt={2}>
        <Box display="flex" p={3}>
          <Typography
            onClick={() => setTab(0)}
            className={classes.tabTitle}
            style={tab === 0 ? { color: '#5381EF' } : {}}
          >
            Details
          </Typography>
          <Typography
            onClick={() => setTab(1)}
            className={`${classes.tabTitle}`}
            style={tab === 1 ? { color: '#5381EF' } : {}}
          >
            Activity
          </Typography>
        </Box>

        <Box display="flex" justifyContent="center" alignItems="center" width="100%">
          <Divider width="90%" />
        </Box>
      </Box>
      {tab === 0 && (
        <Box p={3} pt={0} minHeight={350}>
          <Box className={classes.boxContainer}>
            <DetailsItem label="Description" value={details.description} />
            <DetailsItem label="Claimant" value={details.claimant} />
            <DetailsItem label="Party" value={details.party} />
            <DetailsItem label="Court/Enforcement Directorate" value={details.directorate} />
            <DetailsItem
              label="Estimated receivable/payment (€)"
              value={numberWithCommas(details.estimatedpayment)}
            />
            <DetailsItem label="Opinion" value={details.opinion} />
          </Box>
        </Box>
      )}
      {tab === 1 && <ActivityFeed activityFeed={activityFeed} nowrap />}
    </Paper>
  );
};

export default LegalTrackerTabs;

LegalTrackerTabs.propTypes = {
  details: PropTypes.objectOf(PropTypes.any).isRequired,
  activityFeed: PropTypes.arrayOf(PropTypes.any).isRequired
};
