import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  footerItem: {
    fontFamily: theme.fonts.base,
    fontWeight: 500
  },
  footfallitem: {
    fontFamily: theme.fonts.openSans,
    color: '#5C5C5C'
  },
  textPrimary: {
    color: theme.palette.primary.main,
    fontFamily: theme.fonts.openSans,
    cursor: 'pointer'
  },
  textSecondary: {
    fontFamily: theme.fonts.openSans,
    cursor: 'pointer',
    fontWeight: 'bold',
    fontSize: 14
  },
  input: {
    fontFamily: theme.fonts.openSans,
    color: '#5C5C5C'
  },
  textInput: {},
  editIcon: {
    color: '#5C5C5C',
    cursor: 'pointer',
    marginLeft: theme.spacing(1.5),
    marginRight: theme.spacing(1.5)
  }
}));

export default useStyles;
