import React, { useState } from 'react';

import Box from '@material-ui/core/Box';
import Divider from '@material-ui/core/Divider';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import ActivityFeed from 'components/ActivityFeed';
import PropTypes from 'prop-types';
import useStyles from 'styles/costManager';

const FacilityTabs = ({ details, activityFeed }) => {
  const [tab, setTab] = useState(0);
  const classes = useStyles();

  return (
    <Paper className={classes.paperXL}>
      <Box display="flex" width="100%" flexDirection="column" p={2} mt={2}>
        <Box display="flex" p={3}>
          <Typography
            onClick={() => setTab(0)}
            className={classes.tabTitle}
            style={tab === 0 ? { color: '#5381EF' } : {}}
          >
            Details
          </Typography>
          <Typography
            onClick={() => setTab(1)}
            className={classes.tabTitle}
            style={tab === 1 ? { color: '#5381EF' } : {}}
          >
            Activity
          </Typography>
        </Box>

        <Box display="flex" justifyContent="center" alignItems="center" width="100%">
          <Divider width="90%" />
        </Box>
      </Box>
      {tab === 0 ? (
        <Box p={3} pt={0} minHeight={350}>
          <Box className={classes.boxContainer}>
            <Box display="flex" flexDirection="column" className={classes.boxInner}>
              <Typography className={classes.statsTitle}>Description</Typography>
              <Typography className={classes.statsValue}>{details.description || 'N/A'}</Typography>
            </Box>
          </Box>
        </Box>
      ) : (
        <ActivityFeed activityFeed={activityFeed} nowrap />
      )}
    </Paper>
  );
};

export default FacilityTabs;

FacilityTabs.propTypes = {
  details: PropTypes.objectOf(PropTypes.any).isRequired,
  activityFeed: PropTypes.arrayOf(PropTypes.any).isRequired
};
