import { metaData } from 'config/config';
import wrappers from 'google-protobuf/google/protobuf/wrappers_pb';
import { formatForBackEnd } from 'helpers/utils';

import {
  TenantCostManagerRequest,
  CreateTenantCostManagerRequest,
  TenantCostProviderRequest,
  CreateTenantCostProviderRequest,
  UpdateTenantCostProviderRequest,
  UpdateTenantCostManagerRequest,
  TenantCostSupplierDetailsRequest,
  UpdateTenantCostSupplierDetailsRequest,
  GetTenantCostContractDetailsRequest,
  UpdateTenantCostContractDetailsRequest
} from '../../api/tenant_grpc_web_pb';
import {
  CostManagerService,
  CostContractDetailsService,
  CostProcurementService,
  CostSupplierService
} from '../services/tenant';

import * as actionTypes from './actionTypes';

const getCostManagerStart = () => ({
  type: actionTypes.GET_TENANT_COST_MANAGER_START
});

const getCostManagerSuccess = (costManager) => ({
  type: actionTypes.GET_TENANT_COST_MANAGER_SUCCESS,
  costManager
});

const getCostManagerFail = (error) => ({
  type: actionTypes.GET_TENANT_COST_MANAGER_FAIL,
  error
});

export const getTenantCostManager = (id) => (dispatch) => {
  dispatch(getCostManagerStart());
  const request = new TenantCostManagerRequest();

  request.setId(id);

  return new Promise((resolve, reject) => {
    CostManagerService.getTenantCostManagersByTenantID(request, metaData(), (err, response) => {
      if (err) {
        dispatch(getCostManagerFail(err.message));
        reject(err.message);
      } else {
        dispatch(getCostManagerSuccess(response.toObject().costmanagersList));

        resolve(response.toObject().costmanagersList);
      }
    });
  });
};

export const createTenantCostManager = (id, name) => () => {
  const request = new CreateTenantCostManagerRequest();

  request.setTenantid(id);
  request.setName(name);

  return new Promise((resolve, reject) => {
    CostManagerService.createTenantCostManager(request, metaData(), (err, response) => {
      if (err) {
        reject(err.message);
      } else {
        resolve(response.toObject());
      }
    });
  });
};

export const deleteTenantCostManager = (id) => () => {
  const request = new TenantCostManagerRequest();

  request.setId(id);

  return new Promise((resolve, reject) => {
    CostManagerService.deleteTenantCostManager(request, metaData(), (err, response) => {
      if (err) {
        reject(err.message);
      } else {
        resolve(response.toObject());
      }
    });
  });
};

const getTenantCostManagerDetailsStart = () => ({
  type: actionTypes.GET_TENANT_COST_MANAGER_DETAILS_START
});

const getTenantCostManagerDetailsSuccess = (details) => ({
  type: actionTypes.GET_TENANT_COST_MANAGER_DETAILS_SUCCESS,
  details
});

const getTenantCostManagerDetailsFail = (error) => ({
  type: actionTypes.GET_TENANT_COST_MANAGER_DETAILS_FAIL,
  error
});

export const getTenantCostManagerDetails = (id) => (dispatch) => {
  dispatch(getTenantCostManagerDetailsStart());
  const request = new TenantCostManagerRequest();

  request.setId(id);

  return new Promise((resolve, reject) => {
    CostManagerService.getTenantCostManagerByID(request, metaData(), (err, response) => {
      if (err) {
        dispatch(getTenantCostManagerDetailsFail(err.message));
        reject(err.message);
      } else {
        dispatch(getTenantCostManagerDetailsSuccess(response.toObject()));

        resolve(response.toObject());
      }
    });
  });
};

// Procurement
const getTenantCostManagerProcurementStart = () => ({
  type: actionTypes.GET_TENANT_COST_PROCUREMENT_START
});

const getTenantCostManagerProcurementSuccess = (procurement) => ({
  type: actionTypes.GET_TENANT_COST_PROCUREMENT_SUCCESS,
  procurement
});

const getTenantCostManagerProcurementFail = (error) => ({
  type: actionTypes.GET_TENANT_COST_PROCUREMENT_FAIL,
  error
});

export const getTenantCostManagerProcurement = (id) => (dispatch) => {
  dispatch(getTenantCostManagerProcurementStart());
  const request = new TenantCostProviderRequest();

  request.setId(id);

  return new Promise((resolve, reject) => {
    CostProcurementService.getTenantCostProvidersByCostManagerID(
      request,
      metaData(),
      (err, response) => {
        if (err) {
          dispatch(getTenantCostManagerProcurementFail(err.message));
          reject(err.message);
        } else {
          dispatch(getTenantCostManagerProcurementSuccess(response.toObject().costprovidersList));

          resolve(response.toObject());
        }
      }
    );
  });
};

export const createTenantCostManagerProcurement = (id, form) => () => {
  if (form.id) {
    const request = new UpdateTenantCostProviderRequest();

    request.setId(form.id);

    const name = new wrappers.StringValue();
    name.setValue(form.name);
    request.setName(name);

    const description = new wrappers.StringValue();
    description.setValue(form.description);
    request.setDescription(description);

    return new Promise((resolve, reject) => {
      CostProcurementService.updateTenantCostProvider(request, metaData(), (err, response) => {
        if (err) {
          reject(err.message);
        } else {
          resolve(response.toObject());
        }
      });
    });
  }

  const request = new CreateTenantCostProviderRequest();

  request.setTenantcostmanagerid(id);
  request.setName(form.name);
  request.setDescription(form.description);

  return new Promise((resolve, reject) => {
    CostProcurementService.createTenantCostProvider(request, metaData(), (err, response) => {
      if (err) {
        reject(err.message);
      } else {
        resolve(response.toObject());
      }
    });
  });
};

export const deleteTenantCostManagerProcurement = (id) => () => {
  const request = new TenantCostProviderRequest();
  request.setId(id);

  return new Promise((resolve, reject) => {
    CostProcurementService.deleteTenantCostProvider(request, metaData(), (err, response) => {
      if (err) {
        reject(err.message);
      } else {
        resolve(response.toObject());
      }
    });
  });
};

// Update TenantCost Manager Details
export const updateTenantCostManagerDetails = (id, name) => async () => {
  const request = new UpdateTenantCostManagerRequest();

  request.setId(id);
  request.setName(name);

  return new Promise((resolve, reject) => {
    CostManagerService.updateTenantCostManager(request, metaData(), (err, response) => {
      if (err) {
        reject(err.message);
      } else {
        resolve(response.toObject());
      }
    });
  });
};

// TenantCost Supplier Service
const getTenantCostSupplierService = (supplier) => ({
  type: actionTypes.GET_TENANT_COST_SUPPLIER_DETAILS,
  supplier
});

export const getTenantCostSupplierDetails = (id) => (dispatch) => {
  const request = new TenantCostSupplierDetailsRequest();
  request.setId(id);

  return new Promise((resolve, reject) => {
    CostSupplierService.getTenantCostSupplierDetailsByCostManagerID(
      request,
      metaData(),
      (err, response) => {
        if (err) {
          reject(err.message);
        } else {
          dispatch(getTenantCostSupplierService(response.toObject()));
          resolve(response.toObject());
        }
      }
    );
  });
};

export const updateTenantCostSupplierDetails = (form) => () => {
  const request = new UpdateTenantCostSupplierDetailsRequest();
  request.setId(form.id);

  const city = new wrappers.StringValue();
  city.setValue(form.city);
  request.setCity(city);

  const companyname = new wrappers.StringValue();
  companyname.setValue(form.companyname);
  request.setCompanyname(companyname);

  const contact = new wrappers.StringValue();
  contact.setValue(form.contact);
  request.setContact(contact);

  const country = new wrappers.StringValue();
  country.setValue(form.country);
  request.setCountry(country);

  const email = new wrappers.StringValue();
  email.setValue(form.email);
  request.setEmail(email);

  const postcode = new wrappers.StringValue();
  postcode.setValue(form.postcode);
  request.setPostcode(postcode);

  const street = new wrappers.StringValue();
  street.setValue(form.street);
  request.setStreet(street);

  const phone = new wrappers.StringValue();
  phone.setValue(form.phone);
  request.setPhone(phone);

  return new Promise((resolve, reject) => {
    CostSupplierService.updateTenantCostSupplierDetails(request, metaData(), (err, response) => {
      if (err) {
        reject(err.message);
      } else {
        resolve(response.toObject());
      }
    });
  });
};

// TenantCost Contract Service
const getTenantCostContractSuccess = (details) => ({
  type: actionTypes.GET_TENANT_COST_CONTRACT_DETAILS,
  details
});

export const getTenantCostContractDetails = (id) => (dispatch) => {
  const request = new GetTenantCostContractDetailsRequest();
  request.setId(id);

  return new Promise((resolve, reject) => {
    CostContractDetailsService.getTenantCostContractDetailsByCostManagerID(
      request,
      metaData(),
      (err, response) => {
        if (err) {
          reject(err.message);
        } else {
          dispatch(getTenantCostContractSuccess(response.toObject().contractdetailsList));
          resolve(response.toObject());
        }
      }
    );
  });
};

export const updateTenantCostContractDetails = (id, form) => () => {
  const request = new UpdateTenantCostContractDetailsRequest();
  request.setId(id);

  const typeValue = new wrappers.StringValue();
  typeValue.setValue('Supplier');
  request.setType(typeValue);

  const startDate = new wrappers.StringValue();
  startDate.setValue(formatForBackEnd(form.startdate));
  request.setStartdate(startDate);

  const endDate = new wrappers.StringValue();
  endDate.setValue(formatForBackEnd(form.enddate));
  request.setEnddate(endDate);

  let formCopy = {};
  if (form.supplier.paymentTerms === 'RegularPayment') {
    formCopy = {
      supplier: {
        paymentTerms: form.supplier.paymentTerms,
        schedule: {
          paymentAmount: form.supplier.regularPayment.paymentAmount,
          frequency: form.supplier.frequency
        }
      }
    };
  } else {
    formCopy = {
      supplier: {
        paymentTerms: form.supplier.paymentTerms,
        schedule: form.supplier.schedule.payments
      }
    };
  }
  const typeDataWrap = new wrappers.StringValue();
  typeDataWrap.setValue(JSON.stringify(formCopy));

  request.setTypedata(typeDataWrap);

  return new Promise((resolve, reject) => {
    CostContractDetailsService.updateTenantCostContractDetails(
      request,
      metaData(),
      (err, response) => {
        if (err) {
          reject(err.message);
        } else {
          resolve(response.toObject());
        }
      }
    );
  });
};

export const resetTenantCostManager = () => ({
  type: actionTypes.RESET_TENANT_COST_MANAGER
});
