/* eslint-disable no-unused-vars */
import { metaData } from 'config/config';
import wrappers from 'google-protobuf/google/protobuf/wrappers_pb';
import { formatForBackEnd } from 'helpers/utils';
import { toast } from 'react-toastify';

import {
  CreateLegalTrackerTicketRequest,
  GetLegalTrackerTicketRequest,
  GetLegalTrackerTicketByAssetIDRequest,
  UpdateLegalTrackerTicketRequest,
  DeleteLegalTrackerTicketRequest
} from '../../api/asset_grpc_web_pb';
import { LegalService, LegalMangementService } from '../services/asset';

import * as actionTypes from './actionTypes';

const getLegalTrackerStart = () => ({
  type: actionTypes.GET_ASSET_LEGAL_TRACKER_START
});

const getLegalTrackerSuccess = (legalTracker, bundleId) => ({
  type: actionTypes.GET_ASSET_LEGAL_TRACKER_SUCCESS,
  legalTracker,
  bundleId
});

const getLegalTrackerFail = (error) => ({
  type: actionTypes.GET_ASSET_LEGAL_TRACKER_FAIL,
  error
});

export const setAssetLegalTrackerBundle = (bundleId) => ({
  type: actionTypes.SET_ASSET_LEGAL_TRACKER,
  bundleId
});

export const getAssetLegalTracker = (id, bundleId) => async (dispatch) => {
  dispatch(getLegalTrackerStart());
  const request = new GetLegalTrackerTicketByAssetIDRequest();

  request.setAssetid(id);
  try {
    const result = await LegalMangementService.getLegalTrackerTicketByAssetID(request, metaData());
    const response = result.toObject().ticketsList;

    dispatch(getLegalTrackerSuccess(response, bundleId));
  } catch (error) {
    dispatch(getLegalTrackerFail(error.message));
    throw error.message;
  }
};
// Create
export const createLegalTicket = (assetId, form) => async () => {
  const request = new CreateLegalTrackerTicketRequest();

  request.setAssetid(assetId);
  request.setUserid(form.userid);
  request.setCaseref(form.caseref);
  request.setClaimant(form.claimant);
  request.setParty(form.party);
  request.setCategory(form.category);
  request.setForagainst(form.foragainst);
  request.setStatus(form.status);
  request.setExpecteddate(formatForBackEnd(form.expecteddate));
  request.setDescription(form.description);
  request.setDirectorate(form.directorate);
  request.setEstimatedpayment(form.estimatedpayment);
  request.setOpinion(form.opinion);

  return new Promise((resolve, reject) => {
    LegalService.createLegalTrackerTicket(request, metaData(), (err, response) => {
      if (err) {
        reject(err.message);
        toast.error(err.message);
      } else {
        resolve(response.toObject());
        toast.success('Legal Tracker Ticket Created Successfully');
      }
    });
  });
};

const getLegalTrackeDetailsStart = () => ({
  type: actionTypes.GET_ASSET_LEGAL_TRACKER_CASE_START
});

const getLegalTrackerDetailsSuccess = (details) => ({
  type: actionTypes.GET_ASSET_LEGAL_TRACKER_CASE_SUCCESS,
  details
});

const getLegalTrackerDetailsFail = (error) => ({
  type: actionTypes.GET_ASSET_LEGAL_TRACKER__CASE_FAIL,
  error
});

export const getAssetLegalTrackerDetails = (id) => async (dispatch) => {
  dispatch(getLegalTrackeDetailsStart());
  const request = new GetLegalTrackerTicketRequest();

  request.setId(id);

  try {
    const result = await LegalMangementService.getLegalTrackerTicket(request, metaData());
    const response = result.toObject();

    dispatch(getLegalTrackerDetailsSuccess(response));
    return response;
  } catch (error) {
    dispatch(getLegalTrackerDetailsFail(error.message));
    throw error.message;
  }
};

// Delete
export const deleteLegalTicket = (ticketId) => () => {
  const request = new DeleteLegalTrackerTicketRequest();
  request.setId(ticketId);

  return new Promise((resolve, reject) => {
    LegalService.deleteLegalTrackerTicket(request, metaData(), (err, response) => {
      if (err) {
        reject(err.message);
        toast.error(err.message);
      } else {
        resolve(response.toObject());
        toast.success('Ticket Deleted!');
      }
    });
  });
};

// Update
export const updateLegalTicket = (form) => () => {
  const request = new UpdateLegalTrackerTicketRequest();
  request.setId(form.id);

  if (form.userid) {
    const userid = new wrappers.StringValue();
    userid.setValue(form.userid);
    request.setUserid(userid);
  }

  if (form.caseref) {
    const caseref = new wrappers.StringValue();
    caseref.setValue(form.caseref);
    request.setCaseref(caseref);
  }

  if (form.expecteddate) {
    const expecteddate = new wrappers.StringValue();
    expecteddate.setValue(formatForBackEnd(form.expecteddate));
    request.setExpecteddate(expecteddate);
  }

  if (form.claimant) {
    const claimant = new wrappers.StringValue();
    claimant.setValue(form.claimant);
    request.setClaimant(claimant);
  }

  if ('status' in form) {
    const status = new wrappers.StringValue();
    status.setValue(form.status === 1 ? 'Closed' : 'Open');
    request.setStatus(status);
  }

  if (form.description) {
    const description = new wrappers.StringValue();
    description.setValue(form.description);
    request.setDescription(description);
  }

  if (form.party) {
    const party = new wrappers.StringValue();
    party.setValue(form.party);
    request.setParty(party);
  }

  if ('category' in form) {
    const category = new wrappers.StringValue();
    category.setValue(form.category === 1 ? 'Compensation' : 'Damages');
    request.setCategory(category);
  }

  if (form.directorate) {
    const directorate = new wrappers.StringValue();
    directorate.setValue(form.directorate);
    request.setDirectorate(directorate);
  }

  if (form.estimatedpayment) {
    const estimatedpayment = new wrappers.StringValue();
    estimatedpayment.setValue(form.estimatedpayment.toString());
    request.setEstimatedpayment(estimatedpayment);
  }

  if (form.opinion) {
    const opinion = new wrappers.StringValue();
    opinion.setValue(form.opinion);
    request.setOpinion(opinion);
  }

  if ('foragainst' in form) {
    const foragainst = new wrappers.BoolValue();
    foragainst.setValue(form.foragainst);
    request.setForagainst(foragainst);
  }

  return new Promise((resolve, reject) => {
    LegalService.updateLegalTrackerTicket(request, metaData(), (err, response) => {
      if (err) {
        reject(err.message);
        toast.error(err.message);
      } else {
        toast.success('Ticket Updated!');
        resolve(response.toObject());
      }
    });
  });
};
