import * as actionTypes from '../actions/actionTypes';

export const tenantsInitialState = {
  tenants: {
    loading: false,
    loaded: false,
    data: [],
    error: null
  },
  tenantDetails: {
    loading: false,
    loaded: false,
    data: null,
    error: null
  },
  crumps: {
    crumps: {
      tenantid: '',
      tenantname: '',
      assetid: '',
      assetname: '',
      portfolioid: '',
      portfiolioname: ''
    },
    loading: false,
    loaded: false,
    error: null
  },
  allIds: [],
  byId: []
};

const reducer = (state = tenantsInitialState, action) => {
  switch (action.type) {
    case actionTypes.GET_TENANTS_START:
      return {
        ...state,
        tenants: {
          loading: true
        }
      };
    case actionTypes.GET_TENANTS_SUCCESS:
      return {
        ...state,
        tenants: {
          loading: false,
          loaded: true,
          tenants: action.tenants
        },
        byId: state.byId.some((id) => id === action.bundleId)
          ? state.byId
          : [...state.byId, action.bundleId],
        allIds: { ...state.allIds, [action.bundleId]: [...action.tenants] }
      };
    case actionTypes.GET_TENANTS_FAIL:
      return {
        ...state,
        tenants: {
          loading: false,
          loaded: true,
          error: action.error
        }
      };
    case actionTypes.SET_TENANTS_BUNDLE: {
      return {
        ...state,
        tenants: {
          ...state.tenants,
          tenants: state.allIds[action.bundleId]
        }
      };
    }

    case actionTypes.GET_BREADCRUMPS_START:
      return {
        ...state,
        crumps: {
          ...state.crumps,
          loading: true
        }
      };
    case actionTypes.GET_BREADCRUMPS_SUCCESS:
      return {
        ...state,
        crumps: {
          ...state.crumps,
          loading: false,
          loaded: true,
          crumps: action.crumps,
          error: null
        }
      };
    case actionTypes.GET_BREADCRUMPS_FAIL:
      return {
        ...state,
        crumps: {
          ...state.crumps,
          loading: false,
          error: action.error,
          loaded: true
        }
      };

    case actionTypes.GET_TENANTS_DETAILS_START:
      return {
        ...state,
        tenantDetails: {
          ...state.tenantDetails,
          loading: true
        }
      };
    case actionTypes.GET_TENANTS_DETAILS_SUCCESS:
      return {
        ...state,
        tenantDetails: {
          ...state.tenantDetails,
          loading: false,
          loaded: true,
          data: action.tenantDetails
        }
      };
    case actionTypes.GET_TENANTS_DETAILS_FAIL:
      return {
        ...state,
        tenantDetails: {
          ...state.tenantDetails,
          loading: false,
          loaded: true,
          error: action.error
        }
      };
    default:
      return state;
  }
};

export default reducer;
