/* eslint-disable react/prop-types */
import React from 'react';

import Checkbox from '@material-ui/core/Checkbox';
import Chip from '@material-ui/core/Chip';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import Autocomplete from '@material-ui/lab/Autocomplete';
import PropTypes from 'prop-types';

const useStyles = makeStyles((theme) => ({
  autocompleteRoot: {
    [theme.breakpoints.down('md')]: {
      width: '220px !important'
    },
    maxWidth: 400
  },
  listbox: {
    padding: 0
  },
  option: {
    background: 'transparent',
    '&[aria-selected="true"]': {
      background: 'transparent'
    }
  },
  listItem: {
    fontFamily: theme.fonts.openSans,
    color: '#5C5C5C'
  },
  root: {
    boxShadow: '0px 3px 14px #0000002B'
  },
  select: {
    color: '#5C5C5C'
  },
  label: {
    fontFamily: theme.fonts.openSans
  },
  selected: {
    marginLeft: 14,
    fontFamily: theme.fonts.openSans,
    color: '#5C5C5C'
  }
}));

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const MultiSelectFilter = ({ items, urlParams, setUrlParams, keyword, label, large, disabled }) => {
  const [value, setValue] = React.useState([]);
  const classes = useStyles();

  React.useEffect(() => {
    const newvals = items.filter((item) => urlParams.includes(item.id));

    setValue(newvals);
  }, [urlParams]);

  return (
    <Autocomplete
      multiple
      data-cy={`${label}-filter`}
      data-testid={`${label}-filter`}
      id={`${label}-filter`}
      options={items}
      classes={{
        listbox: classes.listbox,
        option: classes.option,
        paper: classes.root
      }}
      openOnFocus
      disabled={disabled}
      value={value}
      onChange={(event, newValue) => {
        setValue(newValue);
        setUrlParams(newValue.map((item) => item.id));
      }}
      renderTags={(val, getTagProps) => {
        if (val.length > 1) {
          return (
            <Typography className={classes.selected}>{`${urlParams.length} Selected`}</Typography>
          );
        }
        return val.map((option, index) => (
          <Chip
            variant="outlined"
            label={option.name}
            {...getTagProps({ index })}
            style={{ backgroundColor: '#e0e0e0' }}
          />
        ));
      }}
      label={label}
      getOptionLabel={(option) => option[keyword]}
      renderOption={(option, { selected }) => (
        <>
          <Checkbox icon={icon} checkedIcon={checkedIcon} checked={selected} color="primary" />
          <Typography className={classes.listItem}>{option[keyword]}</Typography>
        </>
      )}
      style={
        large
          ? { width: '100%', marginBottom: 15 }
          : { width: 220, marginRight: 15, marginBottom: 15 }
      }
      className={classes.autocompleteRoot}
      renderInput={(params) => (
        <TextField
          {...params}
          variant="outlined"
          fullWidth
          label={label}
          placeholder={urlParams.length > 0 ? null : 'Search'}
        />
      )}
    />
  );
};

export default React.memo(MultiSelectFilter);

MultiSelectFilter.defaultProps = {
  label: '',
  disabled: false,
  large: false
};

MultiSelectFilter.propTypes = {
  items: PropTypes.arrayOf(PropTypes.object).isRequired,
  label: PropTypes.string,
  disabled: PropTypes.bool,
  large: PropTypes.bool,
  keyword: PropTypes.string.isRequired
};
