import * as actionTypes from '../actions/actionTypes';

export const assetFacilityManagerInitialState = {
  facilityManager: {},
  loading: false,
  loaded: false,
  error: null,
  details: {
    loading: false,
    loaded: false,
    error: null,
    details: {},
    activityFeed: []
  }
};

const reducer = (state = assetFacilityManagerInitialState, action) => {
  switch (action.type) {
    case actionTypes.GET_ASSET_FACILITY_MANAGER_START:
      return {
        ...state,
        loading: true
      };
    case actionTypes.GET_ASSET_FACILITY_MANAGER_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        facilityManager: action.facilityManager
      };
    case actionTypes.GET_ASSET_FACILITY_MANAGER_FAIL:
      return {
        ...state,
        loading: false,
        loaded: true,
        error: action.error
      };

    case actionTypes.GET_ASSET_FACILITY_MANAGER_CASE_START:
      return {
        ...state,
        details: {
          ...state.details,
          loading: true
        }
      };
    case actionTypes.GET_ASSET_FACILITY_MANAGER_CASE_SUCCESS:
      return {
        ...state,
        details: {
          ...state.details,
          loading: false,
          loaded: true,
          details: action.details
        }
      };
    case actionTypes.GET_ASSET_FACILITY_MANAGER__CASE_FAIL:
      return {
        ...state,
        details: {
          ...state.details,
          loading: false,
          loaded: true,
          error: action.error
        }
      };

    default:
      return state;
  }
};

export default reducer;
