import React, { Suspense, useEffect } from 'react';

import { Badge, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Notifications } from '@material-ui/icons';
import Breadcrumps from 'components/Breadcrumps';
import Container from 'components/Container';
import Spinner from 'components/Spinner';
import { formatZillions, formatDate } from 'helpers/utils';
import UserHasAccess from 'hooks/UserHasAccess';
import useUrlParams from 'hooks/useUrlParams';
import { useSelector, useDispatch } from 'react-redux';
import * as actions from 'store/actions';

const TenantsTable = React.lazy(() => import('./TenantsTable'));

const makeBundleId = (portfolioIds) => `Page1-PortfolioIds${portfolioIds}`;

const useStyles = makeStyles((theme) => ({
  icon: {
    color: '#CCCCCC'
  },
  root: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: 500,
    padding: theme.spacing(6)
  },
  badge: {
    minWidth: theme.spacing(2.2),
    height: theme.spacing(2.2),
    width: theme.spacing(2.2)
  }
}));

const Tenants = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { portfolioIds, setPortfolios } = useUrlParams();
  const { loading, loaded, error, tenants } = useSelector((state) => state.tenants.tenants);
  const userPermissions = useSelector((state) => state.user.userPermissions.permissionsList);
  const userPortfolios = useSelector((state) => state.portfolios.portfolios);
  const EditTenantsPermissions = UserHasAccess('tenant', 'edit', userPermissions);
  const assets = useSelector((state) => state.assets.assets);
  const columns = [
    {
      title: 'Tenant',
      field: 'companyname',
      customSort: (a, b) => a.companyname.localeCompare(b.companyname),
      render: (rowData) => <span>{rowData.companyname}</span>
    },
    {
      title: 'Asset',
      field: 'assetname',
      customSort: (a, b) => a.assetname.localeCompare(b.assetname),
      render: (rowData) => <span>{rowData.assetname}</span>
    },
    {
      title: 'Contract Start',
      field: 'contractstartdate',
      render: (rowData) => <span>{formatDate(rowData.contractstartdate)}</span>
    },
    {
      title: 'Contract End',
      field: 'contractenddate',
      render: (rowData) => <span>{formatDate(rowData.contractenddate)}</span>
    },
    {
      title: 'GLA (m2)',
      field: 'totalgla',
      render: (rowData) => <span>{formatZillions(rowData.totalgla)}</span>
    },
    {
      title: 'In-place',
      field: 'inplace',
      render: (rowData) => (
        <span style={{ color: rowData.inplace ? '#01B231' : '#AAAAAA' }}>
          {rowData.inplace ? 'Yes' : 'No'}
        </span>
      )
    },
    {
      title: 'Notifications',
      field: 'notifs',
      render: (rowData) => (
        <Badge
          badgeContent={rowData.notifs}
          color="error"
          overlap="circular"
          classes={{ badge: classes.badge }}
        >
          <Notifications className={classes.icon} />
        </Badge>
      )
    }
  ];

  useEffect(() => {
    const bundleId = makeBundleId(portfolioIds);
    dispatch(actions.getTenants(portfolioIds, bundleId, [], true));
  }, [portfolioIds]);

  if (loaded && error) {
    return (
      <div className={classes.root}>
        <Typography color="error" variant="h5">
          {error.substr(0, 45)}
        </Typography>
      </div>
    );
  }

  return (
    <Container>
      <Suspense fallback={<Spinner mini />}>
        <Breadcrumps />
        <TenantsTable
          title={`Tenants (${tenants?.length || 0})`}
          columns={columns}
          data={tenants}
          loading={loading}
          EditTenantsPermissions={EditTenantsPermissions}
          userPortfolios={userPortfolios}
          portfolioIds={portfolioIds}
          setPortfolios={setPortfolios}
          assets={assets}
        />
      </Suspense>
    </Container>
  );
};

export default Tenants;
