import React from 'react';

import { Box, Paper, Typography, Divider, Grid } from '@material-ui/core';
import EditButton from 'components/Buttons/EditButton';
import UploadRightBox from 'components/Wizards/UploadRightBox';
import { formatDate, numberWithCommas } from 'helpers/utils';
import PropTypes from 'prop-types';
import useStyles from 'Workflows/Shared/CostManager/styles';

import EditForm from './ContractDetailsEdit';

const CostContractDetails = ({ id, details, pathName, type }) => {
  const classes = useStyles();
  const [openEdit, setOpenEdit] = React.useState(false);
  const formatedData = details[0]?.typedata ? JSON.parse(details[0]?.typedata) : {};
  const paymentAmount =
    formatedData.supplier?.schedule.length > 0
      ? formatedData.supplier?.schedule?.reduce((acc, b) => acc + b.paymentAmount, 0)
      : formatedData.supplier?.schedule?.paymentAmount;

  return (
    <Box m={2}>
      <Grid container>
        <Grid item xs={12} sm={8} md={9} style={{ marginTop: 10 }}>
          <Paper className={classes.paper}>
            <Box>
              <Box
                display="flex"
                alignItems="center"
                flexWrap="wrap"
                className={classes.responsive}
              >
                <Typography className={classes.title}>Contract Details</Typography>
                <EditButton onClick={() => setOpenEdit(true)} />
              </Box>
              <EditForm
                open={openEdit}
                handleClose={() => setOpenEdit(false)}
                details={details[0]}
                id={id}
                type={type}
              />

              <Box display="flex" justifyContent="center">
                <Divider className={classes.divider} />
              </Box>
              <Box className={classes.boxContainer}>
                <Box display="flex" flexDirection="column" className={classes.boxInner}>
                  <Typography className={classes.statsTitle}>Contract Start Date</Typography>
                  <Typography className={classes.statsValue}>
                    {formatDate(details[0]?.startdate) || 'N/A'}
                  </Typography>
                </Box>
                <Box display="flex" flexDirection="column" className={classes.boxInner}>
                  <Typography className={classes.statsTitle}>Contract End Date</Typography>
                  <Typography className={classes.statsValue}>
                    {formatDate(details[0]?.enddate) || 'N/A'}
                  </Typography>
                </Box>
                <Box display="flex" flexDirection="column" className={classes.boxInner}>
                  <Typography className={classes.statsTitle}>Contract Type</Typography>
                  <Typography className={classes.statsValue}>
                    {details[0]?.type || 'N/A'}
                  </Typography>
                </Box>
                <Box display="flex" flexDirection="column" className={classes.boxInner}>
                  <Typography className={classes.statsTitle}>Payment Amount</Typography>
                  <Typography className={classes.statsValue}>
                    {`€ ${numberWithCommas(paymentAmount)}`}
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Paper>
        </Grid>

        <UploadRightBox pathName={pathName} />
      </Grid>
    </Box>
  );
};

export default CostContractDetails;

CostContractDetails.propTypes = {
  id: PropTypes.string.isRequired,
  pathName: PropTypes.string.isRequired,
  details: PropTypes.arrayOf(PropTypes.any).isRequired,
  type: PropTypes.string.isRequired
};
