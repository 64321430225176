import React from 'react';

import TextField from '@material-ui/core/TextField';
import PropTypes from 'prop-types';
import useStyles from 'styles/forms';

export default function TextInput({ disabled, name, large, label, children, ...rest }) {
  const classes = useStyles();
  return (
    <TextField
      fullWidth
      label={label}
      variant="outlined"
      className={large ? classes.textFieldXL : classes.textField}
      InputProps={{
        classes: {
          input: classes.input
        }
      }}
      placeholder={label}
      name={name}
      id={name}
      disabled={disabled}
      {...rest}
    >
      {children}
    </TextField>
  );
}

TextInput.defaultProps = {
  children: null,
  large: false
};

TextInput.propTypes = {
  disabled: PropTypes.bool.isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  large: PropTypes.bool,
  children: PropTypes.node
};
