/* eslint-disable function-paren-newline */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useEffect, Suspense, lazy } from 'react';

import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Spinner from 'components/Spinner';
import useRouter from 'hooks/useRouter';
import { useDispatch, useSelector } from 'react-redux';
import { Switch, Route } from 'react-router-dom';
import * as actions from 'store/actions';

import Header from './Header';
import SideMenu from './SideMenu';
import useStyles from './styles';

const Actuals = lazy(() => import('./Actuals'));
const Tenants = lazy(() => import('./Tenants'));
const Details = lazy(() => import('./Details'));
const CashManager = lazy(() => import('Workflows/Shared/CashManager/AssetCashManager'));
const OpexManager = lazy(() => import('Workflows/Property/OpexManager'));
const FacilityManager = lazy(() => import('Workflows/Property/FacilityManager'));
const CostManager = lazy(() => import('Workflows/Asset/AssetCostManager'));
const LegalTracker = lazy(() => import('Workflows/Asset/LegalTracker'));
const Dashboard = lazy(() => import('Workflows/Asset/Reports/Dashboard/Dashboard'));
const Valuations = lazy(() => import('Workflows/Asset/Valuations'));
const AdvancedReports = lazy(() => import('./AdvancedReports'));
const AssetCostManagerFlow = lazy(() =>
  import('Workflows/Asset/AssetCostManager/AssetCostManagerFlow')
);
const AssetLegalTrackerDetails = lazy(() =>
  import('Workflows/Asset/LegalTracker/LegalTrackerDetails')
);
const ValuationsDetails = lazy(() => import('Workflows/Asset/Valuations/ValuationsDetails'));
const FacilityManagerDetails = lazy(() =>
  import('Workflows/Property/FacilityManager/FacilityDetails')
);
const OpexManagerFlow = lazy(() => import('Workflows/Property/OpexManager/OpexManagerFlow'));

const routes = [
  { path: '/Asset/:id/Details', component: Details },
  { path: '/Asset/:id/Tenants', component: Tenants },
  { path: '/Asset/:id/OpexManager', component: OpexManager },
  { path: '/Asset/:id/FacilityManager', component: FacilityManager },
  { path: '/Asset/:id/Actuals', component: Actuals },
  { path: '/Asset/:id/Valuations', component: Valuations },
  { path: '/Asset/:id/CostManager', component: CostManager },
  { path: '/Asset/:id/CashManager', component: CashManager },
  { path: '/Asset/:id/LegalTracker', component: LegalTracker },
  { path: '/Asset/:id/Reports', component: Dashboard },
  { path: '/Asset/:id/AdvancedReporting', component: AdvancedReports },
  { path: '/Asset/:id/OpexManager/:index', component: OpexManagerFlow },
  { path: '/Asset/:id/FacilityManager/:index', component: FacilityManagerDetails },
  { path: '/Asset/:id/LegalTracker/:index', component: AssetLegalTrackerDetails },
  { path: '/Asset/:id/CostManager/:index', component: AssetCostManagerFlow },
  { path: '/Asset/:id/Valuations/:index', component: ValuationsDetails }
];

const AssetDetails = () => {
  const { query } = useRouter();
  const dispatch = useDispatch();
  const classes = useStyles();
  const { loading, loaded, data: asset, error } = useSelector((state) => state.assets.assetDetails);

  useEffect(() => {
    if (!query.id || (query.id === asset.id && loaded)) {
      return;
    }

    dispatch(actions.getAssetDetails(query.id));
    dispatch(actions.getAssetKpi(query.id));
    dispatch(actions.listImages(query.id));
    dispatch(actions.getUnitSchedule(query.id));
  }, [query.id]);

  if (loading || !loaded) {
    return <Spinner />;
  }

  if (error && loaded) {
    return (
      <Box m={4}>
        <Typography color="error">{error}</Typography>
      </Box>
    );
  }

  return (
    <Box className={classes.container}>
      <Box maxWidth="210px" minWidth="210px">
        <SideMenu />
      </Box>
      <Box width="100%" className={classes.scrollable}>
        <Box>
          <Header />
          <Suspense fallback={<Spinner mini />}>
            <Switch>
              {routes.map((route) => (
                <Route exact path={route.path} key={route.path}>
                  <route.component />
                </Route>
              ))}
            </Switch>
          </Suspense>
        </Box>
      </Box>
    </Box>
  );
};

export default AssetDetails;
