import modalReducer from 'components/Modal/modalReducer';
import { combineReducers } from 'redux';

import ActualsReducer, { actualsInitialState } from './actualsReducer';
import alertReducer, { alertInitialState } from './alertReducer';
import AssetCashManagerReducer, { assetCashManagerInitialState } from './assetCashManagerReducer';
import AssetCostManagerReducer, { AssetCostManagerInitialState } from './assetCostManagerReducer';
import AssetLegalTracker, { assetLegalTrackerInitialState } from './assetLegalTrackerReducer';
import AssetReducer, { assetsInitialState } from './assetsReducer';
import FacilityManagerReducer, { assetFacilityManagerInitialState } from './facilityManagerReducer';
import HomeDataReducer from './homeReducer';
import MLAReducer, { MLAInitialState } from './MLAReducer';
import OpexManagerReducer, { OpexManagerInitialState } from './opexManagerReducer';
import PortfoliosReducer, { portfolioInitialState } from './PortfoliosReducer';
import ReportsReducer, { reportsInitialState } from './reportsReducer';
import TenantCashManagerReducer, { cashManagerInitialState } from './tenantCashManagerReducer';
import TenantCostManagerReducer from './tenantCostManagerReducer';
import TenantLeasingManagerReducer from './tenantLeasingManagerReducer';
import TenantLegalTrackerReducer from './tenantLegalTrackerReducer';
import TenantManagerReducer, { tenantManagerInitialState } from './tenantManagerReducer';
import TenantReducer, { tenantsInitialState } from './tenantsReducer';
import UserReducer, { userInitialState } from './userReducer';
import UsersReseducer, { usersInitialState } from './usersReducer';
import ValuationsReducer, { assetValuationsInitialState } from './valuationReducer';

const allReducers = {
  user: UserReducer,
  users: UsersReseducer,
  portfolios: PortfoliosReducer,
  assets: AssetReducer,
  tenants: TenantReducer,
  home: HomeDataReducer,
  opexManager: OpexManagerReducer,
  costManager: TenantCostManagerReducer,
  cashManager: TenantCashManagerReducer,
  legalTracker: TenantLegalTrackerReducer,
  leasingManager: TenantLeasingManagerReducer,
  assetCostManager: AssetCostManagerReducer,
  assetCashManager: AssetCashManagerReducer,
  assetLegalTracker: AssetLegalTracker,
  facilityManager: FacilityManagerReducer,
  tenantManager: TenantManagerReducer,
  valuations: ValuationsReducer,
  reports: ReportsReducer,
  alerts: alertReducer,
  modals: modalReducer,
  actuals: ActualsReducer,
  mla: MLAReducer
};

const combinedReducer = combineReducers(allReducers);

const rootReducer = (state, action) => {
  if (action.type === 'USER_SIGNOUT') {
    // window.location.reload();
    return {
      ...state,
      assets: assetsInitialState,
      portfolios: portfolioInitialState,
      tenants: tenantsInitialState,
      user: userInitialState,
      users: usersInitialState,
      cashManager: cashManagerInitialState,
      assetCostManager: AssetCostManagerInitialState,
      assetCashManager: assetCashManagerInitialState,
      assetLegalTracker: assetLegalTrackerInitialState,
      facilityManager: assetFacilityManagerInitialState,
      tenantManager: tenantManagerInitialState,
      valuations: assetValuationsInitialState,
      opexManager: OpexManagerInitialState,
      reports: reportsInitialState,
      alerts: alertInitialState,
      actuals: actualsInitialState,
      mla: MLAInitialState
    };
  }

  return combinedReducer(state, action);
};

export default rootReducer;
