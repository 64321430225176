import React from 'react';

import Box from '@material-ui/core/Box';
import Divider from '@material-ui/core/Divider';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch, useSelector } from 'react-redux';
import * as actions from 'store/actions';

import SideAccordion from './SideAccordion';
import SideFilters from './SIdeFilters';

const useStyles = makeStyles((theme) => ({
  list: {
    height: '100%',
    width: 'auto',
    [theme.breakpoints.up('md')]: {
      width: 500
    },
    overflowY: 'auto'
  },
  fullList: {
    width: 'auto'
  }
}));

const SideBar = () => {
  const classes = useStyles();
  const { open } = useSelector((state) => state.alerts);
  const dispatch = useDispatch();

  const list = () => (
    <div
      className={classes.list}
      role="presentation"
      onKeyDown={() => dispatch(actions.toggleAlertMenu())}
    >
      <Box bgcolor="#F2F3F7" height="100%">
        <Box height={64} />
        <Box minHeight={300} bgcolor="#ffffff">
          <List>
            <ListItem style={{ justifyContent: 'center' }}>
              <SideFilters />
            </ListItem>
          </List>
        </Box>

        <Divider />
        <Box>
          <SideAccordion />
        </Box>
        <List />
      </Box>
    </div>
  );

  return (
    <>
      <Drawer open={open} onClose={() => dispatch(actions.toggleAlertMenu())} anchor="right">
        {list()}
      </Drawer>
    </>
  );
};
export default SideBar;
