import React, { memo } from 'react';

import Box from '@material-ui/core/Box';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import { formatDate } from 'helpers/utils';
import PropTypes from 'prop-types';

import AlertItemActions from '../actions';
import useStyles from '../styles';
import Summary from '../summary';

const LeaseBreakAlert = ({ item, toggleDrawer }) => {
  const classes = useStyles();
  const { name, assetname, portfolioname, id, entityid, type, tenantname, lease } = item;

  return (
    <Box display="flex" width="100%" flexDirection="column" key={id}>
      <Box display="flex" justifyContent="center" mt={1} mb={1}>
        <Divider className={classes.divider} />
      </Box>
      <Typography className={classes.accordionTitle}>{name}</Typography>

      <Summary title="Tenant" value={tenantname} />

      <Summary title="Asset" value={assetname} />

      <Summary title="Portfolio" value={portfolioname} />

      <Summary title="Lease Break Date" value={formatDate(lease.enddate)} />

      <AlertItemActions
        URL={`/Tenants/${entityid}/Options`}
        toggleDrawer={toggleDrawer}
        entityId={entityid}
        type={type}
        name={name}
      />
    </Box>
  );
};

LeaseBreakAlert.propTypes = {
  item: PropTypes.objectOf(PropTypes.any).isRequired,
  toggleDrawer: PropTypes.func.isRequired
};

export default memo(LeaseBreakAlert);
