import React, { lazy, Suspense } from 'react';

import Spinner from 'components/Spinner';
import PropTypes from 'prop-types';

import Box from './Box';
import ChartRow from './ChartRow/ChartRow';
import TenantPerformanceKpis from './TenantPerformanceKpis';
import TenantPicker from './TenantPicker';

const Chart = lazy(() => import('./Chart'));

const TenantPerformance = ({ tenants, loaded }) => {
  if (!loaded) {
    return (
      <Box p={3} mt={3} width="95%" height={600}>
        <Spinner mini />
      </Box>
    );
  }

  return (
    <Box>
      <TenantPicker tenants={tenants} />
      <TenantPerformanceKpis />
      <ChartRow />
      <Suspense fallback={<Spinner />}>
        <Chart />
      </Suspense>
    </Box>
  );
};

TenantPerformance.propTypes = {
  tenants: PropTypes.arrayOf(PropTypes.any).isRequired,
  loaded: PropTypes.bool.isRequired
};

export default TenantPerformance;
