import { numberWithCommas } from 'helpers/utils';

export const config = {
  options: {
    chart: {
      type: 'line',
      zoom: {
        enabled: false
      },
      toolbar: {
        show: false
      }
    },
    colors: ['#0b7de0', '#c41212'],
    dataLabels: {
      enabled: false
    },
    stroke: {
      curve: 'straight',
      width: [2, 2]
    },
    markers: {
      size: [4, 4],
      shape: 'circle'
    },
    title: {
      align: 'left'
    },
    grid: {
      show: true
    },
    legend: {
      show: false
    },
    xaxis: {
      categories: [
        'Jan',
        'Feb',
        'Mar',
        'Apr',
        'May',
        'Jun',
        'Jul',
        'Aug',
        'Sep',
        'Oct',
        'Nov',
        'Dec'
      ]
    },
    yaxis: {
      labels: {
        formatter(value) {
          return `€${numberWithCommas(value)}`;
        }
      }
    }
  }
};
