export const LegalTracker = [
  {
    caseref: '4683',
    id: 1,
    query: 98712,
    category: 0,
    claimAmount: 120000,
    status: 1,
    userid: 'dd0016ae-00b6-4fd2-9e96-b3ce45b64b5c',
    expecteddate: '2021-01-02',
    createddate: '2020-02-01',
    party: 'Party Name',
    claimant: 'Claimant name',
    foragainst: true,
    description: 'lorem ipsum',
    directorate: 'The 2nd civil court',
    estimatedpayment: '20000',
    opinion: 'lorem ipsum 3000'
  },
  {
    caseref: '7383',
    id: 2,
    query: 91493,
    category: 1,
    claimAmount: 420000,
    status: 0,
    userid: 'dd0016ae-00b6-4fd2-9e96-b3ce45b64b5c',
    expecteddate: '2023-01-02',
    createddate: '2020-02-01',
    party: 'Party Name',
    claimant: 'Claimant name',
    foragainst: true,
    description: 'lorem ipsum',
    directorate: 'The 2nd civil court',
    estimatedpayment: '20000',
    opinion: 'lorem ipsum 3000'
  },
  {
    caseref: '7683',
    id: 3,
    query: 98711,
    category: 1,
    claimAmount: 130000,
    status: 0,
    userid: 'dd0016ae-00b6-4fd2-9e96-b3ce45b64b5c',
    expecteddate: '2021-01-02',
    createddate: '2020-02-01',
    party: 'Party Name',
    claimant: 'Claimant name',
    foragainst: true,
    description: 'lorem ipsum',
    directorate: 'The 2nd civil court',
    estimatedpayment: '20000',
    opinion: 'lorem ipsum 3000'
  }
];

export const LegalTrackerDetails = {
  id: '1',
  caseref: '7683',
  status: 0,
  userid: 'dd0016ae-00b6-4fd2-9e96-b3ce45b64b5c',
  createddate: '2020-02-01',
  party: 'Party Name',
  claimant: 'Claimant name',
  category: 1,
  foragainst: true,
  description: 'lorem ipsum',
  court: 'The 2nd civil court',
  estimatedpayment: '20000',
  opinion: 'lorem ipsum 3000',
  expecteddate: '2020-01-01',
  activityFeed: [
    { id: 1, name: 'Query Created', date: '2020-01-01' },
    { id: 2, name: 'pdf uploaded', date: '2019-02-02' },
    { id: 3, name: 'Spoke with John Doe', date: '2019-02-01' }
  ]
};

export const mockFacilityManager = [
  {
    id: '1',
    queryname: 'Broken Lift',
    caseref: 8031,
    createddate: '2020-01-01',
    location: 'upper level',
    duedate: '2021-01-03',
    userid: 'dd0016ae-00b6-4fd2-9e96-b3ce45b64b5c',
    description: 'lorem10103310130',
    status: 0
  },
  {
    id: '2',
    queryname: 'Broken Window',
    caseref: 8061,
    createddate: '2020-01-01',
    location: 'upper level',
    duedate: '2021-01-03',
    userid: 'dd0016ae-00b6-4fd2-9e96-b3ce45b64b5c',
    description: 'lorem10103310130',
    status: 1
  },
  {
    id: '3',
    queryname: 'Broken Door',
    caseref: 8033,
    createddate: '2020-01-01',
    location: 'lower level',
    description: 'lorem10103310130',
    duedate: '2021-01-03',
    userid: 'dd0016ae-00b6-4fd2-9e96-b3ce45b64b5c',
    status: 0
  }
];

export const mockFacilityManagerDetails = {
  id: '1',
  queryname: 'Broken Lift',
  caseref: 8031,
  createddate: '2020-01-01',
  location: 'upper level',
  duedate: '2021-01-03',
  description: 'lorem10103310130',
  userid: 'dd0016ae-00b6-4fd2-9e96-b3ce45b64b5c',
  status: 0,
  activityFeed: [
    { id: 1, name: 'Query Created', date: '2020-01-01' },
    { id: 2, name: 'pdf uploaded', date: '2019-02-02' },
    { id: 3, name: 'Spoke with John Doe', date: '2019-02-01' }
  ]
};

export const mockTenant = {
  tenantid: 'f1405cfb-a9f8-4912-9be2-0b50a5259b2d',
  baserent: 20000,
  servicecharge: 5000,
  turnoverrent: 10000,
  other: 0,
  totalincome: 35000,
  tenantcosts: -3500,
  rentdiscount: -3000,
  leasingcosts: 0,
  allocatedopex: -15000,
  effortratio: 15,
  salesdensity: 130,
  netprofitability: 13500
};

export const mockAsset = {
  assetid: 'f1405cfb-a9f8-4912-9be2-0b50a5259b2d',
  noi: 1.64,
  baserent: 0.6,
  turnoverrent: 0.8,
  servicecharge: -0.3,
  opexchange: -0.5,
  forecastednoi: 2.24
};

export const mockASSETCashManager = {
  id: '1',
  accounts: [
    { id: 1, name: 'Account 1', value: '20000', payble: 2400, receivable: 240000 },
    { id: 2, name: 'Account 2', value: '33333', payble: 3333, receivable: 131111 }
  ],
  chartData: [{}],
  invoicesAwaitingAproval: [
    {
      id: 1,
      name: '#9884',
      dateReceived: '2020-01-31',
      type: 'Security',
      company: 'Company Name',
      amount: 1000
    },
    {
      id: 2,
      name: '#3432',
      dateReceived: '2121-01-01',
      type: 'Security',
      company: 'Company Name',
      amount: 3000
    },
    {
      id: 3,
      name: '#3322',
      dateReceived: '2021-01-01',
      type: 'Security',
      company: 'Company Name',
      amount: 1000
    },
    {
      id: 4,
      name: '#1313',
      dateReceived: '2020-01-01',
      type: 'Security',
      company: 'Company Name',
      amount: 1000
    }
  ],
  invoicesOwed: [
    {
      id: 1,
      name: '#9884',
      dateSent: '2020-01-31',
      type: 'Security',
      company: 'Company Name',
      amount: 1000,
      remainingAmount: 3000
    },
    {
      id: 2,
      name: '#3432',
      dateSent: '2121-01-01',
      type: 'Security',
      company: 'Company Name',
      amount: 3000,
      remainingAmount: 3000
    },
    {
      id: 3,
      name: '#3322',
      dateSent: '2021-01-01',
      type: 'Security',
      company: 'Company Name',
      amount: 1000,
      remainingAmount: 3000
    },
    {
      id: 4,
      name: '#1313',
      dateSent: '2020-01-01',
      type: 'Security',
      company: 'Company Name',
      amount: 1000,
      remainingAmount: 3000
    }
  ]
};

export const mockHomeData = {
  portfolios: 10,
  assets: 40,
  tenants: 400,
  units: 450,
  occp: 79.812,
  gla: 34913031,
  anp: 373020,
  noi: 3712212
};

export const mockTenantCashManager = {
  id: '1',
  accounts: [
    { id: 1, name: 'Account 1', value: '20000', payble: 2400, receivable: 240000 },
    { id: 2, name: 'Account 2', value: '33333', payble: 3333, receivable: 131111 },
    { id: 3, name: 'Account 3', value: '44444', payble: 4442, receivable: 422122 }
  ],
  chartData: [{}],
  invoicesAwaitingAproval: [
    {
      id: 1,
      name: '#9884',
      dateReceived: '2020-01-31',
      type: 'Security',
      company: 'Company Name',
      amount: 1000
    },
    {
      id: 2,
      name: '#3432',
      dateReceived: '2121-01-01',
      type: 'Security',
      company: 'Company Name',
      amount: 3000
    },
    {
      id: 3,
      name: '#3322',
      dateReceived: '2021-01-01',
      type: 'Security',
      company: 'Company Name',
      amount: 1000
    },
    {
      id: 4,
      name: '#1313',
      dateReceived: '2020-01-01',
      type: 'Security',
      company: 'Company Name',
      amount: 1000
    }
  ],
  invoicesOwed: [
    {
      id: 1,
      name: '#9884',
      dateSent: '2020-01-31',
      type: 'Security',
      company: 'Company Name',
      amount: 1000,
      remainingAmount: 3000
    },
    {
      id: 2,
      name: '#3432',
      dateSent: '2121-01-01',
      type: 'Security',
      company: 'Company Name',
      amount: 3000,
      remainingAmount: 3000
    },
    {
      id: 3,
      name: '#3322',
      dateSent: '2021-01-01',
      type: 'Security',
      company: 'Company Name',
      amount: 1000,
      remainingAmount: 3000
    },
    {
      id: 4,
      name: '#1313',
      dateSent: '2020-01-01',
      type: 'Security',
      company: 'Company Name',
      amount: 1000,
      remainingAmount: 3000
    }
  ]
};

export const mockStackingPlan = {
  buildings: [
    {
      buildingname: 'Tower A',
      stackingplanbyfloorList: [
        {
          floor: 1,
          totalgla: 600,
          stackingplanList: [
            {
              unitid: '1',
              unitname: 'test unit',
              tenantname: 'test',
              enddate: '2022-05-05',
              gla: 100,
              startdate: '2020-05-05',
              tenantid: '1213'
            },
            {
              unitid: '2',
              unitname: 'test unit 2',
              tenantname: '',
              enddate: '',
              gla: 200,
              startdate: '',
              tenantid: ''
            },
            {
              unitid: '3',
              unitname: 'test unit 3',
              tenantname: '',
              enddate: '',
              gla: 300,
              startdate: '',
              tenantid: ''
            }
          ]
        },
        {
          floor: 2,
          totalgla: 600,
          stackingplanList: [
            {
              unitid: '22',
              unitname: 'test unait',
              tenantname: '',
              enddate: '',
              gla: 100,
              startdate: '',
              tenantid: ''
            },
            {
              unitid: '222',
              unitname: 'test unit 2a',
              tenantname: '',
              enddate: '',
              gla: 200,
              startdate: '',
              tenantid: ''
            },
            {
              unitid: '322',
              unitname: 'test uanit 3',
              tenantname: '',
              enddate: '',
              gla: 300,
              startdate: '',
              tenantid: ''
            }
          ]
        }
      ]
    },
    {
      buildingname: 'Tower B',
      stackingplanbyfloorList: [
        {
          floor: 1,
          totalgla: 1500,
          stackingplanList: [
            {
              unitid: '13',
              unitname: 'test uni3t',
              tenantname: '',
              enddate: '',
              gla: 500,
              startdate: '',
              tenantid: ''
            },
            {
              unitid: '23',
              unitname: 'test unit 23',
              tenantname: '',
              enddate: '',
              gla: 800,
              startdate: '',
              tenantid: ''
            },
            {
              unitid: '33',
              unitname: 'test unit 33',
              tenantname: '',
              enddate: '',
              gla: 200,
              startdate: '',
              tenantid: ''
            }
          ]
        }
      ]
    }
  ],
  hasbuildings: true
};
export const mockAlerts = [
  {
    assetid: 'e64cc914-8398-4611-a61b-dd54896beb04',
    assetname: 'Greece 2',
    category: 0,
    date: '2021-06-10',
    entityid: 'e1efdaaf-4629-456b-9365-83445f72830e',
    id: '966c1057-49cc-4b39-8245-3def9e6dc4fb',
    name: 'Tenant lease expires in 6 month',
    portfolioid: '8cf3a29e-ee56-4983-978f-22c05b4b1417',
    portfolioname: 'Greece',
    tenantid: 'e1efdaaf-4629-456b-9365-83445f72830e',
    tenantname: 'Pfizer',
    type: 0
  },
  {
    assetid: 'dbd7d929-8345-4083-a668-6419e449a49d',
    assetname: 'Greece 2',
    category: 1,
    date: '2021-06-10',
    entityid: '2ddff667-a29c-4d06-9899-324f5f9c3e94',
    id: '966c1057-49cc-4b39-8245-3def9e6dc4fb',
    name: 'Facility Manager Ticket',
    portfolioid: '8cf3a29e-ee56-4983-978f-22c05b4b1417',
    portfolioname: 'Greece',
    queryname: 'Fix the broken light',
    datecreated: '2020-06-10',
    datedue: '2022-01-01',
    type: 1,
    assignedto: 'tsavlis.thanos@gmail.com'
  }
];

export const mockOpexManager = [
  {
    id: 1,
    name: 'ba',
    assetid: 'e64cc914-8398-4611-a61b-dd54896beb04',
    organizationid: 'e1efdaaf-4629-456b-9365-83445f72830e',
    supplier: 'Pfizer',
    contractstart: '2020-01-31',
    contractend: '2021-01-31',
    contractvalue: 1000,
    paymentthisyear: 1200
  },
  {
    id: 2,
    name: '#9884',
    assetid: 'e64cc914-8398-4611-a61b-dd54896beb04',
    organizationid: 'e1efdaaf-4629-456b-9365-83445f72830e',
    supplier: 'Moderna',
    contractstart: '2020-01-31',
    contractend: '2021-06-31',
    contractvalue: 150,
    paymentthisyear: 151
  },
  {
    id: 3,
    name: 'dada',
    assetid: 'e64cc914-8398-4611-a61b-dd54896beb04',
    organizationid: 'e1efdaaf-4629-456b-9365-83445f72830e',
    supplier: 'AstraZeneca',
    contractstart: '2020-01-31',
    contractend: '2021-01-31',
    contractvalue: 780,
    paymentthisyear: 485
  }
];
