import React from 'react';

import useRouter from 'hooks/useRouter';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import ContractDetails from 'Workflows/Shared/CostManager/ContractDetails';

const TenantCostContractDetails = ({ id }) => {
  const details = useSelector((state) => state.costManager.details);
  const { query } = useRouter();
  const pathName = `Tenants/${query.id}/TenantCostManager/${query.index}/ContractDetails`;

  return <ContractDetails id={id} pathName={pathName} details={details} type="tenant" />;
};

export default TenantCostContractDetails;

TenantCostContractDetails.propTypes = {
  id: PropTypes.string.isRequired
};
