/* eslint-disable no-restricted-globals */
/* eslint-disable radix */
import { useCallback, useState } from 'react';

import queryString from 'query-string';

import useRouter from './useRouter';

const getParams = () => new URLSearchParams(window.location.search);

const getPageParam = () => {
  const pageParam = parseInt(getParams().get('page'));
  return isNaN(pageParam) ? 1 : pageParam;
};

const getPortfoliosIdsParam = () => getParams().get('portfolioIds')?.split(',') ?? [];

const getAssetIdsParam = () => getParams().get('assetIds')?.split(',') ?? [];

const getAssetTypeParam = () => getParams().get('types')?.split(',').map(Number) ?? [];

const historyPushState = (url) => {
  const path = `${window.location.pathname}?${url.toString()}`;
  window.history.pushState({ path }, '', path);
};

export const queryStringOptions = {
  arrayFormat: 'bracket',
  parseNumbers: true,
  parseBooleans: true
};

function useUrlParams() {
  const { location } = useRouter();
  const [assetType, setAssetType] = useState(getAssetTypeParam);
  const [page, setPage] = useState(getPageParam);
  const [portfolioIds, setPortfolioIds] = useState(getPortfoliosIdsParam);
  const [assetIds, setAssetIds] = useState(getAssetIdsParam);

  const setPageParams = useCallback((value) => {
    setPage(value);
    const searchParams = getParams();
    searchParams.set('page', value);
    historyPushState(searchParams);
  }, []);

  const setAssetTypeParams = useCallback((value) => {
    setAssetType(value);
    const searchParams = getParams();
    if (value.length < 1) {
      searchParams.delete('types');
    } else {
      searchParams.set('types', value);
    }
    historyPushState(searchParams);
  }, []);

  const setAssetIdsParams = useCallback((value) => {
    setAssetIds(value);
    const searchParams = getParams();
    if (value.length < 1) {
      searchParams.delete('assetIds');
    } else {
      searchParams.set('assetIds', value);
    }
    historyPushState(searchParams);
  }, []);

  const setPortfoliosIdsParams = useCallback((value) => {
    setPortfolioIds(value);
    const searchParams = getParams();
    if (value.length < 1) {
      searchParams.delete('portfolioIds');
    } else {
      searchParams.set('portfolioIds', value);
    }
    historyPushState(searchParams);
  }, []);

  /**
   * parses the query params of a URL and returns an object with params in the correct typo
   */
  const urlParams = queryString.parse(location.search, queryStringOptions);

  // Cache those values as long as URL parameters are the same
  return {
    urlParams,
    page,
    setPage: setPageParams,
    portfolioIds,
    setAssetIds: setAssetIdsParams,
    assetIds,
    setPortfolios: setPortfoliosIdsParams,
    assetType,
    setAssetType: setAssetTypeParams
  };
}

export default useUrlParams;
