/* eslint-disable no-console */
import React, { useEffect, useState } from 'react';

import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Spinner from 'components/Spinner';
import useIsAdmin from 'helpers/isAdmin';
import useAuth from 'hooks/useAuth';
import UserHasAccess from 'hooks/UserHasAccess';
import Layout from 'layout';
import { useSelector, useDispatch } from 'react-redux';
import { Redirect, Route } from 'react-router-dom';
import { toast } from 'react-toastify';

import ModalManager from './components/Modal/ModalManager';
import Navbar from './components/Navbar';
import * as actions from './store/actions';
import LoginScreen from './views/Auth/LoginScreen';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.grey,
    minHeight: '100vh',
    display: 'flex',
    flexDirection: 'column'
  },
  container: {
    height: '100%'
  }
}));

const App = () => {
  const { auth } = useAuth();
  const classes = useStyles();
  const userUid = useSelector((state) => state.user.auth.uid);
  const userLoaded = useSelector((state) => state.user.loaded);
  const [userHasLoaded, setUserHasLoaded] = useState(false);
  const error = useSelector((state) => state.user.error);
  const userPermissionsLoaded = useSelector((state) => state.user.userRolesLoaded);
  const userPermissions = useSelector((state) => state.user.userPermissions.permissionsList);
  const ReadPortfoliosPermissions = UserHasAccess('portfolio', 'view', userPermissions);
  const authIsEmpty = useSelector((state) => state.user.auth.authIsEmpty);
  const dispatch = useDispatch();
  const userAuthLoaded = useSelector((state) => state.user.auth.loaded);
  const isAdmin = useIsAdmin();

  useEffect(() => {
    if (userLoaded || authIsEmpty) return;
    dispatch(actions.getUser(userUid, auth.accessToken))
      .then((res) => {
        dispatch(actions.getUserRoles(res.roleid));
      })
      .catch(() => {
        dispatch(actions.RegisterUser(userUid)).catch((err) => {
          toast.error(err);
        });
      })
      .finally(() => {
        setUserHasLoaded(true);
      });
  }, [authIsEmpty]);

  useEffect(() => {
    if (!userPermissionsLoaded || authIsEmpty || isAdmin) {
      return;
    }

    if (ReadPortfoliosPermissions) {
      dispatch(actions.getPortfolios());
    }
  }, [userPermissionsLoaded, authIsEmpty]);

  if (!userAuthLoaded && !userHasLoaded) {
    return <Spinner />;
  }

  if (!userUid && userAuthLoaded) {
    return (
      <Box className={classes.root}>
        <Box height="100%">
          <Route exact name="LoginScreen" path="/login" component={LoginScreen} />
          <Redirect from="*" to="/login" />
        </Box>
      </Box>
    );
  }

  if (
    userPermissionsLoaded &&
    error &&
    userLoaded &&
    userHasLoaded &&
    !error.includes('the user has already')
  ) {
    return (
      <Box className={classes.root}>
        <Navbar />

        <Box ml={3} mt={3}>
          <Typography color="error">{error}</Typography>
        </Box>
      </Box>
    );
  }

  return (
    <Box className={classes.root}>
      <ModalManager />
      {userHasLoaded && <Layout />}
    </Box>
  );
};

export default App;
