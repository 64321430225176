// source: external/filters.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

goog.exportSymbol('proto.external.BetweenCondition', null, global);
goog.exportSymbol('proto.external.ConditionValue', null, global);
goog.exportSymbol('proto.external.ConditionValue.ValueCase', null, global);
goog.exportSymbol('proto.external.EqualCondition', null, global);
goog.exportSymbol('proto.external.Filter', null, global);
goog.exportSymbol('proto.external.Filter.ConditionCase', null, global);
goog.exportSymbol('proto.external.InCondition', null, global);
goog.exportSymbol('proto.external.LikeCondition', null, global);
goog.exportSymbol('proto.external.NotEqualCondition', null, global);
goog.exportSymbol('proto.external.NotInCondition', null, global);
goog.exportSymbol('proto.external.NumericConditionValue', null, global);
goog.exportSymbol('proto.external.NumericConditionValue.ValueCase', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.external.Filter = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.external.Filter.oneofGroups_);
};
goog.inherits(proto.external.Filter, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.external.Filter.displayName = 'proto.external.Filter';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.external.EqualCondition = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.external.EqualCondition, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.external.EqualCondition.displayName = 'proto.external.EqualCondition';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.external.NotEqualCondition = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.external.NotEqualCondition, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.external.NotEqualCondition.displayName = 'proto.external.NotEqualCondition';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.external.InCondition = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.external.InCondition.repeatedFields_, null);
};
goog.inherits(proto.external.InCondition, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.external.InCondition.displayName = 'proto.external.InCondition';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.external.NotInCondition = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.external.NotInCondition.repeatedFields_, null);
};
goog.inherits(proto.external.NotInCondition, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.external.NotInCondition.displayName = 'proto.external.NotInCondition';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.external.BetweenCondition = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.external.BetweenCondition, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.external.BetweenCondition.displayName = 'proto.external.BetweenCondition';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.external.LikeCondition = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.external.LikeCondition, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.external.LikeCondition.displayName = 'proto.external.LikeCondition';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.external.ConditionValue = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.external.ConditionValue.oneofGroups_);
};
goog.inherits(proto.external.ConditionValue, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.external.ConditionValue.displayName = 'proto.external.ConditionValue';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.external.NumericConditionValue = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.external.NumericConditionValue.oneofGroups_);
};
goog.inherits(proto.external.NumericConditionValue, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.external.NumericConditionValue.displayName = 'proto.external.NumericConditionValue';
}

/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.external.Filter.oneofGroups_ = [[2,3,4,5,6,7]];

/**
 * @enum {number}
 */
proto.external.Filter.ConditionCase = {
  CONDITION_NOT_SET: 0,
  EQUAL: 2,
  NOTEQUAL: 3,
  IN: 4,
  NOTIN: 5,
  BETWEEN: 6,
  LIKE: 7
};

/**
 * @return {proto.external.Filter.ConditionCase}
 */
proto.external.Filter.prototype.getConditionCase = function() {
  return /** @type {proto.external.Filter.ConditionCase} */(jspb.Message.computeOneofCase(this, proto.external.Filter.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.external.Filter.prototype.toObject = function(opt_includeInstance) {
  return proto.external.Filter.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.external.Filter} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.external.Filter.toObject = function(includeInstance, msg) {
  var f, obj = {
    field: jspb.Message.getFieldWithDefault(msg, 1, ""),
    equal: (f = msg.getEqual()) && proto.external.EqualCondition.toObject(includeInstance, f),
    notequal: (f = msg.getNotequal()) && proto.external.NotEqualCondition.toObject(includeInstance, f),
    pb_in: (f = msg.getIn()) && proto.external.InCondition.toObject(includeInstance, f),
    notin: (f = msg.getNotin()) && proto.external.NotInCondition.toObject(includeInstance, f),
    between: (f = msg.getBetween()) && proto.external.BetweenCondition.toObject(includeInstance, f),
    like: (f = msg.getLike()) && proto.external.LikeCondition.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.external.Filter}
 */
proto.external.Filter.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.external.Filter;
  return proto.external.Filter.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.external.Filter} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.external.Filter}
 */
proto.external.Filter.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setField(value);
      break;
    case 2:
      var value = new proto.external.EqualCondition;
      reader.readMessage(value,proto.external.EqualCondition.deserializeBinaryFromReader);
      msg.setEqual(value);
      break;
    case 3:
      var value = new proto.external.NotEqualCondition;
      reader.readMessage(value,proto.external.NotEqualCondition.deserializeBinaryFromReader);
      msg.setNotequal(value);
      break;
    case 4:
      var value = new proto.external.InCondition;
      reader.readMessage(value,proto.external.InCondition.deserializeBinaryFromReader);
      msg.setIn(value);
      break;
    case 5:
      var value = new proto.external.NotInCondition;
      reader.readMessage(value,proto.external.NotInCondition.deserializeBinaryFromReader);
      msg.setNotin(value);
      break;
    case 6:
      var value = new proto.external.BetweenCondition;
      reader.readMessage(value,proto.external.BetweenCondition.deserializeBinaryFromReader);
      msg.setBetween(value);
      break;
    case 7:
      var value = new proto.external.LikeCondition;
      reader.readMessage(value,proto.external.LikeCondition.deserializeBinaryFromReader);
      msg.setLike(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.external.Filter.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.external.Filter.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.external.Filter} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.external.Filter.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getField();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getEqual();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.external.EqualCondition.serializeBinaryToWriter
    );
  }
  f = message.getNotequal();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.external.NotEqualCondition.serializeBinaryToWriter
    );
  }
  f = message.getIn();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.external.InCondition.serializeBinaryToWriter
    );
  }
  f = message.getNotin();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.external.NotInCondition.serializeBinaryToWriter
    );
  }
  f = message.getBetween();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      proto.external.BetweenCondition.serializeBinaryToWriter
    );
  }
  f = message.getLike();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      proto.external.LikeCondition.serializeBinaryToWriter
    );
  }
};


/**
 * optional string field = 1;
 * @return {string}
 */
proto.external.Filter.prototype.getField = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.external.Filter} returns this
 */
proto.external.Filter.prototype.setField = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional EqualCondition equal = 2;
 * @return {?proto.external.EqualCondition}
 */
proto.external.Filter.prototype.getEqual = function() {
  return /** @type{?proto.external.EqualCondition} */ (
    jspb.Message.getWrapperField(this, proto.external.EqualCondition, 2));
};


/**
 * @param {?proto.external.EqualCondition|undefined} value
 * @return {!proto.external.Filter} returns this
*/
proto.external.Filter.prototype.setEqual = function(value) {
  return jspb.Message.setOneofWrapperField(this, 2, proto.external.Filter.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.external.Filter} returns this
 */
proto.external.Filter.prototype.clearEqual = function() {
  return this.setEqual(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.external.Filter.prototype.hasEqual = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional NotEqualCondition notEqual = 3;
 * @return {?proto.external.NotEqualCondition}
 */
proto.external.Filter.prototype.getNotequal = function() {
  return /** @type{?proto.external.NotEqualCondition} */ (
    jspb.Message.getWrapperField(this, proto.external.NotEqualCondition, 3));
};


/**
 * @param {?proto.external.NotEqualCondition|undefined} value
 * @return {!proto.external.Filter} returns this
*/
proto.external.Filter.prototype.setNotequal = function(value) {
  return jspb.Message.setOneofWrapperField(this, 3, proto.external.Filter.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.external.Filter} returns this
 */
proto.external.Filter.prototype.clearNotequal = function() {
  return this.setNotequal(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.external.Filter.prototype.hasNotequal = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional InCondition in = 4;
 * @return {?proto.external.InCondition}
 */
proto.external.Filter.prototype.getIn = function() {
  return /** @type{?proto.external.InCondition} */ (
    jspb.Message.getWrapperField(this, proto.external.InCondition, 4));
};


/**
 * @param {?proto.external.InCondition|undefined} value
 * @return {!proto.external.Filter} returns this
*/
proto.external.Filter.prototype.setIn = function(value) {
  return jspb.Message.setOneofWrapperField(this, 4, proto.external.Filter.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.external.Filter} returns this
 */
proto.external.Filter.prototype.clearIn = function() {
  return this.setIn(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.external.Filter.prototype.hasIn = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional NotInCondition notIn = 5;
 * @return {?proto.external.NotInCondition}
 */
proto.external.Filter.prototype.getNotin = function() {
  return /** @type{?proto.external.NotInCondition} */ (
    jspb.Message.getWrapperField(this, proto.external.NotInCondition, 5));
};


/**
 * @param {?proto.external.NotInCondition|undefined} value
 * @return {!proto.external.Filter} returns this
*/
proto.external.Filter.prototype.setNotin = function(value) {
  return jspb.Message.setOneofWrapperField(this, 5, proto.external.Filter.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.external.Filter} returns this
 */
proto.external.Filter.prototype.clearNotin = function() {
  return this.setNotin(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.external.Filter.prototype.hasNotin = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional BetweenCondition between = 6;
 * @return {?proto.external.BetweenCondition}
 */
proto.external.Filter.prototype.getBetween = function() {
  return /** @type{?proto.external.BetweenCondition} */ (
    jspb.Message.getWrapperField(this, proto.external.BetweenCondition, 6));
};


/**
 * @param {?proto.external.BetweenCondition|undefined} value
 * @return {!proto.external.Filter} returns this
*/
proto.external.Filter.prototype.setBetween = function(value) {
  return jspb.Message.setOneofWrapperField(this, 6, proto.external.Filter.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.external.Filter} returns this
 */
proto.external.Filter.prototype.clearBetween = function() {
  return this.setBetween(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.external.Filter.prototype.hasBetween = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional LikeCondition like = 7;
 * @return {?proto.external.LikeCondition}
 */
proto.external.Filter.prototype.getLike = function() {
  return /** @type{?proto.external.LikeCondition} */ (
    jspb.Message.getWrapperField(this, proto.external.LikeCondition, 7));
};


/**
 * @param {?proto.external.LikeCondition|undefined} value
 * @return {!proto.external.Filter} returns this
*/
proto.external.Filter.prototype.setLike = function(value) {
  return jspb.Message.setOneofWrapperField(this, 7, proto.external.Filter.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.external.Filter} returns this
 */
proto.external.Filter.prototype.clearLike = function() {
  return this.setLike(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.external.Filter.prototype.hasLike = function() {
  return jspb.Message.getField(this, 7) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.external.EqualCondition.prototype.toObject = function(opt_includeInstance) {
  return proto.external.EqualCondition.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.external.EqualCondition} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.external.EqualCondition.toObject = function(includeInstance, msg) {
  var f, obj = {
    value: (f = msg.getValue()) && proto.external.ConditionValue.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.external.EqualCondition}
 */
proto.external.EqualCondition.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.external.EqualCondition;
  return proto.external.EqualCondition.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.external.EqualCondition} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.external.EqualCondition}
 */
proto.external.EqualCondition.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.external.ConditionValue;
      reader.readMessage(value,proto.external.ConditionValue.deserializeBinaryFromReader);
      msg.setValue(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.external.EqualCondition.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.external.EqualCondition.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.external.EqualCondition} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.external.EqualCondition.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getValue();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.external.ConditionValue.serializeBinaryToWriter
    );
  }
};


/**
 * optional ConditionValue value = 1;
 * @return {?proto.external.ConditionValue}
 */
proto.external.EqualCondition.prototype.getValue = function() {
  return /** @type{?proto.external.ConditionValue} */ (
    jspb.Message.getWrapperField(this, proto.external.ConditionValue, 1));
};


/**
 * @param {?proto.external.ConditionValue|undefined} value
 * @return {!proto.external.EqualCondition} returns this
*/
proto.external.EqualCondition.prototype.setValue = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.external.EqualCondition} returns this
 */
proto.external.EqualCondition.prototype.clearValue = function() {
  return this.setValue(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.external.EqualCondition.prototype.hasValue = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.external.NotEqualCondition.prototype.toObject = function(opt_includeInstance) {
  return proto.external.NotEqualCondition.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.external.NotEqualCondition} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.external.NotEqualCondition.toObject = function(includeInstance, msg) {
  var f, obj = {
    value: (f = msg.getValue()) && proto.external.ConditionValue.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.external.NotEqualCondition}
 */
proto.external.NotEqualCondition.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.external.NotEqualCondition;
  return proto.external.NotEqualCondition.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.external.NotEqualCondition} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.external.NotEqualCondition}
 */
proto.external.NotEqualCondition.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.external.ConditionValue;
      reader.readMessage(value,proto.external.ConditionValue.deserializeBinaryFromReader);
      msg.setValue(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.external.NotEqualCondition.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.external.NotEqualCondition.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.external.NotEqualCondition} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.external.NotEqualCondition.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getValue();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.external.ConditionValue.serializeBinaryToWriter
    );
  }
};


/**
 * optional ConditionValue value = 1;
 * @return {?proto.external.ConditionValue}
 */
proto.external.NotEqualCondition.prototype.getValue = function() {
  return /** @type{?proto.external.ConditionValue} */ (
    jspb.Message.getWrapperField(this, proto.external.ConditionValue, 1));
};


/**
 * @param {?proto.external.ConditionValue|undefined} value
 * @return {!proto.external.NotEqualCondition} returns this
*/
proto.external.NotEqualCondition.prototype.setValue = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.external.NotEqualCondition} returns this
 */
proto.external.NotEqualCondition.prototype.clearValue = function() {
  return this.setValue(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.external.NotEqualCondition.prototype.hasValue = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.external.InCondition.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.external.InCondition.prototype.toObject = function(opt_includeInstance) {
  return proto.external.InCondition.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.external.InCondition} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.external.InCondition.toObject = function(includeInstance, msg) {
  var f, obj = {
    valuesList: jspb.Message.toObjectList(msg.getValuesList(),
    proto.external.ConditionValue.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.external.InCondition}
 */
proto.external.InCondition.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.external.InCondition;
  return proto.external.InCondition.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.external.InCondition} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.external.InCondition}
 */
proto.external.InCondition.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.external.ConditionValue;
      reader.readMessage(value,proto.external.ConditionValue.deserializeBinaryFromReader);
      msg.addValues(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.external.InCondition.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.external.InCondition.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.external.InCondition} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.external.InCondition.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getValuesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.external.ConditionValue.serializeBinaryToWriter
    );
  }
};


/**
 * repeated ConditionValue values = 1;
 * @return {!Array<!proto.external.ConditionValue>}
 */
proto.external.InCondition.prototype.getValuesList = function() {
  return /** @type{!Array<!proto.external.ConditionValue>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.external.ConditionValue, 1));
};


/**
 * @param {!Array<!proto.external.ConditionValue>} value
 * @return {!proto.external.InCondition} returns this
*/
proto.external.InCondition.prototype.setValuesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.external.ConditionValue=} opt_value
 * @param {number=} opt_index
 * @return {!proto.external.ConditionValue}
 */
proto.external.InCondition.prototype.addValues = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.external.ConditionValue, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.external.InCondition} returns this
 */
proto.external.InCondition.prototype.clearValuesList = function() {
  return this.setValuesList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.external.NotInCondition.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.external.NotInCondition.prototype.toObject = function(opt_includeInstance) {
  return proto.external.NotInCondition.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.external.NotInCondition} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.external.NotInCondition.toObject = function(includeInstance, msg) {
  var f, obj = {
    valuesList: jspb.Message.toObjectList(msg.getValuesList(),
    proto.external.ConditionValue.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.external.NotInCondition}
 */
proto.external.NotInCondition.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.external.NotInCondition;
  return proto.external.NotInCondition.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.external.NotInCondition} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.external.NotInCondition}
 */
proto.external.NotInCondition.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.external.ConditionValue;
      reader.readMessage(value,proto.external.ConditionValue.deserializeBinaryFromReader);
      msg.addValues(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.external.NotInCondition.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.external.NotInCondition.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.external.NotInCondition} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.external.NotInCondition.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getValuesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.external.ConditionValue.serializeBinaryToWriter
    );
  }
};


/**
 * repeated ConditionValue values = 1;
 * @return {!Array<!proto.external.ConditionValue>}
 */
proto.external.NotInCondition.prototype.getValuesList = function() {
  return /** @type{!Array<!proto.external.ConditionValue>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.external.ConditionValue, 1));
};


/**
 * @param {!Array<!proto.external.ConditionValue>} value
 * @return {!proto.external.NotInCondition} returns this
*/
proto.external.NotInCondition.prototype.setValuesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.external.ConditionValue=} opt_value
 * @param {number=} opt_index
 * @return {!proto.external.ConditionValue}
 */
proto.external.NotInCondition.prototype.addValues = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.external.ConditionValue, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.external.NotInCondition} returns this
 */
proto.external.NotInCondition.prototype.clearValuesList = function() {
  return this.setValuesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.external.BetweenCondition.prototype.toObject = function(opt_includeInstance) {
  return proto.external.BetweenCondition.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.external.BetweenCondition} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.external.BetweenCondition.toObject = function(includeInstance, msg) {
  var f, obj = {
    from: (f = msg.getFrom()) && proto.external.NumericConditionValue.toObject(includeInstance, f),
    to: (f = msg.getTo()) && proto.external.NumericConditionValue.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.external.BetweenCondition}
 */
proto.external.BetweenCondition.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.external.BetweenCondition;
  return proto.external.BetweenCondition.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.external.BetweenCondition} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.external.BetweenCondition}
 */
proto.external.BetweenCondition.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.external.NumericConditionValue;
      reader.readMessage(value,proto.external.NumericConditionValue.deserializeBinaryFromReader);
      msg.setFrom(value);
      break;
    case 2:
      var value = new proto.external.NumericConditionValue;
      reader.readMessage(value,proto.external.NumericConditionValue.deserializeBinaryFromReader);
      msg.setTo(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.external.BetweenCondition.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.external.BetweenCondition.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.external.BetweenCondition} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.external.BetweenCondition.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFrom();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.external.NumericConditionValue.serializeBinaryToWriter
    );
  }
  f = message.getTo();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.external.NumericConditionValue.serializeBinaryToWriter
    );
  }
};


/**
 * optional NumericConditionValue from = 1;
 * @return {?proto.external.NumericConditionValue}
 */
proto.external.BetweenCondition.prototype.getFrom = function() {
  return /** @type{?proto.external.NumericConditionValue} */ (
    jspb.Message.getWrapperField(this, proto.external.NumericConditionValue, 1));
};


/**
 * @param {?proto.external.NumericConditionValue|undefined} value
 * @return {!proto.external.BetweenCondition} returns this
*/
proto.external.BetweenCondition.prototype.setFrom = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.external.BetweenCondition} returns this
 */
proto.external.BetweenCondition.prototype.clearFrom = function() {
  return this.setFrom(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.external.BetweenCondition.prototype.hasFrom = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional NumericConditionValue to = 2;
 * @return {?proto.external.NumericConditionValue}
 */
proto.external.BetweenCondition.prototype.getTo = function() {
  return /** @type{?proto.external.NumericConditionValue} */ (
    jspb.Message.getWrapperField(this, proto.external.NumericConditionValue, 2));
};


/**
 * @param {?proto.external.NumericConditionValue|undefined} value
 * @return {!proto.external.BetweenCondition} returns this
*/
proto.external.BetweenCondition.prototype.setTo = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.external.BetweenCondition} returns this
 */
proto.external.BetweenCondition.prototype.clearTo = function() {
  return this.setTo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.external.BetweenCondition.prototype.hasTo = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.external.LikeCondition.prototype.toObject = function(opt_includeInstance) {
  return proto.external.LikeCondition.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.external.LikeCondition} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.external.LikeCondition.toObject = function(includeInstance, msg) {
  var f, obj = {
    value: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.external.LikeCondition}
 */
proto.external.LikeCondition.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.external.LikeCondition;
  return proto.external.LikeCondition.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.external.LikeCondition} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.external.LikeCondition}
 */
proto.external.LikeCondition.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setValue(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.external.LikeCondition.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.external.LikeCondition.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.external.LikeCondition} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.external.LikeCondition.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getValue();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string value = 1;
 * @return {string}
 */
proto.external.LikeCondition.prototype.getValue = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.external.LikeCondition} returns this
 */
proto.external.LikeCondition.prototype.setValue = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.external.ConditionValue.oneofGroups_ = [[1,2,3,4]];

/**
 * @enum {number}
 */
proto.external.ConditionValue.ValueCase = {
  VALUE_NOT_SET: 0,
  INT64VALUE: 1,
  FLOAT64VALUE: 2,
  STRINGVALUE: 3,
  BOOLVALUE: 4
};

/**
 * @return {proto.external.ConditionValue.ValueCase}
 */
proto.external.ConditionValue.prototype.getValueCase = function() {
  return /** @type {proto.external.ConditionValue.ValueCase} */(jspb.Message.computeOneofCase(this, proto.external.ConditionValue.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.external.ConditionValue.prototype.toObject = function(opt_includeInstance) {
  return proto.external.ConditionValue.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.external.ConditionValue} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.external.ConditionValue.toObject = function(includeInstance, msg) {
  var f, obj = {
    int64value: jspb.Message.getFieldWithDefault(msg, 1, 0),
    float64value: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0),
    stringvalue: jspb.Message.getFieldWithDefault(msg, 3, ""),
    boolvalue: jspb.Message.getBooleanFieldWithDefault(msg, 4, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.external.ConditionValue}
 */
proto.external.ConditionValue.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.external.ConditionValue;
  return proto.external.ConditionValue.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.external.ConditionValue} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.external.ConditionValue}
 */
proto.external.ConditionValue.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setInt64value(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setFloat64value(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setStringvalue(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setBoolvalue(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.external.ConditionValue.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.external.ConditionValue.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.external.ConditionValue} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.external.ConditionValue.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {number} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeDouble(
      2,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 3));
  if (f != null) {
    writer.writeString(
      3,
      f
    );
  }
  f = /** @type {boolean} */ (jspb.Message.getField(message, 4));
  if (f != null) {
    writer.writeBool(
      4,
      f
    );
  }
};


/**
 * optional int64 int64Value = 1;
 * @return {number}
 */
proto.external.ConditionValue.prototype.getInt64value = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.external.ConditionValue} returns this
 */
proto.external.ConditionValue.prototype.setInt64value = function(value) {
  return jspb.Message.setOneofField(this, 1, proto.external.ConditionValue.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.external.ConditionValue} returns this
 */
proto.external.ConditionValue.prototype.clearInt64value = function() {
  return jspb.Message.setOneofField(this, 1, proto.external.ConditionValue.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.external.ConditionValue.prototype.hasInt64value = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional double float64Value = 2;
 * @return {number}
 */
proto.external.ConditionValue.prototype.getFloat64value = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.external.ConditionValue} returns this
 */
proto.external.ConditionValue.prototype.setFloat64value = function(value) {
  return jspb.Message.setOneofField(this, 2, proto.external.ConditionValue.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.external.ConditionValue} returns this
 */
proto.external.ConditionValue.prototype.clearFloat64value = function() {
  return jspb.Message.setOneofField(this, 2, proto.external.ConditionValue.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.external.ConditionValue.prototype.hasFloat64value = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional string stringValue = 3;
 * @return {string}
 */
proto.external.ConditionValue.prototype.getStringvalue = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.external.ConditionValue} returns this
 */
proto.external.ConditionValue.prototype.setStringvalue = function(value) {
  return jspb.Message.setOneofField(this, 3, proto.external.ConditionValue.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.external.ConditionValue} returns this
 */
proto.external.ConditionValue.prototype.clearStringvalue = function() {
  return jspb.Message.setOneofField(this, 3, proto.external.ConditionValue.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.external.ConditionValue.prototype.hasStringvalue = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional bool boolValue = 4;
 * @return {boolean}
 */
proto.external.ConditionValue.prototype.getBoolvalue = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.external.ConditionValue} returns this
 */
proto.external.ConditionValue.prototype.setBoolvalue = function(value) {
  return jspb.Message.setOneofField(this, 4, proto.external.ConditionValue.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.external.ConditionValue} returns this
 */
proto.external.ConditionValue.prototype.clearBoolvalue = function() {
  return jspb.Message.setOneofField(this, 4, proto.external.ConditionValue.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.external.ConditionValue.prototype.hasBoolvalue = function() {
  return jspb.Message.getField(this, 4) != null;
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.external.NumericConditionValue.oneofGroups_ = [[1,2]];

/**
 * @enum {number}
 */
proto.external.NumericConditionValue.ValueCase = {
  VALUE_NOT_SET: 0,
  INT64VALUE: 1,
  FLOAT64VALUE: 2
};

/**
 * @return {proto.external.NumericConditionValue.ValueCase}
 */
proto.external.NumericConditionValue.prototype.getValueCase = function() {
  return /** @type {proto.external.NumericConditionValue.ValueCase} */(jspb.Message.computeOneofCase(this, proto.external.NumericConditionValue.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.external.NumericConditionValue.prototype.toObject = function(opt_includeInstance) {
  return proto.external.NumericConditionValue.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.external.NumericConditionValue} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.external.NumericConditionValue.toObject = function(includeInstance, msg) {
  var f, obj = {
    int64value: jspb.Message.getFieldWithDefault(msg, 1, 0),
    float64value: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.external.NumericConditionValue}
 */
proto.external.NumericConditionValue.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.external.NumericConditionValue;
  return proto.external.NumericConditionValue.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.external.NumericConditionValue} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.external.NumericConditionValue}
 */
proto.external.NumericConditionValue.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setInt64value(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setFloat64value(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.external.NumericConditionValue.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.external.NumericConditionValue.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.external.NumericConditionValue} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.external.NumericConditionValue.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {number} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeDouble(
      2,
      f
    );
  }
};


/**
 * optional int64 int64Value = 1;
 * @return {number}
 */
proto.external.NumericConditionValue.prototype.getInt64value = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.external.NumericConditionValue} returns this
 */
proto.external.NumericConditionValue.prototype.setInt64value = function(value) {
  return jspb.Message.setOneofField(this, 1, proto.external.NumericConditionValue.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.external.NumericConditionValue} returns this
 */
proto.external.NumericConditionValue.prototype.clearInt64value = function() {
  return jspb.Message.setOneofField(this, 1, proto.external.NumericConditionValue.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.external.NumericConditionValue.prototype.hasInt64value = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional double float64Value = 2;
 * @return {number}
 */
proto.external.NumericConditionValue.prototype.getFloat64value = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.external.NumericConditionValue} returns this
 */
proto.external.NumericConditionValue.prototype.setFloat64value = function(value) {
  return jspb.Message.setOneofField(this, 2, proto.external.NumericConditionValue.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.external.NumericConditionValue} returns this
 */
proto.external.NumericConditionValue.prototype.clearFloat64value = function() {
  return jspb.Message.setOneofField(this, 2, proto.external.NumericConditionValue.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.external.NumericConditionValue.prototype.hasFloat64value = function() {
  return jspb.Message.getField(this, 2) != null;
};


goog.object.extend(exports, proto.external);
