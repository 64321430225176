/* eslint-disable no-unused-vars */
import 'date-fns';
import React from 'react';

import { Box, makeStyles, InputAdornment } from '@material-ui/core';
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import { DatePicker } from '@material-ui/pickers';
import { getDate } from 'helpers/utils';
import PropTypes from 'prop-types';

const useStyles = makeStyles((theme) => ({
  root: {
    width: 400,
    [theme.breakpoints.down('sm')]: {
      width: 'auto'
    },
    '& .MuiFormControl-root .MuiOutlinedInput-input': {
      fontFamily: 'Open Sans !important',
      color: '#5C5C5C'
    }
  },
  datePker: {
    [theme.breakpoints.up('md')]: {
      marginBottom: 0
    }
  }
}));
const DatePickerComponent = ({
  selectedDate,
  setSelectedDate,
  label,
  minDate,
  maxDate,
  maxErrorText,
  minErrorText
}) => {
  const classes = useStyles();

  const dates = getDate(selectedDate);
  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  return (
    <Box className={classes.root}>
      <DatePicker
        variant="inline"
        minDate={minDate}
        maxDate={maxDate}
        format="dd MMM yyyy"
        margin="normal"
        inputVariant="outlined"
        label={label}
        value={dates}
        onChange={handleDateChange}
        autoOk
        fullWidth
        style={{ marginTop: 0 }}
        className={classes.datePker}
        maxDateMessage={maxErrorText}
        minDateMessage={minErrorText}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <CalendarTodayIcon style={{ color: '#9D9D9D' }} />
            </InputAdornment>
          )
        }}
      />
    </Box>
  );
};

export default DatePickerComponent;

DatePickerComponent.defaultProps = {
  minDate: new Date('1989-02-02'),
  maxDate: new Date('2050-01-02'),
  selectedDate: new Date(),
  maxErrorText: 'Date Cannot be after end date',
  minErrorText: 'Date Cannot be before start date'
};

DatePickerComponent.propTypes = {
  selectedDate: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.string]),
  setSelectedDate: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
  minDate: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.string]),
  maxDate: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.string]),
  maxErrorText: PropTypes.string,
  minErrorText: PropTypes.string
};
