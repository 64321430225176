import React, { useState } from 'react';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import PaginationActions from 'components/PaginationActions';
import { numberWithCommas, truncateString } from 'helpers/utils';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';

import useStyles from './styles';

const getUrl = (managerID = '', name, assetId, tab) => {
  const urls = {
    BaseRent: `/Tenants/${managerID}/Details`,
    TurnoverRent: `/Tenants/${managerID}/Details`,
    ServiceCharge: `/Tenants/${managerID}/Details`,
    CumulativeRent: `/Tenants/${managerID}/Details`,
    OtherIncome: `/Tenants/${managerID}/Details`,
    OtherExpenses: `/Tenants/${managerID}/Details`,
    NOI: `/Tenants/${managerID}/Details`,
    Opex: `/Asset/${assetId}/OpexManager/${managerID}`,
    Capex: `/Asset/${assetId}/CostManager/${managerID}`,
    Actuals: `/Asset/${assetId}/Actuals`
  };

  return name === 'forecastsList' ? urls[tab] : urls.Actuals;
};

const TableActualsForecasts = ({ data, name, tab, assetId }) => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const classes = useStyles();

  const handleChangePage = (_, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const emptyRows = rowsPerPage - Math.min(rowsPerPage, data?.length - page * rowsPerPage);

  return (
    <>
      <Table>
        <TableHeader />
        {!data?.length ? (
          <TableBody>
            <TableRow style={{ height: (53 * emptyRows) / 2 }} key={uuidv4()}>
              <TableCell colSpan="14" style={{ border: 'none' }} className={classes.cell}>
                <Typography variant="subtitle1" style={{ textAlign: 'center' }}>
                  No records to display.
                </Typography>
              </TableCell>
            </TableRow>
          </TableBody>
        ) : (
          <TableBody>
            <TableRow key={uuidv4()} />
            {data?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((item) => (
              <TableRow key={uuidv4()}>
                <TableCell style={{ minWidth: 180 }} title={item.name}>
                  <Link to={getUrl(item.managerid, name, assetId, tab)} className={classes.link}>
                    {truncateString(item.name, 15)}
                  </Link>
                </TableCell>
                <TableCell className={classes.cell}>
                  {`€${numberWithCommas(item.totals)}`}
                </TableCell>
                {item[name]?.map((el) => (
                  <TableCell key={uuidv4()} className={classes.cell}>
                    {`€${numberWithCommas(el)}`}
                  </TableCell>
                ))}
              </TableRow>
            ))}
            {emptyRows > 0 && (
              <TableRow style={{ height: 53 * emptyRows }} key={uuidv4()}>
                <TableCell colSpan={14} style={{ border: 'none' }} />
              </TableRow>
            )}
          </TableBody>
        )}
      </Table>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25, 50]}
        component="div"
        count={data?.length || 0}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        ActionsComponent={PaginationActions}
      />
    </>
  );
};

export default TableActualsForecasts;

TableActualsForecasts.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object),
  name: PropTypes.string.isRequired,
  tab: PropTypes.string.isRequired,
  assetId: PropTypes.string.isRequired
};

TableActualsForecasts.defaultProps = {
  data: []
};

const TableHeader = () => (
  <TableHead>
    <TableRow>
      <TableCell>Tenant</TableCell>
      <TableCell>Total</TableCell>
      <TableCell>Jan</TableCell>
      <TableCell>Feb</TableCell>
      <TableCell>Mar</TableCell>
      <TableCell>Apr</TableCell>
      <TableCell>May</TableCell>
      <TableCell>Jun</TableCell>
      <TableCell>Jul</TableCell>
      <TableCell>Aug</TableCell>
      <TableCell>Sep</TableCell>
      <TableCell>Oct</TableCell>
      <TableCell>Nov</TableCell>
      <TableCell>Dec</TableCell>
    </TableRow>
  </TableHead>
);
