/**
 * @fileoverview gRPC-Web generated client stub for external
 * @enhanceable
 * @public
 */

// GENERATED CODE -- DO NOT EDIT!


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');


var external_filters_gorm_pb = require('./filters_gorm_pb.js')

var google_protobuf_empty_pb = require('google-protobuf/google/protobuf/empty_pb.js')

var google_protobuf_wrappers_pb = require('google-protobuf/google/protobuf/wrappers_pb.js')
const proto = {};
proto.external = require('./actual_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.external.ActualServiceClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options['format'] = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.external.ActualServicePromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options['format'] = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.external.ListActualsRequest,
 *   !proto.external.Actuals>}
 */
const methodDescriptor_ActualService_ListActuals = new grpc.web.MethodDescriptor(
  '/external.ActualService/ListActuals',
  grpc.web.MethodType.UNARY,
  proto.external.ListActualsRequest,
  proto.external.Actuals,
  /**
   * @param {!proto.external.ListActualsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.external.Actuals.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.external.ListActualsRequest,
 *   !proto.external.Actuals>}
 */
const methodInfo_ActualService_ListActuals = new grpc.web.AbstractClientBase.MethodInfo(
  proto.external.Actuals,
  /**
   * @param {!proto.external.ListActualsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.external.Actuals.deserializeBinary
);


/**
 * @param {!proto.external.ListActualsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.external.Actuals)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.external.Actuals>|undefined}
 *     The XHR Node Readable Stream
 */
proto.external.ActualServiceClient.prototype.listActuals =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/external.ActualService/ListActuals',
      request,
      metadata || {},
      methodDescriptor_ActualService_ListActuals,
      callback);
};


/**
 * @param {!proto.external.ListActualsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.external.Actuals>}
 *     Promise that resolves to the response
 */
proto.external.ActualServicePromiseClient.prototype.listActuals =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/external.ActualService/ListActuals',
      request,
      metadata || {},
      methodDescriptor_ActualService_ListActuals);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.external.UpdateRequest,
 *   !proto.google.protobuf.Empty>}
 */
const methodDescriptor_ActualService_Update = new grpc.web.MethodDescriptor(
  '/external.ActualService/Update',
  grpc.web.MethodType.UNARY,
  proto.external.UpdateRequest,
  google_protobuf_empty_pb.Empty,
  /**
   * @param {!proto.external.UpdateRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  google_protobuf_empty_pb.Empty.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.external.UpdateRequest,
 *   !proto.google.protobuf.Empty>}
 */
const methodInfo_ActualService_Update = new grpc.web.AbstractClientBase.MethodInfo(
  google_protobuf_empty_pb.Empty,
  /**
   * @param {!proto.external.UpdateRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  google_protobuf_empty_pb.Empty.deserializeBinary
);


/**
 * @param {!proto.external.UpdateRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.google.protobuf.Empty)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.google.protobuf.Empty>|undefined}
 *     The XHR Node Readable Stream
 */
proto.external.ActualServiceClient.prototype.update =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/external.ActualService/Update',
      request,
      metadata || {},
      methodDescriptor_ActualService_Update,
      callback);
};


/**
 * @param {!proto.external.UpdateRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.google.protobuf.Empty>}
 *     Promise that resolves to the response
 */
proto.external.ActualServicePromiseClient.prototype.update =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/external.ActualService/Update',
      request,
      metadata || {},
      methodDescriptor_ActualService_Update);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.external.DeleteRequest,
 *   !proto.google.protobuf.Empty>}
 */
const methodDescriptor_ActualService_Delete = new grpc.web.MethodDescriptor(
  '/external.ActualService/Delete',
  grpc.web.MethodType.UNARY,
  proto.external.DeleteRequest,
  google_protobuf_empty_pb.Empty,
  /**
   * @param {!proto.external.DeleteRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  google_protobuf_empty_pb.Empty.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.external.DeleteRequest,
 *   !proto.google.protobuf.Empty>}
 */
const methodInfo_ActualService_Delete = new grpc.web.AbstractClientBase.MethodInfo(
  google_protobuf_empty_pb.Empty,
  /**
   * @param {!proto.external.DeleteRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  google_protobuf_empty_pb.Empty.deserializeBinary
);


/**
 * @param {!proto.external.DeleteRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.google.protobuf.Empty)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.google.protobuf.Empty>|undefined}
 *     The XHR Node Readable Stream
 */
proto.external.ActualServiceClient.prototype.delete =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/external.ActualService/Delete',
      request,
      metadata || {},
      methodDescriptor_ActualService_Delete,
      callback);
};


/**
 * @param {!proto.external.DeleteRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.google.protobuf.Empty>}
 *     Promise that resolves to the response
 */
proto.external.ActualServicePromiseClient.prototype.delete =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/external.ActualService/Delete',
      request,
      metadata || {},
      methodDescriptor_ActualService_Delete);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.external.GetSubCategoriesRequest,
 *   !proto.external.GetSubCategoriesResponse>}
 */
const methodDescriptor_ActualService_ListSubCategories = new grpc.web.MethodDescriptor(
  '/external.ActualService/ListSubCategories',
  grpc.web.MethodType.UNARY,
  proto.external.GetSubCategoriesRequest,
  proto.external.GetSubCategoriesResponse,
  /**
   * @param {!proto.external.GetSubCategoriesRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.external.GetSubCategoriesResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.external.GetSubCategoriesRequest,
 *   !proto.external.GetSubCategoriesResponse>}
 */
const methodInfo_ActualService_ListSubCategories = new grpc.web.AbstractClientBase.MethodInfo(
  proto.external.GetSubCategoriesResponse,
  /**
   * @param {!proto.external.GetSubCategoriesRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.external.GetSubCategoriesResponse.deserializeBinary
);


/**
 * @param {!proto.external.GetSubCategoriesRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.external.GetSubCategoriesResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.external.GetSubCategoriesResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.external.ActualServiceClient.prototype.listSubCategories =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/external.ActualService/ListSubCategories',
      request,
      metadata || {},
      methodDescriptor_ActualService_ListSubCategories,
      callback);
};


/**
 * @param {!proto.external.GetSubCategoriesRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.external.GetSubCategoriesResponse>}
 *     Promise that resolves to the response
 */
proto.external.ActualServicePromiseClient.prototype.listSubCategories =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/external.ActualService/ListSubCategories',
      request,
      metadata || {},
      methodDescriptor_ActualService_ListSubCategories);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.external.CreateRequest,
 *   !proto.google.protobuf.Empty>}
 */
const methodDescriptor_ActualService_Create = new grpc.web.MethodDescriptor(
  '/external.ActualService/Create',
  grpc.web.MethodType.UNARY,
  proto.external.CreateRequest,
  google_protobuf_empty_pb.Empty,
  /**
   * @param {!proto.external.CreateRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  google_protobuf_empty_pb.Empty.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.external.CreateRequest,
 *   !proto.google.protobuf.Empty>}
 */
const methodInfo_ActualService_Create = new grpc.web.AbstractClientBase.MethodInfo(
  google_protobuf_empty_pb.Empty,
  /**
   * @param {!proto.external.CreateRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  google_protobuf_empty_pb.Empty.deserializeBinary
);


/**
 * @param {!proto.external.CreateRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.google.protobuf.Empty)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.google.protobuf.Empty>|undefined}
 *     The XHR Node Readable Stream
 */
proto.external.ActualServiceClient.prototype.create =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/external.ActualService/Create',
      request,
      metadata || {},
      methodDescriptor_ActualService_Create,
      callback);
};


/**
 * @param {!proto.external.CreateRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.google.protobuf.Empty>}
 *     Promise that resolves to the response
 */
proto.external.ActualServicePromiseClient.prototype.create =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/external.ActualService/Create',
      request,
      metadata || {},
      methodDescriptor_ActualService_Create);
};


module.exports = proto.external;

