import React, { Suspense, lazy, useEffect } from 'react';

import Badge from '@material-ui/core/Badge';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { Notifications } from '@material-ui/icons';
import Breadcrumps from 'components/Breadcrumps';
import Container from 'components/Container';
import Spinner from 'components/Spinner';
import { formatZillions } from 'helpers/utils';
import UserHasAccess from 'hooks/UserHasAccess';
import useUrlParams from 'hooks/useUrlParams';
import { useSelector, useDispatch } from 'react-redux';
import * as actions from 'store/actions';

const AssetsTable = lazy(() => import('./AssetsTable'));

const useStyles = makeStyles((theme) => ({
  icon: {
    color: '#CCCCCC'
  },
  root: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: 500,
    padding: theme.spacing(6)
  },
  badge: {
    minWidth: theme.spacing(2.2),
    height: theme.spacing(2.2),
    width: theme.spacing(2.2)
  }
}));

const Assets = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { page, portfolioIds, setPortfolios } = useUrlParams();
  const { loading, loaded, data, error } = useSelector((state) => state.assets.assets);
  const userPermissions = useSelector((state) => state.user.userPermissions.permissionsList);
  const userPortfolios = useSelector((state) => state.portfolios.portfolios);
  const EditAssetsPermissions = UserHasAccess('asset', 'edit', userPermissions);

  const columns = [
    { title: 'Assets', field: 'name', customSort: (a, b) => a.name.localeCompare(b.name) },
    {
      title: 'Portfolio',
      field: 'portfolioname',
      customSort: (a, b) => a.portfolioname.localeCompare(b.portfolioname)
    },
    { title: 'Tenants', field: 'tenantcount' },

    { title: 'Occupancy (%)', field: 'occupancy' },
    {
      title: 'Leased Area (m2)',
      field: 'glatotal',
      render: (rowData) => formatZillions(rowData.glatotal)
    },
    {
      title: 'Notifications',
      field: 'notifs',
      render: (rowData) => (
        <Badge
          badgeContent={rowData.alertcount}
          color="error"
          overlap="circular"
          classes={{ badge: classes.badge }}
          onClick={(e) => {
            e.stopPropagation();

            dispatch(
              actions.setAlertFilters({
                asset: rowData.id,
                portfolio: rowData.portfolioid,
                tenant: ''
              })
            );

            dispatch(actions.toggleAlertMenu());
          }}
        >
          <Notifications className={classes.icon} />
        </Badge>
      )
    }
  ];

  useEffect(() => {
    dispatch(actions.getAssets(portfolioIds));
  }, [page, portfolioIds]);

  if (error && loaded) {
    return (
      <div className={classes.root}>
        <Typography color="error" variant="h5">
          {error}
        </Typography>
      </div>
    );
  }

  return (
    <Container>
      <Suspense fallback={<Spinner mini />}>
        <Breadcrumps />
        <AssetsTable
          title={`Assets (${data.length})`}
          columns={columns}
          data={data}
          loading={loading}
          EditAssetsPermissions={EditAssetsPermissions}
          userPortfolios={userPortfolios}
          portfolioIds={portfolioIds}
          setPortfolios={setPortfolios}
        />
      </Suspense>
    </Container>
  );
};

export default Assets;
